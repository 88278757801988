<template>
  <Head :title="`Admin - Send User Invitations`"></Head>
  <div class="page-block stack gap-4 vertical align-left">
    <h2 class="heading xl">{{ t('page.heading') }}</h2>

    <p class="text-xl text-gray-600 mb-5">
      {{ t('page.description') }}
    </p>

    <form
      @submit.prevent="form.post(route('admin.user-invites.store'))"
      class="card padded stack vertical gap-5"
    >
      <div class="max-w-prose">
        <s-input-field
          id="email"
          type="text"
          :label="t('email')"
          :required="true"
          v-model="form.email"
          :error="form.errors.email"
        />
      </div>

      <button type="submit" class="btn primary md w-fit my-1" :disabled="form.processing">
        {{ t('page.action') }}
      </button>
    </form>
  </div>
</template>
<script setup lang="ts">
import {Head, useForm} from '@inertiajs/vue3';
import SInputField from '../../../design-system/SInputField.vue';
import {route} from 'ziggy-js';
import {useI18n} from 'vue-i18n';

const {t} = useI18n({
  useScope: 'local',
  inheritLocale: true,
});

const form = useForm({
  email: '',
});
</script>
<i18n>
{
  "en": {
    "page": {
      "heading": "Invite User",
      "description": "Invite a user to Stemble or resend an invitation email.",
      "action": "Send Invitation"
    }
  }
}
</i18n>
