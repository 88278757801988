import {App} from 'vue/dist/vue';
import {Page} from '@inertiajs/core';
import * as Sentry from '@sentry/vue';

export const initializeSentryIfEnabled = (app: App, initialPage: Page) => {
  // Props scoped as any for now, as I don't see a way to specify the type of the inertia app's initial page props
  const {environment, release, sentryDsn, userEmail} = initialPage.props as any;

  // If the sentry DSN is not set, do not initialize sentry
  if (!sentryDsn) {
    return;
  }

  Sentry.init({
    app,
    dsn: sentryDsn,
    integrations: [new Sentry.BrowserTracing()],
    // Set `tracePropagationTargets` to control for which URLs distributed tracing should be enabled
    tracePropagationTargets: [`${window.location.origin}/`],
    // Down sample traces rate
    tracesSampleRate: 0.2,
    // Disable session replay (cost)
    replaysSessionSampleRate: 0.0,
    replaysOnErrorSampleRate: 0.0,
    logErrors: true,
    trackComponents: true,
    environment,
    release: release ?? 'LATEST',
    // This is an error that seems to be coming from one of our libraries, that is eating up our sentry budget
    // It does not appear to be affecting users negatively, as no-one is reporting it and it affects almost everyone. It may just be something that happens on navigation between tasks for marking and submission.
    ignoreErrors: [
      'ResizeObserver loop completed with undelivered notifications',
      'ResizeObserver loop limit exceeded',
    ],
  });

  // Track the user by their email
  Sentry.setUser({
    email: userEmail,
  });
};
