<template>
  <Head :title="`${t('page.heading', user)} - ${course.code}`"></Head>
  <div
    class="flex flex-col align-center justify-center w-full px-5 py-4 md:max-w-3xl md:mx-auto gap-4"
  >
    <s-page-header :heading="t('page.heading', user)"></s-page-header>

    <form @submit.prevent="form.put(route('courses.participants.update', [course.id, user.id]))">
      <s-card padding="sm">
        <template #content>
          <div class="stack vertical">
            <fieldset
              v-for="(courseRole, index) in form.courseRoles"
              class="stack horizontal gap-4 justify-between valign-bottom flex-wrap px-5 pt-4 pb-6 border-b border-gray-200"
              :class="{hidden: courseRole.destroy}"
            >
              <div class="">
                <s-select-field
                  :id="`courseRole[index].roleId`"
                  :label="`${t('course')}/${t('section')}`"
                  v-model.number="courseRole.courseLikeId"
                  required
                >
                  <option
                    v-for="courseLike in courseLikes"
                    :key="courseLike.id"
                    :value="courseLike.id"
                    :disabled="isNotAvailable(courseLike.id, courseRole.courseLikeId)"
                  >
                    {{ courseLike.name }}
                  </option>
                </s-select-field>
              </div>
              <s-select-field
                :label="t('role')"
                :id="`courseRole[index].roleId`"
                v-model.number="courseRole.roleId"
                required
              >
                <option v-for="role in roles" :key="role.id" :value="role.id">
                  {{ t(`roleNames.${role.name}`) }}
                </option>
              </s-select-field>
              <button
                v-if="can.deleteIn"
                class="group btn danger-white md w-fit flex items-center gap-1 ml-4"
                @click.prevent="form.courseRoles[index].destroy = !form.courseRoles[index].destroy"
              >
                <s-icon
                  class="opacity-70 group-hover:opacity-100 transition-opacity duration-100 ease-out"
                  name="close"
                  size="18"
                  aria-hidden="true"
                />
                {{ t('actions.remove') }}
              </button>
            </fieldset>
          </div>
        </template>
        <template #footer>
          <div class="flex flex-wrap gap-x-2 gap-y-3 justify-between w-full">
            <s-btn
              icon="close"
              color="secondary"
              :href="route('courses.participants.show', [course.id, user.id])"
              class="group w-fit"
              :disabled="form.processing"
            >
              {{ t('form.cancel') }}
            </s-btn>
            <span class="flex-1"></span>
            <s-btn
              icon="plus"
              v-if="can.createIn && !inAllSections"
              type="submit"
              class="group w-fit"
              :disabled="form.processing"
              @click.prevent="addRole()"
            >
              {{ t('actions.add') }}
            </s-btn>
            <s-btn
              icon="content-save"
              type="submit"
              class="group"
              :disabled="form.processing"
              :processing="form.processing"
            >
              {{ t('form.submit') }}
            </s-btn>
          </div>
        </template>
      </s-card>
    </form>
  </div>
</template>
<script setup lang="ts">
import {computed, defineProps} from 'vue';
import {Course} from '../../types/entities/course';
import {User} from '../../types/entities/user';
import {Head, useForm} from '@inertiajs/vue3';
import SSelectField from '../../design-system/SSelectField.vue';
import SPageHeader from '../../design-system/SPageHeader.vue';
import {Role} from '../../types/entities/role';
import {CourseLike} from '../../types/entities/course-like';
import {route} from 'ziggy-js';
import SBtn from '../../design-system/SBtn.vue';
import SCard from '../../design-system/SCard.vue';
import UpdateDto = App.DTOs.CourseParticipant.UpdateCourseParticipantDto;
import CourseRoleDto = App.DTOs.CourseParticipant.CourseRoleDto;
import {useI18n} from 'vue-i18n';
import SIcon from '../../design-system/SIcon.vue';

const props = defineProps<{
  course: Course;
  user: User;
  courseRoles: CourseRoleDto[];
  roles: Role[];
  courseLikes: CourseLike[];
  can: {
    createIn: boolean;
    deleteIn: boolean;
  };
}>();

const {t} = useI18n({
  useScope: 'local',
  inheritLocale: true,
});

const form = useForm<UpdateDto>({
  courseRoles: props.courseRoles,
});

const inAllSections = computed(() => {
  return form.courseRoles.filter((cr) => !cr.destroy).length === props.courseLikes.length;
});

const isNotAvailable = (courseLikeId: number, currentValue: number) => {
  return (
    courseLikeId !== currentValue &&
    form.courseRoles.some(
      (courseRole) => !courseRole.destroy && courseRole.courseLikeId === courseLikeId
    )
  );
};

function addRole() {
  if (inAllSections.value) {
    return;
  }
  const defaultRole = props.courseRoles.length
    ? props.courseRoles[props.courseRoles.length - 1]['roleId']
    : null;

  form.courseRoles.push({
    id: null,
    userId: props.user.id,
    roleId: defaultRole,
    courseLikeId: null as any,
    isActive: true,
    destroy: null,
  } as CourseRoleDto);
}
</script>
<i18n>
{
  "en": {
    "page": {
      "heading": "Edit {firstName} {lastName}'s Course Roles ({email})"
    },
    "actions": {
      "add": "Add Course Role"
    }
  },
  "fr": {
    "page": {
      "heading": "Modifier les rôles de cours de {firstName} {lastName} ({email})"
    },
    "actions": {
      "add": "Ajouter un rôle de cours"
    }
  }
}
</i18n>
