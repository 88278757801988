<template>
  <s-details detailsClass="bg-gradient-to-r from-red-100/50 to-red-50/50">
    <template #summary>
      <h3 class="heading md flex items-center gap-1 text-red-600">
        <s-icon name="minus-circle" size="20" class="opacity-70" />
        <i18n-t keypath="heading" :plural="rows.length">
          <template #count>
            <span class="font-black">
              {{ rows.length }}
            </span>
          </template>
        </i18n-t>
      </h3>
    </template>
    <diff-change-set type="remove" :rows="rows" />
  </s-details>
</template>
<script setup lang="ts">
import DiffChangeSet from '../../CourseStudent/Create/DiffChangeSet.vue';
import RosterChangeDto = App.DTOs.Roster.RosterChangeDto;
import {computed} from 'vue';
import {useI18n} from 'vue-i18n';
import SIcon from '../../../design-system/SIcon.vue';
import SDetails from '../../../design-system/SDetails.vue';

const props = defineProps<{
  removals: RosterChangeDto[];
}>();

const rows = computed(() => {
  return props.removals.map((removal) => {
    return {
      [t('email')]: removal.email,
      [t('role')]: t('roleNames.' + removal.role),
      [t('section')]: removal.sectionName,
    };
  });
});
const {t} = useI18n({
  useScope: 'local',
  inheritLocale: true,
});
</script>
<i18n>
{
  "en": {
    "heading": "Removing {count} Staff"
  },
  "fr": {
    "heading": "Suppression d'un membre du personnel | Suppression de {count} membres du personnel"
  }
}
</i18n>
