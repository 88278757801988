<template>
  <div class="relative bg-white z-[1] border-b border-gray-150">
    <s-page-block v-if="title" :size="size" class="!pb-3">
      <s-page-header :heading="title">
        <template #actions>
          <slot name="actions"></slot>
        </template>
      </s-page-header>
    </s-page-block>
    <s-nav-tabs :links="links" :class="`px-3 lg:px-5 ${sizeClasses[size]}`" />
    <div class="z-[-1] absolute top-0 left-0 w-full h-full -translate-y-full bg-white"></div>
  </div>
</template>
<script setup lang="ts">
import SPageBlock from './SPageBlock.vue';
import SPageHeader from './SPageHeader.vue';
import SNavTabs from './SNavTabs.vue';

interface NavLink {
  label: string;
  href: string;
  icon?: string;
}

const {
  links,
  title,
  size = 'lg',
} = defineProps<{
  links: NavLink[];
  title?: string;
  size?: 'full' | 'lg' | 'md' | 'sm';
}>();

const sizeClasses: Record<string, string> = {
  full: '',
  lg: 'max-w-screen-xl mx-auto',
  md: 'max-w-screen-lg mx-auto',
  sm: 'max-w-screen-sm mx-auto',
};
</script>
