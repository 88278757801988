<template>
  <component
    :is="linkOrButton"
    class="group cursor-pointer rounded-lg text-center whitespace-nowrap block outline-transparent outline-4 outline-offset-4 focus:outline-blue-400 disabled:cursor-not-allowed flex flex-col items-center justify-center gap-0.5 lg:gap-1 px-5 py-4 lg:py-5 text-sm lg:text-base leading-tight tracking-slight font-bold text-blue-500 hover:text-blue-600 active:text-blue-500 bg-white border border-gray-300/50 hover:bg-gray-50 active:bg-gray-100 shadow-btn-white"
    :href="href"
    :disabled="disabled || processing"
    :target="target"
  >
    <span class="opacity-70 group-hover:opacity-100 text-2xl lg:text-3xl">
      <s-icon v-if="icon && !processing" :name="icon" size="1em" />
      <s-icon v-if="processing" name="loading" size="1em" class="animate-spin" />
    </span>
    <slot />
  </component>
</template>
<script setup lang="ts">
import {computed} from 'vue';
import SLink from '../components/SLink.vue';
import SIcon from './SIcon.vue';

const props = defineProps<{
  href?: string;
  icon?: string;
  processing?: boolean;
  disabled?: boolean;
  target?: '_blank' | '_parent' | '_self' | '_top' | string;
}>();

const linkOrButton = computed(() => {
  return props.href ? SLink : 'button';
});
</script>
