import {computed, isRef, Ref, toRef, toValue} from 'vue';

export function unwrapValidationErrors(errors?: Record<string, any> | null): Record<string, any> {
  const rootErrors: Record<string, any> = {};
  const nestedErrors: Record<string, any> = {};

  // Treat empty or null errors as empty object
  errors = errors || {};

  for (let [key, error] of Object.entries(errors)) {
    if (typeof error !== 'string') {
      nestedErrors[key] = error;
      continue;
    }
    /*
     * For keys that end in an integer (i.e. array keys, without nested object property)
     * we append ".self" so that it can be merged into the object
     */
    if (key.search(/\.\d+$/) !== -1) {
      key += '.self';
    }

    /*
     * We partition the entries into root errors (without dots) and nested errors (with dots)
     */
    const [rootKey, ...subKeys] = key.split('.');
    const subKey = subKeys.join('.');

    if (!subKey) {
      rootErrors[rootKey] = error;
      continue;
    }

    nestedErrors[rootKey] = nestedErrors[rootKey] || {};
    nestedErrors[rootKey][subKey] = error;
  }

  /*
   * For any nested errors, we recursively unwrap those as well
   */
  for (const [key, nestedError] of Object.entries(nestedErrors)) {
    nestedErrors[key] = unwrapValidationErrors(nestedError);
  }

  return {...rootErrors, ...nestedErrors};
}

export interface UseValidationErrors {
  errors: Ref<Record<string, any>>;
}

export function useValidationErrors(
  rawErrors: Ref<Record<string, any> | null | undefined>
): UseValidationErrors;
export function useValidationErrors<Props extends Record<string, any>>(
  props: Props,
  key: keyof Props
): UseValidationErrors;
export function useValidationErrors<Props extends Record<string, any>>(
  propsOrErrors: Props | Ref<Record<string, any>>,
  key?: keyof Props
) {
  if (!isRef(propsOrErrors) && key) {
    propsOrErrors = toRef(propsOrErrors, key);
  }

  const errors = computed(() => unwrapValidationErrors(toValue(propsOrErrors)));

  return {errors};
}
