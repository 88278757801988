<template>
  <s-nav-header
    :links="[
      {label: 'Home', href: '?name=Home'},
      {label: 'About', href: '?name=About'},
      {label: 'Contact', href: '?name=Contact'},
    ]"
  />
  <p>Current Route: {{ route().params.name }}</p>
</template>
<script setup lang="ts">
import SNavHeader from '../../../../design-system/SNavHeader.vue';
import {route} from 'ziggy-js';
</script>
<script lang="ts">
import {ExampleOptions} from '../../examples';

export const options: ExampleOptions = {
  padding: false,
};
</script>
