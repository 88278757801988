<template>
  <div v-if="rubric.variables.length">
    <h3 class="heading md mb-1">
      {{ t('variables') }}
    </h3>
    <p class="mb-4">
      <i18n-t keypath="permutation-description">
        <template #count>
          <strong>{{ permutations }}</strong>
        </template>
      </i18n-t>
    </p>
    <s-table>
      <template #header>
        <tr>
          <th>{{ t('name') }}</th>
          <th>{{ t('type') }}</th>
          <th>{{ t('possibleValues') }}</th>
          <th></th>
        </tr>
      </template>
      <template #body>
        <tr v-for="variable in rubric.variables" :key="variable.uuid">
          <td class="font-bold text-base font-mono">{{ variable.name }}</td>
          <td>{{ variable.type }}</td>
          <td>
            <template v-if="variable.type === 'Number'">
              <div class="italic truncate flex-1 text-gray-600 font-medium">
                {{
                  generateNumberVariableExamples(
                    variable.min,
                    variable.max,
                    variable.step,
                    variable.shift,
                    variable.includeZero
                  ).join(', ')
                }}
              </div>
            </template>
          </td>
          <td>
            <s-badge
              v-if="!variable.includeZero"
              icon="alert-circle"
              color="blue"
              class="whitespace-nowrap"
            >
              {{ t('excludes-zero') }}
            </s-badge>
          </td>
        </tr>
      </template>
    </s-table>
  </div>
</template>
<script setup lang="ts">
import {calculatePermutations, generateNumberVariableExamples} from '../util/number-variables';
import SBadge from '../design-system/SBadge.vue';
import STable from '../design-system/STable.vue';
import Rubric = App.DTOs.Rubrics.Rubric;
import {computed} from 'vue';
import {useI18n} from 'vue-i18n';

const {rubric} = defineProps<{
  rubric: Rubric;
}>();

const {t} = useI18n({
  inheritLocale: true,
  useScope: 'local',
});

const permutations = computed(() => {
  return rubric.variables.reduce((acc: number, variable: any) => {
    return (
      acc *
      calculatePermutations(
        variable.min,
        variable.max,
        variable.step,
        variable.shift,
        variable.includeZero
      )
    );
  }, 1);
});
</script>
<i18n>
{
  "en": {
    "variables": "Variables",
    "permutation-description": "A random set of variables will be generated for each student. There are {count} possible permutations of this task.",
    "excludes-zero": "Excludes zero",
    "name": "Name",
    "type": "Type",
    "possibleValues": "Possible Values"
  },
  "fr": {
    "variables": "Variables",
    "permutation-description": "Un ensemble aléatoire de variables sera généré pour chaque élève. Il y a {count} permutations possibles de cette tâche.",
    "excludes-zero": "Exclut zéro",
    "name": "Nom",
    "type": "Type",
    "possibleValues": "Valeurs Possibles"
  }
}
</i18n>
