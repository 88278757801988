<template>
  <s-page-block>
    <s-page-header :heading="`${task.title} Evaluations`">
      <template #actions>
        <s-btn :href="route('admin.tasks.evaluations.create', {task: task.id})">
          Create Evaluation
        </s-btn>
      </template>
    </s-page-header>

    <ul v-if="evaluations.length" class="flex flex-col gap-4 mt-6">
      <li v-for="evaluation in evaluations" :key="evaluation.id" class="card padded flex gap-2">
        <div class="w-full">
          <div class="text-lg mb-2">
            <strong>{{ evaluation.title }}</strong>
          </div>
          <div class="flex items-center gap-4 text-slate-700">
            <div class="flex items-center gap-2">
              <s-icon name="account" height="12" width="12" />
              {{ evaluation.createdBy.firstName }} {{ evaluation.createdBy.lastName }}
            </div>
            <div class="flex items-center gap-2">
              <s-icon name="calendar" height="12" width="12" />
              Created: {{ monthDaySometimesYear(evaluation.createdAt) }}
            </div>
            <div v-if="evaluation.updatedAt" class="flex items-center gap-2">
              <s-icon name="calendar" height="12" width="12" />
              Last Updated: {{ monthDaySometimesYear(evaluation.updatedAt) }}
            </div>
          </div>
        </div>
        <s-btn
          class="w-fit"
          color="white"
          size="sm"
          :href="
            route('admin.tasks.evaluations.show', {task: task.id, taskEvaluation: evaluation.id})
          "
        >
          View
        </s-btn>
      </li>
    </ul>
    <p v-else>No evaluations have been run for this task.</p>
  </s-page-block>
</template>
<script setup lang="ts">
import SPageHeader from '../../../design-system/SPageHeader.vue';
import {Task} from '../../../types/entities/tasks';
import SPageBlock from '../../../design-system/SPageBlock.vue';
import {useBreadcrumbs} from '../../../composables/useBreadcrumbs';
import {route} from 'ziggy-js';
import SBtn from '../../../design-system/SBtn.vue';
import {monthDaySometimesYear} from '../../../format/dates';
import SIcon from '../../../design-system/SIcon.vue';
import TaskEvaluationDto = App.DTOs.TaskEvaluationDto;

const props = defineProps<{
  task: Task;
  evaluations: Array<TaskEvaluationDto>;
}>();

useBreadcrumbs([
  {label: 'Tasks', href: route('admin.tasks.index')},
  {label: props.task.title, href: route('admin.tasks.show', {task: props.task.id})},
]);
</script>
