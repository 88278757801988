<!-- eslint-disable -->
<template>
  <div class="w-full flex items-center flex-wrap gap-x-8 gap-y-2">
    <div class="flex-1 max-w-[18rem] flex flex-col gap-2">
      <label class="font-bold text-gray-700 leading-tight">Custom Increment</label>
      <div class="flex-1 flex items-center mb-7 relative">
        <input
          type="range"
          min="0"
          max="7"
          v-model="precisionLevel"
          @input="updateTicks"
          class="h-3 bg-gray-300 rounded-lg appearance-none cursor-pointe flex-1 rotate-180 cursor-pointer"
        />
        <span class="text-sm text-gray-600 font-medium absolute start-0 -bottom-7">
          Min &ndash; 0.05
        </span>
        <span class="text-sm text-gray-600 font-medium absolute end-0 -bottom-7">
          Max &ndash; 1.0
        </span>
      </div>
    </div>
    <div class="flex-1 px-3flex flex-col gap-2">
      <label class="font-bold text-gray-700 leading-tight">Example Grade Scale</label>
      <div
        class="relative w-full h-[1.125rem] bg-transparent border-[2px] border-b-0 border-blue-500 rounded-t md:min-w-[64ch] mb-6 mt-1"
      >
        <div
          v-for="(tick, index) in ticks"
          :key="index"
          class="absolute w-[2px] -translate-x-1/2 h-[1.125rem] bg-blue-500 first:bg-transparent last:bg-transparent bottom-0"
          :style="{left: `${tick}%`}"
        >
          <span
            class="absolute bottom-0 translate-y-full -translate-x-1/2 text-sm font-bold text-gray-600"
          >
            {{ tick }}%
          </span>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import {useVModel} from '@vueuse/core';
import {watch, ref, onMounted} from 'vue';

const permittedGradeIncrements: any = [
  {
    numberOfTicks: 1,
    gradeIncrement: 100,
  },
  {
    numberOfTicks: 2,
    gradeIncrement: 50,
  },
  {
    numberOfTicks: 3,
    gradeIncrement: 33.33,
  },
  {
    numberOfTicks: 4,
    gradeIncrement: 25,
  },
  {
    numberOfTicks: 5,
    gradeIncrement: 20,
  },
  {
    numberOfTicks: 8,
    gradeIncrement: 12.5,
  },
  {
    numberOfTicks: 10,
    gradeIncrement: 10,
  },
  {
    numberOfTicks: 20,
    gradeIncrement: 5,
  },
];

const props = defineProps({
  modelValue: {
    type: Number,
    required: true,
  },
});

const emit = defineEmits(['update:modelValue']);
const gradeIncrement = useVModel(props, 'modelValue', emit);
const precisionLevel = ref(3);
const ticks = ref([] as number[]);

watch(precisionLevel, () => {
  updateGradeIncrement();
  updateTicks();
});

function updateGradeIncrement() {
  gradeIncrement.value = permittedGradeIncrements[precisionLevel.value]['gradeIncrement'] / 100;
}

function updateTicks() {
  ticks.value = [];
  let newTick: number = 0;
  for (let i = 0; i < permittedGradeIncrements[precisionLevel.value]['numberOfTicks']; i++) {
    ticks.value.push(parseFloat(newTick.toFixed(2)));
    newTick += permittedGradeIncrements[precisionLevel.value]['gradeIncrement'];
  }
  ticks.value.push(100);
}

function findPrecisionLevelByGradeIncrement(targetIncrement: number) {
  let closest = null;
  let smallestDiff = Infinity;

  permittedGradeIncrements.forEach((inc: any, idx: number) => {
    const diff = Math.abs(inc.gradeIncrement - targetIncrement);
    if (diff < smallestDiff) {
      smallestDiff = diff;
      closest = idx;
    }
  });
  return closest;
}

onMounted(() => {
  const closestIndex = findPrecisionLevelByGradeIncrement(gradeIncrement.value * 100);
  precisionLevel.value = closestIndex !== null ? closestIndex : precisionLevel.value;
  updateGradeIncrement();
  updateTicks();
});
</script>
