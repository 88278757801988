<template>
  <div class="drop-shadow-card-soft">
    <TabGroup :selectedIndex="selectedIndex" @change="changeTab">
      <TabList
        class="flex flex-col md:flex-row w-full border md:border-none border-gray-200 rounded-t-lg"
      >
        <Tab
          v-for="(tab, index) in tabs"
          as="template"
          v-slot="{selected}"
          :disabled="!!tab.disabled"
        >
          <button
            class="@container group relative w-full flex-1 px-6 py-2.5 text-base leading-tighter border-b last:border-b-0 md:last:border-b border-gray-300/50 md:border md:-mr-px md:last:mr-0 transition"
            :class="{
              'font-bold bg-white md:border-b-white': selected,
              'text-blue-600': selected && !tab.completed,
              'font-medium': !selected,
              'pointer-events-none text-gray-400 bg-gray-150': tab.disabled,
              'bg-gray-100 hover:bg-white hover:text-blue-500': !tab.disabled && !selected,
              'text-gray-600': !tab.disabled && !selected && !tab.completed,
              'md:rounded-t-lg': !sequential,
              'first:rounded-t-lg md:first:rounded-tl-lg md:first:rounded-tr-none md:last:rounded-tr-lg':
                sequential,
              'text-green-500': selected && tab.completed,
              'text-green-600': !tab.disabled && !selected && tab.completed,
            }"
            :style="{
              'z-index': tabs.length - index,
            }"
          >
            <span class="flex flex-col @[10rem]:flex-row items-center justify-center gap-1">
              <s-icon
                v-if="!!tab.icon"
                :name="tab.completed ? 'check-decagram' : tab.icon"
                size="20"
                class="opacity-70"
              />
              {{ tab.label }}
            </span>
            <span
              v-if="sequential && index < tabs.length - 1"
              class="hidden md:block absolute -right-[0.5px] -top-px -bottom-px aspect-square origin-right scale-x-50 pointer-events-none"
              role="presentation"
            >
              <span
                class="absolute top-1/2 right-0 h-[calc(100%_*_sqrt(2)_/_2)] aspect-square rotate-45 -translate-y-1/2 translate-x-1/2 rounded-tr border-t-[1.5px] border-r-[1.5px] transition"
                :class="{
                  'bg-white': selected,
                  'border-gray-200': !tab.disabled,
                  'bg-gray-150 border-gray-300': tab.disabled,
                  'bg-gray-100 group-hover:bg-white': !tab.disabled && !selected,
                }"
              ></span>
            </span>
          </button>
        </Tab>
      </TabList>
      <TabPanels>
        <slot v-for="(tab, index) in tabs">
          <TabPanel
            :key="index"
            class="relative bg-gradient-to-t from-gray-50 via-gray-50 to-white rounded-b-lg rounded-t-none border border-gray-200 border-t-0 overflow-hidden"
            :style="{
              'z-index': tabs.length,
            }"
          >
            <slot :name="'tab-' + index">
              <div
                v-if="$slots['tab-' + index + '-content']"
                class="px-5 md:px-7 lg:px-8 py-4 md:py-5 lg:py-6 flex flex-col"
              >
                <slot :name="'tab-' + index + '-content'"></slot>
              </div>
              <div
                v-if="$slots['tab-' + index + '-footer'] || $slots['tab-footer']"
                class="bg-gradient-to-b from-gray-100 to-gray-50 border-t border-gray-300/50 px-5 md:px-7 lg:px-8 pt-4 pb-5 flex-1 flex flex-wrap items-end justify-between gap-x-4 gap-y-2"
              >
                <slot :name="'tab-' + index + '-footer'"></slot>
                <slot :name="'tab-footer'"></slot>
              </div>
            </slot>
          </TabPanel>
        </slot>
      </TabPanels>
    </TabGroup>
  </div>
</template>
<script setup lang="ts">
import {defineProps, defineEmits} from 'vue';
import {useVModel} from '@vueuse/core';
import {TabGroup, TabList, Tab, TabPanels, TabPanel} from '@headlessui/vue';
import SIcon from './SIcon.vue';

export type Tab = {
  label: string;
  icon?: string;
  disabled?: boolean;
  completed?: boolean;
};

const props = defineProps<{
  tabs: Tab[];
  sequential?: boolean;
  modelValue: number;
}>();
const emit = defineEmits(['update:modelValue']);
const selectedIndex = useVModel(props, 'modelValue', emit);

const changeTab = (index: number) => {
  selectedIndex.value = index;
};
</script>
<style scoped>
.diamond {
  clip-path: polygon(50% 0, 100% 50%, 50% 100%, 0 50%);
}
</style>
