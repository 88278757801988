<template>
  <Head :title="t('page.title')"></Head>
  <div class="bg-gradient-to-b from-blue-50 via-transparent to-transparent">
    <div class="p-4 lg:p-8 max-w-xl mx-auto flex flex-col gap-5">
      <section class="flex flex-col shadow-card rounded-lg">
        <h1
          id="form-title"
          class="bg-gradient-to-t from-white/70 to-white/30 flex items-center justify-center p-6 rounded-t-lg border border-white/50 border-b-gray-100"
        >
          <s-logo type="image" class="logo h-16 lg:h-[4.5rem] w-auto" />
          <span class="sr-only">
            {{ t('page.title') }}
          </span>
        </h1>
        <div
          class="px-5 lg:px-8 py-5 lg:py-8 bg-gradient-to-t from-gray-50 to-white rounded-b-lg flex flex-col gap-5"
        >
          <p>{{ t(`page.errorMessages.${errorMessageKey}`) }}</p>
        </div>
      </section>

      <div class="flex gap-x-3 gap-y-2 flex-wrap">
        <s-link :href="route('support')" class="link-subtle flex items-center gap-1.5">
          <s-icon name="chat-question" class="opacity-70" />
          {{ t('links.help') }}
        </s-link>
      </div>
    </div>
  </div>
</template>
<script setup lang="ts">
import {Head} from '@inertiajs/vue3';
import {useI18n} from 'vue-i18n';
import {route} from 'ziggy-js';
import SLink from '../../components/SLink.vue';
import UnauthorizedLayout from '../../layouts/UnauthorizedLayout.vue';
import SIcon from '../../design-system/SIcon.vue';
import loginTranslations from '../Login/login.json';
import SLogo from '../../design-system/SLogo.vue';

const {t} = useI18n({
  inheritLocale: true,
  messages: loginTranslations,
});

defineOptions({layout: UnauthorizedLayout});

defineProps<{
  errorMessageKey: string;
}>();
</script>
<i18n>
{
  "en": {
    "page": {
      "title": "LTI Launch Failure",
      "errorMessages": {
        "launchValidationFailed": "Could not validate the LTI Launch. Something is probably misconfigured. Please contact Stemble support.",
        "launchTypeFailure": "The launch type is not supported. Stemble only supports launches from the Course Context",
        "nonInstructorUnlinkedCourseLaunch": "Your instructor has not linked this course to Stemble. Contact your instructor to link the course.",
        "noLaunchInProgress": "No LTI launch in progress. Please restart the LTI launch from your LMS.",
        "platformNotFound": "Platform not found. Something is probably misconfigured. Please contact Stemble support."
      }
    }
  },
  "fr": {
    "page": {
      "title": "Échec du lancement LTI",
      "errorMessages": {
        "launchValidationFailed": "Impossible de valider le lancement LTI. Quelque chose est probablement mal configuré. Veuillez contacter le support de Stemble.",
        "launchTypeFailure": "Le type de lancement n'est pas pris en charge. Stemble ne prend en charge que les lancements à partir du contexte du cours",
        "nonInstructorUnlinkedCourseLaunch": "Votre instructeur n'a pas lié ce cours à Stemble. Contactez votre instructeur pour lier le cours.",
        "noLaunchInProgress": "Aucun lancement LTI en cours. Veuillez redémarrer le lancement LTI à partir de votre LMS.",
        "platformNotFound": "Plateforme introuvable. Quelque chose est probablement mal configuré. Veuillez contacter le support de Stemble."
      }
    }
  }
}
</i18n>
