<template>
  <s-tooltip :text="displayGradeAdjustmentsText" :disabled="!displayGradeAdjustmentsText">
    <div
      class="flex items-center flex-wrap gap-1 rounded-full font-bold text-lg leading-tight -mx-0.5 px-4 py-1.5 shadow-soft border"
      :class="determineGradePillClass(grade, !!grade.gradeAdjustments?.length)"
    >
      <span class="whitespace-nowrap">
        <s-task-grade :grade="grade" :gradeDisplay="gradeDisplay" :weight="weight" />
      </span>
      <s-icon
        v-if="grade.gradeAdjustments?.length"
        name="exclamation-circle"
        size="20"
        class="opacity-70"
      />
    </div>
  </s-tooltip>
</template>
<script setup lang="ts">
import {computed} from 'vue';
import {Grade} from '../types/entities/base-grade';
import STaskGrade from './STaskGrade.vue';
import SIcon from '../design-system/SIcon.vue';
import STooltip from '../design-system/STooltip.vue';
import {determineGradePillClass} from '../pages/AssignmentTask/Show/task-response-state-colors';
import GradeDisplay = App.Enums.GradeDisplay;

const props = defineProps<{
  grade: Grade;
  weight: number;
  gradeDisplay?: GradeDisplay;
}>();

const displayGradeAdjustmentsText = computed(() => {
  if (props.grade?.gradeAdjustments?.length) {
    return (
      'Applying a penalty of ' +
      props.grade.gradeAdjustments
        .map((adjustment) => {
          if (adjustment.adjustmentType === 'absolute') {
            return `${adjustment.value} points for ${adjustment.reason || 'being late'}`;
          }
          return `${adjustment.value}% for ${adjustment.reason || 'being late'}`;
        })
        .join(', ') +
      '.'
    );
  }
  return '';
});
</script>
