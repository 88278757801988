<template>
  <!-- @vue-ignore -->
  <s-latex :content="latexContent" v-bind="$attrs" v-on="$listeners" />
</template>

<script lang="ts" setup>
import {computed} from 'vue';
import SLatex from './SLatex.vue';

const props = defineProps<{
  number: string;
  unit?: string;
}>();
const latexContent = computed(() => {
  let result = props.number;
  if (props.unit) {
    result += ` \\ ${props.unit}`;
  }
  return `$${result}$`;
});
</script>
