<template>
  <Head title="Admin - Ai Grading"></Head>
  <div class="page-block">
    <h2 class="heading lg mb-2">Models Metadata</h2>
    <table class="w-full mt-4 border-collapse border border-gray-200">
      <thead>
        <tr class="bg-gray-100">
          <th class="border border-gray-200 p-2 text-left">Model</th>
          <th class="border border-gray-200 p-2 text-left">Provider</th>
          <th class="border border-gray-200 p-2 text-left">Input Token Pricing (per 1M Tokens)</th>
          <th class="border border-gray-200 p-2 text-left">Output Token Pricing (per 1M Tokens)</th>
          <th class="border border-gray-200 p-2 text-left">Max Input Context Window</th>
          <th class="border border-gray-200 p-2 text-left">Max Output Tokens</th>
          <th class="border border-gray-200 p-2 text-left">Supports AI Image Grading</th>
          <th class="border border-gray-200 p-2 text-left">Supports Structured Outputs</th>
          <th class="border border-gray-200 p-2 text-left">Supports Temperature</th>
          <th class="border border-gray-200 p-2 text-left">Supports TopP</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="model in models" :key="model.model" class="hover:bg-gray-50">
          <td class="border border-gray-200 p-2">
            {{ model.model }}
          </td>
          <td class="border border-gray-200 p-2">{{ model.llmProvider }}</td>
          <td class="border border-gray-200 p-2">
            <s-link
              class="link hover:underline"
              :href="route('admin.ai-analytics.pricings.show', [model.id])"
            >
              ${{ model.inputTokenPricingPerMillionTokens.toFixed(2) }}
            </s-link>
          </td>
          <td class="border border-gray-200 p-2">
            <s-link
              class="link hover:underline"
              :href="route('admin.ai-analytics.pricings.show', [model.id])"
            >
              ${{ model.outputTokenPricingPerMillionTokens.toFixed(2) }}
            </s-link>
          </td>
          <td class="border border-gray-200 p-2">{{ model.maxInputContextWindow }} tokens</td>
          <td class="border border-gray-200 p-2">{{ model.maxOutputTokens }} tokens</td>
          <td class="border border-gray-200 p-2">
            <span v-if="model.supportsAiImageGrading" class="text-green-600">Yes</span>
            <span v-else class="text-red-600">No</span>
          </td>
          <td class="border border-gray-200 p-2">
            <span v-if="model.supportsStructuredOutputs" class="text-green-600">Yes</span>
            <span v-else class="text-red-600">No</span>
          </td>
          <td class="border border-gray-200 p-2">
            <span v-if="model.supportsTemperature" class="text-green-600">Yes</span>
            <span v-else class="text-red-600">No</span>
          </td>
          <td class="border border-gray-200 p-2">
            <span v-if="model.supportsTopP" class="text-green-600">Yes</span>
            <span v-else class="text-red-600">No</span>
          </td>
          <td class="border border-gray-200 p-2">
            <s-btn
              :href="route('admin.ai-analytics.models.edit', model.id)"
              class="btn-sm"
              icon="pencil"
              color="secondary"
            >
              Edit
            </s-btn>
            <s-btn
              v-if="!model.deleteProtection"
              @click.prevent="confirmDelete(model.id)"
              class="btn-sm"
              icon="cancel"
              color="danger"
            >
              Delete
            </s-btn>
          </td>
        </tr>
      </tbody>
      <s-btn
        :href="route('admin.ai-analytics.models.create')"
        class="btn-sm"
        icon="plus"
        color="secondary"
      >
        Add New Model
      </s-btn>
    </table>
  </div>
</template>
<script setup lang="ts">
import {route} from 'ziggy-js';
import {router} from '@inertiajs/vue3';

import {Head} from '@inertiajs/vue3';
import {useBreadcrumbs} from '../../../../composables/useBreadcrumbs';
import SBtn from '../../../../design-system/SBtn.vue';
import SLink from '../../../../components/SLink.vue';

defineProps<{
  models: {
    id: number;
    model: string;
    llmProvider: string;
    inputTokenPricingPerMillionTokens: number;
    outputTokenPricingPerMillionTokens: number;
    maxInputContextWindow: number;
    maxOutputTokens: number;
    supportsAiImageGrading: boolean;
    supportsStructuredOutputs: boolean;
    supportsTemperature: boolean;
    supportsTopP: boolean;
    deleteProtection: boolean;
  }[];
}>();

function confirmDelete(id: number) {
  if (
    confirm(
      'You are about to delete this model. This cannot be undone. Are you sure you want to proceed?'
    )
  ) {
    router.delete(route('admin.ai-analytics.models.destroy', id));
  }
}

useBreadcrumbs([{label: 'Ai Analytics', href: route('admin.ai-analytics.index')}]);
</script>

<style scoped></style>
