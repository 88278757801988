import {v4 as uuidv4} from 'uuid';
import {defineComponentType} from './defineComponentType';
import LinkUiConfig = App.Entities.Tasks.Ui.LinkUiConfig;

export const link = defineComponentType<LinkUiConfig, null>({
  translationKey: 'Link',
  icon: 'link',
  uiConfigFactory: () => ({
    uuid: uuidv4(),
    type: 'Link',
    name: 'Link',
    text: '',
    href: '',
    part: null,
  }),
  componentGraderFactory: () => null,
});
