import {inject} from 'vue';

interface Breadcrumb {
  label: string;
  href: string;
  external?: boolean;
}

interface BreadcrumbsContext {
  updateBreadcrumbs: (breadcrumbs: Breadcrumb[]) => void;
}

export function useBreadcrumbs(breadcrumbs: Breadcrumb[]) {
  const breadcrumbsContext = inject<BreadcrumbsContext>('breadcrumbs');

  if (breadcrumbsContext && breadcrumbsContext.updateBreadcrumbs) {
    breadcrumbsContext.updateBreadcrumbs(breadcrumbs);
  } else {
    console.warn('updateBreadcrumbs is not available in the current context');
  }
}
