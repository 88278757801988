import {createI18n} from 'vue-i18n';
import en from '../lang/en.json';
import fr from '../lang/fr.json';

export function createStembleI18n(locale: string = 'en') {
  return createI18n({
    locale,
    fallbackLocale: 'en',
    legacy: false,
    messages: {
      en,
      fr,
    },
  });
}
