<template>
  <s-page-block>
    <s-page-header :heading="`Graded Response (${response.id}) for ${task.title}`"></s-page-header>
    <s-details>
      <template #summary>Response MetaData:</template>
      <pre>
        <code>
          {{ JSON.stringify(response, null, 2) }}
        </code>
      </pre>
    </s-details>
    <s-details>
      <template #summary>Student Response:</template>
      <pre>
        <code>
          {{ JSON.stringify(responseData, null, 2) }}
        </code>
      </pre>
    </s-details>
    <s-details>
      <template #summary>Grade Data:</template>
      <s-task-grade v-if="grade" :grade="grade" :weight="100" />
    </s-details>
    <s-details>
      <template #summary>Feedback Data:</template>
      <div v-for="(part, partName) in feedbackByPart">
        <s-latex v-if="partName != 'default'" :content="partName" />
        <ul>
          <li v-for="feedback in part">
            <s-latex :content="feedback.content" />
          </li>
        </ul>
      </div>
    </s-details>
    <form @submit.prevent="submitForm" class="card padded">
      <!-- Overall Task Grade -->

      Original Task Grade:
      <s-task-grade v-if="grade" :grade="grade" :weight="100" class="mb-4"></s-task-grade>
      <s-input-field
        id="grade"
        label="Task Grade"
        type="number"
        step="0.01"
        prefix="Points Received:"
        :suffix="grade.pointsAvailable.toFixed(2)"
        min="0"
        max="100"
        v-model="form.pointsReceived"
      />

      <!-- Feedbacks -->
      <s-field class="mt-4 mb-8" id="feedbackByPart" label="Feedbacks">
        <div v-for="(part, partName) in form.feedbackByPart" :key="partName" class="mb-4 mt-4">
          <div v-for="(feedback, index) in part" :key="index" class="feedback-item">
            <s-textarea-field
              :id="`feedback-${partName}-${index}`"
              :label="`${partName}`"
              v-model="form.feedbackByPart[partName][index].content"
            />
          </div>
        </div>
      </s-field>

      <!-- Submit/Delete Button -->
      <div class="flex flex-row flex-wrap gap-2 py-1">
        <s-btn color="red" icon="delete" @click.prevent="confirmDelete">Remove Response</s-btn>
        <s-btn type="submit" class="btn-primary">Save Changes</s-btn>
      </div>
    </form>
  </s-page-block>
</template>
<script setup lang="ts">
import SPageBlock from '../../../design-system/SPageBlock.vue';
import SPageHeader from '../../../design-system/SPageHeader.vue';
import {Task, TaskFeedback, TaskResponse} from '../../../types/entities/tasks';
import SDetails from '../../../design-system/SDetails.vue';
import {useBreadcrumbs} from '../../../composables/useBreadcrumbs';
import {route} from 'ziggy-js';
import {Grade} from '../../../types/entities/base-grade';
import SLatex from '../../../components/SLatex.vue';
import STaskGrade from '../../../components/STaskGrade.vue';
import SInputField from '../../../design-system/SInputField.vue';
import STextareaField from '../../../design-system/STextareaField.vue';
import SBtn from '../../../design-system/SBtn.vue';
import SField from '../../../design-system/SField.vue';
import {router, useForm} from '@inertiajs/vue3';

const props = defineProps<{
  task: Task;
  response: TaskResponse;
  responseData: any;
  feedbackByPart: Record<string, TaskFeedback[]>;
  grade: Grade;
}>();

type FormModel = {
  pointsReceived: number;
  feedbackByPart: Record<string, TaskFeedback[]>;
};

const form = useForm<FormModel>({
  pointsReceived: props.grade.pointsReceived,
  feedbackByPart: JSON.parse(JSON.stringify(props.feedbackByPart)),
});

const submitForm = () => {
  form.put(
    route('admin.tasks.responses.update', {task: props.task.id, response: props.response.id})
  );
};

function confirmDelete() {
  if (
    confirm(
      'You are about to remove this response from the task evaluation set. This cannot be undone. Are you sure you want to proceed?'
    )
  ) {
    router.delete(
      route('admin.tasks.responses.destroy', {task: props.task.id, response: props.response.id})
    );
  }
}

useBreadcrumbs([
  {label: 'Tasks', href: route('admin.tasks.index')},
  {label: props.task.title, href: route('admin.tasks.show', {task: props.task.id})},
]);
</script>
