<template>
  <form @submit.prevent="$emit('submit')">
    <div :class="classes.tabPane">
      <template v-if="theirColumns.length">
        <h3 class="heading md">{{ t('columnMapping') }}</h3>
        <p class="text-base mb-3">
          {{ t('selectColumns') }}
        </p>
        <div class="grid grid-cols-[repeat(_auto-fit,_minmax(130px,_1fr))] gap-4 mb-5">
          <div v-for="ourColumn in ourColumns" class="grow shrink-0">
            <s-select-field
              :id="`column-map-${ourColumn.key}`"
              :label="ourColumn.label"
              v-model="form.columnMap[ourColumn.key]"
              :error="errors['columnMap.' + ourColumn.key]"
              class="form-select"
            >
              <option value="">{{ t('--') }}</option>
              <option
                v-for="theirColumn in theirColumns"
                :value="theirColumn"
                :disabled="isMapped(theirColumn)"
              >
                {{ theirColumn }}
              </option>
            </s-select-field>
          </div>
        </div>
      </template>
      <h3 class="heading md mb-2">{{ t('settings') }}</h3>
      <div class="grid grid-cols-[repeat(_auto-fit,_minmax(130px,_1fr))] gap-4 mb-5">
        <div class="grow shrink-0">
          <s-select-field
            id="column-map-default-section"
            :label="t('labels.defaultSection')"
            v-model="form.columnMap.defaultSection"
            :error="errors['columnMap.defaultSection']"
            class="form-select"
          >
            <option
              v-for="courseLike in courseLikes"
              :value="courseLike.name"
              :key="courseLike.name"
            >
              {{ courseLike.name }}
            </option>
          </s-select-field>
        </div>
        <div class="grow shrink-0">
          <s-select-field
            id="column-map-default-role"
            :label="t('labels.defaultRole')"
            v-model="form.columnMap.defaultRole"
            :error="errors['columnMap.defaultRole']"
            class="form-select"
          >
            <option v-for="role in roles" :value="role" :key="role">
              {{ t('roleNames.' + role) }}
            </option>
          </s-select-field>
        </div>
      </div>
      <s-checkbox
        v-if="can.create"
        id="config.addMissingParticipants"
        v-model="form.config.addMissingParticipants"
      >
        <strong class="font-black text-green-600">{{ t('add') }}</strong>
        {{ t('newStaffMembers') }}
      </s-checkbox>
      <s-checkbox
        v-if="can.update"
        id="config.updateNonStudents"
        v-model="form.config.updateNonStudents"
      >
        <strong class="font-black text-blue-500">{{ t('update') }}</strong>
        {{ t('rolesForExistingStaff') }}
      </s-checkbox>
      <s-checkbox
        v-if="can.delete"
        id="config.removeMissingRoles"
        v-model="form.config.removeMissingRoles"
      >
        <strong class="font-black text-red-600">{{ t('remove') }}</strong>
        {{ t('staffRolesNotFound') }}
      </s-checkbox>
    </div>
    <div :class="classes.tabFooter">
      <s-btn
        color="secondary"
        icon="arrow-left"
        @click="$emit('back')"
        class="flex-1 max-w-[16rem]"
      >
        {{ t('actions.back') }}
      </s-btn>
      <s-btn
        type="submit"
        color="primary"
        :disabled="form.processing"
        :processing="form.processing"
        class="flex-1 max-w-[16rem]"
      >
        {{ t('actions.continue') }}
        <s-icon name="arrow-right" class="opacity-70" size="18" />
      </s-btn>
    </div>
  </form>
</template>
<script setup lang="ts">
import SCheckbox from '../../../design-system/SCheckbox.vue';
import SSelectField from '../../../design-system/SSelectField.vue';
import SBtn from '../../../design-system/SBtn.vue';
import {InertiaForm} from '@inertiajs/vue3';
import RosterFormDto = App.DTOs.Roster.RosterFormDto;
import {useI18n} from 'vue-i18n';
import InputMapDto = App.DTOs.Roster.InputMapDto;
import SIcon from '../../../design-system/SIcon.vue';

type ColumnKey = keyof InputMapDto;

type ColumnDefinition = {
  key: ColumnKey;
  label: string;
};

const props = defineProps<{
  form: InertiaForm<RosterFormDto>;
  errors: Record<string, string>;
  classes: any;
  courseLikes: CourseLike[];
  ourColumns: ColumnDefinition[];
  theirColumns: string[];
  roles: string[];
  can: {
    create: boolean;
    update: boolean;
    delete: boolean;
  };
}>();

defineEmits(['submit', 'back']);

const {t} = useI18n({
  useScope: 'local',
  inheritLocale: true,
});

const isMapped = (c: string) =>
  Object.values(props.ourColumns)
    .map((col) => col.key)
    .includes(c as ColumnKey);
</script>
<i18n>
{
  "en": {
    "columnMapping": "Column Mapping",
    "selectColumns": "Select the columns from your spreadsheet that map with our data.",
    "labels": {
      "defaultSection": "Default Section",
      "defaultRole": "Default Role"
    },
    "add": "Add",
    "newStaffMembers": "New Staff Members",
    "update": "Update",
    "rolesForExistingStaff": "Roles for Existing Staff",
    "remove": "Remove",
    "staffRolesNotFound": "staff roles that are not found in the CSV"
  },
  "fr": {
    "columnMapping": "Correspondance des colonnes",
    "selectColumns": "Sélectionnez les colonnes de votre feuille de calcul qui correspondent à nos données.",
    "labels": {
      "defaultSection": "Section par défaut",
      "defaultRole": "Rôle par défaut"
    },
    "add": "Ajouter",
    "newStaffMembers": "Nouveaux membres du personnel",
    "update": "Mettre à jour",
    "rolesForExistingStaff": "Rôles pour le personnel existant",
    "remove": "Supprimer",
    "staffRolesNotFound": "Rôles du personnel non trouvés dans le fichier CSV"
  }
}
</i18n>
