<template>
  <s-tabs
    v-model="selectedTabRef"
    :tabs="[
      {label: 'First', icon: 'plus', disabled: false, completed: true},
      {label: 'Second', icon: 'minus', disabled: false, completed: false},
    ]"
  >
    <template #tab-0-content>First Tab</template>
    <template #tab-1-content>Second Tab</template>
  </s-tabs>
</template>
<script setup lang="ts">
import STabs from '../../../../design-system/STabs.vue';
import {ref} from 'vue';

const selectedTabRef = ref(0);
</script>
