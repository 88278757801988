<template>
  <Head>
    <link
      rel="stylesheet"
      href="https://fonts.googleapis.com/css?family=Roboto:100,300,400,500,700,900"
    />
    <link rel="icon" href="/favicon.png" />
  </Head>
  <sidebar-layout :items="items">
    <slot />
  </sidebar-layout>
</template>
<script lang="ts" setup>
import {Head, usePage} from '@inertiajs/vue3';
import SidebarLayout from '../components/sidebar/SidebarLayout.vue';
import {SidebarItem} from '../components/sidebar/types';

const page = usePage();

const items = page.props.menu as SidebarItem[];
</script>
