<template>
  <Head title="Cannot Copy Task"></Head>
  <section class="page-block">
    <s-page-header
      heading="Cannot Copy Task"
      description="This task cannot be copied through this interface. Please contact an administrator."
      class="pb-4"
    >
      <template #actions>
        <button @click.prevent="back()" class="btn secondary md w-fit">Back</button>
      </template>
    </s-page-header>
  </section>
</template>
<script setup lang="ts">
import SPageHeader from '../../design-system/SPageHeader.vue';
import {route} from 'ziggy-js';
import {Task} from '../../types/entities/tasks';
import {useBreadcrumbs} from '../../composables/useBreadcrumbs';
import {Head} from '@inertiajs/vue3';

const props = defineProps<{task: Task}>();

useBreadcrumbs([
  {label: 'Tasks', href: route('admin.tasks.index')},
  {label: props.task.title, href: route('admin.tasks.show', [props.task.id])},
]);

const back = () => {
  window.history.back();
};
</script>
