import {v4 as uuidv4} from 'uuid';
import {defineComponentType} from './defineComponentType';
import {calculationFactory} from '../../../../util/calculation';
import SingleCalculationUiConfig = App.Entities.Tasks.Ui.SingleCalculationUiConfig;
import SingleCalculationComponentGrader = App.Entities.Tasks.ComponentGraders.SingleCalculationComponentGrader;

export const singleCalculation = defineComponentType<
  SingleCalculationUiConfig,
  SingleCalculationComponentGrader
>({
  translationKey: 'Single Calculation',
  icon: 'calculator',
  uiConfigFactory: () => ({
    uuid: uuidv4(),
    type: 'SingleCalculation',
    name: 'Single Calculation',
    question: '',
    appendText: null,
    prependText: null,
    part: null,
  }),
  componentGraderFactory: (uuid) => ({
    uuid: uuidv4(),
    type: 'SingleCalculation',
    name: 'Single Calculation Grader',
    uiComponentUuid: uuid,
    fallbackFeedback: '',
    calculations: [calculationFactory()],
    tolerance: {
      type: 'relative',
      value: '0.02',
    },
    subexpressions: [],
  }),
});
