<template>
  <div
    v-if="flash && (flash.success || flash.error || flash.warnings?.length)"
    class="!self-end col-start-1 col-end-2 row-start-1 row-end-2 !grow-0 sticky w-full bottom-0 w-full h-0 pointer-events-none z-50 flex flex-col items-center"
  >
    <div
      class="absolute bottom-0 w-full max-w-screen-lg flex flex-col items-stretch gap-3 px-5 lg:px-7 pb-6"
    >
      <!-- Success Flash Message -->
      <s-toast v-if="flash.success" type="success" @dismiss="flash.dismiss('success')">
        {{ flash.success }}
      </s-toast>

      <!-- Error Flash Message -->
      <s-toast v-if="flash.error" type="error" @dismiss="flash.dismiss('error')">
        {{ flash.error }}
      </s-toast>

      <!-- Warnings Flash Message -->
      <s-toast v-if="flash.warnings?.length" type="warning" @dismiss="flash.dismiss('warnings')">
        <ul class="flex flex-col flex-1">
          <li v-for="warning in flash.warnings" :key="warning">{{ warning }}</li>
        </ul>
      </s-toast>
    </div>
  </div>
</template>
<script setup lang="ts">
import {useFlash} from '../composables/useFlash';
import SToast from './SToast.vue';

const flash = useFlash();
</script>
