import {inject, onBeforeUnmount, onMounted, onUnmounted, provide, Ref, ref} from 'vue';

export type Shortcut = {key: string; label: string; onPress: () => void};

export function useKeyboardShortcuts() {
  const keyboardShortcuts = inject<Ref<Shortcut[]>>('keyboardShortcuts');

  return {
    register(shortcut: Shortcut) {
      /**
       * Do nothing if the keyboardShortcuts is not provided.
       */
      if (!keyboardShortcuts?.value) {
        return;
      }

      /**
       * Add the shortcut to the list of shortcuts.
       */
      keyboardShortcuts.value.push(shortcut);

      /**
       * Handle the keyboard event and check if the shortcut key is pressed.
       *
       * @param e
       */
      let hotkeyNavigation = (e: KeyboardEvent) => {
        let isFormInput =
          e.target instanceof HTMLInputElement ||
          e.target instanceof HTMLTextAreaElement ||
          e.target instanceof HTMLSelectElement;

        if (isFormInput) return;

        if (e.key === shortcut.key) {
          shortcut.onPress();
        }
      };

      /**
       * Add the event listener when the component is mounted.
       */
      onMounted(() => {
        window.addEventListener('keydown', hotkeyNavigation);
      });

      /**
       * Remove the event listener when the component is unmounted.
       * Remove the shortcut from the list of shortcuts.
       */
      onUnmounted(() => {
        window.removeEventListener('keydown', hotkeyNavigation);
        keyboardShortcuts.value = keyboardShortcuts.value.filter(
          (item) => item.key !== shortcut.key
        );
      });
    },
  };
}

export function provideKeyboardShortcuts() {
  const keyboardShortcuts = ref<Shortcut[]>([]);

  provide('keyboardShortcuts', keyboardShortcuts);

  return keyboardShortcuts;
}
