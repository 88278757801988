<template>
  <div class="flex">
    <s-badge size="lg" class="!bg-transparent leading-none rounded-r-none !border-r-0">
      <span class="font-bold text-gray-500 tracking-wide flex items-center gap-1">
        <s-icon name="pencil" class="opacity-70" size="18" />
        {{ t('draft') }}
      </span>
    </s-badge>
    <s-tooltip :text="t('discardDraft')">
      <s-btn
        method="delete"
        color="white"
        class="group rounded-l-none"
        :aria-label="t('discardDraft')"
        @click="showDeleteDraftConfirmation = true"
      >
        <s-icon
          name="trash-can"
          class="text-red-600 group-hover:text-red-500 group-active:text-red-600 opacity-70 -mr-1 -ml-1.5"
          size="18"
        />
      </s-btn>
    </s-tooltip>
  </div>
  <s-modal
    v-model:open="showDeleteDraftConfirmation"
    :title="t('discardDraft')"
    :message="t('confirmMessage')"
    :confirm="{
      label: t('actions.discard'),
      icon: 'trash-can',
      color: 'red',
      callback: discardDraft,
    }"
    cancellable
  />
</template>
<script setup lang="ts">
import {ref} from 'vue';
import SBadge from '@/design-system/SBadge.vue';
import SBtn from '@/design-system/SBtn.vue';
import SIcon from '@/design-system/SIcon.vue';
import STooltip from '@/design-system/STooltip.vue';
import SModal from '@/design-system/SModal.vue';
import {useI18n} from 'vue-i18n';

const props = defineProps<{
  discardDraft: () => void;
}>();

const showDeleteDraftConfirmation = ref(false);

const {t} = useI18n({
  inheritLocale: true,
  useScope: 'local',
});
</script>
<i18n>
{
  "en": {
    "discardDraft": "Discard Draft",
    "confirmMessage": "Are you sure you want to delete this draft? This action cannot be undone."
  },
  "fr": {
    "discardDraft": "Supprimer le brouillon",
    "confirmMessage": "Êtes-vous sûr de vouloir supprimer ce brouillon? Cette action ne peut pas être défaite."
  }
}
</i18n>
