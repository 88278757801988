<template>
  <component
    :is="linkOrButton"
    class="disabled:cursor-not-allowed flex items-center justify-center gap-1"
    :class="[
      // Icon Position
      iconEnd ? 'flex-row-reverse' : '',
      underline ? 'link' : 'link-subtle',
    ]"
    :href="href"
    :disabled="disabled || processing"
    :target="target"
  >
    <s-icon v-if="icon && !processing" :name="icon" class="opacity-70" size="1em" />
    <s-icon v-if="processing" name="loading" class="opacity-70 animate-spin" size="1em" />
    <slot />
  </component>
</template>
<script setup lang="ts">
import {computed} from 'vue';
import SLink from '../components/SLink.vue';
import SIcon from './SIcon.vue';

const props = defineProps<{
  href?: string;
  icon?: string;
  processing?: boolean;
  disabled?: boolean;
  target?: '_blank' | '_parent' | '_self' | '_top' | string;
  iconEnd?: boolean;
  underline?: boolean;
}>();

const linkOrButton = computed(() => {
  return props.href ? SLink : 'button';
});
</script>
