<template>
  <s-badge-group>
    <s-badge color="blue">First</s-badge>
    <s-badge color="green">Second</s-badge>
    <s-badge color="yellow">Third</s-badge>
  </s-badge-group>
</template>
<script setup lang="ts">
import SBadgeGroup from '../../../../design-system/SBadgeGroup.vue';
import SBadge from '../../../../design-system/SBadge.vue';
</script>
<script lang="ts">
import {ExampleOptions} from '../../examples';

const description = `Badges can be grouped together to visually.`;

export const options: ExampleOptions = {
  description,
};
</script>
