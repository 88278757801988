<!-- eslint-disable -->
<template>
  <s-field
    class="mt-2 mb-4"
    id="showGradeIncrement"
    description="The smallest increment that can be used to grade the task. Default is 0.05."
    label="Grading Increment"
    :error="errors?.['gradeIncrement']"
  >
    <s-button-toggle
      id="showGradeIncrement"
      :options="[
        {value: false, label: 'Default Precision &ndash; 0.05'},
        {
          value: true,
          label: !useCustomGradeIncrement
            ? 'Custom'
            : `Custom &ndash; ${customGradeIncrement.toFixed(2)}`,
        },
      ]"
      v-model="useCustomGradeIncrement"
    />
    <div v-if="useCustomGradeIncrement" class="pt-2">
      <GradeIncrementSlider v-model="customGradeIncrement" />
    </div>
  </s-field>
</template>

<script setup lang="ts">
import {defineProps, defineEmits, ref, watch} from 'vue';
import SField from '../design-system/SField.vue';
import SButtonToggle from '../design-system/SButtonToggle.vue';
import GradeIncrementSlider from './GradeIncrementSlider.vue';
import {useVModel} from '@vueuse/core';

const props = defineProps({
  modelValue: {
    type: Number,
    default: 0.05,
  },
  errors: Object,
});

const emit = defineEmits(['update:modelValue']);
const gradeIncrement = useVModel(props, 'modelValue', emit);

const DEFAULT_GRADE_INCREMENT = 0.05;
const useCustomGradeIncrement = ref(gradeIncrement.value !== DEFAULT_GRADE_INCREMENT);
const customGradeIncrement = ref(gradeIncrement.value || DEFAULT_GRADE_INCREMENT);

watch(
  [useCustomGradeIncrement, customGradeIncrement],
  ([useCustomGradeIncrement, customGradeIncrement]) => {
    if (useCustomGradeIncrement) {
      gradeIncrement.value = customGradeIncrement;
    } else {
      gradeIncrement.value = DEFAULT_GRADE_INCREMENT;
    }
  }
);
</script>
