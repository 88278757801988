<template>
  <Head :title="t('page.title')" />
  <s-page-block size="sm">
    <s-page-header :heading="t('page.title')" class="mb-4" />
    <form @submit.prevent="form.put(route('password.change'))" class="flex flex-col gap-4">
      <s-input-field
        id="currentPassword"
        name="currentPassword"
        v-model="form.currentPassword"
        :label="t('form.passwordCurrent')"
        :error="form.errors.currentPassword"
        type="password"
      />
      <s-input-field
        id="password"
        name="password"
        v-model="form.password"
        :label="t('form.passwordNew')"
        :error="form.errors.password"
        type="password"
      />
      <s-input-field
        id="passwordConfirmation"
        name="passwordConfirmation"
        v-model="form.passwordConfirmation"
        :label="t('form.passwordConfirm')"
        :error="form.errors.passwordConfirmation"
        type="password"
      />
      <s-btn
        type="submit"
        color="primary"
        :loading="form.processing"
        :disabled="form.processing"
        class="mt-2"
      >
        {{ t('form.submit') }}
      </s-btn>
    </form>
  </s-page-block>
</template>
<script setup lang="ts">
import SPageBlock from '../../design-system/SPageBlock.vue';
import SPageHeader from '../../design-system/SPageHeader.vue';
import {Head, useForm} from '@inertiajs/vue3';
import {useI18n} from 'vue-i18n';
import SInputField from '../../design-system/SInputField.vue';
import SBtn from '../../design-system/SBtn.vue';
import {route} from 'ziggy-js';
import loginTranslations from '../Login/login.json';

const {t} = useI18n({
  inheritLocale: true,
  messages: loginTranslations,
});

const form = useForm<{
  currentPassword: string;
  password: string;
  passwordConfirmation: string;
}>({
  currentPassword: '',
  password: '',
  passwordConfirmation: '',
});
</script>
<i18n>
{
  "en": {
    "page": {
      "title": "Change Password",
    },
  },
  "fr": {
    "page": {
      "title": "Changer le mot de passe",
    },
  },
}
</i18n>
