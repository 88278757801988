<template>
  <s-page-block>
    <s-page-header
      heading="Create Course Section"
      description="Adds missing sections to course from CSV. (No delete or modify)"
    />

    <form
      @submit.prevent="form.post(route('admin.courses.sections.store', {course: course.id}))"
      class="flex flex-col gap-5"
    >
      <s-file-upload v-model="form.file" label="Section CSV" id="file" name="file" />
      <s-btn type="submit" :processing="form.processing">Add Sections</s-btn>
    </form>
  </s-page-block>
</template>
<script setup lang="ts">
import SPageBlock from '../../../design-system/SPageBlock.vue';
import SPageHeader from '../../../design-system/SPageHeader.vue';
import SFileUpload from '../../../components/SFileUpload.vue';
import {useForm} from '@inertiajs/vue3';
import SBtn from '../../../design-system/SBtn.vue';
import {Course} from '../../../types/entities/course';
import {route} from 'ziggy-js';
import {useBreadcrumbs} from '../../../composables/useBreadcrumbs';

const props = defineProps<{course: Course}>();

const form = useForm({
  file: null,
});

useBreadcrumbs([
  {label: 'Courses', href: route('admin.courses.index')},
  {label: props.course.code, href: route('admin.courses.show', {course: props.course.id})},
]);
</script>
