<template>
  <task-form :form="form" :errors="errors">
    <s-textarea-field
      id="question"
      label="Question"
      description="The question to be answered by the student."
      v-model="form.ui.question"
      :error="errors?.['ui.question']"
      class="mb-4"
    />

    <s-form-list
      title="Options"
      addLabel="Add Option"
      :addItem="addOption"
      :items="form.ui.options"
      :removeItem="(item: any, index: number) => form.ui.options.splice(index, 1)"
      :sortable="true"
      placeholder="No Options Added"
      class="mb-3"
    >
      <template #item="{index}">
        <div class="p-5">
          <div class="flex-1 w-full flex flex-wrap gap-x-4 gap-y-3 justify-start mb-3">
            <div class="flex-1 max-w-[10rem] grow">
              <s-input-field
                :id="`options.${index}.key`"
                label="Key"
                v-model="form.ui.options[index].key"
                :error="errors?.[`ui.options.${index}.key`]"
              />
            </div>
            <div class="flex-1 grow-[2]">
              <s-input-field
                :id="`options.${index}.label`"
                label="Label"
                v-model="form.ui.options[index].label"
                :error="errors?.[`ui.options.${index}.label`]"
              />
            </div>
          </div>
          <s-textarea-field
            :id="`feedback.${form.ui.options[index].key}.text`"
            label="Feedback"
            v-model="form.feedback![form.ui.options[index].key]"
            :error="errors?.[`feedback.${form.ui.options[index].key}`]"
          />
        </div>
      </template>
    </s-form-list>

    <div class="flex mb-4">
      <s-checkbox
        id="randomizeOptionOrder"
        label="Randomize Options"
        v-model="form.ui.randomizeOptionOrder"
      />
    </div>

    <s-select-field
      id="correctOption"
      label="Correct Option"
      v-model="form.grader.correctOptionKey"
      :error="errors?.['grader.correctOptionKey']"
      :disabled="!form.ui.options.length"
      required
      class="mb-4"
    >
      <option v-if="!form.ui.options.length" value="">No options yet</option>
      <option v-else value="">Select an option</option>
      <option v-for="option in form.ui.options" :value="option.key">
        {{ option.label }}
      </option>
    </s-select-field>
  </task-form>
</template>
<script lang="ts" setup>
import STextareaField from '../../../design-system/STextareaField.vue';
import SInputField from '../../../design-system/SInputField.vue';
import SSelectField from '../../../design-system/SSelectField.vue';
import SFormList from '../../../design-system/SFormList.vue';
import SCheckbox from '../../../design-system/SCheckbox.vue';
import TaskForm from '../../../forms/TaskForm.vue';
import {useForm} from '@inertiajs/vue3';
import MultipleChoiceFormDto = App.DTOs.Tasks.MultipleChoiceFormDto;

const props = defineProps<{
  modelValue?: MultipleChoiceFormDto;
  isCopy?: boolean;
  errors?: Record<string, any>;
}>();

const form = useForm<MultipleChoiceFormDto>(
  () =>
    props.modelValue || {
      type: 'MultipleChoice',
      title: '',
      status: 'draft',
      feedback: {},
      ui: {
        question: '',
        options: [],
        randomizeOptionOrder: false,
      },
      grader: {
        correctOptionKey: null as any as string,
      },
      draftable: false,
      textbookLink: null,
      textbookLinkText: null,
      textbookLinkIcon: null,
      youtubeId: null,
    }
);

const addOption = () => {
  form.ui.options.push({key: '', label: ''});
};
</script>
