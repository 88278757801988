<template>
  <Head :title="t('page.title')"></Head>
  <div class="bg-gradient-to-b from-blue-50 via-transparent to-transparent">
    <div class="p-4 lg:p-8 max-w-xl mx-auto flex flex-col gap-5">
      <form
        aria-labelledby="form-title"
        @submit.prevent="form.post(route('auth.login'))"
        class="flex flex-col shadow-card rounded-lg"
      >
        <h1
          id="form-title"
          class="bg-gradient-to-t from-white/70 to-white/30 flex items-center justify-center p-6 rounded-t-lg border border-white/50 border-b-gray-100"
        >
          <s-logo type="image" class="logo h-16 lg:h-[4.5rem] w-auto" />
          <span class="sr-only">
            {{ t('page.title') }}
          </span>
        </h1>
        <div
          class="px-5 lg:px-8 py-5 lg:py-8 bg-gradient-to-t from-gray-50 to-white rounded-b-lg flex flex-col gap-5"
        >
          <div class="group">
            <label for="email" class="sr-only">{{ t('email') }}</label>
            <s-input-field
              id="email"
              :placeholder="t('email')"
              v-model="form.email"
              required
              :error="form.errors.email"
            >
              <template v-slot:prefix>
                <s-icon
                  name="email"
                  role="presentation"
                  class="opacity-70 group-hover:opacity-50 group-focus-within:text-blue-600 transition"
                />
              </template>
            </s-input-field>
          </div>
          <div class="group flex items-center gap-x-3 gap-y-2">
            <label for="password" class="sr-only">{{ t('password') }}</label>
            <s-input-field
              id="password"
              type="password"
              :placeholder="t('password')"
              v-model="form.password"
              required
              :error="form.errors.password"
            >
              <template v-slot:prefix>
                <s-icon
                  name="lock"
                  role="presentation"
                  class="opacity-70 group-hover:opacity-50 group-focus-within:text-blue-600 transition"
                />
              </template>
            </s-input-field>
          </div>
          <div class="flex flex-wrap justify-between gap-2">
            <s-checkbox
              v-model="form.remember"
              id="remember"
              name="remember"
              :label="t('form.remember')"
            />
            <s-link :href="route('password.request')" class="link-subtle flex items-center gap-1.5">
              <s-icon name="lock-question" class="opacity-70" />
              {{ t('links.forgot') }}
            </s-link>
          </div>
          <s-btn
            type="submit"
            color="primary"
            :processing="form.processing"
            class="mt-1"
            icon="login"
            icon-end
          >
            {{ t('actions.login') }}
          </s-btn>
        </div>
      </form>
      <div class="flex gap-x-3 gap-y-2 flex-wrap">
        <span class="flex-grow">
          <s-link :href="route('invitation.create')" class="link-subtle flex items-center gap-1.5">
            <s-icon name="clipboard-account" class="opacity-70" />
            {{ t('links.register') }}
          </s-link>
        </span>
        <s-link :href="route('support')" class="link-subtle flex items-center gap-1.5">
          <s-icon name="chat-question" class="opacity-70" />
          {{ t('links.help') }}
        </s-link>
      </div>
    </div>
  </div>
</template>
<script setup lang="ts">
import {Head, useForm} from '@inertiajs/vue3';
import {useI18n} from 'vue-i18n';
import {route} from 'ziggy-js';
import LoginDto = App.DTOs.Auth.LoginDto;
import SInputField from '../../design-system/SInputField.vue';
import SLink from '../../components/SLink.vue';
import SBtn from '../../design-system/SBtn.vue';
import SLogo from '../../design-system/SLogo.vue';
import SCheckbox from '../../design-system/SCheckbox.vue';
import UnauthorizedLayout from '../../layouts/UnauthorizedLayout.vue';
import SIcon from '../../design-system/SIcon.vue';
import loginTranslations from './login.json';

const {t} = useI18n({
  inheritLocale: true,
  messages: loginTranslations,
});

defineOptions({layout: UnauthorizedLayout});

type FormData = LoginDto;

const form = useForm<FormData>({
  email: '',
  password: '',
  remember: false,
});
</script>
<i18n>
{
  "en": {
    "page": {
      "title": "Login"
    }
  },
  "fr": {
    "page": {
      "title": "Connexion"
    }
  }
}
</i18n>
