import {v4 as uuidv4} from 'uuid';
import {defineComponentType} from './defineComponentType';
import TextBlockUiConfig = App.Entities.Tasks.Ui.TextBlockUiConfig;

export const textBlock = defineComponentType<TextBlockUiConfig, null>({
  translationKey: 'Text Block',
  icon: 'text',
  uiConfigFactory: () => ({
    uuid: uuidv4(),
    type: 'TextBlock',
    name: 'Text Block',
    text: '',
    part: null,
  }),
  componentGraderFactory: () => null,
});
