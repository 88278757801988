<template>
  <div class="dashboard">
    <section class="mt-4">
      <h2 class="heading lg mb-2">Grade Analytics</h2>
      <div class="flex gap-4">
        <!-- Grades Chart -->
        <div class="w-1/2 h-full flex">
          <v-chart class="chart flex-1" :option="averageGradesOption" autoresize />
        </div>

        <!-- Stat Cards -->
        <div class="w-1/2 flex flex-col gap-2">
          <s-stat-card
            class="flex-1 flex flex-col justify-center items-center text-center"
            label="Mean Grade Difference"
          >
            <s-help-button title="Mean Grade Difference">
              <div class="prose">
                <p>
                  The mean grade difference represents the net average of all grade differences,
                  where positive and negative values may cancel each other out. It describes how
                  accurate the evaluation results are to the original grading on average.
                </p>
              </div>
            </s-help-button>
            {{ gradeStats.meanGradeDifference.toFixed(2) }}%
          </s-stat-card>
          <s-stat-card
            class="flex-1 flex flex-col justify-center items-center text-center"
            label="Mean Absolute Grade Difference"
          >
            <s-help-button title="Mean Absolute Grade Difference">
              <div class="prose">
                <p>
                  The mean absolute grade difference measures the average size of grade differences,
                  ignoring whether they are positive or negative. It describes how precise the
                  evaluation results are to the original grading.
                </p>
              </div>
            </s-help-button>
            {{ gradeStats.meanAbsoluteGradeDifference.toFixed(2) }}%
          </s-stat-card>
        </div>
      </div>
    </section>

    <!-- Histogram Scatter Plot -->
    <section class="mt-4">
      <v-chart class="chart" :option="histogramScatterOption" autoresize />
    </section>
  </div>
</template>
<script setup lang="ts">
import {computed} from 'vue';
import {use} from 'echarts/core';
import {BarChart, ScatterChart} from 'echarts/charts';
import {
  DatasetComponent,
  GridComponent,
  TitleComponent,
  TooltipComponent,
  TransformComponent,
} from 'echarts/components';
import {CanvasRenderer} from 'echarts/renderers';
import VChart from 'vue-echarts';
import SStatCard from '../../../../components/SStatCard.vue';
import SHelpButton from '../../../../design-system/SHelpButton.vue';
import * as echarts from 'echarts';
import * as ecStat from 'echarts-stat';
import GradeStatistics = App.DTOs.EvaluationAnalytics.GradeStatisticsDto;

echarts.registerTransform((ecStat as any).transform.histogram);

use([
  CanvasRenderer,
  BarChart,
  ScatterChart,
  TooltipComponent,
  GridComponent,
  TitleComponent,
  DatasetComponent,
  TransformComponent,
]);

const props = defineProps<{
  gradeStatistics: GradeStatistics;
}>();

const gradeStats = computed(() => props.gradeStatistics || {});

const colorPalette = [
  '#2ecc71', // Evaluation
  '#33b5e5', // Reference
  '#e74c3c', // Std Error Bars
];

const errorData = computed(() => [
  [
    0,
    gradeStats.value.meanEvaluationGrade - gradeStats.value.stdErrorEvaluationGrade, // lower bound
    gradeStats.value.meanEvaluationGrade + gradeStats.value.stdErrorEvaluationGrade, // upper bound
  ],
  [
    1,
    gradeStats.value.meanReferenceGrade - gradeStats.value.stdErrorReferenceGrade, // lower bound
    gradeStats.value.meanReferenceGrade + gradeStats.value.stdErrorReferenceGrade, // upper bound
  ],
]);

const averageGradesOption = computed(() => ({
  title: {
    text: 'Mean Grades Comparison',
    left: 'center',
    top: 0,
  },
  tooltip: {
    trigger: 'item',
    formatter: '{b}: {c}%',
  },
  legend: {
    data: ['Standard Error'],
    top: 25,
    right: 20,
  },
  xAxis: {
    type: 'category',
    data: ['Evaluation', 'Reference (Original)'],
  },
  yAxis: {
    type: 'value',
    axisLabel: {
      formatter: '{value}',
    },
    name: 'Mean Grade (%)',
    nameLocation: 'center',
    nameGap: 30,
  },
  series: [
    {
      name: 'Mean Grade',
      data: [
        parseFloat(gradeStats.value.meanEvaluationGrade.toFixed(2)),
        parseFloat(gradeStats.value.meanReferenceGrade.toFixed(2)),
      ],
      type: 'bar',
      itemStyle: {
        color: (params: any) => colorPalette[params.dataIndex],
      },
      label: {
        show: true,
        position: 'top',
        formatter: '{c}%',
      },
    },
    {
      type: 'custom',
      name: 'Standard Error',
      itemStyle: {
        borderWidth: 2.5,
        color: colorPalette[2],
      },
      data: errorData.value,
      renderItem: function (params: any, api: any) {
        // https://echarts.apache.org/examples/en/editor.html?c=custom-error-bar
        const xValue = api.value(0);
        const highPoint = api.coord([xValue, api.value(2)]);
        const lowPoint = api.coord([xValue, api.value(1)]);
        const halfWidth = api.size([1, 0])[0] * 0.1;
        const style = api.style({
          stroke: api.visual('color'),
          fill: undefined,
        });
        return {
          type: 'group',
          children: [
            {
              type: 'line',
              transition: ['shape'],
              shape: {
                x1: highPoint[0] - halfWidth,
                y1: highPoint[1],
                x2: highPoint[0] + halfWidth,
                y2: highPoint[1],
              },
              style: style,
            },
            {
              type: 'line',
              transition: ['shape'],
              shape: {
                x1: highPoint[0],
                y1: highPoint[1],
                x2: lowPoint[0],
                y2: lowPoint[1],
              },
              style: style,
            },
            {
              type: 'line',
              transition: ['shape'],
              shape: {
                x1: lowPoint[0] - halfWidth,
                y1: lowPoint[1],
                x2: lowPoint[0] + halfWidth,
                y2: lowPoint[1],
              },
              style: style,
            },
          ],
        };
      },
      encode: {
        x: 0,
        y: [1, 2],
      },
      z: 100,
    },
  ],
}));

const gradeData = computed(
  () =>
    gradeStats.value.data.map(
      ({
        evaluation_grade,
        reference_grade,
        id,
      }: {
        evaluation_grade: number;
        reference_grade: number;
        id: number;
      }) => [Number(evaluation_grade), Number(reference_grade), Number(id)]
    ) as number[][]
);

const evaluationGradeData = computed(() =>
  gradeStats.value.data.map(({evaluation_grade}: {evaluation_grade: number}) =>
    Number(evaluation_grade)
  )
);
const referenceGradeData = computed(() =>
  gradeStats.value.data.map(({reference_grade}: {reference_grade: number}) =>
    Number(reference_grade)
  )
);

function customHistogram(data: number[]): {data: [string, number][]} {
  const binEdges = Array.from({length: 11}, (_, i) => i * 10); // [0, 10, 20, ..., 100]
  const bins = binEdges.slice(0, -1).map((edge, i) => `[${edge}-${binEdges[i + 1]})`);
  bins[bins.length - 1] = `[90-100]`; // Adjust last bin to be closed on both ends

  const counts = new Array(bins.length).fill(0);

  data.forEach((value) => {
    if (value < 0 || value > 100) return;
    const binIndex = Math.min(Math.floor(value / 10), 9); // Last bin index for 90-100
    counts[binIndex]++;
  });

  return {
    data: bins.map((bin, i) => [bin, counts[i]]),
  };
}

const evaluationBins = customHistogram(evaluationGradeData.value);
const referenceBins = customHistogram(referenceGradeData.value);

const histogramScatterOption = computed(() => ({
  title: {text: 'Grade Distributions', left: 'center'},
  dataset: [
    {source: gradeData.value}, // [Evaluation Grade, Reference Grade]
    {
      source: evaluationBins.data,
    },
    {
      source: referenceBins.data,
    },
  ],
  tooltip: {
    trigger: 'item',
    formatter: function (params: any) {
      if (params.seriesType === 'scatter') {
        const evaluationGrade = params.value[0];
        const referenceGrade = params.value[1];
        const taskResponseEvaluationId = params.value[2];
        return `Reference (Original) Grade: ${referenceGrade}<br>Evaluation Grade: ${evaluationGrade}<br>TaskResponseEvaluationId: ${taskResponseEvaluationId}`;
      } else if (params.seriesType === 'bar') {
        const gradeInterval = params.name;
        const count = params.data[1];
        return `Grade Interval: ${gradeInterval}<br>Count: ${count}`;
      }
      return '';
    },
  },
  grid: [
    {
      top: '50%',
      right: '50%',
    },
    {
      bottom: '52%',
      right: '50%',
    },
    {
      top: '50%',
      left: '52%',
    },
  ],
  xAxis: [
    {
      scale: true,
      gridIndex: 0,
      name: 'Evaluation Grade (%)',
      nameLocation: 'center',
      nameGap: 25,
      min: 0,
      max: 100,
      interval: 10,
    },
    {
      type: 'category',
      scale: true,
      axisTick: {show: false},
      axisLabel: {show: false},
      axisLine: {show: false},
      gridIndex: 1,
    },
    {
      scale: true,
      gridIndex: 2,
    },
  ],
  yAxis: [
    {
      gridIndex: 0,
      name: 'Reference Grade (%)',
      nameLocation: 'center',
      nameRotate: 90,
      nameGap: 30,
      min: 0,
      max: 100,
      interval: 10,
    },
    {
      gridIndex: 1,
    },
    {
      type: 'category',
      axisTick: {show: false},
      axisLabel: {show: false},
      axisLine: {show: false},
      gridIndex: 2,
    },
  ],
  series: [
    {
      name: 'Original Scatter',
      type: 'scatter',
      xAxisIndex: 0,
      yAxisIndex: 0,
      encode: {tooltip: [0, 1]},
      datasetIndex: 0,
    },
    {
      name: 'Evaluation',
      type: 'bar',
      xAxisIndex: 1,
      yAxisIndex: 1,
      barWidth: '99.3%',
      label: {
        show: true,
        position: 'top',
      },
      encode: {x: 0, y: 1, itemName: 4},
      datasetIndex: 1,
      color: colorPalette[0],
    },
    {
      name: 'Reference (Original)',
      type: 'bar',
      xAxisIndex: 2,
      yAxisIndex: 2,
      barWidth: '99.3%',
      label: {
        show: true,
        position: 'right',
      },
      encode: {x: 1, y: 0, itemName: 4},
      datasetIndex: 2,
      color: colorPalette[1],
    },
  ],
}));
</script>

<style scoped>
.dashboard {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.dashboard-layout {
  display: flex;
  flex-direction: row;
  gap: 1rem;
  height: 100%;
}

.chart {
  width: 100%;
  aspect-ratio: 4 / 3;
}
</style>
