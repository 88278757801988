<template>
  <Head title="Admin - Analytics"></Head>
  <div class="page-block">
    <s-page-header heading="Analytics" />
    <!-- Courses -->
    <section class="mt-4">
      <h2 class="heading lg mb-2">Courses</h2>
      <div class="stack horizontal gap-2">
        <dl class="w-full grid gap-5 grid-cols-1 sm:grid-cols-2 md:grid-cols-4">
          <s-stat-card label="Total Courses" :value="courses.total" />
          <s-stat-card label="Active Courses" :value="courses.active" />
          <s-stat-card label="Past" :value="courses.past" />
          <s-stat-card label="Upcoming" :value="courses.upcoming" />
        </dl>
      </div>
    </section>
    <!-- Payment Requests -->
    <section class="mt-4">
      <h2 class="heading lg mb-2">Payment Requests</h2>
      <p class="mb-3 italic">Note: Filtered to currently active courses.</p>
      <div class="stack horizontal gap-2">
        <dl class="w-full grid gap-5 grid-cols-1 sm:grid-cols-2 md:grid-cols-4">
          <s-stat-card label="Total">
            {{ paymentRequests.total }}
          </s-stat-card>
          <s-stat-card label="Pending">
            {{ paymentRequests.total - paymentRequests.paid - paymentRequests.exempted }} ({{
              (
                ((paymentRequests.total - paymentRequests.paid - paymentRequests.exempted) /
                  paymentRequests.total) *
                100
              ).toFixed(2)
            }}%)
          </s-stat-card>
          <s-stat-card label="Paid">
            {{ paymentRequests.paid }} ({{
              ((paymentRequests.paid / paymentRequests.total) * 100).toFixed(2)
            }}%)
          </s-stat-card>
          <s-stat-card label="Exempted">
            {{ paymentRequests.exempted }} ({{
              ((paymentRequests.exempted / paymentRequests.total) * 100).toFixed(2)
            }}%)
          </s-stat-card>
        </dl>
      </div>
    </section>
  </div>
</template>
<script setup lang="ts">
import SPageHeader from '../../../design-system/SPageHeader.vue';
import SStatCard from '../../../components/SStatCard.vue';
import {route} from 'ziggy-js';
import {Head} from '@inertiajs/vue3';
import {useBreadcrumbs} from '../../../composables/useBreadcrumbs';

defineProps<{
  courses: {
    total: number;
    past: number;
    active: number;
    upcoming: number;
  };
  paymentRequests: {
    total: number;
    paid: number;
    exempted: number;
  };
}>();

useBreadcrumbs([{label: 'Admin', href: route('admin.index')}]);
</script>
