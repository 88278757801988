import {headingRules} from './heading-rule';
import {blockquoteRules} from './blockquote-rule';

import {
  makeBlockMathInputRule,
  makeInlineMathInputRule,
  REGEX_BLOCK_MATH_DOLLARS,
  REGEX_INLINE_MATH_DOLLARS,
} from '@benrbray/prosemirror-math';
import {calculationTaskRule} from './calculation-task-rule';
import {Schema} from 'prosemirror-model';
import {InputRule} from 'prosemirror-inputrules';
import {imageRules} from './image-rule';

/**
 * Default input rules for any Stemble wysiwyg editor.
 *
 * @see https://prosemirror.net/docs/ref/#inputrules
 */
export const createInputRules: (schema: Schema) => InputRule[] = (schema) => [
  ...headingRules(schema),
  ...blockquoteRules(schema),
  makeInlineMathInputRule(REGEX_INLINE_MATH_DOLLARS, schema.nodes.math_inline),
  makeBlockMathInputRule(REGEX_BLOCK_MATH_DOLLARS, schema.nodes.math_display),
  ...calculationTaskRule(schema),
  ...imageRules(schema),
];
