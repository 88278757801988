<template>
  <s-button-toggle id="status" :options="options" />
</template>
<script setup lang="ts">
import SButtonToggle from '../design-system/SButtonToggle.vue';
import TaskStatus = App.Enums.TaskStatus;

type TaskStatusOption = {
  value: TaskStatus;
  label: string;
};
const options: TaskStatusOption[] = [
  {value: 'draft', label: 'Draft'},
  {value: 'ready_for_review', label: 'Ready for Review'},
  {value: 'published', label: 'Published'},
  {value: 'archived', label: 'Archived'},
];
</script>
