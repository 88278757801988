<template>
  <Head :title="`${t('page.title')} - ${assignment.name} - ${course.code}`" />
  <s-nav-header :links="navLinks" :title="assignment.name" class="mb-3" />
</template>
<script setup lang="ts">
import SNavHeader from '../design-system/SNavHeader.vue';
import {Head, usePage} from '@inertiajs/vue3';
import {Course} from '../types/entities/course';
import {route} from 'ziggy-js';
import {useI18n} from 'vue-i18n';
import {computed} from 'vue';

const props = defineProps<{
  course: Course;
  assignment: {id: number; name: string};
  can: {
    update: boolean;
  };
}>();

const {t} = useI18n({
  useScope: 'local',
  inheritLocale: true,
});

interface NavLink {
  label: string;
  href: string;
  icon?: string;
}

const navLinks = computed<NavLink[]>(() => {
  const editLink = {
    label: t('nav.general'),
    href: route('courses.assignment.edit', {
      course: props.course.id,
      assignment: props.assignment.id,
    }),
    icon: 'pencil',
  };

  const assignLink = {
    label: t('nav.settings'),
    href: route('courses.assignment.settings.edit', {
      course: props.course.id,
      assignment: props.assignment.id,
    }),
    icon: 'cog',
  };

  const editTasksLink = {
    label: t('nav.tasks'),
    href: route('courses.assignments.tasks.index', {
      course: props.course.id,
      assignment: props.assignment.id,
    }),
    icon: 'clipboard-edit-outline',
  };

  const links = [];

  if (props.can.update) {
    links.push(editLink);
    links.push(editTasksLink);
  }

  links.push(assignLink);

  return links;
});

const page = usePage();
const path = computed(() => page.url.split('?')[0]);

const isActive = (link: NavLink): boolean => link.href.toString().endsWith(path.value);
</script>
<i18n>
{
  "en": {
    "page": {
      "title": "Edit Assignment"
    },
    "nav": {
      "general": "General",
      "settings": "Settings",
      "tasks": "Tasks"
    }
  },
  "fr": {
    "page": {
      "title": "Modifier le devoir"
    },
    "nav": {
      "general": "Général",
      "settings": "Paramètres",
      "tasks": "Tâches"
    }
  }
}
</i18n>
