<template>
  <div :class="[classes.tabPane, 'gap-5']">
    <template v-if="numIssues > 0">
      <div class="flex flex-col gap-2">
        <p class="text-lg font-medium">
          {{ t('errorsDetected') }}
        </p>
        <p>
          {{ t('updateErrors') }}
        </p>
      </div>
      <!-- Errors -->
      <s-details
        v-if="!!invalidChanges.length"
        detailsClass="bg-gradient-to-r from-red-50 to-red-50/50"
      >
        <template #summary>
          <h3 class="heading md flex items-center gap-1 text-red-600">
            <s-icon name="alert" size="20" class="opacity-70" />
            <span class="font-black">{{ invalidChanges.length }}</span>
            {{ t('errors') }}
          </h3>
        </template>
        <div class="flex flex-col shadow-card-soft rounded-lg">
          <diff-staff-error v-for="change in invalidChanges" :change="change" />
        </div>
      </s-details>

      <diff-staff-conflict-set
        v-if="Object.keys(conflictingChanges).length"
        :conflicting-changes="conflictingChanges"
      />
    </template>
    <template v-else>
      <p>{{ t('csvNoErrors') }}</p>
    </template>
  </div>
  <div :class="classes.tabFooter">
    <s-btn color="secondary" icon="arrow-left" @click="$emit('back')" class="flex-1 max-w-[16rem]">
      {{ t('actions.back') }}
    </s-btn>
    <s-btn
      type="submit"
      color="primary"
      :disabled="form.processing"
      :processing="form.processing"
      class="flex-1 max-w-[16rem]"
      @click="$emit('submit')"
    >
      {{ t('actions.continue') }}
      <s-icon name="arrow-right" class="opacity-70" size="18" />
    </s-btn>
  </div>
</template>
<script setup lang="ts">
import SBtn from '../../../design-system/SBtn.vue';
import {InertiaForm} from '@inertiajs/vue3';
import {useI18n} from 'vue-i18n';
import RosterFormDto = App.DTOs.Roster.RosterFormDto;
import RosterChangeDto = App.DTOs.Roster.RosterChangeDto;
import DiffStaffError from './DiffStaffError.vue';
import DiffStaffConflictSet from './DiffStaffConflictSet.vue';
import SIcon from '../../../design-system/SIcon.vue';
import SDetails from '../../../design-system/SDetails.vue';

defineProps<{
  form: InertiaForm<RosterFormDto>;
  classes: any;
  numIssues: number;
  invalidChanges: RosterChangeDto[];
  conflictingChanges: Record<string, RosterChangeDto[]>;
}>();

defineEmits(['submit', 'back']);

const {t} = useI18n({
  inheritLocale: true,
  useScope: 'local',
});
</script>
<i18n>
{
  "en": {
    "errorsDetected": "The following errors have been detected in the CSV file.",
    "updateErrors": "Please update the CSV to correct these errors, or continue to apply the valid changes.",
    "errors": "Errors",
    "csvNoErrors": "Your CSV is perfect! No problems at all!"
  },
  "fr": {
    "errorsDetected": "Les erreurs suivantes ont été détectées dans le fichier CSV.",
    "updateErrors": "Veuillez mettre à jour le fichier CSV pour corriger ces erreurs, ou continuez pour appliquer les modifications valides.",
    "errors": "Erreurs",
    "csvNoErrors": "Votre CSV est parfait ! Aucun problème !"
  }
}
</i18n>
