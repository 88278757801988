<template>
  <Head :title="t('page.title', {course: course.code})" />
  <edit-course-header :course="course" />
  <s-page-block size="md" class="flex-1">
    <template v-if="can.create">
      <s-form-inline
        v-model="newForm.name"
        :placeholder="t('form.newSectionName')"
        @submit="
          newForm.post(route('courses.sections.store', {course: course.id}), {
            onSuccess() {
              newForm.reset();
            },
          })
        "
        :submitLabel="t('actions.add')"
        :processing="newForm.processing"
        icon="plus"
        class="mb-3"
      />
    </template>
    <table class="table">
      <thead>
        <tr>
          <th class="w-0">{{ t('table.id') }}</th>
          <th class="w-full">{{ t('table.name') }}</th>
          <th>{{ t('table.numParticipants') }}</th>
          <th v-if="can.delete" class="w-0"></th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="section in sections">
          <td>{{ section.id }}</td>
          <td>
            <form
              v-if="editingId === section.id && can.update"
              @submit.prevent="
                existingSectionForms[section.id].put(
                  route('courses.sections.update', {course: course.id, section: section.id}),
                  {
                    onSuccess: () => (editingId = null),
                  }
                )
              "
              class="flex flex-row gap-1.5 items-center -my-1"
            >
              <s-input-field
                :id="`section-${section.id}-name`"
                v-model="existingSectionForms[section.id].name"
                :error="existingSectionForms[section.id].errors.name"
                input-class="text-sm"
              />
              <s-btn
                type="button"
                icon="close"
                color="white"
                size="md"
                :disabled="existingSectionForms[section.id].processing"
                @click.prevent="editingId = null"
              >
                <span class="sr-only">
                  {{ t('form.cancel') }}
                </span>
              </s-btn>
              <s-btn
                type="submit"
                icon="floppy"
                size="md"
                :processing="existingSectionForms[section.id].processing"
              >
                <span class="sr-only">
                  {{ t('form.save') }}
                </span>
              </s-btn>
            </form>
            <button
              v-else
              @click="editingId = section.id"
              class="flex items-center gap-0.5 link link-subtle"
            >
              <s-icon name="pencil" :aria-label="t('table.rename')" size="20" class="opacity-70" />
              <span class="">
                {{ section.name }}
              </span>
            </button>
          </td>
          <td>
            <s-link
              :href="
                route('courses.participants.index', {
                  course: course.id,
                  _query: {
                    sectionId: section.id,
                  },
                })
              "
              class="flex items-center gap-0.5 link link-subtle"
            >
              <s-icon
                name="account-multiple"
                :aria-label="t('actions.view')"
                size="20"
                class="opacity-70"
              />
              <span class="">
                {{ section.numParticipants }}
              </span>
            </s-link>
          </td>
          <td v-if="can.delete">
            <s-tooltip v-if="section.numParticipants">
              <template #tooltip>{{ t('table.cannotDelete') }}</template>
              <s-btn size="sm" color="red-light" icon="trash-can" disabled>
                {{ t('actions.delete') }}
              </s-btn>
            </s-tooltip>
            <s-btn
              v-else
              size="sm"
              color="red-light"
              icon="trash-can"
              method="delete"
              :href="
                route('courses.sections.destroy', {
                  course: course.id,
                  section: section.id,
                })
              "
              class="w-fit"
            >
              {{ t('actions.delete') }}
            </s-btn>
          </td>
        </tr>
        <tr v-if="!sections.length">
          <td colspan="4" class="text-center">{{ t('table.noData') }}</td>
        </tr>
      </tbody>
    </table>
  </s-page-block>
</template>
<script setup lang="ts">
import {useI18n} from 'vue-i18n';
import EditCourseHeader from '../../layouts/EditCourseHeader.vue';
import SPageBlock from '../../design-system/SPageBlock.vue';
import {Course} from '../../types/entities/course';
import {useBreadcrumbs} from '../../composables/useBreadcrumbs';
import SInputField from '../../design-system/SInputField.vue';
import SBtn from '../../design-system/SBtn.vue';
import STooltip from '../../design-system/STooltip.vue';
import SIcon from '../../design-system/SIcon.vue';
import SFormInline from '../../design-system/SFormInline.vue';
import SLink from '../../components/SLink.vue';
import {Head, InertiaForm, useForm} from '@inertiajs/vue3';
import {route} from 'ziggy-js';
import {ref, watch} from 'vue';

const props = defineProps<{
  course: Course;
  sections: Array<{
    id: number;
    name: string;
    numParticipants: number;
  }>;
  can: {
    create: boolean;
    update: boolean;
    delete: boolean;
  };
}>();

const {t} = useI18n();

useBreadcrumbs([
  {
    label: props.course.code,
    href: route('courses.show', {course: props.course.id}),
  },
]);

const newForm = useForm({
  name: '',
});

const editingId = ref<number | null>(null);
const existingSectionForms = ref<{
  [key: number]: InertiaForm<{id: number; name: string}>;
}>({});

/*
 * Anytime the Section list changes, loop through the sections and
 * create its form if one doesn't already exist.
 */
watch(
  () => props.sections,
  () => {
    props.sections.forEach((section) => {
      if (!existingSectionForms.value[section.id]) {
        existingSectionForms.value[section.id] = useForm({
          id: section.id,
          name: section.name,
        });
      }
    });
  },
  {immediate: true}
);
</script>
<i18n>
{
  "en": {
    "page": {
      "title": "Edit Sections - {course}"
    },
    "form": {
      "newSectionName": "New Section Name"
    },
    "table": {
      "id": "ID",
      "name": "Name",
      "numParticipants": "Number of Participants",
      "noData": "No sections have been added yet.",
      "rename": "Rename",
      "cannotDelete": "Cannot delete a section until all participants are removed."
    }
  },
  "fr": {
    "page": {
      "title": "Modifier les sections - {course}"
    },
    "form": {
      "newSectionName": "Nom de la section nouvelle"
    },
    "table": {
      "id": "ID",
      "name": "Nom",
      "numParticipants": "Nombre de participants",
      "noData": "Aucune section n'a été ajoutée.",
      "rename": "Renommer",
      "cannotDelete": "Impossible de supprimer une section tant que tous les participants ne sont pas supprimés."
    }
  }
}
</i18n>
