<template>
  <Head
    :title="
      t('page.title', {
        course: course.code,
        assignment: assignment.name,
        student: student.email,
      })
    "
  ></Head>
  <s-page-block size="sm">
    <s-page-header :heading="t('page.header')" class="mb-1" />
    <p class="mb-3">
      {{
        t('page.description', {
          course: course.code,
          assignment: assignment.name,
          studentFirst: student.firstName,
          studentLast: student.lastName,
          studentEmail: student.email,
        })
      }}
    </p>
    <form @submit.prevent="submit">
      <div class="w-full flex gap-4 mb-4">
        <s-input-field
          id="localHours"
          v-model.number="localForm.hours"
          type="number"
          :suffix="t('time.hours')"
          :aria-label="t('form.extensionHours')"
          min="0"
        />
        <s-input-field
          id="localMinutes"
          v-model.number="localForm.minutes"
          :error="form.errors.minutes"
          type="number"
          :suffix="t('time.minutes')"
          :aria-label="t('form.extensionMinutes')"
          min="0"
        />
      </div>
      <div class="flex-1 flex flex-wrap items-start justify-between gap-3 pt-1">
        <s-btn
          class="flex-1 w-full"
          color="white"
          icon="close"
          :href="
            route('courses.assignments.students.timer_extensions.index', [
              props.course.id,
              props.assignment.id,
              props.student.id,
            ])
          "
        >
          {{ t('form.cancel') }}
        </s-btn>
        <s-btn
          type="submit"
          class="flex-1 justify-self-end"
          icon="timer-outline"
          :processing="form.processing"
        >
          {{ t('actions.grant') }}
        </s-btn>
      </div>
    </form>

    <!-- <h2>Previous Extension</h2>
    <ul v-if="extensions.length">
      <li v-for="extension in extensions">
        {{ extension.minutes }} minutes granted by {{ extension.grantedBy.fullName }}.
        <span v-if="extension.claimed">Claimed</span>
        <span v-else>Unclaimed</span>
      </li>
    </ul>
    <p v-else>Got no extensions.</p> -->
  </s-page-block>
</template>
<script setup lang="ts">
import {ref, watch} from 'vue';
import {Head, useForm} from '@inertiajs/vue3';
import {Assignment} from '../../types/entities/assignment';
import {Course} from '../../types/entities/course';
import {User} from '../../types/entities/user';
import UserAssignmentTimerDto = App.DTOs.UserAssignmentTimerDto;
import SPageBlock from '../../design-system/SPageBlock.vue';
import SPageHeader from '../../design-system/SPageHeader.vue';
import {useI18n} from 'vue-i18n';
import SBtn from '../../design-system/SBtn.vue';
import SInputField from '../../design-system/SInputField.vue';
import {route} from 'ziggy-js';

const props = defineProps<{
  course: Course;
  assignment: Assignment;
  student: User;
  timer: UserAssignmentTimerDto;
  extensions: any[];
  redirect?: string;
}>();

const {t} = useI18n({
  useScope: 'local',
  inheritLocale: true,
});

const form = useForm({
  minutes: 0,
});

const localForm = ref({
  hours: 0,
  minutes: 0,
});

watch(
  localForm,
  (value) => {
    form.minutes = Math.floor(value.hours * 60) + Math.floor(value.minutes);
  },
  {deep: true}
);

const submit = () => {
  form.post(
    route('courses.assignments.students.timer_extensions.store', {
      course: props.course.id,
      assignment: props.assignment.id,
      user: props.student.id,
      _query: {
        redirect: props.redirect,
      },
    })
  );
};
</script>
<i18n>
{
  "en": {
    "page": {
      "title": "Grant Timer Extension - {student} - {assignment} - {course}",
      "header": "Grant a Timer Extension",
      "description": "Grant a timer extension to {studentFirst} {studentLast} ({studentEmail}) for {assignment} in {course}."
    },
    "form": {
      "extensionHours": "Extension Hours",
      "extensionMinutes": "Extension Minutes"
    },
    "actions": {
      "grant": "Grant Timer Extension"
    }
  },
  "fr": {
    "page": {
      "title": "Accorder une prolongation de temps - {student} - {assignment} - {course}",
      "header": "Accorder une prolongation de temps",
      "description": "Accorder une prolongation de temps à {studentFirst} {studentLast} ({studentEmail}) pour {assignment} dans {course}."
    },
    "form": {
      "extensionHours": "Heures de prolongation",
      "extensionMinutes": "Minutes de prolongation"
    },
    "actions": {
      "grant": "Accorder une prolongation de temps"
    }
  }
}
</i18n>
