<template>
  <Head title="AI Analytics - Task Usage History"></Head>
  <s-page-block size="md">
    <s-page-header heading="Task History" class="pb-4">
      <template #actions>
        <s-btn
          v-if="task.id"
          class="flex-1 max-w-[14rem]"
          color="blue-light"
          icon="eye"
          :href="route('admin.tasks.show', {task: task.id})"
        >
          Task Preview
        </s-btn>
      </template>
    </s-page-header>

    <section class="stack vertical gap-3">
      <h2 class="heading lg mb-2">Task Used in Assignments:</h2>
      <s-link
        v-for="assignment in assignments?.data"
        :href="
          route('admin.ai-analytics.tasks.assignment', {task: task.id, assignment: assignment.id})
        "
        class="card padded stack horizontal align-between valign-center gap-3"
      >
        <div class="stack vertical gap-2 w-full">
          <div>
            <div class="text-sm text-slate-600">
              {{ assignment.institutionCode }} - {{ assignment.courseName }}
            </div>
            <div class="text-lg">
              <strong>{{ assignment.courseCode }}:</strong>
              {{ assignment.name }}
            </div>
          </div>
        </div>
        <div class="stack horizontal gap-4">
          <s-icon name="chevron-right" />
        </div>
      </s-link>
      <s-paginator :paginator="assignments" />
    </section>

    <section class="mt-4">
      <h2 class="heading lg mb-2">Historic Usage by Task</h2>
      <div class="card padded mt-8">
        <task-grade-averages-multiple-step-line-chart
          v-if="rollingAverages.length > 0"
          :rolling-averages="rollingAverages"
        />
        <span v-else>No Grading Data Exists for this Task</span>
      </div>
      <div class="card padded mt-8">
        <task-learning-rate-charts
          v-if="gradesByAttempts"
          :grades-by-attempts="gradesByAttempts"
          :task="null"
          :assignment="null"
        />
        <span v-else>No Learning Rate Exists for this Task</span>
      </div>
      <div class="card padded mt-8">
        <task-token-usage-history-multiple-line-chart
          v-if="tokenUsage.length > 0"
          :token-usage="tokenUsage"
        />
        <span v-else>No Token Usage Tracking Exists for this Task</span>
      </div>
    </section>
  </s-page-block>
</template>

<script setup lang="ts">
import {route} from 'ziggy-js';
import {Head, router} from '@inertiajs/vue3';
import {useBreadcrumbs} from '../../../../composables/useBreadcrumbs';
import TaskTokenUsageHistoryMultipleLineChart from './TaskTokenUsageHistoryMultipleLineChart.vue';
import TaskMistakeLabelsVisualizationScatterChart from './TaskMistakeLabelsVisualizationScatterChart.vue';
import SBtn from '../../../../design-system/SBtn.vue';
import {Task} from '../../../../types/entities/tasks';
import axios from 'axios';
import {ref, Ref} from 'vue';
import SIcon from '../../../../design-system/SIcon.vue';
import SLink from '../../../../components/SLink.vue';
import SPaginator from '../../../../design-system/SPaginator.vue';
import {LaravelPaginator} from '../../../../types/laravel-paginator';
import TaskGradeAveragesMultipleStepLineChart from './TaskGradeAveragesMultipleStepLineChart.vue';
import TaskLearningRateCharts from './TaskLearningRateCharts.vue';
import SInputField from '../../../../design-system/SInputField.vue';
import SPageBlock from '../../../../design-system/SPageBlock.vue';
import SPageHeader from '../../../../design-system/SPageHeader.vue';

const props = defineProps<{
  task: Task;
  assignments: LaravelPaginator<{
    id: number;
    name: string;
    courseName: string;
    courseCode: string;
    institutionCode: string;
  }>;
  tokenUsage: {
    assignmentId: number;
    assignmentName: string;
    dataPoints: {
      day: string;
      totalTokens: number;
    }[];
  }[];
  rollingAverages: {
    day: string;
    new_grades: number;
    avg_final_grade: number;
    avg_accepted_grade: number;
    avg_grade_pre_override: number;
    avg_override_grade_difference: number;
    pie_data: {name: string; value: number}[];
  }[];
  gradesByAttempts: {
    [maxAttempts: string]: {
      example_user_id: number;
      number_of_students: number;
      final_avg_grade: number;
      attempt_data: {
        attempt_number: number;
        avg_grade: number;
      }[];
    };
  };
}>();

const numberOfClusters = ref(5);
const fetchingClusters = ref(false);
const clusterData: Ref<
  {
    title: string;
    count: number;
    points?: {
      embedding: number[];
      mistake_label: string;
    }[];
  }[]
> = ref([]);

async function calculateMistakeLabelClusters() {
  fetchingClusters.value = true;
  try {
    const response = await axios.post(
      route('admin.ai-analytics.tasks.cluster', {
        task: props.task.id,
        numberOfClusters: numberOfClusters.value,
      })
    );
    clusterData.value = response.data;
  } finally {
    fetchingClusters.value = false;
  }
}

useBreadcrumbs([
  {label: 'Ai Analytics', href: route('admin.ai-analytics.index')},
  {label: 'Tasks', href: route('admin.ai-analytics.tasks.index')},
]);
</script>
