import {ref, watch} from 'vue';

export function useForceRerender() {
  const show = ref(true);

  watch(
    show,
    (newValue) => {
      if (!newValue) {
        show.value = true;
      }
    },
    {flush: 'post'}
  );

  const forceRerender = () => (show.value = false);

  return {forceRerender, show};
}
