import {InputRule, wrappingInputRule} from 'prosemirror-inputrules';
import {Schema} from 'prosemirror-model';

/**
 * InputRule for creating Blockquotes using Markdown syntax.
 *
 * Example:
 * - `>[SPACE]` becomes a `blockquote`
 */
export const blockquoteRules: (schema: Schema) => InputRule[] = (schema) => {
  if (!schema.nodes.blockquote) {
    return [];
  }

  return [
    // Markdown Syntax
    wrappingInputRule(/^> $/, schema.nodes.blockquote),
  ];
};
