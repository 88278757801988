<template>
  <s-input-field
    id="label"
    label="Label"
    description="The label that will be presented above the file input."
    v-model="label"
    :error="errors?.label"
    class="mb-4"
  />
  <s-input-field
    id="fileUploadAllowedExtensions"
    label="Allowed File Extensions"
    description="A comma separated list of allowed file extensions. Leave this blank to allow all file types."
    v-model="acceptedFileExtensions"
    :error="errors?.acceptedFileExtensions"
  />
  <div class="flex flex-wrap gap-4">
    <s-input-field
      id="maxNumberOfFiles"
      v-model.number="maxNumberOfFiles"
      type="number"
      label="Maximum Number of Files"
      tooltip="Leave blank for unlimited."
      :error="errors?.maxNumberOfFiles"
    />
    <s-input-field
      id="maxFileSize"
      v-model.number="maxFileSize"
      type="number"
      label="Maximum File Size"
      suffix="MB"
      :error="errors?.maxFileSize"
    />
  </div>
  <s-textarea-field
    id="noFilesProvidedFileUploadFeedback"
    v-model="noFilesProvidedFeedback"
    label="No files provided feedback"
    :error="errors?.noFilesProvidedFeedback"
  />
  <s-textarea-field
    id="filesProvidedUploadFeedback"
    v-model="filesProvidedFeedback"
    label="Files provided feedback"
    :error="errors?.filesProvidedFeedback"
  />
  <s-input-field
    id="multipleChoicePointsAvailable"
    v-model.number="points"
    type="number"
    label="Points Available (optional)"
    tooltip="When this is not set, the question will be worth 1 point."
    :error="errors?.points"
  />
</template>

<script setup lang="ts">
import {useVModels} from '@vueuse/core';
import SInputField from '../../../../design-system/SInputField.vue';
import STextareaField from '../../../../design-system/STextareaField.vue';
import FileUploadUiConfig = App.Entities.Tasks.Ui.FileUploadUiConfig;

const props = defineProps<{
  label: string | null;
  uiConfig: FileUploadUiConfig;
  points: number | null;
  filesProvidedFeedback: string | null;
  noFilesProvidedFeedback: string | null;
  acceptedFileExtensions: string | null;
  maxNumberOfFiles: number | null;
  maxFileSize: number | null;
  errors?: Record<string, any>;
}>();

const emit = defineEmits([
  'update:label',
  'update:acceptedFileExtensions',
  'update:maxNumberOfFiles',
  'update:maxFileSize',
  'update:points',
  'update:filesProvidedFeedback',
  'update:noFilesProvidedFeedback',
]);
const {
  label,
  acceptedFileExtensions,
  maxNumberOfFiles,
  maxFileSize,
  points,
  filesProvidedFeedback,
  noFilesProvidedFeedback,
} = useVModels(props, emit);
</script>
