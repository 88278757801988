<template>
  <div
    class="p-2 rounded-md bg-gray-100 border border-gray-200"
    :class="{'border-red-500': Object.keys(node.attrs.errors).length > 0}"
  >
    <div class="flex gap-2 items-end">
      <s-input-field
        v-model="test"
        :id="node.attrs.ui.uuid"
        :label="node.attrs.ui.question"
        :prefix="node.attrs.ui.prependText"
        :suffix="node.attrs.ui.appendText"
        :placeholder="node.attrs.ui.placeholder"
      />

      <s-btn type="button" color="gray" @click="editorModalOpen = true" icon="cog">
        {{ t('actions.edit') }}
      </s-btn>
    </div>
    <div v-if="Object.keys(node.attrs.errors).length > 0" class="text-sm text-red-600 mt-1">
      <div v-for="(error, key) in node.attrs.errors" :key="key">{{ error }}</div>
    </div>

    <s-drawer
      :open="editorModalOpen"
      @close="editorModalOpen = false"
      :title="t('modal.title')"
      :message="t('modal.message')"
    >
      <div class="flex flex-col gap-4 p-4 w-full overflow-scroll">
        <single-calculation
          :question="node.attrs.ui.question"
          :prependText="node.attrs.ui.prependText"
          :appendText="node.attrs.ui.appendText"
          :calculations="node.attrs.grader.calculations"
          :subexpressions="node.attrs.grader.subexpressions"
          :tolerance="node.attrs.grader.tolerance"
          :fallbackFeedback="node.attrs.grader.fallbackFeedback"
          :errors="node.attrs.errors"
        />
      </div>
    </s-drawer>
  </div>
</template>
<script setup lang="ts">
import {Node} from 'prosemirror-model';
import SInputField from '../../../design-system/SInputField.vue';
import SDrawer from '../../../design-system/SDrawer.vue';
import SBtn from '../../../design-system/SBtn.vue';
import {ref} from 'vue';
import {EditorView} from 'prosemirror-view';
import SingleCalculation from '../../../pages/Task/DataDrivenTask/ComponentForms/SingleCalculation.vue';
import {useI18n} from 'vue-i18n';

const props = defineProps<{
  node: Node;
  view: EditorView;
  getPos: () => number | undefined;
}>();

const {t} = useI18n();

const test = ref('');

const editorModalOpen = ref(false);

function updateQuestion(value: any) {
  const pos = props.getPos();

  if (pos === undefined) {
    return;
  }

  props.view.dispatch(
    props.view.state.tr.setNodeMarkup(pos, null, {
      ...props.node.attrs,
      ui: {
        ...props.node.attrs.ui,
        question: value,
      },
    })
  );
}
</script>
<i18n>
{
  "en": {
    "modal": {
      "title": "Edit Calculation Question",
      "message": "Edit the calculation question and its grading settings."
    }
  },
  "fr": {
    "modal": {
      "title": "Modifier la question de calcul",
      "message": "Modifiez la question de calcul et ses paramètres de notation."
    }
  }
}
</i18n>
