import {TaskResponse} from '../types/entities/tasks';
import {compareAsc} from 'date-fns';
import {AssignmentExtension} from '../types/entities/assignment-extension';

type ResponseTimestampable = Pick<
  TaskResponse,
  'isSelfSubmitted' | 'submittedAt' | 'lastSavedAt' | 'createdAt'
>;

/**
 * Get the most relevant submission timestamp for a response. If not self-submitted, it will return the
 * last saved at or created at date.
 * @param response
 */
export const getResponseTimestamp = (response: ResponseTimestampable | null) => {
  if (!response) {
    return '';
  }

  // For responses submitted by the user themselves, we want the submitted at date
  if (response.isSelfSubmitted && response.submittedAt) {
    return response.submittedAt;
  }

  // When a response is not yet submitted or was submitted on someone's behalf, we want the last time the
  // response's owning user stored the response so instructors can better determine when the work was completed
  return response.lastSavedAt || response.createdAt;
};

export const isResponseLate = (
  dueDate: string | null | undefined,
  response: ResponseTimestampable | null,
  extension?: AssignmentExtension | null
) => {
  if (!response || !dueDate) {
    return false;
  }

  const responseDate = getResponseTimestamp(response);

  const effectiveDueDate = extension?.newDueDate ?? dueDate;

  return compareAsc(new Date(responseDate), new Date(effectiveDueDate)) === 1;
};
