<template>
  <div class="badge-group flex items-stretch">
    <slot />
  </div>
</template>
<script setup lang="ts"></script>
<style scoped>
.badge-group > :deep(*) {
  @apply shrink-0;

  /* Overriding base s-badge styles */

  &:not(:first-child):not(:last-child) {
    @apply !rounded-none;
  }

  &:last-child:not(:first-child) {
    @apply !rounded-l-none;
  }

  &:first-child:not(:last-child) {
    @apply !rounded-r-none;
  }

  &:not(:first-child) {
    @apply !border-l-0;
  }

  &:first-child {
    @apply !rounded-l-full;
  }

  &:last-child {
    @apply !rounded-r-full;
  }
}
</style>
