<template>
  <div
    class="animate-toast rounded-lg overflow-hidden shadow-xl font-medium text-base flex items-stretch pointer-events-auto"
    :role="role || roles[type]"
  >
    <div class="w-2" :class="classes.crust[type]"></div>
    <div
      class="flex-1 px-4 py-3 border-2 rounded-r-lg flex items-center gap-2"
      :class="classes.content[type]"
    >
      <s-icon :name="icon || icons[type]" size="22" :class="classes.icon[type]" />
      <span class="flex-1">
        <slot />
      </span>
      <button
        class="p-2 -m-2 cursor-pointer opacity-50 hover:opacity-100 transition-opacity ease-out duration-150"
        @click="handleDismiss"
      >
        <s-icon name="close" size="20" />
        <span class="sr-only">{{ t('actions.dismiss') }}</span>
      </button>
    </div>
  </div>
</template>
<script setup lang="ts">
import {withDefaults, defineProps, defineEmits} from 'vue';
import SIcon from '../design-system/SIcon.vue';
import {useI18n} from 'vue-i18n';

type AlertType = 'success' | 'warning' | 'error';

const {t} = useI18n({
  useScope: 'local',
  inheritLocale: true,
});

withDefaults(
  defineProps<{
    type?: AlertType;
    role?: string;
    icon?: string;
  }>(),
  {
    type: 'success' as AlertType,
  }
);

const emit = defineEmits<{
  (e: 'dismiss'): void;
}>();

const handleDismiss = () => {
  emit('dismiss');
};

const classes: Record<'crust' | 'content' | 'icon', Record<AlertType, string>> = {
  crust: {
    success: 'bg-green-400',
    warning: 'bg-orange-400',
    error: 'bg-red-400',
  },
  content: {
    success: 'bg-green-100 border-green-200 text-green-700',
    warning: 'bg-orange-100 border-orange-200 text-orange-700',
    error: 'bg-red-100 border-red-300 text-red-700',
  },
  icon: {
    success: 'text-green-500',
    warning: 'text-orange-500',
    error: 'text-red-500',
  },
};

const icons: Record<AlertType, string> = {
  success: 'check-bold',
  warning: 'alert',
  error: 'alert',
};

// Success Flash Message

//  The role="status" attribute is used to indicate that the
//  flash is a status message that is not important enough
//  to interrupt the user's work

//  https://developer.mozilla.org/en-US/docs/Web/Accessibility/ARIA/Roles/status_role

// Warnings & Error  Flash Message

//  The role="alert" attribute is used to indicate that the
//  flash is an important message that needs to be communicated
//  to the user immediately.

//  https://developer.mozilla.org/en-US/docs/Web/Accessibility/ARIA/Roles/alert_role

const roles: Record<AlertType, string> = {
  success: 'status',
  warning: 'alert',
  error: 'alert',
};
</script>
