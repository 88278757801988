<template>
  <div :class="`relative flex items-center gap-1.5 justify-between ${sizeClasses[size].text}`">
    <s-input
      v-bind="$attrs"
      v-model="model"
      :required="required"
      :class="{
        [inputClass || '']: !!inputClass,
      }"
      :size="size"
    />
    <s-tooltip v-if="error" :text="error">
      <div
        role="alert"
        class="flex items-center justify-start gap-1 mt-0.5 text-sm font-medium leading-tight text-red-600"
      >
        <s-icon name="warning" size="16" class="opacity-70" />
        <span class="sr-only">
          {{ error }}
        </span>
      </div>
    </s-tooltip>
  </div>
</template>
<script setup lang="ts">
import SInput from './SInput.vue';
import {useI18n} from 'vue-i18n';
import SIcon from './SIcon.vue';
import STooltip from './STooltip.vue';

const model = defineModel();

const {size = 'md'} = defineProps<{
  id: string;
  label?: string;
  description?: string;
  error?: string;
  required?: boolean;
  size?: 'sm' | 'md' | 'lg';
  inputClass?: string;
}>();

const sizeClasses = {
  sm: {
    padding: 'px-2.5 py-1.5',
    text: 'text-base',
    icon: '18',
  },
  md: {
    padding: 'px-3.5 py-2.5',
    text: 'text-base',
    icon: '20',
  },
  lg: {
    padding: 'px-3.5 py-2.5',
    text: 'text-lg',
    icon: '22',
  },
};

const {t} = useI18n({
  inheritLocale: true,
  scope: 'local',
});
</script>
<script lang="ts">
export default {
  inheritAttrs: false,
};
</script>
