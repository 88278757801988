<template>
  <nav class="w-full flex gap-2.5">
    <s-link
      v-for="link in links"
      :key="link.href"
      :href="link.href"
      class="group/link relative flex items-center gap-1 text-lg font-medium px-2 pt-1 pb-1.5 transition"
      :class="[isActive(link) ? 'text-blue-600' : 'text-gray-600 hover:text-blue-500']"
    >
      <s-icon
        v-if="!!link.icon"
        :name="link.icon"
        size="20"
        class="transition"
        :class="[isActive(link) ? 'text-blue-300' : 'text-gray-400 group-hover/link:text-blue-300']"
      />
      {{ link.label }}
      <span
        v-if="isActive(link)"
        class="w-full h-[3px] rounded-t absolute bottom-0 left-0 bg-blue-300 transition"
      ></span>
      <span
        v-if="isActive(link)"
        class="blur-[2px] w-full h-1 rounded-b absolute bottom-px left-0 translate-y-full bg-gradient-to-b from-blue-200 to-transparent transition opacity-30"
      ></span>
    </s-link>
  </nav>
</template>
<script setup lang="ts">
import {usePage} from '@inertiajs/vue3';
import {computed} from 'vue';
import SIcon from './SIcon.vue';
import SLink from '../components/SLink.vue';

interface NavLink {
  label: string;
  href: string;
  icon?: string;
}

const {links} = defineProps<{
  links: NavLink[];
}>();

const page = usePage();
const path = computed(() => page.url.split('?')[0]);

const isActive = (link: NavLink): boolean => link.href.toString().endsWith(path.value);
</script>
