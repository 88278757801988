<template>
  <div :class="[classes.tabPane, 'gap-5']">
    <template v-if="additions.length || moves.length || removals.length || unchanged.length">
      <diff-student-additions v-if="additions.length" :additions="additions" />
      <diff-student-moves v-if="moves.length" :moves="moves" />
      <diff-student-removals v-if="removals.length" :removals="removals" />
      <diff-student-unchanged v-if="unchanged.length" :unchanged="unchanged" />
      <s-checkbox id="newStudents" v-model="form.sendInvites">
        {{ t('descriptions.sendInvitations') }}
        <strong class="font-black">{{ t('labels.newStudents') }}</strong>
      </s-checkbox>
    </template>
    <p v-else>{{ t('errors.noValidChanges') }}</p>
  </div>
  <div :class="classes.tabFooter">
    <s-btn color="secondary" icon="arrow-left" @click="$emit('back')" class="flex-1 max-w-[16rem]">
      {{ t('actions.back') }}
    </s-btn>
    <s-btn
      :disabled="!(additions.length || moves.length || removals.length || unchanged.length)"
      :processing="processingChanges"
      @click="$emit('submit')"
      icon="floppy"
      color="primary"
      class="flex-1 max-w-[16rem]"
    >
      {{ t('actions.diff') }}
    </s-btn>
  </div>
</template>
<script setup lang="ts">
import DiffStudentAdditions from './DiffStudentAdditions.vue';
import DiffStudentRemovals from './DiffStudentRemovals.vue';
import SBtn from '../../../design-system/SBtn.vue';
import DiffStudentMoves from './DiffStudentMoves.vue';
import DiffStudentUnchanged from './DiffStudentUnchanged.vue';
import {useI18n} from 'vue-i18n';
import SCheckbox from '../../../design-system/SCheckbox.vue';
import {InertiaForm} from '@inertiajs/vue3';
import RosterFormDto = App.DTOs.Roster.RosterFormDto;
import RosterChangeDto = App.DTOs.Roster.RosterChangeDto;

const props = defineProps<{
  processingChanges: boolean;
  classes: any;
  removals: RosterChangeDto[];
  additions: RosterChangeDto[];
  moves: RosterChangeDto[];
  unchanged: RosterChangeDto[];
  form: InertiaForm<RosterFormDto>;
}>();

defineEmits(['submit', 'back']);

const {t} = useI18n({
  useScope: 'local',
  inheritLocale: true,
});
</script>
<i18n>
{
  "en": {
    "descriptions": {
      "sendInvitations": "Send Invitation to"
    },
    "labels": {
      "newStudents": "New Students"
    },
    "errors": {
      "noValidChanges": "Sorry, there's no valid changes to make."
    },
    "actions": {
      "back": "Back",
      "diff": "Confirm Changes"
    }
  },
  "fr": {
    "descriptions": {
      "sendInvitations": "Envoyer une invitation à"
    },
    "labels": {
      "newStudents": "Nouveaux étudiants"
    },
    "errors": {
      "noValidChanges": "Désolé, il n'y a pas de modifications valides à effectuer."
    },
    "actions": {
      "back": "Retour",
      "diff": "Confirmer les changements"
    }
  }
}
</i18n>
