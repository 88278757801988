<template>
  <s-page-block class="bg-blue-200">Page Block</s-page-block>
</template>
<script setup lang="ts">
import SPageBlock from '../../../../design-system/SPageBlock.vue';
</script>
<script lang="ts">
import {ExampleOptions} from '../../examples';

const description = `The PageBlock provides a container for sections of content in the app.

The "padding" prop controls how much space is inside the container.

The "size" prop controls the width of the container.`;

export const options: ExampleOptions = {
  description,
  padding: false,
  controls: [
    {
      type: 'select',
      prop: 'padding',
      label: 'Padding',
      options: ['none', 'full', 'horizontal'],
      default: 'full',
    },
    {
      type: 'select',
      prop: 'size',
      label: 'Size',
      options: ['full', 'lg', 'md', 'sm'],
      default: 'md',
    },
  ],
};
</script>
