<template>
  <div class="">
    <h3 class="heading md flex items-center gap-1 text-blue-600 mb-2">
      <s-icon name="swap-horizontal-circle" size="20" class="opacity-70" />
      <i18n-t keypath="heading" :plural="rows.length">
        <template #count>
          <span class="font-black">
            {{ rows.length }}
          </span>
        </template>
      </i18n-t>
    </h3>
    <div class="flex flex-col gap-4">
      <diff-change-set type="change-role" :rows="rows" />
    </div>
  </div>
</template>
<script setup lang="ts">
import DiffChangeSet from '../../CourseStudent/Create/DiffChangeSet.vue';
import RosterChangeDto = App.DTOs.Roster.RosterChangeDto;
import {computed} from 'vue';
import {useI18n} from 'vue-i18n';
import SIcon from '../../../design-system/SIcon.vue';

const props = defineProps<{
  roleChanges: RosterChangeDto[];
}>();

const {t} = useI18n({
  useScope: 'local',
  inheritLocale: true,
});

const rows = computed<Record<string, any>[]>(() => {
  return props.roleChanges.map((addition) => {
    return {
      [t('row')]: addition.row,
      [t('email')]: addition.email,
      [t('newRole')]: t('roleNames.' + addition.role),
      [t('section')]: addition.sectionName,
    };
  });
});
</script>
<i18n>
{
  "en": {
    "heading": "Changing Role of {count} Staff"
  },
  "fr": {
    "heading": "Changement de rôle d'un membre du personnel | Changement de rôle de {count} membres du personnel"
  }
}
</i18n>
