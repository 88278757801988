import CalculationDto = App.DTOs.Tasks.CalculationDto;

export const calculationFactory = (): CalculationDto => ({
  name: '',
  expression: '',
  feedback: null,
  points: 1,
  sigFigs: null,
  tolerance: null,
  subexpressions: [],
});
