<template>
  <a
    href="#content-target"
    class="[&:not(:focus)]:sr-only absolute top-0 left-0 z-[999] bg-blue-600 text-white font-medium px-3 py-1 shadow-lg rounded-br-lg underline underline-offset-2 decoration-blue-200/70"
  >
    {{ t('skipToContent') }}
  </a>
  <div
    class="grid grid-cols-1 items-stretch h-device w-full transition-all duration-200"
    :class="pageWrapperStyles"
  >
    <nav
      ref="navRef"
      class="flex-1 min-h-0 h-full col-start-1 col-end-2 row-start-1 row-end-2 flex flex-col z-50 w-full max-w-[66%] min-w-[256px] xl:min-w-[300px] text-white transition duration-200 select-none"
      :class="[navTababilityStyles, navTranslationStyles, admin ? 'bg-s-red-1000' : 'bg-black']"
    >
      <div
        class="relative w-full pl-3 py-[0.8125rem] flex flex-row-reverse items-center justify-between"
        :class="
          admin
            ? 'bg-gradient-to-b from-s-red-500/30 to-s-red-600/0'
            : 'bg-gradient-to-b from-blue-1000 to-transparent'
        "
      >
        <div class="relative flex items-center">
          <div
            class="block md:hidden transition"
            :class="
              mobileSidebarOpen
                ? 'text-white hover:text-blue-300'
                : 'text-gray-800 hover:text-blue-600'
            "
          >
            <button ref="inSidebarMobileToggle" @click="toggleMobileSidebar" class="px-3.5 py-3">
              <s-icon name="backburger" />
              <span class="sr-only">{{ t('closeSidebar') }}</span>
            </button>
          </div>
          <div
            class="hidden md:block transition"
            :class="
              desktopSidebarOpen
                ? 'text-white hover:text-blue-300'
                : 'text-gray-800 hover:text-blue-600'
            "
          >
            <button ref="inSidebarDesktopToggle" @click="toggleDesktopSidebar" class="px-3.5 py-3">
              <s-icon name="backburger" />
              <span class="sr-only">{{ t('closeSidebar') }}</span>
            </button>
          </div>
        </div>
        <user-menu />
      </div>
      <hr
        aria-hidden="true"
        class="relative border-t-[1px]"
        :class="admin ? 'border-s-red-500/40' : 'border-blue-400/40'"
      />
      <div class="relative flex-1 flex flex-col text-base pt-3 overflow-y-auto hide-scrollbar">
        <div class="flex-1 flex flex-col gap-px">
          <sidebar-item v-for="item in props.items" :key="getKeyFor(item)" :item="item" />
        </div>
        <div
          class="px-4 pt-4 pb-5"
          :class="
            admin
              ? 'bg-gradient-to-t from-s-red-600/30 to-s-red-600/0'
              : 'bg-gradient-to-t from-blue-1000 to-transparent'
          "
        >
          <s-link href="/" class="flex flex-col transition text-white hover:text-blue-100" external>
            <s-logo class="logo h-10 w-auto" />
            <span class="sr-only">{{ t('stembleDashboard') }}</span>
          </s-link>
          <div class="flex flex-wrap items-center justify-center gap-4 mt-4">
            <s-link
              external
              :href="t('links.termsAndConditions')"
              target="_blank"
              class="underline underline-offset-2 decoration-transparent hover:decoration-blue-400/50 active:decoration-blue-300 decoration-2 font-medium text-blue-400 hover:text-blue-300 active:text-blue-500 tracking-slight transition-all ease-out duration-100 text-xs"
            >
              {{ t('links.termsAndConditionsLabel') }}
            </s-link>
            <s-link
              external
              :href="t('links.privacyPolicy')"
              target="_blank"
              class="underline underline-offset-2 decoration-transparent hover:decoration-blue-400/50 active:decoration-blue-300 decoration-2 font-medium text-blue-400 hover:text-blue-300 active:text-blue-500 tracking-slight transition-all ease-out duration-100 text-xs"
            >
              {{ t('links.privacyPolicyLabel') }}
            </s-link>
          </div>
        </div>
      </div>
    </nav>
    <div
      class="relative col-start-1 col-end-2 md:col-start-2 md:col-end-3 row-start-1 row-end-2 h-dvh w-full grid grid-cols-1 items-stretch"
    >
      <main
        class="col-start-1 col-end-2 row-start-1 row-end-2 w-full h-full flex flex-col transition-spacing ease-linear bg-background flex flex-col align-center overflow-auto"
        scroll-region
        role="main"
      >
        <div
          class="absolute w-full md:w-0 h-full md:h-0 bg-slate-900/70 z-40 transition"
          :class="
            mobileSidebarOpen ? 'opacity-100 pointer-events-auto' : 'opacity-0 pointer-events-none'
          "
          @click="toggleMobileSidebar"
        />

        <div
          class="z-10 flex-none w-full grid grid-cols-2 grid-rows-[1fr_auto] md:flex items-center justify-between gap-x-2 px-3 lg:px-5 pt-2 lg:pt-3"
        >
          <div
            class="row-start-1 row-end-2 col-start-1 col-end-2 flex-0 transition-all ease-out duration-200 max-md:w-10"
            :class="{
              'md:w-12 md:opacity-100': !desktopSidebarOpen,
              'md:w-0 md:opacity-0 pointer-events-none': desktopSidebarOpen,
            }"
          >
            <div class="block md:hidden transition text-gray-800 hover:text-blue-600 -ml-0.5">
              <button ref="onPageMobileToggle" @click="toggleMobileSidebar" class="px-2.5 py-3">
                <s-icon name="menu" />
                <span class="sr-only">{{ t('toggleSidebar') }}</span>
              </button>
            </div>
            <div class="hidden md:block transition text-gray-800 hover:text-blue-600 -ml-0.5">
              <button ref="onPageDesktopToggle" @click="toggleDesktopSidebar" class="px-2.5 py-3">
                <s-icon name="menu" />
                <span class="sr-only">{{ t('toggleSidebar') }}</span>
              </button>
            </div>
          </div>
          <div class="row-start-2 row-end-3 col-start-1 col-end-3 flex-1 px-2.5 md:px-0 pt-0.5">
            <s-breadcrumbs :breadcrumbs="breadcrumbs" />
          </div>
          <nav
            role="navigation"
            class="row-start-1 row-end-2 col-start-2 col-end-3 justify-self-end text-gray-700 stack horizontal gap-3 valign-center"
          >
            <top-bar-dropdown id="keyboard-shortcuts" v-if="keyboardShortcuts.length">
              <template #button>
                <s-icon name="keyboard" size="20" />
                <span class="sr-only">
                  {{ t('keyboardShortcuts') }}
                </span>
              </template>
              <template #dropdown>
                <div class="flex flex-col font-medium text-sm py-1.5">
                  <button
                    v-for="shortcut in keyboardShortcuts"
                    @click.prevent="shortcut.onPress()"
                    class="group/button cursor-pointer p-3 py-1.5 w-full flex items-center gap-2 hover:text-blue-500 transition-all ease-out duration-150"
                  >
                    <span
                      class="keycap transition-all ease-out duration-150 group-hover/button:!text-blue-400 group-active/button:!shadow-none group-active/button:translate-y-0.5"
                    >
                      {{ shortcut.key }}
                    </span>
                    {{ shortcut.label }}
                  </button>
                </div>
              </template>
            </top-bar-dropdown>

            <s-link
              href="/support"
              target="_blank"
              class="rounded border-none bg-transparent px-3 py-2 transition-colors hover:bg-gray-300/75 active:bg-gray-400/75"
            >
              <s-icon name="help-circle" size="20" />
              <span class="sr-only">{{ t('support') }}</span>
            </s-link>

            <!-- Notifications Dropdown - Start -->
            <s-link
              :href="route('notifications.index')"
              class="relative rounded border-none bg-transparent px-3 py-2 transition-colors hover:bg-gray-300/75 active:bg-gray-400/7"
            >
              <s-icon name="bell" size="20" />
              <!-- @vue-ignore -->
              <div
                aria-hidden="true"
                v-if="page.props.notificationsCount > 0"
                class="rounded rounded-full bg-orange-300 shadow-[inset_0_0_5px_-1px_#FF3301,0_0_4px_-1px_#FE6500] absolute -right-0 -top-0 px-[5px] shadow"
              >
                <div class="drop-shadow-sm text-xs font-bold tracking-slight text-white">
                  {{ page.props.notificationsCount }}
                </div>
              </div>
              <span class="sr-only">
                {{ page.props.notificationsCount }} {{ t('notifications') }}
              </span>
            </s-link>
            <!-- Notifications Dropdown - End -->
          </nav>
        </div>

        <span id="content-target" aria-hidden="true"></span>

        <slot />
      </main>
      <s-flash-message />
    </div>
  </div>
</template>
<script setup lang="ts">
import {
  computed,
  defineProps,
  onBeforeUnmount,
  onMounted,
  PropType,
  provide,
  ref,
  Ref,
  watch,
} from 'vue';
import {SidebarItem as SidebarItemData} from './types';
import SidebarItem from './SidebarItem.vue';
import TopBarDropdown from './TopBarDropdown.vue';
import SFlashMessage from '../SFlashMessage.vue';
import SLink from '../SLink.vue';
import SLogo from '../../design-system/SLogo.vue';
import SBreadcrumbs from '../../design-system/SBreadcrumbs.vue';
import UserMenu from './UserMenu.vue';
import {route} from 'ziggy-js';
import {usePage} from '@inertiajs/vue3';
import {useI18n} from 'vue-i18n';
import {provideKeyboardShortcuts} from '../../composables/useKeyboardShortcut';
import {provideFlash} from '../../composables/useFlash';
import {useWindowSize} from '@vueuse/core';
import SIcon from '../../design-system/SIcon.vue';

const page = usePage<{notificationsCount: number}>();
const props = defineProps({
  admin: {
    type: Boolean,
    default: false,
  },
  items: {
    type: Array as PropType<SidebarItemData[]>,
    default() {
      return [];
    },
  },
});

const {t} = useI18n({
  inheritLocale: true,
  scope: 'local',
});

provideFlash();

const autoCollapseWidth = 960;
const mobileSidebarOpen = ref(false);
const desktopSidebarOpen = ref(true);
const {width} = useWindowSize();

if (width.value > autoCollapseWidth - 1) {
  desktopSidebarOpen.value = true;
} else if (width.value < autoCollapseWidth) {
  desktopSidebarOpen.value = false;
}

watch(width, (newWidth, oldWidth) => {
  if (newWidth > autoCollapseWidth - 1 && newWidth > oldWidth) {
    desktopSidebarOpen.value = true;
  } else if (newWidth < autoCollapseWidth && newWidth < oldWidth) {
    desktopSidebarOpen.value = false;
  }
});

const getKeyFor = (item: SidebarItemData) => {
  if (item.type === 'divider') {
    return 'divider';
  }

  return item.label;
};

interface Breadcrumb {
  label: string;
  href: string;
  external?: boolean;
}

const breadcrumbs: Ref<Breadcrumb[]> = ref([]);
const updateBreadcrumbs = (newBreadcrumbs: Breadcrumb[]) => {
  breadcrumbs.value = newBreadcrumbs;
};
const routeHash = computed(() => {
  /**
   * Strip out params, slashes, and numbers so it's just one long string;
   * this way the breadcrumbs are only cleared on a 'real' page change
   */
  const pageUrlWithoutParams = page.url.split('?')[0];

  return pageUrlWithoutParams.replace(/\/\d+/g, '');
});
watch(routeHash, (newRouteHash) => {
  updateBreadcrumbs([]);
});

provide('breadcrumbs', {breadcrumbs, updateBreadcrumbs});

const pageWrapperStyles = computed(() => {
  return desktopSidebarOpen.value
    ? 'md:grid-cols-[256px_1fr] xl:grid-cols-[300px_1fr]'
    : 'md:grid-cols-[0_1fr]';
});

const navTranslationStyles = computed(() => {
  let styles = '';

  if (!desktopSidebarOpen.value) {
    styles += ' md:-translate-x-full ';
  }

  if (!mobileSidebarOpen.value) {
    styles += ' max-md:-translate-x-full ';
  }

  return styles;
});

/**
 * # Tabbing Management
 *
 * When the sidebar is closed, we want to make sure that the user can NOT tab into it.
 *
 * The sidebar's visibility is controlled by two variables:
 * 1. `mobileSidebarOpen`
 * 2. `desktopSidebarOpen`.
 *
 * Screen size determines which variable controls the sidebar's visibility.
 *
 * Because visibility is based on screen size, we cannot simply set `tabindex="-1"` on the sidebar's items.
 *
 * Instead, we use the variables above to apply the `no-touching` class for certain breakpoints.
 *
 * This makes the sidebar items untabbable when the sidebar is closed, on the appropriate breakpoints.
 */
const navTababilityStyles = computed(() => {
  let styles = ' ';

  if (!mobileSidebarOpen.value) {
    styles += 'max-md:no-touching';
  }
  if (!desktopSidebarOpen.value) {
    styles += 'md:no-touching';
  }

  return styles;
});

/**
 * # Focus Management
 *
 * Clicking the "Open Sidebar" button switches the focus to "Close Sidebar" button, and vice-versa.
 *
 * Forcing a focus change like this is _usually_ frowned upon, but in this case it's fine.
 * The expected UX of a menu/accordion is that you stay on the toggle after it's pressed.
 * In those cases it would be one button to both open and close. In our case, one button
 * opens and a different one closes. So while it's _technically_ a focus change, the
 * use does not perceive it as such.
 *
 * ## Focus Management: Desktop Sidebar Toggles
 */
const inSidebarDesktopToggle = ref<HTMLElement | null>(null);
const onPageDesktopToggle = ref<HTMLElement | null>(null);
const toggleDesktopSidebar = () => {
  desktopSidebarOpen.value = !desktopSidebarOpen.value;

  if (desktopSidebarOpen.value) {
    setTimeout(() => inSidebarDesktopToggle.value?.focus(), 50);
  } else {
    onPageDesktopToggle.value?.focus();
  }
};

/**
 * ## Focus Management: Mobile Sidebar Toggles
 */
const inSidebarMobileToggle = ref<HTMLElement | null>(null);
const onPageMobileToggle = ref<HTMLElement | null>(null);
const toggleMobileSidebar = () => {
  mobileSidebarOpen.value = !mobileSidebarOpen.value;

  if (mobileSidebarOpen.value) {
    setTimeout(() => inSidebarMobileToggle.value?.focus(), 50);
  } else {
    onPageMobileToggle.value?.focus();
  }
};

const keyboardShortcuts = provideKeyboardShortcuts();

/**
 * # Focus Management: Mobile Sidebar Tabbing
 *
 * When the mobile sidebar nav is open, we want to close it
 * if the user switches focus to something outside of it.
 *
 * There are two cases where this will occur:
 * 1. the user clicks outside of it
 * 2. the user tabs out of it
 *
 * This is required to adhere to WCAG 2.2 guideline 2.4.11 and 2.4.12
 *
 * https://www.w3.org/TR/WCAG22/#focus-not-obscured-minimum
 */
const navRef = ref<HTMLElement | null>(null);
const onFocusIn = (event: FocusEvent) => {
  if (!navRef.value) return;

  const focusHasLeftNav = !navRef.value.contains(event.target as Node);

  if (mobileSidebarOpen.value && focusHasLeftNav) {
    mobileSidebarOpen.value = false;
  }
};
onMounted(() => document.addEventListener('focusin', onFocusIn));
onBeforeUnmount(() => document.removeEventListener('focusin', onFocusIn));
</script>
<i18n>
{
  "en": {
    "toggleSidebar": "Toggle Sidebar",
    "closeSidebar": "Close Sidebar",
    "keyboardShortcuts": "Keyboard Shortcuts",
    "stembleDashboard": "Stemble Dashboard"
  },
  "fr": {
    "toggleSidebar": "Basculer la barre latérale",
    "closeSidebar": "Fermer la barre latérale",
    "keyboardShortcuts": "Raccourcis du clavier",
    "stembleDashboard": "Tableau de bord de Stemble"
  }
}
</i18n>
