<template>
  <s-logo />
  <s-logo icon />
</template>
<script setup lang="ts">
import SLogo from '@/design-system/SLogo.vue';
</script>
<script lang="ts">
import {ExampleOptions} from '../examples';

const description =
  'Display the Stemble logo with ease. Choose between the full logo or just the icon.';

export const options: ExampleOptions = {
  description,
};
</script>
