<template>
  <Head :title="`Admin - ${course.name} Logs`"></Head>
  <div class="page-block gap-5">
    <s-page-header :heading="course.code"></s-page-header>

    <ol>
      <li v-for="event in events">
        <details>
          <summary>{{ event.created_at }}: {{ event.event_class.split('\\').pop() }}</summary>
          <div>
            <pre><code>{{JSON.stringify(event.event_properties, null, 2)}}</code></pre>
          </div>
        </details>
      </li>
    </ol>
  </div>
</template>

<script setup lang="ts">
import {Course} from '../../../types/entities/course';
import {route} from 'ziggy-js';
import SPageHeader from '../../../design-system/SPageHeader.vue';
import {Head} from '@inertiajs/vue3';
import {useBreadcrumbs} from '../../../composables/useBreadcrumbs';

const props = defineProps<{
  course: Course;
  events: any[];
}>();

useBreadcrumbs([
  {label: 'Courses', href: route('admin.courses.index')},
  {label: props.course.code, href: route('admin.courses.show', {course: props.course.id})},
]);
</script>
