import {InputRule, textblockTypeInputRule} from 'prosemirror-inputrules';
import {Schema} from 'prosemirror-model';

/**
 * InputRules for creating heading tags using Markdown syntax.
 *
 * Example:
 * - `#[SPACE]` becomes an `h1`
 * - `##[SPACE]` becomes an `h2`
 * - `###[SPACE]` becomes an `h3`
 * - etc.
 */
export const headingRules: (schema: Schema) => InputRule[] = (schema) => {
  if (!schema.nodes.heading) {
    return [];
  }

  return [
    // Markdown Syntax
    textblockTypeInputRule(/^(##?#?#?#?#?) $/, schema.nodes.heading, (match) => {
      return {
        level: match[1].length,
      };
    }),
  ];
};
