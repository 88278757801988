<template>
  <v-chart class="multipleTokenChart" :option="option" autoresize />
</template>

<script setup lang="ts">
import {computed, ComputedRef} from 'vue';
import type {ComposeOption} from 'echarts';
import {use} from 'echarts/core';
import {LineChart, LineSeriesOption} from 'echarts/charts';
import {CanvasRenderer} from 'echarts/renderers';
import VChart from 'vue-echarts';

import {
  DataZoomComponent,
  DataZoomComponentOption,
  GridComponent,
  GridComponentOption,
  LegendComponent,
  LegendComponentOption,
  TitleComponent,
  TitleComponentOption,
  TooltipComponent,
  TooltipComponentOption,
} from 'echarts/components';

use([
  TitleComponent,
  LegendComponent,
  DataZoomComponent,
  LineChart,
  CanvasRenderer,
  TooltipComponent,
  GridComponent,
]);

type ECOption = ComposeOption<
  | DataZoomComponentOption
  | LineSeriesOption
  | TitleComponentOption
  | TooltipComponentOption
  | LegendComponentOption
  | GridComponentOption
>;

const props = defineProps<{
  tokenUsage: {
    assignmentId: number;
    assignmentName: string;
    dataPoints: {
      day: string;
      totalTokens: number;
    }[];
  }[];
}>();

const dates = computed(() => props.tokenUsage[0].dataPoints.map((item) => item.day));
const seriesData = computed(() => {
  return props.tokenUsage.map((assignment) => ({
    name: `${assignment.assignmentName} (${assignment.assignmentId})`, // Append the assignmentId
    type: 'line',
    data: assignment.dataPoints.map((dataPoint) => dataPoint.totalTokens),
  }));
});

const option: ComputedRef<any> = computed(() => ({
  title: {
    text: 'Token Usage by Assignment Over Time',
    left: 'center',
    top: '2%',
  },
  tooltip: {
    trigger: 'axis',
  },
  legend: {
    data: props.tokenUsage.map(
      (assignment) => `${assignment.assignmentName} (${assignment.assignmentId})`
    ),
    top: '8%',
  },
  grid: {
    left: '3%',
    right: '4%',
    bottom: '13%',
    top: '18%',
    containLabel: true,
  },
  xAxis: {
    type: 'category',
    boundaryGap: false,
    data: dates.value,
  },
  yAxis: {
    type: 'value',
  },
  dataZoom: [
    {
      type: 'inside',
      start: 0,
      end: 10,
    },
    {
      start: 0,
      end: 10,
    },
  ],
  series: seriesData.value,
}));
</script>

<style scoped>
.multipleTokenChart {
  width: 100%;
  aspect-ratio: 2/1;
  max-height: 100vh;
}
</style>
