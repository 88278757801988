<template>
  <s-moustache :text="text">
    <template #name>
      <strong>Bobby Joe</strong>
    </template>
  </s-moustache>
</template>
<script setup lang="ts">
import SMoustache from '../../../../design-system/SMoustache.vue';

const {text = ''} = defineProps<{text: string}>();
</script>
<script lang="ts">
import {ExampleOptions} from '../../examples';

export const options: ExampleOptions = {
  controls: [{prop: 'text', label: 'Text', type: 'input', default: 'Hello, {{ name }}!'}],
};
</script>
