<template>
  <s-date date="2025/01/15" />
</template>
<script setup lang="ts">
import SDate from '../../../../design-system/SDate.vue';
</script>
<script lang="ts">
import {ExampleOptions} from '../../examples';

const description = `This component is used to display the date and the time.

It makes sure to show the time in the user's timezone and locale.
`;
export const options: ExampleOptions = {
  description,
};
</script>
