<template>
  <label class="group relative flex items-center gap-1.5 cursor-pointer select-none" :for="id">
    <div class="relative grid grid-cols-1 grid-rows-1 justify-center items-center rounded">
      <input
        :id="id"
        class="peer z-[-1] absolute block w-full h-full row-start-1 col-start-1 opacity-0 focus-visible:opacity-100 border border-2 border-transparent focus-visible:border-blue-500 rounded-md"
        type="checkbox"
        tabindex="0"
        v-model="model"
      />
      <div
        class="row-start-1 col-start-1 grid grid-cols-1 grid-rows-1 justify-center items-center peer-focus:ring-2 peer-focus:ring-opacity-50 peer-focus:ring-offset-2 peer-focus:ring-offset-white rounded-sm"
        :class="{
          'peer-focus:ring-blue-500': color === 'blue',
          'peer-focus:ring-green-500': color === 'green',
        }"
        role="presentation"
      >
        <s-icon
          name="checkbox-blank-outline"
          class="row-start-1 col-start-1 transition-all ease-out duration-150 checkbox-icon text-gray-400/70 -m-0.5"
          :class="{
            'opacity-0': modelValue,
            'opacity-100 group-hover:opacity-60': !modelValue,
          }"
        />
        <s-icon
          name="checkbox-marked"
          class="row-start-1 col-start-1 transition-all ease-out duration-150 checkbox-icon -m-0.5"
          :class="{
            'opacity-100 group-hover:opacity-70': modelValue,
            'text-blue-500': modelValue && color === 'blue',
            'text-green-500': modelValue && color === 'green',
            'opacity-0': !modelValue,
          }"
        />
        <s-icon
          v-if="!modelValue"
          name="check"
          size="17"
          class="absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 transition-all ease-out duration-150 checkbox-icon opacity-0 group-hover:opacity-60"
          :class="{
            'text-blue-500': color === 'blue',
            'text-green-500': color === 'green',
          }"
        />
      </div>
    </div>
    <span
      class="text-lg font-medium transition-all ease-out duration-100 group-hover:opacity-80 group-focus-within:opacity-80"
      :class="{
        'text-blue-600': color === 'blue',
        'text-green-600': color === 'green',
        'opacity-100': modelValue,
        'opacity-80 grayscale-[50%] group-hover:grayscale-0 group-focus-within:grayscale-0':
          !modelValue,
      }"
    >
      <slot>{{ label }}</slot>
    </span>
  </label>
</template>

<script lang="ts" setup>
import {defineProps} from 'vue';
import SIcon from './SIcon.vue';

const model = defineModel<boolean | null>();

const {color = 'blue'} = defineProps<{
  id: string;
  label?: string;
  color?: 'blue' | 'green';
}>();
</script>
