<template>
  <Head title="Admin - Course Analytics"></Head>
  <div class="page-block flex flex-col gap-4">
    <s-page-header heading="Course Analytics" />
    <s-stats-section
      heading="Courses"
      :stats="[
        {label: 'Active', value: activeCount},
        {label: 'Upcoming', value: upcomingCount},
        {label: 'Completed', value: completedCount},
      ]"
    />
    <div class="flex flex-wrap gap-4 w-full">
      <section class="flex-grow">
        <h2 class="text-xl font-semibold mb-2">Starting Soon</h2>
        <table class="w-full bg-white rounded">
          <thead>
            <tr>
              <th class="px-3 py-2 text-left">Institution</th>
              <th class="px-3 py-2 text-left">Course</th>
              <th class="px-3 py-2 text-left">Start Date</th>
            </tr>
          </thead>
          <tbody>
            <tr v-if="!startingSoon.length">
              <td class="px-3 py-2" colspan="3">No courses starting soon</td>
            </tr>
            <tr
              v-for="course in startingSoon"
              :key="course.id"
              class="p-3 border-b-1 border-blue-100"
            >
              <td class="px-3 py-2">{{ course.institution.abbreviation }}</td>
              <td class="px-3 py-2">
                <s-link :href="route('admin.courses.show', [course.id])">{{ course.code }}</s-link>
              </td>
              <td class="px-3 py-2">
                {{ monthDaySometimesYear(course.startDate) }}
              </td>
            </tr>
          </tbody>
        </table>
      </section>

      <section class="flex-grow">
        <h2 class="text-xl font-semibold mb-2">Ending Soon</h2>
        <table class="w-full bg-white rounded">
          <thead>
            <tr>
              <th class="px-3 py-2 text-left">Institution</th>
              <th class="px-3 py-2 text-left">Course</th>
              <th class="px-3 py-2 text-left">End Date</th>
            </tr>
          </thead>
          <tbody>
            <tr v-if="!endingSoon.length">
              <td class="px-3 py-2" colspan="3">No courses ending soon</td>
            </tr>
            <tr
              v-for="course in endingSoon"
              :key="course.id"
              class="p-3 border-b-1 border-blue-100"
            >
              <td class="px-3 py-2">{{ course.institution.abbreviation }}</td>
              <td class="px-3 py-2">
                <s-link :href="route('admin.courses.show', [course.id])">{{ course.code }}</s-link>
              </td>
              <td class="px-3 py-2">
                {{ monthDaySometimesYear(course.endDate) }}
              </td>
            </tr>
          </tbody>
        </table>
      </section>
    </div>

    <Bar id="my-chart-id" :options="chartOptions" :data="chartData" />
  </div>
</template>
<script setup lang="ts">
import SPageHeader from '../../../design-system/SPageHeader.vue';
import {Bar} from 'vue-chartjs';
import {
  Chart as ChartJS,
  Title,
  Tooltip,
  Legend,
  BarElement,
  CategoryScale,
  LinearScale,
} from 'chart.js';
import {route} from 'ziggy-js';
import {Head} from '@inertiajs/vue3';
import {Course} from '../../../types/entities/course';
import {monthDaySometimesYear} from '../../../format/dates';
import SLink from '../../../components/SLink.vue';
import SStatsSection from '../../../design-system/SStatsSection.vue';
import {useBreadcrumbs} from '../../../composables/useBreadcrumbs';

ChartJS.register(Title, Tooltip, Legend, BarElement, CategoryScale, LinearScale);

const props = defineProps<{
  data: {
    year: string;
    month: string;
    count: number;
  }[];
  completedCount: number;
  activeCount: number;
  upcomingCount: number;
  startingSoon: Course[];
  endingSoon: Course[];
}>();

useBreadcrumbs([{label: 'Courses', href: route('admin.courses.index')}]);

const chartData = {
  labels: props.data.map((d) => `${d.year}-${d.month}`),
  datasets: [
    {
      label: 'Courses Started',
      backgroundColor: '#f87979',
      data: props.data.map((d) => d.count),
    },
  ],
};

const chartOptions = {
  responsive: true,
};
</script>
