<template>
  <div
    class="rounded-lg border shadow-soft px-3 py-2.5 flex items-center gap-2"
    :class="[messageColorClasses[color]]"
  >
    <s-icon v-if="icon" :name="icon" class="opacity-70 flex-0" size="22" />
    <div class="">
      <slot />
    </div>
  </div>
</template>
<script setup lang="ts">
import SIcon from './SIcon.vue';

withDefaults(
  defineProps<{
    color?: 'green' | 'blue' | 'gray' | 'white' | 'red';
    icon?: string;
  }>(),
  {
    color: 'green',
  }
);

const messageColorClasses = {
  green: 'bg-green-100 border-green-250 text-green-700',
  blue: 'bg-blue-50 border-blue-200 text-blue-700',
  gray: 'bg-gray-100 border-gray-200 text-gray-700',
  white: 'bg-white border-gray-200 text-gray-700',
  red: 'bg-red-100 border-red-200 text-red-700',
};
</script>
