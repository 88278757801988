import {Schema} from 'prosemirror-model';
import {addListNodes} from 'prosemirror-schema-list';
import {paragraph} from './node_specs/paragraph';
import {blockquote} from './node_specs/blockquote';
import {horizontal_rule} from './node_specs/horizontal_rule';
import {heading} from './node_specs/heading';
import {code_block} from './node_specs/code_block';
import {hard_break} from './node_specs/hard_break';
import {math_inline} from './node_specs/math_inline';
import {math_display} from './node_specs/math_display';
import {text} from './node_specs/text';
import {link} from './mark_specs/link';
import {em} from './mark_specs/em';
import {strong} from './mark_specs/strong';
import {code} from './mark_specs/code';

const nodes = {
  doc: {content: 'block*'},
  paragraph,
  blockquote,
  horizontal_rule,
  heading,
  code_block,
  text,
  hard_break,
  math_inline,
  math_display,
};

export const marks = {
  link,
  em,
  strong,
  code,
};

const baseSchema = new Schema({
  nodes,
  marks,
});

export const docSchema = new Schema({
  nodes: addListNodes(baseSchema.spec.nodes, 'paragraph block*', 'block'),
  marks: baseSchema.spec.marks,
});
