<template>
  <div
    class="rounded w-full p-3 border border-primary-400 shadow-lg bg-gradient-to-r from-primary-50 to-primary-100 flex justify-between items-center"
  >
    <div>
      <h2 class="heading sm">
        <slot name="title" />
      </h2>
      <p>
        <slot name="body" />
      </p>
    </div>
    <div v-if="$slots.actions" class="flex gap-2">
      <slot name="actions" />
    </div>
  </div>
</template>
