<template>
  {{ displayGradeText }}
</template>
<script setup lang="ts">
import {Grade} from '../types/entities/base-grade';
import {computed} from 'vue';
import GradeDisplay = App.Enums.GradeDisplay;

const props = defineProps<{
  grade: Grade;
  weight: number;
  gradeDisplay?: GradeDisplay;
}>();

const currentGrade = computed(() => {
  return props.grade.exactNumerator / props.grade.exactDenominator;
});

const taskGradeFractionText = computed(() => {
  let numerator = currentGrade.value * props.weight;

  let stringNumerator: string = '0';

  if (numerator) {
    stringNumerator = numerator.toFixed(2);
  }

  return `${stringNumerator}/${props.weight.toFixed(2)}`;
});

const displayGradeText = computed(() => {
  if (props.gradeDisplay == 'FRACTION') {
    return taskGradeFractionText.value;
  }
  return taskGradeFractionText.value + ' (' + (currentGrade.value * 100).toFixed() + '%' + ')';
});
</script>
