<template>
  <component
    :is="linkOrButton"
    class="btn"
    :class="[
      // Size
      size || 'md',
      // Color
      color || 'primary',
      // Icon Position
      iconEnd ? 'flex-row-reverse' : '',
    ]"
    :href="href"
    :disabled="disabled || processing"
    :target="target"
  >
    <s-icon
      v-if="icon && !processing"
      :name="icon"
      class="opacity-70"
      :class="[iconEnd ? '-mr-1' : '-ml-1']"
      :size="iconSizes[size || 'md']"
    />
    <s-icon
      v-if="processing"
      name="loading"
      class="opacity-70 animate-spin"
      :class="[iconEnd ? '-mr-1' : '-ml-1']"
      :size="iconSizes[size || 'md']"
    />
    <slot />
  </component>
</template>
<script setup lang="ts">
import {computed} from 'vue';
import SLink from '../components/SLink.vue';
import SIcon from './SIcon.vue';

export type Size = 'custom' | 'sm' | 'md' | 'lg' | 'xl' | 'xxl';
export type Color =
  | 'primary'
  | 'primary-light'
  | 'primary-white'
  | 'blue'
  | 'blue-light'
  | 'secondary'
  | 'white'
  | 'success'
  | 'success-white'
  | 'green'
  | 'green-light'
  | 'light'
  | 'gray'
  | 'ghost'
  | 'ghost-red'
  | 'link'
  | 'link-red'
  | 'danger'
  | 'error'
  | 'red'
  | 'red-light'
  | 'error-white'
  | 'danger-white';

const props = defineProps<{
  href?: string;
  icon?: string;
  size?: Size;
  color?: Color;
  processing?: boolean;
  disabled?: boolean;
  target?: '_blank' | '_parent' | '_self' | '_top' | string;
  iconEnd?: boolean;
}>();

const linkOrButton = computed(() => {
  return props.href ? SLink : 'button';
});

const iconSizes = {
  sm: '16',
  md: '18',
  lg: '21',
  xl: '22',
  xxl: '24',
  custom: '1em',
};
</script>
