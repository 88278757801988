<template>
  <h3 class="font-medium text-base leading-snug mb-1">
    <s-latex :content="criteria.name" />
  </h3>

  <s-tooltip>
    <template #tooltip>
      Example grade scale:
      <strong>{{ generateGradeScaleString(criteria.gradeIncrement) }}</strong>
    </template>
    <s-badge-group class="mb-2">
      <s-badge size="sm">
        Weight:
        <strong>{{ criteria.gradeWeight }}</strong>
      </s-badge>
      <s-badge size="sm">
        Increment:
        <strong>{{ criteria.gradeIncrement }}</strong>
      </s-badge>
    </s-badge-group>
  </s-tooltip>

  <p class="text-sm leading-snug whitespace-pre-wrap">
    {{ criteria.criteriaContent }}
  </p>
</template>
<script lang="ts" setup>
import {defineProps} from 'vue';
import SLatex from '@/components/SLatex.vue';
import STooltip from '../../../design-system/STooltip.vue';
import SBadgeGroup from '../../../design-system/SBadgeGroup.vue';
import SBadge from '../../../design-system/SBadge.vue';

defineProps<{
  criteria: any;
}>();

const generateGradeScaleString = (increment: number) => {
  if (increment <= 0 || increment > 1) return;

  const scale: string[] = [];
  for (let i = 0; i <= 1; i += increment) {
    scale.push(`${Math.round(i * 100)}%`);
  }

  if (scale[scale.length - 1] !== '100%') {
    scale.push('100%');
  }

  return scale.join(', ');
};
</script>
