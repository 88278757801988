<template>
  <div class="flex flex-col sm:flex-row flex-wrap gap-4">
    <s-input-field
      id="prepend"
      label="Prepend Text"
      description="Text that is placed at the beginning of the rank order input"
      v-model="prependText"
      :error="errors?.prependText"
    />
    <s-input-field
      id="append"
      label="Append Text"
      description="Text that is placed at the end of the rank order input"
      v-model="appendText"
      :error="errors?.appendText"
    />
  </div>
  <s-form-list
    id="rankSortOptions"
    title="Options"
    addLabel="Add Option"
    description="The available options to rank. The order you select here should be the correct order."
    :addItem="addOption"
    :items="clonedOptions"
    :removeItem="(item, index) => removeOption(index)"
    :sortable="true"
    placeholder="No Options Added"
    @update:items="
      clonedOptions = $event;
      syncOptions();
    "
  >
    <template #item="{index}">
      <div class="flex-1 w-full flex flex-wrap gap-x-3 gap-y-2 justify-start p-5">
        <div class="flex-1 max-w-[10rem] grow">
          <s-input-field
            :id="`options.${index}.key`"
            label="Key"
            :model-value="clonedOptions[index].key"
            :error="errors?.options?.[index]?.key"
            @update:modelValue="
              clonedOptions[index].key = $event;
              syncOptions();
            "
          />
        </div>
        <div class="flex-1 grow-[2]">
          <s-input-field
            :id="`options.${index}.label`"
            label="Label"
            :model-value="clonedOptions[index].label"
            :error="errors?.options?.[index]?.label"
            @update:modelValue="
              clonedOptions[index].label = $event;
              syncOptions();
            "
          />
        </div>
      </div>
    </template>
  </s-form-list>
  <s-input-field
    id="rankOrderPointsAvailable"
    v-model.number="points"
    type="number"
    label="Points Available (optional)"
    tooltip="When this is not set, the points available will be equal to 1"
    :error="errors?.points"
  />
</template>

<script setup lang="ts">
import SInputField from '../../../../design-system/SInputField.vue';
import SFormList from '../../../../design-system/SFormList.vue';
import OptionDto = App.DTOs.Tasks.OptionDto;
import {ref} from 'vue';
import {useVModels} from '@vueuse/core';

const props = defineProps<{
  prependText: string | null;
  appendText: string | null;
  options: OptionDto[] | null;
  errors?: Record<string, any>;
  points: number | null;
}>();

const emit = defineEmits([
  'update:options',
  'update:correctAnswers',
  'update:points',
  'update:prependText',
  'update:appendText',
]);
const {points, prependText, appendText} = useVModels(props, emit);

const clonedOptions = ref<OptionDto[]>(JSON.parse(JSON.stringify(props.options ?? [])));

const syncOptions = () => {
  emit('update:options', clonedOptions.value);
};

const addOption = () => {
  clonedOptions.value.push({key: '', label: ''});
  syncOptions();
};

const removeOption = (index: number) => {
  clonedOptions.value.splice(index, 1);
  syncOptions();
};
</script>
