<template>
  <s-textarea-field
    id="question"
    label="Question"
    description="The question that the student is asked"
    v-model="question"
    :error="errors?.question"
  />
  <div class="flex flex-col sm:flex-row flex-wrap gap-4">
    <s-input-field
      id="prepend"
      label="Prepend Text"
      description="Text that is prepended to the calculation input"
      v-model="prependText"
      :error="errors?.prependText"
    />
    <s-input-field
      id="append"
      label="Append Text"
      description="Text that is appended to the calculation input (e.g. units)"
      v-model="appendText"
      :error="errors?.appendText"
    />
  </div>
  <s-form-list
    title="Subexpressions"
    addLabel="Add Subexpression"
    :addItem="addSubexpression"
    v-model:items="subexpressions"
    :displayValue="(item?: any) => item?.name || 'Unnamed Subexpression'"
    :removeItem="(item: any, index: number) => removeSubexpression(index)"
    removeLabel="Remove Subexpression"
    :confirmRemove="true"
    placeholder="No Subexpressions Added"
    sortable
  >
    <template #item="{index}">
      <div class="p-5">
        <subexpression-form
          v-model:name="subexpressions[index].name"
          v-model:expression="subexpressions[index].expression"
          :errors="errors?.subexpressions?.[index]"
        />
      </div>
    </template>
  </s-form-list>
  <s-form-list
    title="Calculations"
    addLabel="Add Calculation"
    :addItem="addCalculation"
    :items="calculations"
    :displayValue="(item?: any) => item?.name || 'Unnamed Calculation'"
    :removeItem="(item: any, index: number) => removeCalculation(index)"
    removeLabel="Remove Calculation"
    :confirmRemove="true"
    placeholder="No Calculations Added"
  >
    <template #item="{index}">
      <div class="p-5">
        <calculation-form
          v-model:name="calculations[index].name"
          v-model:expression="calculations[index].expression"
          v-model:feedback="calculations[index].feedback"
          v-model:points="calculations[index].points"
          v-model:sig-figs="calculations[index].sigFigs"
          v-model:tolerance="calculations[index].tolerance"
          :errors="errors?.calculations?.[index]"
        />
      </div>
    </template>
  </s-form-list>
  <tolerance-input v-model="tolerance" :errors="errors?.tolerance" />
  <s-textarea-field
    id="fallback"
    label="Fallback Feedback"
    description="Feedback that is returned if the student submitted an answer and no calculation was matched."
    v-model="fallbackFeedback"
    :error="errors?.fallbackFeedback"
    rows="2"
  />
</template>

<script setup lang="ts">
import SFormList from '../../../../design-system/SFormList.vue';
import SInputField from '../../../../design-system/SInputField.vue';
import CalculationForm from './SingleCalculation/CalculationForm.vue';
import ToleranceInput from '../../SinglePartCalculation/ToleranceInput.vue';
import {useVModels} from '@vueuse/core';
import {calculationFactory} from '../../../../util/calculation';
import CalculationDto = App.DTOs.Tasks.CalculationDto;
import CalculationToleranceDto = App.DTOs.Tasks.CalculationToleranceDto;
import CalculationSubexpressionDto = App.DTOs.Tasks.CalculationSubexpressionDto;
import SubexpressionForm from './SingleCalculation/SubexpressionForm.vue';
import STextareaField from '../../../../design-system/STextareaField.vue';

const props = defineProps<{
  question: string | null;
  calculations: CalculationDto[];
  tolerance: CalculationToleranceDto | null;
  fallbackFeedback: string | null;
  prependText: string | null;
  appendText: string | null;
  subexpressions: CalculationSubexpressionDto[];
  errors?: Record<string, any>;
}>();

// While this isn't needed for "normal" use, we explicitly define the emits so the DataDrivenComponent knows which props are writable when it dynamically loads this component.
const emit = defineEmits([
  'update:question',
  'update:calculations',
  'update:subexpressions',
  'update:tolerance',
  'update:fallbackFeedback',
  'update:prependText',
  'update:appendText',
]);
const {question, prependText, appendText} = useVModels(props, emit);

const {calculations, subexpressions, tolerance, fallbackFeedback} = useVModels(props, emit);

const addCalculation = () => {
  calculations.value.push(calculationFactory());
};

const removeCalculation = (index: number) => {
  calculations.value.splice(index, 1);
};

const addSubexpression = () => {
  subexpressions.value.push({name: '', expression: ''});
};

const removeSubexpression = (index: number) => {
  subexpressions.value.splice(index, 1);
};
</script>
