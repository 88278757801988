<template>
  <s-textarea-field
    id="label"
    label="Label"
    description="Input label for the textarea for context"
    :model-value="label ?? ''"
    :error="errors?.label"
    @update:modelValue="emit('update:label', $event)"
  />
  <s-textarea-field
    id="textareaPlaceholder"
    label="Placeholder"
    :model-value="placeholder ?? ''"
    :error="errors?.text"
    @update:modelValue="emit('update:placeholder', $event)"
  />
</template>

<script setup lang="ts">
import STextareaField from '../../../../design-system/STextareaField.vue';

defineProps<{
  placeholder: string | null;
  label: string | null;
  errors?: Record<string, any>;
}>();

const emit = defineEmits<{
  (evt: 'update:placeholder', text: string): void;
  (evt: 'update:label', text: string): void;
}>();
</script>
