<template>
  <task-form :form="form" class="fieldset" />
</template>

<script setup lang="ts">
import TaskForm from '../../../forms/TaskForm.vue';
import {useForm} from '@inertiajs/vue3';
import TaskFormDto = App.DTOs.Tasks.TaskFormDto;

const props = defineProps<{
  modelValue?: TaskFormDto;
  isCopy?: boolean;
  errors?: Record<string, any>;
}>();

const form = useForm<TaskFormDto>(
  () =>
    props.modelValue || {
      type: '',
      title: '',
      status: 'draft',
      draftable: false,
      textbookLink: null,
      textbookLinkText: null,
      textbookLinkIcon: null,
      youtubeId: null,
    }
);
</script>
