<template>
  <Head :title="`${t('page.title')} - ${assignment.name} - ${assignment.course.code}`" />
  <s-page-block size="sm" class="flex flex-col gap-3">
    <s-page-header :overline="t('page.title')" :heading="assignment.name" />

    <p v-if="!timer.hasFinished" class="text-lg">
      {{ t('timedAssignment') }}
    </p>

    <p v-if="!timer.hasStarted && unclaimed.length">
      <i18n-t keypath="notStartedAndGrantedExtension">
        <template v-slot:numMinutes>
          <strong class="font-bold">
            {{
              t('time.xMinutes', {
                count: unclaimed.reduce((sum, extension) => sum + extension.minutes, 0),
              })
            }}
          </strong>
        </template>
        <template v-slot:baseMinutes>
          <strong class="font-bold">
            {{
              t('time.xMinutes', {
                count: props.assignmentTimelimitInMinutes,
              })
            }}
          </strong>
        </template>
      </i18n-t>
    </p>

    <s-message v-if="!timer.hasFinished" color="green" icon="timer-alert-outline" class="my-1">
      <p class="text-lg font-medium">
        <i18n-t keypath="totalMinutesWarning">
          <template v-slot:totalMinutes>
            <strong class="font-bold">
              {{ totalTimerTime }}
            </strong>
          </template>
        </i18n-t>
      </p>
    </s-message>

    <s-message v-if="timer.hasFinished" color="blue" icon="timer-alert-outline" class="mb-1">
      <p v-if="!claimed.length" class="text-lg">{{ t('baseTimeExpired') }}</p>
      <p v-else class="text-lg">
        {{ t('baseTimeAndExtensionsExpired', {count: claimed.length}) }}
      </p>
    </s-message>

    <template v-if="timer.hasFinished && unclaimed.length">
      <p class="text-lg">
        <i18n-t keypath="finishedAndGrantedExtension">
          <template v-slot:minutes>
            <strong class="font-bold">
              {{
                t('time.xMinutes', {
                  count: unclaimed.reduce((sum, extension) => sum + extension.minutes, 0),
                })
              }}
            </strong>
          </template>
        </i18n-t>
      </p>
      <p>{{ t('addedBelow') }}</p>
    </template>

    <div class="flex-1 flex flex-wrap items-start justify-between gap-3 pt-1">
      <s-btn
        class="flex-1 w-full max-w-[16rem]"
        color="white"
        icon="close"
        :href="route('courses.assignment.index', [assignment.course.id])"
      >
        {{ t('cancel') }}
      </s-btn>
      <s-btn
        :href="route('courses.assignment.startTimer', [assignment.course.id, assignment.id])"
        method="post"
        class="flex-1 max-w-[16rem] justify-self-end"
        icon="timer-outline"
      >
        {{ t('actions.startAssignment') }}
      </s-btn>
    </div>
  </s-page-block>
</template>
<script setup lang="ts">
import {useI18n} from 'vue-i18n';
import {Head} from '@inertiajs/vue3';
import SPageBlock from '../../design-system/SPageBlock.vue';
import SPageHeader from '../../design-system/SPageHeader.vue';
import SMessage from '../../design-system/SMessage.vue';
import SBtn from '../../design-system/SBtn.vue';
import {useBreadcrumbs} from '../../composables/useBreadcrumbs';
import {route} from 'ziggy-js';
import {Assignment} from '../../types/entities/assignment';
import {computed} from 'vue';
import UserAssignmentTimerDto = App.DTOs.UserAssignmentTimerDto;

const props = defineProps<{
  assignment: Assignment;
  assignmentTimelimitInMinutes: number;
  timer: UserAssignmentTimerDto;
  extensions: any[];
}>();

const {t} = useI18n({
  inheritLocale: true,
  scope: 'local',
});

useBreadcrumbs([
  {
    label: props.assignment.course.code,
    href: `/courses/${props.assignment.course.id}`,
    external: true,
  },
  {
    label: t('assignments'),
    href: route('courses.assignment.index', [props.assignment.course.id]),
  },
  {
    label: props.assignment.name,
    href: `/courses/${props.assignment.course.id}/assignments/${props.assignment.id}/overview`,
    external: true,
  },
]);

const unclaimed = computed(() => {
  return props.extensions.filter((ext) => !ext.claimed);
});

const claimed = computed(() => {
  return props.extensions.filter((ext) => ext.claimed);
});

const totalTimerTime = computed(() => {
  return (
    props.assignmentTimelimitInMinutes +
    unclaimed.value.reduce((sum, extension) => sum + extension.minutes, 0)
  );
});
</script>
<i18n>
{
  "en": {
    "page": {
      "title": "Start Assignment"
    },
    "timedAssignment": "This is a timed assignment. Once you begin, you will have a fixed amount of time to finish.",
    "notStartedAndGrantedExtension": "You have been granted an extension of {numMinutes} in addition to the base time of {baseMinutes} to complete this assignment.",
    "totalMinutesWarning": "You will have {totalMinutes} total minutes to complete this assignment.",
    "baseTimeExpired": "Your base time has expired.",
    "baseTimeAndExtensionsExpired": "Your base time and {count} extension has expired. | Your base time and {count} extensions have expired.",
    "finishedAndGrantedExtension": "You have been granted an extension of {minutes} to complete this assignment.",
    "addedBelow": "Your added time begins after starting the assignment below.",
    "actions": {
      "startAssignment": "Start Assignment"
    }
  },
  "fr": {
    "page": {
      "title": "Commencer le devoir"
    },
    "timedAssignment": "Il s'agit d'un devoir chronométré. Une fois que vous commencez, vous aurez un temps fixe pour le terminer.",
    "notStartedAndGrantedExtension": "Vous avez obtenu une prolongation de {numMinutes} en plus du temps de base de {baseMinutes} pour compléter ce devoir.",
    "totalMinutesWarning": "Vous disposerez de {totalMinutes} minutes au total pour compléter ce devoir.",
    "baseTimeExpired": "Votre temps de base est écoulé.",
    "baseTimeAndExtensionsExpired": "Votre temps de base et {count} prolongation sont écoulés. | Votre temps de base et {count} prolongations sont écoulés.",
    "finishedAndGrantedExtension": "Une prolongation de {minutes} vous a été accordée pour terminer ce devoir.",
    "addedBelow": "Votre temps ajouté commence après avoir démarré le devoir ci-dessous.",
    "actions": {
      "startAssignment": "Commencer le devoir"
    }
  }
}
</i18n>
