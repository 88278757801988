<template>
  <Head :title="`Admin - User Invitation - ${invitation.email}`"></Head>
  <div class="page-block gap-5">
    <s-link :href="route('admin.user-invites.index')" class="btn secondary md w-fit">Back</s-link>
    <div class="stack vertical gap-3">
      <h2 class="heading md">{{ invitation.email }}</h2>
      <p>{{ invitation.token }}</p>

      <button class="btn primary lg w-fit" @click="copy(invitationUrlSource)">
        Copy Invitation URL
      </button>
      <p class="card padded overflow-x-scroll break-keep whitespace-nowrap">
        {{ invitationUrl }}
      </p>
    </div>
  </div>
</template>
<script setup lang="ts">
import SLink from '../../../components/SLink.vue';
import {useClipboard} from '@vueuse/core';
import {ref} from 'vue';
import {UserInvitation} from '../../../types/entities/user-invitation';
import {route} from 'ziggy-js';
import {Head} from '@inertiajs/vue3';

const props = defineProps<{
  invitation: UserInvitation;
  invitationUrl: string;
}>();

const invitationUrlSource = ref(props.invitationUrl);
const {text, copy, copied, isSupported} = useClipboard({source: invitationUrlSource});
</script>
