<script setup lang="ts">
import SLatex from '../../../components/SLatex.vue';
import {Grade} from '../../../types/entities/base-grade';
import {useI18n} from 'vue-i18n';
const {t} = useI18n({
  inheritLocale: true,
  useScope: 'local',
});
const props = defineProps<{responseGrade: Grade | null}>();
</script>
<i18n>
{
  "en": {
    "gradeManuallyEnteredBy": "Grade manually entered by"
  },
  "fr": {
    "gradeManuallyEnteredBy": "Note saisie manuellement par"
  }
}
</i18n>
<template>
  <div>
    <s-latex
      v-if="responseGrade?.overrideReason"
      :content="responseGrade.overrideReason.replace(/(?:\r\n|\r|\n)/g, '<br>')"
      use-html
    />
    <div v-if="responseGrade?.submittedByName" class="text-gray-600 font-medium mt-2">
      {{ t('gradeManuallyEnteredBy') }}
      <span class="font-bold text-gray-600">{{ responseGrade.submittedByName }}</span>
    </div>
  </div>
</template>
