<template>
  <Head title="Edit Model Metadata" />
  <div class="page-block">
    <section>
      <h2 class="heading lg mb-2">Edit Model Metadata</h2>
      <form
        @submit.prevent="
          form.put(route('admin.ai-analytics.models.update', {llmMetadata: llmMetadata.id}))
        "
      >
        <div class="grid grid-cols-1 md:grid-cols-2 gap-4 mb-8">
          <s-input-field
            id="model"
            placeholder="gpt-4o"
            label="Model Name"
            v-model="form.model"
            :error="form.errors.model"
          />
          <s-select-field id="llmModel" label="Llm Provider" v-model="form.llmProvider">
            <option v-for="(label, key) in LlmProviderOptions" :value="key">
              {{ label }}
            </option>
          </s-select-field>
          <s-input-field
            tooltip="This value can only be updated in the token pricing history"
            type="number"
            readonly
            id="inputTokenPricingPerMillionTokens"
            label="Input Token Pricing Per Million Tokens"
            v-model="llmMetadata.inputTokenPricingPerMillionTokens"
          />
          <s-input-field
            tooltip="This value can only be updated in the token pricing history"
            type="number"
            readonly
            id="outputTokenPricingPerMillionTokens"
            label="Output Token Pricing Per Million Tokens"
            v-model="llmMetadata.outputTokenPricingPerMillionTokens"
          />
          <s-input-field
            type="number"
            step="1"
            min="0"
            id="maxInputContextWindow"
            label="Max Input Context Window"
            v-model="form.maxInputContextWindow"
            :error="form.errors.maxInputContextWindow"
          />
          <s-input-field
            id="maxOutputTokens"
            type="number"
            step="1"
            min="0"
            label="Max Output Tokens"
            v-model="form.maxOutputTokens"
            :error="form.errors.maxOutputTokens"
          />
          <s-field
            id="supportsAiImageGrading"
            label="Supports AI Image Grading"
            :error="form.errors.supportsAiImageGrading"
          >
            <s-button-toggle
              id="supportsAiImageGrading"
              :options="[
                {value: true, label: 'Yes'},
                {value: false, label: 'No'},
              ]"
              v-model="form.supportsAiImageGrading"
            />
          </s-field>
          <s-field
            id="supportsStructuredOutputs"
            label="Supports Structured Outputs"
            :error="form.errors.supportsStructuredOutputs"
          >
            <s-button-toggle
              id="supportsStructuredOutputs"
              :options="[
                {value: true, label: 'Yes'},
                {value: false, label: 'No'},
              ]"
              v-model="form.supportsStructuredOutputs"
            />
          </s-field>

          <s-field
            id="supportsTemperature"
            label="Supports Temperature"
            :error="form.errors.supportsTemperature"
          >
            <s-button-toggle
              id="supportsTemperature"
              :options="[
                {value: true, label: 'Yes'},
                {value: false, label: 'No'},
              ]"
              v-model="form.supportsTemperature"
            />
          </s-field>
          <s-field id="supportsTopP" label="Supports Top P" :error="form.errors.supportsTopP">
            <s-button-toggle
              id="supportsTopP"
              :options="[
                {value: true, label: 'Yes'},
                {value: false, label: 'No'},
              ]"
              v-model="form.supportsTopP"
            />
          </s-field>
        </div>
        <div class="stack horizontal gap-2">
          <s-btn type="submit" class="w-fit" :processing="form.processing">Save</s-btn>
          <s-btn color="secondary" :href="route('admin.ai-analytics.models.index')">Cancel</s-btn>
          <s-btn
            icon="calendar"
            color="secondary"
            :href="route('admin.ai-analytics.pricings.show', {llmMetadata: llmMetadata.id})"
          >
            Pricing History
          </s-btn>
        </div>
      </form>
    </section>
  </div>
</template>

<script setup lang="ts">
import {Head, useForm} from '@inertiajs/vue3';
import {route} from 'ziggy-js';
import SBtn from '../../../../design-system/SBtn.vue';
import {useBreadcrumbs} from '../../../../composables/useBreadcrumbs';
import SInputField from '../../../../design-system/SInputField.vue';
import SField from '../../../../design-system/SField.vue';
import LlmProviders = App.Enums.LlmProviders;
import SSelectField from '../../../../design-system/SSelectField.vue';
import SButtonToggle from '../../../../design-system/SButtonToggle.vue';
import {LlmMetadata} from '../../../../types/entities/llmMetadata';

const LlmProviderOptions: Record<LlmProviders, string> = {
  OpenAi: 'OpenAI',
  GoogleGemini: 'Google Gemini',
  Anthropic: 'Anthropic',
};

const props = defineProps<{
  llmMetadata: LlmMetadata;
}>();

const form = useForm({
  model: props.llmMetadata.model,
  llmProvider: props.llmMetadata.llmProvider,
  maxInputContextWindow: props.llmMetadata.maxInputContextWindow,
  maxOutputTokens: props.llmMetadata.maxOutputTokens,
  supportsAiImageGrading: props.llmMetadata.supportsAiImageGrading,
  supportsStructuredOutputs: props.llmMetadata.supportsStructuredOutputs,
  supportsTemperature: props.llmMetadata.supportsTemperature,
  supportsTopP: props.llmMetadata.supportsTopP,
});

useBreadcrumbs([{label: 'Models Metadata', href: route('admin.ai-analytics.models.index')}]);
</script>
