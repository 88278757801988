<template>
  <div
    v-if="items.length && shouldRender"
    @click.prevent
    class="border border-gray-250 rounded-lg overflow-hidden divide-y divide-y-gray-250 mb-1 bg-white shadow-card-soft"
    ref="listWrapperRef"
  >
    <template v-for="(item, index) in items" :key="keyFn(item, index)">
      <div
        class="group/row transition w-full flex justify-start items-stretch duration-150 ease-out divide-x divide-gray-200 bg-gradient-to-t from-background to-background/50"
      >
        <button
          v-if="sortable"
          class="handle text-gray-600 hover:text-blue-500 flex-none flex items-center justify-center cursor-grab group/handle grid grid-cols-1 grid-rows-1 py-2.5 pl-3 pr-2.5 opacity-30 hover:opacity-100 transition-all ease-out duration-150 bg-white hover:bg-gray-200/20"
        >
          <span
            class="transition-all ease-out duration-150 group-hover/handle:opacity-0 row-start-1 col-start-1 grid grid-cols-1 grid-rows-1"
          >
            <s-icon
              name="dots-vertical"
              size="20"
              class="-translate-x-[0.1875rem] row-start-1 col-start-1"
            />
            <s-icon
              name="dots-vertical"
              size="20"
              class="translate-x-[0.1875rem] row-start-1 col-start-1"
            />
          </span>
          <s-icon
            name="unfold-more-horizontal"
            size="20"
            class="text-blue-500 row-start-1 col-start-1 transition-all ease-out duration-150 opacity-0 group-hover/handle:opacity-100"
          />
        </button>
        <div class="flex-1 w-full" @click.prevent="itemClicked(item, index)">
          <slot name="item" v-bind="{item, index}">
            <div class="px-5 py-3">
              {{ displayValue?.(item) ?? item }}
            </div>
          </slot>
        </div>
        <button
          v-if="removable"
          @click.prevent="tryToRemoveItem(item, index)"
          class="text-red-300 hover:text-red-500 flex-none flex items-center justify-center py-2.5 pl-2.5 pr-3 opacity-70 hover:opacity-100 transition-all ease-out duration-150 bg-white hover:bg-gray-200/20"
        >
          <s-icon name="trash-can-outline" size="20" />
        </button>
      </div>
    </template>
  </div>
  <s-placeholder v-else class="mb-1">
    <slot name="placeholder" :placeholder="placeholder">
      {{ placeholder || 'No items' }}
    </slot>
  </s-placeholder>

  <s-modal
    v-model:open="showRemoveConfirmationModal"
    :title="removeLabel || 'Delete Item'"
    :message="`Are you sure you want to delete ${displayValue?.(itemToRemove) ?? 'this item'}?`"
    :confirm="{
      label: 'Delete',
      icon: 'trash-can',
      color: 'red',
      callback: () => tryToRemoveItem(itemToRemove, items.indexOf(itemToRemove)),
    }"
    cancellable
  />
</template>

<script setup lang="ts">
import {ref, watchEffect} from 'vue';
import {useSortable} from '@vueuse/integrations/useSortable';
import SModal from './SModal.vue';
import SPlaceholder from './SPlaceholder.vue';
import {useForceRerender} from '../composables/useForceRerender';
import SIcon from './SIcon.vue';

const props = defineProps<{
  items: any[];
  placeholder?: string;
  itemKey?: string | ((item: any) => string);
  displayValue?: (item: any) => string;
  removeLabel?: string;
  confirmRemove?: boolean;
  sortable?: boolean;
  removable?: boolean;
}>();

const emit = defineEmits(['update:items', 'itemClicked', 'itemMoved', 'itemRemoved']);

const {forceRerender, show: shouldRender} = useForceRerender();

const showRemoveConfirmationModal = ref(false);
const itemToRemove = ref<any>(null);
const listWrapperRef = ref<HTMLElement | null>(null);

watchEffect(() => {
  if (!props.sortable || !listWrapperRef.value) {
    return;
  }

  useSortable(listWrapperRef.value, props.items, {
    handle: '.handle',
    animation: 150,
    onUpdate: (evt: any) => {
      emit('itemMoved', evt.newIndex, evt.oldIndex);

      const clonedItems: typeof props.items = JSON.parse(JSON.stringify(props.items));

      const [item] = clonedItems.splice(evt.oldIndex, 1);
      clonedItems.splice(evt.newIndex, 0, item);

      emit('update:items', clonedItems);

      // We force Vue to re-render the component so that the component is always
      // displaying the current value of the items array and not the one that Sortable.js may have moved in the DOM
      forceRerender();
    },
  });
});

const tryToRemoveItem = (item: any, index: number) => {
  if (props.confirmRemove && !showRemoveConfirmationModal.value) {
    itemToRemove.value = item;
    showRemoveConfirmationModal.value = true;
  } else {
    emit('itemRemoved', {item, index});
    showRemoveConfirmationModal.value = false;
  }
};

const itemClicked = (item: any, index: number) => {
  emit('itemClicked', {item, index});
};

const keyFn = (item: any, index: number) => {
  if (typeof props.itemKey === 'string') {
    return item[props.itemKey];
  } else if (typeof props.itemKey === 'function') {
    return props.itemKey(item);
  } else {
    return index;
  }
};
</script>

<style scoped>
.sortable-ghost {
  @apply bg-gray-150 text-gray-400;
}

.sortable-drag {
  @apply rounded-lg border border-gray-200 shadow-xl;
}
</style>
