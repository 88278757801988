import {EditorState, Plugin, PluginView} from 'prosemirror-state';
import {EditorView} from 'prosemirror-view';
import {
  AppContext,
  Component,
  createVNode,
  getCurrentInstance,
  reactive,
  ref,
  Ref,
  render,
  VNode,
} from 'vue';
import {computePosition, autoUpdate, shift} from '@floating-ui/vue';
import LinkMenu from './LinkMenu.vue';

export class VueLinkMenuView implements PluginView {
  appContext: AppContext;
  dom: HTMLElement;
  vnode: VNode | null;
  view: EditorView | null = null;
  cleanupFloatingUI: (() => void) | null = null;

  constructor() {
    const currentInstance = getCurrentInstance();
    this.appContext = currentInstance?.appContext!;
    this.vnode = null;
    this.dom = document.createElement('div');
    this.dom.style.display = 'none';
    this.dom.style.position = 'absolute';
    this.dom.style.width = 'max-content';
    this.dom.style.zIndex = '1000';
    this.dom.style.top = '0';
    this.dom.style.left = '0';
  }

  /**
   * Sets the EditorView and sets up the dom element.
   *
   * @param view
   */
  create(view: EditorView) {
    this.view = view;
    view.dom.parentNode?.appendChild(this.dom);
  }

  /**
   * Updates the EditorView
   *
   * @param view
   * @param lastState
   */
  update(view: EditorView, lastState: EditorState | null) {
    this.view = view;
  }

  /**
   *
   */
  destroy() {
    if (this.cleanupFloatingUI) {
      this.cleanupFloatingUI();
    }

    this.dom.remove();
  }

  beginEditing(target: HTMLElement, pos: number) {
    this.positionDom(target);
    this.render(pos);
  }

  stopEditing() {
    /*
     * Cleanup Floating UI
     */
    if (this.cleanupFloatingUI) {
      this.cleanupFloatingUI();
    }

    /*
     * Hide DOM element
     */
    this.dom.style.display = 'none';

    /*
     * Unmount Vue component
     */
    if (this.vnode?.component) {
      render(null, this.dom);
    }
  }

  private positionDom(target: HTMLElement) {
    this.dom.style.display = 'block';

    this.cleanupFloatingUI = autoUpdate(target, this.dom, () => {
      computePosition(target, this.dom, {
        middleware: [shift()],
      }).then(({x, y}) => {
        Object.assign(this.dom.style, {
          left: `${x}px`,
          top: `${y}px`,
        });
      });
    });
  }

  private render(pos: number) {
    this.vnode = createVNode(LinkMenu as any, {
      pos,
      view: this.view,
      onClose: () => {
        this.stopEditing();
      },
    });

    this.vnode.appContext = this.appContext;

    render(this.vnode, this.dom);
  }

  toPlugin() {
    return new Plugin({
      props: {
        handleClick: (view, pos, event) => {
          if (!view.editable) {
            return;
          }

          // @ts-ignore
          let isLink = event.target?.nodeName === 'A';

          if (isLink) {
            let link = event.target as HTMLAnchorElement;

            this.beginEditing(link, pos);
          } else {
            this.stopEditing();
          }
        },
      },
      view: (view) => {
        this.create(view);

        return this;
      },
    });
  }
}
