<template>
  <img
    :src="node.attrs.src"
    :alt="node.attrs.alt"
    :width="node.attrs.width || 'auto'"
    style="max-width: 100%"
    class="inline-block border border-gray-200 rounded-md"
    :class="{
      'cursor-pointer hover:shadow hover:border-primary-400': view.editable,
    }"
    @click="editorModalOpen = true"
  />

  <s-drawer
    :open="view.editable && editorModalOpen"
    @close="editorModalOpen = false"
    :title="t('modal.title')"
  >
    <div class="p-4 w-full overflow-scroll">
      <s-input-field
        id="src"
        :label="t('label.imageUrl')"
        :model-value="node.attrs.src"
        @update:model-value="updateSrc"
        class="mb-4"
      />
      <s-input-field
        id="alt"
        :label="t('label.alt')"
        :model-value="node.attrs.alt"
        @update:model-value="updateAlt"
        class="mb-4"
      />
      <s-input-field
        id="width"
        :label="t('label.width')"
        :model-value="node.attrs.width"
        @update:model-value="updateWidth"
      />
    </div>
  </s-drawer>
</template>
<script setup lang="ts">
import {Node} from 'prosemirror-model';
import SInputField from '../../../design-system/SInputField.vue';
import SDrawer from '../../../design-system/SDrawer.vue';
import SBtn from '../../../design-system/SBtn.vue';
import {ref} from 'vue';
import {EditorView} from 'prosemirror-view';
import {useI18n} from 'vue-i18n';

const props = defineProps<{
  node: Node;
  view: EditorView;
  getPos: () => number | undefined;
}>();

const editorModalOpen = ref(false);

const {t} = useI18n();

const updateSrc = (src: string) => {
  const {view, getPos} = props;
  const {state, dispatch} = view;
  const {tr} = state;

  const pos = getPos();
  if (pos === undefined) {
    return;
  }

  const node = tr.doc.nodeAt(pos);
  if (!node) {
    return;
  }

  props.view.dispatch(
    props.view.state.tr.setNodeMarkup(pos, null, {
      ...node.attrs,
      src,
    })
  );
};

const updateAlt = (alt: string) => {
  const {view, getPos} = props;
  const {state, dispatch} = view;
  const {tr} = state;

  const pos = getPos();
  if (pos === undefined) {
    return;
  }

  const node = tr.doc.nodeAt(pos);
  if (!node) {
    return;
  }

  props.view.dispatch(
    props.view.state.tr.setNodeMarkup(pos, null, {
      ...node.attrs,
      alt,
    })
  );
};

const updateWidth = (width: string) => {
  const {view, getPos} = props;
  const {state, dispatch} = view;
  const {tr} = state;

  const pos = getPos();
  if (pos === undefined) {
    return;
  }

  const node = tr.doc.nodeAt(pos);
  if (!node) {
    return;
  }

  props.view.dispatch(
    props.view.state.tr.setNodeMarkup(pos, null, {
      ...node.attrs,
      width,
    })
  );
};
</script>
<i18n>
{
  "en": {
    "modal": {
      "title": "Edit Image"
    },
    "label": {
      "alt": "Alt Text",
      "imageUrl": "Image URL",
      "width": "Width"
    }
  },
  "fr": {
    "modal": {
      "title": "Modifier l'image"
    },
    "label": {
      "alt": "Texte alternatif",
      "imageUrl": "URL de l'image",
      "width": "Largeur"
    }
  }
}
</i18n>
