<template>
  <div class="flex flex-wrap gap-x-2 gap-y-3">
    <s-btn>Default</s-btn>
    <s-btn color="primary">Primary</s-btn>
    <s-btn color="primary-light">Primary Light</s-btn>
    <s-btn color="secondary">Secondary</s-btn>
    <s-btn color="danger">Danger</s-btn>
    <s-btn color="danger-white">Danger White</s-btn>
  </div>
</template>
<script setup lang="ts">
import SBtn from '../../../../design-system/SBtn.vue';
</script>
<script lang="ts">
import {ExampleOptions} from '../../examples';

const description = `Color is a powerful way to communicate the meaning of a button.

Use color to indicate the primary action, secondary action, or a destructive action.`;

export const options: ExampleOptions = {
  description,
};
</script>
