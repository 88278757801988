<template>
  <component :is="notificationComponent" :notification="notification" />
</template>
<script setup lang="ts">
import {defineAsyncComponent} from 'vue';
import {LaravelNotification} from '../types/laravel-notifications';

const {notification} = defineProps<{notification: LaravelNotification}>();

/**
 *
 * # Component
 * Used to render the notification's type-specific component
 *
 * The `type` will is the fully qualified class name of the PHP notification class.
 *
 * ## Example
 * The type: `App\Notifications\ExampleNotification`
 * Will lookup: `./notifications/ExampleNotification.vue`
 */
const notificationType = notification.type.split('\\').reverse()[0];
const notificationComponent = defineAsyncComponent(
  () => import(`./notifications/${notificationType}.vue`)
);
</script>
