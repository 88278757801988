import {Command, Plugin, PluginView} from 'prosemirror-state';
import {reactive, Reactive} from 'vue';
import {EditorView} from 'prosemirror-view';
import {setBlockType, toggleMark} from 'prosemirror-commands';
import {Schema} from 'prosemirror-model';
import {isHeading} from '../utils/isHeading';
import {isMarked} from '../utils/isMarked';

export type MenuViewItem = {
  icon: string;
  label: string;
  command: Command;
  available?: boolean;
  active?: boolean;

  checkActive: (state: EditorView['state']) => boolean;
};

/**
 * -----------------------------------------------------------------------------
 * MenuView
 * -----------------------------------------------------------------------------
 *
 * This PluginView is responsible for managing a ref for the menu items
 * and updating their availability based on the current state of the editor.
 *
 */
export class MenuView implements PluginView {
  schema: Schema;
  items: Reactive<MenuViewItem[]>;
  editorView: EditorView | null = null;

  constructor(schema: Schema, items: MenuViewItem[]) {
    this.schema = schema;

    if (schema.nodes.heading) {
      items.push({
        label: 'H1',
        icon: '',
        command: setBlockType(schema.nodes.heading, {level: 1}),
        checkActive: isHeading(1),
      });

      items.push({
        label: 'H2',
        icon: '',
        command: setBlockType(schema.nodes.heading, {level: 1}),
        checkActive: isHeading(2),
      });

      items.push({
        label: 'H3',
        icon: '',
        command: setBlockType(schema.nodes.heading, {level: 1}),
        checkActive: isHeading(3),
      });
    }

    if (schema.marks.strong) {
      items.push({
        label: 'B',
        icon: '',
        command: toggleMark(schema.marks.strong),
        checkActive: isMarked(schema.marks.strong),
      });
    }

    if (schema.marks.em) {
      items.push({
        label: 'I',
        icon: '',
        command: toggleMark(schema.marks.em),
        checkActive: isMarked(schema.marks.em),
      });
    }

    this.items = reactive(items);
  }

  /**
   * Triggers the command associated with the menu item.
   *
   * @param item
   */
  handleClick(item: MenuViewItem) {
    if (!this.editorView) return;

    item.command(this.editorView.state, this.editorView.dispatch, this.editorView);

    this.editorView.focus();
  }

  /**
   * Updates the availability of the menu items based on the current state of the editor.
   */
  update() {
    this.items.forEach((item) => {
      if (!this.editorView) return item;

      /**
       * When a `Command` is called without a `dispatch` function, it simply a boolean value
       * indicating whether the command can be executed or not.
       */
      item.available = item.command(this.editorView.state, undefined, this.editorView);

      item.active = item.checkActive(this.editorView.state);

      return item;
    });
  }

  toPlugin = () => {
    return new Plugin({
      view: (editorView) => {
        this.editorView = editorView;
        return this;
      },
    });
  };
}
