<template>
  <div
    class="w-full h-screen rounded-lg bg-[radial-gradient(circle,_rgba(0,0,0,0.2)_1px,_transparent_1px)] bg-[size:16px_16px] grid grid-flow-row auto-rows-max gap-3 place-items-start overflow-auto"
    :class="{
      'px-7 py-6': padded,
    }"
  >
    <example-component v-bind="exampleProps" />
  </div>
</template>
<script setup lang="ts">
import {defineAsyncComponent, ref} from 'vue';
import {ExampleOptions} from './examples';

defineOptions({
  layout: (h: any, page: any) => h(page),
});

const {group, exampleProps, componentName} = defineProps<{
  group?: string;
  exampleProps: any;
  componentName: string;
  code: string;
}>();

const padded = ref(true);

const ExampleComponent = defineAsyncComponent({
  loader: async () => {
    let component;

    if (group) {
      component = await import(`./Examples/${group}/${componentName}.vue`);
    } else {
      component = await import(`./Examples/${componentName}.vue`);
    }

    let options: ExampleOptions = component.options || {};

    if (typeof options.padding !== 'undefined') {
      padded.value = options.padding;
    }

    return component;
  },
});
</script>
