<script setup lang="ts">
import MeanCountOfFeedbackKeys from './Tasks/MeanCountOfFeedbackKeys.vue';
import CourseAnalyticsHeader from './Index/CourseAnalyticsHeader.vue';
import {Course} from '../../types/entities/course';
import MeanIncorrectResponsesBarchart from './Tasks/MeanIncorrectResponsesBarchart.vue';
import {GradeDistribution} from '../../types/GradeDistribution';
import {useI18n} from 'vue-i18n';

defineProps<{
  course: Course;
  assignments:
    | {
        name: string;
        gradeDistribution: GradeDistribution;
      }[]
    | null;
  errorBreakdown: {[key: string]: number} | null;
}>();

const {t} = useI18n();
</script>
<i18n>
{
  "en": {
    "noData": "Please check back later for data.",
    "entireAssignment": "This is for the entire course on all assignments."
  },
  "fr": {
    "noData": "Veuillez revenir plus tard pour les données.",
    "entireAssignment": "Ceci est pour l'ensemble du cours sur tous les devoirs."
  }
}
</i18n>
<template>
  <course-analytics-header :course="course" />
  <section class="section-block gap-5">
    <div class="card padded">
      <mean-incorrect-responses-barchart v-if="assignments" :data="assignments" />
      <span v-else>{{ t('noData') }}</span>
    </div>
    <div class="card padded">
      <mean-count-of-feedback-keys
        v-if="errorBreakdown"
        :data="errorBreakdown"
        :data-scope="t('entireAssignment')"
      />
      <span v-else>{{ t('noData') }}</span>
    </div>
  </section>
</template>
