<template>
  <div
    class="overflow-hidden first:rounded-t-lg last:rounded-b-lg border border-t-0 first:border-t border-red-100 bg-gradient-to-br from-red-100/70 via-red-50/50 to-transparent bg-no-repeat px-3 py-2.5 flex flex-col gap-1.5"
  >
    <p class="text-red-600 font-medium leading-tight flex items-center gap-1">
      <span class="font-black whitespace-nowrap">{{ t('row') }} {{ props.change.row }}:</span>
      <span class="">{{ errorMessage }}</span>
    </p>
    <div class="grid grid-flow-col auto-cols-auto gap-y-2 gap-x-4">
      <div v-for="column in columns" :key="column.label" class="flex flex-col items-start">
        <span
          class="capitalize text-sm leading-tighter"
          :class="column.conflict ? 'text-red-600/70 font-bold' : 'text-gray-600/85 font-medium'"
        >
          {{ column.label }}
        </span>
        <span
          v-if="column.value !== ''"
          class="flex items-center gap-0.5"
          :class="column.conflict ? 'font-black text-red-600' : 'font-medium'"
        >
          <s-icon v-if="column.conflict" name="alert" size="1.125em" class="opacity-70" />
          {{ column.value }}
        </span>
        <span v-else class="font-medium opacity-30" :class="column.conflict ? 'text-red-600' : ''">
          &ndash;&ndash;
        </span>
      </div>
    </div>
  </div>
</template>
<script setup lang="ts">
import {computed, defineProps} from 'vue';
import {useI18n} from 'vue-i18n';
import RosterChangeDto = App.DTOs.Roster.RosterChangeDto;
import SIcon from '../../../design-system/SIcon.vue';

const props = defineProps<{
  change: RosterChangeDto;
}>();

const {t} = useI18n({
  useScope: 'local',
  inheritLocale: true,
});

const errorMessage = computed(() => {
  const errors = props.change.errors || [];
  const conjunction = t('conjunction');
  let message = '';

  if (errors.length === 0) {
    message = '';
  } else if (errors.length === 1) {
    message = t(`errors.${errors[0]}`);
  } else if (errors.length === 2) {
    message = `${t(`errors.${errors[0]}`)} ${conjunction} ${t(`errors.${errors[1]}`)}`;
  } else {
    message = `${errors
      .slice(0, -1)
      .map((error) => t(`errors.${error}`))
      .join(', ')}, ${conjunction} ${t(`errors.${errors[errors.length - 1]}`)}`;
  }

  return message.charAt(0).toUpperCase() + message.slice(1) + '.';
});

const errors = computed(() => {
  return props.change.errors || [];
});

const columns = computed(() => {
  let columns = [
    {
      label: t('row'),
      value: props.change.row,
      conflict: errors.value.some((error) => errorKeyMap[error] === 'row'),
    },
    {
      label: t('email'),
      value: props.change.email,
      conflict: errors.value.some((error) => errorKeyMap[error] === 'email'),
    },
    {
      label: t('section'),
      value: props.change.sectionName,
      conflict: errors.value.some((error) => errorKeyMap[error] === 'sectionName'),
    },
  ];

  if (props.change.studentId) {
    columns.push({label: t('studentId'), conflict: false, value: props.change.studentId || '–'});
  }
  if (props.change.firstName) {
    columns.push({label: t('firstName'), conflict: false, value: props.change.firstName || '–'});
  }

  if (props.change.lastName) {
    columns.push({label: t('lastName'), conflict: false, value: props.change.lastName || '–'});
  }

  return columns;
});

const errorKeyMap = {
  'section-dne': 'sectionName',
  'unauthorized-section': 'sectionName',
  'unauthorized-role': 'role',
  'invalid-email': 'email',
  'invalid-role': 'role',
  'unauthorized-role-change': 'role',
  'already-staff': 'role',
  'student-and-staff': 'role',
  'student-multiple-sections': 'sectionName',
  'multiple-roles-in-one-section': 'role',
};
</script>
<i18n>
{
  "en": {
    "errors": {
      "section-dne": "the section you specified doesn't exist",
      "unauthorized-section": "you aren't allowed to manage the given Section",
      "unauthorized-role": "you aren't allowed to grant the given Role",
      "invalid-email": "the email address you provided is invalid",
      "invalid-role": "the role you specified doesn't exist",
      "unauthorized-role-change": "you are trying to change a User's Role, but you do not have permission to change that kind of Role",
      "already-staff": "you are trying to make an existing Staff member a Student",
      "student-and-staff": "you are trying to make a user BOTH a student AND a staff member",
      "student-multiple-sections": "you are trying to put a Student into multiple sections",
      "multiple-roles-in-one-section": "you are trying to grant a user multiple roles in the same section"
    },
    "conjunction": "and",
    "row": "Row"
  },
  "fr": {
    "errors": {
      "section-dne": "la section que vous avez spécifiée n'existe pas",
      "unauthorized-section": "vous n'êtes pas autorisé à gérer la section donnée",
      "unauthorized-role": "vous n'êtes pas autorisé à attribuer le rôle donné",
      "invalid-email": "l'adresse e-mail que vous avez fournie est invalide",
      "invalid-role": "le rôle que vous avez spécifié n'existe pas",
      "unauthorized-role-change": "vous essayez de modifier le rôle d'un utilisateur, mais vous n'avez pas l'autorisation de modifier ce type de rôle",
      "already-staff": "vous essayez de transformer un membre du personnel existant en étudiant",
      "student-and-staff": "vous essayez de faire d'un utilisateur à la fois un étudiant ET un membre du personnel",
      "student-multiple-sections": "vous essayez de placer un étudiant dans plusieurs sections",
      "multiple-roles-in-one-section": "vous essayez d'attribuer plusieurs rôles à un utilisateur dans la même section"
    },
    "conjunction": "et",
    "row": "Ligne"
  }
}
</i18n>
