<template>
  <!-- TAB 3: Review Changes -->
  <div :class="[classes.tabPane, 'gap-5']">
    <template v-if="additions.length || roleChanges.length || removals.length || unchanged.length">
      <diff-staff-additions v-if="additions.length" :additions="additions" />
      <diff-staff-role-changes v-if="roleChanges.length" :role-changes="roleChanges" />
      <diff-staff-removals v-if="removals.length" :removals="removals" />
      <diff-staff-unchanged v-if="unchanged.length" :unchanged="unchanged" />
      <s-checkbox id="newStudents" v-model="form.sendInvites">
        <span v-html="t('sendInvitationStatement')"></span>
      </s-checkbox>
    </template>
    <p v-else>{{ t('noValidChanges') }}</p>
  </div>
  <div :class="classes.tabFooter">
    <s-btn color="secondary" icon="arrow-left" @click="$emit('back')" class="flex-1 max-w-[16rem]">
      {{ t('actions.back') }}
    </s-btn>
    <s-btn
      :disabled="!(additions.length || roleChanges.length || removals.length || unchanged.length)"
      :processing="processingChanges"
      @click="$emit('submit')"
      icon="floppy"
      color="primary"
      class="flex-1 max-w-[16rem]"
    >
      {{ t('form.save') }}
    </s-btn>
  </div>
</template>
<script setup lang="ts">
import SBtn from '../../../design-system/SBtn.vue';
import {useI18n} from 'vue-i18n';
import DiffStaffAdditions from './DiffStaffAdditions.vue';
import DiffStaffRemovals from './DiffStaffRemovals.vue';
import DiffStaffUnchanged from './DiffStaffUnchanged.vue';
import DiffStaffRoleChanges from './DiffStaffRoleChanges.vue';
import RosterChangeDto = App.DTOs.Roster.RosterChangeDto;
import SCheckbox from '../../../design-system/SCheckbox.vue';
import {InertiaForm} from '@inertiajs/vue3';
import RosterFormDto = App.DTOs.Roster.RosterFormDto;

const props = defineProps<{
  processingChanges: boolean;
  classes: any;
  removals: RosterChangeDto[];
  additions: RosterChangeDto[];
  roleChanges: RosterChangeDto[];
  unchanged: RosterChangeDto[];
  form: InertiaForm<RosterFormDto>;
}>();

defineEmits(['submit', 'back']);

const {t} = useI18n({
  inheritLocale: true,
  useScope: 'local',
});
</script>
<i18n>
{
  "en":{
    "sendInvitationStatement": "Send an invitation to <strong class=\"font-black\">new</strong> staff members",
    "noValidChanges": "Sorry, there's no valid changes to make."
  },
  "fr": {
    "sendInvitationStatement": "Envoyer une invitation aux <strong class=\"font-black\">nouveaux</strong> membres du personnel",
    "noValidChanges": "Désolé, il n'y a aucun changement valide à effectuer."
  }
}
</i18n>
