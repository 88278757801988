<template>
  <task-form :errors="errors" :form="form" class="fieldset">
    <s-input-field
      id="heading"
      label="Heading"
      description="Displayed at the top of the task in large font. This is the title visible to students."
      :required="false"
      v-model="form.ui.heading"
      :error="errors?.['ui.heading']"
    />
    <div class="flex flex-wrap gap-4 mb-1">
      <s-field id="showGradeBreakdown" label="Display Grade Breakdown">
        <s-button-toggle
          id="showGradeBreakdown"
          :options="[
            {value: false, label: 'No'},
            {value: true, label: 'Yes'},
          ]"
          v-model="form.grader.showGradeBreakdown"
        />
      </s-field>
      <s-input-field
        id="Subject"
        label="Subject"
        :required="false"
        v-model="form.grader.subject"
        :error="errors?.['grader.subject']"
      />
    </div>
    <s-field id="questions" label="Questions" :error="errors?.['grader.questions']">
      <s-btn
        @click.prevent="addQuestion()"
        :disabled="form.grader.questions.length >= 15"
        class="mt-2"
        icon="plus"
        size="sm"
      >
        Add Question
      </s-btn>
    </s-field>
    <div class="card padded" v-for="(question, index) in form.grader.questions" :key="index">
      <s-input-field
        class="mt-2 mb-2"
        id="questionText"
        label="Question"
        v-model="form.ui.questions[index].questionText"
        :error="errors?.['ui.questions.' + index + '.questionText']"
      />
      <s-textarea-field
        class="mt-2 mb-2"
        id="rubric"
        label="Rubric"
        v-model="question.rubric"
        :error="errors?.['grader.questions.' + index + '.rubric']"
      />
      <s-input-field
        class="mt-2 mb-2"
        id="part"
        label="Feedback Part Title"
        description="The feedback title associating feedback with the specification."
        v-model="question.feedbackPartTitle"
        :error="errors?.['grader.questions.' + index + '.feedbackPartTitle']"
      />
      <s-input-field
        class="mt-2 mb-2"
        id="score"
        type="number"
        step="1"
        min="0"
        label="Question Weight"
        v-model="question.scoreMaxValue"
        :error="errors?.['grader.questions.' + index + '.scoreMaxValue']"
      />
      <grade-increment-field
        v-model="form.grader.questions[index].gradeIncrement"
        :errors="errors?.['grader.questions.' + index + '.gradeIncrement']"
      />

      <s-field
        class="mt-2 mb-2"
        id="showCharacterLimit"
        label="Character Limit to Student Response"
      >
        <div class="flex items-center space-x-2">
          <s-button-toggle
            id="showCharacterLimit"
            :options="[
              {value: null, label: 'No'},
              {value: form.ui.questions[index].characterLimit ?? 0, label: 'Yes'},
            ]"
            v-model="form.ui.questions[index].characterLimit"
          />
          <template v-if="form.ui.questions[index].characterLimit !== null">
            <s-input-field
              id="characterLimit"
              :required="false"
              type="number"
              min="0"
              v-model="form.ui.questions[index].characterLimit"
            />
          </template>
        </div>
      </s-field>
      <s-btn @click="removeQuestion(index)" class="mt-2" icon="trash-can" size="sm" color="danger">
        Remove
      </s-btn>
    </div>
  </task-form>
</template>
<script lang="ts" setup>
import SInputField from '../../../design-system/SInputField.vue';
import SBtn from '../../../design-system/SBtn.vue';
import SButtonToggle from '../../../design-system/SButtonToggle.vue';
import SField from '../../../design-system/SField.vue';
import STextareaField from '../../../design-system/STextareaField.vue';
import GradeIncrementField from '../../../components/GradeIncrementField.vue';
import TaskForm from '../../../forms/TaskForm.vue';
import {useForm} from '@inertiajs/vue3';
import AiMultipleQuestionFormDto = App.DTOs.Tasks.AiMultipleQuestionFormDto;
import AiWrittenQuestionUIDto = App.DTOs.Tasks.AiWrittenQuestionUIDto;
import AiWrittenQuestionDto = App.DTOs.Tasks.AiWrittenQuestionDto;

const props = defineProps<{
  modelValue?: AiMultipleQuestionFormDto;
  isCopy?: boolean;
  errors?: Record<string, any>;
}>();

const form = useForm<AiMultipleQuestionFormDto>(
  () =>
    props.modelValue || {
      title: '',
      textbookLink: null,
      textbookLinkText: null,
      textbookLinkIcon: null,
      youtubeId: null,
      type: 'AiMultipleQuestion',
      status: 'draft',
      draftable: true,
      ui: {
        heading: '',
        questions: [] as AiWrittenQuestionUIDto[],
      },
      grader: {
        questions: [] as AiWrittenQuestionDto[],
        showGradeBreakdown: false,
        subject: 'Chemistry',
      },
    }
);

function addQuestion() {
  form.grader.questions.push({
    rubric: '',
    scoreMaxValue: 1,
    gradeIncrement: 0.05,
    feedbackPartTitle: '',
  });

  form.ui.questions.push({
    questionText: '',
    characterLimit: null,
  });
}

function removeQuestion(index: number) {
  form.grader.questions.splice(index, 1);
  form.ui.questions.splice(index, 1);
}
</script>
