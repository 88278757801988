<template>
  <div
    class="autogrow-wrapper after:block after:inset-0 after:w-full after:px-4 after:py-2.5 after:leading-tight after:font-normal after:text-transparent after:bg-transparent after:border after:border-transparent"
    :data-replicated-value="model"
  >
    <textarea
      class="px-4 py-2.5 leading-tight rounded-md border border-gray-200 shadow-inner bg-white"
      v-model="model"
    />
  </div>
</template>
<script setup lang="ts">
const model = defineModel<string | number | null | undefined>();
</script>
<style>
.autogrow-wrapper {
  display: grid;
}

.autogrow-wrapper:after {
  content: attr(data-replicated-value) ' ' !important;
  white-space: pre-wrap;
  visibility: hidden;
  min-height: 6.5rem;
}
.autogrow-wrapper > textarea {
  resize: vertical;
  overflow: hidden;
}

.autogrow-wrapper > textarea,
.autogrow-wrapper:after {
  grid-area: 1 / 1 / 2 / 2;
}
</style>
