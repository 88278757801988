<template>
  <span v-if="(!taskTypes || taskTypes.length === 0) && !$slots.default" class="opacity-30">
    &ndash;
  </span>
  <div v-else class="flex-1 flex items-center flex-wrap gap-2">
    <template v-if="$slots.default">
      <slot />
    </template>
    <task-badge
      v-for="taskType in taskTypes"
      :key="taskType"
      :icon="taskTypeIconMap[taskType] || taskTypeDefault.icon"
      :label="taskType"
    />
  </div>
</template>

<script setup lang="ts">
import {defineProps} from 'vue';
import TaskBadge from './TaskBadge.vue';

defineProps<{
  taskTypes?: string[];
}>();

const taskTypeIconMap: Record<string, string> = {
  'True / False': 'check-circle-outline',
  Algorithmic: 'calculator',
  'Electron Configuration': 'atom-variant',
  'Electron Balancing': 'atom-variant',
  'Word Problem': 'text',
  SIM: 'flask-empty-outline',
  'Molecular Orbital': 'molecule',
  'ICE Table': 'table',
  'Chemical Notation': 'molecule-co2',
  Numerical: 'numeric',
  Text: 'text',
  Video: 'video-outline',
  'Multiple Choice': 'format-list-checks',
  'Rank / Sort': 'sort-ascending',
};

const taskTypeDefault = {
  icon: 'clipboard-edit-outline',
  label: 'Generic',
};
</script>
