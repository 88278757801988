<template>
  <template v-if="uiConfig && task.component === 'SinglePartCalculation'">
    <h2 class="heading md mb-1">Question Preview</h2>
    <div class="card padded sm mb-4">
      <s-input-field :id="task.id as unknown as string">
        <template v-if="uiConfig.question" #label>
          <s-latex
            :content="uiConfig.question"
            responsive
            class="mb-1 font-medium whitespace-pre-wrap"
          />
        </template>
        <template v-if="uiConfig.prependText" #prefix>
          <s-latex :content="uiConfig.prependText" responsive />
        </template>
        <template v-if="uiConfig.appendText" #suffix>
          <s-latex :content="uiConfig.appendText" responsive />
        </template>
      </s-input-field>
    </div>
  </template>

  <template v-if="uiConfig && task.component === 'AiSelectableContextCriteriaQuestion'">
    <h2 class="heading md mb-1">Task UI Configuration</h2>
    <div class="card padded sm flex-col gap-2 mb-4">
      <div class="heading md">
        {{ uiConfig.heading }}
      </div>
      <div v-for="taskPart in uiConfig.uiContext" :id="taskPart.uuid">
        <template v-if="taskPart.contextType === 'text_block'">
          <p class="font-medium">
            <s-latex :content="taskPart.text" responsive class="whitespace-pre-wrap" />
          </p>
        </template>
        <template v-else-if="taskPart.contextType === 'student_response'">
          <p class="italic text-gray-500">Student Response Area</p>
          <s-badge size="sm" class="mt-1 mb-2">
            {{ taskPart.characterLimit }} Character Limit
          </s-badge>
        </template>
        <pre v-else><code>{{ JSON.stringify(taskPart, null, 2) }}</code></pre>
        <!-- <pre><code>{{ JSON.stringify(taskPart, null, 2)}}</code></pre> -->
      </div>
    </div>
  </template>

  <template v-if="task.variables?.length">
    <h2 class="heading md mb-1">Variables</h2>
    <div class="@container card divide-y divide-gray-200 mb-4">
      <div class="card-content xs" v-for="variable in task.variables">
        <h3 class="font-bold text-base leading-snug mb-1 capitalize">{{ variable.name }}</h3>
        <div class="grid grid-cols-[2fr_7.5rem_10rem] items-center gap-2">
          <s-label-value label="Generated Range" size="sm">
            <s-number-range
              :min="variable.min"
              :max="variable.max"
              :step="variable.step"
              :shift="variable.shift"
              :includeZero="variable.includeZero"
            />
          </s-label-value>
          <s-badge v-if="variable.includeZero">{{ t('includesZero') }}</s-badge>
          <s-badge color="blue" v-if="variable.scientificNotation">
            {{ t('scientificNotation') }}
          </s-badge>
        </div>
      </div>
    </div>
  </template>

  <template v-if="graderConfig?.calculations?.length">
    <h2 class="heading md mb-1">Calculations</h2>
    <div class="card divide-y divide-gray-200 mb-4">
      <div
        class="@container card-content xs"
        v-for="calculation in graderConfig.calculations"
        :id="`calculation-${calculation.name}`"
      >
        <h3 class="font-bold text-base leading-snug mb-1 capitalize">
          <s-latex :content="calculation.name" class="whitespace-pre-wrap" />
        </h3>
        <div class="grid grid-cols-[2fr_1fr_1fr] @2xl:grid-cols-[3fr_2fr_1fr_1fr] gap-x-3 gap-y-1">
          <s-label-value label="Expression" size="sm" class="col-span-3 @2xl:col-span-1">
            <s-latex :content="calculation.expression" class="whitespace-pre-wrap" />
          </s-label-value>
          <s-label-value label="Tolerance" size="sm">
            {{ t(calculation.tolerance.type)
            }}{{ calculation.tolerance.type !== 'none' ? ',' : '' }}
            <template v-if="calculation.tolerance.type !== 'none'">
              {{ calculation.tolerance.value }}
            </template>
          </s-label-value>
          <s-label-value label="Sig Figs" size="sm">
            <template v-if="calculation.sigFigs">{{ calculation.sigFigs }}</template>
            <template v-else>{{ t('none') }}</template>
          </s-label-value>
          <s-label-value label="Points" size="sm">
            {{ calculation.points }}
          </s-label-value>
        </div>
        <div v-if="calculationFeedback(calculation.name)" class="flex justify-start mt-1">
          <a
            :href="`#feedback-${calculationFeedback(calculation.name)?.key}`"
            class="link-subtle inline-flex items-center gap-1 text-sm"
          >
            <s-icon name="text" size="1.25em" class="" />
            View Feedback
          </a>
        </div>
      </div>
    </div>
  </template>

  <template v-if="task.feedbacks?.length || graderConfig?.fallbackFeedback">
    <h2 class="heading md mb-1">Feedback</h2>
    <div class="card divide-y divide-gray-200 mb-4">
      <div
        class="card-content xs"
        v-for="feedback in task.feedbacks"
        :id="`feedback-${feedback.key}`"
      >
        <h3 class="font-medium text-base leading-snug mb-1">
          <s-latex
            :content="
              feedback.content !== feedback.shortContent ? feedback.shortContent : feedback.key
            "
            class="whitespace-pre-wrap"
          />
        </h3>
        <p class="text-sm leading-snug">
          <template v-if="feedback.content === '{{ feedback }}'">AI Generated Feedback</template>
          <template v-else>
            <s-latex :content="feedback.content" class="whitespace-pre-wrap" />
          </template>
        </p>
        <div v-if="graderCalculation(feedback.key)" class="flex justify-start mt-1">
          <a
            :href="`#calculation-${graderCalculation(feedback.key)?.name}`"
            class="link-subtle inline-flex items-center gap-1 text-sm"
          >
            <s-icon name="calculator" size="1.25em" class="" />
            View Calculation
          </a>
        </div>
      </div>
      <div class="card-content xs" v-if="graderConfig?.fallbackFeedback">
        <h3 class="font-medium text-base leading-snug mb-1.5">Fallback Feedback</h3>
        <p class="text-sm leading-snug">
          <s-latex :content="graderConfig.fallbackFeedback" class="whitespace-pre-wrap" />
        </p>
      </div>
    </div>
  </template>

  <template v-if="graderConfig?.rubric">
    <h2 class="heading md mb-1">Grading Rubric</h2>
    <s-details :open="false" class="mb-4">
      <template #summary>
        <div class="flex flex-col">
          <h3 class="text-lg">Overall Rubric</h3>
          <p class="font-normal text-base">
            The general instructions for guiding the overall assessment
          </p>
        </div>
      </template>
      <p class="whitespace-pre-wrap">{{ graderConfig.rubric }}</p>
    </s-details>
  </template>

  <grading-criteria-list v-if="graderConfig?.criteria?.length" :criteria="graderConfig?.criteria" />

  <template v-if="graderConfig?.contextGraders?.length">
    <h3 class="heading md mb-1">Grading Criteria</h3>
    <div class="card divide-y divide-gray-200 mb-4">
      <div
        class="card-content xs"
        v-for="(grader, index) in graderConfig.contextGraders"
        :key="index"
      >
        <s-details size="sm" :open="false" class="mb-3">
          <template #summary>
            <div class="flex flex-col">
              <h3 class="text-base">View Rubric</h3>
            </div>
          </template>
          <p class="whitespace-pre-wrap">{{ grader.rubric }}</p>
        </s-details>

        <s-badge v-if="grader?.criteria?.length > 1" class="mb-1">
          Total Question Weight {{ grader.scoreMaxValue }}
        </s-badge>

        <grading-criteria-list
          v-if="grader?.criteria?.length > 1"
          :criteria="grader?.criteria"
          headingSize="sm"
        />
        <grading-criteria v-else-if="grader?.criteria?.length" :criteria="grader?.criteria[0]" />
      </div>
    </div>
  </template>
</template>
<script setup lang="ts">
import SLatex from '../../../components/SLatex.vue';
import SBadge from '../../../design-system/SBadge.vue';
import SDetails from '../../../design-system/SDetails.vue';
import SLabelValue from '../../../design-system/SLabelValue.vue';
import GradingCriteria from './GradingCriteria.vue';
import SIcon from '../../../design-system/SIcon.vue';
import SInputField from '../../../design-system/SInputField.vue';
import SNumberRange from '../../../components/SNumberRange.vue';
import GradingCriteriaList from './GradingCriteriaList.vue';
import TaskShowDto = App.DTOs.Tasks.TaskShowDto;
import {useI18n} from 'vue-i18n';
import {computed} from 'vue';

const {task} = defineProps<{
  task: TaskShowDto;
}>();

const graderCalculation = (key: string) => {
  return graderConfig.value?.calculations?.find((calculation: any) => calculation.name === key);
};

const calculationFeedback = (key: string) => {
  return task.feedbacks?.find((feedback) => feedback.key === key);
};

const {t} = useI18n({
  useScope: 'local',
  inheritLocale: true,
});

const uiConfig = computed<any | null>(() => {
  return task.uiConfig;
});

const graderConfig = computed<any | null>(() => {
  return task.graderConfig;
});
</script>
