<template>
  <assignment-analytics-header
    :course="course"
    :assignment="assignment"
    :task-analytics-enabled="taskAnalyticsEnabled"
  />

  <section class="section-block gap-5">
    <div class="card padded">
      <mean-incorrect-responses-barchart v-if="gradeDistributions" :data="[gradeDistributions]" />
      <span v-else>{{ t('data.missing') }}</span>
    </div>
    <div class="card padded">
      <mean-count-of-feedback-keys
        v-if="errorBreakdown"
        :data="errorBreakdown"
        :data-scope="t('thisAssignment')"
      />
      <span v-else>{{ t('data.missing') }}</span>
    </div>
  </section>
</template>
<script setup lang="ts">
import {Assignment} from '../../types/entities/assignment';
import {Course} from '../../types/entities/course';
import {usePageParams} from '../../composables/usePageParams';
import MeanCountOfFeedbackKeys from '../CourseAnalytics/Tasks/MeanCountOfFeedbackKeys.vue';
import MeanIncorrectResponsesBarchart from './Tasks/MeanIncorrectResponsesBarchart.vue';
import AssignmentAnalyticsHeader from './Index/AssignmentAnalyticsHeader.vue';
import {GradeDistribution} from '../../types/GradeDistribution';
import {useI18n} from 'vue-i18n';

const props = defineProps<{
  course: Course;
  assignment: Assignment;
  gradeDistributions: {
    name: string;
    gradeDistributionPerTask: GradeDistribution[];
  } | null;
  errorBreakdown: {[key: string]: number} | null;
  taskAnalyticsEnabled: boolean;
}>();

const params = usePageParams<{reviewerIds: string[]}>();
const {t} = useI18n();
</script>
<i18n>
{
  "en": {
    "data": {
      "missing": "Please check back later for data."
    },
    "thisAssignment": "This is for this assignment only."
  },
  "fr": {
    "data": {
      "missing": "Veuillez revenir plus tard pour les données."
    },
    "thisAssignment": "Ceci est pour ce devoir seulement."
  }
}
</i18n>
