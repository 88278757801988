import {ComponentType} from './defineComponentType';
import {fileUpload} from './file-upload';
import {multiSelect} from './multi-select';
import {multipleChoice} from './multiple-choice';
import {textBlock} from './text-block';
import {link} from './link';
import {singleCalculation} from './single-calculation';
import {rankOrder} from './rank-order';
import {textarea} from './textarea';
import {image} from './image';

export function useTaskComponentTypeRegistry() {
  const componentTypes: ComponentType<any, any>[] = [
    // Display Only
    textBlock,
    link,
    image,
    // Inputs
    textarea,
    multipleChoice,
    multiSelect,
    singleCalculation,
    fileUpload,
    rankOrder,
  ];

  return {componentTypes};
}
