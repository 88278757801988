<template>
  <component :is="VALUE_TYPE_TO_COMPONENT[value.valueType]" :value="value" />
</template>
<script lang="ts" setup>
import NumberValue from './STaskStateNumber.vue';
import ChemicalIsotopeValue from './STaskStateChemicalIsotope.vue';
import ChemicalElementValue from './STaskStateChemicalElement.vue';
import IntegerValue from './STaskStateInteger.vue';

const VALUE_TYPE_TO_COMPONENT = {
  NumberValue,
  ChemicalIsotopeValue,
  ChemicalElementValue,
  IntegerValue,
};

defineProps<{
  value: {
    isScientificNotation: boolean;
    number: number;
    scientificNotationExponent: number;
    scientificNotationNumber: string;
    shift: number;
    sigFigs: number;
    sympyValue: string;
    valueType: keyof typeof VALUE_TYPE_TO_COMPONENT;
  };
}>();
</script>
