<template>
  <Head :title="t('page.title')"></Head>
  <section class="page-block stack vertical gap-6 lg:gap-8 max-w-screen-md">
    <div class="flex justify-between w-full">
      <h1 class="heading text-gray-600 xl">
        {{ t('page.title') }}
      </h1>
      <div class="stack horizontal items-center gap-5">
        <s-link v-if="showingAll()" :href="route('notifications.index')" class="link">
          {{ t('actions.viewUnread') }}
        </s-link>
        <s-link v-else :href="route('notifications.index')" :data="{dismissed: true}" class="link">
          {{ t('actions.viewAll') }}
        </s-link>
        <s-link
          as="button"
          :href="route('notifications.dismiss-all')"
          method="post"
          class="btn primary md"
          :preserve-state="false"
        >
          {{ t('actions.dismissAll') }}
        </s-link>
      </div>
    </div>
    <s-placeholder v-if="!items.length">
      {{ t('status.noUnread') }}
    </s-placeholder>
    <ul v-else class="stack vertical gap-6 w-full">
      <dynamic-notification
        v-for="notification in items"
        :key="notification.id"
        :notification="notification"
        @notificationDismissed="removeNotification"
      />
    </ul>

    <div ref="loadMoreLandmark"></div>
    <div
      v-if="!canLoadMoreItems && items.length > 5"
      class="italic text-center text-gray-400 text-lg tracking-slight p-4"
    >
      {{ t('status.nothingMoreToLoad') }}
    </div>
    <s-link v-if="notifications.next_page_url" @click="loadMoreItems" class="btn secondary md">
      {{ t('actions.loadMore') }}
    </s-link>
  </section>
</template>
<script lang="ts" setup>
import {useI18n} from 'vue-i18n';
import {defineProps, ref, toRef} from 'vue';
import SPlaceholder from '../../design-system/SPlaceholder.vue';
import SLink from '../../components/SLink.vue';
import {useInfiniteScroll} from '../../composables/useInfiniteScroll';
import {LaravelPaginator} from '../../types/laravel-paginator';
import {LaravelNotification} from '../../types/laravel-notifications';
import DynamicNotification from '../../components/DynamicNotification.vue';
import {useIntersectionObserver} from '@vueuse/core';
import {route} from 'ziggy-js';
import {Head, router, usePage} from '@inertiajs/vue3';

const props = defineProps<{
  notifications: LaravelPaginator<LaravelNotification>;
}>();

const {t} = useI18n({
  inheritLocale: true,
});

const {url: initialUrl} = usePage();
async function fetchNotifications(): Promise<LaravelPaginator<any>> {
  return new Promise<LaravelPaginator<LaravelNotification>>((resolve, reject) =>
    router.get(
      props.notifications.next_page_url!,
      {},
      {
        preserveState: true,
        preserveScroll: true,
        onSuccess: () => {
          window.history.replaceState({}, '', initialUrl);
          resolve(props.notifications);
        },
        onError: reject,
      }
    )
  );
}
const {items, removeItem, loadMoreItems, canLoadMoreItems} = useInfiniteScroll(
  fetchNotifications,
  toRef(props.notifications)
);
const loadMoreLandmark = ref<HTMLElement | null>(null);

useIntersectionObserver(loadMoreLandmark, loadMoreItems);

const showingAll = () => {
  return route().params.hasOwnProperty('dismissed');
};
/**
 * Removes the notification from the list
 * ONLY if we're viewing the unread notifications.
 *
 * @param id
 */
const removeNotification = (id: string) => {
  if (showingAll()) {
    return;
  }

  removeItem(id);
};
</script>
<i18n>
{
  "en": {
    "page": {
      "title": "Notifications",
    },
    "actions": {
      "dismissAll": "Dismiss All",
      "viewAll": "View All",
      "viewUnread": "View Unread",
      "loadMore": "Load more",
    },
    "status": {
      "nothingMoreToLoad": "No more notifications to load.",
      "noUnread": "You have no unread notifications.",
    }
  },
  "fr": {
    "page": {
      "title": "Notifications"
    },
    "actions": {
      "dismissAll": "Tout supprimer",
      "viewAll": "Voir tout",
      "viewUnread": "Voir non lus",
      "loadMore": "Charger plus"
    },
    "status": {
      "nothingMoreToLoad": "Aucune autre notification à charger.",
      "noUnread": "Vous n'avez aucune notification non lue."
    }
  }
}
</i18n>
