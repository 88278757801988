<template>
  <s-page-block>
    <s-page-header
      :overline="group"
      :heading="componentName"
      :description="exampleDescription"
      class="mb-4"
    />
    <div
      class="w-full resize overflow-auto card-soft border border-gray-200 mb-5"
      :class="fullscreen ? 'h-[75vh]' : 'h-72'"
    >
      <iframe
        class="w-full h-full overflow-scroll"
        :src="
          route('design.preview', {
            componentName: group ? `${group}/${componentName}` : componentName,
            _query: {
              props: exampleProps,
            },
          })
        "
      />
    </div>
    <h3 class="heading xs mb-2">Source Code</h3>
    <s-card class="mb-6">
      <template #content>
        <highlightjs lang="vue" :code="liveCode" />
      </template>
    </s-card>
    <s-card v-if="exampleControls.length">
      <div class="flex flex-col gap-4">
        <h2 class="heading xs">Controls</h2>
        <component
          v-for="control in exampleControls"
          :is="getControlComponent(control)"
          :control="control"
          v-model="exampleProps[control.prop]"
        />
      </div>
    </s-card>
  </s-page-block>
</template>
<script setup lang="ts">
import {route} from 'ziggy-js';
import {computed, reactive, ref, watch} from 'vue';
import InputControl from './Controls/InputControl.vue';
import SelectControl from './Controls/SelectControl.vue';
import DesignPlaygroundLayout from './DesignPlaygroundLayout.vue';
import SPageBlock from '../../design-system/SPageBlock.vue';
import SPageHeader from '../../design-system/SPageHeader.vue';
import SCard from '../../design-system/SCard.vue';
import {Control, ExampleOptions} from './examples';

defineOptions({
  layout: DesignPlaygroundLayout,
});

const {group, componentName, code} = defineProps<{
  group?: string;
  componentName: string;
  code: string;
}>();

const exampleControls = ref<Control[]>([]);
const exampleDescription = ref<string>('');
const fullscreen = ref(false);

watch(
  () => componentName,
  async () => {
    let exports;

    if (group) {
      exports = await import(`./Examples/${group}/${componentName}.vue`);
    } else {
      exports = await import(`./Examples/${componentName}.vue`);
    }

    let options: ExampleOptions = exports.options || {};

    exampleControls.value = options.controls || [];
    exampleDescription.value = options.description || '';
    fullscreen.value = options.fullscreen || false;
  },
  {immediate: true}
);

let exampleProps = reactive<any>({});

const liveCode = computed(() => {
  /*
   * Returns the source code BUT does some processing.
   * It iterates through each control and checks if a simple string is being
   * used in it's prop. It then replaces the prop with the value stored in `exampleProps`
   *
   * For example, if I put "First name" in the label field and "lg" in the size field, the following code:
   * <template>
   *   <s-input-field id="example" :label="label" :size="size"/>
   * </template>
   *
   * would be replaced with:
   *
   * <template>
   *   <s-input-field id="example" label="First Name" size="lg"/>
   * </template>
   */

  let parsedCode = code;
  exampleControls.value.forEach((control) => {
    const prop = control.prop;
    const value = exampleProps[prop];
    if (typeof value === 'string') {
      const regex = new RegExp(`:${prop}="([^"]+)"`, 'g');
      parsedCode = parsedCode.replace(regex, `${prop}="${value}"`);
    } else if (value === null) {
      const regex = new RegExp(`:${prop}="([^"]+)"`, 'g');
      parsedCode = parsedCode.replace(regex, `:${prop}="null"`);
    }
  });

  return parsedCode;
});

watch(
  exampleControls,
  function () {
    // Reset `exampleProps` to empty object
    exampleProps = reactive({});

    exampleControls.value.forEach((control) => {
      exampleProps[control.prop] = control.default;
    });
  },
  {immediate: true}
);

function getControlComponent(control: Control) {
  switch (control.type) {
    case 'input':
      return InputControl;
    case 'select':
      return SelectControl;
    default:
      return InputControl;
  }
}
</script>
