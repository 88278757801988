<template>
  <Head :title="t('page.title')" />
  <div class="bg-gradient-to-b from-blue-50 via-transparent to-transparent">
    <div role="main" class="p-4 lg:p-8 max-w-xl mx-auto flex flex-col gap-5">
      <form
        aria-labelledby="form-title"
        @submit.prevent="form.post(route('auth.register'))"
        class="flex flex-col shadow-card rounded-lg"
      >
        <h1
          id="form-title"
          class="bg-gradient-to-t from-white/70 to-white/30 flex items-center justify-center p-6 rounded-t-lg border border-white/50 border-b-gray-100"
        >
          <s-logo role="presentation" type="image" class="logo h-16 lg:h-[4.5rem] w-auto" />
          <span class="sr-only">{{ t('page.title') }}</span>
        </h1>

        <div
          class="px-5 lg:px-8 py-5 lg:py-8 bg-gradient-to-t from-gray-50 to-white rounded-b-lg flex flex-col"
        >
          <fieldset class="flex flex-col gap-2.5 mb-3">
            <template v-if="course">
              <s-label-value>
                <template #label>
                  <span class="text-base font-bold text-gray-700 leading-tight">
                    {{ t('institution') }}
                  </span>
                </template>
                {{ course.institution.name }}
              </s-label-value>
              <s-label-value>
                <template #label>
                  <span class="text-base font-bold text-gray-700 leading-tight">
                    {{ t('course') }}
                  </span>
                </template>
                {{ course.name }}
              </s-label-value>
            </template>
            <s-label-value>
              <template #label>
                <span class="text-base font-bold text-gray-700 leading-tight">
                  {{ t('email') }}
                </span>
              </template>
              {{ email }}
            </s-label-value>
          </fieldset>
          <fieldset class="flex flex-col gap-3">
            <s-input-field
              id="firstName"
              :label="t('firstName')"
              :placeholder="t('firstName')"
              v-model="form.firstName"
              required
              :error="form.errors.firstName"
            />
            <s-input-field
              id="lastName"
              :label="t('lastName')"
              :placeholder="t('lastName')"
              v-model="form.lastName"
              required
              :error="form.errors.lastName"
            />
            <s-input-field
              id="password"
              type="password"
              :label="t('password')"
              :placeholder="t('password')"
              v-model="form.password"
              required
              :error="form.errors.password"
            >
              <template v-slot:prefix>
                <mdicon
                  name="lock"
                  role="presentation"
                  class="opacity-70 group-hover:opacity-50 group-focus-within:text-blue-600 transition"
                />
              </template>
            </s-input-field>
            <s-input-field
              id="retypedPassword"
              type="password"
              :label="t('form.retypePassword')"
              :placeholder="t('form.retypePassword')"
              v-model="form.password_confirmation"
              required
              :error="form.errors.password_confirmation"
            >
              <template v-slot:prefix>
                <mdicon
                  name="lock"
                  role="presentation"
                  class="opacity-70 group-hover:opacity-50 group-focus-within:text-blue-600 transition"
                />
              </template>
            </s-input-field>
          </fieldset>
          <div class="flex flex-wrap justify-between gap-2 my-5">
            <s-checkbox v-model="form.agreeWithTerms" id="agreeWithTerms" name="agreeWithTerms">
              {{ t('form.agreeWithTerms') }}
              <s-link external :href="t('links.termsAndConditions')" target="_blank" class="link">
                {{ t('links.termsAndConditionsLabel') }}
              </s-link>
            </s-checkbox>
            <div
              v-if="form.errors.agreeWithTerms"
              aria-live="assertive"
              class="flex items-center justify-start gap-1 mt-0.5 text-sm font-medium leading-tight text-red-600"
            >
              <mdicon
                name="warning"
                width="16"
                height="16"
                class="opacity-80"
                role="presentation"
              />
              {{ t('form.termsError') }}
            </div>
          </div>
          <s-btn
            type="submit"
            color="primary"
            :processing="form.processing"
            class="mt-1"
            icon="login"
            icon-end
          >
            {{ t('actions.createAccount') }}
          </s-btn>
        </div>
      </form>
      <div class="flex gap-x-3 gap-y-2 flex-wrap">
        <s-link :href="route('support')" class="link-subtle flex items-center gap-1.5">
          <mdicon role="presentation" name="chat-question" class="opacity-70" />
          {{ t('links.help') }}
        </s-link>
        <s-link
          :href="t('links.privacyPolicy')"
          external
          target="_blank"
          class="link-subtle flex items-center gap-1.5"
        >
          {{ t('links.privacyPolicyLabel') }}
        </s-link>
      </div>
      <div
        v-if="flash['error']"
        class="px-4 py-2 shadow-sm border text-base leading-tight rounded flex items-center gap-2 bg-red-100 text-red-800 border-red-200"
      >
        <mdicon name="email-remove" class="opacity-70" size="22" aria-hidden="true" />
        {{ flash['error'] }}
      </div>
    </div>
  </div>
</template>
<script setup lang="ts">
import {Head, useForm} from '@inertiajs/vue3';
import {useI18n} from 'vue-i18n';
import {useRoute} from 'ziggy-js';
import SInputField from '../../design-system/SInputField.vue';
import SLink from '../../components/SLink.vue';
import SBtn from '../../design-system/SBtn.vue';
import SLogo from '../../design-system/SLogo.vue';
import SCheckbox from '../../design-system/SCheckbox.vue';
import UnauthorizedLayout from '../../layouts/UnauthorizedLayout.vue';
import RegisterDto = App.DTOs.Auth.RegisterDto;
import SLabelValue from '../../design-system/SLabelValue.vue';
import loginTranslations from '../Login/login.json';
import {Course} from '../../types/entities/course';

defineOptions({layout: UnauthorizedLayout});

type FormData = RegisterDto;
const route = useRoute();
const props = defineProps<{
  email: string;
  token: string;
  course: Course | null;
  flash: Record<string, string>;
}>();

const form = useForm<FormData>({
  firstName: '',
  lastName: '',
  password: '',
  password_confirmation: '',
  agreeWithTerms: false,
  token: props.token,
});

const {t} = useI18n({
  inheritLocale: true,
  messages: loginTranslations,
});
</script>
<i18n>
{
  "en": {
    "page": {
      "title": "Register"
    },
    "form": {
      "agreeWithTerms": "I agree to the",
      "termsError": "You must agree with the terms and conditions."
    }
  },
  "fr": {
    "page": {
      "title": "Inscription"
    },
    "form": {
      "agreeWithTerms": "J'accepte les",
      "termsError": "Vous devez accepter les conditions générales."
    }
  }
}
</i18n>
