<template>
  <s-modal v-model:open="open" :tagline="t('preview.title')" width="lg">
    <template #title>
      <s-latex :content="previewTask?.title" />
    </template>
    <s-modal-content>
      <div v-if="previewTask" class="relative flex flex-col gap-4">
        <div
          v-if="previewTask && task && previewTask.id !== task.id"
          class="absolute inset-0 flex items-center justify-center z-50"
        >
          <span class="loading-spinner"></span>
        </div>
        <div
          class="relative flex flex-col transition-opacity duration-200 ease-out"
          :class="
            previewTask && task && previewTask.id !== task.id
              ? 'opacity-30 pointer-events-none'
              : 'opacity-100'
          "
        >
          <task-preview-form :previewTask="previewTask" :previewTaskState="previewTaskState" />
        </div>
        <!-- This is where the feedback area _used_ to be -->
      </div>

      <div
        v-else
        class="px-6 py-12 flex items-center justify-center row-start-1 row-end-2 col-start-1 col-end-2"
      >
        <span class="loading-spinner"></span>
      </div>
    </s-modal-content>
    <div
      class="px-4 md:px-5 lg:px-6 py-2.5 pb-4 md:py-4 md:pb-5 bg-gray-150 border-t border-gray-250"
    >
      <div class="flex-1 flex flex-wrap items-start justify-between gap-3 pt-0.5">
        <slot />
      </div>
    </div>
  </s-modal>
</template>
<script setup lang="ts">
import TaskIndexDto = App.DTOs.Tasks.TaskIndexDto;
import {defineProps, watch, ref, reactive} from 'vue';
import axios from 'axios';
import {Task, TaskState} from '../../../types/entities/tasks';
import SModal from '../../../design-system/SModal.vue';
import SModalContent from '../../../design-system/SModalContent.vue';
import SLatex from '../../../components/SLatex.vue';
import {route} from 'ziggy-js';
import {useI18n} from 'vue-i18n';
import assignmentTasksTranslations from '../assignmentTasks.json';
import TaskPreviewForm from '../../../forms/TaskPreviewForm.vue';

const props = defineProps<{
  task: TaskIndexDto | null;
}>();

const open = ref(false);
const previewTask = ref<Task | null>(null);
const previewTaskState = ref<TaskState | undefined>(undefined);

watch(
  () => props.task,
  (task) => {
    if (task) {
      open.value = true;
      axios.get(route('api.v1.tasks.show', task)).then((data) => {
        reset();
        previewTask.value = data.data.task;
        previewTaskState.value = data.data.taskStateExample;
      });
    } else {
      open.value = false;
    }
  }
);

const emit = defineEmits(['close']);
watch(open, (isOpen) => {
  if (!isOpen) {
    emit('close');
    reset();
  }
});

const reset = () => {
  previewTask.value = null;
};

const {t} = useI18n({
  useScope: 'local',
  inheritLocale: true,
  messages: assignmentTasksTranslations,
});
</script>
