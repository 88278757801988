<template>
  <Head title="Admin - Institution Token History"></Head>
  <div class="page-block">
    <section class="mt-4">
      <h2 class="heading lg mb-2">Historic Usage by Institution</h2>
      <div class="card padded">
        <institution-token-usage-history-bubble-chart
          v-if="Object.keys(tokenUsage).length > 0"
          :token-usage="tokenUsage"
        />
        <span v-else>No AI Usage Exists for this Institution</span>
      </div>
    </section>
  </div>
</template>

<script setup lang="ts">
import {route} from 'ziggy-js';
import {Head} from '@inertiajs/vue3';
import {useBreadcrumbs} from '../../../../composables/useBreadcrumbs';
import InstitutionTokenUsageHistoryBubbleChart from './InstitutionTokenUsageHistoryBubbleChart.vue';

defineProps<{
  tokenUsage: {
    [month: string]: {
      [courseId: string]: {
        name: string;
        courseFullName: string;
        inputTokens: number;
        outputTokens: number;
        totalTokens: number;
        totalStudents: number;
        inputCumulativeTokens: number;
        outputCumulativeTokens: number;
        totalCumulativeTokens: number;
      };
    };
  };
}>();

useBreadcrumbs([
  {label: 'Ai Analytics', href: route('admin.ai-analytics.index')},
  {label: 'Institutions', href: route('admin.ai-analytics.institutions.index')},
]);
</script>

<style scoped>
.chart {
  width: 100%;
  aspect-ratio: 1;
  max-height: 100vh;
}
</style>
