import {NodeSpec} from 'prosemirror-model';

export let taskSingleCalculation = {
  atom: true,
  selectable: true,
  draggable: true,
  content: 'text*',
  inline: false,
  group: 'block',
  attrs: {
    errors: {default: {}},
    ui: {default: {}},
    grader: {default: {}},
  },
  toDOM: () => ['calculation-question', {}, 0],
  parseDOM: [
    {
      tag: 'calculation-question',
    },
  ],
} as NodeSpec;
