<template>
  <s-details>
    <template #summary>
      <h3 class="heading md flex items-center gap-1 text-blue-600">
        <s-icon name="plus-circle" size="20" class="opacity-70" />
        <i18n-t keypath="heading" :plural="rows.length">
          <template #count>
            <span class="font-black">
              {{ rows.length }}
            </span>
          </template>
        </i18n-t>
      </h3>
    </template>
    <div class="flex flex-col gap-4">
      <diff-change-set type="move" :rows="rows" />
    </div>
  </s-details>
</template>
<script setup lang="ts">
import DiffChangeSet from './DiffChangeSet.vue';
import RosterChangeDto = App.DTOs.Roster.RosterChangeDto;
import {computed} from 'vue';
import {useI18n} from 'vue-i18n';
import SIcon from '../../../design-system/SIcon.vue';
import SDetails from '../../../design-system/SDetails.vue';

const props = defineProps<{
  moves: RosterChangeDto[];
}>();

const rows = computed(() => {
  return props.moves.map((move) => {
    return {
      [t('row')]: move.row,
      [t('email')]: move.email,
      [t('section')]: move.sectionName,
    };
  });
});

const {t} = useI18n({
  useScope: 'local',
  inheritLocale: true,
});
</script>
<i18n>
{
  "en": {
    "heading": "Moving {count} Student | Moving {count} Students"
  },
  "fr": {
    "heading": "Déplacement d'un étudiant | Déplacement de {count} étudiants"
  }
}
</i18n>
