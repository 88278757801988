<template>
  <Head :title="`Admin - Task Preview - ${task.title}`"></Head>
  <div class="page-block max-w-screen-lg gap-5">
    <s-page-header>
      <template #heading>
        <s-latex :content="task.title" :responsive="true" />
        <s-task-status-badge :status="task.status" class="ml-2" />
      </template>
      <template #actions>
        <s-link class="btn secondary" :href="route('tasks.copy', task.id)">Copy Task</s-link>
        <s-link class="btn secondary" :href="route('tasks.edit', task.id)">Edit Task</s-link>
        <s-link class="btn secondary" :href="route('tasks.metadata', {task: task.id})" external>
          Edit Metadata
        </s-link>
        <s-btn
          v-if="task.isAiGraded"
          :href="route('admin.ai-analytics.tasks.show', [task.id])"
          color="secondary"
          icon="chart-timeline-variant-shimmer"
          external
        >
          Past Grading Performance
        </s-btn>
        <s-dropdown-menu
          label="Approve"
          icon="check"
          :items="[
            {
              href: route('admin.tasks.approve', task.id),
              method: 'put',
              as: 'button',
              label: 'Approve',
            },
            {
              href: route('admin.tasks.approve', task.id),
              method: 'put',
              as: 'button',
              data: {publish: true},
              label: 'Approve & Publish',
            },
          ]"
        />
      </template>
    </s-page-header>
    <p v-if="task.approvedBy" class="italic">
      Approved by {{ task.approvedBy }} on {{ shortDayOfWeekMonthDayOfMonth(task.approvedAt) }} at
      {{ timeOfDay(task.approvedAt) }}
    </p>
    <SyntheticStudentWritingGenerator :task="task" :taskState="taskState" v-if="task.isAiGraded" />

    <form @submit.prevent="submitResponse" class="card padded">
      <div class="flex flex-col mb-3">
        <h2 class="heading md mb-3">Preview</h2>
        <legacy-task
          v-model:responseData="form.responseData"
          :task="task"
          :task-state="taskState"
          :response="response"
        />
      </div>
      <div class="flex gap-2 items-center flex-wrap">
        <div class="flex-1 flex gap-2 items-center flex-wrap">
          <s-textbook-link :task="task" />
          <s-btn
            v-if="task.taskType !== 'WatchVideoTask' && task.youtubeVideoId"
            color="gray"
            size="sm"
            icon="youtube"
            target="_blank"
            :href="`https://www.youtube.com/watch?v=${task.youtubeVideoId}`"
            external
          >
            {{ $t('video') }}
          </s-btn>
        </div>
        <s-btn type="submit" color="primary" :processing="form.processing" class="self-end">
          Submit Response
        </s-btn>
      </div>
    </form>
    <student-feedback-area
      v-if="grade && response && feedbackByPart"
      :stored-response="response"
      :response-grade="grade"
      :feedback-by-part="feedbackByPart"
      :task-weight="100"
    />
    <section>
      <s-label-value class="min-w-[6rem]">
        <template #label>
          Types
          <s-help-button>
            <p>Task types describe the kind of work/question that is required of the students.</p>
            <s-details class="mt-4">
              <template #summary>Developer Notes</template>
              <code>App\Entities\Tasks\TaskType.php</code>
            </s-details>
          </s-help-button>
        </template>
        <div class="pt-1">
          <edit-task-types-form :task="task" :types="types" />
        </div>
      </s-label-value>
      <s-label-value class="min-w-[6rem] mt-4">
        <template #label>Topics</template>
        <div class="pt-1 flex gap-1">
          <s-badge
            v-for="(topic, index) in topics"
            :key="index"
            size="custom"
            class="px-2.5 py-1 text-xs"
          >
            {{ topic.description }}
          </s-badge>
        </div>
      </s-label-value>
    </section>

    <s-stats-section
      heading="Task Usage"
      :stats="[
        {label: 'Assignments', value: counts.assignments},
        {label: 'Responses', value: counts.responses},
      ]"
    />

    <s-btn
      v-if="counts.assignments"
      class="max-w-fit"
      :href="
        route('admin.assignments.index', {
          taskId: task.id,
        })
      "
    >
      View Assignments
    </s-btn>

    <s-btn
      v-if="counts.responses"
      class="max-w-fit"
      :href="
        route('admin.tasks.responses.index', {
          task: task.id,
        })
      "
    >
      View Responses
    </s-btn>

    <div>
      <h2 class="heading sm">Evaluation References</h2>
      <s-btn
        v-if="response"
        method="post"
        @click.prevent="addToEvaluationReferences"
        icon="plus"
        color="primary"
      >
        Add to Evaluation References
      </s-btn>
      <template v-if="referenceResponses.length">
        <s-btn :href="route('admin.tasks.evaluations.index', task.id)" color="secondary">
          View Evaluations
        </s-btn>
        <p>These responses are used to run evaluations on this task.</p>
        <ul>
          <li v-for="response in referenceResponses" :key="response.id!">
            <s-link
              method="post"
              :href="route('admin.tasks.responses.edit', {task: task.id, response: response.id})"
            >
              {{ response.id }}
            </s-link>
          </li>
        </ul>
      </template>
      <p v-else>There are no references responses for this task.</p>
    </div>

    <div class="px-4 py-3 rounded-lg bg-red-100 border-2 border-red-300">
      <h2 class="heading sm mb-1 text-red-800">Danger Zone</h2>
      <p v-if="hasPermissionToDeleteTask && !canDeleteTask" class="mb-2 text-red-950">
        Tasks that are use cannot be deleted.
      </p>
      <div class="flex flex-row flex-wrap gap-2 py-1">
        <s-btn
          v-if="hasPermissionToDeleteTask"
          color="red"
          icon="delete"
          @click.prevent="confirmDelete"
          :disabled="!canDeleteTask"
        >
          Delete
        </s-btn>
        <s-tooltip text="Archived tasks still work but cannot be used in new assignments.">
          <s-btn
            v-if="task.status !== 'archived'"
            icon="archive"
            color="white"
            @click.prevent="router.post(route('tasks.archive', [task.id]))"
          >
            Archive
          </s-btn>
        </s-tooltip>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import LegacyTask from '@/components/tasks/LegacyTask.vue';
import {watchEffect} from 'vue';
import {Head, useForm, router} from '@inertiajs/vue3';
import {route} from 'ziggy-js';
import {Task, TaskFeedback, TaskResponse, TaskState} from '../../../types/entities/tasks';
import {Grade} from '../../../types/entities/base-grade';
import SPageHeader from '../../../design-system/SPageHeader.vue';
import SLink from '../../../components/SLink.vue';
import SLatex from '../../../components/SLatex.vue';
import SBtn from '../../../design-system/SBtn.vue';
import SDetails from '../../../design-system/SDetails.vue';
import STaskGrade from '../../../components/STaskGrade.vue';
import STaskStatusBadge from '../../../components/STaskStatusBadge.vue';
import STooltip from '../../../design-system/STooltip.vue';
import {useBreadcrumbs} from '../../../composables/useBreadcrumbs';
import SyntheticStudentWritingGenerator from '../../../components/SyntheticStudentWritingGenerator.vue';
import SStatsSection from '../../../design-system/SStatsSection.vue';
import TaskTypeDto = App.DTOs.Tasks.TaskTypeDto;
import SLabelValue from '../../../design-system/SLabelValue.vue';
import SBadge from '../../../design-system/SBadge.vue';
import SHelpButton from '../../../design-system/SHelpButton.vue';
import TaskTopicDto = App.DTOs.Tasks.TaskTopicDto;
import EditTaskTypesForm from './Show/EditTaskTypesForm.vue';
import SPageBlock from '../../../design-system/SPageBlock.vue';
import StudentFeedbackArea from '../../AssignmentTask/Show/StudentFeedbackArea.vue';
import SDropdownMenu from '../../../design-system/SDropdownMenu.vue';
import {shortDayOfWeekMonthDayOfMonth, timeOfDay} from '../../../format/dates';
import STextbookLink from '../../../components/STextbookLink.vue';

const props = defineProps<{
  hasPermissionToDeleteTask: boolean;
  canDeleteTask: boolean;
  task: Task;
  referenceResponses: TaskResponse[];
  types: TaskTypeDto[];
  topics: TaskTopicDto[];
  taskState?: TaskState;
  response?: TaskResponse;
  grade?: Grade;
  feedbackByPart?: Record<string, TaskFeedback[]>;
  permissions: Record<string, boolean>;
  counts: {
    assignments: number;
    responses: number;
  };
}>();

useBreadcrumbs([{label: 'Tasks', href: route('admin.tasks.index')}]);

const submissionProps: (keyof typeof props)[] = ['response', 'feedbackByPart', 'grade'];

const form = useForm({
  taskId: props.task?.id,
  taskStateId: props.taskState?.id,
  responseData: {},
});

watchEffect(() => {
  form.data().responseData = JSON.parse(JSON.stringify(props.response || null));
});

function submitResponse() {
  form.post(
    route('admin.tasks.preview.responses.store', {...route().params, task: props.task.id}),
    {
      preserveState: true,
      only: submissionProps,
    }
  );
}

function addToEvaluationReferences() {
  router.post(
    route('admin.tasks.responses.edit', {
      task: props.task.id,
      response: props.response?.id,
    }),
    {
      feedback: props.feedbackByPart,
      grade: props.grade,
    },
    {
      preserveState: true,
    }
  );
}

function confirmDelete() {
  if (
    confirm(
      'You are about to delete this task. This cannot be undone. Are you sure you want to proceed?'
    )
  ) {
    router.delete(route('admin.tasks.destroy', [props.task.id]));
  }
}
</script>
