<template>
  <section
    class="flex-none bg-gradient-to-t from-white/70 via-white/30 to-transparent pt-1 overflow-hidden relative"
  >
    <nav class="w-full px-5 lg:px-7 max-w-screen-xl mx-auto flex gap-1.5">
      <s-link
        v-for="tab in tabs"
        :key="tab.href"
        :href="tab.href"
        class="flex items-center gap-1 px-6 py-2.5 text-base leading-tight rounded-t-lg border border-gray-200 transition shadow -mb-px"
        :class="[
          isActive(tab)
            ? 'text-blue-600 font-bold bg-gradient-to-b from-white to-background  border-b-0'
            : 'font-medium bg-gradient-to-b from-gray-50 to-gray-150 hover:to-gray-50 hover:from-white text-gray-600 hover:text-blue-500',
        ]"
      >
        <s-icon v-if="!!tab.icon" :name="tab.icon" size="20" class="opacity-80" />
        {{ tab.label }}
      </s-link>
    </nav>
    <div class="w-full bg-gray-200 h-px pointer-events-none"></div>
  </section>
</template>
<script setup lang="ts">
import SLink from '../components/SLink.vue';
import {usePage} from '@inertiajs/vue3';
import {computed} from 'vue';
import SIcon from './SIcon.vue';

export type Tab = {
  label: string;
  href: string;
  icon?: string;
};

defineProps<{tabs: Tab[]}>();

const page = usePage();
const path = computed(() => page.url.split('?')[0]);

const isActive = (tab: Tab) => tab.href.toString().endsWith(path.value);
</script>
