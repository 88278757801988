<template>
  <s-btn :size="size" :color="color" :icon="icon">
    {{ text }}
  </s-btn>
</template>
<script setup lang="ts">
import SBtn from '../../../../design-system/SBtn.vue';

defineProps<{
  text?: any;
  size?: any;
  color?: any;
  icon?: any;
}>();
</script>
<script lang="ts">
export const options = {
  controls: [
    {
      type: 'input',
      prop: 'text',
      label: 'Text',
      default: 'Button',
    },
    {
      type: 'input',
      prop: 'size',
      label: 'Size',
      default: undefined,
    },
    {
      type: 'input',
      prop: 'color',
      label: 'Color',
      default: undefined,
    },
    {
      type: 'input',
      prop: 'icon',
      label: 'Icon',
      default: undefined,
    },
  ],
};
</script>
