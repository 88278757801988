<template>
  <i18n-t :keypath="currentKeypath">
    <template #penalty>
      <strong
        class="uppercase"
        :class="{'text-red-500': penaltyPolicyValue.substring(0, 1) === '0'}"
      >
        {{ penaltyPolicyValue }}
      </strong>
    </template>
    <template #timeblock>
      <strong class="uppercase">
        {{ penaltyPolicyFrequency }}
      </strong>
    </template>
    <template #date>
      <strong>
        {{ monthDaySometimesYear(submissionCutoff) }}
      </strong>
    </template>
    <template #time>
      <strong>
        {{ timeOfDay(submissionCutoff) }}
      </strong>
    </template>
  </i18n-t>
</template>
<script setup lang="ts">
import {computed, defineProps} from 'vue';
import {monthDaySometimesYear, timeOfDay} from '../format/dates';
import {useI18n} from 'vue-i18n';
import LateResponsePenaltyType = App.DTOs.LateResponsePenaltyPolicy.LateResponsePenaltyType;
import LateResponsePenaltyFrequencyUnit = App.DTOs.LateResponsePenaltyPolicy.LateResponsePenaltyFrequencyUnit;

const props = defineProps<{
  penalty: {
    type: LateResponsePenaltyType;
    value: number;
  };
  frequency: {
    type: LateResponsePenaltyFrequencyUnit;
    value: number;
  };
  submissionCutoff: Date | null;
  isPastDueDate: boolean;
}>();

const penaltyPolicyValue = computed(() => {
  return (
    props.penalty.value +
    (props.penalty.type === 'relative' ? '%' : ' ' + t('point', props.penalty.value))
  );
});

const penaltyPolicyFrequency = computed(() => {
  switch (props.frequency.type) {
    case 'days':
      return t('time.xDays', props.frequency.value);
    case 'hours':
      return t('time.xHours', props.frequency.value);
    case 'minutes':
      return t('time.xMinutes', props.frequency.value);
  }
});

const {t} = useI18n({
  useScope: 'local',
  inheritLocale: true,
});

const currentKeypath = computed(() => {
  if (props.isPastDueDate) {
    return props.submissionCutoff ? 'late.withCutoff' : 'late.withoutCutoff';
  } else {
    return props.submissionCutoff ? 'notLate.withCutoff' : 'notLate.withoutCutoff';
  }
});
</script>
<i18n>
{
  "en": {
    "late.withCutoff": "Applying a penalty of {penalty} for every {timeblock} that the assignment is late.",
    "late.withoutCutoff": "Applying a penalty of {penalty} for every {timeblock} that the assignment is late up to the submission cutoff date of {date} at {time}.",
    "notLate.withCutoff": "Will apply a penalty of {penalty} for every {timeblock} that the assignment is late.",
    "notLate.withoutCutoff": "Will apply a penalty of {penalty} for every {timeblock} that the assignment is late up to the submission cutoff date of {date} at {time}."
  },
  "fr": {
    "late.withCutoff": "Une pénalité de {penalty} pour chaque {timeblock} de retard s'applique.",
    "late.withoutCutoff": "Une pénalité de {penalty} pour chaque {timeblock} de retard s'applique jusqu'à la date limite de soumission du {date} à {time}.",
    "notLate.withCutoff": "Appliquera une pénalité de {penalty} pour chaque {timeblock} de retard.",
    "notLate.withoutCutoff": "Appliquera une pénalité de {penalty} pour chaque {timeblock} de retard jusqu'à la date limite de soumission du {date} à {time}."
  }
}
</i18n>
