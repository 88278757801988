<template>
  <div class="flex-1 w-full relative">
    <Listbox v-model="validModelValue" multiple>
      <div class="relative">
        <ListboxButton
          class="flex items-center justify-between px-3 py-2 rounded-md border border-gray-200 shadow bg-white hover:bg-gray-50 appearance-none text-blue-600 hover:text-blue-400 font-bold w-full cursor-pointer transition-all ease-out duration-150"
          ref="listboxButton"
        >
          <span v-if="validModelValue.length" class="leading-tight flex-1 truncate text-left">
            <slot name="selected" v-bind="{selected: selectedOptions}">
              <span class="comma-separated">
                <span v-for="selected in selectedOptions" :key="getValue(selected)">
                  {{ getLabel(selected) }}
                </span>
              </span>
            </slot>
          </span>
          <span v-else class="leading-tight flex-1 truncate text-left text-gray-600/50">
            {{ emptyLabel }}
          </span>
          <span class="shrink-0 pointer-events-none flex items-center text-blue-500 -my-2">
            <s-icon name="chevron-down" class="opacity-70" size="1.25rem" />
          </span>
        </ListboxButton>

        <Teleport to="body">
          <div class="absolute" :style="dropdownComputed.wrapperStyle">
            <TransitionRoot
              leave="transition ease-in duration-100"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
              @after-leave="confirmSelection"
            >
              <ListboxOptions
                class="absolute left-0 overflow-auto card-soft border border-gray-200 text-base shadow-lg z-50"
                :class="{
                  'top-2': dropdownComputed.position === 'bottom',
                  'bottom-2': dropdownComputed.position === 'top',
                }"
                :style="dropdownComputed.menuStyle"
              >
                <div
                  class="sticky top-0 z-50 w-full p-2.5 border-b border-gray-200 bg-gradient-to-b from-gray-50 to-gray-100"
                >
                  <!-- keydown propagation is stopped when inside the filter box as it interferes with the listbox registered hotkeys. -->
                  <s-input
                    :value="filterText"
                    @input="(event) => (filterText = event.target.value)"
                    @keydown="preventSpacePropagation"
                    placeholder="Filter Options"
                    class="w-full"
                  />
                </div>
                <div
                  v-if="filteredOptions.length === 0 && filterText !== ''"
                  class="relative cursor-default select-none px-4 py-2 text-gray-700"
                >
                  {{ t('noMatches') }}
                </div>
                <template v-for="option in options" :key="getValue(option)">
                  <ListboxOption
                    v-if="isVisible(option)"
                    v-slot="{active, selected}"
                    :disabled="isDisabled(option)"
                    :value="getValue(option)"
                    as="template"
                  >
                    <li
                      class="relative whitespace-nowrap min-w-[4.5rem] text-base select-none pl-10 pr-4 py-2 text-blue-600 border-b last:border-b-0 border-white"
                      :class="[
                        {
                          'bg-gradient-to-r from-blue-100 via-blue-100/70 to-blue-100/70': active,
                          'hover:from-blue-100 hover:via-blue-100/70 hover:to-blue-100/70':
                            active && !isDisabled(option),
                          'bg-gradient-to-r from-blue-50 via-blue-50/50 to-blue-50/50': selected,
                        },
                        isDisabled(option)
                          ? 'cursor-not-allowed grayscale opacity-60 text-gray-600'
                          : 'cursor-pointer transition-all ease-out duration-150',
                      ]"
                    >
                      <span
                        class="absolute inset-y-0 left-0 flex items-center pl-3 text-blue-600 transition-opacity ease-out duration-150"
                        :class="{
                          'opacity-60': active && selected,
                          'opacity-30': active && !selected,
                          'opacity-100': selected,
                          'opacity-0': !selected,
                        }"
                      >
                        <s-icon
                          :name="selected ? 'check' : 'circle-outline'"
                          :size="selected ? '20' : '16'"
                        />
                      </span>
                      <span
                        :class="[
                          selected ? 'font-medium text-blue-600' : 'font-normal',
                          'block truncate',
                        ]"
                      >
                        <slot name="option" v-bind="{option}">
                          {{ getLabel(option) }}
                        </slot>
                      </span>
                    </li>
                  </ListboxOption>
                </template>
              </ListboxOptions>
            </TransitionRoot>
          </div>
        </Teleport>
      </div>
    </Listbox>
  </div>
</template>

<script setup lang="ts">
import {computed, ref, watch} from 'vue';
import {
  Listbox,
  ListboxButton,
  ListboxOption,
  ListboxOptions,
  TransitionRoot,
} from '@headlessui/vue';
import {useElementBounding, useWindowSize} from '@vueuse/core';
import {useI18n} from 'vue-i18n';
import SInput from './SInput.vue';
import SIcon from './SIcon.vue';

const model = defineModel<any[]>({default: []});
const {options, max, valueKey, labelKey, disableOption, emptyLabel, ...props} = defineProps<{
  // These are the list items that will be displayed in the dropdown
  options: any[];
  valueKey?: string;
  labelKey?: string;
  disableOption?: (option: any) => boolean;
  emptyLabel: string;
  max?: number;
}>();

const emit = defineEmits(['confirm:selection']);

const filterText = ref('');
const filteredOptions = ref<any[]>([]);
watch(filterText, () => {
  if (!filterText.value) {
    filteredOptions.value = [];
  }

  filteredOptions.value = options.filter(isVisible);
});

const validModelValue = computed({
  get: () => model.value.filter((item) => !!getOptionByValue(item)),
  set: (newValue) => (model.value = newValue),
});

const selectedOptions = computed(() => validModelValue.value.map(getOptionByValue));

const confirmSelection = () => {
  filterText.value = '';
  emit('confirm:selection');
};

const maxReached = computed(() => {
  return !!max && validModelValue.value.length >= max;
});

const getOptionByValue = (value: any) => {
  return options.find((o) => getValue(o) === value);
};

const getValue = (option: any) => {
  return valueKey ? option[valueKey] : option;
};

const getLabel = (option: any) => {
  return (labelKey ? option[labelKey] : option) || emptyLabel;
};

const isDisabled = (option: any) => {
  return (!!disableOption && disableOption(option)) || (!isSelected(option) && maxReached.value);
};

const isVisible = (option: any) => {
  if (!filterText.value) {
    return true;
  }

  const optionLabel = getLabel(option).toLowerCase();

  return optionLabel.includes(filterText.value.toLowerCase().trim());
};

const isSelected = (option: any) => {
  return validModelValue.value.includes(getValue(option));
};

const preventSpacePropagation = (event: KeyboardEvent) => {
  if (event.key === ' ') {
    event.stopPropagation();
  }
};

const listboxButton = ref(null);
const {x, y, height, width} = useElementBounding(listboxButton);
const {width: windowWidth, height: windowHeight} = useWindowSize();
const maxDropdownHeight = 288;
const buffer = 16;

const dropdownComputed = computed(() => {
  const canFitBelow = y.value + height.value + maxDropdownHeight + buffer < windowHeight.value;
  const canFitAbove = y.value - maxDropdownHeight - buffer > 0;
  const position = !canFitBelow && canFitAbove ? 'top' : 'bottom';

  const wrapperStyle = {
    top: position === 'bottom' ? `${y.value + height.value}px` : 'auto',
    bottom: position === 'top' ? `${windowHeight.value - y.value}px` : 'auto',
    left: `${x.value}px`,
    width: `${width.value}px`,
  };

  // Squish the dropdown when there's not enough space
  const menuStyle = {
    maxHeight:
      !canFitBelow && position === 'bottom'
        ? `${Math.min(maxDropdownHeight, windowHeight.value - y.value - height.value - buffer)}px`
        : `${maxDropdownHeight}px`,
    minWidth: `${width.value}px`,
    maxWidth: `${windowWidth.value - x.value - buffer}px`,
  };

  return {position, wrapperStyle, menuStyle};
});

const {t} = useI18n({
  inheritLocale: true,
  scope: 'local',
});
</script>
<i18n>
{
  "en": {
    "noMatches": "No matches."
  },
  "fr": {
    "noMatches": "Aucun résultat."
  }
}
</i18n>
