<template>
  <s-latex :content="latexContent" />
</template>

<script lang="ts" setup>
import SLatex from './SLatex.vue';
import {computed} from 'vue';

const props = defineProps<{content: string}>();

const latexContent = computed(() => {
  return `$\\ce{${props.content}}$`;
});
</script>
