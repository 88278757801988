import {AnyFn, MaybeRefOrGetter, useDebounceFn} from '@vueuse/core';

export function useCancellableDebounceFn(fn: AnyFn, ms: MaybeRefOrGetter<number>) {
  function createNewDebounce() {
    let cancelled = false;

    return {
      get cancelled() {
        return cancelled;
      },
      set cancelled(v) {
        cancelled = v;
      },
      fn: useDebounceFn((...args: any[]) => {
        if (!cancelled) {
          fn(...args);
        }
      }, ms),
    };
  }

  let currentContext: ReturnType<typeof createNewDebounce> = createNewDebounce();

  const cancel = () => {
    currentContext.cancelled = true;
  };
  const resume = () => {
    cancel();
    currentContext = createNewDebounce();
  };
  const debounced = (...args: any[]) => currentContext.fn(...args);

  return {
    cancel,
    resume,
    debounced,
  };
}
