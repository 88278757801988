<template>
  <Head :title="t('page.title')"></Head>
  <div class="page-block max-w-screen-md gap-5">
    <div class="flex items-center flex-wrap justify-start gap-x-4 gap-y-3">
      <div class="rounded-full p-1 bg-white border border-gray-150">
        <img
          v-if="profilePicture"
          v-show="user"
          :src="profilePicture"
          :alt="t('profilePicture')"
          class="w-24 h-24 object-cover rounded-full"
        />
      </div>
      <div class="flex-1 flex flex-wrap justify-between items-start gap-3 -mt-1">
        <div class="flex-1 flex flex-col gap-1.5">
          <h2 class="text-2xl lg:text-3xl leading-tight text-gray-800">
            {{ user.firstName + ' ' + user.lastName }}
          </h2>
          <p class="flex items-center gap-1.5 leading-tight text-gray-600">
            <s-icon name="email" size="16" class="opacity-70 mt-px" />
            {{ user.email }}
          </p>
          <p
            v-if="notificationEnabled"
            class="flex items-center gap-1.5 leading-tight text-gray-600"
          >
            <s-icon name="bell" size="16" class="opacity-70 mt-px" />
            {{ t('status.notifcations.enabled') }}
          </p>
          <p v-else class="flex items-center gap-1.5 leading-tight text-gray-600 italic opacity-50">
            <s-icon name="bell-off" size="16" class="opacity-70 mt-px" />
            {{ t('status.notifcations.disabled') }}
          </p>
          <p class="flex items-center gap-1.5 leading-tight text-gray-600">
            <s-icon name="translate" size="16" class="opacity-70 mt-px" />
            {{ t(user.languagePreference ?? 'en') }}
          </p>
        </div>
        <div class="flex flex-wrap gap-x-2 gap-y-3">
          <s-btn :href="route('billing.index')" icon="cash-multiple" color="secondary">
            {{ t('billing') }}
          </s-btn>
          <s-btn :href="route('profile.edit', user.id)" icon="pencil">
            {{ t('actions.edit') }}
          </s-btn>
        </div>
      </div>
    </div>
  </div>
</template>
<script setup lang="ts">
import {User} from '../../types/entities/user';
import {route} from 'ziggy-js';
import {Head} from '@inertiajs/vue3';
import SBtn from '../../design-system/SBtn.vue';
import SIcon from '../../design-system/SIcon.vue';
import {useI18n} from 'vue-i18n';

defineProps<{user: User; profilePicture: string; notificationEnabled: boolean}>();

const {t} = useI18n({
  useScope: 'local',
  inheritLocale: true,
});
</script>
<i18n>
{
  "en": {
    "page": {
      "title": "Profile"
    },
    "status": {
      "notifcations": {
        "enabled": "Notifications Enabled",
        "disabled": "Notifications Disabled"
      }
    }
  },
  "fr": {
    "page": {
      "title": "Profil"
    },
    "status": {
      "notifcations": {
        "enabled": "Notifications activées",
        "disabled": "Notifications désactivées"
      }
    }
  }
}
</i18n>
