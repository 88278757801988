<template>
  <td class="text-left">
    <s-label-value :label="t('course')">
      <s-link
        :href="route('billing.show', [paymentRequest.id])"
        class="link-subtle truncate whitespace-nowrap"
      >
        {{ paymentRequest.course.name }}
      </s-link>
    </s-label-value>
  </td>
  <td class="text-left">
    <payment-request-due-date :payment-request="paymentRequest" />
  </td>
  <td class="text-left w-0" :colspan="paymentStatus !== 'unpaid' ? 2 : 1">
    <s-badge
      :color="paymentStatus === 'paid' ? 'green' : paymentStatus === 'exempt' ? 'blue' : 'yellow'"
    >
      {{ t(`badge.status.${paymentStatus}`) }}
    </s-badge>
  </td>
  <td v-if="paymentStatus === 'unpaid'" class="text-right min-w-0">
    <div class="flex justify-end">
      <s-btn
        :href="route('billing.show', [paymentRequest.id])"
        icon="credit-card-outline"
        color="white"
        class="w-fit"
      >
        {{ t('actions.pay') }}
      </s-btn>
    </div>
  </td>
</template>
<script setup lang="ts">
import SLabelValue from '../../design-system/SLabelValue.vue';
import SLink from '../../components/SLink.vue';
import SBadge from '../../design-system/SBadge.vue';
import {PaymentRequest} from '../../types/entities/payment-request';
import {route} from 'ziggy-js';
import SBtn from '../../design-system/SBtn.vue';
import {useI18n} from 'vue-i18n';
import PaymentRequestDueDate from './components/PaymentRequestDueDate.vue';

const props = defineProps<{
  paymentRequest: PaymentRequest;
}>();

type PaymentStatus = 'paid' | 'unpaid' | 'exempt';

const paymentStatus: PaymentStatus = props.paymentRequest.exemptedOn
  ? 'exempt'
  : props.paymentRequest.paidOn
    ? 'paid'
    : 'unpaid';

const {t} = useI18n({
  useScope: 'local',
  inheritLocale: true,
});
</script>
<i18n>
{
  "en": {
    "actions": {
      "pay": "Pay"
    },
    "badge": {
      "status": {
        "exempt": "Exempt",
        "paid": "Paid",
        "unpaid": "Unpaid"
      }
    },
    "help": {
      "oldPaymentRequest": "This payment request is from over six months ago."
    }
  },
  "fr": {
    "actions": {
      "pay": "Payer"
    },
    "badge": {
      "status": {
        "exempt": "Exempté",
        "unpaid": "Non payé",
        "paid": "Payé"
      }
    },
    "help": {
      "oldPaymentRequest": "Cette demande de paiement date de plus de six mois."
    }
  }
}
</i18n>
