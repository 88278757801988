<template>
  <Head :title="`${t('page.heading')} - ${course.code}`"></Head>
  <div class="page-block narrow max-w-screen-md">
    <s-page-header :heading="t('page.heading')" />

    <form @submit.prevent="addParticipant(selectedEmail)" class="flex flex-col gap-3 mt-2 mb-5">
      <s-input-field
        id="email"
        type="email"
        :label="t('email')"
        v-model="selectedEmail"
        :error="emailError"
        @focus="emailFieldFocus = true"
      />
      <div class="flex gap-4 text-sm">
        <s-multi-select-field
          v-model="selectedSections"
          id="assignedSections"
          :label="t('sections')"
          :emptyLabel="t('pleaseSelect')"
          :options="sections.map((section: Course) => section.name)"
          :error="sectionError"
        />
        <div class="flex-1 flex flex-col gap-1.5">
          <s-select-field
            id="role"
            :label="$t('role')"
            v-model="selectedRole"
            :class="selectedRole === '' ? 'text-gray-400' : ''"
          >
            <option v-for="role in roles" :key="role.id" :value="`${role.name}`">
              {{ t('roleNames.' + role.name) }}
            </option>
          </s-select-field>
        </div>
      </div>
      <div class="flex justify-end mt-1">
        <s-btn type="submit" @click="loseFocus" class="min-w-[8rem]">
          <s-icon class="-ml-1" name="plus" width="18" height="18" />
          {{ t('actions.add') }}
        </s-btn>
      </div>
    </form>

    <form @submit.prevent="addParticipantToCourse" class="flex flex-col">
      <div
        v-if="form.selectedParticipants.length > 0"
        class="overflow-x-scroll -m-5 p-5 md:overflow-visible"
      >
        <table class="table">
          <thead>
            <tr>
              <th>{{ t('email') }}</th>
              <th>{{ t('sections') }}</th>
              <th>{{ t('role') }}</th>
              <th></th>
            </tr>
          </thead>
          <tbody class="card">
            <tr v-for="(participant, index) in form.selectedParticipants" :key="participant.email">
              <td class="w-full trunate">
                {{ participant.email }}
                <s-tooltip
                  v-if="getErrorMessage(`selectedParticipants.${index}.email`)"
                  :text="getErrorMessage(`selectedParticipants.${index}.email`)"
                >
                  <s-icon name="warning" size="20" class="text-red-600 opacity-80" />
                </s-tooltip>
              </td>
              <td>
                {{ participant.sections.join(', ') }}
                <s-tooltip
                  v-if="getErrorMessage(`selectedParticipants.${index}.sections`)"
                  :text="getErrorMessage(`selectedParticipants.${index}.sections`)"
                >
                  <s-icon name="warning" size="20" class="text-red-600 opacity-80" />
                </s-tooltip>
              </td>
              <td>
                {{ t('roleNames.' + participant.role) }}
              </td>
              <td class="w-0">
                <s-btn
                  @click.prevent="form.selectedParticipants.splice(index, 1)"
                  icon="trash-can"
                  color="danger-white"
                  size="sm"
                >
                  {{ t('remove') }}
                </s-btn>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <div
        v-else
        class="rounded-lg text-center leading-tight tracking-slight text-gray-400 bg-gray-100 border border-gray-300/50 shadow-inset divide-y divide-gray-150 overflow-hidden px-5 py-4 italic"
      >
        <p>{{ t('noParticipantsAddedYet') }}</p>
      </div>

      <div
        v-if="form.errors.selectedParticipants"
        class="animate-slide-in flex items-center justify-start gap-1 text-sm font-medium leading-tight text-red-600 mt-1.5"
      >
        <s-icon name="warning" size="16" class="opacity-80" />
        {{ form.errors.selectedParticipants }}
      </div>
      <div class="flex mt-4 justify-end">
        <s-checkbox id="newStudents" v-model="form.sendInvites">
          <p class="font-normal">
            <i18n-t keypath="labels.sendInvites">
              <template #new>
                <strong class="font-bold">{{ t('labels.new') }}</strong>
              </template>
            </i18n-t>
          </p>
        </s-checkbox>
      </div>
      <div class="flex flex-wrap justify-between gap-2 mt-4">
        <s-link
          :href="route('courses.participants.index', {course: course.id})"
          class="btn !px-7 secondary"
        >
          <s-icon name="close" class="opacity-70" size="16" />
          {{ t('cancel') }}
        </s-link>
        <button type="submit" class="btn !px-7 primary">
          <s-icon
            :name="form.selectedParticipants.length > 1 ? 'account-multiple-plus' : 'account-plus'"
            class="opacity-70"
            width="18"
            height="18"
          />
          {{ t('createParticipant', form.selectedParticipants.length) }}
        </button>
      </div>
    </form>
  </div>
</template>
<script setup lang="ts">
import SPageHeader from '../../design-system/SPageHeader.vue';
import {Course} from '../../types/entities/course';
import {Head, useForm} from '@inertiajs/vue3';
import SLink from '../../components/SLink.vue';
import SBtn from '../../design-system/SBtn.vue';
import SInputField from '../../design-system/SInputField.vue';
import STooltip from '../../design-system/STooltip.vue';
import {route} from 'ziggy-js';
import SSelectField from '../../design-system/SSelectField.vue';
import {Role} from '../../types/entities/role';
import {computed, ref, watch} from 'vue';
import {User} from '../../types/entities/user';
import SMultiSelectField from '../../design-system/SMultiSelectField.vue';
import SIcon from '../../design-system/SIcon.vue';
import SCheckbox from '../../design-system/SCheckbox.vue';
import {useI18n} from 'vue-i18n';

const props = defineProps<{course: Course; sections: Array<Course>; roles: Array<Role>}>();
const selectedSections = ref<string[]>([]);
const selectedRole = ref<string>('Student');
const selectedEmail = ref<string>('');
const users = ref<Array<User>>([]);
const emailError = ref<string>('');
const sectionError = ref<string>('');
const sendInvites = ref<boolean>(false);

const {t} = useI18n();

const form = useForm({
  selectedParticipants: [] as {
    email: string;
    role: string;
    sections: string[];
  }[],
  sendInvites: false,
});

const emailFieldFocus = ref<boolean>(false);
const errors = computed(() => {
  return form.errors as any;
});
const getErrorMessage = (key: string): string => {
  const errors = form.errors as any;
  return errors[key] ?? '';
};

function loseFocus() {
  emailFieldFocus.value = false;
}

const isEmail = (email: string) => {
  const regex = /^.+@.+$/;
  return regex.test(email);
};

function addParticipant(participantEmail: string) {
  if (!isEmail(participantEmail)) {
    emailError.value = 'Invalid email address.';
    return;
  } else if (selectedSections.value.length === 0) {
    sectionError.value = 'At least one section is required.';
    return;
  } else if (
    form.selectedParticipants.find(
      (participant) =>
        participant.email === participantEmail &&
        participant.sections === selectedSections.value &&
        participant.role === selectedRole.value
    )
  ) {
    emailError.value = 'Duplicate participant found.';
    return;
  } else {
    emailError.value = '';
  }
  form.selectedParticipants.push({
    email: participantEmail,
    role: selectedRole.value,
    sections: selectedSections.value,
  });
  loseFocus();
}

function addParticipantToCourse() {
  form.post(route('courses.participants.store', {course: props.course.id}));
}

watch(selectedSections, (value) => {
  if (value.length > 0) {
    sectionError.value = '';
  }
});
</script>
<i18n>
{
  "en": {
    "page": {
      "heading": "Add Participants"
    },
    "pleaseSelect": "Please Select",
    "remove": "Remove",
    "noParticipantsAddedYet": "No participants added yet",
    "createParticipant": "Create Participant | Create Participants",
    "labels": {
      "sendInvites": "Send invites to {new} participants",
      "new": "new"
    }
  },
  "fr": {
    "page": {
      "heading": "Ajouter des participants"
    },
    "pleaseSelect": "Veuillez sélectionner",
    "remove": "Supprimer",
    "noParticipantsAddedYet": "Aucun participant ajouté pour le moment",
    "createParticipant": "Créer un participant | Créer des participants",
    "labels": {
      "sendInvites": "Envoyer des invitations à tout {new} participant",
      "new": "nouveau"
    }
  }
}
</i18n>
