<template>
  <s-badge>
    <p class="text-sm"><slot /></p>
    <s-btn
      :href="clearRoute"
      @click="onClear"
      class="text-blue-500 w-fit inline-block opacity-80 hover:opacity-100 py-1 pr-1.5 pl-1.5 -my-1 -ml-1 -mr-2.5 transition-opacity ease-out duration-100"
      size="custom"
      color="ghost"
    >
      <s-icon name="close" width="18" height="18" />
      <span class="sr-only">
        {{ clearLabel || t('clear') }}
      </span>
    </s-btn>
  </s-badge>
</template>
<script setup lang="ts">
import SBadge from './SBadge.vue';
import SBtn from './SBtn.vue';
import SIcon from './SIcon.vue';
import {useI18n} from 'vue-i18n';
import {defineProps, defineEmits} from 'vue';

const props = defineProps<{
  clearRoute?: string;
  clearLabel?: string;
}>();

const emit = defineEmits(['clear']);

const {t} = useI18n({
  inheritLocale: true,
  scope: 'local',
});

function onClear(event: MouseEvent) {
  if (!props.clearRoute) event.preventDefault();

  emit('clear', event);
}
</script>
