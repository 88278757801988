<template>
  <task-form :errors="errors" :form="form" class="fieldset">
    <s-input-field
      id="heading"
      label="Heading"
      description="The title displayed to the student."
      :required="false"
      v-model="form.ui.heading"
      :error="errors?.['ui.heading']"
    />
    <s-textarea-field
      id="question"
      label="Question"
      description="The question/instructions displayed to the student."
      v-model="form.ui.question"
      :error="errors?.['ui.question']"
    />

    <s-field class="mt-2 mb-2" id="showCharacterLimit" label="Character Limit to Student Response">
      <div class="flex items-center space-x-2">
        <s-button-toggle
          id="showCharacterLimit"
          :options="[
            {value: null, label: 'No'},
            {value: form.ui.characterLimit ?? 0, label: 'Yes'},
          ]"
          v-model="form.ui.characterLimit"
        />
        <template v-if="form.ui.characterLimit !== null">
          <s-input-field
            id="characterLimit"
            :required="false"
            type="number"
            min="0"
            v-model="form.ui.characterLimit"
          />
        </template>
      </div>
    </s-field>
    <div class="flex flex-wrap gap-4 mb-1">
      <s-field class="mt-2 mb-2" id="allowFileUpload" label="Allow File Uploads">
        <s-button-toggle
          id="allowFileUpload"
          :options="[
            {value: false, label: 'No'},
            {value: true, label: 'Yes'},
          ]"
          v-model="allowFileUpload"
          :error="errors?.['grader.allowFileUpload']"
        />
      </s-field>
      <s-field class="mt-2 mb-2" id="useParsing" label="Use AI Parsing">
        <s-button-toggle
          id="useParsing"
          description="Use Ai Parsing first to select the relevant text"
          label="Use AI Parsing"
          :options="[
            {value: false, label: 'No'},
            {value: true, label: 'Yes'},
          ]"
          v-model="form.grader.useParsing"
          :error="errors?.['grader.useParsing']"
        />
      </s-field>
      <s-input-field
        id="Subject"
        label="Subject"
        :required="false"
        v-model="form.grader.subject"
        :error="errors?.['grader.subject']"
      />
    </div>
    <s-field id="specifications" label="Specifications" :error="errors?.['grader.specifications']">
      <div
        class="card padded mb-2"
        v-for="(specification, index) in form.grader.specifications"
        :key="index"
      >
        <div class="flex items-center space-x-2 mb-2">
          <s-input-field
            id="specificationTitle"
            label="Title of Specification"
            v-model="specification.specificationTitle"
            :error="errors?.['grader.specifications.' + index + '.specificationTitle']"
          />
        </div>
        <s-textarea-field
          class="mb-2"
          id="specificationText"
          label="Specification Question/Description"
          v-model="specification.specificationText"
          :error="errors?.['grader.specifications.' + index + '.specificationText']"
        />
        <s-textarea-field
          class="mb-2"
          id="rubric"
          label="Additional Context/Rules for Ai Grading"
          v-model="specification.rubric"
          :error="errors?.['grader.specifications.' + index + '.rubric']"
        />
        <template v-if="form.grader.useParsing" class="mb-2">
          <s-input-field
            id="specificationAlias"
            label="Alias Names for Specification"
            description="This is used to help the AI identify the specification in the text."
            v-model="specification.specificationAlias"
            :error="errors?.['grader.specifications.' + index + '.specificationAlias']"
          />
        </template>
        <s-input-field
          class="mb-2"
          id="binaryThreshold"
          type="number"
          step="0.05"
          min="0"
          max="1"
          label="Grading Threshold"
          v-model="specification.binaryThreshold"
          :error="errors?.['grader.specifications.' + index + '.binaryThreshold']"
        />
        <s-btn
          @click="form.grader.specifications.splice(index, 1)"
          class="mt-2"
          icon="trash-can"
          size="sm"
          color="danger"
        >
          Remove
        </s-btn>
      </div>
      <s-btn
        @click.prevent="
          form.grader.specifications.push({
            specificationTitle: '',
            specificationText: '',
            specificationAlias: '',
            rubric: '',
            binaryThreshold: 0.8,
          })
        "
        :disabled="form.grader.specifications.length >= 15"
        class="mt-2"
        icon="plus"
        size="sm"
      >
        Add Specification
      </s-btn>
    </s-field>
  </task-form>
</template>
<script lang="ts" setup>
import SInputField from '../../../design-system/SInputField.vue';
import SBtn from '../../../design-system/SBtn.vue';
import STextareaField from '../../../design-system/STextareaField.vue';
import {computed} from 'vue';
import SButtonToggle from '../../../design-system/SButtonToggle.vue';
import SField from '../../../design-system/SField.vue';
import TaskForm from '../../../forms/TaskForm.vue';
import {useForm} from '@inertiajs/vue3';
import AiSpecificationQuestionFormDto = App.DTOs.Tasks.AiSpecificationQuestionFormDto;
import AiSpecificationDto = App.DTOs.Tasks.AiSpecificationDto;
import TaskStatus = App.Enums.TaskStatus;

const props = defineProps<{
  modelValue?: AiSpecificationQuestionFormDto;
  isCopy?: boolean;
  errors?: Record<string, any>;
}>();

const allowFileUpload = computed({
  get: () => form.grader.allowFileUpload,
  set: (newValue) => {
    form.ui.allowFileUpload = newValue;
    form.grader.allowFileUpload = newValue;
  },
});

const form = useForm<AiSpecificationQuestionFormDto>(
  props.modelValue || {
    title: '',
    textbookLink: null,
    textbookLinkText: null,
    textbookLinkIcon: null,
    youtubeId: null,
    type: 'AiSpecificationQuestion',
    status: 'draft' as TaskStatus,
    draftable: true,
    ui: {
      heading: '',
      question: '',
      allowFileUpload: false,
      characterLimit: null,
    },
    grader: {
      specifications: [] as AiSpecificationDto[],
      allowFileUpload: false,
      useParsing: false,
      subject: 'Chemistry',
    },
  }
);
</script>
