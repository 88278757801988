<template>
  <s-drawer
    :title="t('drawer.title')"
    :open="visible"
    @close="
      () => {
        if (!taskToPreview) {
          visible = false;
        }
      }
    "
  >
    <div class="flex-1 w-full flex flex-col gap-2">
      <div class="px-4 md:px-5 lg:px-6">
        <search>
          <div class="flex gap-x-4 gap-y-3 items-end justify-between flex-wrap w-full">
            <div class="flex flex-wrap gap-x-4 gap-y-3 text-sm whitespace-nowrap">
              <s-multi-select
                id="section"
                :label="t('search.types')"
                v-model="selectedType"
                value-key="id"
                label-key="description"
                :empty-label="t('search.allTypes')"
                :options="typeLikes"
              />
            </div>
            <s-input
              id="searchText"
              :placeholder="t('search.placeholder')"
              v-model="searchText"
              type="search"
            />
          </div>
        </search>
      </div>
      <div class="overflow-auto w-full min-h-0 flex-1">
        <table class="table dense h-px min-w-full">
          <thead class="sticky top-0 z-50 bg-white shadow-sm border-b border-gray-150">
            <tr>
              <th class="!pl-4 md:!pl-5 lg:!pl-6 !pr-0 w-0"></th>
              <th class="">
                {{ t('table.title') }}
              </th>
              <th class="text-left text-blue-600 hover:text-blue-500 cursor-pointer">
                {{ t('table.type') }}
              </th>
              <th class="!px-0 w-0">
                {{ t('table.preview') }}
              </th>
              <th class="!pr-4 md:!pr-5 lg:!pr-6 w-0"></th>
            </tr>
          </thead>
          <tbody class="!shadow-none">
            <tr v-if="modifiedTaskArray?.length < 1 && !canLoadMoreItems">
              <td colspan="6" class="bg-gradient-to-b from-gray-100 to-transparent">
                <div class="text-center text-base font-medium text-gray-400 italic py-2">
                  {{ t('search.noResults') }}
                </div>
              </td>
            </tr>
            <tr v-for="(task, index) in modifiedTaskArray">
              <td
                class="!pl-4 md:!pl-5 lg:!pl-6 !pr-0 !rounded-none text-left"
                :class="taskCellClass(task, taskToPreview, modifiedTaskArray, index)"
              >
                <s-icon v-if="task.selected" name="check" size="22" class="text-green-600" />
              </td>
              <td
                class="text-left min-w-56"
                :class="taskCellClass(task, taskToPreview, modifiedTaskArray, index)"
              >
                <s-latex :content="task.title" />
              </td>
              <td
                class="text-left max-w-44"
                :class="taskCellClass(task, taskToPreview, modifiedTaskArray, index)"
              >
                <display-task-types :taskTypes="task.taskTypes">
                  <task-badge
                    v-if="task.scoped"
                    color="blue-light"
                    :label="t('table.custom')"
                    icon="flask-outline"
                  />
                  <task-badge
                    v-if="task.isAiGraded"
                    color="green"
                    :label="t('table.aiGraded')"
                    icon="auto-fix"
                  />
                </display-task-types>
              </td>
              <td
                :class="taskCellClass(task, taskToPreview, modifiedTaskArray, index)"
                class="text-left h-full w-0 !p-0 overflow-hidden"
              >
                <button
                  id="preview"
                  @click.prevent="openTaskPreview(task)"
                  class="inline-table h-full w-full py-3 px-3.5 text-blue-500/70 hover:text-blue-500 hover:bg-gray-300/15 transition"
                >
                  <span class="flex items-center justify-center">
                    <s-icon :name="taskToPreview === task ? 'close' : 'magnify'" size="20" />
                    <span class="sr-only">
                      {{
                        taskToPreview === task ? t('table.closePreview') : t('table.openPreview')
                      }}
                    </span>
                  </span>
                </button>
              </td>
              <td
                class="h-full !p-0 !rounded-none text-left overflow-hidden !border-y-gray-150"
                :class="taskCellClass(task, taskToPreview, modifiedTaskArray, index)"
              >
                <button
                  @click="updateSelectedTasks(task)"
                  class="!inline-table h-full w-full btn !rounded-none !border-y-0 !border-r-0"
                  :class="{
                    'white !bg-transparent hover:!bg-gray-300/15': !task.selected,
                    'red-light': task.selected,
                  }"
                >
                  <span class="flex items-center justify-center gap-1">
                    <s-icon :name="task.selected ? 'minus' : 'plus'" size="20" />
                    {{ task.selected ? t('actions.remove') : t('actions.add') }}
                  </span>
                </button>
              </td>
            </tr>
            <tr v-if="visible && canLoadMoreItems" ref="loadMoreLandmark">
              <td
                colspan="6"
                class="text-center py-4 bg-gradient-to-b from-gray-100 to-transparent"
              >
                <div class="flex items-center justify-center gap-1 py-1">
                  <template v-if="loading">
                    <s-icon
                      name="loading"
                      size="32"
                      class="text-blue-500 opacity-70 animate-spin"
                    />
                    <span class="sr-only">
                      {{ t('table.loading') }}
                    </span>
                  </template>
                  <template v-else>
                    <s-btn @click="loadMoreItems()">
                      {{ t('actions.loadMore') }}
                    </s-btn>
                  </template>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
        <div
          v-if="!canLoadMoreItems"
          class="w-full flex-1 border-t border-gray-150 bg-gradient-to-b from-gray-100 to-gray-50"
        ></div>
      </div>
    </div>
  </s-drawer>
  <preview-task-modal :task="taskToPreview" @close="taskToPreview = null">
    <div class="grid grid-cols-2">
      <s-btn
        :disabled="!hasPreviousTask"
        type="button"
        @click.prevent="previousTask()"
        color="blue-light"
        icon="chevron-left"
        class="!rounded-r-none"
      >
        {{ t('actions.previous') }}
      </s-btn>
      <s-btn
        type="button"
        :disabled="!hasNextTask"
        @click.prevent="nextTask()"
        color="blue-light"
        icon="chevron-right"
        class="!rounded-l-none !border-l-0"
        iconEnd
      >
        {{ t('actions.next') }}
      </s-btn>
    </div>
    <s-btn
      @click.prevent="updateSelectedTasks(taskToPreview)"
      :icon="taskToPreview?.selected ? 'minus' : 'plus'"
      :color="taskToPreview?.selected ? 'red-light' : 'white'"
    >
      {{ taskToPreview?.selected ? t('actions.removeInPreview') : t('actions.addInPreview') }}
    </s-btn>
  </preview-task-modal>
</template>
<script setup lang="ts">
import DisplayTaskTypes from './DisplayTaskTypes.vue';
import {computed, ref, watch} from 'vue';
import SBtn from '../../../design-system/SBtn.vue';
import SIcon from '../../../design-system/SIcon.vue';
import SDrawer from '../../../design-system/SDrawer.vue';
import SLatex from '../../../components/SLatex.vue';
import {useDebounceFn, useIntersectionObserver, useVModel} from '@vueuse/core';
import {InertiaForm} from '@inertiajs/vue3';
import {useInfiniteScroll} from '../../../composables/useInfiniteScroll';
import SInput from '../../../design-system/SInput.vue';
import {Assignment} from '../../../types/entities/assignment';
import PreviewTaskModal from './PreviewTaskModal.vue';
import TaskBadge from './TaskBadge.vue';
import TaskAssignmentDto = App.DTOs.TaskAssignmentDto;
import TaskIndexDto = App.DTOs.Tasks.TaskIndexDto;
import TaskTypeDto = App.DTOs.Tasks.TaskTypeDto;
import {useI18n} from 'vue-i18n';
import SMultiSelect from '../../../design-system/SMultiSelect.vue';
import assignmentTasksTranslations from '../assignmentTasks.json';

type TaskAssignment = TaskAssignmentDto & {task: TaskIndexDto};

type SelectableTask = TaskIndexDto & {
  selected: boolean;
};

const props = defineProps<{
  form: InertiaForm<{
    tasksUpdates: TaskAssignment[];
  }>;
  visible: boolean;
  assignment: Assignment;
  assignmentTasks: TaskAssignment[];
  types: TaskTypeDto[];
}>();

// typeLikes is types, but with the added type 'Custom' to represent showOnlyCustomTasks
const typeLikes = computed(() => {
  return [
    {
      id: 'custom',
      description: 'Custom',
    },
    ...props.types.map((type) => ({
      id: type.id,
      description: type.description,
    })),
  ];
});

const searchText = ref<string>('');
const selectedType = ref<string[]>([]);
const showOnlyCustomTasks = computed(() => selectedType.value.includes('custom'));
const taskCellClass = (
  task: SelectableTask,
  taskToPreview: TaskIndexDto | null,
  tasks: SelectableTask[],
  index: number
) => {
  const nextTask = index + 1 < tasks.length ? tasks[index + 1] : null;
  return {
    '!border-b-0': !task.selected && nextTask && (nextTask.selected || taskToPreview === nextTask),
    '!text-blue-800 !border-y !border-blue-100/70 !bg-blue-50/60': taskToPreview === task,
    'text-green-800 !border-y !border-green-200': task.selected && taskToPreview !== task,
    '!bg-green-100/70 font-medium': task.selected && taskToPreview !== task,
  };
};

const visible = useVModel(props, 'visible');
const taskToPreview = ref<SelectableTask | null>(null);

function openTaskPreview(task: SelectableTask) {
  taskToPreview.value === task ? (taskToPreview.value = null) : (taskToPreview.value = task);
}

function findItemIndex(task: App.DTOs.Tasks.TaskIndexDto & {selected: boolean}) {
  const foundItem = (items.value as TaskIndexDto[]).find(
    (taskItem: TaskIndexDto) => taskItem.id === task.id
  );
  return foundItem ? items.value.indexOf(foundItem) : -1;
}

const currentTaskIndex = computed(() => {
  return findItemIndex(taskToPreview.value as SelectableTask);
});

const hasPreviousTask = computed(() => {
  return currentTaskIndex.value - 1 >= 0;
});

const hasNextTask = computed(() => {
  return currentTaskIndex.value + 1 < items.value.length;
});

function nextTask() {
  if (hasNextTask.value) {
    taskToPreview.value = items.value[currentTaskIndex.value + 1] as SelectableTask;
  }
}

function previousTask() {
  if (hasPreviousTask.value) {
    taskToPreview.value = items.value[currentTaskIndex.value - 1] as SelectableTask;
  }
}

watch(
  () => visible.value,
  (value) => {
    if (!value) {
      taskToPreview.value = null;
    }
  }
);

const isSelected = (task: TaskIndexDto) =>
  props.form.tasksUpdates.some((assignmentTask) => assignmentTask.task.id === task.id);

//This adds a selected attribute to the task, so we can select the tasks to add to the assignment
const modifiedTaskArray = computed(() => {
  return items.value.map((task) => ({
    ...(task as TaskIndexDto),
    selected: isSelected(task as TaskIndexDto),
  }));
});

function updateSelectedTasks(task: SelectableTask | null) {
  if (task) {
    task.selected = !task.selected;
    if (task.selected) {
      addTask(task as TaskIndexDto);
    } else {
      removeTask(task as TaskIndexDto);
    }
  }
}

function addTask(task: TaskIndexDto) {
  const taskAssignment: TaskAssignment = {
    id: null,
    assignment: props.assignment,
    orderingIndex: 0,
    reviewRequired: false,
    task: task,
    pointValue: 1, // Default value for pointValue, you can change it accordingly
  };
  const taskExists = props.form.tasksUpdates.some(
    (existingAssignment) => existingAssignment.task === task
  );
  if (!taskExists) {
    props.form.tasksUpdates.push(taskAssignment);
  }
}

function removeTask(task: TaskIndexDto) {
  const assignmentTask = props.form.tasksUpdates.find(
    (taskAssignment) => taskAssignment.task.id === task.id
  );

  if (assignmentTask) {
    const indexOfTask = props.form.tasksUpdates.indexOf(assignmentTask);

    props.form.tasksUpdates.splice(indexOfTask, 1);
  }
}

const nextPageUrl = ref<string>(route('api.v1.tasks.index'));

async function fetchTasks() {
  const resolved = await fetch(nextPageUrl.value);
  const data = await resolved.json();

  nextPageUrl.value = data.next_page_url;

  return data;
}

const {t} = useI18n({
  useScope: 'local',
  inheritLocale: true,
  messages: assignmentTasksTranslations,
});

const {items, removeItem, loadMoreItems, canLoadMoreItems, reset, loading} = useInfiniteScroll(
  fetchTasks,
  null
);
const loadMoreLandmark = ref<HTMLElement | null>(null);

useIntersectionObserver(loadMoreLandmark, loadMoreItems);

const debouncedFn = useDebounceFn(() => {
  reset();

  nextPageUrl.value = route('api.v1.tasks.index', {
    _query: {
      search: searchText.value,
      // strip fake 'custom' type out of selectedType.value
      type: selectedType.value.filter((type) => type !== 'custom'),
      custom: showOnlyCustomTasks.value,
    },
  });
  loadMoreItems().finally();
}, 700);

watch(searchText, debouncedFn);
watch(selectedType, debouncedFn);
watch(showOnlyCustomTasks, debouncedFn);
</script>
