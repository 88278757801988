<template>
  <s-modal v-model:open="showModel" :title="$t('periodicTable')" width="2xl">
    <periodic-table />
  </s-modal>
</template>

<script setup lang="ts">
import {defineProps, useModel} from 'vue';
import SModal from '../../design-system/SModal.vue';
import PeriodicTable from './PeriodicTable.vue';

const props = defineProps<{
  modelValue?: boolean;
}>();

const showModel = useModel(props, 'modelValue');
</script>
