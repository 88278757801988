<template>
  <div v-if="render.wrapper" class="flex gap-x-2 flex-wrap items-center justify-between">
    <s-search-badge v-if="render.searchBadge" :clearRoute="route(routeName, params)">
      <span class="font-medium">{{ $t('search.showingResultsFor') }}</span>
      {{ ` ` }}
      <strong class="text-blue-600 font-bold">{{ route().params.search }}</strong>
    </s-search-badge>
    <slot />
    <div class="flex-1 flex justify-end">
      <s-badge color="white" v-if="render.paginationBadge">
        <i18n-t keypath="table.displayingXofY" tag="p" class="text-sm text-gray-600 font-medium">
          <template v-slot:x>
            <strong class="text-blue-600 font-bold">
              {{ paginatedResults }}
            </strong>
          </template>
          <template v-slot:y>
            <strong class="text-blue-600 font-bold">
              {{ totalResults }}
            </strong>
          </template>
        </i18n-t>
      </s-badge>
    </div>
  </div>
</template>
<script setup lang="ts">
import {route} from 'ziggy-js';
import SBadge from './SBadge.vue';
import SSearchBadge from './SSearchBadge.vue';
import {computed} from 'vue';

const props = defineProps<{
  routeName: string;
  only?: string[];
  paginatedResults?: number;
  totalResults?: number;
}>();

const params = computed(() => {
  return {
    ...route().params,
    search: undefined,
  };
});

const render = computed(() => {
  const renderSlot = props.only !== undefined && props.only.includes('slot');
  const renderSearchBadge = route().params.search !== undefined;
  const renderPaginationBadge =
    props.paginatedResults !== undefined &&
    props.totalResults !== undefined &&
    props.paginatedResults !== null &&
    props.totalResults !== null;
  return {
    wrapper: renderSearchBadge || renderPaginationBadge || renderSlot,
    searchBadge: renderSearchBadge,
    paginationBadge: renderPaginationBadge,
  };
});
</script>
