<template>
  <s-page-block class="flex flex-col gap-6">
    <s-page-header :heading="`${taskEvaluation.title}`">
      <template #actions>
        <s-btn
          method="post"
          :href="
            route('admin.tasks.evaluations.rerun', {task: task.id, evaluation: taskEvaluation})
          "
        >
          Recalculate Analytics
        </s-btn>
      </template>
    </s-page-header>

    <s-label-value>
      <template #label>Task</template>
      <s-link :href="route('admin.tasks.show', {task: task.id})">
        {{ task.title }}
      </s-link>
    </s-label-value>

    <s-label-value>
      <template #label>Description</template>
      {{ taskEvaluation.description }}
    </s-label-value>

    <s-label-value>
      <template #label>Started</template>
      {{ monthDayYear(taskEvaluation.createdAt) }}
    </s-label-value>

    <s-label-value v-if="taskEvaluation.updatedAt">
      <template #label>Updated</template>
      {{ monthDayYear(taskEvaluation.updatedAt) }}
    </s-label-value>

    <s-label-value>
      <template #label>Finished</template>
      <span v-if="taskEvaluation.finishedAt">
        {{ monthDayYear(taskEvaluation.finishedAt) }}
      </span>
      <span v-else>–</span>
    </s-label-value>
  </s-page-block>

  <s-page-block v-if="taskEvaluation.analytics?.gradeStatistics">
    <h2 class="heading">Grade Analytics</h2>
    <div class="card padded">
      <task-evaluation-grade-charts :gradeStatistics="taskEvaluation.analytics?.gradeStatistics" />
    </div>
  </s-page-block>
  <s-page-block v-if="taskEvaluation.analytics?.aiFeedbackStatistics">
    <h2 class="heading">AI Feedback Analytics</h2>
    <div class="card padded">
      <task-evaluation-ai-feedback-charts
        :feedback-statistics="taskEvaluation.analytics?.aiFeedbackStatistics"
      />
    </div>
  </s-page-block>

  <s-page-block>
    <h2 class="heading">{{ taskResponseEvaluations.length }} Responses Evaluated</h2>
    <ul class="flex flex-col gap-3">
      <li
        v-for="responseEvaluation in taskResponseEvaluations"
        :key="responseEvaluation.id"
        class="card padded flex gap-2"
      >
        Response: {{ responseEvaluation.id }}
        <s-btn
          color="white"
          class="w-fit"
          size="sm"
          :href="
            route('admin.tasks.evaluations.responses.show', {
              task: task.id,
              taskEvaluation: taskEvaluation.id,
              taskResponseEvaluation: responseEvaluation.id,
            })
          "
        >
          View
        </s-btn>
      </li>
    </ul>
  </s-page-block>
</template>

<script setup lang="ts">
import SPageHeader from '../../../design-system/SPageHeader.vue';
import {Task} from '../../../types/entities/tasks';
import SPageBlock from '../../../design-system/SPageBlock.vue';
import {useBreadcrumbs} from '../../../composables/useBreadcrumbs';
import {route} from 'ziggy-js';
import SLink from '../../../components/SLink.vue';
import SLabelValue from '../../../design-system/SLabelValue.vue';
import {monthDayYear} from '../../../format/dates';
import SBtn from '../../../design-system/SBtn.vue';
import TaskEvaluationGradeCharts from './TaskEvaluationAnalytics/TaskEvaluationGradeCharts.vue';
import TaskEvaluationAiFeedbackCharts from './TaskEvaluationAnalytics/TaskEvaluationAiFeedbackCharts.vue';
import TaskEvaluationDto = App.DTOs.TaskEvaluationDto;
import TaskResponseEvaluationDto = App.DTOs.TaskResponseEvaluationDto;

const props = defineProps<{
  task: Task;
  taskEvaluation: TaskEvaluationDto;
  taskResponseEvaluations: TaskResponseEvaluationDto[];
}>();

useBreadcrumbs([
  {label: 'Tasks', href: route('admin.tasks.index')},
  {label: props.task.title, href: route('admin.tasks.show', {task: props.task.id})},
  {label: 'Evaluations', href: route('admin.tasks.evaluations.index', {task: props.task.id})},
]);
</script>
