<template>
  <s-help-button :title="t('help.title')">
    <div class="prose">
      <p>{{ t('help.intro') }}</p>
      <p>
        {{ t('help.description') }}
      </p>
      <template v-if="dataScope">
        <hr />
        <p class="pt-2">
          <span class="font-bold">{{ t('help.scope') }}</span>
          {{ dataScope }}
        </p>
      </template>
    </div>
  </s-help-button>
  <v-chart class="chart" :option="option" autoresize />
</template>

<script setup lang="ts">
import type {EChartsOption} from 'echarts';
import {use} from 'echarts/core';
import {CanvasRenderer} from 'echarts/renderers';
import {PieChart} from 'echarts/charts';
import {
  TitleComponent,
  TooltipComponent,
  LegendComponent,
  GridComponent,
  VisualMapComponent,
} from 'echarts/components';
import VChart from 'vue-echarts';
import {computed} from 'vue';
import SHelpButton from '../../../design-system/SHelpButton.vue';
import {useI18n} from 'vue-i18n';

use([
  CanvasRenderer,
  PieChart,
  TitleComponent,
  TooltipComponent,
  LegendComponent,
  GridComponent,
  VisualMapComponent,
]);

const props = defineProps<{
  data: {[key: string]: number};
  dataScope?: string | null;
}>();

const {t} = useI18n();

const chartData = computed(() => {
  return Object.entries(props.data ?? {}).map(([mistakeLabel, value]) => ({
    name: mistakeLabel,
    value,
  }));
});

const option = computed<EChartsOption>(() => ({
  tooltip: {
    trigger: 'none',
  },
  title: {
    text: t('chart.title'),
    textAlign: 'center',
    left: '50%',
  },
  series: [
    {
      name: t('chart.series'),
      type: 'pie',
      radius: ['40%', '70%'],
      avoidLabelOverlap: false,
      itemStyle: {
        borderRadius: 10,
        borderColor: '#fff',
        borderWidth: 2,
      },
      startAngle: 315,
      endAngle: 45,
      label: {
        show: false,
        position: 'center',
        offset: [-100, 0],
      },
      emphasis: {
        label: {
          show: true,
          fontWeight: 'bold',
          align: 'left',
        },
      },
      labelLine: {
        show: false,
      },
      data: chartData.value,
    },
  ],
}));
</script>

<style scoped>
.chart {
  width: 100%;
  aspect-ratio: 16 / 9;
  max-height: 40vh;
}
</style>
<i18n>
{
  "en": {
    "help": {
      "title": "Most Common Feedback",
      "intro": "This chart shows the most common feedback returned by the grading algorithms.",
      "description": "Feedback occupying large portions of the donut chart indicate that a large portion of students received that feedback and that it may be a common mistake or source of confusion.",
      "scope": "Scope:"
    },
    "chart": {
      "title": "Most Common Feedback",
      "series": "Access From"
    }
  },
  "fr": {
    "help": {
      "title": "Commentaires les plus courants",
      "intro": "Ce graphique montre les commentaires les plus courants donnés par les algorithmes de notation.",
      "description": "Les retours occupant de grandes portions du graphique en anneau indiquent qu'une grande partie des étudiants ont reçu ce commentaire, ce qui peut suggérer une erreur courante ou une source de confusion.",
      "scope": "Porté:"
    },
    "chart": {
      "title": "Commentaires les plus courants",
      "series": "Accès depuis"
    }
  }
}

</i18n>
