<template>
  <div class="@container w-full flex flex-col gap-3">
    <div class="grid grid-cols-1 @sm:grid-cols-[1fr_auto] @2xl:grid-cols-[2fr_3fr_auto] gap-4">
      <div class="@sm:col-span-2 @2xl:col-span-1">
        <s-input-field label="Name" v-model="name" id="calculationName" :error="errors?.name" />
      </div>
      <s-input-field
        label="Expression"
        v-model="expression"
        id="calculationExpression"
        :error="errors?.expression"
      />
    </div>
  </div>
</template>

<script setup lang="ts">
import SInputField from '../../../../../design-system/SInputField.vue';
import {useVModels} from '@vueuse/core';

const props = defineProps<{
  name: string;
  expression: string;
  errors?: Record<string, string>;
}>();

const {name, expression} = useVModels(props);
</script>
