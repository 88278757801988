<template>
  <s-field v-bind="props">
    <template v-slot="a11yProps">
      <MazPhoneNumberInput
        v-bind="{...$attrs, ...a11yProps}"
        no-validation
        no-example
        :placeholder="$t('phoneNumberInput.placeholder')"
        :model-value="modelValue"
        @input="$emit('update:modelValue', $event.target.value)"
      />
    </template>
  </s-field>
</template>
<script setup lang="ts">
import MazPhoneNumberInput from 'maz-ui/components/MazPhoneNumberInput';
import SField from './SField.vue';

const props = defineProps({
  id: {
    type: String,
    required: true,
  },
  label: {
    type: String,
    required: true,
  },
  description: {
    type: String,
    default: '',
  },
  modelValue: {
    type: String,
    default: '',
  },
  error: {
    type: String,
    default: '',
  },
  required: {
    type: Boolean,
    default: false,
  },
});

defineEmits(['update:modelValue']);
</script>
<script lang="ts">
export default {
  inheritAttrs: false,
};
</script>
<style>
.m-input-wrapper {
  @apply px-4 py-2.5 leading-tight rounded-md !border !border-gray-200 shadow-inner !bg-white !overflow-visible;
}

.m-phone-number-input__country-flag {
  @apply !top-1/2 !bottom-auto !transform !-translate-y-1/2;
}

.m-phone-number-input__input {
  @apply !flex-1;
}

.m-input.--has-label .m-input-input {
  @apply !pt-0;
}

.m-input.--should-up .m-input-label {
  @apply !opacity-0;
}

.m-input-wrapper *::placeholder {
  @apply text-gray-400 opacity-50;
}

.m-select-list {
  @apply !bg-white shadow-soft border border-gray-200 !rounded-md;
}

.m-select-list__search-wrapper {
  @apply !mb-2;
}

.m-select-list-item {
  @apply transition-all ease-out duration-150;
}

.m-select-list-item:hover {
  @apply !bg-gray-100;
}

.m-phone-number-input__select__item {
  @apply flex gap-2 items-center truncate;
}
</style>
