<template>
  <div
    ref="editorEl"
    class="whitespace-pre-wrap pm-wrap"
    :class="{
      'input border border-black': !readOnly,
    }"
  ></div>
</template>
<script setup lang="ts">
import {onMounted, ref, toRaw, watch} from 'vue';

// Prosemirror
import {Node, Schema} from 'prosemirror-model';
import {Plugin} from 'prosemirror-state';

// Config
import {docSchema} from './schema/doc-schema';
import {initializePlugins} from './config/initializePlugins';

import '@benrbray/prosemirror-math/dist/prosemirror-math.css';
import 'katex/dist/katex.min.css';
import {createState} from './config/createState';
import {createEditorView} from './config/createEditorView';

const props = defineProps<{
  schema?: Schema;
  plugins?: Plugin[];
  readOnly?: boolean;
  output?: 'json' | 'html' | 'node';
}>();

const model = defineModel<Node | string | undefined>();
const editorEl = ref<HTMLElement | null>(null);

onMounted(() => {
  /**
   * Make sure the editor element is available
   */
  const el = editorEl.value;
  if (!el) {
    return;
  }

  const value = toRaw(model.value);

  /**
   * Pick the schema
   */
  const schema = props.schema || docSchema;

  /**
   * Initialize Plugins based on Schema
   */
  const plugins = initializePlugins(schema, props.plugins);

  /**
   * Initialize State
   */
  const state = createState(schema, value, plugins);

  const view = createEditorView({
    el,
    state,
    editable: !props.readOnly,
    output: props.output || 'json',
    onChange(value) {
      model.value = value;
    },
  });

  /**
   * Re-initialize State if the model value is changed externally.
   */
  watch(model, (newValue) => {
    if (typeof newValue === 'string') {
      return;
    }

    if (view.hasFocus()) {
      return;
    }

    view.updateState(createState(schema, newValue, plugins));
  });
});
</script>
<style>
.pm-wrap {
  h1 {
    @apply text-4xl font-bold;

    &:not(:first-child) {
      @apply mt-6;
    }
  }

  h2 {
    @apply text-3xl font-bold;

    &:not(:first-child) {
      @apply mt-5;
    }
  }

  h3 {
    @apply text-2xl font-bold;

    &:not(:first-child) {
      @apply mt-4;
    }
  }

  h4 {
    @apply text-xl font-bold;

    &:not(:first-child) {
      @apply mt-3;
    }
  }

  h5 {
    @apply text-lg font-bold;

    &:not(:first-child) {
      @apply mt-2;
    }
  }

  h6 {
    @apply text-md font-bold;

    &:not(:first-child) {
      @apply mt-1;
    }
  }

  blockquote {
    @apply border-l-4 border-slate-300 pl-2;
  }

  p {
    @apply my-2;
  }

  ul {
    @apply list-disc pl-4;
  }

  ol {
    @apply list-decimal pl-4;
  }

  a {
    @apply text-blue-500 underline;
  }

  code {
    @apply bg-gray-200 text-gray-800 px-1 rounded-sm;
  }
}

.ProseMirror {
  &:focus {
    @apply outline-none border-teal-500 shadow-teal-50;
  }
}
</style>
