<template>
  <div class="flex flex-col gap-1">
    <div
      v-if="taskResponses.length > 0"
      class="w-full flex flex-wrap items-center justify-between gap-2 text-sm whitespace-nowrap"
    >
      <div class="flex flex-wrap items-center gap-2">
        <span class="font-medium text-gray-600">
          <i18n-t keypath="responseXofY">
            <template #x>
              <strong class="text-gray-800">{{ attemptNumber }}</strong>
            </template>

            <template #y>
              <strong class="text-gray-800">{{ taskResponses.length }}</strong>
            </template>
          </i18n-t>
        </span>

        <s-badge v-if="selectedResponse?.isDraft" size="sm">
          <span class="font-bold text-gray-600 tracking-wide flex items-center gap-0.5">
            <s-icon name="pencil" class="opacity-70" size="14" />
            {{ t('draft') }}
          </span>
        </s-badge>

        <span
          v-if="
            selectedResponse?.id &&
            isTaskResponseEffective(selectedResponse.id, studentAssignmentTaskGrades)
          "
          class="uppercase text-sm font-bold text-blue-600 flex items-center gap-0.5"
        >
          <s-icon name="check-bold" class="opacity-70" size="16" />
          {{ t('activeGrade') }}
        </span>
      </div>

      <s-badge v-if="!selectedResponse?.isDraft && responseIsLate && dueDate" size="sm" color="red">
        <span class="font-bold text-red-700 tracking-wide flex items-center gap-0.5">
          <s-icon name="clock-alert-outline" class="opacity-70" size="14" />
          {{
            formatDistance(getResponseTimestamp(response), dueDate, {
              addSuffix: false,
            })
          }}
          {{ t('late') }}
        </span>
      </s-badge>
    </div>
    <div
      v-if="response && !response?.isSelfSubmitted"
      class="text-sm text-gray-600 font-medium leading-tight mb-1.5"
    >
      {{ t('submittedBy') }}
      <span class="inline font-bold text-gray-600">
        {{ selectedResponse?.submittedBy.firstName }}
        {{ selectedResponse?.submittedBy.lastName }}
      </span>
      {{ t('onBehalfOf') }}
      <span class="inline font-bold text-gray-600">
        {{ selectedResponse?.user.firstName }}
        {{ selectedResponse?.user.lastName }}
      </span>
      {{ t('on') }}
      <span class="inline font-bold text-gray-600">
        {{
          monthDaySometimesYear(selectedResponse?.submittedAt, {
            time: true,
          })
        }}
      </span>
    </div>
    <div class="flex gap-3 flex-wrap items-center justify-between py-0.5">
      <response-listbox
        v-if="taskResponses.length"
        :draft-task-ids="draftTaskIds"
        :task-responses="taskResponses"
        v-model="selectedResponse"
        :student-assignment-task-grades="studentAssignmentTaskGrades"
        :attempted-task-ids="attemptedTaskIds"
        :due-date="dueDate"
        :assignment-extension="assignmentExtension"
        position="top"
      />
      <div v-else>
        <s-badge>{{ t('noResponsesYet') }}</s-badge>
      </div>
      <slot name="actions" />
    </div>
  </div>
</template>
<script setup lang="ts">
import {computed} from 'vue';
import {Grade} from '../../../types/entities/base-grade';
import {Task, TaskResponse} from '../../../types/entities/tasks';
import SBadge from '../../../design-system/SBadge.vue';
import ResponseListbox from './ResponseListbox.vue';
import {useVModel} from '@vueuse/core';
import {formatDistance, monthDaySometimesYear} from '../../../format/dates';
import {getResponseTimestamp, isResponseLate} from '../../../util/response-timestamps';
import {AssignmentExtension} from '../../../types/entities/assignment-extension';
import SIcon from '../../../design-system/SIcon.vue';
import {isTaskResponseEffective} from './task-response-state-utils';
import {useI18n} from 'vue-i18n';

const {t} = useI18n({
  inheritLocale: true,
  useScope: 'local',
});

type PartialTaskResponse = {id: number} & Pick<
  TaskResponse,
  | 'createdAt'
  | 'updatedAt'
  | 'submittedAt'
  | 'isDraft'
  | 'hasAttachments'
  | 'isSelfSubmitted'
  | 'lastSavedAt'
  | 'submittedBy'
  | 'user'
>;

const props = defineProps<{
  dueDate: string | null | undefined;
  assignmentExtension?: AssignmentExtension | null;

  assignmentTasks: {
    id: number;
    orderingIndex: number;
    pointValue: number;
  }[];

  // Student
  attemptedTaskIds: number[];
  draftTaskIds: number[];

  // Task
  task: Task;
  taskResponses: PartialTaskResponse[];
  studentAssignmentTaskGrades: Grade[];

  // Response
  response: TaskResponse | null;

  selectedResponse: PartialTaskResponse | null;
}>();

const selectedResponse = useVModel(props, 'selectedResponse');

const taskNumber = computed(() => {
  return props.assignmentTasks.findIndex((task) => task.id === props.task.id) + 1;
});

const currentTask = computed(() => props.assignmentTasks[taskNumber.value - 1]);

const taskWeight = computed(() => currentTask.value?.pointValue || 0);

const attemptNumber = computed(() => {
  if (!props.response) {
    return 1;
  }

  return (
    props.taskResponses.length -
    props.taskResponses.findIndex((response) => response.id === props.response?.id)
  );
});

const responseIsLate = computed(() =>
  isResponseLate(props.dueDate, props.response, props.assignmentExtension)
);
</script>
<i18n>
{
  "en": {
    "responseXofY": "Response {x} of {y}",
    "activeGrade": "Active Grade",
    "late": "late",
    "submittedBy": "Submitted by",
    "onBehalfOf": "on behalf of",
    "on": "on",
    "noResponsesYet": "No responses yet"
  },
  "fr": {
    "responseXofY": "Réponse {x} sur {y}",
    "activeGrade": "Note active",
    "late": "en retard",
    "submittedBy": "Soumis par",
    "onBehalfOf": "au nom de",
    "on": "le",
    "noResponsesYet": "Aucune réponse pour le moment"
  }
}
</i18n>
