import {Node, Schema} from 'prosemirror-model';
import DataDrivenTaskFormDto = App.DTOs.Tasks.DataDrivenTaskFormDto;
import TextBlockUiConfig = App.Entities.Tasks.Ui.TextBlockUiConfig;
import UiConfig = App.Entities.Tasks.Ui.UiConfig;
import SingleCalculationUiConfig = App.Entities.Tasks.Ui.SingleCalculationUiConfig;
import SingleCalculationComponentGrader = App.Entities.Tasks.ComponentGraders.SingleCalculationComponentGrader;
import TaskComponentGrader = App.Entities.Tasks.ComponentGraders.TaskComponentGrader;
import {InertiaForm} from '@inertiajs/vue3';

export class DataDrivenTaskSerializer {
  constructor(private schema: Schema) {}

  /**
   * Converts a DataDrivenTaskFormDto to a Prosemirror document
   */
  fromDto(dto: DataDrivenTaskFormDto & {errors?: any}): Node {
    const content: Node[] = [];

    dto.uiConfigs.forEach((uiConfig, index) => {
      if (uiConfig.type === 'TextBlock') {
        let config = uiConfig as TextBlockUiConfig;

        content.push(
          this.schema.node(
            'taskTextBlock',
            {
              part: config.part,
              uuid: config.uuid,
              name: config.name,
            },
            [this.schema.text(config.text || ' ')]
          )
        );
      }

      if (uiConfig.type === 'SingleCalculation') {
        let config = uiConfig as SingleCalculationUiConfig;
        let graderConfigIndex = dto.gradingAlgorithm.componentGraders.findIndex(
          (grader) => grader.uiComponentUuid === config.uuid
        )!;
        let graderConfig = dto.gradingAlgorithm.componentGraders[
          graderConfigIndex
        ] as SingleCalculationComponentGrader;

        let errors: any = {};
        if (dto.errors) {
          Object.keys(dto.errors).forEach((key) => {
            if (key.startsWith(`uiConfigs.${index}.`)) {
              const errorKey = key.replace(`uiConfigs.${index}.`, '');
              errors[errorKey] = dto.errors[key as keyof typeof dto.errors];
            } else if (key.startsWith(`gradingAlgorithm.componentGraders.${graderConfigIndex}.`)) {
              const errorKey = key.replace(
                `gradingAlgorithm.componentGraders.${graderConfigIndex}.`,
                ''
              );
              errors[errorKey] = dto.errors[key as keyof typeof dto.errors];
            }
          });
        }

        content.push(
          this.schema.node('taskSingleCalculation', {
            errors,
            ui: {
              type: config.type,
              part: config.part,
              uuid: config.uuid,
              name: config.name,
              question: config.question,
              prependText: config.prependText,
              appendText: config.appendText,
            },
            grader: {
              type: graderConfig.type,
              uuid: graderConfig.uuid,
              name: graderConfig.name,
              uiComponentUuid: config.uuid,
              fallbackFeedback: graderConfig.fallbackFeedback,
              calculations: graderConfig.calculations,
              tolerance: graderConfig.tolerance,
              subexpressions: graderConfig.subexpressions,
            },
          })
        );
      }
    });

    return this.schema.node(
      'doc',
      {
        title: dto.title,
        status: dto.status,
        youtubeId: dto.youtubeId,
        textbookLink: dto.textbookLink,
        textbookLinkText: dto.textbookLinkText,
        textbookLinkIcon: dto.textbookLinkIcon,
        draftable: dto.draftable,
        type: dto.type,
        gradingAlgorithmUuid: dto.gradingAlgorithm.uuid,
        variables: dto.variables,
      },
      content
    );
  }

  /**
   * Converts a Prosemirror document back to a DataDrivenTaskFormDto
   */
  toDto(doc: Node): DataDrivenTaskFormDto {
    // Extract text content from the document
    const uiConfigs: UiConfig[] = [];
    const graderConfigs: TaskComponentGrader[] = [];
    doc.forEach((node) => {
      if (node.type.name === 'taskTextBlock') {
        uiConfigs.push({
          type: 'TextBlock',
          part: node.attrs.part,
          uuid: node.attrs.uuid,
          name: node.attrs.name,
          text: node.textContent,
        } as TextBlockUiConfig);
      }

      if (node.type.name === 'taskSingleCalculation') {
        uiConfigs.push({
          type: node.attrs.ui.type,
          part: node.attrs.ui.part,
          uuid: node.attrs.ui.uuid,
          name: node.attrs.ui.name,
          question: node.attrs.ui.question,
          prependText: node.attrs.ui.prependText,
          appendText: node.attrs.ui.appendText,
        } as SingleCalculationUiConfig);
        graderConfigs.push({
          type: node.attrs.grader.type,
          uuid: node.attrs.grader.uuid,
          name: node.attrs.grader.name,
          uiComponentUuid: node.attrs.grader.uiComponentUuid,
          fallbackFeedback: node.attrs.grader.fallbackFeedback,
          calculations: node.attrs.grader.calculations,
          tolerance: node.attrs.grader.tolerance,
          subexpressions: node.attrs.grader.subexpressions,
        } as SingleCalculationComponentGrader);
      }
    });

    return {
      type: 'DataDrivenTask',
      title: doc.attrs.title,
      textbookLink: doc.attrs.textbookLink,
      textbookLinkText: doc.attrs.textbookLinkText,
      textbookLinkIcon: doc.attrs.textbookLinkIcon,
      youtubeId: doc.attrs.youtubeId,
      status: doc.attrs.status,
      draftable: doc.attrs.draftable,
      uiConfigs,
      gradingAlgorithm: {
        uuid: doc.attrs.gradingAlgorithmUuid,
        componentGraders: graderConfigs,
      },
      variables: [],
      files: [],
    };
  }
}
