<template>
  <Head title="Admin - Users"></Head>
  <div class="page-block">
    <s-page-header heading="Users" class="mb-3" />

    <form @submit.prevent="submitSearch" class="flex-1 flex gap-1 max-w-full mb-5" role="form">
      <s-input
        id="email_field"
        placeholder="Email"
        class="flex-1 min-w-0 !pl-4 focus:!ring-blue-200"
        type="text"
        size="sm"
        v-model="form.email"
      />
      <s-input
        id="first_name_field"
        placeholder="First Name"
        class="flex-1 min-w-0 !pl-4 focus:!ring-blue-200"
        type="text"
        size="sm"
        v-model="form.firstName"
      />
      <s-input
        id="last_name_field"
        placeholder="Last Name"
        class="flex-1 min-w-0 !pl-4 focus:!ring-blue-200"
        type="text"
        size="sm"
        v-model="form.lastName"
      />
      <s-btn type="submit" class="!px-6" icon="magnify">Search</s-btn>
    </form>

    <section class="stack vertical gap-3 mb-5">
      <s-link
        v-for="user in users?.data"
        :href="route('admin.users.show', user.id)"
        class="card group/link"
      >
        <div
          class="card-content xs flex items-center justify-between gap-3 transition group-hover/link:text-blue-500"
        >
          <div class="flex flex-col flex-1">
            <div class="text-lg font-bold">{{ user.firstName }} {{ user.lastName }}</div>
            <div class="opacity-80">
              <div class="flex items-center gap-1">
                <s-icon name="email" size="16" class="opacity-70" />
                {{ user.email }}
              </div>
              <div v-if="user.phoneNumber" class="flex items-center gap-1">
                <s-icon name="phone" size="16" class="opacity-70" />
                <span>{{ user.phoneNumber }}</span>
              </div>
              <div v-if="user.studentId" class="flex items-center gap-1">
                <s-icon name="account" size="16" class="opacity-70" />
                {{ user.studentId }}
              </div>
            </div>
          </div>
          <div class="flex gap-2">
            <s-badge v-if="user.isSuperUser" color="blue-light">Super User</s-badge>
            <s-badge v-if="user.institution">
              {{ user.institution.name }}
            </s-badge>
            <s-icon
              name="chevron-right"
              class="opacity-30 group-hover/link:text-blue-500 group-hover/link:opacity-100 transition"
            />
          </div>
        </div>
      </s-link>
    </section>

    <s-paginator :paginator="users" class="mb-2" />
  </div>
</template>
<script setup lang="ts">
import {route} from 'ziggy-js';
import {User} from '../../../types/entities/user';
import {LaravelPaginator} from '../../../types/laravel-paginator';
import SLink from '../../../components/SLink.vue';
import SPaginator from '../../../design-system/SPaginator.vue';
import SPageHeader from '../../../design-system/SPageHeader.vue';
import {Head, router, useForm} from '@inertiajs/vue3';
import SIcon from '../../../design-system/SIcon.vue';
import SBadge from '../../../design-system/SBadge.vue';
import {Institution} from '../../../types/entities/institution';
import SInput from '../../../design-system/SInput.vue';
import SBtn from '../../../design-system/SBtn.vue';
import {usePageParams} from '../../../composables/usePageParams';

defineProps<{
  users: LaravelPaginator<
    User & {
      isSuperUser: boolean;
      institution: Institution | null;
    }
  >;
}>();

type PageParams = {
  firstName: string;
  lastName: string;
  email: string;
};
const {email, firstName, lastName} = usePageParams<PageParams>({
  firstName: '',
  lastName: '',
  email: '',
});

const form = useForm<PageParams>({
  email,
  firstName,
  lastName,
});

const submitSearch = () => {
  router.visit(
    route('admin.users.index', {
      email: form.email,
      firstName: form.firstName,
      lastName: form.lastName,
    })
  );
};
</script>
