<template>
  <div class="@container">
    <div class="grid @2xl:grid-cols-[1fr_auto] items-end gap-1 mb-4">
      <div class="">
        <h3 class="heading md">
          <task-rubric-text :text="part.label" :rubric="rubric" />
        </h3>
        <p v-if="part.description" class="whitespace-pre-wrap mt-1">
          <s-latex :content="part.description" />
        </p>
        <template v-if="part.modifiers?.length">
          <p class="text-base font-bold leading-tight mt-2 mb-1.5">{{ t('modifiers') }}</p>
          <ul class="text-sm leading-tight flex flex-wrap items-center gap-x-2 gap-y-1 pb-1">
            <li v-for="modifier in part.modifiers">
              <s-tooltip :text="modifier.description">
                <template v-if="modifier.value > 0">
                  <s-badge-group>
                    <s-badge size="sm" color="blue-light">{{ modifier.name }}</s-badge>
                    <s-badge size="sm" color="blue-light">
                      {{
                        modifier.type === 'relative'
                          ? t('xPercent', modifier.value)
                          : t('xPoints', modifier.value)
                      }}
                    </s-badge>
                  </s-badge-group>
                </template>
                <template v-else>
                  <s-badge-group>
                    <s-badge size="sm" color="red">{{ modifier.name }}</s-badge>
                    <s-badge size="sm" color="red">
                      {{
                        modifier.type === 'relative'
                          ? t('xPercent', modifier.value)
                          : t('xPoints', modifier.value)
                      }}
                    </s-badge>
                  </s-badge-group>
                </template>
              </s-tooltip>
            </li>
          </ul>
        </template>
      </div>
      <div class="flex gap-2 py-1.5">
        <s-badge-group>
          <s-badge>
            <s-icon v-if="part.icon" :name="part.icon" size="16" />
            {{ part.type }}
          </s-badge>
          <s-badge v-if="part.uiSubtype && part.uiSubtype === 'radio'">
            <s-icon name="checkbox-multiple-marked-circle-outline" size="16" />
            {{ t('ui.radioButtons') }}
          </s-badge>
          <s-badge v-if="part.uiSubtype && part.uiSubtype === 'dropdown'">
            <s-icon name="form-dropdown" size="16" />
            {{ t('ui.dropdownMenu') }}
          </s-badge>
        </s-badge-group>
        <s-badge v-if="part.pointsAvailable">
          {{ t('xPoints', part.pointsAvailable) }}
        </s-badge>
      </div>
    </div>
    <s-table v-if="part.buckets.length" class="leading-snug">
      <template #header>
        <tr class="">
          <th class="!w-0 !px-0"></th>
          <th class="!pl-4">
            <s-tooltip size="md" color="white">
              <span class="flex items-center gap-1 group">
                {{ t('bucket&Criteria') }}
                <s-icon
                  name="help-circle"
                  size="16"
                  class="text-blue-500 opacity-70 group-hover:opacity-100"
                />
              </span>
              <template #tooltip>
                <p class="max-w-prose text-sm">
                  {{ t('rubricHelp') }}
                </p>
              </template>
            </s-tooltip>
          </th>
          <th>{{ t('pointsAwarded') }}</th>
          <th>{{ t('feedbackToStudent') }}</th>
        </tr>
      </template>
      <template #body>
        <tr
          v-for="bucket in part.buckets.sort((a, b) => b.points - a.points)"
          :key="bucket.name"
          class="bg-gradient-to-br"
          :class="{
            'from-green-200 via-green-100/30 to-green-50/0': bucket.points == part.pointsAvailable,
            'from-blue-100/50 via-blue-50/0 to-blue-50/0':
              typeof bucket.points === 'string' ||
              (bucket.points > 0 && bucket.points < part.pointsAvailable!),
            'from-red-200 via-red-100/20 to-red-50/0': bucket.points == 0,
          }"
        >
          <td class="relative !px-0 !w-2">
            <div
              class="absolute z-1 inset-0"
              :class="{
                'bg-gradient-to-b from-green-400 to-green-300':
                  bucket.points == part.pointsAvailable,
                'bg-gradient-to-b from-blue-300 to-blue-200':
                  typeof bucket.points === 'string' ||
                  (bucket.points > 0 && bucket.points < part.pointsAvailable!),
                'bg-gradient-to-b from-red-400 to-red-300': bucket.points == 0,
              }"
            ></div>
          </td>
          <td class="relative z-0 !pl-4">
            <h3 class="font-bold text-lg leading-tighter">
              <task-rubric-text :text="bucket.name" :rubric="rubric" />
            </h3>
            <ul
              v-if="bucket.criteria.length"
              class="text-sm leading-tight flex flex-col gap-0.5 mt-1"
            >
              <li v-for="criteria in bucket.criteria" class="flex items-center gap-1">
                <span class="opacity-50">&ndash;</span>
                <task-rubric-text :text="criteria" :rubric="rubric" />
              </li>
            </ul>

            <template v-if="bucket.modifiers?.length">
              <p class="text-base font-bold leading-tight mt-2 mb-1.5">
                {{ t('modifiers') }}
              </p>
              <ul class="text-sm leading-tight flex flex-wrap items-center gap-x-2 gap-y-1">
                <li v-for="modifier in bucket.modifiers">
                  <s-tooltip :text="modifier.description">
                    <template v-if="modifier.value > 0">
                      <s-badge-group>
                        <s-badge size="sm" color="blue-light">{{ modifier.name }}</s-badge>
                        <s-badge size="sm" color="blue-light">
                          {{
                            modifier.type === 'relative'
                              ? t('xPercent', modifier.value)
                              : t('xPoints', modifier.value)
                          }}
                        </s-badge>
                      </s-badge-group>
                    </template>
                    <template v-else>
                      <s-badge-group>
                        <s-badge size="sm" color="red">{{ modifier.name }}</s-badge>
                        <s-badge size="sm" color="red">
                          {{ modifier.value }}
                          {{
                            modifier.type === 'relative'
                              ? t('xPercent', modifier.value)
                              : t('xPoints', modifier.value)
                          }}
                        </s-badge>
                      </s-badge-group>
                    </template>
                  </s-tooltip>
                </li>
              </ul>
            </template>
          </td>
          <td class="relative z-1 whitespace-nowrap w-auto">
            <s-badge-group v-if="typeof bucket.points === 'number'" class="w-fit !inline-flex">
              <s-badge
                size="custom"
                class="pl-3 pr-2.5 py-0.5 text-sm"
                :color="
                  bucket.points == part.pointsAvailable
                    ? 'green'
                    : bucket.points == 0
                      ? 'red'
                      : 'white'
                "
              >
                {{ bucket.points }}
              </s-badge>
              <s-badge
                size="custom"
                class="pl-2.5 pr-3 py-0.5 text-sm"
                :color="
                  bucket.points == part.pointsAvailable
                    ? 'green'
                    : bucket.points == 0
                      ? 'red'
                      : 'white'
                "
              >
                {{ part.pointsAvailable }}
              </s-badge>
            </s-badge-group>
            <s-latex v-else :content="bucket.points" />
          </td>
          <td class="relative z-1">
            <div class="prose text-base leading-snug">
              <template v-if="bucket.feedback">{{ bucket.feedback }}</template>
              <span v-else class="opacity-50 italic">
                {{ t('none') }}
              </span>
            </div>
          </td>
        </tr>
      </template>
    </s-table>
  </div>
</template>
<script setup lang="ts">
import TaskRubricText from './TaskRubricText.vue';
import STooltip from '../design-system/STooltip.vue';
import SBadgeGroup from '../design-system/SBadgeGroup.vue';
import SBadge from '../design-system/SBadge.vue';
import SIcon from '../design-system/SIcon.vue';
import SLatex from './SLatex.vue';
import STable from '../design-system/STable.vue';
import Rubric = App.DTOs.Rubrics.Rubric;
import PartRubric = App.DTOs.Rubrics.PartRubric;
import {useI18n} from 'vue-i18n';

defineProps<{
  rubric: Rubric;
  part: PartRubric;
}>();

const {t} = useI18n({
  inheritLocale: true,
  useScope: 'local',
});
</script>
<i18n>
{
  "en": {
    "bucket&Criteria": "Grade Bucket & Criteria",
    "modifiers": "Modifiers",
    "none": "(None)",
    "ui.radioButtons": "Radio Buttons",
    "ui.dropdownMenu": "Dropdown Menu",
    "rubricHelp": "Stemble buckets student's work into categories based on the listed criteria. The student then receives the points and feedback associated with the bucket that their work falls into.",
    "pointsAwarded": "Points Awarded",
    "feedbackToStudent": "Feedback to Student"
  },
  "fr": {
    "bucket&Criteria": "Seau de notes et critères",
    "modifiers": "Modificateurs",
    "none": "(Aucun)",
    "ui.radioButtons": "Boutons radio",
    "ui.dropdownMenu": "Menu déroulant",
    "rubricHelp": "Stemble classe le travail de l'étudiant dans des catégories basées sur les critères énumérés. L'étudiant reçoit ensuite les points et les commentaires associés au seau dans lequel son travail tombe.",
    "pointsAwarded": "Points attribués",
    "feedbackToStudent": "Commentaires à l'étudiant"
  }
}
</i18n>
