<template>
  <ol class="periodic-table">
    <li
      v-for="(element, index) in elements"
      :id="element.name"
      :key="element.name"
      class="element"
      :class="element.type"
      :value="element.atomicWeight"
    >
      <p class="atomic-number">{{ index + 1 }}</p>
      <abbr :data-title="element.name">{{ element.symbol }}</abbr>
      <p class="atomic-weight">{{ frenchify(element.atomicWeight) }}</p>
    </li>
  </ol>
</template>
<script setup lang="ts">
import {useI18n} from 'vue-i18n';

interface ElementItem {
  type: string;
  name: string;
  atomicWeight: string;
  symbol: string;
}

const {t, locale} = useI18n();

const frenchify = (atomicWeight: string) => {
  if (locale.value.startsWith('fr')) {
    return atomicWeight.replace('.', ',');
  }
  return atomicWeight;
};

const elements: ElementItem[] = [
  {
    type: 'hydrogen',
    name: t('Hydrogen'),
    atomicWeight: '1.008',
    symbol: 'H',
  },
  {
    type: 'helium',
    name: t('Helium'),
    atomicWeight: '4.0026',
    symbol: 'He',
  },
  {
    type: 'alkali',
    name: t('Lithium'),
    atomicWeight: '6.94',
    symbol: 'Li',
  },
  {
    type: 'alkaline',
    name: t('Beryllium'),
    atomicWeight: '9.0122',
    symbol: 'Be',
  },
  {
    type: 'metalloid',
    name: t('Boron'),
    atomicWeight: '10.81',
    symbol: 'B',
  },
  {
    type: 'polyatomic-non-metal',
    name: t('Carbon'),
    atomicWeight: '12.011',
    symbol: 'C',
  },
  {
    type: 'diatomic-nonmetal',
    name: t('Nitrogen'),
    atomicWeight: '14.007',
    symbol: 'N',
  },
  {
    type: 'diatomic-nonmetal',
    name: t('Oxygen'),
    atomicWeight: '15.999',
    symbol: 'O',
  },
  {
    type: 'diatomic-nonmetal',
    name: t('Fluorine'),
    atomicWeight: '18.9984',
    symbol: 'F',
  },
  {
    type: 'noble-gas',
    name: t('Neon'),
    atomicWeight: '20.1797',
    symbol: 'Ne',
  },
  {
    type: 'alkali',
    name: t('Sodium'),
    atomicWeight: '22.9898',
    symbol: 'Na',
  },
  {
    type: 'alkaline',
    name: t('Magnesium'),
    atomicWeight: '24.305',
    symbol: 'Mg',
  },
  {
    type: 'aluminum',
    name: t('Aluminum'),
    atomicWeight: '26.9815',
    symbol: 'Al',
  },
  {
    type: 'metalloid',
    name: t('Silicon'),
    atomicWeight: '28.085',
    symbol: 'Si',
  },
  {
    type: 'polyatomic-non-metal',
    name: t('Phosphorus'),
    atomicWeight: '30.9738',
    symbol: 'P',
  },
  {
    type: 'polyatomic-non-metal',
    name: t('Sulfur'),
    atomicWeight: '32.06',
    symbol: 'S',
  },
  {
    type: 'diatomic-nonmetal',
    name: t('Chlorine'),
    atomicWeight: '35.45',
    symbol: 'Cl',
  },
  {
    type: 'noble-gas',
    name: t('Argon'),
    atomicWeight: '39.95',
    symbol: 'Ar',
  },
  {
    type: 'alkali',
    name: t('Potassium'),
    atomicWeight: '39.0983',
    symbol: 'K',
  },
  {
    type: 'alkaline',
    name: t('Calcium'),
    atomicWeight: '40.078',
    symbol: 'Ca',
  },
  {
    type: 'scandium',
    name: t('Scandium'),
    atomicWeight: '44.9559',
    symbol: 'Sc',
  },
  {
    type: 'titanium',
    name: t('Titanium'),
    atomicWeight: '47.867',
    symbol: 'Ti',
  },
  {
    type: 'vanadium',
    name: t('Vanadium'),
    atomicWeight: '50.9415',
    symbol: 'V',
  },
  {
    type: 'chromium',
    name: t('Chromium'),
    atomicWeight: '51.996',
    symbol: 'Cr',
  },
  {
    type: 'manganese',
    name: t('Manganese'),
    atomicWeight: '54.938',
    symbol: 'Mn',
  },
  {
    type: 'iron',
    name: t('Iron'),
    atomicWeight: '55.845',
    symbol: 'Fe',
  },
  {
    type: 'cobalt',
    name: t('Cobalt'),
    atomicWeight: '58.9332',
    symbol: 'Co',
  },
  {
    type: 'nickel',
    name: t('Nickel'),
    atomicWeight: '58.6934',
    symbol: 'Ni',
  },
  {
    type: 'copper',
    name: t('Copper'),
    atomicWeight: '63.546',
    symbol: 'Cu',
  },
  {
    type: 'zinc',
    name: t('Zinc'),
    atomicWeight: '65.38',
    symbol: 'Zn',
  },
  {
    type: 'gallium',
    name: t('Gallium'),
    atomicWeight: '69.723',
    symbol: 'Ga',
  },
  {
    type: 'metalloid',
    name: t('Germanium'),
    atomicWeight: '72.630',
    symbol: 'Ge',
  },
  {
    type: 'metalloid',
    name: t('Arsenic'),
    atomicWeight: '74.9216',
    symbol: 'As',
  },
  {
    type: 'polyatomic-non-metal',
    name: t('Selenium'),
    atomicWeight: '78.971',
    symbol: 'Se',
  },
  {
    type: 'diatomic-nonmetal',
    name: t('Bromine'),
    atomicWeight: '79.904',
    symbol: 'Br',
  },
  {
    type: 'noble-gas',
    name: t('Krypton'),
    atomicWeight: '83.798',
    symbol: 'Kr',
  },
  {
    type: 'alkali',
    name: t('Rubidium'),
    atomicWeight: '85.4678',
    symbol: 'Rb',
  },
  {
    type: 'alkaline',
    name: t('Strontium'),
    atomicWeight: '87.62',
    symbol: 'Sr',
  },
  {
    type: 'yttrium',
    name: t('Yttrium'),
    atomicWeight: '88.9058',
    symbol: 'Y',
  },
  {
    type: 'zirconium',
    name: t('Zirconium'),
    atomicWeight: '91.224',
    symbol: 'Zr',
  },
  {
    type: 'niobium',
    name: t('Niobium'),
    atomicWeight: '92.9064',
    symbol: 'Nb',
  },
  {
    type: 'molybdenum',
    name: t('Molybdenum'),
    atomicWeight: '95.95',
    symbol: 'Mo',
  },
  {
    type: 'technetium',
    name: t('Technetium'),
    atomicWeight: '98',
    symbol: 'Tc',
  },
  {
    type: 'ruthenium',
    name: t('Ruthenium'),
    atomicWeight: '101.07',
    symbol: 'Ru',
  },
  {
    type: 'rhodium',
    name: t('Rhodium'),
    atomicWeight: '102.9055',
    symbol: 'Rh',
  },
  {
    type: 'palladium',
    name: t('Palladium'),
    atomicWeight: '106.42',
    symbol: 'Pd',
  },
  {
    type: 'silver',
    name: t('Silver'),
    atomicWeight: '107.8682',
    symbol: 'Ag',
  },
  {
    type: 'cadmium',
    name: t('Cadmium'),
    atomicWeight: '112.414',
    symbol: 'Cd',
  },
  {
    type: 'indium',
    name: t('Indium'),
    atomicWeight: '114.818',
    symbol: 'In',
  },
  {
    type: 'tin',
    name: t('Tin'),
    atomicWeight: '118.71',
    symbol: 'Sn',
  },
  {
    type: 'metalloid',
    name: t('Antimony'),
    atomicWeight: '121.760',
    symbol: 'Sb',
  },
  {
    type: 'metalloid',
    name: t('Tellurium'),
    atomicWeight: '127.60',
    symbol: 'Te',
  },
  {
    type: 'diatomic-nonmetal',
    name: t('Iodine'),
    atomicWeight: '126.9045',
    symbol: 'I',
  },
  {
    type: 'noble-gas',
    name: t('Xenon'),
    atomicWeight: '131.293',
    symbol: 'Xe',
  },
  {
    type: 'alkali',
    name: t('Caesium'),
    atomicWeight: '131.9055',
    symbol: 'Cs',
  },
  {
    type: 'alkaline',
    name: t('Barium'),
    atomicWeight: '137.327',
    symbol: 'Ba',
  },
  {
    type: 'lanthanide',
    name: t('Lanthanum'),
    atomicWeight: '137.327',
    symbol: 'La',
  },
  {
    type: 'lanthanide',
    name: t('Cerium'),
    atomicWeight: '140.116',
    symbol: 'Ce',
  },
  {
    type: 'lanthanide',
    name: t('Praseodymium'),
    atomicWeight: '140.9077',
    symbol: 'Pr',
  },
  {
    type: 'lanthanide',
    name: t('Neodymium'),
    atomicWeight: '144.242',
    symbol: 'Nd',
  },
  {
    type: 'lanthanide',
    name: t('Promethium'),
    atomicWeight: '145',
    symbol: 'Pm',
  },
  {
    type: 'lanthanide',
    name: t('Samarium'),
    atomicWeight: '150.36',
    symbol: 'Sm',
  },
  {
    type: 'lanthanide',
    name: t('Europium'),
    atomicWeight: '151.964',
    symbol: 'Eu',
  },
  {
    type: 'lanthanide',
    name: t('Gadolinium'),
    atomicWeight: '157.25',
    symbol: 'Gd',
  },
  {
    type: 'lanthanide',
    name: t('Terbium'),
    atomicWeight: '158.9254',
    symbol: 'Tb',
  },
  {
    type: 'lanthanide',
    name: t('Dysprosium'),
    atomicWeight: '162.50',
    symbol: 'Dy',
  },
  {
    type: 'lanthanide',
    name: t('Holmium'),
    atomicWeight: '164.9303',
    symbol: 'Ho',
  },
  {
    type: 'lanthanide',
    name: t('Erbium'),
    atomicWeight: '167.259',
    symbol: 'Er',
  },
  {
    type: 'lanthanide',
    name: t('Thulium'),
    atomicWeight: '168.9342',
    symbol: 'Tm',
  },
  {
    type: 'lanthanide',
    name: t('Ytterbium'),
    atomicWeight: '173.045',
    symbol: 'Yb',
  },
  {
    type: 'lanthanide',
    name: t('Lutetium'),
    atomicWeight: '174.9668',
    symbol: 'Lu',
  },
  {
    type: 'hafnium',
    name: t('Hafnium'),
    atomicWeight: '178.486',
    symbol: 'Hf',
  },
  {
    type: 'tantalum',
    name: t('Tantalum'),
    atomicWeight: '180.9479',
    symbol: 'Ta',
  },
  {
    type: 'tungsten',
    name: t('Tungsten'),
    atomicWeight: '183.84',
    symbol: 'W',
  },
  {
    type: 'rhenium',
    name: t('Rhenium'),
    atomicWeight: '186.207',
    symbol: 'Re',
  },
  {
    type: 'osmium',
    name: t('Osmium'),
    atomicWeight: '190.23',
    symbol: 'Os',
  },
  {
    type: 'iridium',
    name: t('Iridium'),
    atomicWeight: '192.217',
    symbol: 'Ir',
  },
  {
    type: 'platinum',
    name: t('Platinum'),
    atomicWeight: '195.084',
    symbol: 'Pt',
  },
  {
    type: 'gold',
    name: t('Gold'),
    atomicWeight: '196.9666',
    symbol: 'Au',
  },
  {
    type: 'mercury',
    name: t('Mercury'),
    atomicWeight: '200.592',
    symbol: 'Hg',
  },
  {
    type: 'thallium',
    name: t('Thallium'),
    atomicWeight: '204.38',
    symbol: 'Tl',
  },
  {
    type: 'lead',
    name: t('Lead'),
    atomicWeight: '207.2',
    symbol: 'Pb',
  },
  {
    type: 'bismuth',
    name: t('Bismuth'),
    atomicWeight: '208.9804',
    symbol: 'Bi',
  },
  {
    type: 'polonium',
    name: t('Polonium'),
    atomicWeight: '209',
    symbol: 'Po',
  },
  {
    type: 'metalloid',
    name: t('Astatine'),
    atomicWeight: '210',
    symbol: 'At',
  },
  {
    type: 'noble-gas',
    name: t('Radon'),
    atomicWeight: '222',
    symbol: 'Rn',
  },
  {
    type: 'alkali',
    name: t('Francium'),
    atomicWeight: '223',
    symbol: 'Fr',
  },
  {
    type: 'alkaline',
    name: t('Radium'),
    atomicWeight: '226',
    symbol: 'Ra',
  },
  {
    type: 'actinide',
    name: t('Actinium'),
    atomicWeight: '227',
    symbol: 'Ac',
  },
  {
    type: 'actinide',
    name: t('Thorium'),
    atomicWeight: '232.0377',
    symbol: 'Th',
  },
  {
    type: 'actinide',
    name: t('Protactinium'),
    atomicWeight: '231.0359',
    symbol: 'Pa',
  },
  {
    type: 'actinide',
    name: t('Uranium'),
    atomicWeight: '238.0289',
    symbol: 'U',
  },
  {
    type: 'actinide',
    name: t('Neptunium'),
    atomicWeight: '237',
    symbol: 'Np',
  },
  {
    type: 'actinide',
    name: t('Plutonium'),
    atomicWeight: '244',
    symbol: 'Pu',
  },
  {
    type: 'actinide',
    name: t('Americium'),
    atomicWeight: '243',
    symbol: 'Am',
  },
  {
    type: 'actinide',
    name: t('Curium'),
    atomicWeight: '247',
    symbol: 'Cm',
  },
  {
    type: 'actinide',
    name: t('Berkelium'),
    atomicWeight: '247',
    symbol: 'Bk',
  },
  {
    type: 'actinide',
    name: t('Californium'),
    atomicWeight: '251',
    symbol: 'Cf',
  },
  {
    type: 'actinide',
    name: t('Einsteinium'),
    atomicWeight: '252',
    symbol: 'Es',
  },
  {
    type: 'actinide',
    name: t('Fermium'),
    atomicWeight: '257',
    symbol: 'Fm',
  },
  {
    type: 'actinide',
    name: t('Mendelevium'),
    atomicWeight: '258',
    symbol: 'Md',
  },
  {
    type: 'actinide',
    name: t('Nobelium'),
    atomicWeight: '259',
    symbol: 'No',
  },
  {
    type: 'actinide',
    name: t('Lawrencium'),
    atomicWeight: '266',
    symbol: 'Lr',
  },
  {
    type: 'rutherfordium',
    name: t('Rutherfordium'),
    atomicWeight: '267',
    symbol: 'Rf',
  },
  {
    type: 'dubnium',
    name: t('Dubnium'),
    atomicWeight: '268',
    symbol: 'Db',
  },
  {
    type: 'seaborgium',
    name: t('Seaborgium'),
    atomicWeight: '269',
    symbol: 'Up',
  },
  {
    type: 'bohrium',
    name: t('Bohrium'),
    atomicWeight: '270',
    symbol: 'Bh',
  },
  {
    type: 'hassium',
    name: t('Hassium'),
    atomicWeight: '277',
    symbol: 'Hs',
  },
  {
    type: 'unknown',
    name: t('Meitnerium'),
    atomicWeight: '278',
    symbol: 'Mt',
  },
  {
    type: 'unknown',
    name: t('Darmstadtium'),
    atomicWeight: '281',
    symbol: 'Ds',
  },
  {
    type: 'unknown',
    name: t('Roentgenium'),
    atomicWeight: '282',
    symbol: 'Rg',
  },
  {
    type: 'unknown',
    name: t('Copernicium'),
    atomicWeight: '285',
    symbol: 'Cn',
  },
  {
    type: 'unknown',
    name: t('Nihonium'),
    atomicWeight: '286',
    symbol: 'Nh',
  },
  {
    type: 'unknown',
    name: t('Flerovium'),
    atomicWeight: '289',
    symbol: 'Fl',
  },
  {
    type: 'unknown',
    name: t('Moscovium'),
    atomicWeight: '290',
    symbol: 'Mc',
  },
  {
    type: 'unknown',
    name: t('Livermorium'),
    atomicWeight: '293',
    symbol: 'Lv',
  },
  {
    type: 'unknown',
    name: t('Tennessine'),
    atomicWeight: '294',
    symbol: 'Ts',
  },
  {
    type: 'unknown',
    name: t('Oganesson'),
    atomicWeight: '294',
    symbol: 'Og',
  },
];
</script>
<style scoped>
.periodic-table {
  padding: 30px;
  z-index: 0;
  color: rgba(0, 0, 0, 0.7);
  display: grid;
  grid-column-gap: 3px;
  grid-row-gap: 3px;
}

.element {
  display: flex;
  position: relative;
  justify-content: center;
  align-items: center;
  padding: 1rem 0 1.375rem 0;
  border-radius: 3px;
  background: rgb(155, 227, 255);
  z-index: 1;
  transition: all 150ms ease-out;
  user-select: none;
  box-shadow: inset 0 0 0 1px rgba(0, 0, 0, 0.03);
}

.element:hover {
  z-index: 2;
}

.atomic-number {
  position: absolute;
  top: 4px;
  left: 4px;
  opacity: 0.8;
  font-weight: 600;
  font-size: max(calc(5px + 0.4vw), 10px);
  line-height: 1;
  margin: 0;
}

.atomic-weight {
  position: absolute;
  opacity: 0.8;
  font-size: max(calc(5px + 0.4vw), 10px);
  font-weight: 600;
  left: 50%;
  transform: translateX(-50%);
  bottom: 4px;
  line-height: 1;
}

.element abbr {
  line-height: 1;
  font-size: 2.5vw;
  font-weight: 500;
  border-bottom: none !important;
  cursor: inherit !important;
  text-decoration: none !important;
}

.element:hover {
  transform: scale3d(1.1, 1.1, 1.1);
  box-shadow:
    1px 2px 4px 0 rgba(0, 0, 0, 0.15),
    1px 4px 12px 0 rgba(0, 0, 0, 0.2);
}

abbr {
  position: relative;
}

.periodic-table .noble-gas {
  background: rgb(255, 247, 128);
}

.periodic-table .diatomic-nonmetal {
  background: rgb(255, 204, 156);
}

.periodic-table .alkali {
  background: hsl(315, 74%, 88%);
}

.periodic-table .hydrogen {
  background: rgb(190, 216, 224);
}

.periodic-table .alkaline {
  background: hsl(120, 42%, 86%);
}

.periodic-table .unknown {
  background: hsl(0, 0%, 78%);
}

.periodic-table .lanthanide {
  background: hsl(183, 61%, 85%);
}

.periodic-table .actinide {
  background: hsl(82, 82%, 74%);
}

.periodic-table .metalloid {
  background: hsl(142, 81%, 77%);
}

.periodic-table .polyatomic-non-metal {
  background: hsl(0, 100%, 81%);
}

@media all and (max-width: 999px) {
  .periodic-table {
    grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  }

  .element abbr {
    font-size: 0;
  }

  .element abbr:after {
    content: attr(data-title);
    font-size: 1rem;
  }
}

@media all and (min-width: 1000px) {
  .periodic-table:before {
    display: block;
    content: '';
    grid-row: 8 / 10;
    grid-column: 1 / 4;
  }

  .periodic-table li:nth-child(2) {
    grid-column-start: 18;
  }

  .periodic-table li:nth-child(5),
  .periodic-table li:nth-child(13) {
    grid-column-start: 13;
  }

  .periodic-table li:nth-child(58),
  .periodic-table li:nth-child(90) {
    grid-column-start: 4;
  }

  .element:nth-child(n + 58):nth-last-child(n + 48) {
    grid-row-start: 8;
  }

  .element:nth-child(n + 90):nth-last-child(n + 16) {
    grid-row-start: 9;
  }

  abbr:before {
    content: attr(data-title);
    position: absolute;
    display: flex;
    left: 50%;
    bottom: -2.5rem;
    width: auto;
    white-space: nowrap;
    background-color: rgb(0, 0, 0);
    color: #fff;
    border-radius: 3px;
    box-shadow: 1px 1px 5px 0 rgba(0, 0, 0, 0.4);
    font-size: 14px;
    padding: 3px 5px;
    transform: translate3d(-50%, -0.5rem, 0);
    opacity: 0;
    pointer-events: none;
    transition: transform 200ms ease-out;
  }

  .periodic-table li:hover abbr::before {
    transform: translate3d(-50%, 0, 0);
    opacity: 0.7;
  }
}
</style>
<i18n>
#  @intlify/vue-i18n/no-missing-keys-in-other-locales
{
  "fr": {
    "Hydrogen": "Hydrogène",
    "Helium": "Hélium",
    "Lithium": "Lithium",
    "Beryllium": "Béryllium",
    "Boron": "Bore",
    "Carbon": "Carbone",
    "Nitrogen": "Azote",
    "Oxygen": "Oxygène",
    "Fluorine": "Fluor",
    "Neon": "Néon",
    "Sodium": "Sodium",
    "Magnesium": "Magnésium",
    "Aluminum": "Aluminium",
    "Silicon": "Silicium",
    "Phosphorus": "Phosphore",
    "Sulfur": "Soufre",
    "Chlorine": "Chlore",
    "Argon": "Argon",
    "Potassium": "Potassium",
    "Calcium": "Calcium",
    "Scandium": "Scandium",
    "Titanium": "Titane",
    "Vanadium": "Vanadium",
    "Chromium": "Chrome",
    "Manganese": "Manganèse",
    "Iron": "Fer",
    "Cobalt": "Cobalt",
    "Nickel": "Nickel",
    "Copper": "Cuivre",
    "Zinc": "Zinc",
    "Gallium": "Gallium",
    "Germanium": "Germanium",
    "Arsenic": "Arsenic",
    "Selenium": "Sélénium",
    "Bromine": "Brome",
    "Krypton": "Krypton",
    "Rubidium": "Rubidium",
    "Strontium": "Strontium",
    "Yttrium": "Yttrium",
    "Zirconium": "Zirconium",
    "Niobium": "Niobium",
    "Molybdenum": "Molybdène",
    "Technetium": "Technétium",
    "Ruthenium": "Ruthénium",
    "Rhodium": "Rhodium",
    "Palladium": "Palladium",
    "Silver": "Argent",
    "Cadmium": "Cadmium",
    "Indium": "Indium",
    "Tin": "Étain",
    "Antimony": "Antimoine",
    "Tellurium": "Tellure",
    "Iodine": "Iode",
    "Xenon": "Xénon",
    "Caesium": "Césium",
    "Barium": "Baryum",
    "Lanthanum": "Lanthane",
    "Cerium": "Cérium",
    "Praseodymium": "Praséodyme",
    "Neodymium": "Néodyme",
    "Promethium": "Prométhium",
    "Samarium": "Samarium",
    "Europium": "Europium",
    "Gadolinium": "Gadolinium",
    "Terbium": "Terbium",
    "Dysprosium": "Dysprosium",
    "Holmium": "Holmium",
    "Erbium": "Erbium",
    "Thulium": "Thulium",
    "Ytterbium": "Ytterbium",
    "Lutetium": "Lutétium",
    "Hafnium": "Hafnium",
    "Tantalum": "Tantale",
    "Tungsten": "Tungstène",
    "Rhenium": "Rhénium",
    "Osmium": "Osmium",
    "Iridium": "Iridium",
    "Platinum": "Platine",
    "Gold": "Or",
    "Mercury": "Mercure",
    "Thallium": "Thallium",
    "Lead": "Plomb",
    "Bismuth": "Bismuth",
    "Polonium": "Polonium",
    "Astatine": "Astate",
    "Radon": "Radon",
    "Francium": "Francium",
    "Radium": "Radium",
    "Actinium": "Actinium",
    "Thorium": "Thorium",
    "Protactinium": "Protactinium",
    "Uranium": "Uranium",
    "Neptunium": "Neptunium",
    "Plutonium": "Plutonium",
    "Americium": "Américium",
    "Curium": "Curium",
    "Berkelium": "Berkélium",
    "Californium": "Californium",
    "Einsteinium": "Einsteinium",
    "Fermium": "Fermium",
    "Mendelevium": "Mendélévium",
    "Nobelium": "Nobélium",
    "Lawrencium": "Lawrencium",
    "Rutherfordium": "Rutherfordium",
    "Dubnium": "Dubnium",
    "Seaborgium": "Seaborgium",
    "Bohrium": "Bohrium",
    "Hassium": "Hassium",
    "Meitnerium": "Meitnerium",
    "Darmstadtium": "Darmstadtium",
    "Roentgenium": "Roentgenium",
    "Copernicium": "Copernicium",
    "Nihonium": "Nihonium",
    "Flerovium": "Flérove",
    "Moscovium": "Moscovium",
    "Livermorium": "Livermorium",
    "Tennessine": "Tennessine",
    "Oganesson": "Oganesson"
  }
}
</i18n>
