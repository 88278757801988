<template>
  <SBadge>
    <SIcon :name="icon" size="20" />
    {{ label }}
  </SBadge>
</template>

<script setup lang="ts">
import {computed} from 'vue';
import SBadge from '../design-system/SBadge.vue';
import SIcon from '../design-system/SIcon.vue';
import {useI18n} from 'vue-i18n';

const {component} = defineProps<{
  component: string;
}>();

const {t} = useI18n({
  useScope: 'local',
  inheritLocale: true,
});

const acceptedTranslationKeys = [
  'AiAllPurposeQuestion',
  'AiCriteriaQuestion',
  'AiMultipleChoiceQuestion',
  'AiSelectableContextCriteriaQuestion',
  'AiSelectableContextQuestion',
  'AiSpecificationQuestion',
  'SingleAiTask',
  'SinglePartCalculation',
  'MultipleChoice',
];

const taskComponentIconMap: Record<string, string> = {
  default: 'clipboard-edit-outline',
  AiAllPurposeQuestion: 'robot',
  AiCriteriaQuestion: 'robot',
  AiMultipleChoiceQuestion: 'robot',
  AiSelectableContextCriteriaQuestion: 'robot',
  AiSelectableContextQuestion: 'robot',
  AiSpecificationQuestion: 'robot',
  SingleAiTask: 'robot',
  SinglePartCalculation: 'calculator',
  MultipleChoice: 'format-list-checks',
};

const icon = computed(() => taskComponentIconMap[component] || taskComponentIconMap.default);
const label = computed(() =>
  acceptedTranslationKeys.includes(component) ? t(component) : t('defaultComponent')
);
</script>

<i18n>
  {
    "en": {
      "defaultComponent": "Default Component",
      "AiAllPurposeQuestion": "All Purpose Question",
      "AiCriteriaQuestion": "Criteria Question",
      "AiMultipleChoiceQuestion": "Multiple Choice Question",
      "AiSelectableContextCriteriaQuestion": "Selectable Context Criteria Question",
      "AiSelectableContextQuestion": "Selectable Context Question",
      "AiSpecificationQuestion": "Specification Question",
      "SingleAiTask": "AI Task",
      "SinglePartCalculation": "Calculation",
      "MultipleChoice": "Multiple Choice"
    },
    "fr": {
      "defaultComponent": "Composant par défaut",
      "AiAllPurposeQuestion": "Question polyvalente",
      "AiCriteriaQuestion": "Question de critère",
      "AiMultipleChoiceQuestion": "Question à choix multiples",
      "AiSelectableContextCriteriaQuestion": "Question de critère de contexte sélectionnable",
      "AiSelectableContextQuestion": "Question de contexte sélectionnable",
      "AiSpecificationQuestion": "Question de spécification",
      "SingleAiTask": "Tâche IA",
      "SinglePartCalculation": "Calcul",
      "MultipleChoice": "Choix multiples"
    }
  }
  </i18n>
