import {
  determineGradeState,
  DetermineTaskStateProps,
  TaskResponseState,
  TaskResponseStateFields,
  useTaskStateHelpers,
} from './task-response-state-utils';
import {Grade} from '../../../types/entities/base-grade';

const gradePillColorClasses: Record<string, string> = {
  ungraded: 'border-gray-300/30 bg-gray-100 text-gray-700',
  incorrect: 'border-red-300/40 bg-red-100 text-red-700',
  partial: 'border-blue-100 bg-blue-50 text-blue-700',
  correct: 'border-green-300/50 bg-green-100 text-green-700',
  adjusted: 'border-yellow-300/50 bg-yellow-100 text-yellow-700',
};

export function determineGradePillClass(grade: Grade, hasAdjustments?: boolean) {
  const gradeState = hasAdjustments ? 'adjusted' : determineGradeState(grade) || 'ungraded';
  return gradePillColorClasses[gradeState];
}

export function useTaskResponseStateColors(props: DetermineTaskStateProps) {
  const {determineTaskResponseState, determineTaskState} = useTaskStateHelpers(props);

  const feedbackBackgroundClasses: Record<TaskResponseState, string> = {
    draft: 'from-gray-100/80 via-gray-100/40 to-gray-50/0',
    incomplete: 'from-gray-100/80 via-gray-100/40 to-gray-50/0',
    ungraded: 'from-gray-200 via-gray-100/50 to-gray-50/0',
    incorrect: 'from-red-200 via-red-100/50 to-red-50/0',
    partial: 'from-blue-100/70 via-blue-100/20 to-blue-50/0',
    correct: 'from-green-200 via-green-100/50 to-green-50/0',
  };

  function determineFeedbackAreaBackgroundClass(
    response?: TaskResponseStateFields | null,
    grade?: Grade | null
  ): string {
    const feedbackState = determineTaskResponseState(response, grade);
    return feedbackBackgroundClasses[feedbackState] || '';
  }

  const feedbackBorderClasses: Record<TaskResponseState, string> = {
    draft: 'bg-gray-150',
    incomplete: 'bg-gray-150',
    ungraded: 'bg-gray-400',
    incorrect: 'bg-red-400',
    partial: 'bg-blue-400',
    correct: 'bg-green-400',
  };

  function determineFeedbackBorderBackgroundClass(
    response?: TaskResponseStateFields | null,
    grade?: Grade | null
  ): string {
    const feedbackState = determineTaskResponseState(response, grade);
    return feedbackBorderClasses[feedbackState] || '';
  }

  const taskResponseBarClasses: Record<TaskResponseState, string> = {
    draft: 'bg-gray-400 border-gray-500/50',
    incomplete: 'bg-gray-200 border-gray-300',
    ungraded: 'bg-gray-900 border-black',
    incorrect: 'bg-red-500 border-red-600',
    partial: 'bg-blue-500 border-blue-600',
    correct: 'bg-green-500 border-green-600',
  };

  function determineTaskResponseBarClasses(response: TaskResponseStateFields): string[] {
    const taskResponseStatus = determineTaskResponseState(response);
    const taskResponseDotColorClass = taskResponseBarClasses[taskResponseStatus] || '';
    return [taskResponseDotColorClass];
  }

  const taskNavItemBackgroundClasses: Record<TaskResponseState, string> = {
    draft: 'border-gray-500/50',
    ungraded: 'border-gray-700',
    incorrect: 'border-red-500',
    partial: 'border-blue-500',
    correct: 'border-green-500',
    incomplete: 'border-gray-200',
  };

  function determineTaskNavItemClasses(
    taskId: number,
    activeTaskId: number,
    assignmentTasksLength: number
  ): string[] {
    const baseClass =
      'inline-block relative shadow-card text-base py-2 cursor-pointer rounded flex items-center justify-center border-t-[0.375rem]';
    const taskGradeIndicatorState = determineTaskState(taskId);
    const activeClass =
      'font-bold bg-gradient-to-b from-blue-600 via-blue-500 to-blue-400 text-white shadow-[0_0_0_2px_#ABDAF1]';
    const inactiveClass =
      'font-medium bg-white hover:bg-blue-50 hover:text-blue-500 text-blue-600 transition-all ease-out duration-150';
    const backgroundClass = taskNavItemBackgroundClasses[taskGradeIndicatorState] || '';

    return [
      baseClass,
      taskId === activeTaskId ? activeClass : inactiveClass,
      assignmentTasksLength > 12 ? 'px-2.5' : 'px-4',
      backgroundClass,
    ];
  }

  return {
    determineFeedbackAreaBackgroundClass,
    determineFeedbackBorderBackgroundClass,
    determineTaskResponseBarClasses,
    determineTaskNavItemClasses,
  };
}
