<template>
  <Head :title="t('page.title')"></Head>
  <div class="bg-gradient-to-b from-blue-50 via-transparent to-transparent">
    <div class="p-4 lg:p-8 max-w-xl mx-auto flex flex-col gap-5">
      <form
        class="flex flex-col shadow-card rounded-lg"
        @submit.prevent="form.post(route('lti-platform-users.store'))"
      >
        <h1
          id="form-title"
          class="bg-gradient-to-t from-white/70 to-white/30 flex items-center justify-center p-6 rounded-t-lg border border-white/50 border-b-gray-100"
        >
          <s-logo type="image" class="logo h-16 lg:h-[4.5rem] w-auto" />
          <span class="sr-only">
            {{ t('page.title') }}
          </span>
        </h1>
        <div
          class="px-5 lg:px-8 py-5 lg:py-8 bg-gradient-to-t from-gray-50 to-white rounded-b-lg flex flex-col gap-5"
        >
          <p>
            {{
              t('page.message', {
                ltiPlatformUserEmail: ltiPlatformUserEmail,
                stembleUserEmail: stembleUserEmail,
              })
            }}
          </p>

          <s-btn
            type="submit"
            color="primary"
            :processing="form.processing"
            class="mt-1"
            icon="link-plus"
            icon-end
          >
            {{ t('page.linkAccountButton') }}
          </s-btn>
        </div>
      </form>

      <div class="flex gap-x-3 gap-y-2 flex-wrap">
        <s-link :href="route('support')" class="link-subtle flex items-center gap-1.5">
          <s-icon name="chat-question" class="opacity-70" />
          {{ t('links.help') }}
        </s-link>
      </div>
    </div>
  </div>
</template>
<script setup lang="ts">
import {Head, useForm} from '@inertiajs/vue3';
import {useI18n} from 'vue-i18n';
import {route} from 'ziggy-js';
import SLink from '../../components/SLink.vue';
import UnauthorizedLayout from '../../layouts/UnauthorizedLayout.vue';
import SIcon from '../../design-system/SIcon.vue';
import loginTranslations from '../Login/login.json';
import SLogo from '../../design-system/SLogo.vue';
import SBtn from '../../design-system/SBtn.vue';

const {t} = useI18n({
  inheritLocale: true,
  messages: loginTranslations,
});

const form = useForm<{}>({});

defineOptions({layout: UnauthorizedLayout});

defineProps<{
  stembleUserEmail: string;
  ltiPlatformUserEmail: string;
}>();
</script>
<i18n>
{
  "en": {
    "page": {
      "title": "Link LMS User to Stemble User",
      "message": "Are you sure you want to link your {ltiPlatformUserEmail} LMS account to your {stembleUserEmail} account?",
      "linkAccountButton": "Link Account"
    }
  },
  "fr": {
    "page": {
      "title": "Lier l'utilisateur LMS à l'utilisateur Stemble",
      "message": "Voulez-vous vraiment lier votre compte LMS {ltiPlatformUserEmail} à votre compte {stembleUserEmail}?",
      "linkAccountButton": "Lier le compte"
    }
  }
}
</i18n>
