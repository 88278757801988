<template>
  <div class="px-4 md:px-5 lg:px-6 pb-3 md:pb-5">
    <slot />
  </div>
</template>

<script lang="ts">
import {defineComponent} from 'vue';

export default defineComponent({
  name: 'SModalContent',
});
</script>
