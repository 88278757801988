<template>
  <s-details detailsClass="bg-gradient-to-r from-yellow-50/70 to-yellow-50/40">
    <template #summary>
      <h3 class="heading md flex items-center gap-1 text-yellow-600">
        <s-icon name="alert-circle" size="22" class="opacity-70" />
        <i18n-t keypath="heading" :plural="totalConflicts">
          <template #count>
            <span class="font-black">
              {{ totalConflicts }}
            </span>
          </template>
        </i18n-t>
      </h3>
    </template>
    <div class="flex flex-col gap-4">
      <diff-student-conflict
        v-for="(changes, email) in conflictingChanges"
        :email="email"
        :changes="changes"
      />
    </div>
  </s-details>
</template>
<script setup lang="ts">
import DiffStudentConflict from './DiffStudentConflict.vue';
import RosterChangeDto = App.DTOs.Roster.RosterChangeDto;
import {useI18n} from 'vue-i18n';
import SIcon from '../../../design-system/SIcon.vue';
import SDetails from '../../../design-system/SDetails.vue';

const props = defineProps<{
  conflictingChanges: Record<string, RosterChangeDto[]>;
}>();

const totalConflicts = Object.values(props.conflictingChanges).reduce(
  (acc, changes) => acc + changes.length,
  0
);
const {t} = useI18n({
  useScope: 'local',
  inheritLocale: true,
});
</script>
<i18n>
{
  "en": {
    "heading": "{count} Added | {count} Conflicting Changes"
  },
  "fr": {
    "heading": "{count} Ajouté | {count} Modifications conflictuelles"
  }
}
</i18n>
