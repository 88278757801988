<template>
  <s-btn @click="isOpenRef = !isOpenRef">
    {{ isOpenRef ? 'Close Drawer' : 'Open Drawer' }}
  </s-btn>

  <s-drawer
    :open="isOpenRef"
    @close="isOpenRef = false"
    title="Example Drawer"
    tagline="This is a tagline for the drawer."
  >
    The drawer provides no padding or margin, so you can add your own styles to the content.
  </s-drawer>
</template>
<script setup lang="ts">
import SDrawer from '../../../../design-system/SDrawer.vue';
import {ref} from 'vue';
import SBtn from '../../../../design-system/SBtn.vue';

const isOpenRef = ref<boolean>(false);
</script>
<script lang="ts">
import {ExampleOptions} from '../../examples';

export const options: ExampleOptions = {
  fullscreen: true,
};
</script>
