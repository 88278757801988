<template>
  <li
    class="group text-sm leading-tight flex flex-1 items-center divide-x hover:text-blue-800 transition even:bg-gray-300/15 divide-gray-200 text-gray-700"
  >
    <s-link
      :href="route('task-response-attachments.show', [attachment.id])"
      target="_blank"
      external
      class="link-subtle hover:bg-blue-200/15 transition flex-1 min-w-0 w-full flex justify-between gap-1 truncate px-3 py-2"
    >
      <span class="flex-1 min-w-0 truncate">{{ attachment.filename }}</span>
      <span class="transition flex items-center gap-1">
        <s-icon name="download" class="opacity-70" size="16" />
        {{ t('actions.download') }}
      </span>
    </s-link>
    <button
      v-if="previewSupported"
      @click.prevent="$emit('show', attachment)"
      class="hover:bg-blue-200/15 transition link-subtle flex items-center justify-center gap-1 px-3 py-2 min-w-[5rem]"
    >
      <s-icon name="eye" class="opacity-70" size="16" />
      {{ t('view') }}
    </button>
    <button
      v-if="deletable"
      @click.prevent="deleteAttachment()"
      class="hover:bg-blue-200/15 transition link-subtle flex items-center gap-1 px-3 py-2"
      :disabled="deleting"
      :class="{
        'cursor-progress': deleting,
      }"
    >
      <s-icon v-if="deleting" name="loading" class="opacity-70 animate-spin" size="16" />
      <s-icon v-else name="trash-can" class="opacity-70" size="16" />
      {{ t('actions.delete') }}
    </button>
  </li>
</template>
<script setup lang="ts">
import {route} from 'ziggy-js';
import SIcon from '../design-system/SIcon.vue';
import SLink from './SLink.vue';
import {inlineViewableImageMimeTypes, inlineViewableImageTypeExtensions} from '../util/images';
import {router} from '@inertiajs/vue3';
import {computed, ref} from 'vue';
import {useI18n} from 'vue-i18n';

const props = defineProps<{
  attachment: {
    id?: number;
    filename: string;
    mimeType?: string | null;
  };
  deletable: boolean;
  onlyOnDelete: string[];
}>();

defineEmits(['show']);

const {t} = useI18n({
  inheritLocale: true,
  useScope: 'local',
});

const deleting = ref(false);

const supportsPreviewMimeTypes = [...inlineViewableImageMimeTypes, 'application/pdf'];
const supportsPreviewExtensions = [...inlineViewableImageTypeExtensions, 'pdf'];

const previewSupported = computed(() => {
  if (props.attachment.mimeType) {
    return supportsPreviewMimeTypes.includes(props.attachment.mimeType);
  }

  const ext = props.attachment.filename.split('.').pop();

  return ext && supportsPreviewExtensions.includes(ext.toLowerCase());
});

const deleteAttachment = () => {
  router.delete(route('task-response-attachments.destroy', [props.attachment.id]), {
    only: props.onlyOnDelete,
    preserveState: true,
    onStart() {
      deleting.value = true;
    },
    onFinish() {
      deleting.value = false;
    },
  });
};
</script>
<i18n>
{
  "en": {
    "download": "Download",
    "view": "View",
    "delete": "Delete"
  },
  "fr": {
    "download": "Télécharger",
    "view": "Voir",
    "delete": "Supprimer"
  }
}
</i18n>
