<template>
  <v-chart class="chart" :option="option" autoresize />
</template>
<script setup lang="ts">
import {computed} from 'vue';
import type {ComposeOption} from 'echarts';
import {use} from 'echarts/core';
import {CanvasRenderer} from 'echarts/renderers';
import VChart from 'vue-echarts';
import {
  LegendComponent,
  LegendComponentOption,
  TitleComponent,
  TitleComponentOption,
  TooltipComponent,
  TooltipComponentOption,
} from 'echarts/components';
import {SunburstChart, SunburstSeriesOption} from 'echarts/charts';

use([TitleComponent, SunburstChart, CanvasRenderer, TooltipComponent]);

type ECOption = ComposeOption<SunburstSeriesOption | TitleComponentOption | TooltipComponentOption>;

const props = defineProps<{
  institutionTokenUsage: Array<{
    name: string; // Institution Abbreviation
    institutionFullName: string;
    children: Array<{
      name: string; // Course code
      courseFullName: string;
      value: number; // Total tokens
    }>;
  }>;
}>();

const colorPalette = [
  '#6200ea',
  '#ff0266',
  '#00c853',
  '#03dac6',
  '#ff5722',
  '#ffeb3b',
  '#9c27b0',
  '#1b9e77',
  '#03a9f4',
  '#d95f02',
  '#7570b3',
  '#3700b3',
  '#e7298a',
  '#66a61e',
  '#e6ab02',
];

function generateColorShades(baseColor: string, numVariants: number) {
  const color = parseInt(baseColor.replace(/^#/, ''), 16);
  const r = (color >> 16) & 0xff;
  const g = (color >> 8) & 0xff;
  const b = color & 0xff;

  return Array.from({length: numVariants}, (_, index) => {
    const shadeFactor = ((index + 1) / (numVariants + 1)) * 0.7;
    const newR = Math.min(
      255,
      Math.max(0, Math.round(r + (255 - r) * shadeFactor + (Math.random() * 20 - 10)))
    );
    const newG = Math.min(
      255,
      Math.max(0, Math.round(g + (255 - g) * shadeFactor + (Math.random() * 20 - 10)))
    );
    const newB = Math.min(
      255,
      Math.max(0, Math.round(b + (255 - b) * shadeFactor + (Math.random() * 20 - 10)))
    );

    return `#${((1 << 24) + (newR << 16) + (newG << 8) + newB).toString(16).slice(1)}`;
  });
}

const dynamicColoredData = computed(() => {
  return props.institutionTokenUsage.map((institution, instIndex) => {
    const baseColor = colorPalette[instIndex % colorPalette.length];

    const childrenShades = generateColorShades(baseColor, institution.children.length);

    const children = institution.children.map((course, index) => ({
      ...course,
      itemStyle: {
        color: childrenShades[index],
      },
    }));

    return {
      ...institution,
      itemStyle: {
        color: baseColor,
      },
      children,
    };
  });
});

const option = computed<ECOption>(() => ({
  title: {
    text: 'Token Usage Distribution by Institution and Course',
    left: 'center',
    top: 'top',
  },
  backgroundColor: '#f5f5f5',
  tooltip: {
    trigger: 'item',
    formatter: function (params: any) {
      const {name, data} = params;
      if (data.courseFullName) {
        return `Course: ${data.courseFullName} (${name})<br>Total Tokens: ${data.value}`;
      } else if (data.institutionFullName) {
        return `Institution: ${data.institutionFullName} (${name})<br>Total Tokens: ${data.value}`;
      }
      return `${name}<br>Total Tokens: ${data.value}`;
    },
  },
  series: [
    {
      type: 'sunburst',
      radius: [0, '90%'],
      data: dynamicColoredData.value,
      emphasis: {
        focus: 'ancestor',
      },
      levels: [
        {}, // 1st Level: Empty Donut Hole
        {
          // 2nd Level: Institution
          r0: '15%',
          r: '45%',
          itemStyle: {
            borderWidth: 3,
          },
          label: {
            rotate: 'tangential',
            fontSize: 12,
          },
        },
        {
          // 3rd Level: Course
          r0: '45%',
          r: '75%',
          label: {
            position: 'outside',
            padding: 3,
          },
        },
      ],
    },
  ],
}));
</script>

<style scoped>
.chart {
  width: 100%;
  aspect-ratio: 1;
  max-height: 100vh;
}
</style>
