<template>
  <s-tooltip color="white" size="md">
    <div
      class="inline-block border border-gray-200 bg-white rounded-lg h-fit w-fit mx-[0.0625em] px-[0.375em] text-[0.875em] font-mono cursor-pointer shadow-soft"
    >
      {{ variable.name }}
    </div>
    <template #tooltip>
      <p class="font-mono text-lg leading-tight font-medium mb-1">
        {{ variable.name }}
      </p>
      <p class="mb-1.5 text-base leading-tight">
        {{ t('description') }}
      </p>
      <p class="italic flex-1 text-sm font-medium leading-tight">
        <strong>{{ t('generated-range') }}</strong>
        {{
          generateNumberVariableExamples(
            variable.min,
            variable.max,
            variable.step,
            variable.shift,
            variable.includeZero
          ).join(', ')
        }}
      </p>
    </template>
  </s-tooltip>
</template>
<script setup lang="ts">
import NumberVariableDto = App.DTOs.Tasks.Variables.NumberVariableDto;
import {generateNumberVariableExamples} from '../util/number-variables';
import STooltip from '../design-system/STooltip.vue';
import {useI18n} from 'vue-i18n';

defineProps<{
  variable: NumberVariableDto;
}>();

const {t} = useI18n({
  useScope: 'local',
  inheritLocale: true,
});
</script>
<i18n>
  {
    "en": {
      "description": "This is a variable that will be randomized to a different value for each student.",
      "generated-range": "Generated Range"
    },
    "fr": {
      "description": "Cette variable sera aléatoirement modifiée pour chaque étudiant.",
      "generated-range": "Plage générée"
    }
  }
</i18n>
