import {computed, Ref} from 'vue';
import {addDays, addWeeks, addHours, addMinutes} from 'date-fns';

export function useRelativeDateRef(
  reference: Ref<Date | string>,
  relativeDaysValue: Ref<number>,
  relativeHoursValue: Ref<number>,
  relativeMinutesValue: Ref<number>
) {
  return computed(() => {
    let date: Date;
    date = addDays(new Date(reference.value), relativeDaysValue.value);
    if (relativeHoursValue.value) {
      date = addHours(date, relativeHoursValue.value);
    }
    if (relativeMinutesValue.value) {
      date = addMinutes(date, relativeMinutesValue.value);
    }
    return date.toISOString();
  });
}
