<template>
  <form
    @submit.prevent="onSubmit"
    class="flex-1 flex max-w-full rounded-full focus-within:ring-2 focus-within:ring-blue-200"
    :role="role || 'form'"
    :aria-label="placeholder"
  >
    <s-input
      :id
      :placeholder
      class="flex-1 min-w-0 !rounded-l-full !rounded-r-none !pl-4 focus:!ring-blue-200"
      :type="type || 'text'"
      size="sm"
      v-model="model"
    />
    <s-btn type="submit" class="!px-6 !rounded-l-none" :icon :processing>
      {{ submitLabel }}
    </s-btn>
  </form>
</template>

<script setup lang="ts">
import SInput from './SInput.vue';
import SBtn from './SBtn.vue';

const model = defineModel<string | number | null>();

defineProps<{
  placeholder: string;
  submitLabel: string;
  icon?: string;
  type?: string;
  role?: string;
  id?: string;
  processing?: boolean;
}>();

const emit = defineEmits(['submit']);

const onSubmit = () => {
  emit('submit', model.value || '');
};
</script>
