<template>
  <!--
  This is an Inactive User Interface Component
  and does not need to meed contrast requirements.

  The user can't interact with it and it only
  represents a lack of actionability.
  -->
  <div
    class="bg-gray-200/30 shadow-inset w-full flex justify-center items-center py-3 border border-gray-250 rounded-lg overflow-hidden"
  >
    <p class="text-base font-medium text-gray-400 italic">
      <slot>
        {{ t('default') }}
      </slot>
    </p>
  </div>
</template>
<script setup lang="ts">
import {useI18n} from 'vue-i18n';

const {t} = useI18n({
  useScope: 'local',
  inheritLocale: true,
});
</script>
<i18n>
{
  "en": {
    "default": "Nothing to display."
  },
  "fr": {
    "default": "Rien à afficher."
  }
}
</i18n>
