<template>
  <SBadge>
    <SIcon :name="taskTypeIconMap[type] || taskTypeIconMap.default" size="20" />
    {{ t(type) || t('defaultType') }}
  </SBadge>
</template>

<script setup lang="ts">
import SBadge from '../design-system/SBadge.vue';
import SIcon from '../design-system/SIcon.vue';
import {useI18n} from 'vue-i18n';

defineProps<{
  type: string;
}>();

const {t} = useI18n({
  useScope: 'local',
  inheritLocale: true,
});

const taskTypeIconMap: Record<string, string> = {
  default: 'clipboard-edit-outline',
  CalculationQuestion: 'calculator',
  WatchVideoTask: 'video-outline',
  BooleanTask: 'check-circle-outline',
  DynamicQuestion: 'cog',
  ManuallyGradedTask: 'text',
  MultipleChoiceQuestion: 'format-list-checks',
  DataDrivenTask: 'cog',
};
</script>
<i18n>
{
  "en": {
    "WatchVideoTask": "Watch Video",
    "CalculationQuestion": "Calculation Question",
    "BooleanTask": "Boolean Task",
    "DynamicQuestion": "Dynamic Question",
    "ManuallyGradedTask": "Manually Graded Task",
    "MultipleChoiceQuestion": "Multiple Choice Question",
    "DataDrivenTask": "Multi-Part Task",
    "defaultType": "Generic"
  },
  "fr": {
    "WatchVideoTask": "Regarder la vidéo",
    "CalculationQuestion": "Question de calcul",
    "BooleanTask": "Tâche booléenne",
    "DynamicQuestion": "Question dynamique",
    "ManuallyGradedTask": "Tâche évaluée manuellement",
    "MultipleChoiceQuestion": "Question à choix multiples",
    "DataDrivenTask": "Tâche en plusieurs parties",
    "defaultType": "Générique"
  }
}
</i18n>
