<template>
  <s-textarea-field
    id="label"
    label="Label"
    description="The label that will be presented above the options."
    :model-value="label ?? ''"
    :error="errors?.label"
    @update:modelValue="emit('update:label', $event)"
    class="mb-4"
  />
  <s-form-list
    title="Options"
    addLabel="Add Option"
    :addItem="addOption"
    :items="clonedOptions"
    :removeItem="(item, index) => removeOption(index)"
    :sortable="true"
    placeholder="No Options Added"
    @update:items="
      clonedOptions = $event;
      syncOptions();
    "
  >
    <template #item="{item, index}">
      <div class="p-5">
        <div class="flex-1 w-full flex flex-wrap gap-x-4 gap-y-3 justify-start mb-3">
          <div class="flex-1 max-w-[10rem] grow">
            <s-input-field
              :id="`options.${index}.key`"
              label="Key"
              :model-value="clonedOptions[index].key"
              :error="errors?.options?.[index]?.key"
              @update:modelValue="
                clonedOptions[index].key = $event;
                clonedFeedbacks[index].name = $event;
                syncOptions();
              "
            />
          </div>
          <div class="flex-1 grow-[2]">
            <s-input-field
              :id="`options.${index}.label`"
              label="Label"
              :model-value="clonedOptions[index].label"
              :error="errors?.options?.[index]?.label"
              @update:modelValue="
                clonedOptions[index].label = $event;
                syncOptions();
              "
            />
          </div>
        </div>
        <s-textarea-field
          :id="`feedback.${item.key}.text`"
          label="Feedback"
          :model-value="getFeedbackByKey(item.key)"
          @update:model-value="updateFeedback(item.key, $event)"
          :error="errors?.feedbacks?.[index]"
        />
      </div>
    </template>
  </s-form-list>
  <s-select-field
    id="multipleChoiceUiType"
    v-model="uiType"
    label="Display Type"
    :error="errors?.uiType"
  >
    <option value="radio">Radio Buttons</option>
    <option value="dropdown">Dropdown</option>
  </s-select-field>
  <s-input-field
    id="multipleChoicePointsAvailable"
    v-model.number="points"
    type="number"
    label="Points Available (optional)"
    tooltip="When this is not set, the question will be worth 1 point."
    :error="errors?.points"
  />
  <s-select-field
    v-model="correctAnswer"
    label="Correct Option"
    id="multipleChoiceCorrectOption"
    :error="errors?.correctAnswer"
  >
    <option v-for="option in clonedOptions" :value="option.key">{{ option.label }}</option>
  </s-select-field>
</template>

<script setup lang="ts">
import SInputField from '../../../../design-system/SInputField.vue';
import SFormList from '../../../../design-system/SFormList.vue';
import OptionDto = App.DTOs.Tasks.OptionDto;
import {ref} from 'vue';
import {useVModels} from '@vueuse/core';
import STextareaField from '../../../../design-system/STextareaField.vue';
import TaskResponseFeedbackDto = App.DTOs.Tasks.TaskResponseFeedbackDto;
import SSelectField from '../../../../design-system/SSelectField.vue';
import MultipleChoiceUiType = App.Enums.Tasks.Ui.MultipleChoiceUiType;

const props = defineProps<{
  label: string | null;
  options: OptionDto[] | null;
  uiType: MultipleChoiceUiType;
  correctAnswer: string | number | null;
  points: number | null;
  feedbacks: TaskResponseFeedbackDto[] | null;
  errors?: Record<string, any>;
}>();

const emit = defineEmits([
  'update:label',
  'update:options',
  'update:feedbacks',
  'update:correctAnswer',
  'update:points',
  'update:uiType',
]);
const {correctAnswer, points, uiType} = useVModels(props, emit);

const clonedOptions = ref<OptionDto[]>(JSON.parse(JSON.stringify(props.options ?? [])));
const clonedFeedbacks = ref<TaskResponseFeedbackDto[]>(
  JSON.parse(JSON.stringify(props.feedbacks ?? []))
);

const syncOptions = () => {
  emit('update:options', clonedOptions.value);
  emit('update:feedbacks', clonedFeedbacks.value);
};

const addOption = () => {
  clonedOptions.value.push({key: '', label: ''});
  syncOptions();
};

const removeOption = (optionIndex: number) => {
  let removedOption: OptionDto | null = null;
  if (optionIndex !== -1) {
    [removedOption] = clonedOptions.value.splice(optionIndex, 1);
  }

  const hasAnotherEntryWithSameKey = !!clonedOptions.value.find(({key}) => removedOption?.key);

  if (!hasAnotherEntryWithSameKey) {
    const feedbackIndex = clonedFeedbacks.value.findIndex(({name}) => name === removedOption?.key);
    if (feedbackIndex !== -1) {
      clonedFeedbacks.value.splice(feedbackIndex, 1);
    }
  }

  syncOptions();
};

const getFeedbackByKey = (key: string): string | null => {
  return clonedFeedbacks.value.find(({name}) => name === key)?.content ?? null;
};

const updateFeedback = (key: string, value: string) => {
  const index = clonedFeedbacks.value.findIndex(({name}) => name === key);

  // New feedback
  if (index === -1) {
    clonedFeedbacks.value.push({
      id: null,
      taskId: null,
      name: key,
      content: value,
      shortContent: value,
    });
    syncOptions();
    return;
  }

  if (!value) {
    clonedFeedbacks.value.splice(index, 1);
    syncOptions();
    return;
  }

  clonedFeedbacks.value[index].content = value;
  clonedFeedbacks.value[index].shortContent = value;
  syncOptions();
};
</script>
