<template>
  <Head title="Admin - Ai Analytics - Institution Search"></Head>
  <div class="page-block">
    <section class="mt-4">
      <h2 class="heading lg mb-2">Institutions</h2>

      <!-- Institution Search/Select Section -->
      <div class="card padded mb-4">
        <s-search-section
          route-name="admin.ai-analytics.institutions.index"
          label="Search by abbreviation or name"
        />

        <section class="stack vertical gap-3">
          <s-link
            v-for="institution in institutions?.data"
            :href="route('admin.ai-analytics.institutions.show', institution.id)"
            class="card padded stack horizontal align-between valign-center gap-3"
          >
            <div class="stack vertical gap-2 w-full">
              <div>
                <div class="text-lg">
                  <strong>{{ institution.abbreviation }}:</strong>
                  {{ institution.name }}
                </div>
              </div>
            </div>

            <div class="stack horizontal gap-4">
              <s-icon name="chevron-right" />
            </div>
          </s-link>
          <s-paginator :paginator="institutions" />
        </section>
      </div>
    </section>
  </div>
</template>

<script setup lang="ts">
import {route} from 'ziggy-js';
import {Head} from '@inertiajs/vue3';
import {useBreadcrumbs} from '../../../../composables/useBreadcrumbs';
import SPaginator from '../../../../design-system/SPaginator.vue';
import SSearchSection from '../../../../design-system/SSearchSection.vue';
import {LaravelPaginator} from '../../../../types/laravel-paginator';
import {Institution} from '../../../../types/entities/institution';
import SLink from '../../../../components/SLink.vue';
import SIcon from '../../../../design-system/SIcon.vue';

defineProps<{
  institutions: LaravelPaginator<Institution>;
}>();

useBreadcrumbs([{label: 'Ai Analytics', href: route('admin.ai-analytics.index')}]);
</script>

<style scoped></style>
