<template>
  <s-help-button :title="t('chart.title')">
    <div class="prose">
      <p>
        {{ t('chart.intro') }}
      </p>
      <p>
        {{ t('chart.simpleTask') }}
      </p>
      <p>
        {{ t('chart.difficultTask') }}
      </p>
    </div>
  </s-help-button>
  <v-chart class="chart" :option="option" autoresize />
</template>

<script setup lang="ts">
import type {ComposeOption} from 'echarts';
import {use} from 'echarts/core';
import {CanvasRenderer} from 'echarts/renderers';
import {BarChart, BarSeriesOption} from 'echarts/charts';
import {
  GridComponent,
  GridComponentOption,
  LegendComponent,
  LegendComponentOption,
  TitleComponent,
  TitleComponentOption,
  TooltipComponent,
  TooltipComponentOption,
} from 'echarts/components';
import VChart from 'vue-echarts';
import {computed} from 'vue';
import SHelpButton from '../../../design-system/SHelpButton.vue';
import {GradeDistribution} from '../../../types/GradeDistribution';
import {useI18n} from 'vue-i18n';

type ECOption = ComposeOption<
  | BarSeriesOption
  | TitleComponentOption
  | TooltipComponentOption
  | LegendComponentOption
  | GridComponentOption
>;

const {t} = useI18n();

use([CanvasRenderer, BarChart, TitleComponent, TooltipComponent, LegendComponent, GridComponent]);

const props = defineProps<{
  data: {
    name: string;
    gradeDistributionPerTask: GradeDistribution[];
  }[];
}>();

const maxTasks = computed(() =>
  props.data.reduce((acc, item) => Math.max(acc, item.gradeDistributionPerTask?.length ?? 0), 0)
);

const taskLabels = computed(() => {
  let labels = [];
  for (let i = 0; i < maxTasks.value; i++) {
    labels.push(`${t('task')} ${i + 1}`);
  }
  return labels;
});

const series = computed<BarSeriesOption[]>(() => {
  const keys = [
    {key: 'n_20_responses', label: '0% - 20%'},
    {key: 'n_40_responses', label: '20% - 40%'},
    {key: 'n_60_responses', label: '40% - 60%'},
    {key: 'n_80_responses', label: '60% - 80%'},
    {key: 'n_100_responses', label: '80% - 100%'},
  ] as const;

  return keys.map(({key, label}, i) => ({
    name: label,
    type: 'bar',
    stack: 'total',
    emphasis: {
      focus: 'series',
    },
    data: props.data[0]?.gradeDistributionPerTask?.map((row) => row[key]),
  }));
});

const option = computed<ECOption>(() => ({
  tooltip: {
    position: 'left',
  },
  legend: {
    show: true,
    bottom: 0,
  },
  title: {
    text: t('gradeDistributionPerTask'),
    left: '50%',
    textAlign: 'center',
  },
  grid: {
    top: '10%',
    left: '10%',
    bottom: '10%',
    right: '10%',
  },
  xAxis: {
    type: 'value',
    position: 'top',
    nameLocation: 'middle',
    name: t('averageCountOfGradesPerStudentPerTask'),
    nameGap: 10,
  },
  yAxis: {
    type: 'category',
    data: taskLabels.value,
    nameRotate: 0,
    inverse: true,
    splitArea: {
      show: true,
    },
    axisTick: {
      alignWithLabel: true,
    },
  },
  series: series.value,
}));
</script>

<style scoped>
.chart {
  width: 100%;
  aspect-ratio: 16 / 9;
  max-height: 100vh;
}
</style>
<i18n>
{
  "en": {
    "chart": {
      "title": "Grade Distribution per Task",
      "intro": "This chart shows the number of responses that students submit on average, broken down by grade range. This can be used as a measure of difficulty.",
      "simpleTask": "For a simple task, one would expect the total count of responses to be fairly low and the distribution to skew towards higher grade ranges.",
      "difficultTask": "For difficult questions, the average number of responses can be quite high. One would expect a distribution skewing towards the middle or lower grade ranges as students submit more answers that receive only partial credit."
    },
    "task": "Task",
    "averageCountOfGradesPerStudentPerTask": "Average Count of Grades per Student per Task",
    "gradeDistributionPerTask": "Grade Distribution per Task"
  },
  "fr": {
    "task": "Tâche",
    "chart": {
      "title": "Distribution des Notes par Tâche",
      "intro": "Ce graphique montre le nombre de réponses que les étudiants soumettent en moyenne, réparties par plage de notes. Cela peut être utilisé comme une mesure de la difficulté.",
      "simpleTask": "Pour une tâche simple, on s'attend à ce que le nombre total de réponses soit relativement bas et que la répartition penche vers des plages de notes plus élevées.",
      "difficultTask": "Pour des questions difficiles, le nombre moyen de réponses peut être assez élevé. On s'attend à ce qu'une distribution penche vers les plages de notes moyennes ou basses, car les étudiants soumettent plus de réponses qui reçoivent des points partiels."
    },
    "averageCountOfGradesPerStudentPerTask": "Nombre Moyen de Notes par Étudiant par Tâche",
    "gradeDistributionPerTask": "Distribution des Notes par Tâche"
  }
}
</i18n>
