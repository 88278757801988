<template>
  <s-input-field id="example">
    <template #tooltip>Do you know the muffin man?</template>
    <template #prefix>
      <s-icon name="pencil" />
      Edit
    </template>
    <template #label>
      This is a
      <em class="uppercase">custom</em>
      label
    </template>
    <template #suffix>
      <s-icon name="clock" />
      Time
    </template>
  </s-input-field>
</template>
<script setup lang="ts">
import SInputField from '../../../../design-system/SInputField.vue';
import SIcon from '../../../../design-system/SIcon.vue';

defineProps<{
  label?: string;
  description?: string;
  size?: string;
  error?: string;
}>();
</script>
<script lang="ts">
import {ExampleOptions} from '../../examples';

export const options: ExampleOptions = {
  controls: [
    {
      type: 'input',
      prop: 'label',
      label: 'Label',
      default: 'Salary',
    },
    {
      type: 'input',
      prop: 'error',
      label: 'Error',
      default: null,
    },
    {
      type: 'input',
      prop: 'prefix',
      label: 'Prefix',
      default: '$',
    },
    {
      type: 'input',
      prop: 'suffix',
      label: 'suffix',
      default: '/ hour',
    },
    {
      type: 'select',
      prop: 'size',
      label: 'Size',
      options: ['custom', 'xs', 'sm', 'md', 'lg'],
      default: null,
    },
  ],
};
</script>
