<template>
  <div class="text-center">
    <p class="heading lg bold text-blue-800">{{ props.status }}</p>
    <h1 class="mt-4 heading xxl bold">{{ t(`title.${status}`) }}</h1>
    <p class="mt-6 text-base leading-7 text-gray-600 whitespace-pre-wrap">
      <i18n-t :keypath="`description.${status}`">
        <template v-slot:supportEmail>
          <s-link href="mailto:support@stemble.com" class="link">
            {{ t('supportEmail') }}
          </s-link>
        </template>
      </i18n-t>
    </p>
    <div class="mt-10 flex items-center justify-center">
      <s-btn @click="back">
        {{ $t('page.back') }}
      </s-btn>
    </div>
  </div>
</template>
<script setup lang="ts">
import SLink from '../components/SLink.vue';
import SBtn from '../design-system/SBtn.vue';
import {useI18n} from 'vue-i18n';

const props = defineProps<{status: number}>();

const {t} = useI18n({
  useScope: 'local',
  inheritLocale: true,
});

const back = () => {
  window.history.back();
};
</script>
<i18n>
{
  "en": {
    "title": {
      "503": "Service Unavailable",
      "500": "Server Error",
      "404": "Page Not Found",
      "403": "Forbidden"
    },
    "description": {
      "503": "Sorry, we are doing some maintenance. Please check back soon.",
      "500": "Whoops... Something went wrong on our servers.\nPlease contact {supportEmail} if the issue persists.",
      "404": "Sorry, the page you are looking for could not be found.",
      "403": "Sorry, you are forbidden from accessing this page."
    },
    "supportEmail": "support{'@'}stemble.com"
  },
  "fr": {
    "title": {
      "503": "Service non disponible",
      "500": "Erreur du serveur",
      "404": "Page non trouvée",
      "403": "Interdit"
    },
    "description": {
      "503": "Désolé, nous effectuons une maintenance. Veuillez revenir plus tard.",
      "500": "Oups... Quelque chose s'est mal passé sur nos serveurs.\nVeuillez contacter {supportEmail} si le problème persiste.",
      "404": "Désolé, la page que vous recherchez est introuvable.",
      "403": "Désolé, vous êtes interdit d'accéder à cette page."

    },
    "supportEmail": "support{'@'}stemble.com"
  }
}
</i18n>
