<template>
  <details
    v-if="timer"
    class="overflow-hidden rounded-lg shadow-card-soft bg-gradient-to-tl from-white via-gray-50 to-gray-150"
  >
    <summary
      class="group select-none cursor-pointer list-none relative overflow-hidden border rounded-lg shadow-card-soft flex flex-col p-0"
      :class="{
        'bg-gradient-to-t border-red-200 from-red-200 to-red-100 text-red-700':
          timerComplete || timer.hasFinished,
        'bg-gradient-to-tl border-gray-150 text-gray-700 from-gray-50 to-white':
          !timerComplete && !timer.hasFinished,
      }"
    >
      <div class="flex w-full">
        <p
          class="flex items-center text-xl leading-none font-medium tracking-wide px-3 lg:px-4 py-2 lg:py-2.5 border-r border-gray-150"
          :class="{
            'bg-white opacity-40': timerComplete || timer.hasFinished,
            'text-blue-600': !timerComplete && !timer.hasFinished,
          }"
        >
          {{ displayValue }}
        </p>
        <div class="flex flex-col flex-1">
          <div
            class="flex items-center justify-between w-full px-3 lg:px-4 pt-2.5 lg:pt-3 pb-2 lg:pb-2.5 bg-gradient-to-r to-transparent transition"
            :class="{
              'from-white/50 text-red-600 group-hover:text-red-500':
                timerComplete || timer.hasFinished,
              'from-gray-50 text-gray-600 group-hover:text-blue-600':
                !timerComplete && !timer.hasFinished,
            }"
          >
            <h2
              class="flex-1 w-full flex items-center gap-1 text-sm leading-tight font-bold tracking-wide uppercase"
            >
              <s-icon
                :name="timerComplete || timer.hasFinished ? 'timer-off-outline' : 'timer-outline'"
                size="18"
              />
              {{ t('heading') }}
            </h2>
            <s-icon name="chevron-down" size="18" />
          </div>
          <progress
            class="h-2.5 w-full border-t [&::-webkit-progress-bar]:transition-all [&::-webkit-progress-bar]:ease-out [&::-webkit-progress-bar]:duration-200"
            :class="{
              'border-gray-200 bg-blue-50 shadow-inset [&::-webkit-progress-bar]:bg-blue-50 [&::-webkit-progress-value]:bg-blue-400 [&::-moz-progress-bar]:bg-blue-400':
                !timerComplete && !timer.hasFinished,
              'border-red-200 bg-red-100 [&::-webkit-progress-bar]:bg-red-100 [&::-webkit-progress-value]:bg-red-400 [&::-moz-progress-bar]:bg-red-400':
                timerComplete || timer.hasFinished,
            }"
            max="100"
            :value="progressValue"
          >
            {{ progressValue }}{{ t('%') }}
          </progress>
        </div>
      </div>
    </summary>
    <div class="px-3 lg:px-4 py-2.5 lg:py-3">
      <div class="w-full flex flex-wrap gap-x-3 gap-y-1">
        <s-label-value :label="t('started')" size="sm" class="flex-1">
          <span class="flex flex-col">
            {{ monthDaySometimesYear(timer.startedAt) }}
            <div class="text-sm font-bold">
              {{ timeOfDay(timer.startedAt) }}
            </div>
          </span>
        </s-label-value>
        <s-label-value
          :label="timerComplete || timer.hasFinished ? t('finished') : t('finishes')"
          size="sm"
          class="flex-1"
        >
          <span
            class="flex flex-col"
            :class="{
              'text-red-700 font-medium': timerComplete || timer.hasFinished,
            }"
          >
            {{ monthDaySometimesYear(timer.finishesAt) }}
            <div class="text-sm font-bold">
              {{ timeOfDay(timer.finishesAt) }}
            </div>
          </span>
        </s-label-value>
        <s-label-value :label="t('totalTime')" size="sm" class="flex-1">
          {{ t('time.xMinutes', timer.timelimitInMinutes || 1) }}
        </s-label-value>
      </div>
    </div>
  </details>
</template>

<script setup lang="ts">
import {defineProps} from 'vue';
import {monthDaySometimesYear, timeOfDay} from '../../../format/dates';
import UserAssignmentTimerDto = App.DTOs.UserAssignmentTimerDto;
import {useTimer} from '../../../composables/useTimer';
import SIcon from '../../../design-system/SIcon.vue';
import SLabelValue from '../../../design-system/SLabelValue.vue';
import {useI18n} from 'vue-i18n';

const props = defineProps<{
  timer: UserAssignmentTimerDto | null;
}>();

const {displayValue, progressValue, timerComplete} = useTimer({
  startedAt: props.timer?.startedAt,
  finishesAt: props.timer?.finishesAt,
});

const {t} = useI18n({
  useScope: 'local',
  inheritLocale: true,
});
</script>
<i18n>
{
  "en": {
    "heading": "Assignment Timer",
    "started": "Started",
    "finishes": "Finishes",
    "finished": "Finished",
    "totalTime": "Total Time"
  },
  "fr": {
    "heading": "Minuteur du devoir",
    "started": "Commencé",
    "finishes": "Se termine",
    "finished": "Terminé",
    "totalTime": "Temps total"
  }
}
</i18n>
