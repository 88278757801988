<template>
  <Head :title="`${user.firstName} ${user.lastName} (${user.email}) - ${course.code}`"></Head>
  <div class="flex flex-col align-center justify-center w-full px-5 py-4 md:max-w-3xl md:mx-auto">
    <s-page-header :heading="`${user.firstName} ${user.lastName}`" />

    <div class="flex justify-start gap-6 mt-4 mb-5">
      <s-label-value :label="t('studentId')">
        <template v-if="!isEditing">
          <s-btn-link v-if="!isEditing" @click="editMode" icon="pencil" :iconEnd="true">
            {{ user.studentId }}
          </s-btn-link>
        </template>
        <template v-else>
          <form @submit.prevent="submit">
            <input
              v-model="form.studentId"
              id="studentId"
              class="border-blue-500"
              style="
                width: 75px;
                border: none;
                border-bottom: 1px solid;
                background-color: transparent;
                outline: none;
                padding: 2px;
              "
            />
            <button
              class="cursor-pointer group text-lg min-w-0 min-h-0 overflow-hidden truncate gap-1 items-center text-blue-600 hover:text-blue-500 active:text-blue-600 transition-all ease-out duration-100"
              type="submit"
            >
              <s-icon name="check" size="16" />
            </button>
            <button
              class="cursor-pointer group text-lg min-w-0 min-h-0 overflow-hidden truncate gap-1 items-center text-blue-600 hover:text-blue-500 active:text-blue-600 transition-all ease-out duration-100"
              @click="cancelEdit"
            >
              <s-icon name="close" size="16" />
            </button>
          </form>
        </template>
        <div v-if="form.errors.studentId" class="text-xs flex items-center gap-1 text-red-900">
          <s-icon name="alert" class="opacity-70" width="14" height="14" />
          <p>{{ form.errors.studentId }}</p>
        </div>
      </s-label-value>

      <s-label-value>
        <template #label>
          {{ t('studentEmail') }}
          <s-email-status-icon
            :verified="user.hasVerifiedEmail"
            :sent="!!userInvitationCreatedAt"
          />
        </template>
        <s-btn-link v-if="isSupported" @click.stop="copy(user.email)" icon="content-copy">
          <span class="flex-1 min-w-0 relative">
            <span
              :class="copied ? 'opacity-0' : 'opacity-100'"
              class="flex-1 min-w-0 truncate"
              :aria-hidden="copied"
            >
              {{ user.email }}
            </span>
            <span
              :class="copied ? 'opacity-50' : 'opacity-0'"
              class="absolute left-0 italic transition-all ease-out duration-100"
              :aria-hidden="!copied"
            >
              {{ t('copyEmail.success') }}
            </span>
          </span>
        </s-btn-link>
        <span v-else class="flex-1 min-w-0 truncate">
          {{ user.email }}
        </span>
      </s-label-value>

      <s-label-value :label="t('lastActivity')">
        <!-- If the user can view the activity log, provide a link. Otherwise, just show the recent activity. -->
        <template v-if="can.viewActivityLog">
          <s-btn-link
            :href="
              route('courses.participants.activities.index', {
                course: course.id,
                user: user.id,
              })
            "
            class="capitalize"
            icon="open-in-new"
          >
            {{
              lastActivityAt
                ? formatRelative(new Date(lastActivityAt), new Date())
                : t('noRecentActivity')
            }}
          </s-btn-link>
        </template>
        <template v-else>
          <span class="">
            {{ monthDayYear(lastActivityAt, {shortMonth: true}) || t('noRecentActivity') }}
          </span>
        </template>
      </s-label-value>

      <s-label-value :label="t('section', sections.length)">
        <span class="comma-separated">
          <span v-for="section in sections" :key="section.id">
            {{ section.name }}
          </span>
        </span>
      </s-label-value>
    </div>

    <s-card v-if="paymentRequest" :heading="t('headings.payments')" class="mb-5">
      <div class="flex flex-wrap gap-3 justify-between">
        <s-badge
          class=""
          :color="paymentRequest.paidOn ? 'green' : paymentRequest.exemptedOn ? 'blue' : 'red'"
        >
          <template v-if="paymentRequest && paymentRequest?.paidOn">
            <s-icon name="cash-check" class="opacity-70" size="20" />
            {{ t('status.paid') }}
          </template>
          <template v-else-if="paymentRequest && paymentRequest?.exemptedOn">
            <s-icon name="cash-off" class="opacity-70" size="20" />
            {{ t('status.exempt') }}
          </template>
          <template v-else-if="paymentRequest && !paymentRequest?.paidOn">
            <s-icon name="cash-remove" class="opacity-70" size="20" />
            {{ t('status.unpaid') }}
          </template>
        </s-badge>
      </div>
    </s-card>

    <s-card v-if="participantIsStudent" class="mb-5" :heading="t('headings.assignments')">
      <div
        v-if="!assignments?.length"
        class="mt-1.5 px-4 py-5 text-center italic text-lg opacity-50"
      >
        {{ t('empty.assignments') }}
      </div>

      <ul class="mt-1">
        <li
          v-for="assignment in assignments"
          :key="assignment.id"
          class="flex flex-row items-center gap-3 py-2.5 border-b-2 last:border-none border-gray-100 text-gray-700 text-lg"
        >
          <a
            class="p-2 -m-2 flex gap-2 items-center justify-between cursor-pointer transition hover:text-blue-500"
            :href="`/courses/${course.id}/assignments/${assignment.id}/marking/${user.id}/tasks/first`"
            target="_blank"
          >
            <s-icon name="open-in-new" size="16" />
            <span class="sr-only">
              {{
                t('actions.openInNewTab', {
                  assignmentName: assignment.name,
                })
              }}
            </span>
          </a>
          <a
            class="w-full group flex gap-2 items-center justify-between cursor-pointer transition hover:text-blue-500"
            :href="`/courses/${course.id}/assignments/${assignment.id}/marking/${user.id}/tasks/first`"
          >
            <span
              class="underline underline-offset-2 decoration-transparent group-hover:decoration-blue-200/70 group-active:decoration-blue-200 decoration-2 group-hover:decoration-blue-200 transition-all ease-out duration-150"
            >
              {{ assignment.name }}
            </span>

            <span
              class="px-2 py-1 flex-shrink-0 rounded-full border shadow text-sm font-bold leading-none"
              :class="gradePillColorClassMap[gradeToColor(assignment.grade)]"
            >
              <s-grade
                class="h-fit"
                :grade-display="course.gradeDisplay"
                :grade="assignment.grade"
                tooltip
              ></s-grade>
            </span>
          </a>
          <s-btn
            v-if="assignment.extension && can.grantExtensions"
            :href="
              route('courses.assignment.extension.edit', {
                course: course.id,
                assignment: assignment.id,
                extension: assignment.extension.id,
                _query: {
                  // Encode Current url
                  redirect: encodeURIComponent(getCurrentPath()),
                },
              })
            "
            icon="calendar-edit"
            size="sm"
            color="blue-light"
            :title="t('actions.editExtension')"
          >
            {{ t('actions.editExtension') }}
          </s-btn>
          <s-btn
            v-else-if="can.grantExtensions"
            :href="
              route('courses.assignment.extension.create', {
                course: course.id,
                assignment: assignment.id,
                _query: {
                  redirect: getCurrentPath(),
                  user: props.user.id,
                },
              })
            "
            icon="calendar-plus"
            size="sm"
            color="white"
            :title="t('actions.grantExtension')"
          >
            {{ t('actions.grantExtension') }}
          </s-btn>
        </li>
      </ul>
    </s-card>

    <s-card :heading="t('courseRole', courseRoles.length)">
      <template #actions>
        <s-btn
          v-if="canUpdateParticipant"
          :href="route('courses.participants.edit', [course.id, user.id])"
          icon="pencil"
          color="secondary"
        >
          {{ t('actions.edit', 2) }}
        </s-btn>
      </template>
      <div class="flex flex-wrap justify-start gap-4">
        <div
          v-for="courseRole in courseRoles"
          :key="courseRole.id"
          class="bg-gradient-to-tl from-gray-100 to-gray-50 rounded-lg border border-gray-300/30 shadow-soft min-w-[11rem] px-4 py-3"
        >
          <s-label-value :label="courseRole.courseLike.name">
            {{ t('roleNames.' + courseRole.role.name) }}
          </s-label-value>
          <div
            class="flex items-center gap-1 text-green-600 font-medium"
            v-if="courseRole.isActive"
          >
            <s-icon class="opacity-70 flex-none" name="check-circle" size="18" />
            {{ t('active') }}
          </div>
          <div class="flex items-center gap-1 text-red-600 font-medium" v-else>
            <s-icon class="opacity-70 flex-none" name="close-circle" size="18" />
            {{ t('inactive') }}
          </div>
        </div>
      </div>
    </s-card>

    <s-btn
      v-if="canRemoveParticipant"
      color="danger-white"
      icon="close"
      class="group w-fit mt-6"
      @click.prevent="destroyParticipant()"
    >
      {{
        t('actions.remove', {
          firstName: user.firstName,
          lastName: user.lastName,
          courseName: course.name,
        })
      }}
    </s-btn>
  </div>
</template>
<script setup lang="ts">
import {useClipboard} from '@vueuse/core';
import {Course} from '../../types/entities/course';
import SPageHeader from '../../design-system/SPageHeader.vue';
import SLabelValue from '../../design-system/SLabelValue.vue';
import SIcon from '../../design-system/SIcon.vue';
import SBtnLink from '../../design-system/SBtnLink.vue';
import SCard from '../../design-system/SCard.vue';
import SBadge from '../../design-system/SBadge.vue';
import {CourseRole} from '../../types/entities/course-role';
import {Head, router, useForm} from '@inertiajs/vue3';
import {route} from 'ziggy-js';
import {useI18n} from 'vue-i18n';
import {monthDayYear} from '../../format/dates';
import {formatRelative} from 'date-fns';
import SGrade from '../../components/SGrade.vue';
import SBtn from '../../design-system/SBtn.vue';
import StudentCourseAssignmentRowDto = App.DTOs.CourseAssignments.StudentCourseAssignmentRowDto;
import AssignmentGradeDto = App.DTOs.AssignmentGradeDto;
import ShowParticipantDto = App.DTOs.CourseParticipant.ShowParticipantDto;
import SEmailStatusIcon from '../../components/SEmailStatusIcon.vue';
import {PaymentRequest} from '../../types/entities/payment-request';
import {ref} from 'vue';

const props = defineProps<{
  canUpdateParticipant: boolean;
  canRemoveParticipant: boolean;
  course: Course;
  user: ShowParticipantDto;
  participantIsStudent: boolean;
  lastActivityAt: string;
  courseRoles: CourseRole[];
  assignments: StudentCourseAssignmentRowDto[] | null;
  paymentRequest: PaymentRequest | null;
  userInvitationCreatedAt: Date;
  can: {
    grantExtensions: boolean;
    viewActivityLog: boolean;
  };
}>();

const {t} = useI18n({
  inheritLocale: true,
  useScope: 'local',
});

const sections = props.courseRoles
  .map((courseRole) => courseRole.courseLike)
  .filter((courseLike) => courseLike.dtype === 'coursesection');

const {text, copy, copied, isSupported} = useClipboard();

const gradePillColorClassMap = {
  green: 'border-[#C1EF9B] bg-[#D4FFB1] text-[#689A3F]',
  blue: 'border-blue-100 bg-blue-50 text-blue-800',
  red: 'border-red-200 bg-red-100 text-red-800',
  gray: 'border-gray-300 bg-gray-100 text-gray-800',
};

const isEditing = ref<boolean>(false);
const form = useForm({
  studentId: props.user.studentId,
});

function editMode() {
  isEditing.value = true;
}

// Confirm the new student ID and exit edit mode
function confirmEdit() {
  isEditing.value = false;
  // You can also call an API or emit an event here to persist the changes
}

// Cancel editing and revert to the original student ID
function cancelEdit() {
  isEditing.value = false;
}

const gradeToColor = (grade: AssignmentGradeDto | null) => {
  if (!grade) {
    return 'gray';
  }

  const numericGrade = +((grade.exactNumerator / grade.exactDenominator) * 100).toFixed(0);

  if (numericGrade >= 85) {
    return 'green';
  }

  if (numericGrade <= 50) {
    return 'red';
  }

  return 'blue';
};

const destroyParticipant = () => {
  if (
    window.confirm(
      t('actions.confirm-remove', {
        firstName: props.user.firstName,
        lastName: props.user.lastName,
        courseName: props.course.name,
      })
    )
  ) {
    router.delete(route('courses.participants.destroy', [props.course.id, props.user.id]));
  }
};

const submit = () => {
  form.patch(route('courses.students.update', [props.course.id, props.user.id]));
  isEditing.value = false;
};

const getCurrentPath = () => {
  return window.location.pathname;
};
</script>
<style scoped>
.comma-separated > *:not(:last-child)::after {
  content: ', ';
}
</style>
<i18n>
{
  "en": {
    "actions": {
      "edit": "Edit Role | Edit Roles",
      "remove": "Remove {firstName} {lastName} from {courseName}",
      "confirm-remove": "Are you sure you want to remove {firstName} {lastName} from {courseName}?",
      "openInNewTab": "Open {assignmentName} in a new tab",
      "editExtension": "Edit Extension",
      "grantExtension": "Grant Extension"
    },
    "headings": {
      "assignments": "Assessments",
      "payments": "Payment Status"
    },
    "empty": {
      "assignments": "No assessment found"
    },
    "label": {
      "payment": "Payment",
      "fullCourseRole": "Rôle complet"
    },
    "status": {
      "paid": "Paid",
      "unpaid": "Unpaid",
      "exempt": "Exempt"
    },
    "lastActivity": "Last Activity",
    "noRecentActivity": "No recent activity"
  },
  "fr": {
    "actions": {
      "edit": "Modifier le rôle | Modifier les rôles",
      "remove": "Retirer {firstName} {lastName} de {courseName}",
      "confirm-remove": "Êtes-vous sûr de vouloir retirer {firstName} {lastName} de {courseName}?",
      "openInNewTab": "Ouvrir {assignmentName} dans un nouvel onglet",
      "editExtension": "Modifier la prolongation",
      "grantExtension": "Accorder une prologation"
    },
    "headings": {
      "assignments": "Devoirs",
      "payments": "Statut de paiement"
    },
    "empty": {
      "assignments": "Aucun devoir trouvé"
    },
    "label": {
      "payment": "Paiement",
      "fullCourseRole": "Rôle complet"
    },
    "status": {
      "paid": "Payé",
      "unpaid": "Non payé",
      "exempt": "Exempté"
    },
    "lastActivity": "Dernière activité",
    "noRecentActivity": "Aucune activité récente"
  }
}
</i18n>
