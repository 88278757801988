import {inject, provide, Ref, ref, watch} from 'vue';
import {usePage} from '@inertiajs/vue3';

/**
 * Flash data sent by server
 */
export type FlashData = {
  success?: string;
  error?: string;
  warnings?: string[];
};

/**
 * Flash composable
 */
export type Flash = FlashData & {
  dismiss(type: keyof FlashData): void;
  set(type: keyof FlashData, message: string | string[]): void;
};

/**
 * Provide flash composable to the app
 */
export function provideFlash() {
  provide('flash', buildFlash());
}

/**
 * Use flash composable
 */
export function useFlash() {
  return inject<Ref<Flash>>('flash');
}

function buildFlash() {
  const page = usePage<{
    flash: {success: string; error: string; warnings: string[]};
  }>();

  const flash = ref<Flash>({
    dismiss(type: keyof FlashData) {
      flash.value[type] = undefined;
    },
    set(type: keyof FlashData, message: string & string[]) {
      flash.value[type] = message;
    },
  });

  watch(page, (page) => {
    flash.value.success = page.props.flash.success;
    flash.value.error = page.props.flash.error;
    flash.value.warnings = page.props.flash.warnings;
  });

  return flash;
}
