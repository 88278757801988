<template>
  <div class="dashboard">
    <section class="mt-4">
      <h2 class="heading lg mb-2">Feedback Analytics</h2>
      <div class="flex gap-4">
        <!-- Ai Token Usage Chart -->
        <div class="w-1/2 h-full flex">
          <v-chart class="chart flex-1" :option="tokenUsageOption" autoresize />
        </div>

        <!-- Stat Cards -->
        <div class="w-1/4 flex flex-col gap-2">
          <s-stat-card
            class="flex-1 flex flex-col justify-center items-center text-center"
            label="Average Evaluation Word Count"
          >
            <s-help-button title="Standard Deviation">
              <div class="prose">
                <p>
                  The upper and lower bounds around the average word counts represent the standard
                  deviation, which indicates how much variation exists in feedback length (measured
                  in words or sentences). A smaller deviation means feedback lengths are more
                  consistent.
                </p>
              </div>
            </s-help-button>
            {{ feedbackStats.meanEvaluationWordCount.toFixed(2) }}
            <br />
            ± {{ feedbackStats.stdDevEvaluationWordCount.toFixed(2) }}
          </s-stat-card>
          <s-stat-card
            class="flex-1 flex flex-col justify-center items-center text-center"
            label="Average Reference Word Count"
          >
            {{ feedbackStats.meanReferenceWordCount.toFixed(2) }}
            <br />
            ± {{ feedbackStats.stdDevReferenceWordCount.toFixed(2) }}
          </s-stat-card>
        </div>
        <div class="w-1/4 flex flex-col gap-2">
          <s-stat-card
            class="flex-1 flex flex-col justify-center items-center text-center"
            label="Average Number of Sentences in Evaluation"
          >
            {{ feedbackStats.meanEvaluationSentenceCount.toFixed(2) }}
            <br />
            ± {{ feedbackStats.stdDevEvaluationSentenceCount.toFixed(2) }}
          </s-stat-card>
          <s-stat-card
            class="flex-1 flex flex-col justify-center items-center text-center"
            label="Average Number of Sentences in Reference"
          >
            {{ feedbackStats.meanReferenceSentenceCount.toFixed(2) }}
            <br />
            ± {{ feedbackStats.stdDevReferenceSentenceCount.toFixed(2) }}
          </s-stat-card>
        </div>
      </div>
    </section>
  </div>
</template>
<script setup lang="ts">
import {computed} from 'vue';
import {use} from 'echarts/core';
import {BarChart} from 'echarts/charts';
import SHelpButton from '../../../../design-system/SHelpButton.vue';
import VChart from 'vue-echarts';
import {
  DatasetComponent,
  GridComponent,
  TitleComponent,
  TooltipComponent,
  TransformComponent,
} from 'echarts/components';
import {CanvasRenderer} from 'echarts/renderers';
import SStatCard from '../../../../components/SStatCard.vue';
import AiFeedbackStatistics = App.DTOs.EvaluationAnalytics.AiFeedbackStatisticsDto;

use([
  CanvasRenderer,
  BarChart,
  TooltipComponent,
  GridComponent,
  TitleComponent,
  DatasetComponent,
  TransformComponent,
]);

const props = defineProps<{
  feedbackStatistics: AiFeedbackStatistics;
}>();

const feedbackStats = computed(() => props.feedbackStatistics || {});

const tokenUsageOption = computed(() => ({
  title: {
    text: 'Token Usage Comparison',
    left: 'center',
  },
  tooltip: {
    trigger: 'axis',
    axisPointer: {
      type: 'shadow',
    },
  },
  legend: {
    data: ['Output', 'Input', 'Total'],
    top: '10%',
  },
  grid: {
    top: '20%',
    left: '5%',
    right: '4%',
    bottom: '3%',
    containLabel: true,
  },
  xAxis: {
    type: 'category',
    data: ['Evaluation', 'Reference'],
    axisTick: {alignWithLabel: true},
  },
  yAxis: {
    type: 'value',
    name: 'Tokens',
    nameLocation: 'middle',
    nameGap: 40,
    axisLabel: {
      formatter: '{value}',
    },
  },
  series: [
    {
      name: 'Output',
      type: 'bar',
      barGap: 0,
      data: [
        feedbackStats.value.meanEvaluationOutputTokens.toFixed(2),
        feedbackStats.value.meanReferenceOutputTokens.toFixed(2),
      ],
    },
    {
      name: 'Input',
      type: 'bar',
      data: [
        feedbackStats.value.meanEvaluationInputTokens.toFixed(2),
        feedbackStats.value.meanReferenceInputTokens.toFixed(2),
      ],
    },
    {
      name: 'Total',
      type: 'bar',
      data: [
        feedbackStats.value.meanEvaluationTotalTokens.toFixed(2),
        feedbackStats.value.meanReferenceTotalTokens.toFixed(2),
      ],
    },
  ],
}));
</script>

<style scoped>
.dashboard {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.dashboard-layout {
  display: flex;
  flex-direction: row;
  gap: 1rem;
  height: 100%;
}

.chart {
  width: 100%;
  aspect-ratio: 4 / 3;
}
</style>
