<template>
  <SBanner>
    <template #title>
      {{
        isPast(new Date(course.paymentDueDate))
          ? t('trial-has-ended.heading')
          : t('trial-ending-soon.heading')
      }}
    </template>

    <template #body>
      <i18n-t v-if="isPast(new Date(course.paymentDueDate))" keypath="trial-has-ended.description">
        <template #course>
          {{ course.code }}
        </template>
      </i18n-t>
      <i18n-t v-else keypath="trial-ending-soon.description">
        <template #date>
          <span class="underline">{{ monthDaySometimesYear(course.paymentDueDate) }}</span>
        </template>
        <template #course>
          {{ course.code }}
        </template>
      </i18n-t>
    </template>

    <template #actions>
      <s-btn :href="route('billing.index')" class="w-fit h-fit" icon="currency-usd">
        {{ t('action') }}
      </s-btn>
    </template>
  </SBanner>
</template>
<script setup lang="ts">
import {isPast} from 'date-fns';
import {route} from 'ziggy-js';
import {monthDaySometimesYear} from '../format/dates';
import SBtn from '../design-system/SBtn.vue';
import {useI18n} from 'vue-i18n';
import {Course} from '../types/entities/course';
import SBanner from '../design-system/SBanner.vue';

defineProps<{
  course: Course;
}>();

const {t} = useI18n({
  useScope: 'local',
  inheritLocale: true,
});
</script>
<i18n>
{
  "en": {
    "action": "Pay now",
    "trial-ending-soon.heading": "Your free trial is ending soon!",
    "trial-ending-soon.description": "Please pay by {date} to make sure you retain access to {course}.",
    "trial-has-ended.heading": "Your free trial has ended",
    "trial-has-ended.description": "Please pay now to regain access to {course}."
  },
  "fr": {
    "action": "Payer maintenant",
    "trial-ending-soon.heading": "Votre essai gratuit se termine bientôt !",
    "trial-ending-soon.description": "Veuillez payer avant le {date} pour ne pas perdre l'accès à {course}.",
    "trial-has-ended.heading": "Votre essai gratuit est terminé",
    "trial-has-ended.description": "Veuillez payer maintenant pour obtenir de nouveau l'accès à {course}."
  }
}
</i18n>
