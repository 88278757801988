<template>
  <table class="w-full border-collapse mb-1" :class="classes[props.type]?.table">
    <thead v-if="props.rows && props.rows[0]" class="">
      <tr>
        <th
          v-for="(value, key) in props.rows[0]"
          :key="key"
          class="text-left px-2 first:pl-3.5 last:pr-3.5 pb-1.5 capitalize text-sm leading-tighter opacity-70 font-bold"
        >
          {{ key }}
        </th>
      </tr>
    </thead>
    <tbody
      v-if="props.rows && props.rows.length"
      class="rounded-lg shadow-card diff-table bg-white"
    >
      <tr v-for="(row, index) in props.rows" :key="index" class="group">
        <td
          v-for="(value, key) in row"
          :key="key"
          class="text-left font-medium px-2 first:pl-3.5 last:pr-3.5 py-2 border-b"
          :class="classes[props.type].cell"
        >
          {{ value }}
        </td>
      </tr>
    </tbody>
  </table>
</template>
<script setup lang="ts">
import {defineProps} from 'vue';
import RosterChangeType = App.Enums.Roster.RosterChangeType;

const props = defineProps<{
  type: RosterChangeType;
  rows?: Record<string, any>[];
}>();

const classes: Record<RosterChangeType, Record<string, string>> = {
  add: {
    table: 'text-green-800',
    cell: 'bg-green-100/30 group-even:bg-green-200/30 border-green-100',
  },
  remove: {
    table: 'text-red-800',
    cell: 'bg-red-100/30 group-even:bg-red-200/25 border-red-100',
  },
  'change-role': {
    table: 'text-blue-700',
    cell: 'bg-blue-50/30 group-even:bg-blue-100/25 border-blue-50',
  },
  move: {
    table: 'text-blue-700',
    cell: 'bg-blue-50/30 group-even:bg-blue-100/25 border-blue-50',
  },
  none: {
    table: 'text-gray-800',
    cell: 'bg-gray-50/30 group-even:bg-gray-200/20 border-gray-100',
  },
  error: {
    table: 'text-red-800',
    cell: 'bg-red-100/30 group-even:bg-red-200/25 border-red-100',
  },
  ignore: {
    table: 'text-gray-800',
    cell: 'bg-gray-50/30 group-even:bg-gray-200/20 border-gray-100',
  },
};
</script>
<style scoped>
.diff-table {
  tr {
    &:first-child {
      td {
        @apply first:rounded-tl-lg last:rounded-tr-lg;
      }
    }

    &:last-child {
      td {
        @apply first:rounded-bl-lg last:rounded-br-lg border-b-0;
      }
    }
  }
}
</style>
