<template>
  <s-page-block>
    <s-page-header :heading="`Responses for ${task.title}`"></s-page-header>
    <ul v-if="responses.data.length">
      <li v-for="response in responses.data" :key="response.id" class="card padded">
        <s-details>
          <template #summary>
            {{ response.id }}
          </template>
          <pre>
            <code>
              {{ JSON.stringify(response, null, 2) }}
            </code>
        </pre>
        </s-details>
      </li>
    </ul>
    <p>There are no responses for this task.</p>
  </s-page-block>
</template>
<script setup lang="ts">
import SPageBlock from '../../../design-system/SPageBlock.vue';
import SPageHeader from '../../../design-system/SPageHeader.vue';
import {Task} from '../../../types/entities/tasks';
import {LaravelPaginator} from '../../../types/laravel-paginator';
import TaskResponseDto = App.DTOs.Entities.Tasks.TaskResponseDto;
import SDetails from '../../../design-system/SDetails.vue';
import {useBreadcrumbs} from '../../../composables/useBreadcrumbs';
import {route} from 'ziggy-js';

const props = defineProps<{
  task: Task;
  responses: LaravelPaginator<TaskResponseDto>;
}>();

useBreadcrumbs([
  {label: 'Tasks', href: route('admin.tasks.index')},
  {label: props.task.title, href: route('admin.tasks.show', {task: props.task.id})},
]);
</script>
