<template>
  <form v-if="editing" @submit.prevent="submit">
    <div class="flex flex-col sm:flex-row sm:items-center gap-2">
      <s-multi-select-field
        v-if="availableTypes.length"
        id="types"
        :options="availableTypes"
        empty-label="Select types"
        v-model="form.types"
        value-key="id"
        label-key="description"
      >
        <template #selected="{selected}: {selected: TaskTypeDto[]}">
          <span class="flex gap-2 flex-wrap">
            <s-badge size="sm" v-for="option in selected">
              {{ option.description }}
            </s-badge>
          </span>
        </template>
      </s-multi-select-field>
      <s-btn type="button" color="white" class="h-fit" icon="close" @click="editing = false">
        Cancel
      </s-btn>
      <s-btn
        type="submit"
        color="primary"
        class="h-fit"
        icon="floppy"
        :processing="form.processing"
      >
        Save
      </s-btn>
    </div>
  </form>
  <div v-else class="flex gap-1">
    <s-badge v-for="(type, index) in types" :key="index">
      {{ type.description }}
    </s-badge>
    <s-btn size="sm" color="white" @click="editing = true" icon="pencil">Edit</s-btn>
  </div>
</template>
<script setup lang="ts">
import SMultiSelectField from '../../../../design-system/SMultiSelectField.vue';
import {useForm} from '@inertiajs/vue3';
import {onMounted, ref} from 'vue';
import {Task} from '../../../../types/entities/tasks';
import SBadge from '../../../../design-system/SBadge.vue';
import TaskTypeDto = App.DTOs.Tasks.TaskTypeDto;
import SBtn from '../../../../design-system/SBtn.vue';

const props = defineProps<{
  task: Task;
  types: TaskTypeDto[];
}>();

const form = useForm({
  types: props.types.map((type) => type.id),
});

const editing = ref(false);
const availableTypes = ref<TaskTypeDto[]>([]);

onMounted(() => {
  fetch(route('api.v1.task-types.index'))
    .then((response) => response.json())
    .then((data) => (availableTypes.value = data));
});

const submit = () => {
  form.put(route('admin.tasks.types.store', [props.task.id]), {
    preserveScroll: true,
    onSuccess: () => {
      editing.value = false;
    },
    only: ['types'],
  });
};
</script>
