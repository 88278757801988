<template>
  <span class="leading-tight">
    {{ date ? monthDaySometimesYear(date) : t('notAvailable') }}
    <div v-if="date" class="text-sm leading-tight font-medium opacity-80">
      {{ timeOfDay(date) }}
    </div>
  </span>
</template>
<script setup lang="ts">
import {monthDaySometimesYear, timeOfDay} from '../format/dates';
import {useI18n} from 'vue-i18n';

const props = defineProps<{
  date?: string | null;
}>();

const {t} = useI18n({
  inheritLocale: true,
});
</script>
<i18n>
{
  "en": {
    "notAvailable": "N/A"
  },
  "fr": {
    "notAvailable": "N/A"
  }
}
</i18n>
