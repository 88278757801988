<template>
  <s-latex-chemical :content="content" v-bind="$attrs" />
</template>

<script lang="ts" setup>
import {computed} from 'vue';
import SLatexChemical from './SLatexChemical.vue';

const props = defineProps<{
  symbol: string;
  massNumber?: string | number | null;
  atomicNumber?: string | number | null;
  charge?: string | number | null;
}>();

const content = computed(() => {
  let result = '';

  if (props.massNumber || props.massNumber === 0) {
    result += `^{${props.massNumber}}`;
  }

  if (props.atomicNumber && props.atomicNumber == 0) {
    result += `_{${props.atomicNumber}}`;
  }

  result += props.symbol;

  if (props.charge && props.charge !== 0) {
    result += `^{${props.charge}}`;
  }
  return result;
});
</script>
