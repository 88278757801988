import {Plugin} from 'prosemirror-state';

const taskComponentCreationInterceptor = new Plugin({
  appendTransaction: (transactions, oldState, newState) => {
    // Only process if there are actual changes
    if (!transactions.some((tr) => tr.docChanged)) {
      return null;
    }

    // Compare old and new documents to find newly created nodes
    const newDoc = newState.doc;
    let tr = newState.tr;
    let hasChanges = false;

    newDoc.descendants((node, pos) => {
      if (node.type.name.startsWith('task') && !node.attrs.uuid) {
        tr.setNodeMarkup(pos, null, {
          ...node.attrs,
          uuid: crypto.randomUUID(),
        });
        hasChanges = true;
      }
    });

    return hasChanges ? tr : null;
  },
});

export default taskComponentCreationInterceptor;
