import {Schema} from 'prosemirror-model';
import {InputRule, textblockTypeInputRule} from 'prosemirror-inputrules';
import {singleCalculation} from '../../../pages/Task/DataDrivenTask/component-registry/single-calculation';

/**
 * Input rule for creating a single calculation task component using a slash command.
 *
 * Example:
 * - `/q calc[SPACE]` becomes a single calculation task component
 */
export const calculationTaskRule: (schema: Schema) => InputRule[] = (schema) => {
  if (!schema.nodes.taskSingleCalculation) {
    return [];
  }

  return [
    textblockTypeInputRule(/^\/q calc $/, schema.nodes.taskSingleCalculation, () => {
      const uiConfig = singleCalculation.uiConfigFactory();
      const graderConfig = singleCalculation.componentGraderFactory(uiConfig.uuid);

      return {
        ui: uiConfig,
        grader: graderConfig,
      };
    }),
  ];
};
