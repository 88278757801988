<template>
  <s-link
    :href="
      route('courses.assignments.show', {
        course: course.id,
        assignment: assignment.id,
      })
    "
    class="@container/card group"
  >
    <s-card padding="sm">
      <div class="flex w-full gap-2 items-center -mb-1">
        <div class="flex-1">
          <div class="flex flex-col @xl:flex-row items-start justify-between gap-y-2.5 gap-x-4">
            <div>
              <p class="text-sm leading-tight opacity-80 mb-1">{{ course.code }}</p>
              <h4
                class="text-xl leading-tight transition-all ease-out duration-150 group-hover:text-blue-600"
              >
                {{ assignment.name }}
              </h4>
            </div>
            <div class="flex items-center gap-2 text-sm -mt-0.5 mb-0.5">
              <s-tooltip v-if="visibilityConfig">
                <template #tooltip>
                  {{ visibilityConfig.tooltip }}
                </template>
                <s-badge
                  v-if="visibilityConfig"
                  :color="visibilityConfig.color"
                  class="whitespace-nowrap"
                >
                  <s-icon
                    :name="visibilityConfig.icon"
                    size="18"
                    class="text-grey-500 opacity-70"
                  />
                  {{ t(visibilityConfig.text) }}
                </s-badge>
              </s-tooltip>
              <s-badge v-if="assignment.isReviewRequired" color="blue" class="whitespace-nowrap">
                <s-icon name="alert" size="18" class="text-blue-500 opacity-70" />
                {{ t('badges.reviewRequired') }}
              </s-badge>
              <s-badge color="white" class="whitespace-nowrap">
                {{ t('badges.totalPoints', {points: assignment.pointsAvailable}) }}
              </s-badge>
            </div>
          </div>
        </div>
        <s-icon
          name="chevron-right"
          class="flex-0 opacity-70 group-hover:text-blue-400 group-hover:opacity-100 transition"
        />
      </div>
      <template #footer>
        <div class="w-full flex">
          <SLabelValue class="flex-1">
            <template v-slot:label>
              <s-tooltip
                position="top"
                v-if="assignment.courseLikeAssignmentsInfo.multipleStartDates"
              >
                <template #tooltip>
                  {{
                    t('tooltips.earliestStartDate', {
                      numberOfSections: assignment.courseLikeAssignmentsInfo.numberOfSections,
                    })
                  }}
                </template>
                <span class="flex items-center gap-1">
                  <s-icon name="warning" class="opacity-70 text-blue-500" size="18" />
                  {{ t('startDate') }}
                </span>
              </s-tooltip>
              <template v-else>{{ t('startDate') }}</template>
            </template>
            <span v-if="assignment.courseLikeAssignmentsInfo.earliestStartDate">
              <div class="leading-tighter">
                {{
                  monthDayYear(assignment.courseLikeAssignmentsInfo.earliestStartDate, {
                    time: false,
                  })
                }}
              </div>
              <span class="text-sm text-gray-600 leading-none font-bold uppercase tracking-slight">
                {{ timeOfDay(assignment.courseLikeAssignmentsInfo.earliestStartDate) }}
              </span>
            </span>
            <s-badge v-else color="red" icon="alert" size="sm">
              {{ t('badges.notAssigned') }}
            </s-badge>
          </SLabelValue>
          <SLabelValue class="flex-1">
            <template v-slot:label>
              <s-tooltip v-if="assignment.courseLikeAssignmentsInfo.multipleDueDates">
                <template #tooltip>
                  {{
                    t('tooltips.lastestDueDate', {
                      numberOfSections: assignment.courseLikeAssignmentsInfo.numberOfSections,
                    })
                  }}
                </template>
                <span class="flex items-center gap-1">
                  <s-icon
                    name="warning"
                    class="opacity-70 text-blue-500"
                    width="18"
                    height="18"
                    aria-hidden="true"
                  />
                  {{ t('dueDate') }}
                </span>
              </s-tooltip>
              <template v-else>{{ t('dueDate') }}</template>
            </template>

            <span v-if="assignment.courseLikeAssignmentsInfo.latestDueDate">
              <div class="capitalize leading-tighter">
                {{
                  monthDayYear(assignment.courseLikeAssignmentsInfo.latestDueDate, {time: false})
                }}
              </div>
              <span class="text-sm text-gray-600 leading-none font-bold uppercase tracking-slight">
                {{ timeOfDay(assignment.courseLikeAssignmentsInfo.latestDueDate) }}
              </span>
            </span>
            <s-badge v-else color="red" icon="alert" size="sm">
              {{ t('badges.notAssigned') }}
            </s-badge>
          </SLabelValue>
          <SLabelValue
            v-if="assignment.courseLikeAssignmentsInfo.timelimitEnabled"
            :label="t('timeLimit')"
          >
            <!-- TODO: Deal with multiple different time limits -->
            {{
              assignment.courseLikeAssignmentsInfo.highestTimelimitInMinutes
                ? minutesToHumanReadable(
                    assignment.courseLikeAssignmentsInfo.highestTimelimitInMinutes
                  )
                : t('notSet')
            }}
          </SLabelValue>
        </div>
      </template>
    </s-card>
  </s-link>
</template>

<script setup lang="ts">
import {computed} from 'vue';
import {route} from 'ziggy-js';
import SLink from '../../../components/SLink.vue';
import SCard from '../../../design-system/SCard.vue';
import SLabelValue from '../../../design-system/SLabelValue.vue';
import SBadge from '../../../design-system/SBadge.vue';
import STooltip from '../../../design-system/STooltip.vue';
import {minutesToHumanReadable, monthDayYear, timeOfDay} from '../../../format/dates';
import {useI18n} from 'vue-i18n';
import SIcon from '../../../design-system/SIcon.vue';
import {Course} from '../../../types/entities/course';
import StaffCourseAssignmentRowDto = App.DTOs.CourseAssignments.StaffCourseAssignmentRowDto;

const props = defineProps<{
  course: Course;
  assignment: StaffCourseAssignmentRowDto;
}>();

const {t} = useI18n({
  inheritLocale: true,
  useScope: 'local',
});

const visibilityConfig = computed(() => {
  const visibilityMap: Record<
    string,
    {color: 'blue-light' | 'green'; text: string; tooltip: string; icon: string}
  > = {
    visible: {
      color: 'green',
      text: 'badges.visible',
      tooltip: t('tooltips.visibility.visible'),
      icon: 'eye',
    },
    partial: {
      color: 'blue-light',
      text: 'badges.partiallyVisible',
      icon: 'eye-minus',
      tooltip: t('tooltips.visibility.partial'),
    },
    hidden: {
      icon: 'eye-off',
      color: 'blue-light',
      text: 'badges.notVisible',
      tooltip: t('tooltips.visibility.hidden'),
    },
  };
  return visibilityMap[props.assignment.visibility];
});
</script>

<i18n>
{
  "en": {
    "notSet": "Not Set",
    "badges": {
      "reviewRequired": "Needs Review",
      "totalPoints": "{points} Total Points",
      "notAssigned": "Not Assigned",
      "visible": "Visible",
      "notVisible": "Not Visible",
      "partiallyVisible": "Partially Visible"
    },
    "tooltips": {
      "earliestStartDate": "This is the earliest start date of the
{
  numberOfSections
} assigned sections",
"lastestDueDate": "This is the latest due date of the
{numberOfSections} assigned sections",
"visibility": {
"visible": "All students who are assigned this assignment can now see it.",
"partial": "Only some of the sections of students who are assigned this assignment can now see it.",
"hidden": "This assignment isn't visible to any students yet."
}
},
},
"fr": {
"notSet": "Non défini",
"badges": {
"reviewRequired": "Besoin de révision",
"totalPoints": "{points} Points en total",
"notAssigned": "Non attribué",
"visible": "Visible",
"notVisible": "Non visible",
"partiallyVisible": "Partiellement visible"
},
"tooltips": {
"earliestStartDate": "C'est la date de début la plus tôt des
{
numberOfSections
} sections assignées",
"lastestDueDate": "C'est la date de fin la plus tardive des
{numberOfSections} sections assignées",
"visibility": {
"visible": "Tous les étudiants qui sont assignés à cet
exercice peuvent maintenant le voir.",
"partial": "Seules certaines sections d'étudiants qui sont
assignées à cet exercice peuvent maintenant le voir.",
"hidden": "Cet exercice n'est pas encore visible pour
les étudiants."
}
},
}
}
</i18n>
