import {EditorState} from 'prosemirror-state';

/**
 * Accepts a position in the document.
 *
 * If that position has a Link mark, it will find the start and end positions of said Link.
 *
 * @param state
 * @param pos
 */
export function getLinkRange(state: EditorState, pos: number) {
  const linkType = state.schema.marks.link;

  /**
   * --------------------------------------------------------------------------
   * Resolve the Position
   * --------------------------------------------------------------------------
   *
   * This provides detailed information about the location in the doc.
   */
  const $pos = state.doc.resolve(pos);

  /**
   * --------------------------------------------------------------------------
   * Get the Node for the position
   * --------------------------------------------------------------------------
   * - The parent node is the node that contains the position.
   * - The parentOffset is the index of the position within the parent node.
   * - We get the childAfter, because the positions are _between_ the characters
   *
   * ## Example
   *
   * Let's say you have this text:
   *
   *    <p>abc<a href='#'>def</a>ghi</p>
   *
   * Then in prosemirror doc would look sorta like this:
   *
   *    {
   *      type: 'paragraph',
   *      content: [
   *        { type: 'text', text: 'abc' },
   *        { type: 'text', text: 'def', marks: [{ type: 'link', attrs: { href: '#' } }] },
   *        { type: 'text', text: 'ghi' }
   *      ]
   *    }
   *
   * The `positions` are the spaces between the cahracters
   *
   *    _a_b_c_d_e_f_g_h_i_
   *    0_1_2_3_4_5_6_7_8_9
   *
   * Infor about the link is not the text, but as metadata associated with the node.
   *
   * So, if the cursor is to the left of the `d`:
   * - the parent node is the `paragraph`
   * - the parentOffset is 3
   * - the `start` node is the 2nd text node
   *
   * However, if the link was to the right of the `f`:
   * - the parent node is the `paragraph`
   * - the parentOffset is 6
   * - the `start` node is the 3rd text node
   */
  const {parent, parentOffset} = $pos;
  const start = parent.childAfter(parentOffset);
  if (!start.node) return null;

  /**
   * --------------------------------------------------------------------------
   * Return null if the Node does NOT have a Link Mark
   * --------------------------------------------------------------------------
   */
  const link = start.node.marks.find((mark) => mark.type === linkType);
  if (!link) return null;

  /**
   * --------------------------------------------------------------------------
   * Find the Start of the link
   * --------------------------------------------------------------------------
   */
  let startIndex = $pos.index();
  let startPos = $pos.start() + start.offset;
  while (startIndex > 0 && link.isInSet(parent.child(startIndex - 1).marks)) {
    startIndex -= 1;
    startPos -= parent.child(startIndex).nodeSize;
  }

  /**
   * --------------------------------------------------------------------------
   * Find the End of the link
   * --------------------------------------------------------------------------
   */
  let endIndex = startIndex + 1;
  let endPos = startPos + start.node.nodeSize;
  while (endIndex < parent.childCount && link.isInSet(parent.child(endIndex).marks)) {
    endPos += parent.child(endIndex).nodeSize;
    endIndex += 1;
  }

  // Return the full range of positions that this link covers
  return {from: startPos, to: endPos};
}
