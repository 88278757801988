<template>
  <v-chart
    ref="chart"
    class="chart"
    :init-options="{renderer: 'svg'}"
    :option="option"
    autoresize
    renderer="svg"
  />
</template>
<script setup lang="ts">
import {computed, provide} from 'vue';
/**
 * New Stuff
 */
import {use} from 'echarts/core';
import {CanvasRenderer, SVGRenderer} from 'echarts/renderers';
import {
  BarChart,
  BarSeriesOption,
  LineChart,
  LineSeriesOption,
  ScatterChart,
  ScatterSeriesOption,
} from 'echarts/charts';
import {
  GridComponent,
  GridComponentOption,
  LegendComponent,
  LegendComponentOption,
  MarkLineComponent,
  MarkLineComponentOption,
  MarkPointComponent,
  MarkPointComponentOption,
  ToolboxComponent,
  ToolboxComponentOption,
  TooltipComponent,
  TooltipComponentOption,
} from 'echarts/components';
import VChart, {THEME_KEY} from 'vue-echarts';
import {UniversalTransition} from 'echarts/features';

const props = defineProps<{
  assignmentsByMonth: {
    count: number;
    countNotReviewed: number;
    countReviewed: number;
    month: string;
    year: string;
  }[];
}>();

provide(THEME_KEY, 'light');

use([
  GridComponent,
  BarChart,
  ScatterChart,
  LineChart,
  CanvasRenderer,
  TooltipComponent,
  LegendComponent,
  ToolboxComponent,
  MarkPointComponent,
  MarkLineComponent,
  UniversalTransition,
  SVGRenderer,
]);

type EChartsOption = echarts.ComposeOption<
  | GridComponentOption
  | BarSeriesOption
  | LineSeriesOption
  | ToolboxComponentOption
  | TooltipComponentOption
  | LegendComponentOption
  | MarkPointComponentOption
  | MarkLineComponentOption
  | ScatterSeriesOption
>;

const option = computed<EChartsOption>(() => {
  return {
    tooltip: {
      trigger: 'axis',
    },
    xAxis: {
      type: 'category',
      data: props.assignmentsByMonth.map((assignment) => `${assignment.month}, ${assignment.year}`),
    },
    yAxis: {
      type: 'value',
      min: 0,
      name: '# Assignments Created',
      nameLocation: 'middle',
      nameGap: 40,
    },
    series: [
      {
        type: 'scatter',
        name: 'Total',
        data: props.assignmentsByMonth.map((task) => task.count),
        itemStyle: {
          color: 'rgba(3,0,0,0.8)',
        },
      },
      {
        type: 'bar',
        name: 'Normal',
        stack: 'total',
        animation: false,
        data: props.assignmentsByMonth.map((task) => task.countNotReviewed),
        showBackground: true,
        backgroundStyle: {
          color: 'rgba(210,206,206,0.2)', // background color of the bar
        },
      },
      {
        type: 'bar',
        name: 'Reviews Enabled',
        stack: 'total',
        animation: false,
        data: props.assignmentsByMonth.map((task) => {
          return task.countReviewed;
        }),
        showBackground: true,
        backgroundStyle: {
          color: 'rgba(210,206,206,0.2)', // background color of the bar
        },
      },
    ],
  };
});
</script>
<style scoped>
.chart {
  width: 100%;
  aspect-ratio: 16 / 9;
  max-height: 40vh;
}
</style>
