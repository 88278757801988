<template>
  <component
    :is="divOrLink"
    :href="href"
    class="ml-2.5 relative tracking-slight rounded-l-full px-3.5 py-2.5 transition"
    :class="{
      'font-medium text-white bg-blue-900 shadow-lg': activeRoute && divOrLink !== 'div',
      'font-normal text-white/80 hover:text-white hover:bg-blue-900/70':
        !activeRoute && divOrLink !== 'div',
    }"
  >
    <div class="stack horizontal valign-center gap-3 w-full drop-shadow">
      <s-icon
        v-if="!!icon"
        :name="icon"
        class="drop-shadow-lg"
        :class="activeRoute ? 'text-s-red-200' : 'text-blue-200'"
      />
      <slot />
    </div>
  </component>
</template>
<script lang="ts" setup>
import {computed, defineProps} from 'vue';
import SLink from '../../components/SLink.vue';
import {usePage} from '@inertiajs/vue3';
import SIcon from '../../design-system/SIcon.vue';

const props = defineProps<{
  href?: string;
  icon?: string;
}>();

const page = usePage();
const path = computed(() => page.url.split('?')[0]);
const activeRoute = computed(() => path.value === props.href);

const divOrLink = computed(() => {
  if (props.href) {
    return SLink;
  }

  return 'div';
});
</script>
