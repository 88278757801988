<template>
  <section aria-labelledby="assignment-info" class="stack vertical">
    <h2
      v-if="name"
      id="assignment-info"
      class="flex-1 flex justify-between gap-3 flex-wrap items-center text-sm font-bold tracking-wide leading-none text-gray-600 mb-1.5"
    >
      {{ name }}
      <span v-if="isPastDueDate" class="-my-1">
        <s-badge color="red">
          <strong class="text-xs">{{ t('pastDueDate') }}</strong>
        </s-badge>
      </span>
    </h2>
    <div class="flex flex-wrap gap-2 mb-1">
      <s-label-value class="flex-1 whitespace-nowrap">
        <template #label>
          <span
            class="flex items-center gap-1"
            :class="{
              'text-green-700': extendedStartDate,
            }"
          >
            <s-icon
              v-if="extendedStartDate"
              name="calendar-arrow-right"
              size="20"
              class="text-green-500 -my-1"
              aria-hidden="true"
            />
            {{ t('started') }}
          </span>
        </template>
        <template v-if="startDate">
          <s-date v-if="!extendedStartDate" :date="startDate" />
          <button
            v-else
            class="relative group flex items-center gap-1"
            @click.stop.prevent="overlayOpen = true"
          >
            <s-tooltip>
              <template #tooltip>
                <strong class="text-sm leading-tight">
                  {{ t('extensionGrantedMessage') }}
                </strong>
                <br />
                {{ t('modifiedStartDateMessage') }}
              </template>
              <s-date :date="extendedStartDate" class="font-medium text-green-700 text-left" />
            </s-tooltip>
          </button>
        </template>
        <div v-else class="flex my-0.5">
          <s-badge color="red" icon="alert" size="sm">{{ t('notAssigned') }}</s-badge>
        </div>
      </s-label-value>
      <div class="flex-1">
        <s-tooltip :disabled="!isPastDueDate || !lateResponsePenaltyPolicy">
          <template v-if="isPastDueDate" #tooltip>
            <strong class="text-sm leading-tight">
              <p class="block min-w-[12rem] text-sm font-normal leading-snug">
                <s-penalty-policy-student
                  v-if="lateResponsePenaltyPolicy"
                  :isPastDueDate="isPastDueDate"
                  :penalty="{
                    type: lateResponsePenaltyPolicy.penaltyType,
                    value: lateResponsePenaltyPolicy.penaltyValue,
                  }"
                  :frequency="{
                    type: lateResponsePenaltyPolicy.frequencyUnit,
                    value: lateResponsePenaltyPolicy.frequencyValue,
                  }"
                  :submission-cutoff="effectiveCutoffDate"
                />
              </p>
            </strong>
          </template>
          <s-label-value
            class="flex-1 whitespace-nowrap"
            :class="isPastDueDate ? 'text-red-600' : ''"
          >
            <template #label>
              <span
                class="flex items-center gap-1"
                :class="{
                  'text-red-600': isPastDueDate && !extendedDueDate,
                  'text-green-700': extendedDueDate,
                }"
              >
                <s-icon
                  v-if="extendedDueDate && !isPastDueDate"
                  name="calendar-arrow-right"
                  size="18"
                  class="text-green-500 -my-1"
                />
                <s-icon v-else-if="isPastDueDate" name="alert" size="18" class="-my-1 opacity-70" />
                {{ t('dueOn') }}
              </span>
            </template>
            <template v-if="dueDate">
              <s-date v-if="!extendedDueDate" :date="dueDate" />
              <button v-else class="relative group" @click.stop.prevent="overlayOpen = true">
                <s-tooltip>
                  <template #tooltip>
                    <strong class="text-sm leading-tight">
                      {{ t('extensionGrantedMessage') }}
                    </strong>
                    <br />
                    {{ t('modifiedDueDateMessage') }}
                  </template>
                  <s-date :date="extendedDueDate" class="text-left font-medium text-green-700" />
                </s-tooltip>
              </button>
            </template>
            <div v-else class="flex my-0.5">
              <s-badge color="red" icon="alert" size="sm">{{ t('notAssigned') }}</s-badge>
            </div>
          </s-label-value>
        </s-tooltip>
      </div>
    </div>
    <div class="flex flex-wrap gap-2">
      <s-label-value :label="t('assignmentGrade')" class="flex-1">
        <s-icon v-if="!canViewGrades" name="lock" size="18" class="text-gray-600" />
        <s-grade v-else :grade="grade" tooltip :grade-display="course.gradeDisplay"></s-grade>
      </s-label-value>
    </div>
  </section>
  <s-modal v-model:open="overlayOpen" :title="t('assignmentExtended')" confirmable>
    <template #content>
      <p class="mb-4">{{ t('extensionModifiedMessage') }}</p>
      <div v-if="extendedStartDate" class="flex gap-2 justify-between flex-wrap mb-3">
        <s-label-value :label="t('originalStartDate')" class="flex-1">
          <s-date :date="startDate" />
        </s-label-value>
        <s-label-value class="flex-1 text-green-700" :label="t('modifiedStartDate')">
          <s-date :date="extendedStartDate" class="font-bold" />
        </s-label-value>
      </div>
      <div v-if="extendedDueDate" class="flex gap-2 justify-between flex-wrap">
        <s-label-value :label="t('originalDueDate')" class="flex-1">
          <s-date :date="dueDate" />
        </s-label-value>
        <s-label-value class="flex-1 text-green-700" :label="t('modifiedDueDate')">
          <s-date :date="extendedDueDate" class="font-bold" />
        </s-label-value>
      </div>
    </template>
  </s-modal>
</template>
<script setup lang="ts">
import {computed, ref} from 'vue';
import {isDatePast} from '../../../format/dates';
import SLabelValue from '../../../design-system/SLabelValue.vue';
import SModal from '../../../design-system/SModal.vue';
import SGrade from '../../../components/SGrade.vue';
import SIcon from '../../../design-system/SIcon.vue';
import STooltip from '../../../design-system/STooltip.vue';
import {Assignment} from '../../../types/entities/assignment';
import {Course} from '../../../types/entities/course';
import {User} from '../../../types/entities/user';
import SBadge from '../../../design-system/SBadge.vue';
import SDate from '../../../design-system/SDate.vue';
import SPenaltyPolicyStudent from '../../../components/SPenaltyPolicyStudent.vue';
import {useI18n} from 'vue-i18n';
import AssignmentGradeDto = App.DTOs.AssignmentGradeDto;
import UserAssignmentTimerDto = App.DTOs.UserAssignmentTimerDto;
import LateResponsePenaltyPolicyDto = App.DTOs.LateResponsePenaltyPolicy.LateResponsePenaltyPolicyDto;

const props = defineProps<{
  assignment?: Assignment;
  lateResponsePenaltyPolicy?: LateResponsePenaltyPolicyDto | null;
  name?: string;
  course: Course;
  startDate?: string | null;
  dueDate?: string | null;
  extendedStartDate?: string | null;
  extendedDueDate?: string | null;
  totalMarks: number;
  grade?: AssignmentGradeDto;
  canViewGrades?: boolean;
  student?: User;
  timer?: UserAssignmentTimerDto | null;
}>();

const overlayOpen = ref(false);

const isPastDueDate = computed(() => {
  const activeDueDate = props.extendedDueDate || props.dueDate;
  if (!activeDueDate) return false;
  return isDatePast(activeDueDate);
});

const effectiveCutoffDate = computed(() => {
  // 1. An interval calculated between the cutoff and the original due date
  // 2. The interval is applied to the effective due date to get the effective cutoff date
  const originalDueDate = props.dueDate;
  const cutoffDate = props.lateResponsePenaltyPolicy?.submissionCutoff;
  if (!cutoffDate || !originalDueDate) return null;
  const interval = new Date(cutoffDate).getTime() - new Date(originalDueDate).getTime();
  const effectiveDueDate = props.extendedDueDate || props.dueDate;
  if (!effectiveDueDate) return null;
  return new Date(new Date(effectiveDueDate).getTime() + interval);
});

const {t} = useI18n({
  inheritLocale: true,
  useScope: 'local',
});
</script>
<i18n>
{
  "en": {
    "pastDueDate": "Past Due Date",
    "started": "Started",
    "extensionGrantedMessage": "You've been granted an extension for this assignment.",
    "modifiedStartDateMessage": "Your modified start date is indicated.",
    "notAssigned": "Not Assigned",
    "dueOn": "Due On",
    "modifiedDueDateMessage": "Your modified due date is indicated.",
    "assignmentGrade": "Assignment Grade",
    "assignmentExtended": "Assignment Extended",
    "extensionModifiedMessage": "Your due date for this assignment has been modified:",
    "originalStartDate": "Original Start Date",
    "modifiedStartDate": "Modified Start Date",
    "originalDueDate": "Original Due Date",
    "modifiedDueDate": "Modified Due Date"
  },
  "fr": {
    "pastDueDate": "Date limite dépassée",
    "started": "Commencé",
    "extensionGrantedMessage": "Vous avez obtenu une prolongation pour ce devoir.",
    "modifiedStartDateMessage": "Votre nouvelle date de début est indiquée.",
    "notAssigned": "Non attribué",
    "dueOn": "À rendre le",
    "modifiedDueDateMessage": "Votre nouvelle date d'échéance est indiquée.",
    "assignmentGrade": "Note de l'assignation",
    "assignmentExtended": "Devoir prolongé",
    "extensionModifiedMessage": "Votre date limite pour cet devoir a été modifiée :",
    "originalStartDate": "Date de début originale",
    "modifiedStartDate": "Date de début modifiée",
    "originalDueDate": "Date d'échéance originale",
    "modifiedDueDate": "Date d'échéance modifiée"
  }
}
</i18n>
