<template>
  <div class="flex flex-col pt-1">
    <div class="flex items-end justify-between gap-x-4 gap-y-1 flex-wrap mb-4">
      <h2 class="text-2xl">{{ title }}</h2>
      <s-dropdown-menu v-if="menuOptions" :items="menuOptions" class="flex-1 max-w-[14.5rem]">
        <template #trigger="{open}">
          <button
            class="btn md primary-light group/button flex items-center justify-center gap-1.5 flex-1"
          >
            <span
              class="opacity-70 grid grid-cols-1 grid-rows-1 justify-center items-center transition-all ease-out duration-150 -ml-1"
              role="presentation"
            >
              <s-icon
                name="close"
                size="18"
                class="row-start-1 col-start-1 transition-all duration-150"
                :class="!open ? 'opacity-0 rotate-90 ease-in' : 'opacity-100 ease-out'"
              />
              <s-icon
                name="plus"
                size="18"
                class="row-start-1 col-start-1 transition-all duration-150"
                :class="open ? 'opacity-0 rotate-90 ease-out' : 'opacity-100 ease-in'"
              />
            </span>
            {{ addLabel }}
          </button>
        </template>
      </s-dropdown-menu>
      <s-btn
        v-else-if="addItem"
        @click.prevent="addItem"
        color="primary-light"
        icon="plus"
        class="flex-1 max-w-[14.5rem]"
      >
        {{ addLabel }}
      </s-btn>
    </div>
    <s-field :id="id!" :description="description">
      <template #default="a11yProps">
        <s-sortable-list
          :items="clonedItems"
          :remove-label="removeLabel"
          :confirm-remove="confirmRemove"
          :removable="!!removeItem"
          :item-key="itemKey"
          :display-value="displayValue"
          :placeholder="placeholder"
          :sortable="sortable"
          v-bind="a11yProps"
          @itemRemoved="({item, index}) => removeItem?.(item, index)"
          @update:items="
            clonedItems = $event;
            emit('update:items', clonedItems);
          "
        >
          <template #item="{item, index}">
            <slot name="item" :item="item" :index="index" />
          </template>
        </s-sortable-list>
      </template>
    </s-field>
  </div>
</template>

<script setup lang="ts">
import {ref, watch} from 'vue';
import SDropdownMenu from './SDropdownMenu.vue';
import SBtn from './SBtn.vue';
import SSortableList from './SSortableList.vue';
import SField from './SField.vue';
import SIcon from './SIcon.vue';

const props = defineProps<{
  id?: string | null;
  title: string;
  description?: string;
  addLabel: string;
  items: any[];
  addItem?: () => void;
  placeholder?: string;
  menuOptions?: {
    label: string;
    onClick?: () => void;
    icon?: string;
  }[];
  itemKey?: string | ((item: any) => string);
  displayValue?: (item: any) => string;
  removeItem?: (item: any, index: number) => void;
  removeLabel?: string;
  confirmRemove?: boolean;
  sortable?: boolean;
}>();

const clonedItems = ref<any[]>(JSON.parse(JSON.stringify(props.items)));
const emit = defineEmits(['update:items']);

watch(
  () => props.items,
  (newItems) => {
    clonedItems.value = JSON.parse(JSON.stringify(newItems));
  },
  {deep: true}
);
</script>

<style scoped>
.sortable-ghost {
  @apply bg-gray-150 text-gray-400;
}

.sortable-drag {
  @apply rounded-lg border border-gray-200 shadow-xl;
}
</style>
