<template>
  <Head title="Admin - Generate Access Codes"></Head>
  <div class="page-block stack gap-6 vertical align-left">
    <h1 class="heading xxl">{{ t('page.heading') }}</h1>

    <p class="text-xl">
      {{ t('page.description') }}
    </p>

    <form
      @submit.prevent="form.post(route('admin.access-codes.store'))"
      class="bg-white stack vertical gap-5 p-5 rounded-lg w-full"
    >
      <s-input-field
        id="nAccessCodes"
        :label="t('label.nAccessCodes')"
        v-model.number="form.nAccessCodes"
        :error="form.errors.nAccessCodes"
        type="number"
        min="1"
      />

      <button type="submit" class="btn primary md w-fit" :disabled="form.processing">
        {{ t('form.submit') }}
      </button>

      <div v-if="newCodes.length">
        <h2 class="heading">{{ t('page.newCodes') }}</h2>
        <p v-for="code in newCodes">{{ code }}</p>
      </div>
    </form>
  </div>
</template>
<script setup lang="ts">
import {Head, useForm} from '@inertiajs/vue3';
import {PropType} from 'vue';
import {route} from 'ziggy-js';
import {useI18n} from 'vue-i18n';
import SInput from '../../../design-system/SInput.vue';
import SInputField from '../../../design-system/SInputField.vue';

const {t} = useI18n({
  useScope: 'local',
  inheritLocale: true,
});

const form = useForm({
  nAccessCodes: '1',
});

defineProps({
  newCodes: {
    type: Array as PropType<string[]>,
    default: () => [],
  },
});
</script>
<i18n>
{
  "en": {
    "page": {
      "heading": "Generate Access Codes",
      "description": "This form lets you generate access codes to send to bookstores.",
      "newCodes": "New Access Codes"
    },
    "label": {
      "nAccessCodes": "Number of Access Codes"
    },
    "form": {
      "submit": "Generate Codes"
    }
  }
}
</i18n>
