<template>
  <Head
    :title="
      t('page.title', {
        courseCode: course.code,
      })
    "
  ></Head>
  <s-page-block size="md">
    <div class="card stack vertical">
      <div
        class="card-content stack vertical gap-1 mb-4 bg-gradient-to-t from-gray-100 to-transparent"
      >
        <s-page-header :overline="t('page.header')" :heading="courseName" />
        <div class="stack horizontal align-between text-sm lg:text-lg">
          <div v-if="sectionName">{{ t('section') }} &ndash; {{ sectionName }}</div>
          <div>
            <span v-if="!isAttendanceCompleted">
              {{ t('form.completeIn') }}
            </span>
            <span v-else class="text-amber-500">{{ t('form.completed') }}</span>
            <span
              class="font-bold pl-1"
              :class="{
                'text-blue-800': !isAttendanceCompleted,
                'text-amber-500': isAttendanceCompleted,
              }"
            >
              {{ currentTimeRef }}
            </span>
          </div>
        </div>
      </div>
      <div class="card-content gap-6 grid grid-cols-5 relative">
        <div class="col-span-5 sm:col-span-3 stack vertical gap-3">
          <s-field id="attendanceLink" :label="t('form.attendanceLink')">
            <s-input
              :model-value="route('courses.attendance.index', courseId)"
              class="lg:text-lg tracking-wide text-center font-bold text-blue-800 pointer-events-none"
            />
          </s-field>
          <s-field id="attendanceCode" :label="t('form.attendanceCode')">
            <s-input
              :model-value="code"
              class="text-4xl lg:text-6xl tracking-widest text-center font-bold text-blue-800 pointer-events-none"
            />
          </s-field>
        </div>
        <div class="col-span-5 sm:col-span-2">
          <img :src="QRCodeURL" :alt="t('form.qrCode')" class="w-full" />
        </div>
        <div
          v-if="isAttendanceCompleted"
          class="absolute top-0 left-0 w-full h-full inset-0 bg-gray-50/70 backdrop-blur stack horizontal align-center valign-center"
        >
          <div
            class="text-2xl font-semibold tracking-wide text-blue-600 stack horizontal gap-1 valign-center"
          >
            <s-icon name="account-check" />
            {{ t('form.attendanceCompleted') }}
          </div>
        </div>
      </div>
    </div>
  </s-page-block>
</template>
<script setup lang="ts">
import {computed, defineProps, onMounted, ref} from 'vue';
import SPageBlock from '../../design-system/SPageBlock.vue';
import SPageHeader from '../../design-system/SPageHeader.vue';
import SField from '../../design-system/SField.vue';
import SInput from '../../design-system/SInput.vue';
import {route} from 'ziggy-js';
import {addMinutes, differenceInSeconds, format} from 'date-fns';
import {Head} from '@inertiajs/vue3';
import {Course} from '../../types/entities/course';
import SIcon from '../../design-system/SIcon.vue';
import {useI18n} from 'vue-i18n';

const props = defineProps<{
  QRCodeURL: string;
  code: string;
  courseName: string;
  courseId: number;
  sectionName?: string;
  course: Course;
  rollCall: {
    id: string;
    code: string;
    startsAt: string;
    endsAt: string;
  };
}>();

onMounted(() => startTimer());

const currentTimeRef = ref(calculateTimeDifference(props.rollCall.endsAt));
const isAttendanceCompleted = computed(() => currentTimeRef.value === '00:00');

let timerRef: NodeJS.Timer;

function calculateTimeDifference(targetTimeInUTC: string): string {
  const currentTimeUTC = addMinutes(new Date(), new Date().getTimezoneOffset());
  const targetTimeUTC = addMinutes(
    new Date(targetTimeInUTC),
    new Date(targetTimeInUTC).getTimezoneOffset()
  );

  const timeDifferenceSeconds = differenceInSeconds(targetTimeUTC, currentTimeUTC);

  if (timeDifferenceSeconds <= 0) {
    return '00:00';
  }

  return format(new Date(0, 0, 0, 0, 0, timeDifferenceSeconds), 'mm:ss');
}

function decrementTimer(currentTime: string, secondsToDecrement: number): void {
  if (currentTime === '00:00') {
    clearInterval(+timerRef);
    return;
  }

  const [mins, secs] = currentTime.split(':');
  const minutes = parseInt(mins, 10);
  const seconds = parseInt(secs, 10);

  const totalSeconds = minutes * 60 + seconds;
  const remainingSeconds = Math.max(0, totalSeconds - secondsToDecrement);

  const newMinutes = Math.floor(remainingSeconds / 60);
  const newSeconds = remainingSeconds % 60;

  currentTimeRef.value = `${String(newMinutes).padStart(2, '0')}:${String(newSeconds).padStart(
    2,
    '0'
  )}`;
}

function startTimer() {
  timerRef = setInterval(() => {
    decrementTimer(currentTimeRef.value, 1);
  }, 1000);
}

const {t} = useI18n({
  useScope: 'local',
  inheritLocale: true,
});
</script>
<i18n>
{
  "en": {
    "form": {
      "completeIn": "Complete in",
      "completed": "Completed",
      "attendanceLink": "Attendance Link",
      "attendanceCode": "Attendance Code",
      "qrCode": "QR Code",
      "attendanceCompleted": "Attendance has been recorded."
    },
    "page": {
      "title": "{courseCode}  - Roll Call",
      "header": "Attendance Check"
    },
  },
  "fr": {
    "form": {
      "completeIn": "Compléter dans",
      "completed": "Terminé",
      "attendanceLink": "Lien de présence",
      "attendanceCode": "Code de présence",
      "qrCode": "Code QR",
      "attendanceCompleted": "La présence a été enregistrée."
    },
    "page": {
      "title": "{courseCode} - Appel",
      "header": "Vérification de présence"
    },
  }
}
</i18n>
