<template>
  <Head :title="t('page.title', {code: course.code})"></Head>
  <s-page-block size="md">
    <s-page-header :heading="t('page.heading')" class="mb-4" />
    <s-tabs
      sequential
      :tabs="[
        {
          label: t('tabs.upload'),
          icon: 'file-upload',
          completed: !!theirColumns.length,
        },
        {
          label: t('tabs.configure'),
          icon: 'table-cog',
          disabled: !theirColumns.length,
          completed: !!rosterChanges.length,
        },
        {
          label: t('tabs.resolve', {count: invalidChanges.length}),
          icon: 'alert',
          disabled: !rosterChanges.length,
          completed: !numIssues,
        },
        {
          label: t('tabs.confirm', {count: validChanges.length}),
          icon: 'clipboard-check',
          disabled: !rosterChanges.length,
        },
      ]"
      v-model="selectedTab"
    >
      <template #tab-0>
        <tab-select-csv :course="course" :form="form" @next="changeTab(1)" :classes="classes" />
      </template>
      <template #tab-1>
        <tab-config
          :form="form"
          :classes="classes"
          :course-likes="courseLikes"
          :errors="errors"
          :our-columns="ourColumns"
          :their-columns="theirColumns"
          :can="can"
          @submit="submitCsv"
          @back="changeTab(0)"
        />
      </template>
      <template #tab-2>
        <tab-resolve-issues
          :conflicting-changes="conflictingChanges"
          :form="form"
          :classes="classes"
          :invalid-changes="invalidChanges"
          :num-issues="numIssues"
          @submit="changeTab(3)"
          @back="changeTab(1)"
        />
      </template>
      <template #tab-3>
        <tab-confirm-changes
          :processing-changes="processingChanges"
          :additions="additions"
          :moves="moves"
          :classes="classes"
          :unchanged="unchanged"
          :removals="removals"
          @back="changeTab(2)"
          @submit="processChanges"
          :form="form"
        />
      </template>
    </s-tabs>
  </s-page-block>
</template>

<script setup lang="ts">
import STabs from '../../design-system/STabs.vue';
import SPageHeader from '../../design-system/SPageHeader.vue';
import SPageBlock from '../../design-system/SPageBlock.vue';
import {Course} from '../../types/entities/course';
import {Head, router, useForm} from '@inertiajs/vue3';
import {route} from 'ziggy-js';
import {useI18n} from 'vue-i18n';
import RosterFormDto = App.DTOs.Roster.RosterFormDto;
import {computed, ref, watch} from 'vue';
import InputMapDto = App.DTOs.Roster.InputMapDto;
import RosterChangeDto = App.DTOs.Roster.RosterChangeDto;
import TabSelectCsv from './Create/TabSelectCsv.vue';
import TabConfig from './Create/TabConfig.vue';
import TabResolveIssues from './Create/TabResolveIssues.vue';
import TabConfirmChanges from './Create/TabConfirmChanges.vue';

const props = defineProps<{
  course: Course;
  courseLikes: CourseLike[];
  roles: string[];
  diff?: RosterChangeDto[];
  can: {
    create: boolean;
    update: boolean;
    delete: boolean;
  };
}>();

const rosterChanges = ref<RosterChangeDto[]>(props.diff || []);
watch(
  () => props.diff,
  (diff) => {
    rosterChanges.value = diff || [];
  }
);

const rosterChangesByEmail = ref<Record<string, RosterChangeDto[]>>({});
watch(rosterChanges, (diff) => {
  const changesByEmail: Record<string, RosterChangeDto[]> = {};

  diff?.forEach((change) => {
    const previous = changesByEmail[change.email || ''] || [];

    changesByEmail[change.email || ''] = [...previous, change];
  });

  rosterChangesByEmail.value = changesByEmail;
});

const {t} = useI18n({
  useScope: 'local',
  inheritLocale: true,
});

const invalidChanges = computed<RosterChangeDto[]>(() => {
  if (!rosterChanges.value) return [];
  return rosterChanges.value.filter((c) => c.errors?.length && !c.conflictingRows?.length);
});

const conflictingChanges = computed<Record<string, RosterChangeDto[]>>(() => {
  const changesByEmail = rosterChangesByEmail.value;

  if (!changesByEmail) return {};

  const conflictsByEmail: Record<string, RosterChangeDto[]> = {};
  Object.keys(changesByEmail || {}).forEach((email: keyof typeof changesByEmail) => {
    let conflicts = changesByEmail[email].filter((c) => c.conflictingRows?.length);

    if (conflicts.length) {
      conflictsByEmail[email] = conflicts;
    }
  });
  return conflictsByEmail;
});

const validChanges = computed<RosterChangeDto[]>(() => {
  if (!rosterChanges.value) return [];
  return rosterChanges.value.filter(
    (c) => !c.errors?.length && !c.conflictingRows?.length && c.type !== 'ignore'
  );
});

const removals = computed<RosterChangeDto[]>(() => {
  return validChanges.value.filter((c) => c.type === 'remove');
});

const additions = computed<RosterChangeDto[]>(() => {
  return validChanges.value.filter((c) => c.type === 'add');
});

const moves = computed<RosterChangeDto[]>(() => {
  return validChanges.value.filter((c) => c.type === 'move');
});

const unchanged = computed<RosterChangeDto[]>(() => {
  return validChanges.value.filter((c) => c.type === 'none');
});

const numIssues = computed(() => {
  return (
    invalidChanges.value.length +
    Object.values(conflictingChanges.value).reduce((acc, changes) => acc + changes.length, 0)
  );
});

const form = useForm<RosterFormDto>({
  file: null as null | File,
  sendInvites: false,
  config: {
    addMissingParticipants: props.can.create,
    removeMissingRoles: false,
    updateNonStudents: false,
    updateStudents: props.can.update,
    ignoredRoles: [],
  },
  columnMap: {
    email: 'email',
    sectionName: 'sectionName',
    role: 'role',
    firstName: null,
    lastName: null,
    studentId: null,
    defaultSection: props.courseLikes[0].name,
    defaultRole: null,
  },
});

const errors = computed(() => form.errors as any);

const classes = {
  tabPane: 'px-5 md:px-7 lg:px-8 py-4 md:py-5 lg:py-6 flex flex-col',
  tabFooter:
    'bg-gray-100 border-t border-gray-150 px-5 md:px-7 lg:px-8 pt-4 pb-5 md:pt-5 md:pb-6 flex-1 flex flex-wrap items-end justify-between gap-x-4 gap-y-2',
};

type ColumnKey = keyof InputMapDto;

const ourColumns = [
  {key: 'email' as ColumnKey, label: t('email')},
  {key: 'sectionName' as ColumnKey, label: t('section')},
  {key: 'firstName' as ColumnKey, label: t('firstName')},
  {key: 'lastName' as ColumnKey, label: t('lastName')},
  {key: 'studentId' as ColumnKey, label: t('studentId')},
];

const theirColumns = ref<string[]>([]);

const selectedTab = ref(0);

function changeTab(index: number) {
  selectedTab.value = index;
}

const processingChanges = ref(false);

function processChanges() {
  processingChanges.value = true;
  router.post(
    route('courses.students.process', {course: props.course.id}),
    {
      rosterDiff: rosterChanges.value,
      sendInvites: form.sendInvites,
    },
    {
      onFinish() {
        processingChanges.value = false;
      },
    }
  );
}

function looseMatch(theirColumn: string, ourColumn: string) {
  const simplifiedOurColumn = ourColumn.replace(/[^a-zA-Z]/g, '').toLowerCase();
  const simplifiedTheirColumn = theirColumn.replace(/[^a-zA-Z]/g, '').toLowerCase();
  return (
    simplifiedOurColumn.includes(simplifiedTheirColumn) ||
    simplifiedTheirColumn.includes(simplifiedOurColumn)
  );
}

function submitCsv() {
  form.post(route('courses.students.diff', {course: props.course.id}), {
    onSuccess() {
      if (invalidChanges.value.length) {
        changeTab(2);
      } else {
        changeTab(3);
      }
    },
    onError(errors) {
      if (errors.file) {
        changeTab(0);
      }
    },
  });
}

watch(
  () => form.file,
  (newFile, oldFile) => {
    if (newFile === oldFile) return;

    if (!newFile) {
      theirColumns.value = [];
      rosterChanges.value = [];
      rosterChangesByEmail.value = {};
      return;
    }

    const reader = new FileReader();
    reader.onload = (e) => {
      const content = reader.result || '';
      const lines = content.toString().split('\n');
      if (lines.length) {
        const newColumns = lines[0].split(',').filter((c) => !!c.trim());
        theirColumns.value = newColumns;
        if (selectedTab.value === 0) {
          setTimeout(() => changeTab(1), 0);
        }

        for (const column of ourColumns) {
          for (const theirColumn of newColumns) {
            if (looseMatch(theirColumn, column.key)) {
              form.columnMap[column.key] = theirColumn;
              break;
            }
          }
        }
      }
    };
    reader.readAsText(newFile);
  }
);
</script>
<i18n>
{
  "en": {
    "page": {
      "title": "Manage Students for {code}",
      "heading": "Manage Students"
    },
    "tabs": {
      "upload": "Select CSV",
      "configure": "Configure",
      "resolve": "Resolve Issues ({count})",
      "confirm": "Confirm Changes ({count})"
    },
    "errors": {
      "invalid-email": "Invalid email address",
      "invalid-role": "Invalid role: {role}",
      "section-dne": "Section does not exist: {sectionName}",
      "unauthorized-role": "You are not authorized to assign the role: {role}",
      "unauthorized-section": "You are not authorized to assign participants to the section: {sectionName}"
    },
    "actions": {
      "continue": "Continue",
      "diff": "Confirm Changes",
      "map": "Map Columns",
      "confirm": "Confirm Changes",
      "back": "Back"
    },
    "labels": {
      "defaultSection": "Default Section"
    }
  },
  "fr": {
    "page": {
      "title": "Gérer les étudiants pour {code}",
      "heading": "Gérer les étudiants"
    },
    "tabs": {
      "upload": "Sélectionner un fichier CSV",
      "configure": "Configurer",
      "resolve": "Résoudre les problèmes ({count})",
      "confirm": "Confirmer les modifications ({count})"
    },
    "errors": {
      "invalid-email": "Adresse e-mail invalide",
      "invalid-role": "Rôle invalide : {role}",
      "section-dne": "La section n'existe pas : {sectionName}",
      "unauthorized-role": "Vous n'êtes pas autorisé à attribuer le rôle : {role}",
      "unauthorized-section": "Vous n'êtes pas autorisé à attribuer des participants à la section : {sectionName}"
    },
    "actions": {
      "continue": "Continuer",
      "diff": "Confirmer les modifications",
      "map": "Mapper les colonnes",
      "confirm": "Confirmer les modifications",
      "back": "Retour"
    },
    "labels": {
      "defaultSection": "Section par défaut"
    }
  }
}
</i18n>
