<template>
  <div class="flex flex-wrap gap-4">
    <div class="min-w-0 flex flex-col items-stretch gap-1" role="none">
      <s-label for="type">Tolerance Type</s-label>
      <s-button-toggle
        id="type"
        :options="computedOptions"
        :model-value="modelValue?.type"
        @update:model-value="updateModelValue('type', $event)"
        :error="errors?.type"
      />
    </div>
    <div class="flex-1 min-w-24">
      <s-input-field
        label="Value"
        :model-value="modelValue?.value"
        @update:model-value="updateModelValue('value', $event)"
        :disabled="modelValue === null"
        id="toleranceValue"
        :error="errors?.value"
      />
    </div>
  </div>
</template>
<script setup lang="ts">
import {computed} from 'vue';
import {useVModel} from '@vueuse/core';
import SInputField from '../../../design-system/SInputField.vue';
import SButtonToggle from '../../../design-system/SButtonToggle.vue';
import SLabel from '../../../design-system/SLabel.vue';
import CalculationToleranceDto = App.DTOs.Tasks.CalculationToleranceDto;
import CalculationToleranceType = App.DTOs.Tasks.CalculationToleranceType;

interface Option {
  label: string;
  value: CalculationToleranceType | null;
}

const props = defineProps<{
  modelValue?: CalculationToleranceDto | null;
  nullable?: boolean;
  errors?: Record<string, any>;
}>();

const emit = defineEmits<{
  (evt: 'update:modelValue', value: CalculationToleranceType | null): void;
}>();

const modelValueInternal = useVModel(props, 'modelValue', emit);

const computedOptions = computed(() => {
  const options: Option[] = [
    {label: 'Relative', value: 'relative'},
    {label: 'Absolute', value: 'absolute'},
  ];

  if (props.nullable) {
    options.push({label: 'None', value: null});
  }

  return options;
});

const updateModelValue = (key: keyof CalculationToleranceDto, value: any) => {
  if (value === null) {
    modelValueInternal.value = null;
  } else {
    modelValueInternal.value = {
      type: modelValueInternal.value?.type ?? 'relative',
      value: modelValueInternal.value?.value ?? '0.02',
      [key]: value,
    };
  }
};
</script>

<style scoped></style>
