<template>
  <TransitionRoot appear :show="internalOpen" as="template">
    <Dialog as="div" :open="internalOpen" @close="close" class="relative z-50">
      <TransitionChild
        as="template"
        enter="duration-300 ease-out"
        enter-from="opacity-0"
        enter-to="opacity-100"
        leave="duration-200 ease-in"
        leave-from="opacity-100"
        leave-to="opacity-0"
      >
        <div class="fixed inset-0 bg-gray-900/80" />
      </TransitionChild>
      <div class="fixed inset-0 flex flex-col">
        <div
          class="flex-1 flex flex-col min-h-0 items-center justify-start text-center ml-12 md:ml-24 xl:ml-48"
        >
          <TransitionChild
            as="template"
            enter="duration-200 ease-out"
            enter-from="opacity-0 translate-x-4"
            enter-to="opacity-100 scale-100"
            leave="duration-200 ease-in"
            leave-from="opacity-100 scale-100"
            leave-to="opacity-0 translate-x-4"
          >
            <DialogPanel
              class="flex-shrink min-h-0 h-full w-full transform overflow-hidden text-left align-middle transition-all card flex flex-col !rounded-none origin-right"
            >
              <div
                v-if="title"
                class="px-4 md:px-5 lg:px-6 pt-5 pb-3 flex gap-2 items-center justify-between bg-white shrink-0"
              >
                <div class="flex-1 -mt-0.5">
                  <p v-if="tagline" class="text-sm font-bold text-gray-600 leading-tight">
                    {{ tagline }}
                  </p>
                  <DialogTitle as="h3" class="text-xl leading-tight font-medium text-gray-700">
                    {{ title }}
                  </DialogTitle>
                </div>
                <span class="pb-2">
                  <s-btn
                    @click="close"
                    :aria-label="t('actions.confirm')"
                    color="blue-light"
                    icon="check"
                  >
                    {{ t('actions.confirm') }}
                  </s-btn>
                </span>
              </div>
              <div class="flex-1 min-h-0 flex">
                <slot />
              </div>
            </DialogPanel>
          </TransitionChild>
        </div>
      </div>
    </Dialog>
  </TransitionRoot>
</template>
<script lang="ts" setup>
import {defineProps, defineEmits} from 'vue';
import {useVModel} from '@vueuse/core';
import {Dialog, DialogPanel, DialogTitle, TransitionRoot, TransitionChild} from '@headlessui/vue';
import SBtn from './SBtn.vue';
import {useI18n} from 'vue-i18n';

interface DrawerProps {
  open: boolean;
  title?: string;
  tagline?: string;
}

const props = defineProps<DrawerProps>();

const emit = defineEmits(['update:open', 'close']);
const internalOpen = useVModel(props, 'open', emit);

const close = () => {
  emit('close');
  internalOpen.value = false;
};

const {t} = useI18n({
  useScope: 'local',
  inheritLocale: true,
});
</script>
