<template>
  <task-form :errors="errors" :form="form" class="fieldset">
    <s-input-field
      id="heading"
      label="Heading"
      description="The title displayed to the student."
      :required="false"
      v-model="form.ui.heading"
      :error="errors?.['ui.heading']"
    />
    <s-textarea-field
      id="text"
      label="Question"
      description="The question/instructions displayed to the student."
      v-model="form.ui.question"
      :error="errors?.['ui.question']"
    />
    <s-field class="mt-2 mb-2" id="showCharacterLimit" label="Character Limit to Student Response">
      <div class="flex items-center space-x-2">
        <s-button-toggle
          id="showCharacterLimit"
          :options="[
            {value: null, label: 'No'},
            {value: form.ui.characterLimit ?? 0, label: 'Yes'},
          ]"
          v-model="form.ui.characterLimit"
        />
        <template v-if="form.ui.characterLimit !== null">
          <s-input-field
            id="characterLimit"
            :required="false"
            type="number"
            min="0"
            v-model="form.ui.characterLimit"
          />
        </template>
      </div>
    </s-field>
    <s-textarea-field
      id="rubric"
      label="Overall Rubric"
      description="The general instructions for guiding the overall assessment"
      v-model="form.grader.rubric"
      :error="errors?.['grader.rubric']"
    />
    <div class="flex flex-wrap gap-4 mb-1">
      <s-input-field
        id="scoreMaxValue"
        type="number"
        step="1"
        min="0"
        label="Total Points Available"
        description="This should match with the total score of all criteria items."
        v-model="form.grader.scoreMaxValue"
        :error="errors?.['grader.scoreMaxValue']"
      />
      <s-input-field
        id="Subject"
        label="Subject"
        placeholder="Chemistry"
        description="The subject of the task."
        :required="false"
        v-model="form.grader.subject"
        :error="errors?.['grader.subject']"
      />
    </div>
    <s-field id="showGradeBreakdown" label="Display Grade Breakdown">
      <s-button-toggle
        id="showGradeBreakdown"
        :options="[
          {value: false, label: 'No'},
          {value: true, label: 'Yes'},
        ]"
        v-model="form.grader.showGradeBreakdown"
      />
    </s-field>
    <div class="card padded" v-for="(criteria, index) in form.grader.criteria" :key="index">
      <s-input-field
        id="text"
        label="Name"
        v-model="criteria.name"
        :error="errors?.['grader.criteria.' + index + '.name']"
      />
      <s-textarea-field
        id="text"
        label="Criteria Item Rubric"
        v-model="criteria.criteriaContent"
        :error="errors?.['grader.criteria.' + index + '.criteriaContent']"
      />
      <div class="flex flex-wrap gap-4 mb-1">
        <grade-increment-field
          v-model="criteria.gradeIncrement"
          :errors="errors?.['grader.criteria.' + index + '.gradeIncrement']"
        />
        <s-input-field
          id="score"
          type="number"
          step="1"
          min="0"
          label="Grade Weight"
          v-model="criteria.gradeWeight"
          :error="errors?.['grader.criteria.' + index + '.gradeWeight']"
        />
      </div>
      <s-btn
        @click="form.grader.criteria.splice(index, 1)"
        class="mt-2"
        icon="trash-can"
        size="sm"
        color="danger"
      >
        Remove
      </s-btn>
    </div>
    <s-field id="criteria" label="Criteria Items" :error="errors?.['grader.criteria']">
      <s-btn
        @click.prevent="
          form.grader.criteria.push({
            name: '',
            criteriaContent: '',
            gradeIncrement: 0.25,
            gradeWeight: 1,
          })
        "
        :disabled="form.grader.criteria.length >= 15"
        class="mt-2"
        icon="plus"
        size="sm"
      >
        Add Criteria Item
      </s-btn>
    </s-field>
  </task-form>
</template>
<script lang="ts" setup>
import SInputField from '../../../design-system/SInputField.vue';
import SBtn from '../../../design-system/SBtn.vue';
import STextareaField from '../../../design-system/STextareaField.vue';
import SButtonToggle from '../../../design-system/SButtonToggle.vue';
import SField from '../../../design-system/SField.vue';
import GradeIncrementField from '../../../components/GradeIncrementField.vue';
import TaskForm from '../../../forms/TaskForm.vue';
import {useForm} from '@inertiajs/vue3';
import AiCriteriaQuestionFormDto = App.DTOs.Tasks.AiCriteriaQuestionFormDto;
import AiCriteriaDto = App.DTOs.Tasks.AiCriteriaDto;

const props = defineProps<{
  modelValue?: AiCriteriaQuestionFormDto;
  isCopy?: boolean;
  errors?: Record<string, any>;
}>();

const form = useForm<AiCriteriaQuestionFormDto>(
  () =>
    props.modelValue || {
      title: '',
      textbookLink: null,
      textbookLinkText: null,
      textbookLinkIcon: null,
      youtubeId: null,
      type: 'AiCriteriaQuestion',
      status: 'draft',
      draftable: true,
      ui: {
        heading: '',
        question: '',
        characterLimit: null,
      },
      grader: {
        rubric: '',
        scoreMaxValue: 1,
        criteria: [] as AiCriteriaDto[],
        showGradeBreakdown: false,
        subject: 'Chemistry',
      },
    }
);
</script>
