<template>
  <component :is="aOrLink" v-bind="linkAttrs">
    <slot />
  </component>
</template>
<script lang="ts" setup>
import {computed, defineProps, PropType} from 'vue';
import {Link} from '@inertiajs/vue3';

const props = defineProps({
  external: {
    type: Boolean,
    default: false,
  },
  target: {
    type: String as PropType<'_blank' | '_parent' | '_self' | '_top' | string>,
    default: '',
  },
});

const isHtmlLink = computed(() => props.external);

const linkAttrs = computed(() => ({
  target: isHtmlLink.value && props.target ? props.target : undefined,
  rel: isHtmlLink.value && props.target == '_blank' ? 'noopener noreferrer' : undefined,
}));

const aOrLink = computed(() => (isHtmlLink.value ? 'a' : Link));
</script>
