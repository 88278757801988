<template>
  <s-badge v-if="status === 'draft'" color="yellow">
    {{ t('statuses.draft') }}
  </s-badge>
  <s-badge v-else-if="status === 'ready_for_review'" color="blue">
    {{ t('statuses.readyForReview') }}
  </s-badge>
  <s-badge v-else-if="status === 'archived'" color="gray">
    {{ t('statuses.archived') }}
  </s-badge>
  <s-badge v-else-if="status === 'published'" color="green">
    {{ t('statuses.published') }}
  </s-badge>
</template>
<script setup lang="ts">
import SBadge from '../design-system/SBadge.vue';
import TaskStatus = App.Enums.TaskStatus;
import {useI18n} from 'vue-i18n';

defineProps<{
  status: TaskStatus;
}>();

const {t} = useI18n({
  useScope: 'local',
  inheritLocale: true,
});
</script>
<i18n>
{
  "en": {
    "statuses": {
      "draft": "Draft",
      "readyForReview": "Ready for Review",
      "archived": "Archived",
      "published": "Published"
    }
  },
  "fr": {
    "statuses": {
      "draft": "Brouillon",
      "readyForReview": "Prêt pour révision",
      "archived": "Archivé",
      "published": "Publié"
    }
  }
}
</i18n>
