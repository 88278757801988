<template>
  <i18n-t :keypath="messageKeypath">
    <template #noCutoff>
      <strong>{{ t('noCutoff') }}</strong>
    </template>
    <template #fixedCutoffDate>
      <strong>
        {{ monthDaySometimesYear(fixedCutoffDate, {time: true}) }}
      </strong>
    </template>
    <template #relativeCutoff>
      <strong class="comma-separated" :class="{french}">
        <span v-for="timespan in relativeTimeframes" :key="timespan">
          <span class="uppercase">
            {{ timespan }}
          </span>
        </span>
      </strong>
    </template>
    <template #noPenalty>
      <strong>{{ t('noPenalty') }}</strong>
    </template>
    <template #penalty>
      <strong class="uppercase">{{ penalty }}</strong>
    </template>
    <template #timeblock>
      <strong class="uppercase">
        {{ timeblock }}
      </strong>
    </template>
  </i18n-t>
</template>
<i18n>
{
  "en": {
    "noPenalty.noCutoff": "Late submissions accepted with {noPenalty} with {noCutoff}.",
    "noPenalty.fixedCutoff": "Late submissions accepted with {noPenalty} up to the cutoff date of {fixedCutoffDate}.",
    "noPenalty.relativeCutoff": "Late submissions accepted with {noPenalty} up to the cutoff date of {relativeCutoff} after the due date.",
    "penalty.noCutoff": "Applying a {penalty} penalty for every {timeblock} that the assignment is late with {noCutoff}.",
    "penalty.fixedCutoff": "Applying a {penalty} penalty for every {timeblock} that the assignment is late up to the cutoff date of {fixedCutoffDate}.",
    "penalty.relativeCutoff": "Applying a {penalty} penalty for every {timeblock} that the assignment is late up to the cutoff date of {relativeCutoff} after the due date. ",
    "xDays": "day | {count} days",
    "xHours": "hour | {count} hours",
    "xMinutes": "minute | {count} minutes",
    "xPoint": "{count} point | {count} points",
    "xPercent": "{count}%",
    "noPenalty": "NO PENALTY",
    "noCutoff": "NO CUTOFF DATE"
  },
  "fr": {
    "noPenalty.noCutoff": "Les soumissions tardives sont acceptées {noPenalty} et {noCutoff}.",
    "noPenalty.fixedCutoff": "Les soumissions tardives sont acceptées {noPenalty} jusqu'à la date limite du {fixedCutoffDate}.",
    "noPenalty.relativeCutoff": "Les soumissions tardives sont acceptées {noPenalty} jusqu'à la date limite du {relativeCutoff} après la date d'échéance.",
    "penalty.noCutoff": "Application d'une pénalité de {penalty} pour chaque {timeblock} de retard du devoir {noCutoff}.",
    "penalty.fixedCutoff": "Application d'une pénalité de {penalty} pour chaque {timeblock} de retard du devoir jusqu'à la date limite de {fixedCutoffDate}.",
    "penalty.relativeCutoff": "Application d'une pénalité de {penalty} pour chaque {timeblock} de retard du devoir jusqu'à la date limite de {relativeCutoff} après la date d'échéance.",
    "xDays": "jour | {count} jours",
    "xHours": "heure | {count} heures",
    "xMinutes": "minute | {count} minutes",
    "xPoint": "{count} point | {count} points",
    "xPercent": "{count}%",
    "noPenalty": "SANS PÉNALITÉ",
    "noCutoff": "SANS DATE LIMITE"
  }
}
</i18n>
<script setup lang="ts">
import {computed, defineProps} from 'vue';
import {monthDaySometimesYear} from '../format/dates';
import LateResponsePenaltyType = App.DTOs.LateResponsePenaltyPolicy.LateResponsePenaltyType;
import LateResponsePenaltyFrequencyUnit = App.DTOs.LateResponsePenaltyPolicy.LateResponsePenaltyFrequencyUnit;
import {useI18n} from 'vue-i18n';

export type LateResponsePenaltyPolicy = {
  enabled: boolean;
  penalty: {
    type: LateResponsePenaltyType;
    value: number;
  };
  frequency: {
    type: LateResponsePenaltyFrequencyUnit;
    value: number;
  };
  submissionCutoff: {
    cutoffDateType: 'fixed' | 'relative' | null;
    fixedCutoffDate: {
      date: string;
      time: string;
    };
    relativeCutoffDate: {
      days: number;
      hours: number;
      minutes: number;
    };
  };
};

const props = defineProps<{
  lateResponsePenaltyPolicy: LateResponsePenaltyPolicy;
}>();

const {t, locale} = useI18n({
  useScope: 'local',
  inheritLocale: true,
});

const french = locale.value.startsWith('fr');

const messageKeypath = computed(() => {
  if (!props.lateResponsePenaltyPolicy.enabled) {
    if (props.lateResponsePenaltyPolicy.submissionCutoff.cutoffDateType === 'fixed') {
      return 'noPenalty.fixedCutoff';
    } else if (props.lateResponsePenaltyPolicy.submissionCutoff.cutoffDateType === 'relative') {
      return 'noPenalty.relativeCutoff';
    } else {
      return 'noPenalty.noCutoff';
    }
  } else {
    if (props.lateResponsePenaltyPolicy.submissionCutoff.cutoffDateType === 'fixed') {
      return 'penalty.fixedCutoff';
    } else if (props.lateResponsePenaltyPolicy.submissionCutoff.cutoffDateType === 'relative') {
      return 'penalty.relativeCutoff';
    } else {
      return 'penalty.noCutoff';
    }
  }
});

const penalty = computed(() => {
  const value = props.lateResponsePenaltyPolicy.penalty.value;

  if (props.lateResponsePenaltyPolicy.penalty.type === 'relative') {
    return t('xPercent', value);
  }

  return t('xPoint', value);
});

const timeblock = computed(() => {
  switch (props.lateResponsePenaltyPolicy.frequency.type) {
    case 'days':
      return t('xDays', props.lateResponsePenaltyPolicy.frequency.value);
    case 'hours':
      return t('xHours', props.lateResponsePenaltyPolicy.frequency.value);
    case 'minutes':
      return t('xMinutes', props.lateResponsePenaltyPolicy.frequency.value);
  }
});

const fixedCutoffDate = computed(() => {
  const cutoffDate = props.lateResponsePenaltyPolicy.submissionCutoff.fixedCutoffDate;

  return cutoffDate.date + ' ' + cutoffDate.time;
});

const relativeTimeframes = computed((): string[] => {
  const {days, hours, minutes} =
    props.lateResponsePenaltyPolicy.submissionCutoff.relativeCutoffDate;
  const timeValues = [
    {value: days, label: t('time.xDays', days)},
    {value: hours, label: t('time.xHours', hours)},
    {value: minutes, label: t('time.xMinutes', minutes)},
  ].filter((v) => v.value > 0);
  if (timeValues.length === 0) {
    return [t('time.minutes', 0)];
  }
  return timeValues.map((v) => v.label);
});
</script>
