<template>
  <task-form :form="form" :errors="errors" class="fieldset mb-1">
    <single-calculation-form
      v-model:question="form.ui.question"
      v-model:prepend-text="form.ui.prependText"
      v-model:append-text="form.ui.appendText"
      v-model:fallback-feedback="form.grader.fallbackFeedback"
      v-model:calculations="form.grader.calculations"
      v-model:tolerance="form.grader.tolerance"
      v-model:subexpressions="form.grader.subexpressions"
      :errors="errors?.grader"
    />

    <task-variable-form
      v-model:variables="form.grader.variables"
      :errors="errors?.grader?.variables"
    />
  </task-form>
</template>

<script lang="ts" setup>
import TaskVariableForm from '../DataDrivenTask/Variables/TaskVariableForm.vue';
import {default as SingleCalculationForm} from '../DataDrivenTask/ComponentForms/SingleCalculation.vue';
import {calculationFactory} from '../../../util/calculation';
import {useValidationErrors} from '../../../util/validation-errors';
import TaskForm from '../../../forms/TaskForm.vue';
import {useForm} from '@inertiajs/vue3';
import SinglePartCalculationFormDto = App.DTOs.Tasks.SinglePartCalculationFormDto;

const props = defineProps<{
  modelValue?: SinglePartCalculationFormDto;
  isCopy?: boolean;
  errors?: Record<string, any>;
}>();

const {errors} = useValidationErrors(props, 'errors');

const form = useForm<SinglePartCalculationFormDto>(
  () =>
    props.modelValue || {
      type: 'SinglePartCalculation',
      title: '',
      status: 'draft',
      ui: {
        question: '',
        prependText: null,
        appendText: null,
      },
      grader: {
        tolerance: {
          type: 'relative',
          value: '0.02',
        },
        calculations: [calculationFactory()],
        fallbackFeedback: null,
        variables: [],
        subexpressions: [],
      },
      draftable: true,
      textbookLink: null,
      textbookLinkText: null,
      textbookLinkIcon: null,
      youtubeId: null,
    }
);
</script>
