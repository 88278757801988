<template>
  <div class="">
    <h3 class="heading md flex items-center gap-1 text-gray-600 mb-2">
      <s-icon name="plus-circle" size="20" class="opacity-70" />
      <i18n-t keypath="heading" :plural="rows.length">
        <template #count>
          <span class="font-black">
            {{ rows.length }}
          </span>
        </template>
      </i18n-t>
    </h3>
    <div class="flex flex-col gap-4">
      <diff-change-set type="none" :rows="rows" />
    </div>
  </div>
</template>
<script setup lang="ts">
import DiffChangeSet from './DiffChangeSet.vue';
import RosterChangeDto = App.DTOs.Roster.RosterChangeDto;
import {computed} from 'vue';
import {useI18n} from 'vue-i18n';
import SIcon from '../../../design-system/SIcon.vue';

const props = defineProps<{
  unchanged: RosterChangeDto[];
}>();

const rows = computed<Record<string, any>[]>(() => {
  return props.unchanged.map((unchanged) => {
    return {
      [t('row')]: unchanged.row,
      [t('email')]: unchanged.email,
      [t('section')]: unchanged.sectionName,
    };
  });
});
const {t} = useI18n({
  useScope: 'local',
  inheritLocale: true,
});
</script>
<i18n>
{
  "en": {
    "heading": "{count} Unchanged"
  },
  "fr": {
    "heading": "{count} Inchangé"
  }
}
</i18n>
