<template>
  <s-tooltip color="white">
    <div
      class="inline-block border border-gray-400 bg-gray-50 hover:white rounded-lg h-fit w-fit px-2 text-sm font-mono cursor-pointer"
    >
      {{ shared.name }}
    </div>
    <template #tooltip>
      <div>
        <div class="flex gap-2 justify-between mb-2">
          <h2>
            <code>{{ shared.name }}</code>
          </h2>
          <s-badge>{{ shared.sourceType }}</s-badge>
        </div>
        <p v-if="shared.sourceType === 'input' && partName">
          <i18n-t keypath="description">
            <template #partName>
              <strong>
                <task-rubric-text :text="partName" :rubric="rubric" />
              </strong>
            </template>
          </i18n-t>
        </p>
      </div>
    </template>
  </s-tooltip>
</template>
<script setup lang="ts">
import SharedValue = App.DTOs.Rubrics.SharedValue;
import SBadge from '../design-system/SBadge.vue';
import Rubric = App.DTOs.Rubrics.Rubric;
import {computed} from 'vue';
import TaskRubricText from '../components/TaskRubricText.vue';
import STooltip from '../design-system/STooltip.vue';
import {useI18n} from 'vue-i18n';

const {rubric, shared} = defineProps<{
  rubric: Rubric;
  shared: SharedValue;
}>();

const partName = computed(() => {
  return rubric.parts.find((part) => part.partId === shared.sourcePartId)?.label;
});

const {t} = useI18n({
  inheritLocale: true,
  useScope: 'local',
});
</script>
<i18n>
{
  "en": {
    "description": "This value will be replaced with the students input to {partName}"
  },
  "fr": {
    "description": "Cette valeur sera remplacée par l'entrée de l'étudiant pour {partName}"
  }
}
</i18n>
