<template>
  <div class="card border border-gray-150">
    <div class="rounded-t-lg px-5 md:px-7 lg:px-8 py-4 md:py-6 bg-gray-50">
      <div
        v-if="!single"
        class="flex justify-between gap-3 items-start text-base leading-tight text-gray-600 mb-4"
      >
        <p class="flex-1 flex flex-wrap items-start gap-x-1.5 gap-y-1">
          <s-icon name="account-group" class="opacity-70" width="18" height="18" />
          <span class="font-medium">{{ section }}</span>
          <span class="opacity-70">&ndash;</span>
          <span class="leading-tight comma-separated" :class="{french}">
            <span
              class="animate-slide-in-up"
              v-for="participant in participants"
              :key="participant"
            >
              {{ participant }}
            </span>
          </span>
        </p>
        <div v-if="totalExtensions > 1" class="text-gray-600 font-medium text-sm whitespace-nowrap">
          {{ index + 1 }}
          <span class="opacity-50">{{ t('of') }}</span>
          {{ totalExtensions }}
        </div>
      </div>
      <div class="flex justify-start items-center mb-5">
        <s-button-toggle
          name="extensionMode"
          :options="[
            {value: 'dueDate', label: t('dueDate')},
            {value: 'startDate', label: t('startDate')},
            {value: 'both', label: t('both')},
          ]"
          v-model="extensionMode"
          :id="'extensionMode' + uuid"
        />
      </div>
      <div class="flex justify-start items-center">
        <s-button-toggle
          name="newDateType"
          :options="[
            {value: 'relative', label: t('relative')},
            {value: 'fixed', label: t('fixed')},
          ]"
          v-model="newDateType"
          :id="'newDateType' + uuid"
        />
      </div>
    </div>
    <div
      v-if="newDateType === 'relative'"
      class="px-5 md:px-7 lg:px-8 pb-4 md:pb-6 bg-gray-50 border-gray-150"
    >
      <div class="w-full grid grid-cols-2 lg:grid-cols-3 justify-items-stretch gap-4">
        <span class="col-span-2 lg:col-span-1">
          <s-input-field
            id="relativeDaysValue"
            v-model.number="relativeDaysValue"
            type="number"
            :suffix="t('timeUnits.days')"
            :aria-label="t('ariaLabels.relDays')"
          />
        </span>
        <s-input-field
          id="relativeHoursValue"
          v-model.number="relativeHoursValue"
          type="number"
          :suffix="t('timeUnits.hours')"
          :aria-label="t('ariaLabels.relHours')"
        />
        <s-input-field
          id="relativeMinutesValue"
          v-model.number="relativeMinutesValue"
          type="number"
          :suffix="t('timeUnits.minutes')"
          :aria-label="t('ariaLabels.relMinutes')"
        />
      </div>
    </div>
    <div
      v-if="hasStartDate && newDateType === 'fixed'"
      class="px-5 md:px-7 lg:px-8 pb-4 md:pb-6 bg-gray-50 border-gray-150"
    >
      <p class="font-bold text-gray-600 leading-tight text-sm mb-1.5">
        {{ t('modifiedStartDate') }}
      </p>
      <div class="flex items-center justify-between w-full gap-3">
        <div class="flex-1">
          <s-input-field
            v-model="fixedModifiedStartDate"
            type="date"
            :aria-label="t('modifiedStartDate')"
            :id="'fixedModifiedStartDate' + uuid"
          />
        </div>
        <div class="flex-1">
          <s-input-field
            v-model="fixedModifiedStartTime"
            type="time"
            :aria-label="t('modifiedStartTime')"
            :id="'fixedModifiedStartTime' + uuid"
          />
        </div>
      </div>
    </div>
    <div
      v-if="hasStartDate"
      class="flex flex-wrap items-center space-between w-full gap-3 px-5 md:px-7 lg:px-8 py-4 md:py-6 bg-white border-t border-gray-150"
      :class="{
        'rounded-b-lg': !hasDueDate,
      }"
    >
      <p class="flex-1 flex flex-col text-gray-600">
        <strong class="flex items-center gap-1 mb-0.5 font-medium text-gray-500">
          <s-icon name="calendar-blank" class="opacity-70" size="18" />
          {{ t('originalStartDate') }}
        </strong>
        <span class="text-xl leading-snug">
          {{ monthDayYear(currentStartDate) }}
        </span>
        <span class="text-base leading-snug">
          {{ format(currentStartDate, 'p') }}
        </span>
      </p>
      <p v-if="modifiedStartDate" class="flex-1 flex flex-col text-green-700">
        <strong class="flex items-center gap-1 mb-0.5 font-medium">
          <s-icon name="calendar-arrow-right" class="opacity-70" size="18" />
          {{ t('modifiedStartDate') }}
        </strong>
        <span class="text-xl leading-snug">
          {{ monthDayYear(modifiedStartDate) }}
        </span>
        <span class="text-base leading-snug">
          {{ format(modifiedStartDate, 'p') }}
        </span>
      </p>
    </div>
    <div
      v-if="hasDueDate && newDateType === 'fixed'"
      class="px-5 md:px-7 lg:px-8 bg-gray-50 border-gray-150"
      :class="{
        'border-t': hasStartDate,
        'py-4': hasStartDate,
        'md:py-6': hasStartDate,
        'pb-4': !hasStartDate,
        'md:pb-6': !hasStartDate,
      }"
    >
      <p class="font-bold text-gray-600 leading-tight text-sm mb-1.5">
        {{ t('modifiedDueDate') }}
      </p>
      <div class="flex items-center justify-between w-full gap-3">
        <div class="flex-1">
          <s-input-field
            v-model="fixedModifiedDueDate"
            type="date"
            :aria-label="t('modifiedDueDate')"
            :id="'fixedModifiedDueDate' + uuid"
          />
        </div>
        <div class="flex-1">
          <s-input-field
            v-model="fixedModifiedDueTime"
            type="time"
            :aria-label="t('ariaLabels.modifiedDueTime')"
            :id="'fixedModifiedDueTime' + uuid"
          />
        </div>
      </div>
    </div>
    <div
      v-if="hasDueDate"
      class="flex flex-wrap items-center space-between w-full gap-3 px-5 md:px-7 lg:px-8 py-4 md:py-6 rounded-b-lg bg-white border-t border-gray-150"
    >
      <p class="flex-1 flex flex-col text-gray-600">
        <strong class="flex items-center gap-1 mb-0.5 font-medium text-gray-500">
          <s-icon name="calendar-blank" class="opacity-70" size="18" />
          {{ t('originalDueDate') }}
        </strong>
        <span class="text-xl leading-snug">
          {{ monthDayYear(currentDueDate) }}
        </span>
        <span class="text-base leading-snug">
          {{ format(currentDueDate, 'p') }}
        </span>
      </p>
      <p v-if="modifiedDueDate" class="flex-1 flex flex-col text-green-700">
        <strong class="flex items-center gap-1 mb-0.5 font-medium">
          <s-icon name="calendar-arrow-right" class="opacity-70" size="18" />
          {{ t('modifiedDueDate') }}
        </strong>
        <span class="text-xl leading-snug">
          {{ monthDayYear(modifiedDueDate) }}
        </span>
        <span class="text-base leading-snug">
          {{ format(modifiedDueDate, 'p') }}
        </span>
      </p>
    </div>
  </div>
</template>
<script setup lang="ts">
import {computed, PropType, ref, toRef, watch, watchEffect} from 'vue';
import SInputField from '../../design-system/SInputField.vue';
import SButtonToggle from '../../design-system/SButtonToggle.vue';
import {useRelativeDateRef} from '../../composables/dates/useRelativeDateRef';
import {v4 as uuidV4} from 'uuid';
import SIcon from '../../design-system/SIcon.vue';
import {useI18n} from 'vue-i18n';
import {format, monthDayYear} from '../../format/dates';

const {t, locale} = useI18n({useScope: 'local', inheritLocale: true});

type CurrentDueDate = {
  date: string;
  timezone: string;
  timezone_type: number;
};

const props = defineProps({
  currentStartDate: {
    type: String,
    required: true,
  },
  currentDueDate: {
    type: String,
    required: true,
  },
  section: {
    type: String,
    required: true,
  },
  participants: {
    type: Array as PropType<string[]>,
    required: true,
  },
  index: {
    type: Number,
    required: true,
  },
  totalExtensions: {
    type: Number,
    required: true,
  },
  modelValue: {
    type: Object as PropType<{newStartDate: string | null; newDueDate: string | null}>,
    required: true,
  },
  single: {
    type: Boolean,
    required: false,
    default: false,
  },
  mode: {
    type: String as PropType<'dueDate' | 'startDate' | 'both'>,
    default: 'dueDate',
  },
  dateType: {
    type: String as PropType<'fixed' | 'relative'>,
    default: 'relative',
  },
});

const french = locale.value.startsWith('fr');

const extensionMode = ref<'dueDate' | 'startDate' | 'both'>(props.mode);
watch(toRef(props, 'mode'), (newMode) => {
  extensionMode.value = newMode;
});

const newDateType = ref<'relative' | 'fixed'>(props.dateType);
watch(toRef(props, 'dateType'), (newType) => {
  newDateType.value = newType;
});

const hasStartDate = computed(() => extensionMode.value !== 'dueDate');
const hasDueDate = computed(() => extensionMode.value !== 'startDate');

const startingStartDate = new Date(
  props.modelValue?.newStartDate ? props.modelValue?.newStartDate : props.currentStartDate
);
const startingDueDate = new Date(
  props.modelValue?.newDueDate ? props.modelValue?.newDueDate : props.currentDueDate
);

const relativeDaysValue = ref<number>(0);
const relativeHoursValue = ref<number>(0);
const relativeMinutesValue = ref<number>(0);

const fixedModifiedStartDate = ref<string>(format(startingStartDate, 'yyyy-MM-dd'));
const fixedModifiedStartTime = ref<string>(format(startingStartDate, 'HH:mm'));
const fixedModifiedDueDate = ref<string>(format(startingDueDate, 'yyyy-MM-dd'));
const fixedModifiedDueTime = ref<string>(format(startingDueDate, 'HH:mm'));

const relativeModifiedStartDate = useRelativeDateRef(
  toRef(props, 'currentStartDate'),
  relativeDaysValue,
  relativeHoursValue,
  relativeMinutesValue
);
const relativeModifiedDueDate = useRelativeDateRef(
  toRef(props, 'currentDueDate'),
  relativeDaysValue,
  relativeHoursValue,
  relativeMinutesValue
);

const modifiedStartDate = computed(() => {
  if (extensionMode.value === 'dueDate') {
    return null;
  }

  return newDateType.value === 'relative'
    ? relativeModifiedStartDate.value
    : new Date(`${fixedModifiedStartDate.value}T${fixedModifiedStartTime.value}`).toISOString();
});
const modifiedDueDate = computed(() => {
  if (extensionMode.value === 'startDate') {
    return null;
  }
  return newDateType.value === 'relative'
    ? relativeModifiedDueDate.value
    : new Date(`${fixedModifiedDueDate.value}T${fixedModifiedDueTime.value}`).toISOString();
});

const emit = defineEmits(['update:modelValue']);

const emitDates = () => {
  emit('update:modelValue', {
    newStartDate: modifiedStartDate.value,
    newDueDate: modifiedDueDate.value,
  });
};

watch([modifiedStartDate, modifiedDueDate], emitDates);

const uuid = uuidV4();
</script>
<i18n>
  {
    "en": {
      "dueDate": "Due Date",
      "startDate": "Start Date",
      "of": "of",
      "modifiedDueDate": "Modified Due Date",
      "modifiedStartDate": "Modified Start Date",
      "modifiedStartTime": "Modified Start Time",
      "originalDueDate": "Original Due Date",
      "originalStartDate": "Original Start Date",
      "both": "Both",
      "relative": "Relative",
      "fixed": "Fixed",
      "timeUnits": {
        "days": "Days",
        "hours": "Hours",
        "minutes": "Minutes"
      },
      "ariaLabels": {
        "relDays": "Relative Days",
        "relHours": "Relative Hours",
        "relMinutes": "Relative Minutes",
        "modifiedDueTime": "Modified Due Time"
      }
    },
    "fr": {
      "dueDate": "Date d'échéance",
      "startDate": "Date de début",
      "of": "sur",
      "modifiedDueDate": "Date d'échéance modifiée",
      "modifiedStartDate": "Date de début modifiée",
      "modifiedStartTime": "Heure de début modifiée",
      "originalDueDate": "Date d'échéance initiale",
      "originalStartDate": "Date de début initiale",
      "both": "Les deux",
      "relative": "Relatif/Relative",
      "fixed": "Fixé/Fixée",
      "timeUnits": {
        "days": "Jours",
        "hours": "Heures",
        "minutes": "Minutes"
      },
      "ariaLabels": {
        "relDays": "Jours relatifs",
        "relHours": "Heures relatives",
        "relMinutes": "Minutes relatives",
        "modifiedDueTime": "Heure d'échéance modifiée"
      }
    },
  }
</i18n>
