<template>
  <div
    class="flex flex-col gap-1.5 px-5 md:px-7 pt-3.5 pb-5 rounded-b-lg bg-gradient-to-b from-gray-100 via-gray-50 to-gray-50 border-t border-gray-150 border-t-gray-200"
  >
    <div class="w-full flex items-center flex-wrap justify-between gap-x-2">
      <span
        v-if="taskResponses.length > 0"
        class="text-sm flex gap-2 items-center whitespace-nowrap"
      >
        <span class="flex items-center gap-1 lfont-medium text-gray-600">
          <i18n-t keypath="responseXofY">
            <template #index>
              <strong class="text-gray-800">{{ attemptNumber }}</strong>
            </template>
            <template #total>
              <strong class="text-gray-800">{{ taskResponses.length }}</strong>
            </template>
          </i18n-t>
          <template v-if="selectedResponse?.isDraft">
            &ndash;
            <strong
              class="text-gray-600 uppercase text-xs font-bold tracking-wide flex items-center gap-1"
            >
              <s-icon name="pencil" class="opacity-70" size="15" />
              {{ t('draft') }}
            </strong>
          </template>
        </span>

        <span
          v-if="selectedResponse?.id && isTaskResponseEffective(selectedResponse.id)"
          class="uppercase text-sm font-bold text-blue-600 flex items-center gap-0.5"
        >
          <s-icon name="check-bold" class="opacity-70" size="16" />
          {{ t('badges.effective') }}
        </span>

        <s-badge
          v-if="!selectedResponse?.isDraft && responseIsLate && dueDate"
          size="sm"
          color="red"
        >
          <span class="font-bold text-red-700 tracking-wide flex items-center gap-0.5">
            <s-icon name="clock-alert-outline" class="opacity-70" size="14" />
            {{
              t('badges.xLate', {
                timeframe: formatDistance(getResponseTimestamp(response), dueDate, {
                  addSuffix: false,
                }),
              })
            }}
          </span>
        </s-badge>
      </span>
      <div class="flex-1"></div>
      <span class="text-sm flex gap-1 items-center whitespace-nowrap">
        <span class="text-gray-700 font-bold">{{ t('task') }} {{ taskNumber }}</span>
        <span class="font-medium text-gray-600">&ndash;</span>
        <span class="font-medium text-gray-600">
          {{ t('badges.worthPoints', {points: taskWeight}) }}
        </span>
      </span>
    </div>
    <div
      v-if="response && !response?.isSelfSubmitted"
      class="text-sm text-gray-600 font-medium leading-tight mb-1.5"
    >
      <i18n-t keypath="submittedByPersonOn">
        <template #submittedBy>
          <span class="inline font-bold text-gray-600">
            {{ selectedResponse?.submittedBy.firstName }}
            {{ selectedResponse?.submittedBy.lastName }}
          </span>
        </template>

        <template #onBehalfOf>
          <span class="inline font-bold text-gray-600">
            {{ selectedResponse?.user.firstName }}
            {{ selectedResponse?.user.lastName }}
          </span>
        </template>

        <template #submissionTime>
          <span class="inline font-bold text-gray-600">
            {{
              monthDaySometimesYear(selectedResponse?.submittedAt, {
                time: true,
              })
            }}
          </span>
        </template>
      </i18n-t>
    </div>
    <div class="flex gap-3 flex-wrap items-center justify-between">
      <response-listbox
        v-if="taskResponses.length"
        :draft-task-ids="draftTaskIds"
        :task-responses="taskResponses"
        v-model="selectedResponse"
        :student-assignment-task-grades="studentAssignmentTaskGrades"
        :attempted-task-ids="attemptedTaskIds"
        :due-date="dueDate"
        :assignment-extension="assignmentExtension"
      />
      <div v-else>
        <span
          class="px-4 py-1.5 text-sm leading-tight font-medium bg-gray-100 border border-gray-200 italic rounded-full text-gray-600"
        >
          {{ t('noResponses') }}
        </span>
      </div>
      <slot name="actions" />
    </div>
  </div>
</template>

<script setup lang="ts">
import {computed} from 'vue';
import {Grade} from '../../../types/entities/base-grade';
import {Task, TaskResponse} from '../../../types/entities/tasks';
import SBadge from '../../../design-system/SBadge.vue';
import ResponseListbox from './ResponseListbox.vue';
import {useVModel} from '@vueuse/core';
import {formatDistance, monthDaySometimesYear} from '../../../format/dates';
import {getResponseTimestamp, isResponseLate} from '../../../util/response-timestamps';
import {AssignmentExtension} from '../../../types/entities/assignment-extension';
import SIcon from '../../../design-system/SIcon.vue';
import {Course} from '../../../types/entities/course';
import {Assignment} from '../../../types/entities/assignment';
import {useI18n} from 'vue-i18n';

type PartialTaskResponse = {id: number} & Pick<
  TaskResponse,
  | 'createdAt'
  | 'updatedAt'
  | 'submittedAt'
  | 'isDraft'
  | 'hasAttachments'
  | 'isSelfSubmitted'
  | 'lastSavedAt'
  | 'submittedBy'
  | 'user'
>;

const props = defineProps<{
  course: Course;
  assignment: Assignment;
  dueDate: string | null | undefined;
  assignmentExtension?: AssignmentExtension | null;
  assignmentTasks: {
    id: number;
    orderingIndex: number;
    pointValue: number;
  }[];

  // Student
  attemptedTaskIds: number[];
  draftTaskIds: number[];

  // Task
  task: Task;
  taskResponses: PartialTaskResponse[];
  studentAssignmentTaskGrades: Grade[];

  // Response
  response: TaskResponse | null;

  selectedResponse: PartialTaskResponse | null;
}>();

const {t} = useI18n({
  inheritLocale: true,
  useScope: 'local',
});

const selectedResponse = useVModel(props, 'selectedResponse');

const taskNumber = computed(() => {
  return props.assignmentTasks.findIndex((task) => task.id === props.task.id) + 1;
});

const currentTask = computed(() => props.assignmentTasks[taskNumber.value - 1]);

const taskWeight = computed(() => currentTask.value?.pointValue || 0);

const attemptNumber = computed(() => {
  if (!props.response) {
    return 1;
  }

  return (
    props.taskResponses.length -
    props.taskResponses.findIndex((response) => response.id === props.response?.id)
  );
});

const responseIsLate = computed(() =>
  isResponseLate(props.dueDate, props.response, props.assignmentExtension)
);

function isTaskResponseEffective(responseId: number) {
  const grade = props.studentAssignmentTaskGrades
    .filter((grade) => grade.responseId === responseId)
    .sort((a, b) => b.id - a.id)[0];

  return grade?.isEffective;
}
</script>
<i18n>
{
  "en": {
    "badges": {
      "effective": "Active Grade",
      "xLate": "{timeframe} late",
      "worthPoints": "Worth {points} points"
    },
    "noResponses": "No responses yet",
    "submittedByPersonOn": "Submitted by {submittedBy} on behalf of {onBehalfOf} on {submissionTime}",
    "responseXofY": "Response {index} of {total}"
  },
  "fr": {
    "badges": {
      "effective": "Note active",
      "xLate": "{timeframe} en retard",
      "worthPoints": "Vaut {points} points"
    },
    "noResponses": "Aucune réponse pour le moment",
    "submittedByPersonOn": "Soumis par {submittedBy} au nom de {onBehalfOf} le {submissionTime}",
    "responseXofY": "Réponse {index} sur {total}"
  }
}
</i18n>
