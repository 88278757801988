<template>
  <Head :title="t('page.title')"></Head>
  <div class="bg-gradient-to-b from-blue-50 via-transparent to-transparent">
    <div role="main" class="p-4 lg:p-8 max-w-xl mx-auto flex flex-col gap-5">
      <form
        aria-labelledby="form-title"
        @submit.prevent="form.post(route('password.update'))"
        class="flex flex-col shadow-card rounded-lg"
      >
        <h1
          id="form-title"
          class="bg-gradient-to-t from-white/70 to-white/30 flex items-center justify-center p-6 rounded-t-lg border border-white/50 border-b-gray-100"
        >
          <s-logo role="presentation" type="image" class="logo h-16 lg:h-[4.5rem] w-auto" />
          <span class="sr-only">{{ t('page.title') }}</span>
        </h1>
        <div
          class="px-5 lg:px-8 py-5 lg:py-8 bg-gradient-to-t from-gray-50 to-white rounded-b-lg flex flex-col gap-5"
        >
          <p class="text-lg">
            {{ t('page.instructions') }}
          </p>
          <s-input-field
            id="email"
            :label="t('email')"
            :placeholder="t('email')"
            v-model="form.email"
            required
            :error="form.errors.email"
          >
            <template v-slot:prefix>
              <mdicon
                name="email"
                role="presentation"
                class="opacity-70 group-hover:opacity-50 group-focus-within:text-blue-600 transition"
              />
            </template>
          </s-input-field>
          <s-input-field
            id="password"
            type="password"
            :label="t('password')"
            :placeholder="t('password')"
            v-model="form.password"
            required
            :error="form.errors.password"
          >
            <template v-slot:prefix>
              <mdicon
                name="lock"
                role="presentation"
                class="opacity-70 group-hover:opacity-50 group-focus-within:text-blue-600 transition"
              />
            </template>
          </s-input-field>
          <s-input-field
            id="retypedPassword"
            type="password"
            :label="t('passwordConfirmation')"
            :placeholder="t('form.retypePassword')"
            v-model="form.password_confirmation"
            required
            :error="form.errors.password_confirmation"
          >
            <template v-slot:prefix>
              <mdicon
                name="lock"
                role="presentation"
                class="opacity-70 group-hover:opacity-50 group-focus-within:text-blue-600 transition"
              />
            </template>
          </s-input-field>

          <div class="mt-1 flex flex-wrap gap-4">
            <s-btn :href="route('login')" color="secondary" class="flex-1">
              {{ t('actions.backToLogin') }}
              <mdicon name="keyboard-return" class="opacity-70" role="presentation" />
            </s-btn>
            <s-btn
              type="submit"
              color="primary"
              :loading="form.processing"
              :disabled="form.processing"
              class="flex-1"
            >
              {{ t('actions.updatePassword') }}
              <mdicon
                v-if="!form.processing"
                name="lock-reset"
                class="opacity-70"
                role="presentation"
              />
              <mdicon v-else name="loading" class="opacity-70 animate-spin" role="presentation" />
            </s-btn>
          </div>
        </div>
      </form>
      <div class="flex gap-x-3 gap-y-2 flex-wrap">
        <s-link :href="route('support')" class="link-subtle flex items-center gap-1.5">
          <mdicon role="presentation" name="chat-question" class="opacity-70" />
          {{ t('links.help') }}
        </s-link>
      </div>
      <div
        v-if="flash['error']"
        class="px-4 py-2 shadow-sm border text-base leading-tight rounded flex items-center gap-2 bg-red-100 text-red-800 border-red-200"
      >
        <mdicon name="email-remove" class="opacity-70" size="22" aria-hidden="true" />
        {{ flash['error'] }}
      </div>
    </div>
  </div>
</template>
<script setup lang="ts">
import {Head, useForm} from '@inertiajs/vue3';
import {useI18n} from 'vue-i18n';
import {route} from 'ziggy-js';
import PasswordResetDto = App.DTOs.Auth.PasswordResetDto;
import SInputField from '../../design-system/SInputField.vue';
import SLink from '../../components/SLink.vue';
import SBtn from '../../design-system/SBtn.vue';
import SLogo from '../../design-system/SLogo.vue';
import UnauthorizedLayout from '../../layouts/UnauthorizedLayout.vue';
import loginTranslations from '../Login/login.json';

defineOptions({layout: UnauthorizedLayout});

const props = defineProps<{
  token: string;
  flash: Record<string, string>;
}>();

const form = useForm<PasswordResetDto>({
  email: '',
  password: '',
  password_confirmation: '',
  token: props.token,
});

const {t} = useI18n({
  inheritLocale: true,
  messages: loginTranslations,
});
</script>
<i18n>
{
  "en": {
    "page": {
      "title": "Reset Password",
      "instructions": "Enter your email address and a new password to reset the password for your account."
    },
  },
  "fr": {
    "page": {
      "title": "Réinitialiser le mot de passe",
      "instructions": "Entrez votre adresse e-mail et un nouveau mot de passe pour réinitialiser le mot de passe de votre compte."
    },
  }
}
</i18n>
