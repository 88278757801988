<template>
  <Head :title="`Admin - Users - ${user.email}`"></Head>
  <SPageBlock>
    <SPageHeader :heading="t('page.heading', user)" class="mb-4" />
    <form
      @submit.prevent="form.put(route('admin.users.update', {user: user.id}))"
      class="flex flex-col gap-4"
    >
      <s-input-field
        id="firstName"
        :label="t('firstName')"
        v-model="form.firstName"
        required
        :error="form.errors.firstName"
      />
      <s-input-field
        id="lastName"
        :label="t('lastName')"
        v-model="form.lastName"
        required
        :error="form.errors.lastName"
      />

      <s-phone-number-input
        id="phoneNumber"
        label="Phone Number"
        v-model="form.phoneNumber"
        :error="form.errors.phoneNumber"
      />

      <s-input-field
        id="studentId"
        :label="t('studentId')"
        v-model="form.studentId"
        :error="form.errors.studentId"
      />

      <s-select-field
        id="institutionId"
        :label="t('institution')"
        v-model="form.institutionId"
        :error="form.errors.institutionId"
        required
      >
        <option :value="null">None</option>
        <option v-for="institution in institutions" :value="institution.id">
          {{ institution.name }}
        </option>
      </s-select-field>

      <s-btn type="submit" color="primary" :loading="form.processing" :disabled="form.processing">
        {{ t('form.save') }}
      </s-btn>
    </form>
  </SPageBlock>
</template>
<script setup lang="ts">
import {Head, useForm} from '@inertiajs/vue3';
import {User} from '../../../types/entities/user';
import {useBreadcrumbs} from '../../../composables/useBreadcrumbs';
import SPageBlock from '../../../design-system/SPageBlock.vue';
import SInputField from '../../../design-system/SInputField.vue';
import SBtn from '../../../design-system/SBtn.vue';
import SPageHeader from '../../../design-system/SPageHeader.vue';
import {useI18n} from 'vue-i18n';
import SPhoneNumberInput from '../../../design-system/SPhoneNumberInput.vue';
import {route} from 'ziggy-js';
import UserUpdateDto = App.DTOs.Users.UserUpdateDto;
import SSelectField from '../../../design-system/SSelectField.vue';
import {Institution} from '../../../types/entities/institution';

const props = defineProps<{
  user: User;
  institutions: Institution[];
}>();

type FormData = UserUpdateDto & {
  phoneNumber: string; // This is a string because the phone number input component doesn't work with null
};

const form = useForm<FormData>({
  firstName: props.user.firstName,
  lastName: props.user.lastName,
  studentId: props.user.studentId,
  phoneNumber: props.user.phoneNumber || '',
  institutionId: props.user.institutionId || null,
});

const {t} = useI18n({
  inheritLocale: true,
});

useBreadcrumbs([
  {label: 'Admin', href: route('admin.index')},
  {label: 'Users', href: route('admin.users.index')},
  {label: props.user.email, href: route('admin.users.show', {id: props.user.id})},
]);
</script>
<i18n>
{
  "en": {
    "page": {
      "heading": "{email}"
    }
  }
}
</i18n>
