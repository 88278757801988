<template>
  <Head :title="`${t('page.title')} - ${course.code}`" />
  <s-page-block>
    <s-page-header :heading="t('page.title')" class="mb-3" />
    <div class="pb-5 flex flex-wrap-reverse gap-x-3 gap-y-4 items-end justify-between">
      <s-search-section
        class="flex-grow"
        route-name="courses.assignment.index"
        :label="t('search.placeholder')"
      />
    </div>
    <section class="@container flex flex-col gap-5">
      <s-placeholder v-if="assignments.data.length === 0">
        <template v-if="route().params.search">
          {{ t('search.noResults') }}
        </template>
        <template v-else>
          {{ t('page.noAssignments') }}
        </template>
      </s-placeholder>
      <div v-else class="flex flex-col gap-4">
        <student-assignment-card
          v-for="assignment in assignments?.data"
          :key="assignment.id"
          :assignment="assignment"
          :grade="assignment.grade"
          :course="course"
        />
      </div>
      <s-paginator :paginator="assignments" preserve-state :only="['assignments']" />
    </section>
  </s-page-block>
</template>
<script setup lang="ts">
import {Head} from '@inertiajs/vue3';
import {LaravelPaginator} from '../../types/laravel-paginator';
import SPaginator from '@/design-system/SPaginator.vue';
import SPageHeader from '@/design-system/SPageHeader.vue';
import SPlaceholder from '@/design-system/SPlaceholder.vue';
import {Course} from '../../types/entities/course';
import SSearchSection from '../../design-system/SSearchSection.vue';
import SPageBlock from '../../design-system/SPageBlock.vue';
import StudentCourseAssignmentRowDto = App.DTOs.CourseAssignments.StudentCourseAssignmentRowDto;
import {useI18n} from 'vue-i18n';
import StudentAssignmentCard from './Index/StudentAssignmentCard.vue';
import {route} from 'ziggy-js';

defineProps<{
  course: Course;
  assignments: LaravelPaginator<StudentCourseAssignmentRowDto>;
}>();

const {t} = useI18n({
  useScope: 'local',
  inheritLocale: true,
});
</script>
<i18n>
{
  "en": {
    "page": {
      "title": "Assignments",
      "noAssignments": "You do not have any assignments at the moment."
    },
    "search": {
      "noResults": "No assignments found for your search.",
      "placeholder": "Search assignments by title"
    }
  },
  "fr": {
    "page": {
      "title": "Devoirs",
      "noAssignments": "Vous n'avez actuellement aucun devoir."
    },
    "search": {
      "noResults": "Aucun devoir trouvé pour votre recherche.",
      "placeholder": "Rechercher des devoirs par titre"
    }
  }
}
</i18n>
