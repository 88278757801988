<template>
  <div class="flex flex-wrap gap-4">
    <s-input-field
      type="number"
      step="0.0000001"
      min="0"
      v-model="inputTokenCost"
      id="inputCost"
      label="Input Token Cost:"
    />
    <s-input-field
      type="number"
      step="0.0000001"
      min="0"
      v-model="outputTokenCost"
      id="outputCost"
      label="Output Token Cost:"
    />
  </div>
  <v-chart class="chart" :option="option" autoresize />
</template>

<script setup lang="ts">
import {computed, ComputedRef, ref} from 'vue';
import type {ComposeOption} from 'echarts';
import {use} from 'echarts/core';
import {ScatterChart, ScatterSeriesOption} from 'echarts/charts';
import {CanvasRenderer} from 'echarts/renderers';
import VChart from 'vue-echarts';

import {
  GridComponent,
  GridComponentOption,
  LegendComponent,
  LegendComponentOption,
  TimelineComponent,
  TimelineComponentOption,
  TitleComponent,
  TitleComponentOption,
  TooltipComponent,
  TooltipComponentOption,
} from 'echarts/components';

use([
  TimelineComponent,
  TitleComponent,
  LegendComponent,
  ScatterChart,
  CanvasRenderer,
  TooltipComponent,
  GridComponent,
]);

type ECOption = ComposeOption<
  | ScatterSeriesOption
  | TimelineComponentOption
  | TitleComponentOption
  | TooltipComponentOption
  | LegendComponentOption
  | GridComponentOption
>;

const props = defineProps<{
  tokenUsage: {
    [month: string]: {
      [courseId: string]: {
        name: string;
        courseFullName: string;
        inputTokens: number;
        outputTokens: number;
        totalTokens: number;
        totalStudents: number;
        inputCumulativeTokens: number;
        outputCumulativeTokens: number;
        totalCumulativeTokens: number;
      };
    };
  };
}>();

const inputTokenCost = ref(0.0000025);
const outputTokenCost = ref(0.00001);

function calculateCostPerStudent(inputTokens: number, outputTokens: number, totalStudents: number) {
  const inputCost = inputTokens * inputTokenCost.value;
  const outputCost = outputTokens * outputTokenCost.value;
  totalStudents = totalStudents || 1;
  const totalCost = inputCost + outputCost;
  return totalCost / totalStudents;
}

const colorPalette = [
  '#6200ea',
  '#ff0266',
  '#00c853',
  '#ff5722',
  '#03dac6',
  '#ffeb3b',
  '#9c27b0',
  '#1b9e77',
  '#03a9f4',
  '#d95f02',
  '#7570b3',
  '#3700b3',
  '#e7298a',
  '#66a61e',
  '#e6ab02',
];

const getColorByCourseId = (courseId: string) => {
  const hash = [...courseId].reduce((acc, char) => acc + char.charCodeAt(0), 0);
  return colorPalette[hash % colorPalette.length]; // Cycle through the color palette
};

const timelineData = computed(() => Object.keys(props.tokenUsage));

const seriesData = computed(() => {
  return timelineData.value.map((month) => ({
    series: Object.keys(props.tokenUsage[month]).map((courseId) => {
      const course = props.tokenUsage[month][courseId];
      const costPerStudent = calculateCostPerStudent(
        course.inputCumulativeTokens,
        course.outputCumulativeTokens,
        course.totalStudents
      );
      const costTotalCourse = calculateCostPerStudent(course.inputTokens, course.outputTokens, 1);
      return [
        course.totalStudents, // x-axis: number of students
        costPerStudent, // y-axis: cost per student
        Math.sqrt(course.totalCumulativeTokens) / 40, // bubble size
        costTotalCourse, // tooltip
        course.courseFullName,
        courseId,
        month, // timeline
      ];
    }),
  }));
});

const maxCostPerStudent = computed(() => {
  let maxVal = 0;
  Object.keys(props.tokenUsage).forEach((month) => {
    Object.keys(props.tokenUsage[month]).forEach((courseId) => {
      const course = props.tokenUsage[month][courseId];
      const costPerStudent = calculateCostPerStudent(
        course.inputCumulativeTokens,
        course.outputCumulativeTokens,
        course.totalStudents
      );
      maxVal = Math.max(maxVal, costPerStudent);
    });
  });
  return maxVal;
});

const option: ComputedRef<ECOption> = computed(() => ({
  baseOption: {
    timeline: {
      axisType: 'category',
      autoPlay: true,
      orient: 'vertical',
      inverse: true,
      playInterval: 1500,
      data: timelineData.value,
      top: '10%',
      bottom: '10%',
      left: '85%',
      right: '6%',
      checkpointStyle: {
        borderWidth: 2,
      },
    },
    title: {
      text: 'Generative AI Cost Per Student by Course (Timeline)',
      left: 'center',
    },
    tooltip: {
      trigger: 'item',
      formatter: function (param: any) {
        const [totalStudents, costPerStudent, bubbleSize, costTotalCourse, courseFullName] =
          param.data;
        return `Course: ${courseFullName}<br/>Students: ${totalStudents}<br/>Cost of Total Course: $${costTotalCourse.toFixed(0)}`;
      },
    },
    grid: {
      left: '10%',
      right: '25%',
      bottom: '15%',
    },
    xAxis: {
      name: 'Number of Students',
      splitLine: {lineStyle: {type: 'dashed'}},
      nameLocation: 'middle',
    },
    yAxis: {
      name: 'Cost per Student (USD)',
      axisLabel: {
        formatter: '$ {value}',
      },
      splitLine: {lineStyle: {type: 'dashed'}},
      max: maxCostPerStudent.value.toFixed(3),
      scale: true,
    },
    series: [],
  },
  options: seriesData.value.map((dataForMonth, index) => ({
    title: {
      text: `AI Cost on ${timelineData.value[index]}`,
    },
    series: [
      {
        type: 'scatter',
        name: 'Course Data',
        data: dataForMonth.series,
        symbolSize: function (data: any) {
          return data[2];
        },
        emphasis: {
          focus: 'series',
          label: {
            show: true,
            formatter: function (param: any) {
              return param.data[4]; // Display course code on emphasis
            },
            position: 'top',
          },
        },
        itemStyle: {
          color: function (param: any) {
            return getColorByCourseId(param.data[5]); // Assign color based on courseId
          },
          shadowBlur: 10,
          shadowColor: 'rgba(120, 36, 50, 0.5)',
          shadowOffsetY: 5,
        },
      },
    ],
  })),
}));
</script>

<style scoped>
.chart {
  width: 100%;
  aspect-ratio: 1;
  max-height: 100vh;
}
</style>
