import {ref, onMounted, onUnmounted} from 'vue';

export function useTimer({
  startedAt,
  finishesAt,
}: {
  startedAt?: string | null;
  finishesAt?: string | null;
}) {
  const timerComplete = ref(false);
  const displayValue = ref('00:00:00');
  const progressValue = ref(0);
  const intervalId = ref<ReturnType<typeof setTimeout> | number | null>(null);

  const calculateTimeLeft = () => {
    if (!finishesAt || !finishesAt) return 0;
    const now = new Date().getTime();
    return new Date(finishesAt).getTime() - now;
  };

  const updateDisplayValue = () => {
    const timeLeft = calculateTimeLeft();
    if (timeLeft <= 0) {
      displayValue.value = '00:00';
      timerComplete.value = true;
      progressValue.value = 100;
      return;
    }

    const days = Math.floor(timeLeft / (1000 * 60 * 60 * 24));
    const years = Math.floor(days / 365);
    const remainingDays = days % 365;
    const hours = Math.floor((timeLeft % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
    const minutes = Math.floor((timeLeft % (1000 * 60 * 60)) / (1000 * 60));
    const seconds = Math.floor((timeLeft % (1000 * 60)) / 1000);

    let newDisplayValue = '';
    if (years > 0) {
      newDisplayValue += `${years}y `;
    }
    if (remainingDays > 0) {
      newDisplayValue += `${remainingDays}d `;
    }
    if (hours > 0 || years > 0 || remainingDays > 0) {
      newDisplayValue += `${hours.toString().padStart(2, '0')}:`;
    }
    newDisplayValue += `${minutes.toString().padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`;

    displayValue.value = newDisplayValue;

    // Update progressValue
    if (finishesAt && startedAt) {
      const totalTime = new Date(finishesAt).getTime() - new Date(startedAt).getTime();
      progressValue.value = parseFloat(
        Math.max(0, ((totalTime - timeLeft) / totalTime) * 100).toFixed(2)
      );
    }
  };

  onMounted(() => {
    if (finishesAt && !timerComplete.value) {
      updateDisplayValue(); // Initialize displayValue and progressValue
      intervalId.value = setInterval(updateDisplayValue, 1000);
    }
  });

  onUnmounted(() => {
    if (intervalId.value) clearInterval(intervalId.value);
  });

  return {displayValue, progressValue, timerComplete};
}
