<template>
  <sidebar-layout :items="page.props.components as any[]">
    <slot />
  </sidebar-layout>
</template>
<script setup lang="ts">
import {usePage} from '@inertiajs/vue3';
import SidebarLayout from '../../components/sidebar/SidebarLayout.vue';

const page = usePage();
</script>
