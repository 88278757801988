<template>
  <Menu as="div" @click.prevent class="relative flex" ref="reference">
    <MenuButton as="template" v-slot="{open}">
      <slot name="trigger" :open="open">
        <button class="shrink-0 btn md white group/button flex items-center justify-center gap-1">
          <span
            class="opacity-70 group-hover/button:opacity-100 group-hover/button:text-blue-500 grid grid-cols-1 grid-rows-1 justify-center items-center transition-all ease-out duration-150"
            role="presentation"
          >
            <s-icon
              name="close"
              size="18"
              class="row-start-1 col-start-1 transition-all ease-out duration-150"
              :class="!open ? 'opacity-0' : 'opacity-100'"
              aria-hidden="true"
            />
            <s-icon
              :name="icon || 'dots-vertical'"
              size="18"
              class="row-start-1 col-start-1 transition-all ease-out duration-150"
              :class="open ? 'opacity-0' : 'opacity-100'"
              aria-hidden="true"
            />
          </span>
          <span v-if="!label">{{ $t('menu') }}</span>
          <span v-else>{{ label }}</span>
        </button>
      </slot>
    </MenuButton>
    <Teleport to="body">
      <div ref="floating" :style="floatingStyles">
        <transition
          enter-active-class="transition duration-100 ease-out"
          enter-from-class="transform scale-95 opacity-0"
          enter-to-class="transform scale-100 opacity-100"
          leave-active-class="transition duration-75 ease-out"
          leave-from-class="transform scale-100 opacity-100"
          leave-to-class="transform scale-95 opacity-0"
        >
          <MenuItems
            class="absolute right-0 bottom-0 translate-y-full w-56 origin-top-right divide-y divide-gray-200 rounded-md bg-white shadow-lg ring-1 ring-black/5 focus:outline-none"
          >
            <template v-for="(item, index) in items">
              <MenuItem :key="index" v-slot="{active}" v-if="item.show !== false">
                <component
                  :is="item.href ? SLink : 'button'"
                  :href="item.href"
                  :method="item.method"
                  :data="item.data"
                  @click="item.onClick"
                  :class="[
                    active ? 'bg-gray-50 text-blue-500' : 'text-blue-600',
                    'group flex gap-1.5 w-full items-center first:rounded-t-md last:rounded-b-md px-3 py-2 first:pt-3 last:pb-3',
                  ]"
                >
                  <s-icon
                    v-if="item.icon"
                    :name="item.icon"
                    :class="active ? 'opacity-100' : 'opacity-70'"
                    size="20"
                    aria-hidden="true"
                  />
                  {{ item.label }}
                </component>
              </MenuItem>
            </template>
          </MenuItems>
        </transition>
      </div>
    </Teleport>
  </Menu>
</template>
<script setup lang="ts">
import {ref} from 'vue';
import {Menu, MenuButton, MenuItem, MenuItems} from '@headlessui/vue';
import {autoUpdate, flip, offset, shift, useFloating} from '@floating-ui/vue';

import SLink from '../components/SLink.vue';
import SIcon from './SIcon.vue';

const props = defineProps<{
  label?: string;
  icon?: string;
  items: {
    label: string;
    href?: string;
    method?: string;
    data?: any;
    as?: string;
    onClick?: () => void;
    show?: boolean;
    icon?: string;
  }[];
}>();

const reference = ref<HTMLElement | null>(null);
const floating = ref<HTMLElement | null>(null);
const middleware = ref([offset(8), flip(), shift()]);

const {floatingStyles} = useFloating(reference, floating, {
  placement: 'bottom-end',
  middleware,
  whileElementsMounted: autoUpdate,
});
</script>
