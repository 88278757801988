import {v4 as uuidv4} from 'uuid';
import {defineComponentType} from './defineComponentType';
import TextareaUiConfig = App.Entities.Tasks.Ui.TextareaUiConfig;

export const textarea = defineComponentType<TextareaUiConfig, null>({
  translationKey: 'Textarea',
  icon: 'text-box-edit-outline',
  uiConfigFactory: () => ({
    uuid: uuidv4(),
    type: 'Textarea',
    name: 'Textarea',
    label: '',
    placeholder: '',
    part: null,
  }),
  componentGraderFactory: () => null,
});
