<template>
  <Head :title="t('head.title', {course: course.code})"></Head>
  <s-page-block size="md">
    <s-page-header
      :overline="`${course.institution.abbreviation} - ${course.code}`"
      :heading="course.name"
      class="mb-3"
    />

    <!-- Course Schedule -->
    <section class="flex gap-5 relative items-center">
      <s-label-value :label="t('startDate')">
        {{ monthDaySometimesYear(course.startDate) }}
      </s-label-value>

      <s-label-value :label="t('endDate')">
        {{ monthDaySometimesYear(course.endDate) }}
      </s-label-value>

      <div class="bg-gray-400 rounded w-px h-5 -m-0.5"></div>

      <s-label-value :label="t('paymentDueDate')">
        <template v-if="course.paymentDueDate">
          {{ monthDaySometimesYear(course.paymentDueDate) }}
        </template>
        <span v-else class="italic text-gray-500">
          {{ t('paymentStatus.notSet') }}
        </span>
      </s-label-value>

      <payment-request-status-badge
        v-if="paymentRequest"
        :payment-request="paymentRequest"
        class="mt-0.5 text-sm"
      />
    </section>

    <course-payment-required-banner
      v-if="paymentRequest?.isPending"
      :course="course"
      :paymentRequest="paymentRequest"
      class="my-4"
    />

    <!-- Course Links -->
    <nav class="mt-4 grid grid-cols-[repeat(auto-fill,_minmax(9rem,_1fr))] gap-2">
      <s-card-btn :href="route('courses.assignment.index', {course: course.id})" icon="book-edit">
        {{ t('assignments') }}
      </s-card-btn>
      <s-card-btn
        v-if="can.viewGrades"
        :href="route('courses.grades.index', {course: course.id})"
        icon="table"
      >
        {{ t('grades') }}
      </s-card-btn>
      <s-card-btn
        v-if="can.viewParticipants"
        :href="route('courses.participants.index', {course: course.id})"
        icon="account-group"
      >
        {{ t('participants') }}
      </s-card-btn>

      <s-card-btn
        v-if="can.viewAnalytics"
        :href="route('courses.analytics.tasks', {course: course.id})"
        icon="chart-bar"
      >
        {{ t('analytics') }}
      </s-card-btn>

      <s-card-btn
        v-if="can.takeAttendance"
        :href="route('courses.roll-call.index', {course: course.id})"
        icon="account-check"
      >
        {{ t('attendance') }}
      </s-card-btn>
      <s-card-btn
        v-if="can.update"
        :href="route('courses.edit', {course: course.id})"
        icon="pencil"
      >
        {{ t('links.edit') }}
      </s-card-btn>
      <s-card-btn
        v-if="loggedInUser.isSuperUser"
        :href="route('admin.courses.show', {course: course.id})"
        icon="cog"
      >
        {{ t('superAdmin') }}
      </s-card-btn>
    </nav>
  </s-page-block>
</template>
<script setup lang="ts">
import {Head} from '@inertiajs/vue3';
import {route} from 'ziggy-js';
import SPageBlock from '../../design-system/SPageBlock.vue';
import SPageHeader from '../../design-system/SPageHeader.vue';
import {useI18n} from 'vue-i18n';
import {Course} from '../../types/entities/course';
import {monthDaySometimesYear} from '../../format/dates';
import SCardBtn from '../../design-system/SCardBtn.vue';
import CoursePaymentRequiredBanner from '../../banners/CoursePaymentRequiredBanner.vue';
import PaymentRequestDto = App.DTOs.Entities.PaymentRequestDto;
import SLabelValue from '../../design-system/SLabelValue.vue';
import PaymentRequestStatusBadge from '../../components/payment-requests/PaymentRequestStatusBadge.vue';

defineProps<{
  loggedInUser: {isSuperUser: boolean};
  course: Course;
  paymentRequest: PaymentRequestDto | null;
  can: {
    update: boolean;
    viewGrades: boolean;
    viewParticipants: boolean;
    viewAnalytics: boolean;
    takeAttendance: boolean;
  };
}>();

const {t} = useI18n({
  useScope: 'local',
  inheritLocale: true,
});
</script>
<i18n>
{
  "en": {
    "head": {
      "title": "{course}"
    },
    "paymentStatus": {
      "paid": "Payment has been made for this course.",
      "exempted": "You are exempted from paying for this course.",
      "notSet": "Not Set"
    },
    "links": {
      "edit": "Edit Course"
    }
  },
  "fr": {
    "head": {
      "title": "{course}"
    },
    "paymentStatus": {
      "paid": "Le paiement a été effectué pour ce cours.",
      "exempted": "Vous êtes exempté de payer pour ce cours.",
      "notSet": "Non défini"
    },
    "links": {
      "edit": "Modifier le cours"
    }
  }
}
</i18n>
