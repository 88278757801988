import {NodeSpec} from 'prosemirror-model';

export let taskTextBlock = {
  content: 'text*',
  group: 'block',
  toDOM: (node) => [
    'p',
    {
      ['data-type']: 'text-block',
      ['data-part']: node.attrs.part,
      ['data-uuid']: node.attrs.uuid,
      ['data-name']: node.attrs.name,
    },
    0,
  ],
  parseDOM: [
    {
      tag: 'p[data-type=text-block]', // important!
      getAttrs: (dom: HTMLElement) => {
        return {
          ['data-part']: dom.getAttribute('data-part'),
          ['data-uuid']: dom.getAttribute('data-uuid'),
          ['data-name']: dom.getAttribute('data-name'),
        };
      },
    },
  ],

  attrs: {
    errors: {default: {}},
    part: {default: null},
    uuid: {default: null},
    name: {default: 'Text Block'},
  },
} as NodeSpec;
