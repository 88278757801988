<template>
  <form>
    <div :class="[classes.tabPane, 'gap-5']">
      <SFileUpload
        v-model="form.file"
        icon=""
        :file-type-message="t('fileTypeMessage')"
        accept="text/csv"
        @errorChange="form.errors.file = $event"
      ></SFileUpload>
      <div v-if="form.errors.file" class="animate-slide-in">
        <p
          class="flex items-center justify-start gap-1.5 px-4 py-3 border border-red-200 bg-gradient-to-r from-red-100/70 to-red-100 text-base leading-tight text-red-700 rounded-md shadow-soft"
        >
          <s-icon name="warning" class="opacity-70" width="18" height="18" />
          {{ form.errors.file }}
        </p>
      </div>
    </div>
    <div :class="classes.tabFooter">
      <s-btn
        color="secondary"
        icon="close"
        :href="route('courses.participants.index', {course: course.id})"
        class="flex-1 max-w-[16rem]"
      >
        {{ t('form.cancel') }}
      </s-btn>
      <s-btn
        @click.prevent="
          () => {
            if (!form.file) {
              form.errors.file = t('pleaseSelectFile');
              return;
            }
            $emit('next');
          }
        "
        color="primary"
        class="flex-1 max-w-[16rem]"
      >
        {{ t('actions.map') }}
        <s-icon name="arrow-right" class="opacity-70" size="18" />
      </s-btn>
    </div>
  </form>
</template>
<script setup lang="ts">
import {route} from 'ziggy-js';
import SFileUpload from '../../../components/SFileUpload.vue';
import SBtn from '../../../design-system/SBtn.vue';
import {InertiaForm} from '@inertiajs/vue3';
import RosterFormDto = App.DTOs.Roster.RosterFormDto;
import {useI18n} from 'vue-i18n';
import {Course} from '../../../types/entities/course';
import SIcon from '../../../design-system/SIcon.vue';

const props = defineProps<{
  course: Course;
  form: InertiaForm<RosterFormDto>;
  classes: any;
}>();

defineEmits(['next']);

const {t} = useI18n({
  useScope: 'local',
  inheritLocale: true,
});
</script>

<i18n>
{
  "en": {
    "fileTypeMessage": "CSV files only",
    "pleaseSelectFile": "Please select a file to continue."
  },
  "fr": {
    "fileTypeMessage": "Fichiers CSV uniquement",
    "pleaseSelectFile": "Veuillez sélectionner un fichier pour continuer."
  }
}
</i18n>
