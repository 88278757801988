<template>
  <Head :title="t('tasks')"></Head>
  <s-page-block class="flex flex-col gap-5">
    <s-page-header :heading="t('tasks')">
      <template #actions>
        <s-btn v-if="can.create" :href="route('tasks.create')" color="primary" icon="plus">
          {{ t('actions.create') }}
        </s-btn>
      </template>
    </s-page-header>

    <div class="flex flex-wrap gap-x-3 gap-y-4 items-end justify-between">
      <div class="text-sm grow max-w-56">
        <s-select-field id="status" :label="t('labels.status')" v-model="status">
          <option value="">{{ t('any') }}</option>
          <option value="draft">{{ t('status.draft') }}</option>
          <option value="ready_for_review">{{ t('status.readyForReview') }}</option>
          <option value="published">{{ t('status.published') }}</option>
          <option value="archived">{{ t('status.archived') }}</option>
        </s-select-field>
      </div>
      <div class="text-sm grow max-w-56">
        <s-multi-select
          id="section"
          :label="t('search.types')"
          v-model="selectedTypes"
          value-key="id"
          label-key="description"
          :empty-label="t('search.allTypes')"
          :options="typeLikes"
        />
      </div>
      <div class="grow max-w-xl">
        <s-search-section
          route-name="tasks.index"
          label="Search by task ID or title"
          :only="['tasks']"
        />
      </div>
    </div>

    <section class="stack vertical gap-3">
      <s-link
        v-for="task in tasks.data"
        :href="route('tasks.show', [task.id])"
        class="group card-soft border border-gray-200 transition hover:from-gray-100 hover:to-gray-50 px-4 md:px-5 lg:px-6 py-2.5 md:py-4 stack horizontal align-between valign-center gap-3 text-gray-700 hover:text-blue-500"
      >
        <div class="stack vertical gap-2 w-full">
          <div class="text-lg leading-tight">
            <s-latex :content="task.title"></s-latex>
          </div>
        </div>
        <div class="stack horizontal gap-4 w-fit text-nowrap">
          <display-task-types :task-types="task.taskTypes" />
          <s-task-status-badge :status="task.status" size="sm" />
          <s-icon
            name="chevron-right"
            class="text-blue-500 opacity-50 group-hover:opacity-80 group-active:opacity-100 transition"
          />
        </div>
      </s-link>
    </section>
    <s-paginator :paginator="tasks" preserve-state />
  </s-page-block>
</template>
<script setup lang="ts">
import STaskStatusBadge from '../../components/STaskStatusBadge.vue';
import SIcon from '../../design-system/SIcon.vue';
import SPaginator from '../../design-system/SPaginator.vue';
import SPageBlock from '../../design-system/SPageBlock.vue';
import SPageHeader from '../../design-system/SPageHeader.vue';
import SBtn from '../../design-system/SBtn.vue';
import SSelectField from '../../design-system/SSelectField.vue';
import SSearchSection from '../../design-system/SSearchSection.vue';
import SLink from '../../components/SLink.vue';
import {LaravelPaginator} from '../../types/laravel-paginator';
import {computed, ref, watch} from 'vue';
import {router, Head} from '@inertiajs/vue3';
import {route} from 'ziggy-js';
import {useI18n} from 'vue-i18n';
import SLatex from '../../components/SLatex.vue';
import TaskIndexDto = App.DTOs.Tasks.TaskIndexDto;
import DisplayTaskTypes from '../AssignmentTasks/Components/DisplayTaskTypes.vue';
import SMultiSelect from '../../design-system/SMultiSelect.vue';
import TaskTypeDto = App.DTOs.Tasks.TaskTypeDto;
import {useDebounceFn} from '@vueuse/core';

const {types} = defineProps<{
  tasks: LaravelPaginator<TaskIndexDto>;
  types: TaskTypeDto[];
  can: {
    create: boolean;
  };
}>();

const typeLikes = computed(() => {
  return [
    {
      id: 'custom',
      description: 'Custom',
    },
    ...types.map((type) => ({
      id: type.id,
      description: type.description,
    })),
  ];
});

const status = ref<string>((route().params.status as string) || '');
// @ts-ignore
console.log(route().params);
const selectedTypes = ref<string[]>([]);

const updateSearch = useDebounceFn(() =>
  router.replace(
    route('tasks.index', {
      _query: {
        search: route().params.search,
        status: status.value,
        type: selectedTypes.value.filter((type) => type !== 'custom'),
        custom: selectedTypes.value.includes('custom'),
      },
    })
  )
);

watch([status, selectedTypes], updateSearch);

const {t} = useI18n({
  inheritLocale: true,
  useScope: 'local',
});
</script>
<i18n>
{
  "en": {
    "actions.create": "Create Task",
    "any": "Any",
    "labels.status": "Status",
    "status.draft": "Draft",
    "status.readyForReview": "Ready for Review",
    "status.published": "Published",
    "status.archived": "Archived",
    "search.allTypes": "All Types"
  },
  "fr": {
    "actions.create": "Créer une tâche",
    "any": "Tout",
    "labels.status": "Statut",
    "status.draft": "Brouillon",
    "status.readyForReview": "Prêt pour la révision",
    "status.published": "Publié",
    "status.archived": "Archivé",
    "search.allTypes": "Tous les types"
  }
}
</i18n>
