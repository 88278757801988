<script setup lang="ts">
import {computed, ref} from 'vue';
import SIcon from '../../../design-system/SIcon.vue';

const props = defineProps<{
  legendAlignment: 'left' | 'right';
  class?: string;
}>();

const showLegend = ref(false);

const buttonClass = computed(() =>
  [
    showLegend.value ? 'blue transition-all ease-out duration-150' : 'white',
    props.class ?? '',
  ].join(' ')
);

const legendContainerClass = computed(() =>
  [
    showLegend.value
      ? 'opacity-100 translate-y-[-100%] pointer-events-all'
      : 'opacity-0 translate-y-[calc(-100%+0.5rem)] pointer-events-none',
    props.legendAlignment === 'left' ? 'left-0' : 'right-0',
  ].join(' ')
);
</script>

<template>
  <button
    @click="showLegend = !showLegend"
    class="btn block min-w-[8rem]"
    :class="buttonClass"
    aria-hidden="true"
  >
    {{ showLegend ? $t('Hide Legend') : $t('Show Legend') }}
  </button>
  <div
    aria-hidden="true"
    class="absolute -top-2 card pb-5 pt-6 pl-6 pr-10 z-[100] select-none flex flex-col gap-1.5 transition-all ease-out duration-150"
    :class="legendContainerClass"
  >
    <span class="flex items-center gap-2 whitespace-nowrap">
      <span class="inline-block rounded-full shadow-card w-4 h-4 bg-green-500"></span>
      <span>{{ $t('correct') }}</span>
    </span>
    <span class="flex items-center gap-2 whitespace-nowrap">
      <span class="inline-block rounded-full shadow-card w-4 h-4 bg-blue-400"></span>
      <span>{{ $t('partiallyCorrect') }}</span>
    </span>
    <span class="flex items-center gap-2 whitespace-nowrap">
      <span class="inline-block rounded-full shadow-card w-4 h-4 bg-red-400"></span>
      <span>{{ $t('incorrect') }}</span>
    </span>
    <span class="flex items-center gap-2 whitespace-nowrap">
      <span class="inline-block rounded-full shadow-card w-4 h-4 bg-gray-600"></span>
      <span>{{ $t('ungraded') }}</span>
    </span>
    <span class="flex items-center gap-2 whitespace-nowrap">
      <span class="inline-block rounded-full shadow-card w-4 h-4 bg-gray-400"></span>
      <span>
        {{ $t('draft') }}
        <em>({{ $t('ungraded') }})</em>
      </span>
    </span>
    <span class="flex items-center gap-2 whitespace-nowrap">
      <s-icon name="pencil" class="opacity-70 -mx-0.5" size="20" />
      <span>{{ $t('containsDraft') }}</span>
    </span>
    <span class="flex items-center gap-2 whitespace-nowrap">
      <span class="inline-block rounded-full shadow-card w-4 h-4 bg-gray-150"></span>
      <span>{{ $t('incomplete') }}</span>
    </span>
  </div>
</template>
