<template>
  <details
    :class="`rounded-lg shadow-card-soft [&_.s-details-icon]:open:-rotate-180 ${detailsClass}`"
  >
    <summary
      class="group w-full cursor-pointer font-medium flex list-none items-center gap-4 justify-between transition hover:text-blue-600"
      :class="[sizeClasses.summary[size], summaryClass]"
    >
      <slot name="summary" />
      <s-icon
        name="chevron-down"
        class="s-details-icon opacity-30 group-hover:opacity-70 transition duration-150 ease-in-out"
      />
    </summary>
    <div :class="[sizeClasses.details[size], contentClass]">
      <slot />
    </div>
  </details>
</template>

<script setup lang="ts">
import SIcon from './SIcon.vue';

type Size = 'sm' | 'md' | 'lg';

const {
  size = 'md',
  detailsClass = 'bg-gradient-to-t from-gray-50 to-white border border-gray-150',
  summaryClass = '',
  contentClass = '',
} = defineProps<{
  size?: Size;
  detailsClass?: string;
  summaryClass?: string;
  contentClass?: string;
}>();

const sizeClasses: Record<'summary' | 'details', Record<Size, string>> = {
  summary: {
    sm: 'px-4 py-2',
    md: 'px-5 lg:px-6 py-3 md:py-4',
    lg: 'px-5 md:px-7 lg:px-8 py-4 md:py-5',
  },
  details: {
    sm: 'px-4 pb-2 -mt-1',
    md: 'px-5 lg:px-6 pb-3 md:pb-4 -mt-2',
    lg: 'px-5 md:px-7 lg:px-8 pb-4 md:pb-5 -mt-2',
  },
};
</script>
