<template>
  <Head :title="t('page.title')"></Head>
  <div class="bg-gradient-to-b from-blue-50 via-transparent to-transparent">
    <div class="p-4 lg:p-8 max-w-xl mx-auto flex flex-col gap-5">
      <form
        @submit.prevent="form.post(route('password.email'))"
        class="flex flex-col shadow-card rounded-lg"
      >
        <div
          class="bg-gradient-to-t from-white/70 to-white/30 flex items-center justify-center p-6 rounded-t-lg border border-white/50 border-b-gray-100"
        >
          <s-logo role="presentation" type="image" class="logo h-16 lg:h-[4.5rem] w-auto" />
          <span class="sr-only">Stemble</span>
        </div>
        <div
          class="px-5 lg:px-8 py-5 lg:py-8 bg-gradient-to-t from-gray-50 to-white rounded-b-lg flex flex-col gap-5"
        >
          <div class="flex flex-col gap-1">
            <h1 id="form-title" class="heading sm">
              {{ t('page.title') }}
            </h1>
            <p class="text-base">
              {{ t('page.description') }}
            </p>
          </div>
          <div class="group">
            <label for="email" class="sr-only">{{ t('email') }}</label>
            <s-input-field
              id="email"
              :placeholder="t('email')"
              v-model="form.email"
              required
              :error="form.errors.email"
            >
              <template v-slot:prefix>
                <s-icon
                  name="email"
                  class="opacity-70 group-hover:opacity-50 group-focus-within:text-blue-600 transition"
                />
              </template>
            </s-input-field>
          </div>
          <div class="mt-1 flex flex-wrap gap-4">
            <s-btn :href="route('login')" color="secondary" class="flex-1">
              {{ t('actions.backToLogin') }}
              <s-icon name="keyboard-return" class="opacity-70" />
            </s-btn>
            <s-btn
              type="submit"
              color="primary"
              :loading="form.processing"
              :disabled="form.processing"
              class="flex-1"
            >
              {{ t('actions.reset') }}
              <s-icon
                v-if="!form.processing"
                name="lock-reset"
                class="opacity-70"
                role="presentation"
              />
              <s-icon v-else name="loading" class="opacity-70 animate-spin" />
            </s-btn>
          </div>
        </div>
      </form>
      <div class="flex gap-x-3 gap-y-2 flex-wrap">
        <span class="flex-grow">
          <s-link :href="route('invitation.create')" class="link-subtle flex items-center gap-1.5">
            <s-icon name="clipboard-account" class="opacity-70" />
            {{ t('links.register') }}
          </s-link>
        </span>
        <s-link :href="route('support')" class="link-subtle flex items-center gap-1.5">
          <s-icon name="chat-question" class="opacity-70" />
          {{ t('links.help') }}
        </s-link>
      </div>
      <div
        v-if="flash['success']"
        class="px-4 py-2 shadow-sm border text-base leading-tight rounded flex items-center gap-2 bg-green-100 text-green-800 border-green-200"
      >
        <s-icon name="email-check" class="opacity-70" size="22" />
        {{ flash['success'] }}
      </div>
    </div>
  </div>
</template>
<script setup lang="ts">
import {defineProps} from 'vue';
import {Head, useForm} from '@inertiajs/vue3';
import {useI18n} from 'vue-i18n';
import {route} from 'ziggy-js';
import SInputField from '../../design-system/SInputField.vue';
import SLink from '../../components/SLink.vue';
import SBtn from '../../design-system/SBtn.vue';
import SLogo from '../../design-system/SLogo.vue';
import UnauthorizedLayout from '../../layouts/UnauthorizedLayout.vue';
import SIcon from '../../design-system/SIcon.vue';
import loginTranslations from '../Login/login.json';

defineOptions({layout: UnauthorizedLayout});

defineProps<{
  flash: Record<string, string>;
}>();

type FormData = {email: string};

const form = useForm<FormData>({
  email: '',
});

const {t} = useI18n({
  inheritLocale: true,
  messages: loginTranslations,
});
</script>
<i18n>
{
  "en": {
    "page": {
      "title": "Password Reset",
      "description": "Enter your email address to receive instructions on how to reset your password.",
    },
  },
  "fr": {
    "page": {
      "title": "Réinitialisation du mot de passe",
      "description": "Entrez votre adresse e-mail pour recevoir des instructions sur la façon de réinitialiser votre mot de passe.",
    },
  }
}
</i18n>
