<template>
  <v-chart class="tokenChart" :option="option" />
</template>

<script setup lang="ts">
import {computed, ComputedRef} from 'vue';
import type {ComposeOption} from 'echarts';
import * as echarts from 'echarts/core';
import {use} from 'echarts/core';
import {LineChart, LineSeriesOption} from 'echarts/charts';
import {CanvasRenderer} from 'echarts/renderers';
import VChart from 'vue-echarts';

import {
  DataZoomComponent,
  DataZoomComponentOption,
  GridComponent,
  GridComponentOption,
  LegendComponent,
  LegendComponentOption,
  TitleComponent,
  TitleComponentOption,
  TooltipComponent,
  TooltipComponentOption,
} from 'echarts/components';

use([
  TitleComponent,
  LegendComponent,
  DataZoomComponent,
  LineChart,
  CanvasRenderer,
  TooltipComponent,
  GridComponent,
]);

type ECOption = ComposeOption<
  | DataZoomComponentOption
  | LineSeriesOption
  | TitleComponentOption
  | TooltipComponentOption
  | LegendComponentOption
  | GridComponentOption
>;

const props = defineProps<{
  tokenUsage: {
    day: string;
    totalTokens: number;
    totalImageTokens: number;
    avgInputTokensPerAPIRequest: number;
    avgOutputTokensPerAPIRequest: number;
    avgInputTokensPerImageAPIRequest: number;
    avgOutputTokensPerImageAPIRequest: number;
  }[];
}>();

const dates = computed(() => props.tokenUsage.map((item) => item.day));
const tokens = computed(() => props.tokenUsage.map((item) => item.totalTokens));
const imageTokens = computed(() => props.tokenUsage.map((item) => item.totalImageTokens));
const inputTokens = computed(() =>
  props.tokenUsage.map((item) => item.avgInputTokensPerAPIRequest)
);
const outputTokens = computed(() =>
  props.tokenUsage.map((item) => item.avgOutputTokensPerAPIRequest)
);
const inputImageTokens = computed(() =>
  props.tokenUsage.map((item) => item.avgInputTokensPerImageAPIRequest)
);
const outputImageTokens = computed(() =>
  props.tokenUsage.map((item) => item.avgOutputTokensPerImageAPIRequest)
);

const option: ComputedRef<ECOption> = computed(() => ({
  title: {
    text: 'Token Usage Over Time',
    left: 'center',
  },
  tooltip: {
    trigger: 'axis',
    position: (pt) => [pt[0], '10%'],
  },
  legend: [
    {
      data: ['Tokens', 'Image Tokens'],
      top: 30,
    },
    {
      data: [
        'Average Input Tokens Per API Request',
        'Average Output Tokens Per API Request',
        'Average Input Tokens Per Image API Request',
        'Average Output Tokens Per Image API Request',
      ],
      bottom: 75,
    },
  ],
  grid: [
    {
      left: 60,
      right: 50,
      height: '35%',
    },
    {
      left: 60,
      right: 50,
      top: '50%',
      height: '35%',
    },
  ],
  xAxis: [
    {
      type: 'category',
      boundaryGap: false,
      axisLine: {onZero: true},
      data: dates.value,
    },
    {
      gridIndex: 1,
      type: 'category',
      boundaryGap: false,
      axisLine: {onZero: true},
      data: dates.value,
      position: 'top',
    },
  ],
  yAxis: [
    {
      name: 'Total tokens',
      type: 'value',
    },
    {
      gridIndex: 1,
      name: 'Average Tokens',
      type: 'value',
    },
  ],
  axisPointer: {
    link: [
      {
        xAxisIndex: 'all',
      },
    ],
  },
  dataZoom: [
    {
      show: true,
      realtime: true,
      start: 30,
      end: 70,
      xAxisIndex: [0, 1],
    },
    {
      type: 'inside',
      realtime: true,
      start: 30,
      end: 70,
      xAxisIndex: [0, 1],
    },
  ],
  series: [
    {
      name: 'Tokens',
      type: 'line',
      symbol: 'none',
      sampling: 'lttb',
      itemStyle: {
        color: 'rgb(33, 150, 243)',
      },
      areaStyle: {
        color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
          {offset: 0, color: 'rgb(33, 150, 243)'},
          {offset: 1, color: 'rgb(144, 202, 249)'},
        ]),
      },
      data: tokens.value,
    },
    {
      name: 'Image Tokens',
      type: 'line',
      symbol: 'none',
      sampling: 'lttb',
      itemStyle: {
        color: 'rgb(233, 150, 200)',
      },
      areaStyle: {
        color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
          {offset: 0, color: 'rgb(233, 150, 143)'},
          {offset: 1, color: 'rgb(204, 102, 229)'},
        ]),
      },
      data: imageTokens.value,
    },
    {
      name: 'Average Input Tokens Per API Request',
      type: 'line',
      xAxisIndex: 1,
      yAxisIndex: 1,
      stack: 'Total',
      areaStyle: {},
      emphasis: {
        focus: 'series',
      },
      data: inputTokens.value,
    },
    {
      name: 'Average Output Tokens Per API Request',
      type: 'line',
      xAxisIndex: 1,
      yAxisIndex: 1,
      stack: 'Total',
      areaStyle: {},
      emphasis: {
        focus: 'series',
      },
      data: outputTokens.value,
    },
    {
      name: 'Average Input Tokens Per Image API Request',
      type: 'line',
      xAxisIndex: 1,
      yAxisIndex: 1,
      stack: 'Total',
      areaStyle: {},
      emphasis: {
        focus: 'series',
      },
      data: inputImageTokens.value,
    },
    {
      name: 'Average Output Tokens Per Image API Request',
      type: 'line',
      xAxisIndex: 1,
      yAxisIndex: 1,
      stack: 'Total',
      areaStyle: {},
      emphasis: {
        focus: 'series',
      },
      data: outputImageTokens.value,
    },
  ],
}));
</script>

<style scoped>
.tokenChart {
  width: 100%;
  aspect-ratio: 1;
}
</style>
