<template>
  <s-date date="" />
</template>
<script setup lang="ts">
import SDate from '../../../../design-system/SDate.vue';
</script>
<script lang="ts">
import {ExampleOptions} from '../../examples';

const description = `If the date is not provided, it will let the user know the date is not available.`;

export const options: ExampleOptions = {
  description,
};
</script>
