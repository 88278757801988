<template>
  <Head title="Feature Flags"></Head>
  <s-page-block>
    <s-page-header heading="Feature Flags">
      <template #actions></template>
    </s-page-header>
    <table class="table">
      <thead>
        <tr>
          <th>Feature</th>
          <th>Targeting</th>
          <th>Purge State</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="feature in features" :key="feature.key">
          <td>{{ feature.label }}</td>
          <td>
            <div class="flex gap-1">
              <s-tooltip>
                <template #tooltip>
                  <span v-if="isTargeting(feature.key)">
                    Targeting rules are enabled for this feature.
                    <br />
                    Note: This does not mean it is available to everyone.
                  </span>
                  <span v-else>This feature is turned off for everyone.</span>
                </template>
                <s-toggle
                  :model-value="isTargeting(feature.key)"
                  @update:model-value="
                    router.put(route('admin.features.update', [feature.key]), {
                      targeting: !isTargeting(feature.key),
                    })
                  "
                />
              </s-tooltip>
            </div>
          </td>
          <td class="flex flex-wrap gap-1">
            <s-tooltip>
              <template #tooltip>
                Use this when changing how flags are evaluated.
                <br />
                Purge's the cache so the flag will be re-evaluated for each user.
              </template>
              <s-btn
                color="danger"
                size="sm"
                @click.prevent="router.delete(route('admin.features.destroy', [feature.key]))"
              >
                Purge
              </s-btn>
            </s-tooltip>
          </td>
        </tr>
      </tbody>
    </table>
  </s-page-block>
</template>
<script setup lang="ts">
import SPageHeader from '../../../design-system/SPageHeader.vue';
import SBtn from '../../../design-system/SBtn.vue';
import {route} from 'ziggy-js';
import {router} from '@inertiajs/vue3';
import SBadge from '../../../design-system/SBadge.vue';
import STooltip from '../../../design-system/STooltip.vue';
import FeatureData = App.DTOs.FeatureData;
import SPageBlock from '../../../design-system/SPageBlock.vue';
import {Head} from '@inertiajs/vue3';
import SToggle from '../../../design-system/SToggle.vue';

type Override = {
  name: string;
  value: string;
};

const props = defineProps<{
  features: FeatureData[];
  overrides: Override[];
}>();

const overrideFor = (feature: string): Override | undefined => {
  return props.overrides.find((override) => override.name === feature);
};

const isTargeting = (feature: string) => {
  return !overrideFor(feature);
};
</script>
