<template>
  <Head
    :title="task?.type ? `${isCopy ? 'Copy' : 'Create'} ${task.type}` : 'Select Task Type'"
  ></Head>
  <s-page-block size="md">
    <s-page-header
      :heading="task?.type ? `${isCopy ? 'Copy' : 'Create'} ${task.type}` : 'Select Task Type'"
      class="mb-4"
    >
      <template #actions>
        <button
          v-if="!formComponent"
          class="btn secondary md w-fit"
          @click.prevent="showImportModal = true"
        >
          JSON Import
        </button>
        <button
          v-if="formComponent"
          @click.prevent="updateQueryString('')"
          class="btn secondary md w-fit"
        >
          Cancel
        </button>
      </template>
    </s-page-header>

    <!--  Task Type Form -->
    <template v-if="formComponent && clonedTask">
      <component
        :is="formComponent"
        :institutions="institutions"
        :modelsMetadata="modelsMetadata"
        :modelValue="clonedTask"
        :errors="errors"
      />
    </template>
    <div
      v-else-if="!taskTypes"
      class="rounded-lg border border-gray-200 h-36 w-full flex items-center justify-center bg-gray-200/30 shadow-inset"
    >
      <s-icon name="loading" class="animate-spin text-blue-500 w-12 h-12 m-auto" />
    </div>
    <section
      v-else
      class="rounded-lg text-center leading-tight tracking-slight text-blue-500 bg-white border border-gray-300/50 shadow-btn-white flex flex-col divide-y divide-gray-150 overflow-hidden"
    >
      <button
        v-for="type in taskTypes"
        @click.prevent="updateQueryString(type.id)"
        class="cursor-pointer px-5 py-3.5 flex items-center gap-1.5 justify-center text-sm leading-tight font-medium transition hover:bg-gray-50 active:bg-gray-100"
      >
        <s-icon v-if="type.icon" :name="type.icon" class="opacity-70" width="18" height="18" />
        {{ type.label }}
      </button>
    </section>
  </s-page-block>

  <s-modal
    :open="showImportModal"
    :confirm="{
      label: 'Import',
      callback: importTaskFromJson,
    }"
    @cancel="showImportModal = false"
    width="xl"
  >
    <div class="w-full px-4 md:px-5 lg:px-6 pb-3 pt-5 flex gap-2 items-start justify-between">
      <div class="flex-1 flex flex-col">
        <h2 class="text-xl leading-snug sm">Import Task from JSON</h2>
      </div>
    </div>
    <s-modal-content>
      <div class="flex flex-col gap-4 pb-1">
        <div class="flex w-full flex-wrap justify-between gap-x-4 gap-y-3">
          <s-textarea-field id="import-json" label="Task JSON" v-model="taskImportJson" />
        </div>
      </div>
    </s-modal-content>
  </s-modal>
</template>
<script setup lang="ts">
import SPageHeader from '../../design-system/SPageHeader.vue';
import SPageBlock from '../../design-system/SPageBlock.vue';
import {computed, defineAsyncComponent, ref} from 'vue';
import {Institution} from '../../types/entities/institution';
import {route} from 'ziggy-js';
import {router} from '@inertiajs/vue3';
import {Head} from '@inertiajs/vue3';
import SIcon from '../../design-system/SIcon.vue';
import SModal from '../../design-system/SModal.vue';
import SModalContent from '../../design-system/SModalContent.vue';
import STextareaField from '../../design-system/STextareaField.vue';
import {useFlash} from '../../composables/useFlash';
import {LlmMetadata} from '../../types/entities/llmMetadata';

type TaskType = {id: string; label: string; icon?: string};

const props = defineProps<{
  task: Record<string, any> | null;
  institutions: Institution[];
  modelsMetadata: LlmMetadata[];
  taskTypes?: TaskType[];
  isCopy?: boolean;
  errors?: Record<string, any>;
}>();

const flash = useFlash();

const clonedTask = ref(props.task ? JSON.parse(JSON.stringify(props.task)) : null);

const type = ref(route().params.type ?? clonedTask.value?.type);

const formComponent = computed(() => {
  if (type.value) {
    return defineAsyncComponent(() => import(`./${type.value}/Form.vue`));
  }
});

const showImportModal = ref(false);
const taskImportJson = ref('');
const importTaskFromJson = () => {
  try {
    // attempt to parse the JSON
    const task = JSON.parse(taskImportJson.value);

    // Assign the parsed JSON to the clonedTask if it doesn't explode and set the for type. This should trigger the render of the form component
    clonedTask.value = task;
    type.value = task.type;
  } catch (e) {
    console.error(e);
    flash?.value?.set('error', 'Invalid Task JSON');
  } finally {
    showImportModal.value = false;
  }
};

const updateQueryString = (typeId: string) => {
  type.value = typeId;
  let _query: any = {};
  if (typeId) _query.type = typeId;

  router.get(route('tasks.create', {_query}));
};
</script>
