<template>
  <Head :title="t('page.title')"></Head>
  <div class="page-block max-w-screen-lg">
    <div class="mb-4 pt-4">
      <!-- eslint-disable-next-line @intlify/vue-i18n/no-raw-text -->
      <a href="/" title="Stemble Learning">
        <img src="../../../images/logo-color-black-text-large.png" class="w-64 h-auto" />
      </a>
    </div>
    <s-page-header :heading="t('page.title')" class="mb-5" />
    <div ref="hubspotFormContainer"></div>
  </div>
</template>

<script lang="ts" setup>
import {useI18n} from 'vue-i18n';
import {onMounted, ref} from 'vue';
import SPageHeader from '../../design-system/SPageHeader.vue';
import {Head} from '@inertiajs/vue3';

const hubspotFormContainer = ref<HTMLElement | null>(null);

const {t, locale} = useI18n({
  useScope: 'local',
  inheritLocale: true,
});

type SupportedLocale = 'en' | 'fr';

const formIds: Record<SupportedLocale, string> = {
  en: '552c43f2-ecb1-40cc-8e5e-a300164c3cdf',
  fr: '9579691a-6f6c-4eee-a0eb-cbbbe1b78c61',
};

onMounted(() => {
  // This ensure fr-CA is treated as fr
  const baseLocale = locale.value.split('-')[0] as SupportedLocale;
  const formId = formIds[baseLocale] || formIds.en;
  const script = document.createElement('script');
  script.src = '//js.hsforms.net/forms/embed/v2.js';
  script.charset = 'utf-8';
  script.onload = () => {
    // @ts-ignore
    window.hbspt.forms.create({
      region: 'na1',
      portalId: '9143538',
      formId,
    });
  };
  hubspotFormContainer.value!.appendChild(script);
});
</script>

<script lang="ts">
import LoggedOutLayout from '../../components/LoggedOutLayout.vue';

export default {
  layout: LoggedOutLayout,
};
</script>
<i18n>
{
  "en": {
    "page": {
      "title": "Contact Stemble Support"
    }
  },
  "fr": {
    "page": {
      "title": "Contacter le support de Stemble"
    }
  }
}
</i18n>
