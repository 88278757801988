import {v4 as uuidv4} from 'uuid';
import {defineComponentType} from './defineComponentType';
import MultipleChoiceUiConfig = App.Entities.Tasks.Ui.MultipleChoiceUiConfig;
import MultipleChoiceComponentGrader = App.Entities.Tasks.ComponentGraders.MultipleChoiceComponentGrader;

export const multipleChoice = defineComponentType<
  MultipleChoiceUiConfig,
  MultipleChoiceComponentGrader
>({
  translationKey: 'Multiple Choice',
  icon: 'format-list-bulleted',
  uiConfigFactory: () => ({
    uuid: uuidv4(),
    uiType: 'radio',
    type: 'MultipleChoice',
    name: 'Multiple Choice',
    label: '',
    options: [],
    part: null,
  }),
  componentGraderFactory: (uuid) => ({
    uuid: uuidv4(),
    type: 'MultipleChoice',
    name: 'Multiple Choice Grader',
    uiComponentUuid: uuid,
    correctAnswer: '',
    points: null,
    feedbacks: [],
  }),
});
