<template>
  <Head :title="`Admin - Edit ${course.name}`"></Head>
  <s-page-block size="md">
    <s-page-header
      :overline="course.institution.name"
      :heading="`${course.code} - ${course.name}`"
      class="mb-4"
    >
      <template #actions>
        <s-btn
          color="blue-light"
          icon="arrow-right"
          iconEnd
          class="w-fit"
          :href="'/courses/' + course.id"
          external
        >
          Go to course
        </s-btn>
      </template>
    </s-page-header>
    <form @submit.prevent="form.patch(route('admin.courses.update', course.id))">
      <s-card>
        <div class="stack vertical gap-4">
          <s-input-field
            id="priceId"
            label="Price ID"
            v-model="form.priceId"
            :error="form.errors.priceId"
          />
          <s-input-field
            id="dueDate"
            label="Due Date"
            type="date"
            v-model="form.paymentDueDate"
            :error="form.errors.paymentDueDate"
          />
          <s-input-field
            id="couponId"
            label="Coupon ID"
            v-model="form.couponId"
            :error="form.errors.couponId"
          />
          <s-select-field id="paymentModel" label="Payment Model" v-model="form.paymentModel">
            <template #tooltip>
              <table class="text-left w-full max-w-screen-sm leading-tight">
                <tr>
                  <th class="font-bold opacity-70 align-top">Payment Model</th>
                  <th class="font-bold opacity-70 align-top">Description</th>
                </tr>
                <tr>
                  <td class="font-bold pr-2 align-top pb-2 whitespace-nowrap">Demo</td>
                  <td class="text-left pb-1">
                    Used for courses that the sales team have setup as a demo to prospective
                    customers.
                  </td>
                </tr>
                <tr>
                  <td class="font-bold pr-2 align-top pb-2 whitespace-nowrap">Free Trial</td>
                  <td class="text-left pb-1">
                    Used for courses that are paid for by the institutions directly. These would not
                    require payment or access codes from students.
                  </td>
                </tr>
                <tr>
                  <td class="font-bold pr-2 align-top pb-2 whitespace-nowrap">
                    Student Subscription
                  </td>
                  <td class="text-left pb-1">
                    Used for courses that are paid for by students either via credit card or by
                    access code. These courses
                    <em>require</em>
                    a Stripe ID to be set.
                  </td>
                </tr>
                <tr>
                  <td class="font-bold pr-2 align-top pb-2 whitespace-nowrap">
                    Institution Subscription
                  </td>
                  <td class="text-left pb-1">
                    Used for courses that the institution purchases access codes in bulk that they
                    then re-sell to the students.
                  </td>
                </tr>
                <tr>
                  <td class="font-bold pr-2 align-top pb-2 whitespace-nowrap">Access Code Only</td>
                  <td class="text-left pb-1">
                    Used for courses that the institution purchases access codes in bulk that they
                    then re-sell to the students.
                  </td>
                </tr>
              </table>
            </template>
            <option v-for="paymentModel in paymentModelOptions" :value="paymentModel">
              {{ paymentModel }}
            </option>
          </s-select-field>
          <s-toggle-field
            label="In App Payment Enabled?"
            name="isInAppPaymentEnabled"
            v-model="form.inAppPaymentEnabled"
            id="inAppPaymentEnabled"
          />
        </div>
        <template #footer>
          <div class="flex justify-between flex-wrap gap-x-2 gap-y-3">
            <s-btn color="secondary" icon="close" :href="route('admin.courses.show', course.id)">
              Cancel
            </s-btn>
            <s-btn type="submit" icon="floppy" class="w-fit" :processing="form.processing">
              Save Changes
            </s-btn>
          </div>
        </template>
      </s-card>
    </form>
  </s-page-block>
</template>

<script setup lang="ts">
import {Course} from '../../../types/entities/course';
import {route} from 'ziggy-js';
import SInputField from '../../../design-system/SInputField.vue';
import {Head, useForm} from '@inertiajs/vue3';
import {timeIgnorantYearMonthDayForInput} from '../../../format/dates';
import SPageBlock from '../../../design-system/SPageBlock.vue';
import SPageHeader from '../../../design-system/SPageHeader.vue';
import SBtn from '../../../design-system/SBtn.vue';
import SToggleField from '../../../design-system/SToggleField.vue';
import SSelectField from '../../../design-system/SSelectField.vue';
import PaymentModel = App.Enums.PaymentModel;
import SDetails from '../../../design-system/SDetails.vue';
import SCard from '../../../design-system/SCard.vue';

const {course} = defineProps<{course: Course; paymentModelOptions: PaymentModel[]}>();

const form = useForm({
  priceId: course.priceId,
  couponId: course.couponId,
  paymentDueDate: timeIgnorantYearMonthDayForInput(course.paymentDueDate),
  inAppPaymentEnabled: course.inAppPaymentEnabled,
  paymentModel: course.paymentModel,
});
</script>
