<template>
  <Head :title="t('page.title', paymentRequest.course.code)"></Head>
  <div class="page-block max-w-screen-md gap-5">
    <s-page-header :heading="paymentRequest.course.name"></s-page-header>

    <div class="flex justify-start gap-6">
      <s-label-value :label="t('label.paymentRequestId')">
        {{ paymentRequest.id }}
      </s-label-value>

      <payment-request-due-date :payment-request="paymentRequest" />
    </div>

    <p>
      <template v-if="paymentRequest.paidOn">
        {{ t('paidOn', monthDaySometimesYear(paymentRequest.paidOn)) }}
      </template>
      <template v-if="paymentRequest.exemptedOn">
        {{ t('exemptedOn', monthDaySometimesYear(paymentRequest.exemptedOn)) }}
      </template>
    </p>

    <form
      v-if="!paymentRequest.paidOn && !paymentRequest.exemptedOn"
      @submit.prevent="form.post(route('billing.access-code', paymentRequest.id))"
    >
      <div class="flex flex-col gap-5">
        <p class="text-lg leading-tight">
          <template v-if="paymentRequest.course.inAppPaymentEnabled">
            {{ t('instructions.allMethodsAvailable') }}
          </template>
          <template v-else>
            {{ t('instructions.accessCodeOnly') }}
          </template>
        </p>

        <div class="w-full flex items-center gap-x-2 gap-y-3 flex-wrap">
          <s-input-field
            v-model="form.accessCode"
            id="accessCode"
            placeholder="00000-00000-00000-00000"
            class="tracking-wide text-lg text-center w-[28ch] px-1"
            maxlength="23"
            :disabled="form.processing"
          />

          <s-btn
            type="submit"
            color="white"
            class="w-fit"
            :processing="form.processing"
            icon="arrow-right"
            icon-end
          >
            {{ t('actions.payWithAccessCode') }}
          </s-btn>
        </div>

        <div class="w-24 relative my-1" v-if="paymentRequest.course.inAppPaymentEnabled">
          <span
            class="uppercase font-bold text-xs tracking-wider text-gray-600 absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2 p-1 bg-gray-100"
          >
            {{ t('or') }}
          </span>
          <span class="block h-px w-full bg-gray-300"></span>
        </div>

        <s-btn
          v-if="paymentRequest.course.inAppPaymentEnabled"
          @click="router.post(route('billing.checkout', paymentRequest.id))"
          icon="credit-card-outline"
          color="white"
          class="w-fit"
          type="button"
          :processing="form.processing"
        >
          {{ t('actions.payOnline') }}
        </s-btn>
      </div>
    </form>
    <div
      v-if="message || form.errors.accessCode"
      class="px-4 py-2 shadow-sm border text-basse rounded w-fit flex items-center gap-2"
      :class="
        messageType === 'success' && form.errors.accessCode?.length === 0
          ? 'bg-green-100 text-green-800 border-green-200'
          : 'bg-red-100 text-red-800 border-red-200'
      "
    >
      <s-icon
        v-if="messageType === 'success' && form.errors.accessCode?.length === 0"
        name="check-decagram"
        class="opacity-70"
        width="18"
        height="18"
      />
      <s-icon v-else name="alert" class="opacity-70" width="18" height="18" />
      <p v-if="message">{{ message }}</p>
      <p v-if="form.errors.accessCode">{{ form.errors.accessCode }}</p>
    </div>
  </div>
</template>
<script setup lang="ts">
import SPageHeader from '../../design-system/SPageHeader.vue';
import SLabelValue from '../../design-system/SLabelValue.vue';
import {PaymentRequest} from '../../types/entities/payment-request';
import SInputField from '../../design-system/SInputField.vue';
import {ref} from 'vue';
import {monthDaySometimesYear} from '../../format/dates';
import {Head, router, useForm} from '@inertiajs/vue3';
import {route} from 'ziggy-js';
import {useBreadcrumbs} from '../../composables/useBreadcrumbs';
import SIcon from '../../design-system/SIcon.vue';
import {useI18n} from 'vue-i18n';
import PaymentRequestDueDate from './components/PaymentRequestDueDate.vue';
import SBtn from '../../design-system/SBtn.vue';

const {paymentRequest} = defineProps<{
  paymentRequest: PaymentRequest;
}>();

const {t} = useI18n({
  inheritLocale: true,
  useScope: 'local',
});
const accessCode = ref<string>('');
const message = ref<string>('');
const messageType = ref<'success' | 'error'>('success');
const form = useForm({
  accessCode: accessCode.value,
});

useBreadcrumbs([{label: t('billing'), href: route('billing.index')}]);
</script>
<i18n>
{
  "en": {
    "page": {
      "title": "{code} - Billing"
    },
    "label": {
      "paymentRequestId": "Payment Request ID"
    },
    "instructions": {
      "allMethodsAvailable": "Please enter a course access code or pay online.",
      "accessCodeOnly": "Please enter a course access code."
    },
    "description": {
      "paidOn": "Paid on {date}",
      "exemptedOn": "You were marked exempt from payment on {date}"
    },
    "actions": {
      "payWithAccessCode": "Pay with Access Code",
      "payOnline": "Pay Online"
    }
  },
  "fr": {
    "page": {
      "title": "{code} - Facturation"
    },
    "label": {
      "paymentRequestId": "ID"
    },
    "instructions": {
      "allMethodsAvailable": "Veuillez entrer un code d'accès au cours ou payer en ligne.",
      "accessCodeOnly": "Veuillez entrer un code d'accès au cours."
    },
    "description": {
      "paidOn": "Payé le {date}",
      "exemptedOn": "Vous avez été marqué exempté du paiement le {date}"
    },
    "actions": {
      "payWithAccessCode": "Payer avec un code d'accès",
      "payOnline": "Payer en ligne"
    }
  }
}
</i18n>
<style scoped>
.comma-separated > *:not(:last-child)::after {
  content: ', ';
}
</style>
