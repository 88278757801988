<template>
  <nav v-if="crumbs.length" :aria-label="t('aria-label.nav')">
    <ol class="text-sm font-medium leading-tight flex flex-wrap">
      <li
        v-for="(breadcrumb, index) in crumbs"
        :key="breadcrumb.href"
        class="flex items-center whitespace-nowrap max-w-full"
      >
        <s-link
          class="inline-block link decoration-1 hover:underline no-underline py-0.5 truncate max-w-full"
          :class="[
            index === 0 ? 'pl-0' : 'pl-1.5',
            index === crumbs.length - 1 ? 'pr-0' : 'pr-1.5',
          ]"
          :key="breadcrumb.href"
          :href="breadcrumb.href"
          :external="breadcrumb.external"
        >
          <s-latex :content="breadcrumb.label" :responsive="true" />
        </s-link>
        <span
          v-if="index < crumbs.length - 1"
          class="breadcrumb-divider opacity-50 text-blue-300 mx-0.5"
          aria-hidden="true"
        >
          /
        </span>
      </li>
    </ol>
  </nav>
</template>
<script setup lang="ts">
import SLink from '../components/SLink.vue';
import SLatex from '../components/SLatex.vue';
import {useI18n} from 'vue-i18n';
import {usePage} from '@inertiajs/vue3';
import {computed} from 'vue';

type Breadcrumb = {
  label: string;
  href: string;
  external?: boolean;
};

const {t} = useI18n({
  inheritLocale: true,
  useScope: 'local',
});

const props = defineProps<{breadcrumbs: Breadcrumb[]}>();

const page = usePage();

const crumbs = computed<Breadcrumb[]>(() => {
  return (page.props.breadcrumbs as Breadcrumb[]) || props.breadcrumbs || [];
});
</script>
<i18n>
{
  "en": {
    "aria-label": {
      "nav": "Breadcrumbs"
    }
  },
  "fr": {
    "aria-label": {
      "nav": "Fil d'Ariane"
    }
  }
}
</i18n>
