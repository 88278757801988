<template>
  <v-chart class="chart" :option="combinedOption" autoresize />
</template>

<script setup lang="ts">
import {computed, ComputedRef} from 'vue';
import type {ComposeOption} from 'echarts';
import {use} from 'echarts/core';
import {PieChart, PieSeriesOption, ScatterChart, ScatterSeriesOption} from 'echarts/charts';
import {CanvasRenderer} from 'echarts/renderers';
import VChart from 'vue-echarts';

import {
  GridComponent,
  LegendComponent,
  LegendComponentOption,
  TitleComponent,
  TitleComponentOption,
  TooltipComponent,
  TooltipComponentOption,
} from 'echarts/components';

use([
  TitleComponent,
  LegendComponent,
  TooltipComponent,
  GridComponent,
  ScatterChart,
  PieChart,
  CanvasRenderer,
]);

type ECOption = ComposeOption<
  | ScatterSeriesOption
  | PieSeriesOption
  | TitleComponentOption
  | TooltipComponentOption
  | LegendComponentOption
>;

const props = defineProps<{
  clusterData: {
    title: string | null;
    count: number;
    points?: {
      embedding: number[];
      mistake_label: string;
    }[];
  }[];
}>();

const pieData = computed(() => {
  return props.clusterData.map((cluster, index) => ({
    name: cluster.title ?? `Cluster ${index + 1}`, // Fallback for null titles
    value: cluster.count,
  }));
});

const data = computed(() => {
  const scatterData: Array<[number, number, number, string]> = []; // [x, y, clusterIndex, Label]

  props.clusterData.forEach((cluster, index) => {
    if (cluster.points) {
      cluster.points.forEach((point) => {
        const [x, y] = point.embedding;
        scatterData.push([x, y, index, point.mistake_label]);
      });
    }
  });

  return scatterData;
});

const colorPalette = [
  '#ff5733',
  '#33b5e5',
  '#ffcc33',
  '#2ecc71',
  '#9b59b6',
  '#e74c3c',
  '#f39c12',
  '#3498db',
  '#1abc9c',
  '#8e44ad',
];

const combinedOption: ComputedRef<any> = computed(() => ({
  title: {
    text: 'Student Mistake Visualizations',
    left: '20%',
    top: '5%',
  },
  tooltip: {
    trigger: 'item',
    formatter: (params: any) => {
      if (params.seriesType === 'scatter') {
        const clusterIndex = params.data[2];
        const mistakeLabel = params.data[3];
        const cluster = props.clusterData[clusterIndex];
        const title = cluster.title ?? `${clusterIndex + 1}`;
        return `Student Mistake: ${mistakeLabel}<br>Cluster: ${title}`;
      } else if (params.seriesType === 'pie') {
        return `${params.name}: ${params.value} (${params.percent}%)`;
      }
    },
  },
  legend: {
    orient: 'vertical',
    top: '2%',
    left: '70%',
    data: props.clusterData.map((cluster, index) => ({
      name: cluster.title ?? `Cluster ${index + 1}`,
      icon: 'circle',
    })),
    textStyle: {
      color: '#333',
    },
  },
  grid: [
    {
      left: '10%',
      right: '50%',
      bottom: '10%',
    },
    {
      left: '10%',
      right: '50%',
      bottom: '50%',
    },
  ],
  xAxis: {
    type: 'value',
    name: 'PCA 1',
    gridIndex: 0,
  },
  yAxis: {
    type: 'value',
    name: 'PCA 2',
    gridIndex: 0,
  },
  series: [
    {
      name: 'Clusters',
      type: 'scatter',
      data: data.value,
      symbolSize: 10,
      itemStyle: {
        color: (params: any) => {
          const clusterIndex = params.data[2];
          return colorPalette[clusterIndex % colorPalette.length];
        },
      },
    },
    {
      name: 'Mistake Labels',
      type: 'pie',
      radius: ['15%', '30%'],
      center: ['75%', '50%'],
      data: pieData.value.map((item, index) => ({
        ...item,
        itemStyle: {
          color: colorPalette[index % colorPalette.length],
        },
      })),
      emphasis: {
        itemStyle: {
          shadowBlur: 10,
          shadowOffsetX: 0,
          shadowOffsetY: 0,
          shadowColor: 'rgba(0, 0, 0, 0.5)',
        },
      },
    },
  ],
}));
</script>

<style scoped>
.chart {
  width: 100%;
  aspect-ratio: 2;
  max-height: 100vh;
}
</style>
