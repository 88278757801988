<template>
  <s-input-field id="example" type="number" :label="label" :size="size" :error="error" />
</template>
<script setup lang="ts">
import SInputField from '../../../../design-system/SInputField.vue';

defineProps<{
  label?: string;
  description?: string;
  size?: 'sm' | 'md' | 'lg';
  error?: string;
}>();
</script>
<script lang="ts">
import {ExampleOptions} from '../../examples';

export const options: ExampleOptions = {
  controls: [
    {
      type: 'input',
      prop: 'label',
      label: 'Label',
      default: 'Salary',
    },
    {
      type: 'input',
      prop: 'error',
      label: 'Error',
      default: null,
    },
    {
      type: 'input',
      prop: 'prefix',
      label: 'Prefix',
      default: '$',
    },
    {
      type: 'input',
      prop: 'suffix',
      label: 'suffix',
      default: '/ hour',
    },
    {
      type: 'select',
      prop: 'size',
      label: 'Size',
      options: ['custom', 'xs', 'sm', 'md', 'lg'],
      default: null,
    },
  ],
};
</script>
