<template>
  <task-form :form="form" :errors="errors" class="fieldset" />
</template>
<script lang="ts" setup>
import WatchVideoTaskFormDto = App.DTOs.Tasks.WatchVideoTaskFormDto;
import TaskForm from '../../../forms/TaskForm.vue';
import {useForm} from '@inertiajs/vue3';

const props = defineProps<{modelValue?: WatchVideoTaskFormDto; errors?: Record<string, any>}>();

const form = useForm<WatchVideoTaskFormDto>(
  () =>
    props.modelValue || {
      type: 'WatchVideoTask',
      status: 'draft',
      title: '',
      textbookLink: null,
      textbookLinkText: null,
      textbookLinkIcon: null,
      youtubeId: null,
      draftable: false,
    }
);
</script>
