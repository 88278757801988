<template>
  <s-field v-bind="$props">
    <template v-slot="a11yProps">
      <s-toggle v-bind="{...a11yProps, ...$attrs}" :id="id" v-model="model" />
    </template>
  </s-field>
</template>

<script setup lang="ts">
import SField from './SField.vue';
import SToggle from './SToggle.vue';

defineProps<{
  id: string;
  label?: string;
  description?: string;
  tooltip?: string;
  error?: string;
  required?: boolean;
  class?: string | Record<string, boolean | string>;
}>();

const model = defineModel<boolean>();
</script>
