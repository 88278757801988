import {Grade} from '../../../types/entities/base-grade';
import {TaskResponse} from '../../../types/entities/tasks';

export type TaskResponseState =
  | 'incomplete'
  | 'ungraded'
  | 'incorrect'
  | 'partial'
  | 'correct'
  | 'draft';

export type TaskResponseStateFields = Pick<TaskResponse, 'id' | 'isDraft'>;

export interface DetermineTaskStateProps {
  attemptedTaskIds: number[];
  draftTaskIds: number[];
  studentAssignmentTaskGrades: Grade[];
}

export function determineGradeState(grade: Grade): TaskResponseState {
  if (grade.originalNumerator === 0) {
    return 'incorrect';
  }

  if (grade.originalNumerator === grade.originalDenominator) {
    return 'correct';
  }

  return 'partial';
}

export function determineTaskResponseState(
  studentAssignmentTaskGrades: Grade[],
  response?: TaskResponseStateFields | null,
  grade?: Grade | null
): TaskResponseState {
  if (response?.isDraft) {
    return 'draft';
  }

  /**
   * Lookup most recent grade for the response if not provided.
   *
   * Useful for the task-navigation.
   */
  if (!grade) {
    const orderedResponseGrades = studentAssignmentTaskGrades
      .filter((grade) => grade.responseId === response?.id)
      .sort((a, b) => b.id - a.id);

    if (orderedResponseGrades?.length) {
      grade = orderedResponseGrades.find((grade) => grade.isEffective) || orderedResponseGrades[0];
    }
  }

  if (grade) {
    return determineGradeState(grade);
  }

  return 'ungraded';
}

export function determineTaskState(
  props: DetermineTaskStateProps,
  taskId: number
): TaskResponseState {
  const grade = props.studentAssignmentTaskGrades
    .filter((grade) => grade.taskId === taskId && grade.isEffective)
    .sort((a, b) => b.id - a.id)[0];
  const attempted = props.attemptedTaskIds.includes(taskId);
  const draft = props.draftTaskIds.includes(taskId);

  if (grade) {
    return determineGradeState(grade);
  }

  if (!attempted) {
    if (draft) {
      return 'draft';
    }

    return 'incomplete';
  }

  return 'ungraded';
}

export function useTaskStateHelpers(props: DetermineTaskStateProps) {
  function determineTaskResponseStateUtil(
    response?: TaskResponseStateFields | null,
    grade?: Grade | null
  ): TaskResponseState {
    return determineTaskResponseState(props.studentAssignmentTaskGrades, response, grade);
  }

  function determineTaskStateUtil(taskId: number): TaskResponseState {
    return determineTaskState(props, taskId);
  }

  return {
    determineGradeState,
    determineTaskResponseState: determineTaskResponseStateUtil,
    determineTaskState: determineTaskStateUtil,
  };
}

export function isTaskResponseEffective(responseId: number, grades: Grade[]): boolean {
  return grades.some((grade) => grade.responseId === responseId && grade.isEffective);
}
