<template>
  <s-input-field
    id="linkText"
    label="Text"
    :model-value="text ?? ''"
    :error="errors?.text"
    @update:modelValue="emit('update:text', $event)"
  />
  <s-input-field
    id="linkHref"
    label="Link"
    :model-value="href ?? ''"
    :error="errors?.href"
    @update:modelValue="emit('update:href', $event)"
  />
</template>

<script setup lang="ts">
import SInputField from '../../../../design-system/SInputField.vue';

defineProps<{
  text: string | null;
  href: string | null;
  errors?: Record<string, any>;
}>();

const emit = defineEmits<{
  (evt: 'update:text', text: string): void;
  (evt: 'update:href', href: string): void;
}>();
</script>
