import {
  differenceInDays as baseDifferenceInDays,
  differenceInMonths,
  format as baseFormat,
  formatDistance as formatDistanceInternal,
  isPast,
} from 'date-fns';
import {enCA, enUS, fr, frCA} from 'date-fns/locale';
import {parseISO} from 'date-fns/fp';
import {useI18n} from 'vue-i18n';

const locales = {
  en: enUS,
  'en-CA': enCA,
  fr,
  'fr-CA': frCA,
};

export type MonthDayYearOptions = {
  shortMonth?: boolean;
  time?: boolean;
};

type MaybeDateString = string | null | undefined;
type MaybeDate = Date | MaybeDateString;

const getLocale = () => {
  try {
    const {locale} = useI18n();

    return locale.value;
  } catch (e) {
    return 'en';
  }
};

export const format = (date: MaybeDate, formatString: string) => {
  date = normalizeDate(date);
  if (!date) return '';

  const locale = getLocale();

  return baseFormat(date, formatString, {
    // @ts-ignore
    locale: locales[locale],
  });
};

export const normalizeDate = (maybeDate: MaybeDate): Date | null => {
  if (!maybeDate) {
    return null;
  }
  return maybeDate instanceof Date ? maybeDate : new Date(maybeDate);
};

const buildFormatStringForMonthDayYearOptions = (options: MonthDayYearOptions) => {
  const month = options.shortMonth ? 'MMM' : 'MMMM';
  let day = 'do';
  const year = 'yyyy';
  let time = '';

  const locale = getLocale();

  if (options.time) {
    if (locale.startsWith('fr')) {
      time = " 'à' p";
    } else {
      time = " '@' p";
    }
  }

  if (locale.startsWith('fr')) {
    day = 'd';
    return `${day} ${month}  ${year}${time}`;
  }

  return `${month} ${day}, ${year}${time}`;
};

export const shortMonthYear = (date: MaybeDate) => {
  return format(date, 'MMM yyyy');
};

export const monthDayYear = (date: MaybeDate, options: MonthDayYearOptions = {}) => {
  return format(date, buildFormatStringForMonthDayYearOptions(options));
};

/**
 * Returns a string like "Mon, Jan 1st"
 * @param maybeDate
 */
export const shortDayOfWeekMonthDayOfMonth = (maybeDate: MaybeDate) => {
  const date = normalizeDate(maybeDate);
  if (!date) return '';

  if (date.getFullYear() !== new Date().getFullYear()) {
    return format(date, 'EEE, MMM do yyyy');
  }

  return format(date, 'EEE, MMM do');
};

export const monthDaySometimesYear = (maybeDate: MaybeDate, options: MonthDayYearOptions = {}) => {
  const date = normalizeDate(maybeDate);
  if (!date) return '';
  // If the date is in the current year, don't show the year
  if (date.getFullYear() === new Date().getFullYear()) {
    return format(
      date,
      buildFormatStringForMonthDayYearOptions(options)
        .replace(/, yyyy/, '')
        .replace(/yyyy/, '')
    );
  }
  return format(date, buildFormatStringForMonthDayYearOptions(options));
};

export const timeOfDay = (maybeDate: MaybeDate) => {
  return format(maybeDate, 'p');
};

export const timeIgnorantMonthDayYear = (
  dateString: MaybeDateString,
  options: MonthDayYearOptions = {}
) => {
  if (!dateString) {
    return '';
  }

  const date = parseISO(dateString);
  const dateWithOffset = adjustForUTCOffset(date);

  return format(dateWithOffset, buildFormatStringForMonthDayYearOptions(options));
};

export const timeIgnorantYearMonthDayForInput = (dateString: MaybeDateString) => {
  if (!dateString) {
    return '';
  }

  const date = parseISO(dateString);
  const dateWithOffset = adjustForUTCOffset(date);

  return format(dateWithOffset, 'yyyy-MM-dd');
};

export const adjustForUTCOffset = (date: Date) => {
  return new Date(
    date.getUTCFullYear(),
    date.getUTCMonth(),
    date.getUTCDate(),
    date.getUTCHours(),
    date.getUTCMinutes(),
    date.getUTCSeconds()
  );
};

export const isDateOverSixMonthsAgo = (maybeDate: MaybeDate) => {
  const date = normalizeDate(maybeDate);
  if (!date) return false;
  return isPast(date) && differenceInMonths(new Date(), date) > 6;
};

export const isDatePast = (maybeDate: MaybeDate) => {
  const date = normalizeDate(maybeDate);
  if (!date) return false;
  return isPast(date);
};

export const formatDistance = (
  from: MaybeDate,
  to: MaybeDate,
  options: Parameters<typeof formatDistanceInternal>[2]
) => {
  const date = normalizeDate(from);
  const baseDate = normalizeDate(to);

  if (!date || !baseDate) return '';

  return formatDistanceInternal(date, baseDate, options);
};

export const getDifferenceInDaysHoursMinutes = (start: MaybeDate, end: MaybeDate) => {
  const startDate = normalizeDate(start);
  const endDate = normalizeDate(end);

  if (!startDate || !endDate) return {days: 0, hours: 0, minutes: 0};

  const differenceMs = endDate.getTime() - startDate.getTime();

  const days = Math.floor(differenceMs / (1000 * 60 * 60 * 24));
  const hours = Math.floor((differenceMs % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
  const minutes = Math.floor((differenceMs % (1000 * 60 * 60)) / (1000 * 60));

  return {days, hours, minutes};
};

export const differenceInDays = (start: MaybeDate, end: MaybeDate) => {
  const startDate = normalizeDate(start);
  const endDate = normalizeDate(end);

  if (!startDate || !endDate) return 0;

  return baseDifferenceInDays(startDate, endDate);
};

export const minutesToHumanReadable = (minutes: number) => {
  const hours = Math.floor(minutes / 60);
  const remainingMinutes = minutes % 60;

  const {t} = useI18n();

  if (hours > 0 && remainingMinutes > 0) {
    return `${t('time.xHours', hours)}, ${t('time.xMinutes', remainingMinutes)}`;
  } else if (hours > 0) {
    return t('time.xHours', hours);
  } else {
    return t('time.xMinutes', remainingMinutes);
  }
};

/**
 * Given a date string from the server, it returns a formatted date string that can
 * be used as an input value for <input type="date" >
 */
export const formatForDateInput = (dateString: MaybeDateString): string => {
  if (!dateString) {
    return '';
  }

  const date = normalizeDate(dateString);

  return date?.toISOString().split('T')[0] ?? '';
};
