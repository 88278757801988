<template>
  <div class="flex flex-wrap items-center gap-x-2.5 gap-y-2">
    <Switch
      v-model="internalValue"
      :class="internalValue ? 'from-white to-gray-50' : 'from-gray-150 to-gray-100'"
      class="relative bg-gradient-to-r inline-flex rounded-full p-0.5 pr-6 shadow-inset border border-gray-300/50 outline outline-transparent outline-2 outline-offset-2 focus-within:outline-blue-400 shrink-0 cursor-pointer transition-colors duration-200 ease-in-out focus:outline-none focus-visible:ring-2 focus-visible:ring-white/75"
    >
      <span class="sr-only">{{ internalValue ? t('status.enabled') : t('status.disabled') }}</span>
      <span
        aria-hidden="true"
        :class="
          internalValue
            ? 'text-green-600 hover:text-green-500 border border-green-300/70 bg-green-200 hover:bg-green-200/70 active:bg-green-100 shadow-btn-white translate-x-3/4'
            : 'text-blue-500 hover:text-blue-600 active:text-blue-500 bg-white border border-gray-300/50 hover:bg-gray-50 active:bg-gray-100 shadow-btn-white'
        "
        class="w-7 h-7 flex items-center justify-center pointer-events-none transform rounded-full ring-0 transition duration-200 ease-out"
      >
        <s-icon
          :name="internalValue ? 'check-bold' : 'close-thick'"
          :class="internalValue ? 'opacity-70' : 'opacity-40'"
          class="transition"
          size="16"
        />
      </span>
    </Switch>
    <button
      @click.prevent="internalValue = !internalValue"
      class="cursor-pointer font-bold text-gray-700 leading-tight"
      :class="
        internalValue
          ? 'text-green-600 hover:text-green-500'
          : 'text-gray-600/60 hover:text-blue-500'
      "
    >
      {{ internalValue ? t('status.enabled') : t('status.disabled') }}
    </button>
  </div>
</template>

<script lang="ts" setup>
import {useVModel} from '@vueuse/core';
import {Switch} from '@headlessui/vue';
import {defineProps, defineEmits} from 'vue';
import SIcon from './SIcon.vue';
import {useI18n} from 'vue-i18n';

const props = defineProps({
  modelValue: {
    type: Boolean,
    default: false,
  },
});

const emit = defineEmits(['update:modelValue']);
const internalValue = useVModel(props, 'modelValue', emit);

const {t} = useI18n({
  useScope: 'local',
  inheritLocale: true,
});
</script>
