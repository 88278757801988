<template>
  <s-page-block>
    <s-page-header heading="Create a new Task Type"></s-page-header>
  </s-page-block>
  <s-page-block>
    <form
      @submit.prevent="form.post(route('admin.tasks.evaluations.store', {task: task.id}))"
      class="flex flex-col gap-4"
    >
      <s-input-field
        id="title"
        label="Evaluation Title"
        v-model="form.title"
        :error="form.errors.title"
      />
      <s-input-field
        id="description"
        label="Description"
        v-model="form.description"
        :error="form.errors.description"
      />
      <s-btn type="submit">Create Task Evaluation</s-btn>
    </form>
  </s-page-block>
</template>
<script setup lang="ts">
import SPageBlock from '../../../design-system/SPageBlock.vue';
import SPageHeader from '../../../design-system/SPageHeader.vue';
import {useForm} from '@inertiajs/vue3';
import SInputField from '../../../design-system/SInputField.vue';
import SBtn from '../../../design-system/SBtn.vue';
import {route} from 'ziggy-js';
import {Task} from '../../../types/entities/tasks';

defineProps<{
  task: Task;
}>();

const form = useForm({
  title: '',
  description: '',
});
</script>
