<template>
  <v-chart
    ref="chart"
    class="chart"
    :init-options="{renderer: 'svg'}"
    :option="option"
    autoresize
    renderer="svg"
  />
</template>
<script setup lang="ts">
import {computed, provide} from 'vue';
import {use} from 'echarts/core';
import {CanvasRenderer, SVGRenderer} from 'echarts/renderers';
import {
  BarChart,
  BarSeriesOption,
  LineChart,
  LineSeriesOption,
  ScatterChart,
  ScatterSeriesOption,
} from 'echarts/charts';
import {
  GridComponent,
  GridComponentOption,
  LegendComponent,
  LegendComponentOption,
  MarkLineComponent,
  MarkLineComponentOption,
  MarkPointComponent,
  MarkPointComponentOption,
  ToolboxComponent,
  ToolboxComponentOption,
  TooltipComponent,
  TooltipComponentOption,
} from 'echarts/components';
import VChart, {THEME_KEY} from 'vue-echarts';
import {UniversalTransition} from 'echarts/features';
import {ScatterControllerChartOptions} from 'chart.js';
import {useI18n} from 'vue-i18n';

export type Participant = {
  id: number;
  firstName: string;
  lastName: string;
  email: string;
  roles: string;
  sections: string;
  numReviewedGrades: number;
};

export type TaskPerformanceData = {
  id: number;
  orderingIndex: number;
  averageGrade: number;
  numGrades: number;
  numOverrides: number;
  reviewRequired: boolean;
};

const props = defineProps<{
  reviewers: Participant[];
  tasks: TaskPerformanceData[];
  tasksByReviewer: {
    [key: number]: TaskPerformanceData[];
  };
}>();

provide(THEME_KEY, 'light');

use([
  GridComponent,
  BarChart,
  ScatterChart,
  LineChart,
  CanvasRenderer,
  TooltipComponent,
  LegendComponent,
  ToolboxComponent,
  MarkPointComponent,
  MarkLineComponent,
  UniversalTransition,
  SVGRenderer,
]);

type EChartsOption = echarts.ComposeOption<
  | GridComponentOption
  | BarSeriesOption
  | LineSeriesOption
  | ToolboxComponentOption
  | TooltipComponentOption
  | LegendComponentOption
  | MarkPointComponentOption
  | MarkLineComponentOption
  | ScatterSeriesOption
>;

const option = computed<EChartsOption>(() => {
  const toFullName = (reviewer: Participant) => reviewer.firstName + ' ' + reviewer.lastName;

  let courseAvgGrades = props.tasks.map((task) => decimalToPercent(task.averageGrade));
  return {
    legend: {
      data: [t('classAverage'), ...props.reviewers.map(toFullName)],
    },
    tooltip: {
      trigger: 'axis',
    },
    xAxis: {
      type: 'category',
      data: props.tasks.map((task) => task.orderingIndex),
      axisLabel: {formatter: t('task') + ' {value}'},
    },
    yAxis: {
      type: 'value',
      min: 0,
      max: 100,
      axisLabel: {formatter: '{value}%'},
      name: t('averageGrade'),
      nameLocation: 'middle',
      nameGap: 40,
    },
    series: [
      {
        type: 'scatter',
        name: t('classAverage'),
        data: courseAvgGrades,
        tooltip: {
          valueFormatter: (value) => `${value}%`,
        },
        itemStyle: {
          color: 'rgba(3,0,0,0.8)',
        },
      },
      ...Object.keys(props.tasksByReviewer).map((reviewerId): BarSeriesOption => {
        const reviewer = props.reviewers.find((reviewer) => reviewer.id === ~~reviewerId)!;
        let tasksByReviewerElement = props.tasksByReviewer[~~reviewerId] || [];

        return {
          type: 'bar',
          barGap: '0%',
          barCategoryGap: '10%',
          name: `${reviewer.firstName} ${reviewer.lastName}`,
          data: props.tasks.map((task) => {
            const taskByReviewer = tasksByReviewerElement.find(
              (taskByReviewer) => taskByReviewer.id === task.id
            );
            return decimalToPercent(taskByReviewer?.averageGrade || 0);
          }),
          showBackground: true,
          backgroundStyle: {
            color: 'rgba(210,206,206,0.2)', // background color of the bar
          },
          tooltip: {
            valueFormatter: (value) => `${value}%`,
          },
        };
      }),
    ],
  };
});

const decimalToPercent = (value: number) => {
  return Math.floor(value * 100);
};

const {t} = useI18n({
  scope: 'local',
  inheritLocale: false,
});
</script>
<style scoped>
.chart {
  width: 100%;
  aspect-ratio: 16 / 9;
  max-height: 40vh;
}
</style>
<i18n>
{
  "en": {
    "classAverage": "Class Average",
    "task": "Task",
    "averageGrade": "Average Grade (%)"

  },
  "fr": {
    "classAverage": "Moyenne de la Classe",
    "task": "Tâche",
    "averageGrade": "Note moyenne (%)"
  }
}
</i18n>
