<template>
  <div class="flex items-center justify-center h-0">
    <s-tooltip v-if="verified" :text="t('verified')">
      <s-icon name="check-decagram" size="18" class="text-green-500" />
    </s-tooltip>
    <s-tooltip v-else-if="sent">
      <template #tooltip>{{ t('sent') }}</template>
      <s-icon name="email-fast" size="18" class="text-blue-500" />
      <span class="sr-only">{{ t('sent') }}</span>
    </s-tooltip>
    <s-tooltip v-else>
      <template #tooltip>{{ t('not-sent') }}</template>
      <s-icon name="email-off" size="18" class="text-gray-400/80" />
      <span class="sr-only">{{ t('not-sent') }}</span>
    </s-tooltip>
  </div>
</template>
<script setup lang="ts">
import {defineProps, withDefaults} from 'vue';
import STooltip from '../design-system/STooltip.vue';
import {useI18n} from 'vue-i18n';
import SIcon from '../design-system/SIcon.vue';

const props = defineProps<{
  sent: boolean;
  verified: boolean;
}>();

const {t} = useI18n({
  inheritLocale: true,
  useScope: 'local',
});
</script>
<i18n>
{
  "en": {
      "verified": "This user is registered.",
      "sent": "An invitation email has been sent.",
      "not-sent": "No invitation has been sent."
  },
  "fr": {
    "verified": "Cet utilisateur est inscrit.",
    "sent": "Un courriel d'invitation a été envoyé.",
    "not-sent": "Aucune invitation n'a été envoyée."
  }
}
</i18n>
