<template>
  <s-page-block>
    <s-page-header
      :heading="`Response ${taskResponseEvaluation.response.id} for ${taskEvaluation.title}`"
    ></s-page-header>
    <div class="card padded">
      <h2>Task Response Evaluation Meta Data</h2>
      <pre>
        <code>
          {{ JSON.stringify(taskResponseEvaluation.response, null, 2) }}
        </code>
      </pre>
    </div>
    <div class="card padded">
      <h2>
        <s-link
          method="post"
          :href="
            route('admin.tasks.responses.edit', {
              task: props.task.id,
              response: referenceResponse.id,
            })
          "
        >
          View/Edit Original Response (Reference)
        </s-link>
      </h2>
      <pre>
        <code>
          {{ JSON.stringify(referenceResponse, null, 2) }}
        </code>
      </pre>
    </div>
    <div class="card padded">
      <h2>Evaluation Result</h2>
      <pre>
        <code>
          {{ JSON.stringify(response, null, 2) }}
        </code>
      </pre>
    </div>
  </s-page-block>
</template>
<script setup lang="ts">
import SPageBlock from '../../../design-system/SPageBlock.vue';
import SPageHeader from '../../../design-system/SPageHeader.vue';
import {Task} from '../../../types/entities/tasks';
import {useBreadcrumbs} from '../../../composables/useBreadcrumbs';
import {route} from 'ziggy-js';
import SLink from '../../../components/SLink.vue';
import TaskEvaluationDto = App.DTOs.TaskEvaluationDto;
import TaskResponseEvaluationDto = App.DTOs.TaskResponseEvaluationDto;
import TaskResponseDto = App.DTOs.Entities.Tasks.TaskResponseDto;

const props = defineProps<{
  task: Task;
  taskEvaluation: TaskEvaluationDto;
  taskResponseEvaluation: TaskResponseEvaluationDto;
  response: TaskResponseDto;
  referenceResponse: TaskResponseDto;
}>();

useBreadcrumbs([
  {label: 'Tasks', href: route('admin.tasks.index')},
  {label: props.task.title, href: route('admin.tasks.show', {task: props.task.id})},
  {label: 'Evaluations', href: route('admin.tasks.evaluations.index', {task: props.task.id})},
  {
    label: props.taskEvaluation.title,
    href: route('admin.tasks.evaluations.show', {
      task: props.task.id,
      taskEvaluation: props.taskEvaluation.id,
    }),
  },
]);
</script>
