<template>
  <Head title="Admin - Courses"></Head>
  <div class="page-block gap-5">
    <s-page-header heading="Courses">
      <template #actions>
        <s-btn icon="plus" href="/courses/create" external>Create course</s-btn>
        <s-btn icon="poll" color="secondary" :href="route('admin.courses.analytics')">
          Analytics
        </s-btn>
      </template>
    </s-page-header>

    <div class="text-sm grow flex flex-col sm:flex-row gap-2">
      <s-select-field id="institution" label="Institution" v-model="institution">
        <option value="">Any</option>
        <option v-for="institution in institutions" :value="institution.id">
          {{ institution.abbreviation }}: {{ institution.name }}
        </option>
      </s-select-field>
      <s-select-field id="paymentModel" label="Payment Model" v-model="paymentModel">
        <option value="">Any</option>
        <option v-for="paymentModel in paymentModelOptions" :value="paymentModel">
          {{ paymentModel }}
        </option>
      </s-select-field>
      <s-select-field id="isPriceIdSet" label="Price ID" v-model="isPriceIdSet">
        <option value="">–</option>
        <option value="set">Set</option>
        <option value="not_set">Not Set</option>
      </s-select-field>
    </div>
    <div>
      <s-search-section
        route-name="admin.courses.index"
        label="Search by course name, course code, or institution name"
      />
    </div>

    <section class="stack vertical gap-3">
      <s-link
        v-for="course in courses?.data"
        :href="route('admin.courses.show', course.id)"
        class="card padded stack horizontal align-between valign-center gap-3"
      >
        <div class="stack vertical gap-2 w-full">
          <div>
            <div class="text-sm text-slate-600">{{ course.institution.name }}</div>
            <div class="text-lg">
              <strong>{{ course.code }}:</strong>
              {{ course.name }}
            </div>
          </div>

          <div class="grid grid-cols-1 sm:grid-cols-4 gap-4">
            <s-label-value label="Start Date">
              <span v-if="!!course.startDate">
                {{ monthDayYear(course.startDate, {shortMonth: true}) }}
              </span>
              <span v-else>Unknown</span>
            </s-label-value>

            <template
              v-if="!course.paymentModel || paymentRequiredModels.includes(course.paymentModel)"
            >
              <s-label-value label="Payment Due">
                <span v-if="course.paymentDueDate">
                  {{ monthDayYear(course.paymentDueDate, {shortMonth: true}) }}
                  <small>
                    ({{ differenceInDays(course.paymentDueDate, course.startDate) }} days)
                  </small>
                </span>
                <span v-else class="bg-red-600 text-white px-1 rounded-md">Not set</span>
              </s-label-value>

              <s-label-value label="Payment Model">
                <s-badge v-if="course.paymentModel" class="capitalize">
                  {{ course.paymentModel.replace('_', ' ') }}
                </s-badge>
                <s-badge v-else color="red">Not Set</s-badge>
              </s-label-value>

              <s-label-value label="Price ID">
                <s-badge v-if="course.priceId" color="green" class="overflow-x-hidden">
                  {{ course.priceId.slice(0, 16) }}
                </s-badge>
                <s-badge v-else color="red">Not Set</s-badge>
              </s-label-value>
            </template>
          </div>
        </div>
        <div class="stack horizontal gap-4">
          <s-icon name="chevron-right" />
        </div>
      </s-link>
      <s-paginator :paginator="courses" />
    </section>
  </div>
</template>
<script setup lang="ts">
import {LaravelPaginator} from '../../../types/laravel-paginator';
import SLink from '../../../components/SLink.vue';
import {route} from 'ziggy-js';
import SPaginator from '../../../design-system/SPaginator.vue';
import {Course} from '../../../types/entities/course';
import SPageHeader from '../../../design-system/SPageHeader.vue';
import {differenceInDays, monthDayYear} from '../../../format/dates';
import SSearchSection from '../../../design-system/SSearchSection.vue';
import SSelectField from '../../../design-system/SSelectField.vue';
import {Head, router} from '@inertiajs/vue3';
import SBtn from '../../../design-system/SBtn.vue';
import SLabelValue from '../../../design-system/SLabelValue.vue';
import SBadge from '../../../design-system/SBadge.vue';
import SIcon from '../../../design-system/SIcon.vue';
import {Institution} from '../../../types/entities/institution';
import {ref, watch} from 'vue';
import PaymentModel = App.Enums.PaymentModel;

defineProps<{
  courses: LaravelPaginator<Course>;
  institutions: Institution[];
  paymentModelOptions: string[];
}>();

const institution = ref<string>((route().params.institution as string) || '');
const paymentModel = ref<string>((route().params.paymentModel as string) || '');
const isPriceIdSet = ref<string>((route().params.isPriceIdSet as string) || '');

const paymentRequiredModels: PaymentModel[] = ['student_subscription', 'access_code_only'];

watch(
  [institution, paymentModel, isPriceIdSet],
  ([newInstitution, newPaymentModel, newIsPriceIdSet]) => {
    router.replace(
      route('admin.courses.index', {
        institution: newInstitution,
        paymentModel: newPaymentModel,
        isPriceIdSet: newIsPriceIdSet,
      })
    );
  }
);
</script>
