<template>
  <nav :aria-label="t('aria-label.nav')">
    <div class="flex flex-wrap gap-y-2.5 w-full">
      <s-link
        v-for="page in paginator.links"
        :href="page.url"
        class="flex-1 max-w-[3.5rem] first:max-w-[8rem] last:max-w-[8rem] first:rounded-l-full last:rounded-r-full text-center leading-tight tracking-slight whitespace-nowrap block outline-transparent outline-4 outline-offset-4 focus:outline-blue-400 flex items-center justify-center gap-1.5 px-3 py-2.5 text-sm leading-tight font-medium -mr-px last:mr-0"
        :class="{
          // Current Page or Disabled
          'pointer-events-none': page.active || !page.url,
          // Disabled
          'text-gray-500 bg-white/30 cursor-not-allowed': !page.url,
          // Current Page
          'text-white border border-primary-700/30 bg-primary-600 shadow-btn-blue': page.active,
          // Not Current Page
          'border border-gray-300/50': !page.active,
          // Not Current Page & Not Disabled
          'text-blue-600 bg-white shadow-btn-white cursor-pointer hover:bg-gray-50 active:bg-gray-100 transition-all ease-out duration-150':
            !page.active && page.url,
        }"
        :aria-label="getAriaLabel(page)"
        v-bind="{
          ['aria-current']: page.active ? 'page' : 'false',
          ['aria-disabled']: !page.url,
          ...inertiaLinkProps,
        }"
      >
        {{ page.label }}
      </s-link>
    </div>
  </nav>
</template>
<script setup lang="ts">
import {LaravelPaginator, LaravelPaginatorLink} from '../types/laravel-paginator';
import SLink from '../components/SLink.vue';
import {computed} from 'vue';
import {useI18n} from 'vue-i18n';

const props = defineProps<{
  paginator: LaravelPaginator<any>;
  preserveState?: boolean;
  only?: string[];
}>();

const {t} = useI18n({
  inheritLocale: true,
  useScope: 'local',
});

const getAriaLabel = (page: LaravelPaginatorLink) => {
  // Check if label can be converted to a number
  if (!isNaN(Number(page.label))) {
    return t('aria-label.page-number', {number: page.label});
  }

  if (page.label === '...') {
    return t('aria-label.etc');
  }

  return t('aria-label.' + page.label);
};

const inertiaLinkProps = computed(() => {
  const conditionalPropsToAdd: {[key: string]: any} = {};

  if (props.preserveState) {
    conditionalPropsToAdd['preserve-state'] = true;
  }

  if (props.only) {
    conditionalPropsToAdd['only'] = props.only;
  }

  return conditionalPropsToAdd;
});
</script>
<i18n>
{
  "en": {
    "aria-label": {
      "nav": "Pagination",
      "Next": "Next Page",
      "Previous": "Previous Page",
      "etc": "More Pages",
      "page-number": "Page {number}"
    }
  },
  "fr": {
    "aria-label": {
      "nav": "Pagination",
      "Next": "Page suivante",
      "Previous": "Page précédente",
      "etc": "Plus de pages",
      "page-number": "Page {number}"
    }
  }
}
</i18n>
