<template>
  <s-page-block>
    <s-page-header :heading="t('heading')" class="mb-4"></s-page-header>

    <payment-required-banner
      v-if="pendingPaymentRequests.length"
      :payment-requests="pendingPaymentRequests"
      class="mb-3 -mt-1"
    />

    <h2 v-if="courses?.length" class="heading mb-3">{{ t('courses.heading') }}</h2>
    <ul
      class="group/list grid md:grid-cols-[repeat(auto-fit,_minmax(300px,_1fr))] gap-3 place-items-stretch"
    >
      <li v-for="course in courses" :key="course.id">
        <s-link
          :href="route('courses.show', {course: course.id})"
          class="group flex items-center justify-between card-soft sm padded border border-gray-200 hover:shadow-lg text-gray-800 hover:text-blue-600 transition group-hover/list:opacity-70 hover:!opacity-100"
        >
          <div>
            <div class="text-sm opacity-80">
              {{ course.institution.name }}
            </div>
            <strong class="text-lg leading-tight">{{ course.code }}</strong>
          </div>
          <mdicon
            name="ChevronRight"
            class="opacity-70 group-hover:text-blue-400 group-hover:opacity-100 transition"
          />
        </s-link>
      </li>
    </ul>
  </s-page-block>

  <!-- STUDENT ASSIGNMENT SECTION -->
  <s-page-block v-if="studentAssignments.length">
    <h2 class="heading mb-4 flex items-center gap-1">
      {{ t('assignments.heading') }}
      <s-help-button :title="t('assignments.help.title')">
        <div class="prose">
          <p>
            {{ t('assignments.student-help.what-is-highlighted') }}
          </p>
          <p>
            {{ t('assignments.help.more-assignments') }}
          </p>
        </div>
      </s-help-button>
    </h2>
    <div class="flex flex-col gap-4">
      <student-assignment-card
        v-for="assignment in studentAssignments"
        :key="assignment.id"
        :assignment="assignment"
        :grade="assignment.grade"
        :course="getCourseById(assignment.courseId)"
        include-course-code
      />
    </div>
  </s-page-block>

  <!-- STAFF ASSIGNMENT SECTION -->
  <s-page-block v-if="staffAssignments.length">
    <h2 class="heading mb-4 flex items-center gap-1">
      {{ t('assignments.heading') }}
      <s-help-button :title="t('assignments.help.title')">
        <div class="prose">
          <p>
            {{ t('assignments.staff-help.what-is-highlighted') }}
          </p>
          <p>
            {{ t('assignments.help.more-assignments') }}
          </p>
        </div>
      </s-help-button>
    </h2>
    <div class="flex flex-col gap-4">
      <staff-assignment-card-mini
        v-for="assignment in staffAssignments"
        :key="assignment.id"
        :course="getCourseById(assignment.courseId)"
        :assignment="assignment"
        :can-grant-assignment-extensions="false"
      />
    </div>
  </s-page-block>
</template>
<script setup lang="ts">
import SPageBlock from '../../design-system/SPageBlock.vue';
import SPageHeader from '../../design-system/SPageHeader.vue';
import {useI18n} from 'vue-i18n';
import {route} from 'ziggy-js';
import {Course} from '../../types/entities/course';
import SLink from '../../components/SLink.vue';
import StudentAssignmentCard from '../CourseAssignment/Index/StudentAssignmentCard.vue';
import SHelpButton from '../../design-system/SHelpButton.vue';
import StaffAssignmentCardMini from '../CourseAssignment/Index/StaffAssignmentCardMini.vue';
import PaymentRequiredBanner from '../../banners/PaymentRequiredBanner.vue';
import StudentCourseAssignmentRowDto = App.DTOs.CourseAssignments.StudentCourseAssignmentRowDto;
import StaffCourseAssignmentRowDto = App.DTOs.CourseAssignments.StaffCourseAssignmentRowDto;
import PaymentRequestDto = App.DTOs.Entities.PaymentRequestDto;

const props = defineProps<{
  courses: Course[];
  pendingPaymentRequests: PaymentRequestDto[];
  studentAssignments: StudentCourseAssignmentRowDto[];
  staffAssignments: StaffCourseAssignmentRowDto[];
}>();
const {t} = useI18n();
const getCourseById = (courseId: number): Course => {
  return props.courses.find((course) => course.id == courseId) as Course;
};
</script>
<i18n>
{
  "en": {
    "heading": "Dashboard",
    "courses.heading": "Your Courses",
    "assignments.heading": "Highlighted Assignments",
    "assignments.help.title": "Highlighted Assignments",
    "assignments.help.more-assignments": "Visit Assignments page for the course to see the full list.",
    "assignments.student-help.what-is-highlighted": "In this section we list the next 6 assignments that are due.",
    "assignments.staff-help.what-is-highlighted": "In this section we list the 6 most recently completed assignments."
  },
  "fr": {
    "heading": "Tableau de bord",
    "courses.heading": "Vos cours",
    "assignments.heading": "Vos devoirs",
    "assignments.help.title": "Devoirs mis en évidence",
    "assignments.help.more-assignments": "Pour voir la liste complète des devoirs, visitez la page 'Devoirs' de vos cours.",
    "assignments.student-help.what-is-highlighted": "Dans cette section, nous mettons en évidence les 6 prochains devoirs à rendre.",
    "assignments.staff-help.what-is-highlighted": "Dans cette section, nous mettons en évidence les 6 devoirs les plus récemment terminés."
  }
}
</i18n>
