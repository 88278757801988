<template>
  <s-page-block>
    <s-page-header heading="Create a new Task Type"></s-page-header>
  </s-page-block>
  <s-page-block>
    <form @submit.prevent="form.post(route('admin.task-types.store'))" class="flex flex-col gap-4">
      <s-input-field
        id="description"
        label="Description"
        v-model="form.description"
        :error="form.errors.description"
      />
      <s-btn type="submit">Create Task Type</s-btn>
    </form>
  </s-page-block>
</template>
<script setup lang="ts">
import SPageBlock from '../../../design-system/SPageBlock.vue';
import SPageHeader from '../../../design-system/SPageHeader.vue';
import {useForm} from '@inertiajs/vue3';
import SInputField from '../../../design-system/SInputField.vue';
import SBtn from '../../../design-system/SBtn.vue';
import {route} from 'ziggy-js';

const form = useForm({
  description: '',
});
</script>
