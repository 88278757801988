<template>
  {{ generatedNumbers.join(', ') }}
</template>
<script lang="ts" setup>
import {computed} from 'vue';

const {min, max, shift, step, includeZero} = defineProps<{
  min: number;
  max: number;
  shift?: number;
  step?: number;
  includeZero?: boolean;
}>();

const generatedNumbers = computed(() => {
  if (
    min === undefined ||
    max === undefined ||
    step === undefined ||
    shift === undefined ||
    max < min ||
    step <= 0
  ) {
    return [];
  }

  let result = [];
  let current = min;
  const nDecimals = Math.max(-shift, 0);

  while (current <= max) {
    if (current == 0 && !includeZero) {
      current += step;
      continue;
    }

    const scaledValue = current * Math.pow(10, shift);
    result.push(scaledValue.toFixed(nDecimals));

    current += step;
  }

  if (result.length > 9) {
    const startingNumbers = result.slice(0, 3);
    const endingNumbers = result.slice(-3);
    let truncatedResult = startingNumbers;
    truncatedResult.push('... ');
    truncatedResult = truncatedResult.concat(endingNumbers);
    return truncatedResult;
  }

  return result;
});
</script>
