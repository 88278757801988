import {Plugin} from 'prosemirror-state';
import {getCurrentInstance} from 'vue';
import {VueNodeView} from '../views/VueNodeView';

export class VueNodeViewPlugin extends Plugin {
  constructor(name: string, component: any, wrapperTagName: string = 'div') {
    const currentInstance = getCurrentInstance();

    super({
      props: {
        nodeViews: {
          [name]: (node, view, getPos) => {
            return new VueNodeView(
              node,
              view,
              getPos,
              component,
              currentInstance!.appContext,
              wrapperTagName
            );
          },
        },
      },
    });
  }
}
