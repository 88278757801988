<template>
  <v-chart class="chart" :option="option" autoresize ref="chartRef" />
</template>

<script setup lang="ts">
import {computed, onMounted, ref} from 'vue';
import {use} from 'echarts/core';
import {LineChart, PieChart} from 'echarts/charts';
import {CanvasRenderer} from 'echarts/renderers';
import VChart from 'vue-echarts';

import {
  DataZoomComponent,
  GridComponent,
  LegendComponent,
  TitleComponent,
  TooltipComponent,
} from 'echarts/components';

use([
  TitleComponent,
  LegendComponent,
  DataZoomComponent,
  LineChart,
  PieChart,
  CanvasRenderer,
  TooltipComponent,
  GridComponent,
]);

const props = defineProps<{
  rollingAverages: {
    day: string;
    avg_final_grade?: number;
    avg_estimated_ai_grade?: number;
    avg_accepted_grade?: number;
    avg_grade_pre_override?: number;
    avg_override_grade_difference?: number;
    pie_data: {name: string; value: number}[];
  }[];
}>();

function friendlyName(key: string) {
  switch (key) {
    case 'avg_final_grade':
      return 'Avg Final Grade';
    case 'avg_estimated_ai_grade':
      return 'Avg AI Grade';
    case 'avg_accepted_grade':
      return 'Avg Approved Grade';
    case 'avg_grade_pre_override':
      return 'Avg AI Grade (Pre-Override)';
    case 'avg_override_grade_difference':
      return 'Avg Override Grade Difference';
    default:
      return key;
  }
}

const colorMap: Record<string, string> = {
  // Line-chart keys
  'Avg Final Grade': 'black',
  'Avg AI Grade': '#01eaf5',
  'Avg Override Grade Difference': '#8e44ad',
  'Avg Approved Grade': '#1e8449',
  'Avg AI Grade (Pre-Override)': '#d35400',

  // Pie-chart slices
  'TA Override & Increased Grades': '#7fb3d5',
  'TA Override & Decreased Grades': '#ec7063',
  'TA Override & Unchanged Grades': '#58d68d',
  'Non-Reviewed Grades': '#f7dc6f',
  'Reviewed & Approved AI Grades': '#1e8449',
};

const chartRef = ref<InstanceType<typeof VChart> | null>(null);

const dates = computed(() => props.rollingAverages.map((item) => item.day));

const lineKeys = computed(() => {
  if (!props.rollingAverages.length) return [];
  const keysFromFirstDay = Object.keys(props.rollingAverages[0]) as Array<
    keyof (typeof props.rollingAverages)[0]
  >;
  return keysFromFirstDay.filter(
    (k) => !['day', 'pie_data', 'new_grades'].includes(k as string)
  ) as Array<keyof (typeof props.rollingAverages)[0]>;
});

const seriesData = computed(() => {
  return lineKeys.value.map((key) => {
    const name = friendlyName(key);
    return {
      name,
      type: 'line',
      smooth: true,
      itemStyle: {
        color: colorMap[name],
      },
      data: props.rollingAverages.map((item) => item[key] ?? 0),
    };
  });
});

const lineLegend = computed(() => lineKeys.value.map((k) => friendlyName(k)));
const pieLegend = computed(() => {
  const lastDay = props.rollingAverages.at(-1);
  if (!lastDay) return [];
  return lastDay.pie_data.map((slice) => slice.name);
});

const option = computed(() => ({
  title: [
    {
      text: 'Grading Average Trends',
      left: 'center',
      top: '45%',
    },
    {
      text: 'Grade Approval/Override Rates',
      left: 'center',
      top: '2%',
    },
  ],
  legend: [
    {
      top: '50%',
      data: lineLegend.value,
    },
    {
      orient: 'vertical',
      right: '0%',
      top: '5%',
      data: pieLegend.value,
    },
  ],
  tooltip: {
    trigger: 'axis',
    axisPointer: {
      type: 'cross',
    },
  },
  grid: {
    left: '3%',
    right: '3%',
    top: '55%',
    containLabel: true,
  },
  xAxis: {
    type: 'category',
    data: dates.value,
  },
  yAxis: {
    type: 'value',
  },
  series: [
    ...seriesData.value,
    {
      type: 'pie',
      id: 'pie',
      radius: ['15%', '30%'],
      center: ['50%', '25%'],
      emphasis: {
        focus: 'self',
      },
      label: {
        formatter: '{b}: {c} ({d}%)',
      },
      encode: {
        itemName: 'day',
        value: 'value',
      },
      data: (props.rollingAverages.at(-1)?.pie_data || []).map((slice) => {
        return {
          ...slice,
          itemStyle: {
            color: colorMap[slice.name],
          },
        };
      }),
    },
  ],
}));

const updatePieChart = (chart: any) => {
  chart.on('updateAxisPointer', (event: any) => {
    const xAxisInfo = event.axesInfo?.[0];
    if (xAxisInfo) {
      const dayIndex = xAxisInfo.value; // Get index from axis interaction
      const dayPieData = props.rollingAverages[dayIndex]?.pie_data || [];
      chart.setOption({
        series: {
          id: 'pie',
          label: {
            formatter: '{b}: {c} ({d}%)',
          },
          data: dayPieData.map((slice: any) => ({
            ...slice,
            itemStyle: {
              color: colorMap[slice.name],
            },
          })),
        },
      });
    }
  });
};

onMounted(() => {
  const chartInstance = chartRef.value?.chart;
  if (chartInstance) {
    updatePieChart(chartInstance);
  }
});
</script>

<style scoped>
.chart {
  width: 100%;
  aspect-ratio: 1.6;
  max-height: 100vh;
}
</style>
