<template>
  <prosemirro-menu
    :items="menuView.items"
    @click="(item: MenuViewItem) => menuView.handleClick(item)"
  />
  <ProsemirrorEditor v-model="doc" output="node" :schema="taskSchema" :plugins="plugins" />
</template>
<script setup lang="ts">
import {Component, computed} from 'vue';
import {Node} from 'prosemirror-model';
import DataDrivenTaskFormDto = App.DTOs.Tasks.DataDrivenTaskFormDto;
import {taskSchema} from './schema/task-schema';
import ProsemirrorEditor from '../prosemirror-editor/ProsemirrorEditor.vue';
import {DataDrivenTaskSerializer} from './schema/DataDrivenTaskSerializer';
import taskComponentCreationInterceptor from './schema/plugins/task-component-creation-interceptor';
import {MenuView, MenuViewItem} from '../prosemirror-editor/views/MenuView';
import ProsemirroMenu from '../prosemirror-editor/components/ProsemirroMenu.vue';
import {VueNodeViewPlugin} from '../prosemirror-editor/plugins/VueNodeViewPlugin';
import CalculationQuestionNode from '../prosemirror-editor/views/CalculationQuestionNode.vue';
import {InertiaForm} from '@inertiajs/vue3';

const form = defineModel<InertiaForm<DataDrivenTaskFormDto>>({required: true});

const menuView = new MenuView(taskSchema, []);
const plugins = [
  taskComponentCreationInterceptor,
  menuView.toPlugin(),
  new VueNodeViewPlugin('taskSingleCalculation', CalculationQuestionNode),
];

const doc = computed<Node>({
  get() {
    return new DataDrivenTaskSerializer(taskSchema).fromDto(form.value);
  },
  set(doc) {
    const task = new DataDrivenTaskSerializer(taskSchema).toDto(doc);

    form.value.uiConfigs = task.uiConfigs;
    form.value.gradingAlgorithm = task.gradingAlgorithm;
  },
});
</script>
