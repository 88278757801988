import {NodeSpec} from 'prosemirror-model';

export let blockquote: NodeSpec = {
  content: 'block+',
  group: 'block',
  defining: true,
  parseDOM: [{tag: 'blockquote'}],
  toDOM() {
    return ['blockquote', 0];
  },
};
