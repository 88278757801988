import {v4 as uuidv4} from 'uuid';
import {defineComponentType} from './defineComponentType';
import ImageUiConfig = App.Entities.Tasks.Ui.ImageUiConfig;

export const image = defineComponentType<ImageUiConfig, null>({
  translationKey: 'Image',
  icon: 'image',
  uiConfigFactory: () => ({
    uuid: uuidv4(),
    type: 'Image',
    name: 'Image',
    fileUploadId: 0,
    alt: '',
    width: 'auto',
    part: null,
  }),
  componentGraderFactory: () => null,
});
