<template>
  <prosemirror-menu
    v-if="!readOnly"
    :items="menuView.items"
    @click="(item: MenuViewItem) => menuView.handleClick(item)"
  />
  <ProsemirrorEditor
    v-model="doc"
    output="json"
    :schema="assignmentOverviewSchema"
    :plugins="plugins"
    :read-only="readOnly"
  />
</template>
<script setup lang="ts">
import {assignmentOverviewSchema} from './schema/assignment-overview-schema';
import ProsemirrorEditor from '../prosemirror-editor/ProsemirrorEditor.vue';
import {MenuView, MenuViewItem} from '../prosemirror-editor/views/MenuView';
import ProsemirrorMenu from '../prosemirror-editor/components/ProsemirroMenu.vue';
import {VueLinkMenuView} from '../prosemirror-editor/decorations/LinkDecorations';

defineProps<{
  readOnly?: boolean;
}>();

const doc = defineModel<any>();

const menuView = new MenuView(assignmentOverviewSchema, []);
const linkMenu = new VueLinkMenuView();
const plugins = [menuView.toPlugin(), linkMenu.toPlugin()];
</script>
