<template>
  <Head :title="`Admin - User Invitations`"></Head>
  <div class="page-block gap-4">
    <div class="stack horizontal align-between gap-1 w-full flex-wrap">
      <h2 class="heading xl">User Invitations</h2>

      <s-link :href="route('admin.user-invites.create')" class="btn primary md w-fit">
        <div>Invite User</div>
      </s-link>
    </div>

    <s-select-field id="claimed" label="Filter" v-model="claimedFilter" @change="filterChange()">
      <option value="all">Claimed/Unclaimed</option>
      <option key="claimed" value="claimed">Claimed</option>
      <option key="unclaimed" value="unclaimed">Unclaimed</option>
    </s-select-field>
    <s-search-section route-name="admin.user-invites.index" label="Search by email" />

    <div class="stack vertical gap-2">
      <s-link
        v-for="invite in invites.data"
        :key="invite.id"
        class="card padded stack vertical sm:horizontal gap-2 align-between"
        :href="route('admin.user-invites.show', [invite.id])"
      >
        <div class="flex flex-col gap-1 flex-grow-1">
          <div>
            <p>{{ invite.email }}</p>
            <small class="text-slate-700">{{ invite.token }}</small>
          </div>
        </div>
        <div class="flex flex-col gap-1">
          <div v-if="invite.claimedAt">
            <p class="bg-green-600 text-white px-1 rounded-md">Claimed</p>
            <small>Claimed at:</small>
            <p>{{ monthDayYear(invite.createdAt) }}.</p>
          </div>
          <div>
            <small>Created:</small>
            <p>{{ monthDayYear(invite.createdAt) }}.</p>
          </div>
          <div>
            <small>Expires:</small>
            <p>{{ monthDayYear(invite.expiresAt) }}.</p>
          </div>
        </div>
      </s-link>
      <div v-if="!invites.data.length">There are no pending invites.</div>
      <s-paginator :paginator="invites" />
    </div>
  </div>
</template>
<script setup lang="ts">
import {LaravelPaginator} from '../../../types/laravel-paginator';
import SLink from '../../../components/SLink.vue';
import SPaginator from '../../../design-system/SPaginator.vue';
import {route, RouteParams} from 'ziggy-js';
import {UserInvitation} from '../../../types/entities/user-invitation';
import {monthDayYear} from '../../../format/dates';
import SSearchSection from '../../../design-system/SSearchSection.vue';
import {Head, router} from '@inertiajs/vue3';
import SSelectField from '../../../design-system/SSelectField.vue';
import {ref} from 'vue';

const props = defineProps<{
  invites: LaravelPaginator<UserInvitation>;
  claimedFilter: string;
}>();
const claimedFilter = ref<string>(props.claimedFilter.toString());
const filterChange = () => {
  router.visit(
    route('admin.user-invites.index', {
      claimedAt: claimedFilter.value,
    } as RouteParams<any>)
  );
};
</script>
<style scoped>
table {
  width: 100%;
  border-collapse: collapse;
}

tr {
  border-bottom: 1px solid #ddd;
}

td,
th {
  padding: 0.5rem;
  text-align: left;
}
</style>
