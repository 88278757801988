<template>
  <s-form-inline
    v-model="search"
    :placeholder="label"
    icon="magnify"
    @submit="submit"
    :submitLabel="$t('search.label')"
    type="search"
    role="search"
    id="searchText"
  />
</template>

<script setup lang="ts">
import {ref, watch} from 'vue';
import SFormInline from './SFormInline.vue';
import {router} from '@inertiajs/vue3';
import {route} from 'ziggy-js';

const props = defineProps<{
  routeName: string;
  only?: string[];
  label: string;
}>();

const search = ref<string | number | null | undefined>(
  (route().params.search as string | number | null | undefined) || ''
);

watch(
  () => route().params.search as string | number | null | undefined,
  (newSearch) => {
    search.value = newSearch || '';
  }
);

const submit = (searchText: string) => {
  const params = {...route().params, search: searchText, page: 1};
  const href = route(props.routeName, params);
  router.visit(href, {only: props.only || []});
};
</script>
