<template>
  <div class="relative">
    <select
      v-bind="$attrs"
      v-model="model"
      class="pl-3 pr-7 py-2.5 leading-tight rounded-md border border-gray-200 shadow-soft bg-white hover:bg-gray-50 appearance-none text-blue-600 hover:text-blue-400 font-bold w-full cursor-pointer transition-all ease-out duration-150 truncate"
    >
      <slot />
    </select>
    <div
      aria-hidden="true"
      class="absolute inset-y-0 right-1.5 flex items-center pointer-events-none text-blue-500"
    >
      <s-icon name="chevron-down" class="opacity-70" />
    </div>
  </div>
</template>
<script setup lang="ts">
import SIcon from './SIcon.vue';

defineOptions({
  inheritAttrs: false,
});

const model = defineModel();
</script>
