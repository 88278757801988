<template>
  <div
    class="relative max-w-full shrink"
    :class="{
      'cursor-not-allowed filter grayscale opacity-70': disabled,
    }"
  >
    <Combobox v-model="selectedOption" by="id" v-slot="{activeOption}" :disabled="disabled">
      <div
        class="flex flex-1 shrink max-w-full w-full border border-gray-200 shadow bg-white hover:bg-gray-50 rounded-md cursor-default overflow-hidden focus:outline-none focus-within:outline-none focus-within:ring-2 focus-within:ring-blue-500"
        :class="{
          'pointer-events-none': disabled,
        }"
      >
        <ComboboxButton
          as="div"
          class="max-w-full shrink group cursor-pointer bg-transparent hover:bg-gray-100 flex items-center p-0 border-0 transition-all ease-out duration-150"
          ref="listboxButton"
        >
          <div class="relative max-w-full flex-1 w-full min-w-0">
            <ComboboxInput
              :aria-labelledby="labelId"
              class="shrink absolute max-w-full w-full py-2 px-3 leading-tight rounded-l-md border-none focus:ring-0 outline-none whitespace-nowrap truncate"
              @change="updateQuery"
              @focus="$event.target.select()"
              :displayValue="displayValue"
              :placeholder="props.emptyLabel"
            />
            <span
              :id="labelId"
              class="max-w-full shrink block min-w-[4.5rem] py-2 px-3 leading-tight opacity-0 pointer-events-none whitespace-nowrap truncate"
            >
              {{ selectedOption ? displayValue(selectedOption) : props.emptyLabel }}
            </span>
          </div>
          <div class="grow-0 pl-1 pr-2 border-l border-gray-200 h-full flex items-center">
            <s-icon
              name="chevron-down"
              class="opacity-50 group-hover:opacity-100 text-blue-500 transition-all ease-out duration-150"
            />
          </div>
        </ComboboxButton>
      </div>

      <Teleport to="body">
        <div class="absolute" :style="dropdownComputed.wrapperStyle">
          <TransitionRoot
            leave="transition ease-in duration-100"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
            @after-leave="query = ''"
          >
            <ComboboxOptions
              class="absolute left-0 overflow-scroll z-40 card shadows shadow-lg"
              :class="{
                'top-2': dropdownComputed.position === 'bottom',
                'bottom-2': dropdownComputed.position === 'top',
              }"
              :style="dropdownComputed.menuStyle"
            >
              <div
                v-if="filteredOptions.length === 0 && query !== ''"
                class="relative cursor-default select-none px-4 py-2 text-gray-700"
              >
                {{ $t('combobox.noMatches') }}
              </div>

              <ComboboxOption
                v-for="option in filteredOptions"
                :key="getOptionKey(option)"
                :value="option"
                :disabled="option.disabled"
                v-slot="{selected, active}"
                as="template"
                :hold="true"
              >
                <li
                  class="relative whitespace-nowrap min-w-[4.5rem] text-base cursor-pointer select-none px-4 py-2 transition-all ease-out duration-150"
                  :class="{
                    'text-white bg-blue-600': active,
                    'text-gray-700 hover:bg-gray-150 text-blue-600': !active,
                  }"
                >
                  {{ (option && displayValue(option)) || props.emptyLabel }}
                </li>
              </ComboboxOption>
            </ComboboxOptions>
          </TransitionRoot>
        </div>
      </Teleport>
    </Combobox>
  </div>
</template>

<script lang="ts" setup>
import {computed, defineEmits, defineProps, ref, watch} from 'vue';
import {useElementBounding, useWindowSize} from '@vueuse/core';
import {
  Combobox,
  ComboboxButton,
  ComboboxInput,
  ComboboxOption,
  ComboboxOptions,
  TransitionRoot,
} from '@headlessui/vue';
import SIcon from './SIcon.vue';

const props = defineProps<{
  options: any[];
  idKey: string;
  filterBy?: string[];
  displayValue: (option: any) => string;
  modelValue: string | number | undefined;
  emptyLabel: string;
  nullable?: boolean;
  disabled?: boolean;
}>();

const selectedOption = ref(
  props.options.find((option) => option[props.idKey] === props.modelValue) || props.options[0]
);

const getOptionKey = (option: any) => {
  return typeof option === 'object' ? option[props.idKey] : option;
};

const emit = defineEmits(['update:modelValue']);

const emitUpdate = (value: any) => {
  emit('update:modelValue', value);
};

watch(selectedOption, (newValue) => {
  emitUpdate(!!newValue ? newValue[props.idKey] || null : null);
});

watch(
  () => props.modelValue,
  (newValue) => {
    const newSelectedOption = props.options.find((option) => option[props.idKey] === newValue);
    selectedOption.value = newSelectedOption || null;
  }
);

const query = ref('');

const updateQuery = (event: any) => {
  query.value = event.target.value;
};

const filteredOptions = computed(() =>
  props.options.filter((option) => {
    // check if any of the filterBy fields contain the query
    if (props.filterBy?.length) {
      return props.filterBy.some((field) => {
        return option[field].toLowerCase().includes(query.value.toLowerCase());
      });
    }
    return combineObjectValuesIntoString(option).toLowerCase().includes(query.value.toLowerCase());
  })
);

const combineObjectValuesIntoString = (obj: any) => {
  return Object.values(obj).join(' ');
};

const labelId = `combobox-label-${Math.random().toString(36).substring(2)}`;

const listboxButton = ref(null);
const {x, y, height, width} = useElementBounding(listboxButton);
const {width: windowWidth, height: windowHeight} = useWindowSize();
const maxDropdownHeight = 288;
const buffer = 16;

const dropdownComputed = computed(() => {
  const canFitBelow = y.value + height.value + maxDropdownHeight + buffer < windowHeight.value;
  const canFitAbove = y.value - maxDropdownHeight - buffer > 0;
  const position = !canFitBelow && canFitAbove ? 'top' : 'bottom';

  const wrapperStyle = {
    top: position === 'bottom' ? `${y.value + height.value}px` : 'auto',
    bottom: position === 'top' ? `${windowHeight.value - y.value}px` : 'auto',
    left: `${x.value}px`,
    width: `${width.value}px`,
  };

  // Squish the dropdown when there's not enough space
  const menuStyle = {
    maxHeight:
      !canFitBelow && position === 'bottom'
        ? `${Math.min(maxDropdownHeight, windowHeight.value - y.value - height.value - buffer)}px`
        : `${maxDropdownHeight}px`,
    minWidth: `${width.value}px`,
    maxWidth: `${windowWidth.value - x.value - buffer}px`,
  };

  return {position, wrapperStyle, menuStyle};
});
</script>
