<template>
  <Head :title="t('page.title')" />
  <section class="section-block pt-4 pb-1">
    <s-page-header :heading="t('page.heading')" />
  </section>

  <s-tab-nav
    :tabs="[
      {
        label: t('tabs.tasks'),
        icon: 'clipboard-check-outline',
        href: route('courses.analytics.tasks', {
          course: props.course.id,
        }),
      },
    ]"
    class="mb-5"
  />
</template>
<script setup lang="ts">
import {route} from 'ziggy-js';
import SPageHeader from '../../../design-system/SPageHeader.vue';
import STabNav from '../../../design-system/STabNav.vue';
import {Course} from '../../../types/entities/course';
import {useI18n} from 'vue-i18n';
import {Head} from '@inertiajs/vue3';

const props = defineProps<{
  course: Course;
}>();

const {t} = useI18n({
  scope: 'local',
  inheritLocale: false,
});
</script>
<i18n>
{
  "en": {
    "page": {
      "title": "Course Analytics",
      "heading": "Course Analytics"
    },
    "tabs": {
      "tasks": "Tasks"
    }
  },
  "fr": {
    "page": {
      "title": "Analyse du cours",
      "heading": "Analyse du cours"
    },
    "tabs": {
      "tasks": "Tâches"
    }
  }
}

</i18n>
