<template>
  <div :class="cardClasses">
    <div v-if="heading || $slots.header || $slots.heading" :class="headerClasses">
      <slot name="header">
        <div class="flex flex-col gap-2 sm:flex-row flex-wrap sm:items-end justify-between">
          <component :is="headingTag" class="text-lg leading-tight text-pretty flex flex-row">
            <slot name="heading">
              {{ heading }}
            </slot>
          </component>

          <div v-if="$slots.actions" class="flex flex-wrap gap-x-2 gap-y-3">
            <slot name="actions"></slot>
          </div>
        </div>
      </slot>
    </div>

    <div v-if="!$slots.content" :class="contentClasses">
      <slot />
    </div>
    <slot name="content" v-else />

    <div v-if="footer || $slots.footer" :class="footerClasses">
      <slot name="footer">
        {{ footer }}
      </slot>
    </div>
  </div>
</template>

<script setup lang="ts">
import {computed} from 'vue';

type Color = 'white' | 'custom';
type Size = 'xs' | 'sm' | 'md';
type Padding = 'none' | 'sm' | 'md';

interface CardProps {
  size?: Size;
  padding?: Padding;
  color?: Color;
  heading?: string;
  footer?: string;
  headingLevel?: 1 | 2 | 3 | 4 | 5 | 6;
  headerClass?: string;
  footerClass?: string;
}

const {
  size = 'md',
  padding = 'md',
  color = 'white',
  headingLevel = 2,
  heading,
  footer,
  headerClass,
  footerClass,
} = defineProps<CardProps>();

const headingTag = computed(() => `h${headingLevel}`);

const classes = {
  base: {
    card: 'rounded-lg shadow-card-soft overflow-hidden',
    header: 'border-b border-gray-150',
    footer: 'border-t border-gray-150',
  },
  color: {
    card: {
      white: 'bg-gradient-to-t from-gray-50 to-white border border-gray-150',
      custom: '',
    },
    header: 'bg-gradient-to-t from-gray-500/5 to-gray-300/5',
    footer: 'bg-gradient-to-b from-gray-600/5 to-gray-400/5',
  },
  padding: {
    header: {
      none: '',
      sm: 'px-4 md:px-5 lg:px-6 py-3 md:py-4',
      md: 'px-5 md:px-7 lg:px-8 pt-4 pb-2.5 md:pt-5 md:pb-3.5',
    },
    content: {
      none: '',
      sm: 'px-4 md:px-5 lg:px-6 py-4 md:py-5',
      md: 'px-5 md:px-7 lg:px-8 py-4 md:py-6 lg:py-7',
    },
    footer: {
      none: '',
      sm: 'px-4 md:px-5 lg:px-6 pb-3 pt-2 md:pb-4 md:pt-3',
      md: 'px-5 md:px-7 lg:px-8 pb-4 pt-3 md:pb-5 md:pt-4',
    },
  },
};

const cardClasses = computed(() => {
  return [classes.base.card, classes.color.card[color]];
});

const headerClasses = computed(() => {
  return [headerClass, classes.base.header, classes.color.header, classes.padding.header[padding]];
});

const contentClasses = computed(() => {
  return [classes.padding.content[padding]];
});

const footerClasses = computed(() => {
  return [footerClass, classes.base.footer, classes.color.footer, classes.padding.footer[padding]];
});
</script>
