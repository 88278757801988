<template>
  <span
    ref="wrapperEl"
    class="relative"
    @mouseenter="isTooltipVisible = true"
    @mouseleave="hideTooltipIfNeitherElIsHovered"
  >
    <slot></slot>
    <!-- TODO: This role should maybe be tooltip.-->
    <span
      ref="tooltipEl"
      aria-hidden="true"
      class="pointer-events-none absolute z-50 left-1/2 -translate-x-1/2 whitespace-nowrap flex flex-col items-center gap-1.5 rounded-md shadow-md px-2 py-2 shadow-lg bg-gradient-to-b from-gray-100 via-gray-50 to-white border border-gray-150 border-b-gray-200 border-r-gray-200 text-gray-700 font-medium tracking-slight transition-all ease-out duration-150"
      :class="{
        '-top-2 -translate-y-1/2': position === 'top',
        '-translate-y-full': position === 'top' && isTooltipVisible,
        '-bottom-2 translate-y-1/2': position === 'bottom',
        'translate-y-full': position === 'bottom' && isTooltipVisible,
        'opacity-100 delay-700': isTooltipVisible,
        'opacity-0 delay-300': !isTooltipVisible,
      }"
      @mouseleave="hideTooltipIfNeitherElIsHovered"
    >
      <span class="text-sm flex items-center gap-1.5">
        <span v-for="key in keys" class="keycap">{{ key }}</span>
      </span>
      <span class="text-xs font-bold text-gray-600 uppercase pr-1">
        {{ $t('shortcut') }}
      </span>
      <span
        class="absolute left-1/2 -translate-x-1/2 w-2.5 h-2.5 bg-gradient-to-br from-transparent rounded-br-sm border-r border-b border-gray-200"
        :class="{
          'from-white via-white bottom-[-0.5px] translate-y-1/2 rotate-45': position === 'top',
          'from-gray-100 via-gray-100 top-[-0.5px] -translate-y-1/2 rotate-[-135deg]':
            position === 'bottom',
        }"
      ></span>
    </span>
  </span>
</template>
<script setup lang="ts">
import {defineProps, onBeforeUnmount, onMounted, ref, PropType} from 'vue';

const props = defineProps({
  keys: {
    type: [Array, String],
  },
  position: {
    type: String as PropType<'top' | 'bottom'>,
    default: 'top',
  },
});

const wrapperEl = ref<HTMLElement | null>(null);
const tooltipEl = ref<HTMLElement | null>(null);

const isTooltipVisible = ref(false);

const hideTooltipIfNeitherElIsHovered = () => {
  setTimeout(() => {
    if (!wrapperEl.value || !tooltipEl.value) {
      return;
    }

    const wrapperIsHovered = wrapperEl.value.matches(':hover');
    const tooltipIsHovered = tooltipEl.value.matches(':hover');

    if (!wrapperIsHovered && !tooltipIsHovered) {
      isTooltipVisible.value = false;
    }
  }, 200);
};

const handleKeydown = (event: KeyboardEvent) => {
  if (event.key === 'Escape') {
    isTooltipVisible.value = false;
  }
};

onMounted(() => {
  window.addEventListener('keydown', handleKeydown);
});

onBeforeUnmount(() => {
  window.removeEventListener('keydown', handleKeydown);
});
</script>
