export function generateNumberVariableExamples(
  min?: number,
  max?: number,
  step?: number,
  shift?: number,
  includeZero?: boolean
): string[] {
  if (
    min === undefined ||
    max === undefined ||
    step === undefined ||
    shift === undefined ||
    max < min ||
    step <= 0
  ) {
    return [];
  }

  let result = [];
  let current = min;
  const nDecimals = Math.max(-shift, 0);

  while (current <= max) {
    if (current == 0 && !includeZero) {
      current += step;
      continue;
    }

    const scaledValue = current * Math.pow(10, shift);
    result.push(scaledValue.toFixed(nDecimals));

    current += step;
  }

  if (result.length > 9) {
    const startingNumbers = result.slice(0, 3);
    const endingNumbers = result.slice(-3);
    let truncatedResult = startingNumbers;
    truncatedResult.push('... ');
    truncatedResult = truncatedResult.concat(endingNumbers);
    return truncatedResult;
  }

  return result;
}

export function calculatePermutations(
  min?: number,
  max?: number,
  step?: number,
  shift?: number,
  includeZero?: boolean
): number {
  if (
    min === undefined ||
    max === undefined ||
    step === undefined ||
    shift === undefined ||
    max < min ||
    step <= 0
  ) {
    return 0;
  }

  let result = 0;
  let current = min;

  while (current <= max) {
    if (current == 0 && !includeZero) {
      current += step;
      continue;
    }

    result += 1;

    current += step;
  }

  return result;
}
