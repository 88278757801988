<template>
  <span>
    <template v-for="(fragment, i) in fragments">
      <katex-element
        v-if="fragment.type === 'inline' || fragment.type === 'display'"
        :key="i"
        :display-mode="fragment.type === 'display'"
        :expression="fragment.content"
        :style="responsive ? 'font-size: 1em' : 'font-size: 13px'"
      />
      <template v-else-if="useHtml">
        <span v-html="DOMPurify.sanitize(fragment.content)" />
      </template>
      <template v-else>
        {{ fragment.content }}
      </template>
    </template>
  </span>
</template>

<script setup lang="ts">
import KatexElement from './KatexElement.vue';
import {computed, toRef} from 'vue';
import DOMPurify from 'dompurify';
import {useLatexFragments} from '../composables/useLatexFragments';

const mathPattern = /(\$\$.*?\$\$|\$.*?\$)/s;

const props = defineProps<{
  content: any;
  useHtml?: boolean;
  responsive?: boolean;
}>();

const {fragments} = useLatexFragments(toRef(props, 'content'));
</script>
