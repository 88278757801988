<template>
  <s-label-value :label="t('dueDate')">
    <span
      v-if="paymentRequest.course.paymentDueDate"
      class="flex items-center gap-1"
      :class="isDateOverSixMonthsAgo(paymentRequest.course.paymentDueDate) ? 'text-gray-600' : ''"
    >
      <s-tooltip
        v-if="isDateOverSixMonthsAgo(paymentRequest.course.paymentDueDate)"
        :text="t('oldPaymentRequest')"
      >
        <s-icon name="information" class="opacity-70" width="18" height="18" />
      </s-tooltip>
      <span class="truncate whitespace-nowrap">
        {{ monthDayYear(paymentRequest.course.paymentDueDate) }}
      </span>
    </span>
    <span v-else class="italic opacity-30">
      {{ t('notSet') }}
    </span>
  </s-label-value>
</template>
<script setup lang="ts">
import {isDateOverSixMonthsAgo, monthDayYear} from '../../../format/dates';
import SIcon from '../../../design-system/SIcon.vue';
import STooltip from '../../../design-system/STooltip.vue';
import SLabelValue from '../../../design-system/SLabelValue.vue';
import {useI18n} from 'vue-i18n';
import {PaymentRequest} from '../../../types/entities/payment-request';

defineProps<{
  paymentRequest: PaymentRequest;
}>();

const {t} = useI18n({
  inheritLocale: true,
  useScope: 'local',
});
</script>
<i18n>
{
  "en": {
    "oldPaymentRequest": "This payment request is over six months old.",
    "notSet": "Not set"
  },
  "fr": {
    "oldPaymentRequest": "Cette demande de paiement a plus de six mois.",
    "notSet": "Non défini"
  }
}
</i18n>
