<template>
  <s-tooltip>
    <template #tooltip>
      {{ tooltipText }}
    </template>
    <s-badge :color="badgeColor">
      <s-icon :name="iconName" size="18" class="opacity-70" />
      {{ badgeText }}
    </s-badge>
  </s-tooltip>
</template>
<script setup lang="ts">
import {defineProps, computed} from 'vue';
import {useI18n} from 'vue-i18n';
import {useTimer} from '../../../composables/useTimer';
import STooltip from '../../../design-system/STooltip.vue';
import SBadge from '../../../design-system/SBadge.vue';
import SIcon from '../../../design-system/SIcon.vue';
import {monthDaySometimesYear} from '../../../format/dates';
import StudentCourseAssignmentRowDto = App.DTOs.CourseAssignments.StudentCourseAssignmentRowDto;

const props = defineProps<{
  assignment: StudentCourseAssignmentRowDto;
}>();

const {t} = useI18n({
  useScope: 'local',
  inheritLocale: true,
});

const {displayValue, progressValue, timerComplete} = useTimer({
  startedAt: props.assignment.timer?.startedAt,
  finishesAt: props.assignment.timer?.finishesAt,
});

const unclaimedExtensions = computed(() => {
  return props.assignment.timer?.extensions?.filter((extension) => !extension.claimed);
});

const tooltipText = computed(() => {
  return props.assignment.timer?.hasFinished
    ? unclaimedExtensions.value?.length
      ? t('description.extension', {
          completedAt: monthDaySometimesYear(props.assignment.timer.finishesAt, {
            time: true,
          }),
        })
      : t('description.completed', {
          completedAt: monthDaySometimesYear(props.assignment.timer.finishesAt, {
            time: true,
          }),
        })
    : props.assignment.timer?.isRunning
      ? t('description.running', {
          startedAt: monthDaySometimesYear(props.assignment.timer.startedAt, {time: true}),
          finishesAt: monthDaySometimesYear(props.assignment.timer.finishesAt, {
            time: true,
          }),
        })
      : t('description.notStarted');
});

const badgeText = computed(() => {
  return props.assignment.timer?.hasFinished
    ? unclaimedExtensions.value?.length
      ? t('extension')
      : t('completed')
    : props.assignment.timer?.isRunning
      ? displayValue.value
      : t('notStarted');
});

const badgeColor = computed(() => {
  return props.assignment.timer?.hasFinished || timerComplete.value
    ? unclaimedExtensions.value?.length
      ? 'blue'
      : 'red'
    : props.assignment.timer?.isRunning
      ? 'green'
      : 'gray';
});

const iconName = computed(() => {
  return props.assignment.timer?.hasFinished
    ? 'timer-off'
    : props.assignment.timer?.isRunning
      ? 'timer'
      : 'timer-outline';
});
</script>
<i18n>
{
  "en": {
    "completed": "Completed",
    "notStarted": "Not Started",
    "extension": "Extension Available",
    "description": {
        "completed": "Completed {completedAt}",
        "running": "Started {startedAt}, ends {finishesAt}",
        "notStarted": "The timer has not been started",
        "extension":  "Completed {completedAt}. Extension available.",
    }
  },
  "fr": {
    "completed": "Terminé",
    "notStarted": "Non commencé",
    "extension": "Prolongation disponible",
    "description": {
        "completed": "Terminé le {completedAt}",
        "running": "Commencé le {startedAt}, se termine le {finishesAt}",
        "notStarted": "Le minuteur n'a pas été démarré",
        "extension":  "Terminé le {completedAt}. Prolongation disponible.",
    }
  }
}
</i18n>
