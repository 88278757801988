<template>
  <s-page-block>
    <s-page-header :title="t('page.title', course.code)" :heading="t('page.heading')" />
  </s-page-block>
  <s-page-block>
    <form @submit.prevent="submit" class="flex flex-col gap-4">
      <s-input-field
        id="code"
        :label="t('labels.code')"
        v-model="form.code"
        :error="form.errors.code"
      />
      <button type="submit" class="btn primary md w-fit my-1" :disabled="form.processing">
        {{ $t('form.submit') }}
      </button>
    </form>
  </s-page-block>
</template>
<script setup lang="ts">
import {onMounted} from 'vue';
import {useForm} from '@inertiajs/vue3';
import SInputField from '../../design-system/SInputField.vue';
import {route} from 'ziggy-js';
import {useI18n} from 'vue-i18n';
import SPageBlock from '../../design-system/SPageBlock.vue';
import SPageHeader from '../../design-system/SPageHeader.vue';
import {useBreadcrumbs} from '../../composables/useBreadcrumbs';
import {Course} from '../../types/entities/course';

const props = defineProps<{
  course: Course;
}>();

type PageParams = {
  course?: string;
  code?: string;
};

const {t} = useI18n({
  useScope: 'local',
  inheritLocale: true,
});

const {code = ''} = route().params as PageParams;

const getCode = () => code;

const form = useForm({
  code: getCode(),
});

const submit = () => {
  form.post(route('courses.attendance.store', props.course.id));
};

onMounted(() => {
  if (form.code) {
    submit();
  }
});

useBreadcrumbs([
  {
    label: props.course.code,
    href: route('courses.show', props.course.id),
  },
]);
</script>
<i18n>
{
  "en": {
    "page": {
      "title": "Attendance - {courseCode}",
      "heading": "Attendance"
    },
    "labels": {
      "code": "Attendance Code"
    }
  },
  "fr": {
    "page": {
      "title": "Présence - {courseCode}",
      "heading": "Présence"
    },
    "labels": {
      "code": "Code de présence"
    }
  }
}

</i18n>
