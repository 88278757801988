<template>
  <Head :title="t('page.title')"></Head>
  <div class="bg-gradient-to-b from-blue-50 via-transparent to-transparent">
    <div class="p-4 lg:p-8 max-w-xl mx-auto flex flex-col gap-5">
      <form
        class="flex flex-col shadow-card rounded-lg"
        @submit.prevent="
          form.post(
            route('institutions.lti-course-contexts.store', {
              institution: institution.id,
            })
          )
        "
      >
        <h1
          id="form-title"
          class="bg-gradient-to-t from-white/70 to-white/30 flex items-center justify-center p-6 rounded-t-lg border border-white/50 border-b-gray-100"
        >
          <s-logo type="image" class="logo h-16 lg:h-[4.5rem] w-auto" />
          <span class="sr-only">
            {{ t('page.title') }}
          </span>
        </h1>
        <div
          class="px-5 lg:px-8 py-5 lg:py-8 bg-gradient-to-t from-gray-50 to-white rounded-b-lg flex flex-col gap-5"
        >
          <p>
            {{
              t('page.linkMessage', {
                lmsCourse: ltiCourseLabel,
              })
            }}
          </p>
          <div class="flex-1 flex flex-col gap-1.5">
            <s-select-field
              id="role"
              :label="t('course')"
              v-model="form.course"
              :class="form.course === '' ? 'text-gray-400' : ''"
              :error="form.errors.course"
            >
              <option value=""></option>
              <option v-for="course in courses" :key="course.id" :value="`${course.id}`">
                {{ course.code }} - {{ shortMonthYear(course.startDate) }}
              </option>
            </s-select-field>
          </div>

          <s-btn
            type="submit"
            color="primary"
            :processing="form.processing"
            class="mt-1"
            icon="link-plus"
            icon-end
          >
            {{ t('page.linkCourseButton') }}
          </s-btn>
        </div>
      </form>

      <div class="flex gap-x-3 gap-y-2 flex-wrap">
        <s-link :href="route('support')" class="link-subtle flex items-center gap-1.5">
          <s-icon name="chat-question" class="opacity-70" />
          {{ t('links.help') }}
        </s-link>
      </div>
    </div>
  </div>
</template>
<script setup lang="ts">
import {Head, useForm} from '@inertiajs/vue3';
import {useI18n} from 'vue-i18n';
import {route} from 'ziggy-js';
import SLink from '../../components/SLink.vue';
import UnauthorizedLayout from '../../layouts/UnauthorizedLayout.vue';
import SIcon from '../../design-system/SIcon.vue';
import loginTranslations from '../Login/login.json';
import SLogo from '../../design-system/SLogo.vue';
import {Course} from '../../types/entities/course';
import SBtn from '../../design-system/SBtn.vue';
import SSelectField from '../../design-system/SSelectField.vue';
import {shortMonthYear} from '../../format/dates';
import {Institution} from '../../types/entities/institution';

const form = useForm<{
  course: string;
}>({
  course: '',
});

const {t} = useI18n({
  inheritLocale: true,
  messages: loginTranslations,
});

defineOptions({layout: UnauthorizedLayout});

defineProps<{
  institution: Institution;
  ltiCourseLabel: string;
  courses: Course[];
}>();
</script>
<i18n>
{
  "en": {
    "page": {
      "title": "Link Stemble Course to LMS Course",
      "linkCourseButton": "Link Course",
      "linkMessage": "Select the Stemble Course you would like to link to \"{lmsCourse}\""
    }
  },
  "fr": {
    "page": {
      "title": "Lier le cours Stemble au cours LMS",
      "linkCourseButton": "Lier le cours",
      "linkMessage": "Sélectionnez le cours Stemble que vous souhaitez lier au cours LMS {lmsCourse}."
    }
  }
}
</i18n>
