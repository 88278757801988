<template>
  <s-link
    :href="
      route('web.courses.assignments.tasks.first', {
        course: course.id,
        assignment: assignment.id,
      })
    "
    class="@container/card group"
  >
    <s-card
      padding="sm"
      class="xl:grid xl:grid-cols-[1fr_19rem]"
      footerClass="xl:border-t-0 xl:border-l xl:flex xl:items-center xl:bg-gradient-to-r xl:from-gray-400/5 xl:to-gray-300/5"
    >
      <div class="flex flex-col gap-3">
        <div class="flex flex-wrap items-center justify-between gap-y-1.5 gap-x-4">
          <div>
            <small v-if="includeCourseCode">{{ course.code }}</small>
            <h4 class="text-xl transition group-focus:text-blue-600 group-hover:text-blue-600">
              {{ assignment.name }}
            </h4>
          </div>
          <div class="flex flex-wrap gap-2 text-sm -mr-0.5">
            <timer-badge
              v-if="assignment.courseLikeAssignmentsInfo.timelimitEnabled"
              :assignment="assignment"
            />
            <s-badge-group>
              <s-badge :color="assignment.numSubmissions < assignment.numTasks ? 'gray' : 'green'">
                <s-icon v-if="assignmentCompleted" name="check-bold" size="16" class="opacity-70" />
                <i18n-t keypath="xOfYTasksSubmitted">
                  <template v-slot:x>
                    <strong class="font-bold">
                      {{ assignment.numSubmissions }}
                    </strong>
                  </template>
                  <template v-slot:y>
                    <strong class="font-bold">
                      {{ assignment.numTasks }}
                    </strong>
                  </template>
                </i18n-t>
                <s-tooltip v-if="hasDrafts(assignment)">
                  <template #tooltip>
                    {{ t('labels.draftWarning', assignment.numDrafts) }}
                  </template>
                  <s-icon name="alert" class="text-gray-700 opacity-70" size="16" />
                </s-tooltip>
              </s-badge>
              <s-badge
                v-if="grade && assignment.gradesAreVisible"
                :color="determineGradePillColor(grade)"
                class="min-w-[6rem] justify-end"
              >
                <span class="opacity-70">{{ t('grade') }}</span>
                <span class="font-bold whitespace-nowrap">
                  <s-grade :grade-display="course.gradeDisplay" :grade="grade" tooltip />
                </span>
              </s-badge>
              <s-badge
                v-if="!grade && assignment.gradesAreVisible"
                color="gray"
                class="min-w-[6.5rem] justify-end"
              >
                <span class="opacity-70">{{ t('grade') }}</span>
                <span class="font-bold whitespace-nowrap">
                  <s-grade :grade-display="course.gradeDisplay" :grade="null" tooltip />
                </span>
              </s-badge>
              <s-badge
                v-if="!assignment.gradesAreVisible"
                color="gray"
                class="min-w-[6.5rem] justify-end"
              >
                <s-icon name="lock" class="text-gray-600" size="18" />
                <span class="sr-only">{{ t('gradesHidden') }}</span>
              </s-badge>
            </s-badge-group>
          </div>
        </div>
        <div class="grid grid-cols-[1fr_1fr] sm:grid-cols-[1fr_1fr_auto] gap-y-2 gap-x-6">
          <SLabelValue :label="t('startDate')" class="flex-1">
            <span
              v-if="assignment.courseLikeAssignmentsInfo.startDate"
              class="-mt-0.5 block"
              :class="{
                'text-gray-600': assignment.extension?.newStartDate,
                'line-through': assignment.extension?.newStartDate,
              }"
            >
              <s-date :date="assignment.courseLikeAssignmentsInfo.startDate" />
            </span>
            <span v-else>
              {{ t('badges.notSet') }}
            </span>
          </SLabelValue>
          <SLabelValue
            class="flex-1"
            :class="{
              'text-red-600': highlightPenalty,
            }"
          >
            <template #label>
              <span
                class="flex items-center gap-0.5"
                :class="{
                  'font-bold': highlightPenalty,
                }"
              >
                <s-icon v-if="highlightPenalty" name="alert" class="opacity-70" size="16" />
                {{ t('dueDate') }}
              </span>
            </template>
            <span
              v-if="assignment.courseLikeAssignmentsInfo.dueDate"
              class="-mt-0.5 block"
              :class="{
                'text-gray-600': assignment.extension?.newDueDate,
                'line-through': assignment.extension?.newDueDate,
              }"
            >
              <s-date :date="assignment.courseLikeAssignmentsInfo.dueDate" />
            </span>
            <s-badge v-else color="red" size="sm">
              {{ t('badges.notSet') }}
            </s-badge>
          </SLabelValue>
          <div class="flex flex-wrap gap-y-2 gap-x-6">
            <SLabelValue
              v-if="assignment.courseLikeAssignmentsInfo.timelimitEnabled"
              :label="t('timeLimit')"
              class="!gap-0"
            >
              {{
                assignment.courseLikeAssignmentsInfo.timelimitInMinutes
                  ? minutesToHumanReadable(assignment.courseLikeAssignmentsInfo.timelimitInMinutes)
                  : t('notSet')
              }}
            </SLabelValue>
          </div>
        </div>
        <div
          v-if="assignment.extension"
          class="my-1 border rounded-md px-4 pt-2 pb-1 bg-gradient-to-r from-blue-50/20 via-blue-50/50 to-blue-50/50 border border-blue-200/40 grid grid-cols-[auto,1fr] grid-rows-[1fr_auto] gap-x-1.5"
        >
          <div class="row-span-2 pt-1">
            <s-icon name="warning" class="text-blue-400" size="18" />
            <span class="sr-only">{{ t('labels.warning') }}</span>
          </div>
          <div class="flex gap-1 mb-0.5 pt-0.5">
            <SLabelValue
              v-if="assignment.extension.newStartDate"
              :label="t('labels.adjustedStartDate')"
              class="!gap-0 flex-1"
            >
              <s-date :date="assignment.extension.newStartDate" class="font-bold text-blue-600" />
            </SLabelValue>
            <SLabelValue
              v-if="assignment.extension.newDueDate"
              :label="t('labels.adjustedDueDate')"
              class="!gap-0 flex-1"
            >
              <s-date :date="assignment.extension.newDueDate" class="font-bold text-blue-600" />
            </SLabelValue>
          </div>
          <i18n-t keypath="descriptions.extension" tag="p" class="text-sm text-gray-700">
            <template v-slot:name>
              <strong>{{ assignment.extension.createdBy }}</strong>
            </template>
            <template v-slot:date>
              <strong>
                {{ monthDayYear(assignment.extension.createdAt, {time: true}) }}
              </strong>
            </template>
          </i18n-t>
        </div>
      </div>
      <template #footer>
        <div class="flex justify-end flex-wrap gap-x-4 gap-y-3">
          <s-link
            :href="
              route('courses.assignments.show', {
                course: course.id,
                assignment: assignment.id,
              })
            "
            class="link text-sm whitespace-nowrap flex items-center gap-1.5"
          >
            <s-icon name="eye" />
            {{ t('links.show') }}
          </s-link>
          <span
            class="btn group-focus:outline group-focus-visible:outline-blue-400 min-w-32"
            :class="actionColor"
          >
            {{ t('links.' + assignmentProgressStatus) }}
            <s-icon name="chevron-right" class="-ml-0.5" />
          </span>
        </div>
      </template>
      <template
        v-if="
          assignment.courseLikeAssignmentsInfo.lateResponsePenaltyPolicy && !assignmentCompleted
        "
      >
        <div
          class="px-4 md:px-5 lg:px-6 pt-2 pb-2.5 md:pt-3 md:pb-3.5 bg-gradient-to-b border-t shadow-inset flex items-center gap-1.5"
          :class="{
            'from-red-100/50 via-red-100/30 to-red-100/30 border-red-200/70 text-red-700':
              highlightPenalty,
            'from-gray-100 via-gray-50 to-gray-50 border-gray-150 text-gray-700': !highlightPenalty,
          }"
        >
          <s-icon
            :name="highlightPenalty ? 'alert' : 'calendar-clock'"
            :class="highlightPenalty ? 'text-red-600' : 'opacity-70'"
            size="18"
          />
          <p class="text-base leading-snug">
            <s-penalty-policy-student
              :isPastDueDate="isPastDueDate"
              :penalty="{
                type: assignment.courseLikeAssignmentsInfo.lateResponsePenaltyPolicy.penaltyType,
                value: assignment.courseLikeAssignmentsInfo.lateResponsePenaltyPolicy.penaltyValue,
              }"
              :frequency="{
                type: assignment.courseLikeAssignmentsInfo.lateResponsePenaltyPolicy.frequencyUnit,
                value:
                  assignment.courseLikeAssignmentsInfo.lateResponsePenaltyPolicy.frequencyValue,
              }"
              :submission-cutoff="effectiveCutoffDate"
            />
          </p>
        </div>
      </template>
    </s-card>
  </s-link>
</template>

<script setup lang="ts">
import {route} from 'ziggy-js';
import TimerBadge from './TimerBadge.vue';
import SLink from '../../../components/SLink.vue';
import SGrade from '../../../components/SGrade.vue';
import SPenaltyPolicyStudent from '../../../components/SPenaltyPolicyStudent.vue';
import SCard from '../../../design-system/SCard.vue';
import SDate from '../../../design-system/SDate.vue';
import STooltip from '../../../design-system/STooltip.vue';
import SLabelValue from '../../../design-system/SLabelValue.vue';
import SBadge from '../../../design-system/SBadge.vue';
import SBadgeGroup from '../../../design-system/SBadgeGroup.vue';
import {isDatePast, minutesToHumanReadable, monthDayYear} from '../../../format/dates';
import {Course} from '../../../types/entities/course';
import {useI18n} from 'vue-i18n';
import SIcon from '../../../design-system/SIcon.vue';
import {computed} from 'vue';
import StudentCourseAssignmentRowDto = App.DTOs.CourseAssignments.StudentCourseAssignmentRowDto;
import AssignmentGradeDto = App.DTOs.AssignmentGradeDto;

const props = defineProps<{
  course: Course;
  assignment: StudentCourseAssignmentRowDto;
  grade?: AssignmentGradeDto | null;
  includeCourseCode?: boolean;
}>();

const {t} = useI18n({
  useScope: 'local',
  inheritLocale: true,
});

type PillColor = 'white' | 'gray' | 'blue' | 'green' | 'yellow' | 'red';

const unclaimedExtensions = computed(() => {
  return props.assignment.timer?.extensions?.filter((extension) => !extension.claimed);
});

const assignmentProgressStatus = computed(() => {
  if (!props.assignment.numSubmissions && !props.assignment.timer?.hasStarted) {
    return 'not-started';
  } else if (
    props.assignment.numSubmissions >= props.assignment.numTasks ||
    (props.assignment.timer?.hasFinished && unclaimedExtensions.value?.length === 0)
  ) {
    return 'completed';
  } else {
    return 'in-progress';
  }
});

const actionColor = computed(() => {
  if (assignmentProgressStatus.value === 'not-started') {
    return 'primary';
  } else if (assignmentProgressStatus.value === 'completed') {
    return 'white';
  } else {
    return 'blue-light';
  }
});

const isPastDueDate = computed(() => {
  const activeDueDate =
    props.assignment.extension?.newDueDate || props.assignment.courseLikeAssignmentsInfo.dueDate;
  if (!activeDueDate) return false;
  return isDatePast(activeDueDate);
});

const assignmentCompleted = computed(() => {
  return props.assignment.numSubmissions >= props.assignment.numTasks;
});

const highlightPenalty = computed(() => {
  return isPastDueDate.value && !assignmentCompleted.value;
});

const effectiveCutoffDate = computed(() => {
  // 1. An interval calculated between the cutoff and the original due date
  // 2. The interval is applied to the effective due date to get the effective cutoff date
  const originalDueDate = props.assignment.courseLikeAssignmentsInfo.dueDate;
  const cutoffDate =
    props.assignment.courseLikeAssignmentsInfo.lateResponsePenaltyPolicy?.submissionCutoff;
  if (!cutoffDate || !originalDueDate) return null;
  const interval = new Date(cutoffDate).getTime() - new Date(originalDueDate).getTime();
  const effectiveDueDate =
    props.assignment.extension?.newDueDate || props.assignment.courseLikeAssignmentsInfo.dueDate;
  if (!effectiveDueDate) return null;
  return new Date(new Date(effectiveDueDate).getTime() + interval);
});

const gradePillColors: Record<string, PillColor> = {
  ungraded: 'gray',
  incorrect: 'red',
  partial: 'blue',
  correct: 'green',
};

type HasOriginalNumeratorAndDenominator = {
  originalNumerator: number;
  originalDenominator: number;
};

function determineGradePillColor(grade: HasOriginalNumeratorAndDenominator): PillColor {
  const gradeState = determineGradeState(grade) || 'ungraded';
  return gradePillColors[gradeState] || 'gray';
}

function hasDrafts(assignment: StudentCourseAssignmentRowDto) {
  return !!assignment.numDrafts;
}

// START COPIED CODE
// Pulled from backend/resources/js/pages/AssignmentMarking/Show.vue
// Not sure if better to export/import?
type TaskResponseState = 'incomplete' | 'ungraded' | 'incorrect' | 'partial' | 'correct';

const getGradePercentage = (grade: HasOriginalNumeratorAndDenominator) => {
  return Math.round((100 * Number(grade.originalNumerator)) / Number(grade.originalDenominator));
};

function determineGradeState(grade: HasOriginalNumeratorAndDenominator): TaskResponseState {
  const gradePercentage = getGradePercentage(grade);

  if (gradePercentage === 0) {
    return 'incorrect';
  }

  if (gradePercentage === 100) {
    return 'correct';
  }

  return 'partial';
}

// END COPIED CODE
</script>
<i18n>
{
  "en": {
    "notSet": "Not Set",
    "badges": {
      "notSet": "Not Set"
    },
    "labels": {
      "adjustedStartDate": "New Start Date",
      "adjustedDueDate": "New Due Date",
      "warning": "Warning",
      "draftWarning": "You have {count} unsubmitted drafts for this assignment."
    },
    "descriptions": {
      "extension": "Granted by {name} on {date}"
    },
    "links": {
      "show": "View Details",
      "not-started": "Start",
      "in-progress": "Continue",
      "completed": "Review",
      "tasks": "Go to Assignment"
    }
  },
  "fr": {
    "notSet": "Non défini",
    "badges": {
      "notSet": "Non défini"
    },
    "labels": {
      "adjustedStartDate": "Nouvelle date de début",
      "adjustedDueDate": "Nouvelle date d'échéance",
      "warning": "Avertissement",
      "draftWarning": "Vous avez {count} brouillons non soumis pour cette tâche."
    },
    "descriptions": {
      "extension": "Accordée par {name} le {date}"
    },
    "links": {
      "show": "Voir les détails",
      "not-started": "Commencer",
      "in-progress": "Continuer",
      "completed": "Réviser",
      "tasks": "Aller au devoir"
    }
  }
}
</i18n>
