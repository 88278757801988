import {router} from '@inertiajs/vue3';
import {route} from 'ziggy-js';
import {customRef} from 'vue';
import {reactiveComputed, tryOnMounted, tryOnUnmounted} from '@vueuse/core';

export function usePageParams<PageParams extends Record<string, any>>(defaults?: PageParams) {
  let trigger: () => void;

  const ref = customRef((_track, _trigger) => {
    trigger = _trigger;

    return {
      get: () => {
        _track();
        return {
          ...defaults,
          ...(route().params as PageParams),
        };
      },
      set: () => {},
    };
  });

  let stopHandle: () => void;

  tryOnMounted(() => {
    stopHandle = router.on('navigate', () => trigger?.());
  });

  tryOnUnmounted(() => {
    stopHandle?.();
  });

  return reactiveComputed(() => ref.value);
}
