<template>
  <SNavHeader
    :links="[
      {
        label: t('General'),
        icon: 'pencil',
        href: route('courses.edit', {course: course.id}),
      },
      {
        label: t('sections'),
        icon: 'calendar',
        href: route('courses.sections.index', {course: course.id}),
      },
    ]"
    :title="t('title')"
    size="md"
    class="mb-3"
  >
    <template #actions>
      <s-btn
        v-if="course.subCourseId"
        :href="route('courses.edit', {course: course.subCourseId})"
        color="blue-light"
      >
        {{ t('actions.viewSubCourse') }}
      </s-btn>
      <s-btn
        v-else-if="course.parentCourseId"
        :href="route('courses.edit', {course: course.parentCourseId})"
        color="blue-light"
      >
        {{ t('actions.viewParentCourse') }}
      </s-btn>
      <s-btn
        v-else-if="canCreate"
        :href="
          route('courses.create', {
            _query: {
              parent_id: course.id,
            },
          })
        "
        icon="plus"
        color="blue-light"
      >
        {{ t('actions.createSubCourse') }}
      </s-btn>
    </template>
  </SNavHeader>
</template>
<script setup lang="ts">
import {useI18n} from 'vue-i18n';
import {route} from 'ziggy-js';
import {Course} from '../types/entities/course';
import SBtn from '../design-system/SBtn.vue';
import SNavHeader from '../design-system/SNavHeader.vue';

defineProps<{
  course: Course;
  canCreate?: boolean;
}>();

const {t} = useI18n({
  useScope: 'local',
  inheritLocale: true,
});
</script>
<i18n>
{
  "en": {
    "title": "Course Settings",
    "actions": {
      "createSubCourse": "Add a Lab/Tutorial",
      "viewParentCourse": "View Parent Course",
      "viewSubCourse": "View Lab/Tutorial"
    }
  },
  "fr": {
    "title": "Paramètres du cours",
    "actions": {
      "createSubCourse": "Ajouter un labo/tutoriel",
      "viewParentCourse": "Voir le cours parent",
      "viewSubCourse": "Voir le labo/tutoriel"
    }
  }
}
</i18n>
