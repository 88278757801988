<template>
  <div class="@container flex-1 min-w-0 max-w-full flex flex-col gap-3">
    <div class="grid grid-cols-1 @sm:grid-cols-2 @2xl:grid-cols-4 gap-x-4 gap-y-3">
      <div class="@sm:col-span-2">
        <s-input-field id="numberVariableName" v-model="name" label="Name" :error="errors?.name" />
      </div>
      <div class="min-w-0 flex flex-col items-stretch gap-1" role="none">
        <s-label for="numberVariableMinimum">Scientific Notation</s-label>
        <s-toggle
          id="numberVariableMinimum"
          v-model="scientificNotation"
          :error="errors?.scientificNotation"
        />
      </div>
      <div class="min-w-0 flex flex-col items-stretch gap-1" role="none">
        <s-label for="numberVariableIncludeZero">Include Zero</s-label>
        <s-toggle
          id="numberVariableIncludeZero"
          v-model="includeZero"
          :error="errors?.includeZero"
        />
      </div>
    </div>
    <div class="grid grid-cols-2 @lg:grid-cols-4 gap-x-4 gap-y-3">
      <s-input-field
        id="numberVariableMinimum"
        v-model.number="min"
        label="Minimum"
        tooltip="The minimum value this variable can take"
        type="number"
        :error="errors?.min"
      />
      <s-input-field
        id="numberVariableMaximum"
        v-model.number="max"
        label="Maximum"
        tooltip="The maximum value this variable can take"
        type="number"
        :error="errors?.max"
      />
      <s-input-field
        id="numberVariableShift"
        v-model.number="shift"
        label="Shift"
        tooltip="Offset the random number by a power of ten"
        type="number"
        :error="errors?.shift"
      />
      <s-input-field
        id="numberVariableStep"
        v-model.number="step"
        label="Step"
        tooltip="The size between two successive possible numbers"
        type="number"
        :error="errors?.step"
      />
    </div>
    <div
      v-if="generatedNumbers.length > 0"
      class="italic truncate flex-1 text-gray-600 font-medium"
    >
      Generated Range: {{ generatedNumbers.join(', ') }}
    </div>
  </div>
</template>

<script setup lang="ts">
import {computed} from 'vue';
import SLabel from '../../../../design-system/SLabel.vue';
import SInputField from '../../../../design-system/SInputField.vue';
import {useVModels} from '@vueuse/core';
import SToggle from '../../../../design-system/SToggle.vue';
import {generateNumberVariableExamples} from '../../../../util/number-variables';

const props = defineProps<{
  name: string;
  min: number;
  max: number;
  shift?: number;
  step?: number;
  scientificNotation?: boolean;
  includeZero?: boolean;
  errors?: Record<
    'name' | 'min' | 'max' | 'shift' | 'step' | 'scientificNotation' | 'includeZero',
    string
  >;
}>();

const {name, min, max, shift, step, scientificNotation, includeZero} = useVModels(props);

const generatedNumbers = computed(() => {
  let min = props.min;
  let max = props.max;
  let step = props.step;
  let shift = props.shift;

  return generateNumberVariableExamples(min, max, step, shift, includeZero.value);
});
</script>
