<template>
  <section class="w-full" :class="[sizeClasses[props.size], paddingClasses[props.padding]]">
    <slot />
  </section>
</template>
<script setup lang="ts">
export type Size = 'full' | 'lg' | 'md' | 'sm';
export type Padding = 'none' | 'full' | 'horizontal';

const props = withDefaults(
  defineProps<{
    size?: Size;
    padding?: Padding;
  }>(),
  {
    size: 'lg',
    padding: 'full',
  }
);

const sizeClasses: Record<Size, string> = {
  full: '',
  lg: 'max-w-screen-xl mx-auto',
  md: 'max-w-screen-lg mx-auto',
  sm: 'max-w-screen-sm mx-auto',
};

const paddingClasses: Record<Padding, string> = {
  none: '',
  full: 'px-5 lg:px-7 py-4 last-of-type:pb-7 first-of-type:pt-3',
  horizontal: 'px-5 lg:px-7',
};
</script>
