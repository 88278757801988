<template>
  <span
    class="px-3 py-1 rounded-full border shadow text-base font-bold leading-none"
    :class="
      statusBadgeClassMap[
        paymentRequest.paidOn ? 'green' : paymentRequest.exemptedOn ? 'blue' : 'red'
      ]
    "
  >
    {{
      paymentRequest.paidOn
        ? t('status.paid')
        : paymentRequest.exemptedOn
          ? t('status.exempt')
          : t('status.paymentDue')
    }}
  </span>
</template>
<script setup lang="ts">
import {PaymentRequest} from '../../types/entities/payment-request';
import {useI18n} from 'vue-i18n';

const {t} = useI18n({
  useScope: 'local',
  inheritLocale: true,
});

defineProps<{
  paymentRequest: Omit<PaymentRequest, 'user' | 'course'>;
}>();

const statusBadgeClassMap = {
  green: 'border-[#C1EF9B] bg-[#D4FFB1] text-[#689A3F]',
  blue: 'border-blue-100 bg-blue-50 text-blue-800',
  red: 'border-red-200 bg-red-100 text-red-800',
};
</script>
<i18n>
{
  "en": {
    "status": {
      "paid": "Paid",
      "exempt": "Exempt",
      "paymentDue": "Payment Due"
    }
  },
  "fr": {
    "status": {
      "paid": "Payé",
      "exempt": "Exempté",
      "paymentDue": "Paiement dû"
    }
  }
}
</i18n>
