<template>
  <div class="flex flex-col gap-0.5 w-full">
    <span class="text-base leading-tight">{{ shortDayOfWeekMonthDayOfMonth(props.dateTime) }}</span>
    <span class="text-sm leading-tight uppercase tracking-slight font-bold text-left">
      {{ timeOfDay(props.dateTime) }}
    </span>
  </div>
</template>
<script setup lang="ts">
import {shortDayOfWeekMonthDayOfMonth, timeOfDay} from '../../../format/dates';

const props = defineProps<{
  dateTime?: Date | null;
}>();
</script>
