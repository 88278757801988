import {NodeSpec} from 'prosemirror-model';

export let code_block: NodeSpec = {
  content: 'text*',
  marks: '',
  group: 'block',
  code: true,
  defining: true,
  parseDOM: [{tag: 'pre', preserveWhitespace: 'full'}],
  toDOM() {
    return ['pre', ['code', 0]];
  },
};
