import {InputRule} from 'prosemirror-inputrules';
import {Schema} from 'prosemirror-model';

/**
 * InputRule for creating Images
 */
export const imageRules: (schema: Schema) => InputRule[] = (schema) => {
  if (!schema.nodes.image) {
    return [];
  }

  return [
    // Markdown Syntax
    new InputRule(/^\/image /, (state, match, start, end) => {
      const tr = state.tr;
      const image = schema.nodes.image.create({src: '', alt: ''});
      tr.replaceWith(start, end, image);
      return tr;
    }),
  ];
};
