<template>
  <s-select-field :id="control.prop" :label="control.label" v-model="model">
    <option v-for="option in control.options">
      {{ option }}
    </option>
  </s-select-field>
</template>
<script setup lang="ts">
import SSelectField from '../../../design-system/SSelectField.vue';
import {Control} from '../examples';

defineProps<{
  control: Control;
}>();

const model = defineModel();
</script>
