<!--

# SField

A wrapper component for various types of form inputs.

It provides the label and error message for the input, along with the necessary aria attributes.

-->
<template>
  <div role="none" class="flex-1 min-w-0 flex flex-col items-stretch gap-1">
    <s-label v-if="label || $slots.label" :for="id">
      <slot name="label">
        {{ label }}
      </slot>
      <s-tooltip v-if="tooltip || $slots.tooltip">
        <template #tooltip>
          <slot name="tooltip">
            {{ tooltip }}
          </slot>
        </template>
        <s-icon
          name="information"
          width="16"
          height="16"
          class="text-blue-500 opacity-70"
          role="presentation"
        />
      </s-tooltip>
    </s-label>
    <p v-if="description" class="text-base text-gray-700 leading-tight -mt-0.5 mb-1">
      {{ description }}
    </p>
    <slot v-bind="ariaProps" />
    <!--
    ARIA role="alert" is used to indicate that the element contains an
    error message that should be communicated to the user immediately.

    https://developer.mozilla.org/en-US/docs/Web/Accessibility/ARIA/Roles/alert_role
    -->
    <div
      :id="ids.error"
      v-if="error"
      role="alert"
      class="flex items-center justify-start gap-1 mt-0.5 text-sm font-medium leading-tight text-red-600"
    >
      <s-icon name="warning" size="16" class="opacity-70" />
      {{ error }}
    </div>
  </div>
</template>
<script setup lang="ts">
import {computed, type VNode} from 'vue';
import SLabel from './SLabel.vue';
import STooltip from './STooltip.vue';
import SIcon from './SIcon.vue';

const {
  id,
  label,
  description,
  tooltip,
  error,
  required = false,
} = defineProps<{
  id: string;
  label?: string | null;
  description?: string | null;
  tooltip?: string | VNode | null;
  error?: string | null;
  required?: boolean;
}>();

const ids = computed(() => ({
  description: `${id}-description`,
  error: `${id}-error`,
}));

const ariaProps = computed(() => {
  return {
    id: id,
    'aria-invalid': !!error,
    'aria-describedby': ids.value.error,
    'aria-required': required,
  };
});
</script>
