<template>
  <s-form-list
    formTitle="Create DataDrivenTask"
    title="Variables"
    addLabel="Add Variable"
    :addItem="addNumberVariable"
    :items="variablesClone"
    itemKey="uuid"
    :displayValue="(item?: any) => item?.name || 'Unamed Variable'"
    :removeItem="(item?: any) => removeVariable(item.uuid)"
    removeLabel="Remove Variable"
    :confirmRemove="true"
    placeholder="No Variables Added"
  >
    <template #item="{item: variable, index: i}">
      <div class="p-5">
        <number-variable-input
          :name="variable.name"
          @update:name="updateVariableValue(i, 'name', $event)"
          :min="variable.min"
          @update:min="updateVariableValue(i, 'min', $event)"
          :max="variable.max"
          @update:max="updateVariableValue(i, 'max', $event)"
          :shift="variable.shift"
          @update:shift="updateVariableValue(i, 'shift', $event)"
          :step="variable.step"
          @update:step="updateVariableValue(i, 'step', $event)"
          :include-zero="variable.includeZero"
          @update:include-zero="updateVariableValue(i, 'includeZero', $event)"
          :scientific-notation="variable.scientificNotation"
          @update:scientific-notation="updateVariableValue(i, 'scientificNotation', $event)"
          :errors="errors?.[i]"
        />
      </div>
    </template>
  </s-form-list>
</template>

<script setup lang="ts">
import NumberVariableInput from './NumberVariableInput.vue';
import SFormList from '../../../../design-system/SFormList.vue';
import {v4 as uuidV4} from 'uuid';
import {ref, toRef, watch} from 'vue';
import NumberVariableDto = App.DTOs.Tasks.Variables.NumberVariableDto;

const props = defineProps<{
  variables: NumberVariableDto[];
  errors?: Record<string, any>;
}>();

const variablesClone = ref<NumberVariableDto[]>(JSON.parse(JSON.stringify(props.variables)));
watch(
  toRef(props, 'variables'),
  () => {
    variablesClone.value = JSON.parse(JSON.stringify(props.variables));
  },
  {deep: true}
);

const emit = defineEmits<{
  (evt: 'update:variables', payload: NumberVariableDto[]): void;
}>();

const addNumberVariable = () => {
  const variable: NumberVariableDto = {
    name: '',
    uuid: uuidV4(),
    type: 'Number',
    min: 0,
    max: 10,
    shift: 0,
    step: 1,
    scientificNotation: false,
    includeZero: true,
  };

  emit('update:variables', [...props.variables, variable]);
};

const removeVariable = (uuid: string) => {
  const index = variablesClone.value.findIndex((variable) => variable.uuid === uuid);
  variablesClone.value.splice(index, 1);

  emit('update:variables', variablesClone.value);
};

function updateVariableValue<Key extends keyof NumberVariableDto>(
  index: number,
  key: Key,
  value: NumberVariableDto[Key]
) {
  variablesClone.value[index][key] = value;
  emit('update:variables', variablesClone.value);
}
</script>
