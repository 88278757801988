<template>
  <button
    type="button"
    @click="isOpen = true"
    :title="title ?? t('help')"
    class="p-2 -m-2 transition text-blue-500 opacity-70 hover:opacity-100"
  >
    <s-icon name="help-circle" size="20" />
    <span class="sr-only">{{ title || t('actions.open') }}</span>
  </button>
  <s-modal :title="title ?? t('help')" v-model:open="isOpen" confirmable>
    <template #content>
      <slot />
    </template>
  </s-modal>
</template>

<script setup lang="ts">
import SModal from './SModal.vue';
import {ref} from 'vue';
import {useI18n} from 'vue-i18n';
import SIcon from './SIcon.vue';

const props = defineProps<{
  title?: string | null;
}>();

const isOpen = ref(false);

const {t} = useI18n({
  useScope: 'local',
  inheritLocale: true,
});
</script>
