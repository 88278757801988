<template>
  <s-page-block size="md">
    <s-page-header class="mb-2">
      <template #heading>
        <s-latex :content="task.title" class="whitespace-pre-wrap" />
      </template>
      <template #actions>
        <s-btn
          v-if="can.update"
          icon="pencil"
          color="blue-light"
          :href="route('tasks.edit', {task: task.id})"
        >
          {{ t('actions.edit') }}
        </s-btn>
      </template>
    </s-page-header>

    <div class="flex flex-wrap gap-x-2 gap-y-3">
      <s-badge-group>
        <s-badge size="custom" class="pl-2.5 pr-2 py-0.5 text-sm">
          <strong>ID</strong>
        </s-badge>
        <s-badge color="white" size="custom" class="px-2.5 py-0.5 text-sm">
          {{ task.id }}
        </s-badge>
      </s-badge-group>

      <s-badge-group>
        <s-task-type-badge :type="task.type" />
        <s-task-component-badge v-if="task.component" :component="task.component" />
      </s-badge-group>

      <s-badge v-if="permutations > 1" icon="shuffle">
        <strong class="font-black">{{ permutations }}</strong>
        Permutations
      </s-badge>

      <s-badge v-if="task.draftable" color="gray" icon="pencil">
        {{ t('draftable') }}
      </s-badge>
    </div>

    <div v-if="task.textbookLink || task.youtubeId" class="flex flex-wrap gap-4 mt-3">
      <s-label-value v-if="task.textbookLink" label="Textbook Link">
        {{ task.textbookLink }}
      </s-label-value>
      <s-label-value v-if="task.youtubeId" label="YouTube ID">
        <a
          :href="'https://www.youtube.com/watch?v=' + task.youtubeId"
          target="_blank"
          rel="noopener"
          class="link flex items-center gap-1"
        >
          <s-icon name="youtube" class="text-[#CD201F]" />
          {{ task.youtubeId }}
        </a>
      </s-label-value>
    </div>

    <s-details size="lg" class="rounded-b-none border-b-0 mt-4" open>
      <template #summary>
        <span class="text-lg flex items-center gap-1.5">
          <s-icon name="eye" size="20" class="opacity-70" />
          {{ t('preview') }}
        </span>
      </template>
      <task-preview-form :preview-task="previewTask" :preview-task-state="previewTaskState" />
    </s-details>
    <s-details size="lg" class="rounded-t-none">
      <template #summary>
        <span class="text-lg flex items-center gap-1.5">
          <s-icon name="text-box-multiple-outline" size="18" class="opacity-70" />
          Rubric
        </span>
      </template>
      <task-rubric v-if="rubric" :rubric="rubric" />
    </s-details>
  </s-page-block>
</template>
<script lang="ts" setup>
import {computed} from 'vue';
import {Task, TaskState} from '../../types/entities/tasks';
import SPageBlock from '../../design-system/SPageBlock.vue';
import SPageHeader from '../../design-system/SPageHeader.vue';
import SLabelValue from '../../design-system/SLabelValue.vue';
import SIcon from '../../design-system/SIcon.vue';
import SCard from '../../design-system/SCard.vue';
import SDetails from '../../design-system/SDetails.vue';
import SBadgeGroup from '../../design-system/SBadgeGroup.vue';
import SBadge from '../../design-system/SBadge.vue';
import SLatex from '../../components/SLatex.vue';
import STaskTypeBadge from '../../components/STaskTypeBadge.vue';
import STaskComponentBadge from '../../components/STaskComponentBadge.vue';
import {useI18n} from 'vue-i18n';
import SBtn from '../../design-system/SBtn.vue';
import TaskPreviewForm from '../../forms/TaskPreviewForm.vue';
import {route} from 'ziggy-js';
import TaskShowDto = App.DTOs.Tasks.TaskShowDto;
import {calculatePermutations} from '../../util/number-variables';
import TaskRubric from '../../components/TaskRubric.vue';
import Rubric = App.DTOs.Rubrics.Rubric;

const {task, rubric} = defineProps<{
  task: TaskShowDto;
  previewTask: Task;
  previewTaskState: TaskState;
  rubric: Rubric | null;
  can: {
    update: boolean;
  };
}>();

const {t} = useI18n({
  useScope: 'local',
  inheritLocale: true,
});

const permutations = computed(() => {
  if (!rubric || !rubric.variables) return 1;
  return rubric.variables.reduce((acc: number, variable: any) => {
    return (
      acc *
      calculatePermutations(
        variable.min,
        variable.max,
        variable.step,
        variable.shift,
        variable.includeZero
      )
    );
  }, 1);
});

/* For all links on the page with a # in the href,
   scroll to the element in the main content area.

   The default browser scrolling behaviour breaks
   the layout
*/
document.addEventListener('DOMContentLoaded', () => {
  const main = document.querySelector('main');

  if (!main) {
    console.error('Main element not found');
    return;
  }

  document.querySelectorAll('a[href^="#"]').forEach((anchor: any) => {
    anchor.addEventListener('click', function (e: any) {
      e.preventDefault();

      // @ts-ignore
      const target = document.querySelector(this.getAttribute('href'));
      if (target) {
        // Calculate target's position relative to the main container
        const targetPosition = target.offsetTop - main.offsetTop;

        console.log(`Scrolling to ${targetPosition} within the main element.`);

        main.scrollTo({
          top: targetPosition,
          behavior: 'smooth',
        });
      } else {
        // @ts-ignore
        console.warn(`Target not found for href: ${this.getAttribute('href')}`);
      }
    });
  });
});
</script>
<i18n>
{
  "en": {
    "scientificNotation": "Scientific Notation",
    "includesZero": "Includes Zero",
    "draftable": "Drafts Enabled",
    "relative": "Relative",
    "absolute": "Absolute",
    "none": "None",
    "preview": "Preview"
  },
  "fr": {
    "scientificNotation": "Notation Scientifique",
    "includesZero": "Inclut Zéro",
    "draftable": "Brouillons activés",
    "relative": "Relatif",
    "absolute": "Absolu",
    "none": "Aucun",
    "preview": "Aperçu"
  }
}
</i18n>
