<template>
  <Head :title="`Admin - Pricing for ${llmMetadata.model}`" />

  <div class="page-block">
    <h2 class="heading lg mb-2">Model Pricing History for {{ llmMetadata.model }}</h2>

    <table class="w-full mt-4 border-collapse border border-gray-200">
      <thead>
        <tr class="bg-gray-100">
          <th class="border border-gray-200 p-2 text-left">Price Start Date</th>
          <th class="border border-gray-200 p-2 text-left">Price End Date</th>
          <th class="border border-gray-200 p-2 text-left">Input Token Pricing</th>
          <th class="border border-gray-200 p-2 text-left">Output Token Pricing</th>
        </tr>
      </thead>
      <tbody>
        <template v-for="(row, index) in pricingRows" :key="row.id ?? `new-${index}`">
          <tr class="hover:bg-gray-50">
            <td class="border border-gray-200 p-2">
              <span>
                {{ row.effectiveFrom ? monthDayYear(new Date(row.effectiveFrom)) : '—' }}
              </span>
            </td>
            <td class="border border-gray-200 p-2">
              <span>
                {{ row.effectiveTo ? monthDayYear(new Date(row.effectiveTo)) : '—' }}
              </span>
            </td>
            <td class="border border-gray-200 p-2">
              <s-input-field
                prefix="$"
                suffix="per 1M tokens"
                id="inputTokenPricing"
                type="number"
                step="0.01"
                min="0"
                v-model="row.inputTokenPricingPerMillionTokens"
                :readonly="!isRowInEditMode(row.id)"
                :error="row.errors?.inputTokenPricingPerMillionTokens"
              />
            </td>
            <td class="border border-gray-200 p-2">
              <s-input-field
                id="outputTokenPricing"
                prefix="$"
                suffix="per 1M tokens"
                type="number"
                step="0.01"
                min="0"
                v-model="row.outputTokenPricingPerMillionTokens"
                :readonly="!isRowInEditMode(row.id)"
                :error="row.errors?.outputTokenPricingPerMillionTokens"
              />
            </td>
            <td
              class="border border-gray-200 p-2"
              :rowspan="index < pricingRows.length - 1 ? 2 : 1"
              style="vertical-align: middle"
            >
              <div v-if="!isRowInEditMode(row.id)">
                <s-btn
                  class="btn-sm"
                  icon="pencil"
                  color="secondary"
                  @click="startEdit(row)"
                  :disabled="someRowIsBeingEdited"
                >
                  Edit
                </s-btn>
                <s-btn
                  class="btn-sm"
                  icon="cancel"
                  color="danger"
                  @click.prevent="deleteRow(row, index)"
                  :disabled="someRowIsBeingEdited || isOnlyRow"
                >
                  Delete
                </s-btn>
              </div>
              <div v-else>
                <s-btn
                  class="btn-sm"
                  icon="check"
                  color="primary"
                  @click="saveRows()"
                  :disabled="rowHasErrors(row)"
                  :processing="row.saving"
                >
                  Save
                </s-btn>
                <s-btn class="btn-sm" icon="cancel" color="secondary" @click="cancelAllEdits()">
                  Cancel
                </s-btn>
              </div>
            </td>
          </tr>
          <tr v-if="index < pricingRows.length - 1" class="bg-gray-50">
            <td colspan="4" class="border border-gray-200 p-2">
              <div class="flex items-center justify-center gap-4 w-1/2 mx-auto">
                <span class="text-gray-700 font-semibold">Adjust date for price change:</span>
                <s-input-field
                  id="effectiveTo"
                  type="date"
                  v-model="row.effectiveTo"
                  @change="handleEndDateChange(row, index)"
                  :error="row.errors?.effectiveTo"
                  :readonly="!isRowInEditMode(row.id)"
                />
              </div>
            </td>
          </tr>
        </template>
      </tbody>
    </table>

    <s-btn
      class="btn-sm mt-4"
      icon="plus"
      color="secondary"
      @click="addNewRow"
      :disabled="someRowIsBeingEdited"
    >
      Add New Pricing Row
    </s-btn>
  </div>
</template>

<script setup lang="ts">
import {ref, computed, reactive} from 'vue';
import {Head} from '@inertiajs/vue3';
import {route} from 'ziggy-js';
import axios from 'axios';

import SBtn from '../../../../design-system/SBtn.vue';
import SInputField from '../../../../design-system/SInputField.vue';
import {useBreadcrumbs} from '../../../../composables/useBreadcrumbs';
import {formatForDateInput, monthDayYear} from '../../../../format/dates';
import {LlmMetadata} from '../../../../types/entities/llmMetadata';

const props = defineProps<{
  histories: {
    id: number;
    llmMetadata: LlmMetadata;
    effectiveFrom: string | null;
    effectiveTo: string | null;
    inputTokenPricingPerMillionTokens: number;
    outputTokenPricingPerMillionTokens: number;
  }[];
  llmMetadata: LlmMetadata;
}>();

type PricingRow = {
  id: number | null;
  llmMetadata: LlmMetadata;
  effectiveFrom: string | null;
  effectiveTo: string | null;
  inputTokenPricingPerMillionTokens: number;
  outputTokenPricingPerMillionTokens: number;
  editing: boolean;
  saving: boolean;
  errors: Record<string, string>;
  _backup?: any;
};

const pricingRows = reactive<PricingRow[]>(
  props.histories.map((h) => ({
    ...h,
    effectiveFrom: formatForDateInput(h.effectiveFrom),
    effectiveTo: formatForDateInput(h.effectiveTo),
    editing: false,
    saving: false,
    errors: {},
  }))
);

const someRowIsBeingEdited = computed(() => pricingRows.some((row) => row.editing));
const isOnlyRow = computed(() => pricingRows.length === 1);

function isRowInEditMode(rowId: number | null) {
  return pricingRows.some((r) => r.id === rowId && r.editing);
}

function rowHasErrors(row: PricingRow) {
  return Object.keys(row.errors).length > 0;
}

function startEdit(row: PricingRow) {
  row.editing = true;
  row.errors = {};
  row._backup = JSON.parse(JSON.stringify(row));
}

function cancelEditForRow(row: PricingRow) {
  if (row && row.editing) {
    if (row._backup) {
      Object.assign(row, row._backup);
      delete row._backup;
    }
    row.editing = false;
    row.errors = {};
  }
}

function cancelAllEdits() {
  pricingRows.forEach((row) => cancelEditForRow(row));
  const lastRow = pricingRows[pricingRows.length - 1];
  if (lastRow && lastRow.id === null) {
    pricingRows.pop();
  }
}

function addNewRow() {
  if (someRowIsBeingEdited.value) return;

  const today = new Date();
  let newRowStartDate = today;

  if (pricingRows.length > 0) {
    const prevRow = pricingRows[pricingRows.length - 1];

    // If the previous row has an end date, new row starts the next day.
    if (prevRow.effectiveTo) {
      const prevEndDate = new Date(prevRow.effectiveTo);
      prevEndDate.setDate(prevEndDate.getDate() + 1);
      newRowStartDate = prevEndDate;
    } else {
      const yesterday = new Date(today);
      yesterday.setDate(yesterday.getDate() - 1);

      startEdit(prevRow);

      if (prevRow.effectiveFrom) {
        const prevStartDate = new Date(prevRow.effectiveFrom);
        if (prevStartDate > yesterday) {
          yesterday.setDate(prevStartDate.getDate());
          newRowStartDate = prevStartDate;
          newRowStartDate.setDate(newRowStartDate.getDate() + 1);
        }
      }
      prevRow.effectiveTo = formatForDateInput(yesterday.toISOString());
    }
  }

  pricingRows.push({
    id: null,
    llmMetadata: props.llmMetadata,
    effectiveFrom: formatForDateInput(newRowStartDate.toISOString()),
    effectiveTo: null,
    inputTokenPricingPerMillionTokens: 2.5,
    outputTokenPricingPerMillionTokens: 10.0,
    editing: true,
    saving: false,
    errors: {},
  });
}

function validateRowDates(row: PricingRow) {
  delete row.errors.effectiveTo; // clear existing error
  const fromDate = row.effectiveFrom ? new Date(row.effectiveFrom) : null;
  const toDate = row.effectiveTo ? new Date(row.effectiveTo) : null;

  // 1) End date must be >= start date
  if (fromDate && toDate && toDate < fromDate) {
    row.errors.effectiveTo = 'End date cannot be before its own start date.';
    return;
  }

  // 2) End date must not be > the next row's end date
  const index = pricingRows.indexOf(row);
  const notLastRow = index >= 0 && index < pricingRows.length - 1;

  if (notLastRow && toDate) {
    const nextRow = pricingRows[index + 1];
    const nextRowEndDate = nextRow.effectiveTo ? new Date(nextRow.effectiveTo) : null;

    if (nextRowEndDate && toDate > nextRowEndDate) {
      row.errors.effectiveTo = "End date cannot be after the next row's end date.";
    }
  }
}

function handleEndDateChange(row: PricingRow, index: number) {
  validateRowDates(row);
  if (row.errors.effectiveTo) {
    return;
  }
  row.editing = true;
  const nextRow = pricingRows[index + 1];
  if (!nextRow || !row.effectiveTo) return;

  // Make the next row start on the day after the current row ends
  const newEndDate = new Date(row.effectiveTo);
  newEndDate.setDate(newEndDate.getDate() + 1);
  startEdit(nextRow);
  nextRow.effectiveFrom = formatForDateInput(newEndDate.toISOString());
}

function saveRows() {
  const promises = pricingRows.filter((row) => row.editing).map((row) => saveRow(row));
  return Promise.all(promises);
}

function saveRow(row: PricingRow) {
  if (rowHasErrors(row)) {
    return Promise.reject('Cannot save row with errors.');
  }
  row.saving = true;
  row.errors = {};

  const isNew = row.id === null;
  const payload = {
    llmMetadataId: row.llmMetadata.id,
    effectiveFrom: row.effectiveFrom || null,
    effectiveTo: row.effectiveTo || null,
    inputTokenPricingPerMillionTokens: row.inputTokenPricingPerMillionTokens,
    outputTokenPricingPerMillionTokens: row.outputTokenPricingPerMillionTokens,
  };

  const request = isNew
    ? axios.post(route('admin.ai-analytics.pricings.store'), payload)
    : axios.put(
        route('admin.ai-analytics.pricings.update', {llmTokenPricingHistory: row.id}),
        payload
      );

  return request
    .then((response) => {
      // If newly created, assign the ID from the server
      if (isNew && response.data?.data?.id) {
        row.id = response.data.data.id;
      }
      row.editing = false;
      row.saving = false;
      row.errors = {};
      delete row._backup;
    })
    .catch((error) => {
      if (error.response?.data?.errors) {
        row.errors = error.response.data.errors;
      }
      row.saving = false;
      throw error;
    });
}

function deleteRow(row: PricingRow, index: number) {
  if (someRowIsBeingEdited.value || isOnlyRow.value) return;

  const doRemoval = () => {
    pricingRows.splice(index, 1);

    // If there's a next row (now at the same index), adjust its start date to the previous row's end + 1
    const prev = pricingRows[index - 1];
    const next = pricingRows[index];
    if (prev && next && prev.effectiveTo) {
      const prevEnd = new Date(prev.effectiveTo);
      prevEnd.setDate(prevEnd.getDate() + 1);
      next.effectiveFrom = formatForDateInput(prevEnd.toISOString());

      // Auto-save next row
      if (next.id && !next.editing) {
        next.editing = true;
        saveRow(next).catch(() => {});
      }
    }
  };
  axios
    .delete(route('admin.ai-analytics.pricings.destroy', {llmTokenPricingHistory: row.id}))
    .then(() => {
      doRemoval();
    })
    .catch((error) => {
      console.error('Error deleting row:', error);
    });
}

useBreadcrumbs([{label: 'Models Metadata', href: route('admin.ai-analytics.models.index')}]);
</script>

<style scoped></style>
