<template>
  <div class="page-block">
    <Head title="Admin" />
    <h1 class="heading xl mb-2">{{ t('admin') }}</h1>
    <p class="text-xl leading-tight">{{ t('welcome') }}</p>
  </div>
</template>
<script setup lang="ts">
import {Head} from '@inertiajs/vue3';
import {useI18n} from 'vue-i18n';

const {t} = useI18n();
</script>
<i18n>
{
  "en": {
    "admin": "Admin",
    "welcome": "Welcome to the admin dashboard!"
  },
  "fr": {
    "admin": "Admin",
    "welcome": "Bienvenue au tableau de bord de l'administrateur !"
  }
}
</i18n>
