<template>
  <div class="flex flex-col">
    <!-- Top Level Link-->
    <sidebar-link
      role="button"
      tabindex="0"
      class="cursor-pointer"
      :class="[
        activeRoute
          ? 'font-medium text-white'
          : 'font-normal text-white/80 hover:text-white hover:bg-blue-900/70',
      ]"
      :id="toggleId"
      :aria-controls="panelId"
      :aria-expanded="accordianIsOpen"
      @click="toggleAccordian"
      @keydown.space="toggleAccordian"
      @keydown.enter="toggleAccordian"
    >
      <div class="flex justify-between items-center gap-2 w-full">
        <div class="flex items-center gap-2.5">
          <s-icon
            v-if="!!icon"
            :name="icon"
            :class="activeRoute ? 'text-s-red-200' : 'text-blue-200'"
          />
          <div class="flex flex-col">
            <slot />
            <div v-if="subtext" class="text-sm font-normal leading-tight drop-shadow text-white/80">
              {{ subtext }}
            </div>
          </div>
        </div>
        <icon-chevron-down
          class="h-4 w-4 mb-1 transition-transform ease-in-out"
          :class="{'rotate-180': accordianIsOpen}"
          role="presentation"
        />
      </div>
    </sidebar-link>

    <nav
      v-if="links.length"
      :id="panelId"
      :aria-labelledby="toggleId"
      :aria-hidden="!accordianIsOpen"
      class="transition-[grid-template-rows] ease-out duration-200 grid min-h-0 overflow-hidden"
      :class="accordianIsOpen ? 'grid-rows-[1fr]' : 'grid-rows-[0fr] no-touching'"
      :hidden="!accordianIsOpen"
      :visibility="accordianIsOpen ? 'visible' : 'hidden'"
    >
      <div
        class="w-full overflow-hidden transition-opacity ease-out duration-200"
        :class="accordianIsOpen ? 'opacity-100' : 'opacity-0'"
      >
        <div class="text-sm flex flex-col gap-px pl-3 pb-2 -mt-0.5">
          <sidebar-item v-for="item in links" :key="item.label" :item="item">
            {{ item.label }}
          </sidebar-item>
        </div>
      </div>
    </nav>
  </div>
</template>
<script lang="ts" setup>
import {ref, watch, computed, defineProps} from 'vue';
import SidebarLink from './SidebarLink.vue';
import SidebarItem from './SidebarItem.vue';
import IconChevronDown from '../icons/IconChevronDown.vue';
import {usePage} from '@inertiajs/vue3';
import SIcon from '../../design-system/SIcon.vue';

const props = defineProps<{
  subtext?: string;
  prefix?: string;
  icon?: string;
  links: any[];
}>();

const randomId = Math.random().toString(36).substring(7);
const toggleId = `toggle-${randomId}`;
const panelId = `panel-${randomId}`;

const page = usePage();
const activeRoute = computed(() => {
  // Little janky but it can be improved once we're off the legacy frontend.
  return props.prefix
    ? page.url.startsWith(props.prefix) || page.url.startsWith('/web' + props.prefix)
    : false;
});

const accordianIsOpen = ref<boolean>(activeRoute.value);

watch(activeRoute, (newVal) => {
  if (newVal) {
    accordianIsOpen.value = true;
  } else {
    accordianIsOpen.value = false;
  }
});

const toggleAccordian = () => {
  accordianIsOpen.value = !accordianIsOpen.value;
};
</script>
