<template>
  <s-help-button :title="t('help.title')">
    <div class="prose">
      <p>
        {{ t('help.intro') }}
      </p>
      <p>
        {{ t('help.assignmentsWithManyResponses') }}
      </p>
      <p>
        {{ t('help.magnitudeOfTheCounts') }}
      </p>
    </div>
  </s-help-button>
  <v-chart class="chart" :option="option" autoresize />
</template>

<script setup lang="ts">
import type {ComposeOption} from 'echarts';
import {use} from 'echarts/core';
import {CanvasRenderer} from 'echarts/renderers';
import {BarChart, BarSeriesOption} from 'echarts/charts';
import {
  GridComponent,
  GridComponentOption,
  LegendComponent,
  LegendComponentOption,
  TitleComponent,
  TitleComponentOption,
  TooltipComponent,
  TooltipComponentOption,
} from 'echarts/components';
import VChart from 'vue-echarts';
import {computed} from 'vue';
import SHelpButton from '../../../design-system/SHelpButton.vue';
import {GradeDistribution} from '../../../types/GradeDistribution';
import {useI18n} from 'vue-i18n';

type ECOption = ComposeOption<
  | BarSeriesOption
  | TitleComponentOption
  | TooltipComponentOption
  | LegendComponentOption
  | GridComponentOption
>;

use([CanvasRenderer, BarChart, TitleComponent, TooltipComponent, LegendComponent, GridComponent]);

const props = defineProps<{
  data: {
    name: string;
    gradeDistribution: GradeDistribution;
  }[];
}>();

const series = computed<BarSeriesOption[]>(() => {
  const keys = [
    {key: 'n_20_responses', label: '0% - 20%'},
    {key: 'n_40_responses', label: '20% - 40%'},
    {key: 'n_60_responses', label: '40% - 60%'},
    {key: 'n_80_responses', label: '60% - 80%'},
    {key: 'n_100_responses', label: '80% - 100%'},
  ] as const;

  return keys.map(({key, label}, i) => ({
    name: label,
    type: 'bar',
    stack: 'total',
    emphasis: {
      focus: 'series',
    },
    data: props.data.map(({gradeDistribution}) => gradeDistribution[key]),
  }));
});

const {t} = useI18n();

const option = computed<ECOption>(() => ({
  tooltip: {
    position: 'left',
  },
  legend: {
    show: true,
    bottom: 0,
  },
  title: {
    text: t('chart.title'),
    left: '50%',
    textAlign: 'center',
  },
  grid: {
    top: '10%',
    left: '20%',
    bottom: '10%',
    right: '10%',
  },
  xAxis: {
    type: 'value',
    position: 'top',
    nameLocation: 'middle',
    name: t('chart.xAxis.name'),
    nameGap: 25,
  },
  yAxis: {
    type: 'category',
    data: props.data.map(({name}) => name),
    nameRotate: 0,
    inverse: true,
    splitArea: {
      show: true,
    },
    axisTick: {
      alignWithLabel: true,
    },
  },
  series: series.value,
}));
</script>

<style scoped>
.chart {
  width: 100%;
  aspect-ratio: 5 / 4;
  max-height: 100vh;
}
</style>
<i18n>
{
  "en": {
    "help": {
      "title": "Grade Distribution per Assignment",
      "intro": "This chart shows the number of grades that fall within a given range over the entire assignment, divided by the total number of students that have grades on the assignment. This acts as a measure of difficulty for the assignment as a whole.",
      "assignmentsWithManyResponses": "Assignments with a large number of responses on average would generally indicate that students struggled with the assignment.",
      "magnitudeOfTheCounts": "The magnitudes of the counts within grade ranges relative to one another can show you how skewed the responses are towards higher or lower grades. For example: a bar with many grades in the 60% to 80% range might indicate that students are understanding most of a concept, but struggling to fully grasp the final details required to get above 80%."
    },
    "chart": {
      "title": "Grade Distribution per Task",
      "xAxis": {
        "name": "Average Count of Grades per Student per Task"
      }
    }
  },
  "fr": {
    "help": {
      "title": "Ce graphique montre les commentaires les plus courants donnés par les algorithmes de notation.",
      "intro": "Les commentaires occupant de grandes portions du graphique en anneau indiquent qu'une grande partie des étudiants ont reçu ce commentaire, ce qui peut suggérer une erreur courante ou une source de confusion.",
      "assignmentsWithManyResponses": "Les devoirs avec un grand nombre de réponses en moyenne indiqueraient généralement que les étudiants ont eu du mal avec le devoir.",
      "magnitudeOfTheCounts": "Les magnitudes des comptes dans les plages de notes les unes par rapport aux autres peuvent vous montrer à quel point les réponses sont biaisées vers des notes plus élevées ou plus basses. Par exemple : une barre avec de nombreuses notes dans la plage de 60 % à 80 % pourrait indiquer que les étudiants comprennent la plupart d'un concept, mais ont du mal à saisir pleinement les détails finaux nécessaires pour dépasser 80 %."
    },
    "chart": {
      "title": "Distribution des notes par tâche",
      "xAxis": {
        "name": "Nombre moyen de notes par étudiant par tâche"
      }
    }
  }
}

</i18n>
