<template>
  <s-table>
    <template #header>
      <tr>
        <th>Header 1</th>
        <th>Header 2</th>
        <th>Header 3</th>
      </tr>
    </template>
    <template #body>
      <tr>
        <td>Row 1, Cell 1</td>
        <td>Row 1, Cell 2</td>
        <td>Row 1, Cell 3</td>
      </tr>
      <tr>
        <td>Row 2, Cell 1</td>
        <td>Row 2, Cell 2</td>
        <td>Row 2, Cell 3</td>
      </tr>
      <tr>
        <td>Row 3, Cell 1</td>
        <td>Row 3, Cell 2</td>
        <td>Row 3, Cell 3</td>
      </tr>
    </template>
  </s-table>
</template>
<script setup lang="ts">
import STable from '../../../design-system/STable.vue';
</script>
<script lang="ts">
import {ExampleOptions} from '../examples';

const description = `Tables are a great way to display data in a structured format.

Use tables to display data in a way that is easy to read and understand.

If the screen is too narrow to display the table, users will be able to scroll horizontally.
`;

export const options: ExampleOptions = {
  description,
  controls: [
    {
      type: 'select',
      prop: 'density',
      label: 'Density',
      options: ['normal', 'dense', 'denser'],
      default: 'normal',
    },
  ],
};
</script>
