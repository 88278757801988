import {EditorView} from 'prosemirror-view';

export const isHeading = (level: 1 | 2 | 3 | 4 | 5 | 6) => (state: EditorView['state']) => {
  const {$from} = state.selection;
  const headingType = state.schema.nodes.heading;

  if (!headingType) {
    return false; // No heading node in the schema
  }

  return $from.parent.type === headingType && $from.parent.attrs.level === level;
};
