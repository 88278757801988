import {NodeSpec} from 'prosemirror-model';

/**
 * An inline image (`<img>`) node. Supports `src`, `alt`, and `href`
 * attributes. The latter two default to the empty string.
 */
export const image = {
  inline: true,
  attrs: {
    src: {},
    alt: {default: null},
    title: {default: null},
    width: {default: null},
  },
  group: 'inline',
  draggable: true,
  parseDOM: [
    {
      tag: 'img[src]',
      getAttrs(dom: HTMLElement) {
        return {
          src: dom.getAttribute('src'),
          title: dom.getAttribute('title'),
          alt: dom.getAttribute('alt'),
          width: dom.getAttribute('width'),
        };
      },
    },
  ],
  toDOM(node) {
    let {src, alt, title, width} = node.attrs;
    return ['img', {src, alt, title, width}];
  },
} as NodeSpec;
