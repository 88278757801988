<template>
  <div class="" @drop.prevent="processFileDrop">
    <div
      v-if="$slots.default"
      class="flex items-center justify-between mb-4"
      :class="{'opacity-50 pointer-events-none': processing}"
    >
      <div>
        <s-badge v-if="error" color="red" class="animate-toast">
          <s-icon name="alert" size="18" class="opacity-70" />
          <span class="text-sm">{{ error }}</span>
        </s-badge>
      </div>
      <div class="relative flex items-center gap-2">
        <div v-if="processing" class="absolute inset-0 flex items-center justify-center">
          <s-icon name="loading" class="opacity-70 animate-spin text-blue-500" size="24" />
        </div>
        <p class="italic font-medium text-base text-gray-400 leading-none">
          {{ t('dragAndDropOr') }}
        </p>
        <label for="file-upload" class="btn md white">
          <s-icon name="upload" size="20" class="opacity-70" />
          <span>{{ t('selectFile') }}</span>
          <input
            ref="fileInputElement"
            id="file-upload"
            name="file-upload"
            type="file"
            class="sr-only"
            :accept="accept"
            :multiple="multiple"
            @change="processFileInputChange"
          />
        </label>
      </div>
    </div>
    <slot>
      <div
        class="relative flex justify-center rounded-lg bg-gray-200/30 border border-dashed border-gray-900/25 px-6 pt-11 pb-12 select-none"
      >
        <div class="flex flex-col items-center justify-center gap-4 text-center">
          <s-icon
            name="file-upload"
            size="64"
            class="transition-colors duration-150 ease-out"
            :class="fileNames.length ? 'text-blue-300' : 'text-gray-300'"
          />
          <template v-if="fileNames.length">
            <button v-for="filename in fileNames" class="btn md white" @click="clearInput">
              <s-icon name="file" size="18" class="opacity-70" />
              <span class="text-base">{{ filename }}</span>
              <s-icon name="close" size="16" class="opacity-70 text-gray-400" />
            </button>
          </template>
          <div
            v-else
            class="flex flex-col items-center justify-center gap-6 text-center"
            :class="{'opacity-50 pointer-events-none': processing}"
          >
            <p
              v-if="fileTypeMessage"
              class="flex items-center justify-center gap-1 text-xs font-bold text-gray-600"
            >
              <s-icon name="file" size="16" class="opacity-70" />
              {{ fileTypeMessage }}
            </p>
            <div class="flex items-center justify-center gap-2 leading-6">
              <label for="file-upload" class="btn md white">
                <s-icon name="upload" size="20" class="opacity-70" />
                <span>{{ t('selectFile') }}</span>
                <input
                  ref="fileInputElement"
                  id="file-upload"
                  name="file-upload"
                  type="file"
                  class="sr-only"
                  :accept="accept"
                  :multiple="multiple"
                  @change="processFileInputChange"
                />
              </label>
              <p class="italic font-medium text-base text-gray-400 leading-none">
                {{ t('dragAndDrop') }}
              </p>
            </div>
            <div v-if="error">
              <s-badge color="red" class="animate-toast">
                <s-icon name="alert" size="18" class="opacity-70" />
                <span class="text-sm">{{ error }}</span>
              </s-badge>
            </div>
          </div>
        </div>
        <div v-if="processing" class="absolute inset-0 flex items-center justify-center">
          <s-icon name="loading" class="opacity-70 animate-spin text-blue-500" size="24" />
        </div>
      </div>
    </slot>
  </div>
</template>

<script lang="ts" setup>
import {computed, defineEmits, defineProps, onMounted, onUnmounted, ref} from 'vue';
import {useVModel} from '@vueuse/core';
import SIcon from '../design-system/SIcon.vue';
import SBadge from '../design-system/SBadge.vue';
import {useI18n} from 'vue-i18n';
import {fileSize} from '../format/file-size';

const {t} = useI18n({useScope: 'local', inheritLocale: true});

const props = withDefaults(
  defineProps<{
    modelValue: File | File[] | null;
    fileTypeMessage?: string;
    accept: string;
    multiple: boolean;
    processing?: boolean;
    maxSize?: number;
  }>(),
  {modelValue: null, accept: '*', multiple: false}
);

const emits = defineEmits<{
  errorChange: [error: string];
  'update:modelValue': [file: File | File[] | null];
}>();

const error = ref<string | null>(null);
const fileInputElement = ref<HTMLInputElement | null>(null);
const modelValue = useVModel(props, 'modelValue');

const fileNames = computed(() => {
  if (!modelValue.value) {
    return [];
  }

  if (Array.isArray(modelValue.value)) {
    return modelValue.value.map((f) => f.name);
  }

  return [modelValue.value.name];
});

const targetEventNames = ['dragenter', 'dragover', 'dragleave', 'drop'];
const preventDefaults = (e: Event) => {
  e.preventDefault();
};

onMounted(() => {
  for (const event of targetEventNames) {
    document.body.addEventListener(event, preventDefaults);
  }
});

onUnmounted(() => {
  for (const event of targetEventNames) {
    document.body.removeEventListener(event, preventDefaults);
  }
});

const processFileInputChange = (e: Event) => {
  const target = e.target as HTMLInputElement;
  handleFileUpload(target.files);
};

const processFileDrop = (e: DragEvent) => {
  const files = e.dataTransfer?.files ?? null;

  if (!files || !fileInputElement?.value) {
    return;
  }

  fileInputElement.value.files = files;
  handleFileUpload(files);
};

const clearInput = (e: any) => {
  e.preventDefault();
  modelValue.value = null;
  if (fileInputElement.value) {
    fileInputElement.value.files = null;
  }

  emits('errorChange', '');
};

const handleFileUpload = (files: FileList | null) => {
  if (!files) {
    return;
  }

  const filesArray = Array.from(files);

  for (const file of filesArray) {
    if (props.maxSize && file.size > props.maxSize) {
      const errorMessage = `File of size ${fileSize(file.size)} exceeds the limit of ${fileSize(props.maxSize)}`;
      emits('errorChange', errorMessage);
      error.value = errorMessage;
      return false;
    }
  }

  modelValue.value = props.multiple ? filesArray : filesArray[0];
  emits('errorChange', '');
  error.value = null;
};
</script>
<i18n>
{
  "en": {
    "selectFile": "Select a file",
    "dragAndDrop": "or drag and drop",
    "dragAndDropOr": "Drag and drop or"
  },
  "fr": {
    "selectFile": "Sélectionner un fichier",
    "dragAndDrop": "ou glisser-déposer",
    "dragAndDropOr": "Glisser-déposer ou"
  }
}
</i18n>
