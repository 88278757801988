<template>
  <div class="flex flex-wrap gap-4">
    <s-input-field
      type="number"
      step="0.0000001"
      min="0"
      v-model="inputTokenCost"
      id="inputCost"
      label="Input Token Cost:"
    />
    <s-input-field
      type="number"
      step="0.0000001"
      min="0"
      v-model="outputTokenCost"
      id="outputCost"
      label="Output Token Cost:"
    />
  </div>
  <v-chart class="chart" :option="option" autoresize />
</template>

<script setup lang="ts">
import {computed, ComputedRef, ref} from 'vue';
import type {ComposeOption} from 'echarts';
import {use} from 'echarts/core';
import {ScatterChart, ScatterSeriesOption} from 'echarts/charts';
import {CanvasRenderer} from 'echarts/renderers';
import VChart from 'vue-echarts';

import {
  GridComponent,
  GridComponentOption,
  LegendComponent,
  LegendComponentOption,
  TitleComponent,
  TitleComponentOption,
  TooltipComponent,
  TooltipComponentOption,
} from 'echarts/components';
import {monthDaySometimesYear} from '../../../../format/dates';
import SInputField from '../../../../design-system/SInputField.vue';

use([
  LegendComponent,
  TitleComponent,
  ScatterChart,
  CanvasRenderer,
  TooltipComponent,
  GridComponent,
]);

type ECOption = ComposeOption<
  | ScatterSeriesOption
  | TitleComponentOption
  | TooltipComponentOption
  | LegendComponentOption
  | GridComponentOption
>;

const props = defineProps<{
  courseTokenUsage: Array<{
    courseId: number;
    courseName: string;
    courseCode: string;
    startDate: any;
    endDate: any;
    institutionId: number;
    institutionName: string;
    institutionCode: string;
    inputTokens: number;
    outputTokens: number;
    totalTokens: number;
    totalStudents: number;
  }>;
}>();

const inputTokenCost = ref(0.0000025);
const outputTokenCost = ref(0.00001);

function calculateCostPerStudent(inputTokens: number, outputTokens: number, totalStudents: number) {
  const inputCost = inputTokens * inputTokenCost.value;
  const outputCost = outputTokens * outputTokenCost.value;
  const totalCost = inputCost + outputCost;
  return totalCost / totalStudents;
}

const colorPalette = [
  '#6200ea',
  '#ff0266',
  '#00c853',
  '#03dac6',
  '#ff5722',
  '#ffeb3b',
  '#9c27b0',
  '#1b9e77',
  '#03a9f4',
  '#d95f02',
  '#7570b3',
  '#3700b3',
  '#e7298a',
  '#66a61e',
  '#e6ab02',
];

interface GroupedByInstitution {
  [key: number]: {
    institutionName: string;
    courses: typeof props.courseTokenUsage;
  };
}

const groupedByInstitution: ComputedRef<GroupedByInstitution> = computed(() => {
  const grouped: GroupedByInstitution = {};

  props.courseTokenUsage.forEach((item) => {
    if (!grouped[item.institutionId]) {
      grouped[item.institutionId] = {
        institutionName: item.institutionName,
        courses: [],
      };
    }
    grouped[item.institutionId].courses.push(item);
  });

  return grouped;
});

const seriesData: ComputedRef<ScatterSeriesOption[]> = computed(() => {
  return Object.keys(groupedByInstitution.value).map((institutionId, index) => {
    const institution = groupedByInstitution.value[Number(institutionId)];
    const color = colorPalette[index % colorPalette.length];

    return {
      name: institution.institutionName,
      type: 'scatter',
      data: institution.courses.map((course) => [
        course.totalStudents, // x-axis: number of students
        calculateCostPerStudent(course.inputTokens, course.outputTokens, course.totalStudents), // y-axis: tokens per student
        Math.sqrt(course.totalTokens) / 50, // bubble size: scaled by total tokens
        course.courseName, // label: course name
        course.courseCode,
        course.startDate,
        course.endDate,
      ]),
      symbolSize: (data: [number, number, number, string]) => data[2],
      emphasis: {
        focus: 'series',
        label: {
          show: true,
          formatter: function (param: any) {
            const [
              totalStudents,
              costPerStudent,
              bubbleSize,
              courseName,
              courseCode,
              startDate,
              endDate,
            ] = param.data;
            return courseCode;
          },
          position: 'top',
        },
      },
      itemStyle: {
        shadowBlur: 10,
        shadowColor: 'rgba(25, 100, 150, 0.5)',
        shadowOffsetY: 5,
        color,
      },
    } as ScatterSeriesOption;
  });
});

const option: ComputedRef<ECOption> = computed(() => ({
  title: {
    text: 'Generative Ai Cost Per Student by Course',
    left: '5%',
    top: '3%',
  },
  legend: {
    type: 'scroll',
    orient: 'vertical',
    right: 10,
    top: 20,
    bottom: 20,
    data: Object.values(groupedByInstitution.value).map(
      (institution) => institution.institutionName
    ),
  },
  grid: {
    left: '8%',
    right: '20%',
    top: '10%',
  },
  xAxis: {
    name: 'Number of Students',
    splitLine: {
      lineStyle: {
        type: 'dashed',
      },
    },
  },
  yAxis: {
    name: 'Cost per Student (USD)',
    axisLabel: {
      formatter: '$ {value}',
    },
    splitLine: {
      lineStyle: {
        type: 'dashed',
      },
    },
    scale: true,
  },
  tooltip: {
    trigger: 'item',
    formatter: function (param: any) {
      const [
        totalStudents,
        costPerStudent,
        bubbleSize,
        courseName,
        courseCode,
        startDate,
        endDate,
      ] = param.data;
      return `Course: ${courseName}<br>Start Date: ${monthDaySometimesYear(startDate, {shortMonth: true})}<br>End Date: ${monthDaySometimesYear(endDate, {shortMonth: true})}`;
    },
  },
  series: seriesData.value,
}));
</script>

<style scoped>
.chart {
  width: 100%;
  aspect-ratio: 1;
  max-height: 100vh;
}
</style>
