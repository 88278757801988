<template>
  <s-field :id :label :description :error :required>
    <template v-slot="a11yProps">
      <s-multi-select
        :options="options"
        :value-key="valueKey"
        :label-key="labelKey"
        :disableOption="disableOption"
        :emptyLabel="emptyLabel"
        :max="max"
        v-model="model"
        @confirm:selection="$emit('confirm:selection', $event)"
        v-bind="{...$attrs, ...a11yProps}"
      >
        <template v-if="$slots.selected" #selected="slotProps">
          <slot name="selected" v-bind="slotProps"></slot>
        </template>
        <template v-if="$slots.option" #option="slotProps">
          <slot name="option" v-bind="slotProps"></slot>
        </template>
      </s-multi-select>
    </template>
  </s-field>
</template>
<script setup lang="ts">
import SField from './SField.vue';
import SMultiSelect from './SMultiSelect.vue';

const model = defineModel<any[]>({default: []});

defineProps<{
  id: string;
  options: any[];
  valueKey?: string;
  labelKey?: string;
  disableOption?: (option: any) => boolean;
  emptyLabel: string;
  label?: string;
  description?: string;
  tooltip?: string;
  error?: string | null;
  required?: boolean;
  max?: number;
}>();

defineEmits(['confirm:selection']);
</script>
<script lang="ts">
export default {
  inheritAttrs: false,
};
</script>
