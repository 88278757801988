<template>
  <section>
    <s-file-upload @update:modelValue="uploadFile" :processing="uploading" :max-size="1_000_000">
      <template v-if="files.length > 0" #default>
        <ul class="flex flex-col rounded-lg shadow-soft">
          <li
            v-for="(file, index) in files"
            :key="file.id || file.temp?.originalFilename || index"
            class="p-2 border first:rounded-t-lg last:rounded-b-lg [&:not(:first-child)]:border-t-0 border-gray-200 text-gray-700"
            :class="{
              'bg-gradient-to-l from-red-50 via-transparent to-transparent': file.deleted,
              'bg-gradient-to-l from-green-50 via-transparent to-transparent': file.temp,
            }"
          >
            <template v-if="file.id">
              <div class="flex gap-3 items-center w-full">
                <div v-if="taskId" class="w-20 h-20 rounded overflow-hidden flex shadow-card">
                  <img
                    :src="`/tasks/${taskId}/files/${file.id}`"
                    class="w-full h-full object-cover"
                  />
                </div>
                <div class="flex flex-col flex-1 w-full">
                  <p class="text-base">{{ file.name }}</p>
                  <p class="font-medium text-sm">{{ fileSize(file.size) }}</p>
                </div>
                <s-badge v-if="file.deleted" color="red">{{ t('deleted') }}</s-badge>
                <s-btn
                  v-if="!file.deleted"
                  @click.prevent="file.deleted = true"
                  type="button"
                  icon="close"
                  color="ghost"
                  :aria-label="t('actions.delete')"
                />
                <s-btn
                  v-else
                  @click.prevent="file.deleted = false"
                  type="button"
                  icon="restore"
                  color="ghost"
                  :aria-label="t('actions.restore')"
                />
              </div>
            </template>
            <template v-else-if="file.temp">
              <div class="flex gap-3 items-center w-full">
                <div class="w-20 h-20 rounded overflow-hidden flex shadow-card">
                  <img :src="generateTempFileUrl(file.temp)" class="w-full h-full object-cover" />
                </div>
                <div class="flex flex-col flex-1 w-full">
                  <p class="text-base">{{ file.temp.originalFilename }}</p>
                  <p class="font-medium text-sm">{{ fileSize(file.temp.size) }}</p>
                </div>
                <s-badge color="green">{{ t('new') }}</s-badge>
                <s-btn
                  @click.prevent="removeNewFile(file)"
                  type="button"
                  icon="close"
                  color="ghost"
                  :aria-label="t('actions.delete')"
                />
              </div>
            </template>
          </li>
        </ul>
      </template>
    </s-file-upload>
  </section>
</template>
<script setup lang="ts">
import {ref} from 'vue';
import Vapor from 'laravel-vapor';
import TaskFileUploadDto = App.DTOs.Tasks.TaskFileUploadDto;
import {useI18n} from 'vue-i18n';
import SBadge from '../../design-system/SBadge.vue';
import SBtn from '../../design-system/SBtn.vue';
import SFileUpload from '../../components/SFileUpload.vue';
import {fileSize} from '../../format/file-size';

const files = defineModel<TaskFileUploadDto[]>({default: []});

const {taskId} = defineProps<{taskId?: number | null}>();

const {t} = useI18n({
  inheritLocale: true,
  scope: 'local',
});

const tempFiles = ref<File[]>([]);
const uploading = ref(false);

const uploadFile = async (file: File | File[] | null) => {
  if (!file || Array.isArray(file)) {
    return;
  }

  uploading.value = true;

  const vaporReference = await Vapor.store(file);

  const newFileData: TaskFileUploadDto = {
    id: null,
    name: file.name,
    size: file.size,
    mimetype: file.type,
    deleted: false,
    temp: {
      ...vaporReference,
      originalFilename: file.name,
      size: file.size,
      mimeType: file.type,
    } as TaskFileUploadDto['temp'],
  };

  files.value.push(newFileData);
  tempFiles.value.push(file);
  uploading.value = false;
};

const getBrowserFileFromTemp = (tempFile: TaskFileUploadDto['temp']) => {
  return tempFiles.value.find((file) => file.name === tempFile!.originalFilename);
};
const generateTempFileUrl = (file: TaskFileUploadDto['temp']) => {
  const tempFile = getBrowserFileFromTemp(file);

  return tempFile ? URL.createObjectURL(tempFile) : '';
};

const removeNewFile = (file: TaskFileUploadDto) => {
  let indexOfFile = files.value.indexOf(file);

  if (indexOfFile >= 0) {
    files.value.splice(indexOfFile, 1);
  }

  let browserFile = getBrowserFileFromTemp(file.temp);
  let indexOfBrowserFile = browserFile ? tempFiles.value.indexOf(browserFile) : -1;
  if (indexOfBrowserFile >= 0) {
    tempFiles.value.splice(indexOfBrowserFile, 1);
  }
};
</script>
<i18n>
{
  "en": {
  },
  "fr": {
  }
}
</i18n>
