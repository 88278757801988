<template>
  <Head :title="`Admin - Tasks Analytics`"></Head>
  <div class="page-block">
    <s-page-header heading="Task Analytics">
      <template v-slot:actions>
        <div class="flex -translate-y-2">
          <s-link
            class="btn !rounded-r-none"
            :href="route('admin.tasks.analytics', {groupBy: 'month'})"
            :class="{
              'disabled green-light !filter-none !text-green-600 !opacity-100':
                selectedGroupBy === 'month',
              'white !border-r-0': selectedGroupBy !== 'month',
            }"
          >
            <s-icon v-if="selectedGroupBy === 'month'" name="check" class="opacity-70" size="16" />
            <span v-if="selectedGroupBy === 'month'">Grouped by Month</span>
            <span v-else>Group by Month</span>
          </s-link>
          <s-link
            class="btn !rounded-l-none"
            :href="route('admin.tasks.analytics', {groupBy: 'week'})"
            :class="{
              'disabled green-light !filter-none !text-green-600 !opacity-100':
                selectedGroupBy === 'week',
              'white !border-l-0': selectedGroupBy !== 'week',
            }"
          >
            <s-icon v-if="selectedGroupBy === 'week'" name="check" class="opacity-70" size="16" />
            <span v-if="selectedGroupBy === 'week'">Grouped by Week</span>
            <span v-else>Group by Week</span>
          </s-link>
        </div>
      </template>
    </s-page-header>

    <Bar id="my-chart-id" :options="chartOptions" :data="chartData" />

    <s-stats-section class="mt-2" heading="All Tasks" :stats="[{label: 'Total', value: total}]" />
    <s-stats-section
      class="mt-2"
      heading="Generic vs School Specific Tasks"
      :stats="[
        {label: 'Generic', value: total - scoped},
        {label: 'School Specific', value: scoped},
      ]"
    />
    <s-stats-section
      class="mt-2"
      heading="In-Code vs In-App"
      :stats="[
        {label: 'In Code', value: inCode},
        {label: 'In App', value: total - inCode},
      ]"
    />
  </div>
</template>
<script setup lang="ts">
import SPageHeader from '../../../design-system/SPageHeader.vue';
import {Bar} from 'vue-chartjs';
import {
  Chart as ChartJS,
  Title,
  Tooltip,
  Legend,
  BarElement,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  BubbleController,
} from 'chart.js';
import {route} from 'ziggy-js';
import {computed} from 'vue';
import SLink from '../../../components/SLink.vue';
import {Head} from '@inertiajs/vue3';
import SStatsSection from '../../../design-system/SStatsSection.vue';
import {useBreadcrumbs} from '../../../composables/useBreadcrumbs';
import SIcon from '../../../design-system/SIcon.vue';

ChartJS.register(
  Title,
  Tooltip,
  Legend,
  BarElement,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  BubbleController
);

type TaskTimeFrame = {
  year: string;
  month: string;
  week: string;
  count: number;
  count_created_in_app: number;
  count_created_in_code: number;
};

const props = defineProps<{
  data: TaskTimeFrame[];
  total: number;
  scoped: number;
  inCode: number;
}>();

useBreadcrumbs([{label: 'Tasks', href: route('admin.tasks.index')}]);

const chartData = computed<any>(() => {
  let labelFn = (d: TaskTimeFrame) => `${d.year}-${d.month}`;
  //@ts-ignore
  if (route().params.groupBy === 'week') {
    labelFn = (d: TaskTimeFrame) => `${d.year}-${d.month}-${d.week}`;
  }

  return {
    labels: props.data.map(labelFn),
    datasets: [
      {
        label: 'Created in Code',
        backgroundColor: '#003f5c',
        data: props.data.map((d) => d.count_created_in_code),
        stack: 'combined',
        type: 'bar',
      },
      {
        label: 'Created in App',
        backgroundColor: '#bc5090',
        data: props.data.map((d) => d.count_created_in_app),
        stack: 'combined',
        type: 'bar',
      },
      {
        type: 'bubble',
        label: 'Total',
        backgroundColor: '#000',
        data: props.data.map((d) => d.count),
        fill: false,
        borderColor: '#000',
        pointRadius: 5,
      },
    ],
  };
});

const chartOptions = {
  responsive: true,
  scales: {
    y: {
      stacked: true,
    },
  },
  plugins: {
    tooltip: {
      callbacks: {
        label(context: any) {
          const total = props.data[context.dataIndex].count;

          if (context.type === 'bubble') {
            return `Total: ${total}`;
          }

          let percent = ((context.parsed.y / total) * 100).toFixed(0);

          return `${context.dataset.label}: ${context.parsed.y} ${percent}%`;
        },
      },
    },
  },
};
//@ts-ignore
const selectedGroupBy = computed(() => (route().params.groupBy === 'week' ? 'week' : 'month'));
</script>
