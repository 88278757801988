<template>
  <Head title="Admin - Task Usage History"></Head>
  <s-page-block size="md">
    <s-page-header heading="Assignment Task History" class="pb-4">
      <template #actions>
        <s-btn
          v-if="assignment.id"
          class="flex-1 max-w-[14rem]"
          color="blue-light"
          icon="book-edit"
          :href="`/courses/${assignment.course.id}/assignments/${assignment.id}`"
        >
          Assignment: {{ assignment.name }}
        </s-btn>
        <s-btn
          v-if="task.id"
          class="flex-1 max-w-[14rem]"
          color="blue-light"
          icon="eye"
          :href="route('admin.tasks.show', {task: task.id})"
        >
          Task Preview
        </s-btn>
      </template>
    </s-page-header>
    <section class="mt-4">
      <div class="card padded mt-8">
        <task-grade-averages-multiple-step-line-chart
          v-if="rollingAverages.length > 0"
          :rolling-averages="rollingAverages"
        />
        <span v-else>No Grading Data Exists for this Task</span>
      </div>
      <div class="card padded mt-8">
        <task-learning-rate-charts
          v-if="gradesByAttempts"
          :grades-by-attempts="gradesByAttempts"
          :task="task"
          :assignment="assignment"
        />
        <span v-else>No Learning Rate Exists for this Task</span>
      </div>
      <div class="card padded mt-8">
        <task-token-usage-history-line-chart
          v-if="tokenUsage.length > 0"
          :token-usage="tokenUsage"
        />
        <span v-else>No Token Usage Tracking Exists for this Task</span>
      </div>
    </section>
  </s-page-block>
</template>

<script setup lang="ts">
import {route} from 'ziggy-js';
import {Head} from '@inertiajs/vue3';
import {useBreadcrumbs} from '../../../../composables/useBreadcrumbs';
import TaskMistakeLabelsVisualizationScatterChart from '../Task/TaskMistakeLabelsVisualizationScatterChart.vue';
import {Task} from '../../../../types/entities/tasks';
import {Ref, ref} from 'vue';
import axios from 'axios';
import SBtn from '../../../../design-system/SBtn.vue';
import {Assignment} from '../../../../types/entities/assignment';
import SStatCard from '../../../../components/SStatCard.vue';
import TaskTokenUsageHistoryLineChart from '../Task/TaskAssignmentTokenUsageHistoryLineChart.vue';
import TaskGradeAveragesMultipleStepLineChart from '../Task/TaskGradeAveragesMultipleStepLineChart.vue';
import SLink from '../../../../components/SLink.vue';
import TaskLearningRateCharts from '../Task/TaskLearningRateCharts.vue';
import SInputField from '../../../../design-system/SInputField.vue';
import InstitutionTokenUsageHistoryBubbleChart from '../Institution/InstitutionTokenUsageHistoryBubbleChart.vue';
import SPageBlock from '../../../../design-system/SPageBlock.vue';
import SPageHeader from '../../../../design-system/SPageHeader.vue';

const props = defineProps<{
  task: Task;
  assignment: Assignment;
  mistakeLabelCount: number;
  tokenUsage: {
    day: string;
    totalTokens: number;
    totalImageTokens: number;
    avgInputTokensPerAPIRequest: number;
    avgOutputTokensPerAPIRequest: number;
    avgInputTokensPerImageAPIRequest: number;
    avgOutputTokensPerImageAPIRequest: number;
  }[];
  rollingAverages: {
    day: string;
    new_grades: number;
    avg_final_grade: number;
    avg_accepted_grade: number;
    avg_grade_pre_override: number;
    avg_override_grade_difference: number;
    pie_data: {name: string; value: number}[];
  }[];
  gradesByAttempts: {
    [maxAttempts: string]: {
      example_user_id: number;
      number_of_students: number;
      final_avg_grade: number;
      attempt_data: {
        attempt_number: number;
        avg_grade: number;
      }[];
    };
  };
}>();

const numberOfClusters = ref(5);
const fetchingClusters = ref(false);
const clusterData: Ref<
  {
    title: string;
    count: number;
    points?: {
      embedding: number[];
      mistake_label: string;
    }[];
  }[]
> = ref([]);

async function calculateMistakeLabelClusters() {
  fetchingClusters.value = true;
  try {
    const response = await axios.post(
      route('admin.ai-analytics.tasks.assignment.cluster', {
        task: props.task.id,
        assignment: props.assignment.id,
        numberOfClusters: numberOfClusters.value,
      })
    );
    if (!response.data) {
      return;
    }
    clusterData.value = response.data;
  } finally {
    fetchingClusters.value = false;
  }
}

useBreadcrumbs([
  {label: 'Ai Analytics', href: route('admin.ai-analytics.index')},
  {label: 'Tasks', href: route('admin.ai-analytics.tasks.index')},
  {label: props.task.title, href: route('admin.ai-analytics.tasks.show', {task: props.task.id})},
]);
</script>
