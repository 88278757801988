<template>
  <Head title="Admin - Ai Grading"></Head>
  <div class="page-block">
    <s-page-header heading="Ai Grading">
      <template #actions>
        <s-btn icon="clipboard" color="secondary" :href="route('admin.ai-analytics.tasks.index')">
          Tasks
        </s-btn>
        <s-btn icon="school" color="secondary" :href="route('admin.ai-analytics.courses.index')">
          Active Courses
        </s-btn>
        <s-btn
          icon="school"
          color="secondary"
          :href="route('admin.ai-analytics.institutions.index', 1)"
        >
          Institutions
        </s-btn>
        <s-btn
          icon="star-four-points"
          color="secondary"
          :href="route('admin.ai-analytics.models.index')"
        >
          Models
        </s-btn>
      </template>
    </s-page-header>
  </div>
</template>
<script setup lang="ts">
import SPageHeader from '../../../design-system/SPageHeader.vue';
import {route} from 'ziggy-js';
import {Head} from '@inertiajs/vue3';
import {useBreadcrumbs} from '../../../composables/useBreadcrumbs';
import SBtn from '../../../design-system/SBtn.vue';

defineProps<{}>();

useBreadcrumbs([{label: 'Admin', href: route('admin.index')}]);
</script>

<style scoped></style>
