<template>
  <Head :title="t('page.title', paymentRequest.course.code)"></Head>
  <div class="page-block max-w-screen-md gap-5">
    <s-page-header :heading="paymentRequest.course.name"></s-page-header>
    <div class="flex justify-start gap-6">
      <s-label-value :label="t('label.paymentRequestId')">
        {{ paymentRequest.id }}
      </s-label-value>

      <payment-request-due-date :payment-request="paymentRequest" />
    </div>
    <p class="text-xl leading-tight flex items-center gap-1.5 text-green-700 mb-2">
      <s-icon name="check-decagram" class="opacity-70" width="24" height="24" />
      {{ t('success') }}
    </p>
    <s-link :href="route('billing.index')" class="btn lg white w-fit">
      <s-icon name="keyboard-return" class="opacity-70" width="18" height="18" />
      {{ t('action.back') }}
    </s-link>
  </div>
</template>

<script setup lang="ts">
import SLink from '../../components/SLink.vue';
import SPageHeader from '../../design-system/SPageHeader.vue';
import SLabelValue from '../../design-system/SLabelValue.vue';
import {PaymentRequest} from '../../types/entities/payment-request';
import {route} from 'ziggy-js';
import {Head} from '@inertiajs/vue3';
import {useBreadcrumbs} from '../../composables/useBreadcrumbs';
import SIcon from '../../design-system/SIcon.vue';
import PaymentRequestDueDate from './components/PaymentRequestDueDate.vue';
import {useI18n} from 'vue-i18n';

defineProps<{
  paymentRequest: PaymentRequest;
}>();

const {t} = useI18n({
  inheritLocale: true,
  useScope: 'local',
});

useBreadcrumbs([{label: t('billing'), href: route('billing.index')}]);
</script>
<i18n>
{
  "en": {
    "page": {
      "title": "Payment Successful - {code} - Billing"
    },
    "label": {
      "paymentRequestId": "Payment Request ID"
    },
    "action": {
      "back": "Return to Billing"
    },
    "success": "Payment made successfully."
  },
  "fr": {
    "page": {
      "title": "Paiement réussi - {code} - Facturation"
    },
    "label": {
      "paymentRequestId": "ID de demande de paiement"
    },
    "action": {
      "back": "Retour à la facturation"
    },
    "success": "Paiement effectué avec succès."
  }
}
</i18n>
