<template>
  <s-select-field
    id="fileUploadId"
    label="Image"
    description="Images uploaded and saved in the media manager will display here"
    :model-value="fileUploadId"
    @update:model-value="emit('update:fileUploadId', $event)"
    :error="errors?.fileUploadId"
  >
    <option
      v-for="file in files.filter((file: TaskFileUploadDto) => isImageMimeType(file.mimetype))"
      :value="file.id"
      :disabled="!!(file.temp || file.deleted)"
    >
      {{ file.temp ? file.temp.originalFilename : file.name }}

      <template v-if="file.temp">(Unsaved. Can't use until you save the task.)</template>
    </option>
  </s-select-field>

  <s-input-field
    id="alt"
    label="Alt"
    description="Describe the image to ensure accessibility"
    :error="errors?.alt"
    :model-value="alt"
    @update:model-value="emit('update:alt', $event)"
  />

  <s-input-field
    id="widtb"
    label="Width"
    description="Desired image width"
    :error="errors?.width"
    :model-value="width"
    @update:model-value="emit('update:width', $event)"
  />

  <s-field v-if="taskId" label="Preview" id="preview">
    <div class="flex">
      <img
        :src="
          route('tasks.files.show', {
            task: taskId,
            fileUpload: fileUploadId,
          })
        "
        :alt="alt"
        :width="width"
        class="max-w-full h-auto"
      />
    </div>
  </s-field>
  <div v-else>Can only preview on a saved task.</div>
</template>

<script setup lang="ts">
import SSelectField from '../../../../design-system/SSelectField.vue';
import SInputField from '../../../../design-system/SInputField.vue';
import SField from '../../../../design-system/SField.vue';
import {route} from 'ziggy-js';
import {isImageMimeType} from '../../../../util/images';
import TaskFileUploadDto = App.DTOs.Tasks.TaskFileUploadDto;

const {width = 'auto'} = defineProps<{
  taskId: number;
  files: TaskFileUploadDto[];
  fileUploadId: number;
  alt: string;
  width?: string;
  errors?: Record<string, any>;
}>();

const emit = defineEmits<{
  (evt: 'update:fileUploadId', fileUploadId: number): void;
  (evt: 'update:alt', text: string): void;
  (evt: 'update:width', width: string): void;
}>();
</script>
