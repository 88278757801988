<template>
  <Head :title="t('page.title', {assignment: assignment.name})"></Head>
  <edit-assignment-header
    :course="course"
    :assignment="assignment"
    :can="{
      update: can.updateAssignment,
    }"
  />
  <form @submit.prevent="updateAssignmentTasks()" class="flex-1 flex flex-col">
    <s-page-block size="lg" class="flex-1">
      <div class="flex flex-wrap gap-3 items-end justify-between mb-2">
        <s-badge-group>
          <s-badge>
            <s-icon name="clipboard-edit-outline" size="18" class="opacity-70" />
            <strong class="!font-black">{{ form.tasksUpdates.length }}</strong>
            {{ t('task', form.tasksUpdates.length) }}
          </s-badge>
          <s-badge>
            <s-icon name="trophy-outline" size="18" class="opacity-70" />
            <strong class="!font-black">
              {{ assignmentTotalPoints }}
            </strong>
            {{ t('form.totalPoints', assignmentTotalPoints) }}
          </s-badge>
        </s-badge-group>
        <div class="flex flex-wrap flex-grow justify-end gap-x-2 gap-y-3">
          <s-btn
            type="button"
            @click="
              tryToLeave(
                route('web.courses.assignments.tasks.first', {
                  course: route().params.course,
                  assignment: props.assignment.id,
                })
              )
            "
            color="white"
            icon="magnify"
          >
            {{ t('actions.preview') }}
          </s-btn>
          <s-btn type="button" @click="openTaskModal()" color="blue-light" icon="plus">
            {{ t('actions.addTasks') }}
          </s-btn>
        </div>
      </div>
      <div
        class="w-[calc(100%_+_2.5rem)] lg:w-[calc(100%_+_3.5rem)] overflow-auto -my-3 -mx-5 lg:-mx-7 py-3 px-5 lg:px-7"
      >
        <table class="table dense min-w-full max-w-full h-px">
          <thead class="">
            <tr>
              <th class="text-left whitespace-nowrap">
                {{ t('table.order') }}
              </th>
              <th class="text-left whitespace-nowrap">
                {{ t('table.title') }}
              </th>
              <th v-if="assignment.reviewRequired" class="text-left !pr-5">
                <s-tooltip>
                  <template #tooltip>
                    {{ t('table.reviewTooltip') }}
                  </template>
                  <span class="flex items-center gap-1">
                    {{ t('table.review') }}
                    <s-icon name="help-circle" size="18" class="opacity-70 text-blue-400" />
                  </span>
                </s-tooltip>
              </th>
              <th class="text-left">
                {{ t('table.type') }}
              </th>
              <th class="text-left">
                {{ t('table.points') }}
              </th>
              <th class="!px-0">
                {{ t('table.preview') }}
              </th>
              <th></th>
            </tr>
          </thead>
          <tbody ref="listWrapperRef" class="rounded-lg shadow">
            <tr v-if="form.tasksUpdates?.length < 1">
              <td colspan="7" class="!text-center !py-3 !bg-gray-50 italic text-gray-400">
                {{ t('page.noTasks') }}
              </td>
            </tr>
            <tr v-for="(taskAssignment, index) in form.tasksUpdates" :key="taskAssignment.task.id">
              <td class="w-0 !pl-2">
                <button
                  type="button"
                  class="handle text-gray-600 hover:text-blue-500 flex-none flex items-center justify-start gap-1 cursor-grab group/handle py-1 pl-1 pr-2 transition-all ease-out duration-150"
                >
                  <span
                    class="flex-none opacity-30 group-hover/handle:opacity-100 grid grid-cols-1 grid-rows-1"
                  >
                    <span
                      class="transition-all ease-out duration-150 group-hover/handle:opacity-0 row-start-1 col-start-1 grid grid-cols-1 grid-rows-1"
                    >
                      <s-icon
                        name="dots-vertical"
                        size="20"
                        class="-translate-x-[0.1875rem] row-start-1 col-start-1"
                      />
                      <s-icon
                        name="dots-vertical"
                        size="20"
                        class="translate-x-[0.1875rem] row-start-1 col-start-1"
                      />
                    </span>
                    <s-icon
                      name="unfold-more-horizontal"
                      size="20"
                      class="text-blue-500 row-start-1 col-start-1 transition-all ease-out duration-150 opacity-0 group-hover/handle:opacity-100"
                    />
                  </span>
                  <span class="font-medium">{{ index + 1 }}</span>
                </button>
              </td>
              <td class="text-left min-w-56">
                <s-latex :content="taskAssignment.task.title" />
              </td>
              <td v-if="assignment.reviewRequired" class="h-full w-0">
                <span class="flex items-center justify-center">
                  <s-checkbox
                    :id="`review-${taskAssignment.task.id}`"
                    v-model="taskAssignment.reviewRequired"
                    color="green"
                  />
                </span>
              </td>
              <td class="text-left max-w-44">
                <display-task-types :taskTypes="taskAssignment.task.taskTypes">
                  <task-badge
                    v-if="taskAssignment.task.scoped"
                    color="blue-light"
                    :label="t('customMadeTask')"
                    icon="flask-outline"
                  />
                  <task-badge
                    v-if="taskAssignment.task.isAiGraded"
                    color="green"
                    :label="t('aiGraded')"
                    icon="auto-fix"
                  />
                </display-task-types>
              </td>
              <td class="text-left w-0">
                <s-inline-input-field
                  id="pointsInput"
                  v-model.number="taskAssignment.pointValue"
                  size="sm"
                  class="w-20"
                  type="number"
                  min="0"
                  :error="
                    (form.errors as Partial<Record<string, string>>)[
                      `tasksUpdates.${index}.pointValue`
                    ]
                  "
                ></s-inline-input-field>
              </td>
              <td class="text-left h-full w-0 !p-0 align-stretch">
                <button
                  id="preview"
                  type="button"
                  @click.prevent="openTaskPreview(taskAssignment.task, taskAssignment)"
                  class="inline-table h-full w-full py-3 px-3.5 text-blue-500/70 hover:text-blue-500 hover:bg-gray-300/15 transition border-l border-gray-100"
                >
                  <span class="flex items-center justify-center">
                    <s-icon
                      :name="taskToPreview === taskAssignment.task ? 'close' : 'magnify'"
                      size="20"
                    />
                  </span>
                  <span class="sr-only">
                    {{
                      taskToPreview === taskAssignment.task
                        ? t('table.closePreview')
                        : t('table.openPreview')
                    }}
                  </span>
                </button>
              </td>

              <td class="text-left h-full w-0 !p-0 align-stretch">
                <button
                  id="delete"
                  type="button"
                  @click.prevent="tryToRemoveTask(taskAssignment)"
                  class="inline-table h-full w-full py-3 pl-3.5 pr-4 text-red-500/60 hover:text-red-500 hover:bg-gray-300/15 transition border-l border-gray-100"
                  :aria-label="t('form.removeTask', {task: taskAssignment.task.title})"
                >
                  <s-icon name="trash-can-outline" size="20" />
                </button>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </s-page-block>
    <s-form-footer
      :cancelRoute="
        route('courses.assignments.show', {
          course: course.id,
          assignment: assignment.id,
        })
      "
      :formProcessing="form.processing"
      :formIsDirty="form.isDirty"
    />
  </form>
  <create-task-assignment-drawer
    v-model:visible="showTaskModal"
    :form="form"
    :assignment-tasks="assignmentTasks"
    :types="types"
    :assignment="assignment"
  />

  <preview-task-modal :task="taskToPreview" @close="taskToPreview = null">
    <div class="grid grid-cols-2">
      <s-btn
        type="button"
        :disabled="!hasPreviousTask"
        @click="previousTask()"
        color="blue-light"
        icon="chevron-left"
        class="!rounded-r-none"
      >
        {{ t('actions.previous') }}
      </s-btn>
      <s-btn
        type="button"
        :disabled="!hasNextTask"
        @click="nextTask()"
        color="blue-light"
        icon="chevron-right"
        class="!rounded-l-none !border-l-0"
        iconEnd
      >
        {{ t('actions.next') }}
      </s-btn>
    </div>
    <s-btn
      v-if="taskAssignmentToPreview"
      type="button"
      @click="tryToRemoveTask(taskAssignmentToPreview)"
      color="red"
      icon="trash-can"
    >
      {{ t('actions.remove') }}
    </s-btn>
  </preview-task-modal>

  <s-modal
    v-if="taskToTryAndRemove"
    v-model:open="showRemoveConfirmationModal"
    :title="t('form.removeConfirmationTitle')"
    :confirm="{
      label: t('actions.remove'),
      icon: 'trash-can',
      color: 'red',
    }"
    @confirm="tryToRemoveTask(taskToTryAndRemove)"
    @close="showRemoveConfirmationModal = false"
    cancellable
  >
    <template #content>
      <p>
        <i18n-t keypath="form.removeConfirmation">
          <template v-slot:task>
            <strong class="italic">{{ taskToTryAndRemove.task?.title }}</strong>
          </template>
        </i18n-t>
      </p>
    </template>
  </s-modal>

  <s-modal
    v-if="leavingToHref"
    v-model:open="showDirtyFormWarningModal"
    :title="t('form.unsavedChanges')"
    :confirm="{
      label: t('form.saveAndPreview'),
      icon: 'floppy',
      color: 'blue',
    }"
    @confirm="saveAndRedirect(leavingToHref)"
    @close="showDirtyFormWarningModal = false"
    cancellable
  >
    <template #content>
      <p>
        {{ t('form.saveAndPreviewConfirmation') }}
      </p>
    </template>
  </s-modal>
</template>
<script setup lang="ts">
import {computed, ref} from 'vue';
import {Head, useForm, router} from '@inertiajs/vue3';
import {useSortable} from '@vueuse/integrations/useSortable';
import {useI18n} from 'vue-i18n';
import {route} from 'ziggy-js';
import TaskAssignmentDto = App.DTOs.TaskAssignmentDto;
import TaskIndexDto = App.DTOs.Tasks.TaskIndexDto;
import {Course} from '../../types/entities/course';
import {Assignment} from '../../types/entities/assignment';
import SIcon from '../../design-system/SIcon.vue';
import SBtn from '../../design-system/SBtn.vue';
import SBadge from '../../design-system/SBadge.vue';
import SBadgeGroup from '../../design-system/SBadgeGroup.vue';
import STooltip from '../../design-system/STooltip.vue';
import SPageBlock from '../../design-system/SPageBlock.vue';
import SModal from '../../design-system/SModal.vue';
import SFormFooter from '../../design-system/SFormFooter.vue';
import SLatex from '../../components/SLatex.vue';
import CreateTaskAssignmentDrawer from './Components/CreateTaskAssignmentDrawer.vue';
import DisplayTaskTypes from './Components/DisplayTaskTypes.vue';
import PreviewTaskModal from './Components/PreviewTaskModal.vue';
import TaskBadge from './Components/TaskBadge.vue';
import TaskTypeDto = App.DTOs.Tasks.TaskTypeDto;
import SCheckbox from '../../design-system/SCheckbox.vue';
import SInlineInputField from '../../design-system/SInlineInputField.vue';
import assignmentTasksTranslations from './assignmentTasks.json';
import EditAssignmentHeader from '../../layouts/EditAssignmentHeader.vue';

type AssignmentTask = TaskAssignmentDto & {task: TaskIndexDto};

const props = defineProps<{
  assignmentTasks: AssignmentTask[];
  assignment: Assignment;
  course: Course;
  types: TaskTypeDto[];
  can: {
    updateAssignment: boolean;
  };
}>();

const listWrapperRef = ref<HTMLElement | null>(null);
const assignmentTotalPoints = computed(() => {
  return form.tasksUpdates.reduce((total, taskAssignment) => {
    return total + (taskAssignment.pointValue || 0);
  }, 0);
});
const taskToTryAndRemove = ref<AssignmentTask | null>(null);
const showRemoveConfirmationModal = ref<boolean>(false);

const taskToPreview = ref<TaskIndexDto | null>(null);
const taskAssignmentToPreview = ref<AssignmentTask | null>(null);

const leavingToHref = ref<string | null>(null);
const showDirtyFormWarningModal = ref<boolean>(false);

function tryToLeave(route: string) {
  if (form.isDirty) {
    leavingToHref.value = route;
    showDirtyFormWarningModal.value = true;
  } else {
    router.visit(route);
  }
}

function saveAndRedirect(href: string) {
  form.post(
    route('courses.assignments.tasks.store', {
      course: props.course.id,
      assignment: props.assignment.id,
    }),
    {
      onSuccess: () => {
        router.visit(href);
      },
      onError: () => {
        showDirtyFormWarningModal.value = false;
      },
    }
  );
}

function openTaskPreview(task: TaskIndexDto, taskAssignment: AssignmentTask) {
  taskAssignmentToPreview.value === taskAssignment
    ? (taskAssignmentToPreview.value = null)
    : (taskAssignmentToPreview.value = taskAssignment);
  taskToPreview.value === task ? (taskToPreview.value = null) : (taskToPreview.value = task);
}

const getCurrentTaskIndex = computed(() => {
  return form.tasksUpdates.findIndex(
    (taskAssignment) => taskAssignment.task.id === taskToPreview.value?.id
  );
});

const hasPreviousTask = computed(() => {
  return form.tasksUpdates.length && getCurrentTaskIndex.value > 0;
});

const hasNextTask = computed(() => {
  return form.tasksUpdates.length && getCurrentTaskIndex.value < form.tasksUpdates.length - 1;
});

function nextTask() {
  if (hasNextTask.value) {
    taskToPreview.value = form.tasksUpdates[getCurrentTaskIndex.value + 1].task || null;
  }
}

function previousTask() {
  if (hasPreviousTask.value) {
    taskToPreview.value = form.tasksUpdates[getCurrentTaskIndex.value - 1].task || null;
  }
}

const form = useForm({
  tasksUpdates: props.assignmentTasks,
});

useSortable(listWrapperRef, () => form.tasksUpdates, {
  handle: '.handle',
  animation: 150,
});

const showTaskModal = ref<boolean>(false);

const openTaskModal = () => {
  showTaskModal.value = true;
};

function removeTask(taskAssignment: AssignmentTask) {
  form.tasksUpdates = form.tasksUpdates.filter((item) => item !== taskAssignment);
}

function tryToRemoveTask(taskAssignment: AssignmentTask) {
  if (!showRemoveConfirmationModal.value) {
    showRemoveConfirmationModal.value = true;
    taskToTryAndRemove.value = taskAssignment;
  } else {
    removeTask(taskAssignment);
    showRemoveConfirmationModal.value = false;
  }
}

function updateAssignmentTasks() {
  form.post(
    route('courses.assignments.tasks.store', {
      course: props.course.id,
      assignment: props.assignment.id,
    })
  );
}

const {t} = useI18n({
  useScope: 'local',
  inheritLocale: true,
  messages: assignmentTasksTranslations,
});
</script>
<i18n>
{
  "en": {
    "aiGraded": "AI Graded",
    "customMadeTask": "Custom"
  },
  "fr": {
    "aiGraded": "Noté par IA",
    "customMadeTask": "Personnalisée"
  }
}
</i18n>
