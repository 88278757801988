import {Task, TaskResponse} from '../../../types/entities/tasks';
import {computed, Ref} from 'vue';

export interface UseSubmitButtonStateOptions {
  task: Task;
  storedResponse: TaskResponse | null;
}

export function useSubmitButtonState(
  options: Ref<UseSubmitButtonStateOptions>,
  isDirty: Ref<boolean>
) {
  const isStoreSubmitDisabled = computed(() => {
    return (
      // Cannot submit an already submitted response
      (options.value.storedResponse &&
        options.value.storedResponse.submittedAt &&
        !isDirty.value) ||
      options.value.task.taskType === 'WatchVideoTask' ||
      (options.value.task.taskType === 'DynamicQuestion' && !options.value.task.content.hasGrader)
    );
  });

  const submitTooltipText = computed(() => {
    const isVideo = options.value.task.taskType === 'WatchVideoTask';
    const isDynamicQuestion = options.value.task.taskType === 'DynamicQuestion';

    if (options.value.storedResponse && options.value.storedResponse.submittedAt) {
      if (isVideo) {
        return 'You have already received credit for watching this video.';
      }
      return 'This response has already been submitted. You can modify it to start a new draft.';
    } else if (isVideo) {
      return 'Watching a video will automatically submit your response.';
    } else if (isDynamicQuestion) {
      return 'This task is not graded through Stemble';
    }
    return null;
  });

  return {isStoreSubmitDisabled, submitTooltipText};
}
