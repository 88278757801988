<template>
  <Head :title="`${t('heading')} - ${user.email} - ${course.code}`"></Head>
  <s-page-block-scrollable>
    <template #header>
      <s-page-header :heading="t('heading')" class="mb-4"></s-page-header>
    </template>

    <fieldset class="flex flex-wrap gap-3 mb-4">
      <s-multi-select
        id="activityTypes"
        :label="t('search.activityTypes')"
        :model-value="selectedActivityTypes"
        value-key="value"
        label-key="description"
        :empty-label="t('search.activityTypes')"
        :options="translatedActivityTypes"
        @update:model-value="
          params.activityTypes = $event;
          debouncedSubmitSearch({activityTypes: $event});
        "
      >
        <template #option="{option}">
          <div class="flex items-center gap-1">
            <activity-icon :activity-type-name="option.name" />
            <span>{{ option.description }}</span>
          </div>
        </template>
      </s-multi-select>

      <s-combobox
        :model-value="selectedAssignmentId"
        :displayValue="(assignment?: any) => assignment?.name"
        :options="allAssignments"
        id-key="id"
        :emptyLabel="t('allAssignments')"
        :filter-by="['name']"
        @update:modelValue="submitSearch({assignmentId: $event, taskId: undefined})"
      />

      <s-tooltip :disabled="!!selectedAssignmentId" :text="t('selectAssignmentFirst')">
        <s-combobox
          :model-value="selectedTaskId"
          :displayValue="taskDisplayName"
          :options="allTasks"
          id-key="id"
          :emptyLabel="t('allTasks')"
          @update:modelValue="submitSearch({taskId: $event})"
          :disabled="!selectedAssignmentId"
        />
      </s-tooltip>
    </fieldset>

    <s-table
      :items="userActivities?.data"
      :getItemKey="(item: UserActivityLogDto) => item.id"
      class="mb-5"
      density="denser"
    >
      <template #header>
        <th></th>
        <th>{{ t('activity') }}</th>
        <th>{{ t('timestamp') }}</th>
      </template>
      <template #body>
        <template v-if="userActivities?.data.length">
          <tr
            v-for="activity in userActivities?.data"
            :key="activity.id"
            :class="activityTableRowClassMap[activity.activityTypeName]"
          >
            <td class="!pr-0 w-0" :class="activityTableCellClassMap[activity.activityTypeName]">
              <activity-icon :activity-type-name="activity.activityTypeName" />
            </td>
            <td
              class="activity-cell-green"
              :class="activityTableCellClassMap[activity.activityTypeName]"
            >
              <s-link
                class="text-inherit hover:text-blue-500 underline underline-offset-2 decoration-transparent hover:decoration-blue-200/70 active:decoration-blue-200 cursor-pointer"
                :href="
                  getActivityLink({
                    activity,
                  })
                "
              >
                <i18n-t :keypath="`activities.messages.${activity.activityTypeName}`">
                  <template v-slot:succeeded>
                    <strong>{{ t('succeeded') }}</strong>
                  </template>
                  <template v-slot:failed>
                    <strong>{{ t('failed') }}</strong>
                  </template>
                  <template v-slot:assignmentName>
                    <strong>
                      {{ activity.displayContext.assignmentName }}
                    </strong>
                  </template>
                  <template v-slot:taskNumber>
                    <strong>
                      {{
                        t('taskX', {
                          taskNumber: activity.displayContext.taskNumber,
                        })
                      }}
                    </strong>
                  </template>
                </i18n-t>
              </s-link>
              <div v-if="activity.displayContext.reasons" class="text-sm leading-tight">
                <ul class="inline comma-separated oxford-comma">
                  <li
                    v-for="reason in activity.displayContext.reasons"
                    :key="reason"
                    class="inline"
                  >
                    <span class="opacity-80 font-medium">{{ reason }}</span>
                  </li>
                </ul>
              </div>
            </td>
            <td :class="activityTableCellClassMap[activity.activityTypeName]">
              <span class="whitespace-nowrap leading-tight">
                {{ monthDaySometimesYear(activity.createdAt) }}
                {{ timeOfDay(activity.createdAt) }}
              </span>
            </td>
          </tr>
        </template>
        <tr v-else>
          <td colspan="100%" class="italic text-gray-400">{{ t('noResults') }}</td>
        </tr>
      </template>
    </s-table>
    <template #footer>
      <s-paginator :paginator="userActivities" :only="reloadProps" />
    </template>
  </s-page-block-scrollable>
</template>
<script setup lang="ts">
import {Head, router} from '@inertiajs/vue3';
import {LaravelPaginator} from '../../types/laravel-paginator';
import SPaginator from '../../design-system/SPaginator.vue';
import STable from '../../design-system/STable.vue';
import STooltip from '../../design-system/STooltip.vue';
import {Course} from '../../types/entities/course';
import SPageBlockScrollable from '../../design-system/SPageBlockScrollable.vue';
import {useI18n} from 'vue-i18n';
import {route} from 'ziggy-js';
import SPageHeader from '../../design-system/SPageHeader.vue';
import UserActivityLogDto = App.DTOs.UserActivityLogDto;
import ActivityIcon from './Index/ActivityIcon.vue';
import ShowParticipantDto = App.DTOs.CourseParticipant.ShowParticipantDto;
import SMultiSelect from '../../design-system/SMultiSelect.vue';
import {usePageParams} from '../../composables/usePageParams';
import {computed, watch} from 'vue';
import IntegerEnumDto = App.DTOs.Users.IntegerEnumDto;
import {useCancellableDebounceFn} from '../../composables/useCancellableDebounceFn';
import SCombobox from '../../design-system/SCombobox.vue';
import SLink from '../../components/SLink.vue';
import {monthDaySometimesYear, timeOfDay} from '../../format/dates';

const {t} = useI18n({
  inheritLocale: true,
  useScope: 'local',
});

const props = defineProps<{
  user: ShowParticipantDto;
  course: Course;
  assignments: {id: number; name: string}[];
  tasks: {id: number; title: string; orderingIndex: number}[];
  userActivities: LaravelPaginator<UserActivityLogDto>;
  activityTypes: IntegerEnumDto[];
}>();

type PropKeys = keyof typeof props;
const reloadProps: PropKeys[] = ['userActivities', 'tasks'];

const allAssignments = computed(() => [
  {
    id: undefined,
    name: t('allAssignments'),
  },
  ...props.assignments,
]);

const allTasks = computed(() => [
  {
    id: undefined,
    title: '',
    orderingIndex: 0,
  },
  ...props.tasks,
]);

const taskDisplayName = (task: {id?: number; title: string; orderingIndex: number}) => {
  if (!task.id) {
    return t('allTasks');
  }

  return `${task.orderingIndex}: ${task.title}`;
};

const translatedActivityTypes = computed<{value: number; name: string; description: string}[]>(
  () => {
    return props.activityTypes.map((activityType) => ({
      ...activityType,
      description: t(`activities.titles.${activityType.name}`),
    }));
  }
);

type PageParams = {
  activityTypes?: string[];
  assignmentId?: string;
  taskId?: string;
};

const params = usePageParams<PageParams>();

const selectedAssignmentId = computed(() => {
  return params.assignmentId ? parseInt(params.assignmentId) : undefined;
});

const selectedTaskId = computed(() => {
  return params.taskId ? parseInt(params.taskId) : undefined;
});

const selectedActivityTypes = computed(() => {
  return params.activityTypes?.map((x) => parseInt(x));
});

const getRouteParams = () => {
  return {
    ...params,
  };
};

const submitSearch = (params: Partial<PageParams>) => {
  router.visit(
    route('courses.participants.activities.index', {
      user: props.user.id,
      course: props.course.id,
      // Get current route params
      ...getRouteParams(),
      // Empty page when changing filters
      page: undefined,
      // Merge in latest changes.
      ...params,
    }),
    {
      only: reloadProps,
    }
  );
};

const {debounced: debouncedSubmitSearch} = useCancellableDebounceFn(submitSearch, 1500);

const activityTableRowClassMap: {[key: string]: string} = {
  TASK_RESPONSE_SUBMIT_SUCCEEDED:
    'bg-gradient-to-r from-green-100 via-transparent to-transparent even:via-gray-50 even:to-gray-50',
  TASK_RESPONSE_SUBMIT_FAILED:
    'bg-gradient-to-r from-red-100 via-transparent to-transparent even:via-gray-50 even:to-gray-50',
  TASK_RESPONSE_DRAFT_STORE_SUCCEEDED:
    'bg-gradient-to-r from-green-100 via-transparent to-transparent even:via-gray-50 even:to-gray-50',
  TASK_RESPONSE_DRAFT_STORE_FAILED:
    'bg-gradient-to-r from-red-100 via-transparent to-transparent even:via-gray-50 even:to-gray-50',
};

const activityTableCellClassMap: {[key: string]: string} = {
  TASK_RESPONSE_SUBMIT_SUCCEEDED: 'text-green-700 !bg-transparent font-medium',
  TASK_RESPONSE_SUBMIT_FAILED: 'text-red-700 !bg-transparent font-medium',
  TASK_RESPONSE_DRAFT_STORE_SUCCEEDED: 'text-green-700 !bg-transparent font-medium',
  TASK_RESPONSE_DRAFT_STORE_FAILED: 'text-red-700 !bg-transparent font-medium',
};

const getActivityLink = ({activity}: {activity: UserActivityLogDto}) => {
  return route('courses.assignment.marking.tasks.show', {
    course: activity.courseId,
    assignment: activity.assignmentId,
    user: props.user.id,
    task: activity.taskId,
    _query: {
      response: activity.responseId,
    },
  });
};
</script>
<i18n>
{
  "en": {
    "heading": "Activity Log",
    "activity": "Activity",
    "timestamp": "Timestamp",
    "search": {
      "activityTypes": "Activity Types"
    },
    "allAssignments": "All Assignments",
    "allTasks": "All Tasks",
    "taskX": "Task {taskNumber}",
    "succeeded": "Succeeded",
    "failed": "Failed",
    "noResults": "No results found",
    "selectAssignmentFirst": "Select an assignment first",
    "activities": {
      "titles": {
        "TASK_RESPONSE_SUBMIT_ATTEMPTED": "Submit Attempted",
        "TASK_RESPONSE_SUBMIT_SUCCEEDED": "Submit Succeeded",
        "TASK_RESPONSE_SUBMIT_FAILED": "Submit Failed",
        "TASK_RESPONSE_DRAFT_STORE_ATTEMPTED": "Draft Save Attempted",
        "TASK_RESPONSE_DRAFT_STORE_SUCCEEDED": "Draft Response Save Succeeded",
        "TASK_RESPONSE_DRAFT_STORE_FAILED": "Draft Response Save Failed",
        "TASK_RESPONSE_DRAFT_DELETED": "Deleted Draft",
        "NAVIGATED_TO_ASSIGNMENT_TASK": "Navigated to Task"
      },
      "messages": {
        "TASK_RESPONSE_SUBMIT_ATTEMPTED": "Submit attempted for {taskNumber} on {assignmentName}",
        "TASK_RESPONSE_SUBMIT_SUCCEEDED": "Submit succeeded for {taskNumber} on {assignmentName}",
        "TASK_RESPONSE_SUBMIT_FAILED": "Submit failed for {taskNumber} on {assignmentName}",
        "TASK_RESPONSE_DRAFT_STORE_ATTEMPTED": "Draft save attempted for {taskNumber} on {assignmentName}",
        "TASK_RESPONSE_DRAFT_STORE_SUCCEEDED": "Draft save succeeded for {taskNumber} on {assignmentName}",
        "TASK_RESPONSE_DRAFT_STORE_FAILED": "Draft save failed for {taskNumber} on {assignmentName}",
        "TASK_RESPONSE_DRAFT_DELETED": "Deleted Draft for {taskNumber} on {assignmentName}",
        "NAVIGATED_TO_ASSIGNMENT_TASK": "Navigated to {taskNumber} on {assignmentName}"
      }
    }
  },
  "fr": {
    "heading": "Journal d'activités",
    "activity": "Activité",
    "timestamp": "Horodatage",
    "search": {
      "activityTypes": "Types d'activités"
    },
    "allAssignments": "Tous les devoirs",
    "allTasks": "Toutes les tâches",
    "taskX": "Tâche {taskNumber}",
    "succeeded": "Réussi",
    "failed": "Échoué",
    "noResults": "Aucun résultat trouvé",
    "selectAssignmentFirst": "Sélectionnez d'abord un devoir",
    "activities": {
      "titles": {
        "TASK_RESPONSE_SUBMIT_ATTEMPTED": "Tentative d'envoi",
        "TASK_RESPONSE_SUBMIT_SUCCEEDED": "Envoi réussi",
        "TASK_RESPONSE_SUBMIT_FAILED": "Envoi échoué",
        "TASK_RESPONSE_DRAFT_STORE_ATTEMPTED": "Tentative de sauvegarde du brouillon",
        "TASK_RESPONSE_DRAFT_STORE_SUCCEEDED": "Sauvegarde du brouillon réussie",
        "TASK_RESPONSE_DRAFT_STORE_FAILED": "Sauvegarde du brouillon échouée",
        "TASK_RESPONSE_DRAFT_DELETED": "Brouillon supprimé",
        "NAVIGATED_TO_ASSIGNMENT_TASK": "Navigué vers la tâche"
      },
      "messages": {
        "TASK_RESPONSE_SUBMIT_ATTEMPTED": "Envoi tenté pour {taskNumber} sur {assignmentName}",
        "TASK_RESPONSE_SUBMIT_SUCCEEDED": "Envoi réussi pour {taskNumber} sur {assignmentName}",
        "TASK_RESPONSE_SUBMIT_FAILED": "Échec de l'envoi pour {taskNumber} sur {assignmentName}",
        "TASK_RESPONSE_DRAFT_STORE_ATTEMPTED": "Brouillon sauvegardé pour {taskNumber} sur {assignmentName}",
        "TASK_RESPONSE_DRAFT_STORE_SUCCEEDED": "Brouillon sauvegardé pour {taskNumber} sur {assignmentName}",
        "TASK_RESPONSE_DRAFT_STORE_FAILED": "Échec de la sauvegarde du brouillon pour {taskNumber} sur {assignmentName}",
        "TASK_RESPONSE_DRAFT_DELETED": "Brouillon supprimé pour la tâche {taskNumber} sur {assignmentName}",
        "NAVIGATED_TO_ASSIGNMENT_TASK": "Navigué vers {taskNumber} sur {assignmentName}"
      }
    }
  }
}
</i18n>
