<template>
  <Head :title="`Admin - Tasks`"></Head>
  <div class="page-block max-w-screen-lg gap-5 pb-8">
    <s-page-header heading="Tasks">
      <template #actions>
        <s-btn :href="route('admin.tasks.analytics')" color="secondary" icon="poll">
          View Analytics
        </s-btn>
        <s-btn :href="route('tasks.create')" color="primary" icon="plus">Create Task</s-btn>
      </template>
    </s-page-header>

    <s-stats-section
      heading="Last 7 Days"
      :stats="[{label: 'New Tasks', value: numRecentTasks}]"
      class="-mt-2"
    />

    <div class="flex flex-wrap gap-x-3 gap-y-4 items-end justify-between">
      <div class="text-sm grow max-w-56">
        <s-select-field id="status" label="Status" v-model="status">
          <option value="">All</option>
          <option value="draft">Draft</option>
          <option value="ready_for_review">Ready for Review</option>
          <option value="published">Published</option>
          <option value="archived">Archived</option>
        </s-select-field>
      </div>
      <div class="text-sm grow max-w-56">
        <s-select-field id="institution" label="Institution" v-model="institution">
          <option value="">Any</option>
          <option v-for="institution in institutions" :value="institution.id">
            {{ institution.abbreviation }}
          </option>
        </s-select-field>
      </div>
      <div class="text-sm grow max-w-56">
        <s-select-field id="createdBy" label="Created By" v-model="createdBy">
          <option value="">Any</option>
          <option v-for="user in users" :value="user.id">
            {{ user.firstName }} {{ user.lastName }}
          </option>
        </s-select-field>
      </div>
      <div class="grow max-w-xl">
        <s-search-section
          route-name="admin.tasks.index"
          label="Search by task ID or title"
          :only="['tasks']"
        />
      </div>
    </div>

    <section class="stack vertical gap-3">
      <s-link
        v-for="task in tasks.data"
        :href="route('admin.tasks.show', [task.id])"
        class="group card-soft border border-gray-200 transition hover:from-gray-100 hover:to-gray-50 px-4 md:px-5 lg:px-6 py-2.5 md:py-4 stack horizontal align-between valign-center gap-3 text-gray-700 hover:text-blue-500"
      >
        <div class="stack vertical gap-2 w-full">
          <div>
            <div class="text-sm leading-tight opacity-70 mb-0.5">
              {{ task.id }}

              {{ task.component || task.questionId || task.taskObject.type }}
            </div>
            <div class="text-lg leading-tight">
              <s-latex :content="task.taskObject.title"></s-latex>
            </div>
            <div class="flex gap-5 flex-wrap mt-0.5">
              <div v-if="task.createdById" class="text-sm leading-tight opacity-70 mt-0.5">
                Created by:
                <strong>{{ task.createdByFirstName }} {{ task.createdByLastName }}</strong>
              </div>

              <div v-if="task.institutions" class="text-sm leading-tight opacity-70 mt-0.5">
                Scoped to:
                <strong>{{ task.institutions.split(',').join(', ') }}</strong>
              </div>
            </div>
          </div>
        </div>
        <div class="stack horizontal gap-4 w-fit text-nowrap">
          <s-task-status-badge :status="task.status" size="sm" />
          <s-icon
            name="chevron-right"
            class="text-blue-500 opacity-50 group-hover:opacity-80 group-active:opacity-100 transition"
          />
        </div>
      </s-link>
    </section>
    <s-paginator :paginator="tasks" preserve-state />
  </div>
</template>
<script setup lang="ts">
import {LaravelPaginator} from '../../../types/laravel-paginator';
import SLink from '../../../components/SLink.vue';
import {route} from 'ziggy-js';
import SPaginator from '../../../design-system/SPaginator.vue';
import SPageHeader from '../../../design-system/SPageHeader.vue';
import SSearchSection from '../../../design-system/SSearchSection.vue';
import {Head, router} from '@inertiajs/vue3';
import SSelectField from '../../../design-system/SSelectField.vue';
import {ref, watch} from 'vue';
import SBtn from '../../../design-system/SBtn.vue';
import AdminTaskIndexRecordDto = App.DTOs.AdminTaskIndexRecordDto;
import {Institution} from '../../../types/entities/institution';
import SLatex from '../../../components/SLatex.vue';
import SStatsSection from '../../../design-system/SStatsSection.vue';
import STaskStatusBadge from '../../../components/STaskStatusBadge.vue';
import SIcon from '../../../design-system/SIcon.vue';
import {User} from '../../../types/entities/user';

defineProps<{
  numRecentTasks: number;
  tasks: LaravelPaginator<AdminTaskIndexRecordDto>;
  institutions: Institution[];
  users: User[];
}>();

const status = ref<string>((route().params.status as string) || '');
const institution = ref<string>((route().params.institution as string) || '');
const createdBy = ref<string>((route().params.createdBy as string) || '');

watch([status, institution, createdBy], ([newStatus, newInstitution, newCreatedBy]) => {
  router.replace(
    route('admin.tasks.index', {
      status: newStatus,
      institution: newInstitution,
      createdBy: newCreatedBy,
      search: route().params.search,
    })
  );
});
</script>
