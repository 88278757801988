<template>
  <Head title="Admin - Current Ai Grading"></Head>
  <div class="page-block">
    <section class="mt-4">
      <h2 class="heading lg mb-2">Active Courses</h2>
      <div class="card padded">
        <course-token-usage-sunburst-chart
          v-if="courseTokenUsage"
          :token-usage="courseTokenUsage"
          :institution-token-usage="institutionTokenUsage"
        />
        <span v-else>Please check back later for data.</span>
      </div>
      <div class="card padded">
        <course-token-cost-per-user-bubble-chart
          v-if="courseTokenUsage"
          :course-token-usage="courseTokenUsage"
        />
        <span v-else>Please check back later for data.</span>
      </div>
    </section>
  </div>
</template>
<script setup lang="ts">
import {route} from 'ziggy-js';
import {Head} from '@inertiajs/vue3';
import {useBreadcrumbs} from '../../../../composables/useBreadcrumbs';
import CourseTokenUsageSunburstChart from './CourseTokenUsageSunburstChart.vue';
import CourseTokenCostPerUserBubbleChart from './CourseTokenCostPerUserBubbleChart.vue';

defineProps<{
  courseTokenUsage: Array<{
    courseId: number;
    courseName: string;
    courseCode: string;
    startDate: any;
    endDate: any;
    institutionId: number;
    institutionName: string;
    institutionCode: string;
    inputTokens: number;
    outputTokens: number;
    totalTokens: number;
    totalStudents: number;
  }>;
  institutionTokenUsage: Array<{
    name: string; // Institution Abbreviation
    institutionFullName: string;
    children: Array<{
      name: string; // Course code
      courseFullName: string;
      value: number; // Total tokens
    }>;
  }>;
}>();

useBreadcrumbs([{label: 'Ai Analytics', href: route('admin.ai-analytics.index')}]);
</script>

<style scoped></style>
