import {EditorView} from 'prosemirror-view';
import {mathSerializer} from '@benrbray/prosemirror-math';
import {DOMSerializer} from 'prosemirror-model';
import {EditorState} from 'prosemirror-state';

type EditorViewOptions = {
  el: HTMLElement;
  state: EditorState;
  editable: boolean;
  output: 'json' | 'html' | 'node';
  onChange(value: any): void;
};

export function createEditorView({el, state, editable, output, onChange}: EditorViewOptions) {
  const view = new EditorView(el, {
    state,
    editable: () => editable,
    clipboardTextSerializer: (slice) => {
      return mathSerializer.serializeSlice(slice);
    },
    dispatchTransaction(transaction) {
      const newState = view.state.apply(transaction);

      view.updateState(newState);

      if (transaction.docChanged) {
        if (output === 'html') {
          const el = document.createElement('div');
          DOMSerializer.fromSchema(state.schema).serializeFragment(newState.doc.content, {}, el);
          onChange(el.innerHTML);
        } else if (output === 'node') {
          onChange(newState.doc);
        } else {
          onChange(newState.doc.toJSON());
        }
      }
    },
  });
  return view;
}
