<template>
  <s-input-field :id="control.prop" :label="control.label" v-model="model" />
</template>
<script setup lang="ts">
import SInputField from '../../../design-system/SInputField.vue';
import {Control} from '../examples';

defineProps<{
  control: Control;
}>();

const model = defineModel<string | number | null | undefined>();
</script>
