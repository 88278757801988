import {Schema} from 'prosemirror-model';
import {Plugin} from 'prosemirror-state';

/*
 * ------------------------------------------------------------
 * Official Plugins
 * ------------------------------------------------------------
 */
import {inputRules} from 'prosemirror-inputrules';
import {keymap as createKeymap} from 'prosemirror-keymap';
import {dropCursor} from 'prosemirror-dropcursor';
import {history} from 'prosemirror-history';
import {baseKeymap as baseKeybindings} from 'prosemirror-commands';

/*
 * ------------------------------------------------------------
 * Official Plugin Config
 * ------------------------------------------------------------
 */
import {createKeymapBindings} from '../plugins/createKeymapBindings';
import {createInputRules} from '../input-rules/createInputRules';

/**
 * ------------------------------------------------------------
 * Third Party Plugins
 * ------------------------------------------------------------
 */
import codemark from 'prosemirror-codemark';
import 'prosemirror-codemark/dist/codemark.css';

import {mathPlugin} from '@benrbray/prosemirror-math';

/*
 * ------------------------------------------------------------
 * In-House Plugins
 * ------------------------------------------------------------
 */
import taskComponentCreationInterceptor from '../../task-editor/schema/plugins/task-component-creation-interceptor';
import {VueNodeViewPlugin} from '../plugins/VueNodeViewPlugin';
import ImageNode from '../views/ImageNode.vue';

/**
 * ------------------------------------------------------------
 * Default Stemble Plugins
 * ------------------------------------------------------------
 *
 * Plugins are the main way to extend the functionality of
 * the ProseMirror editor. They can be used to provide a wide
 * variety of features, from key bindings to custom node views.
 *
 * @see https://prosemirror.net/docs/ref/#state.Plugin
 */
export const initializePlugins = (schema: Schema, additionalPlugins: Plugin<any>[] = []) => {
  let plugins = [
    /**
     * ------------------------------------------------------------
     * History
     * ------------------------------------------------------------
     *
     * Allows for undo/redo functionality.
     */
    history(),

    /**
     * ------------------------------------------------------------
     * Keymap plugins
     * ------------------------------------------------------------
     *
     * Order is important.
     * So is them being separate.
     */
    createKeymap(createKeymapBindings(schema)),
    createKeymap(baseKeybindings),

    /**
     * ------------------------------------------------------------
     * Input rules
     * ------------------------------------------------------------
     *
     * Allows for text replacement. e.g. markdown syntax
     */
    inputRules({rules: createInputRules(schema)}),

    /**
     * ------------------------------------------------------------
     * Drop Cursor
     * ------------------------------------------------------------
     *
     * Shows a cursor to indicate where content will be dropped.
     */
    dropCursor(),
  ];

  /**
   * ------------------------------------------------------------
   * Math Helpers
   * ------------------------------------------------------------
   *
   * https://github.com/benrbray/prosemirror-math
   */
  if (schema.marks.code) {
    plugins = [...plugins, ...codemark({markType: schema.marks.code}), mathPlugin];
  }

  if (schema.nodes.image) {
    plugins.push(new VueNodeViewPlugin('image', ImageNode, 'span'));
  }

  return [...plugins, ...additionalPlugins];
};
