<template>
  <Head :title="t('page.title')"></Head>
  <form
    @submit.prevent="
      router.post(route('profile.update'), {
        _method: 'patch',
        avatar: form.avatar,
        firstName: form.firstName,
        lastName: form.lastName,
        notificationPreferenceMode: form.notificationPreferenceMode,
        languagePreference: form.languagePreference,
      })
    "
    class="page-block max-w-screen-md"
  >
    <div class="flex gap-3 items-center mb-5">
      <div class="rounded-full p-1 bg-white border border-gray-150">
        <img
          v-if="profilePicture"
          v-show="user"
          :src="profilePicture"
          :alt="t('profilePicture')"
          class="w-20 h-20"
        />
      </div>
      <s-input-field
        type="file"
        @input="form.avatar = $event.target.files[0]"
        :error="form.errors.avatar"
        id=""
      />
    </div>
    <s-input-field
      id="firstName"
      :label="t('firstName')"
      v-model="form.firstName"
      :error="form.errors.firstName"
      class="mb-4"
    />
    <s-input-field
      id="lastName"
      :label="t('lastName')"
      v-model="form.lastName"
      :error="form.errors.lastName"
      class="mb-4"
    />
    <s-input-field id="email" :label="t('email')" :modelValue="user.email" class="mb-4" disabled />
    <s-select-field
      :label="t('languagePreference')"
      id="languagePreference"
      v-model="form.languagePreference"
    >
      <option
        v-for="languagePreference in languagePreferenceOptions"
        :value="languagePreference.value"
      >
        {{ languagePreference.label }}
      </option>
    </s-select-field>
    <div class="flex flex-col gap-1 mt-3 mb-2">
      <label class="flex items-center gap-1.5 font-bold text-gray-700 leading-tight">
        {{ t('notifications') }}
      </label>
      <s-toggle name="notificationPreference" v-model="form.notificationPreferenceMode" id="" />
    </div>
    <div class="flex flex-wrap gap-3 justify-end">
      <s-link
        :href="route('profile.show', user.id)"
        class="btn white lg flex items-center justify-center gap-2"
      >
        <s-icon name="close" class="opacity-70" width="18" height="18" />
        {{ t('form.cancel') }}
      </s-link>
      <button
        type="submit"
        class="btn primary md flex items-center justify-center gap-2"
        :disabled="form.processing"
      >
        <s-icon name="content-save" class="opacity-70" width="18" height="18" />
        {{ t('form.save') }}
      </button>
    </div>
  </form>
</template>
<script setup lang="ts">
import SInputField from '../../design-system/SInputField.vue';
import {Head, router, useForm} from '@inertiajs/vue3';
import {User} from '../../types/entities/user';
import SLink from '../../components/SLink.vue';
import {route} from 'ziggy-js';
import SToggle from '../../design-system/SToggle.vue';
import {toRef, watch} from 'vue';
import SIcon from '../../design-system/SIcon.vue';
import {useI18n} from 'vue-i18n';
import SSelectField from '../../design-system/SSelectField.vue';
import SupportedLocales = App.Enums.SupportedLocales;

const props = defineProps<{user: User; profilePicture: string; notificationEnabled: boolean}>();

watch(toRef(props.notificationEnabled), (newMode) => {
  form.notificationPreferenceMode = newMode;
});

const form = useForm({
  firstName: props.user.firstName,
  lastName: props.user.lastName,
  avatar: null,
  notificationPreferenceMode: props.notificationEnabled ?? true,
  languagePreference: props.user.languagePreference ?? 'en',
});

const {t} = useI18n({
  useScope: 'local',
  inheritLocale: true,
});

type LanguagePreferenceOption = {label: string; value: SupportedLocales};
const languagePreferenceOptions: LanguagePreferenceOption[] = [
  {label: t('en'), value: 'en'},
  {label: t('fr'), value: 'fr'},
];
</script>
<i18n>
{
  "en": {
    "page": {
      "title": "Edit Profile"
    },
    "languagePreference": "Language Preference"
  },
  "fr": {
    "page": {
      "title": "Modifier le profil"
    },
    "languagePreference": "Choix de Langue"
  }
}
</i18n>
