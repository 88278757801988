<template>
  <Head :title="t('head.title')"></Head>
  <s-page-block size="md">
    <s-page-header>
      <template #heading>
        <span>
          <i18n-t v-if="parent" keypath="subCourseHeading">
            <template #name>
              <strong>{{ parent.name }}</strong>
            </template>
            <template #code>
              <strong>{{ parent.code }}</strong>
            </template>
          </i18n-t>
          <i18n-t v-else keypath="heading" />
        </span>
      </template>
    </s-page-header>
    <form class="flex flex-col gap-4 mt-3" @submit.prevent="form.post(route('courses.store'))">
      <s-input-field
        id="name"
        :label="t('labels.name')"
        v-model="form.name"
        :error="form.errors.name"
        required
      />
      <s-input-field
        id="code"
        :label="t('labels.courseCode')"
        v-model="form.courseCode"
        :error="form.errors.courseCode"
        required
      />
      <s-select-field
        id="institutionId"
        :label="t('institution')"
        :disabled="!!parent"
        v-model="form.institutionId"
        :error="form.errors.institutionId"
        required
      >
        <option v-for="institution in institutions" :value="institution.id">
          {{ institution.name }}
        </option>
      </s-select-field>

      <fieldset class="flex flex-col md:flex-row gap-5">
        <s-input-field
          id="startDate"
          :label="t('startDate')"
          type="date"
          v-model="form.startDate"
          :error="form.errors.startDate"
          required
        />
        <s-input-field
          id="endDate"
          :label="t('endDate')"
          type="date"
          v-model="form.endDate"
          :error="form.errors.endDate"
          required
        />
      </fieldset>

      <h2 class="heading sm">
        {{ t('Payment Information') }}
      </h2>
      <s-select-field
        id="paymentModel"
        :label="t('labels.paymentModel')"
        v-model="form.paymentModel"
      >
        <option v-for="model in paymentModels" :value="model">
          {{ model }}
        </option>
      </s-select-field>
      <fieldset
        v-if="['student_subscription', 'access_code_only'].includes(form.paymentModel)"
        class="flex flex-col md:flex-row gap-5"
      >
        <s-input-field
          id="priceId"
          :label="t('labels.priceId')"
          v-model="form.priceId"
          :error="form.errors.priceId"
        />
        <s-input-field
          id="couponId"
          :label="t('labels.couponId')"
          v-model="form.couponId"
          :error="form.errors.couponId"
        />
      </fieldset>
      <s-input-field
        id="dueDate"
        v-if="['student_subscription', 'access_code_only'].includes(form.paymentModel)"
        :label="t('labels.paymentDueDate')"
        type="date"
        v-model="form.paymentDueDate"
        :error="form.errors.paymentDueDate"
      />

      <div class="flex justify-end py-2">
        <s-btn type="submit" icon="plus" class="flex-1 max-w-[16rem]">
          {{ t('actions.submit') }}
        </s-btn>
      </div>
    </form>
  </s-page-block>
</template>
<script setup lang="ts">
import {Head, useForm} from '@inertiajs/vue3';
import {Institution} from '../../types/entities/institution';
import SPageBlock from '../../design-system/SPageBlock.vue';
import SPageHeader from '../../design-system/SPageHeader.vue';
import SInputField from '../../design-system/SInputField.vue';
import SSelectField from '../../design-system/SSelectField.vue';
import {useI18n} from 'vue-i18n';
import {route} from 'ziggy-js';
import SBtn from '../../design-system/SBtn.vue';
import CreateCourseDto = App.DTOs.Courses.CreateCourseDto;
import {Course} from '../../types/entities/course';
import {formatForDateInput} from '../../format/dates';
import PaymentModel = App.Enums.PaymentModel;

const props = defineProps<{
  parent: Course | null;
  institution: Institution;
  institutions: Institution[];
  paymentModels: PaymentModel[];
}>();

const form = useForm<CreateCourseDto>({
  parentId: props.parent?.id || null,
  institutionId: props.parent?.institution?.id ?? 0,
  name: '',
  courseCode: '',
  priceId: '',
  couponId: '',
  paymentDueDate: '',
  startDate: formatForDateInput(props.parent?.startDate),
  endDate: formatForDateInput(props.parent?.endDate),
  paymentModel: 'student_subscription',
});

const {t} = useI18n({
  useScope: 'local',
  inheritLocale: true,
});
</script>
<i18n>
{
  "en": {
    "head": {
      "title": "Create Course"
    },
    "heading": "Create Course",
    "subCourseHeading": "Create a Sub-Course of {name} ({code})",
    "labels": {
      "name": "Name",
      "courseCode": "Course Code",
      "paymentModel": "Payment Model",
      "priceId": "Stripe Price ID",
      "couponId": "Stripe Coupon ID",
      "paymentDueDate": "Payment Due Date"
    },
    "actions": {
      "submit": "Create the Course"
    }
  },
  "fr": {
    "head": {
      "title": "Créer un cours"
    },
    "heading": "Créer un cours",
    "subCourseHeading": "Créer un sous-cours de {name} ({code})",
    "labels": {
      "name": "Nom",
      "courseCode": "Code du cours",
      "paymentModel": "Payment Model",
      "priceId": "Stripe Price ID",
      "couponId": "Stripe Coupon ID",
      "paymentDueDate": "Payment Due Date"
    },
    "actions": {
      "submit": "Créer le cours"
    }
  }
}
</i18n>
