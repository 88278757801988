<template>
  <SBanner>
    <template #title>
      <i18n-t keypath="title" :plural="paymentRequests.length">
        <template #course>
          {{ paymentRequests[0].course.code }}
        </template>
      </i18n-t>
    </template>
    <template #body>
      <i18n-t keypath="body" :plural="paymentRequests.length">
        <template #date>
          <span class="underline">
            {{ monthDaySometimesYear(paymentRequests[0].dueDate) }}
          </span>
        </template>
        <template #course>
          <span class="font-bold">
            {{ paymentRequests[0].course.code }}
          </span>
        </template>
      </i18n-t>
    </template>
    <template #actions>
      <s-btn :href="route('billing.index')" class="w-fit h-fit" icon="currency-usd">
        {{ t('action') }}
      </s-btn>
    </template>
  </SBanner>
</template>
<script setup lang="ts">
import PaymentRequestDto = App.DTOs.Entities.PaymentRequestDto;
import SBanner from '../design-system/SBanner.vue';
import {route} from 'ziggy-js';
import SBtn from '../design-system/SBtn.vue';
import {useI18n} from 'vue-i18n';
import {monthDaySometimesYear} from '../format/dates';

defineProps<{
  paymentRequests: PaymentRequestDto[];
}>();

const {t} = useI18n({
  useScope: 'local',
  inheritLocale: true,
});
</script>
<i18n>
{
  "en": {
    "title": "Payment required for {course} | Payment required for {count} courses",
    "body": "Please pay by {date} to make sure you retain access to {course}. | Visit the billing page to pay for your courses.",
    "action": "Go to Billing"
  },
  "fr": {
    "title": "Paiement requis pour {course} | Paiement requis pour {count} cours",
    "body": "Veuillez payer avant le {date} pour conserver l'accès à {course}. | Visitez la page de facturation pour payer vos cours.",
    "action": "Aller à la facturation"
  }
}
</i18n>
