import {computed, ref, Ref, shallowRef} from 'vue';
import {LaravelPaginator} from '../types/laravel-paginator';

export const useInfiniteScroll = <T>(
  fetchFn: (previousPaginator?: LaravelPaginator<any> | null) => Promise<LaravelPaginator<T>>,
  paginator?: Ref<LaravelPaginator<T>> | null
) => {
  const loading = ref(false);
  const previousPaginator = shallowRef(paginator?.value ?? null);
  const items = shallowRef(previousPaginator.value?.data ?? []);
  const canLoadMoreItems = computed(() => {
    if (previousPaginator.value) return !!previousPaginator.value.next_page_url;
    return true;
  });

  async function loadMoreItems() {
    if (loading.value) {
      return;
    }

    if (!canLoadMoreItems.value) {
      return;
    }

    /**
     * ## Explanation
     *
     * While it is obvious *what* this code does, it is not obvious *why* it does these things.
     *
     * Let's use notifications as an example.
     *
     * The `/notifications` route is technically paginated. Going to `/notifications?page=1` gets
     * you items 1-10, going to `/notifications?page=2` gets you items 11-20, and so on.
     *
     * Instead of having links to the next page, we have this function which simply fetches the
     * notifications for that page, and the appends them to the items we've already loaded.
     * (See: `items.value = [...items.value, ...props[propName].data]`)
     *
     * Normally that would scroll the window to the top and reset the components state so that
     * you don't see page 1 notifications, only page 2s. The `preserveScroll` and `preserveState`
     * prevent each of those things from happening, respectively.
     *
     * But, it still updates the url in the browser to `/notifications?page=2`. That means that
     * if we scroll to the second page and then hit refresh we won't see any content from page 1.
     * So to prevent that from happening we do `window.history.replaceState({}, '', initialUrl);`
     * to make sure that if the user refreshes, they always see page 1
     */
    try {
      loading.value = true;
      previousPaginator.value = await fetchFn(previousPaginator.value);
      items.value = [...items.value, ...previousPaginator.value.data];
    } finally {
      loading.value = false;
    }
  }
  if (!previousPaginator.value) {
    void loadMoreItems();
  }

  return {
    items,
    canLoadMoreItems,
    loading,
    loadMoreItems,
    removeItem: (id: string) => {
      items.value = items.value.filter((item: any) => item.id != id);
    },
    reset: () => {
      items.value = [];
      loading.value = false;
      previousPaginator.value = null;
    },
  };
};
