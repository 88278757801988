<template>
  <div
    class="overflow-hidden rounded-lg shadow-card-soft border flex flex-col"
    :class="selectedChange !== null ? '' : 'border-yellow-200'"
  >
    <div
      class="px-3 py-1.5 border-b flex items-center justify-between gap-1.5 bg-gradient-to-r"
      :class="
        selectedChange !== null
          ? 'border-gray-200 from-gray-100 via-gray-50 to-gray-50 text-gray-400'
          : 'border-yellow-200 from-yellow-100/80 via-yellow-50 to-yellow-50/50 text-yellow-600'
      "
    >
      <div class="flex items-center justify-start gap-1.5">
        <s-icon name="alert-circle" size="18" class="opacity-70" />
        <p class="font-medium flex gap-1">
          <span class="font-bold">{{ email }}</span>
          –
          {{ messages.map((message) => t(`conflict.${message}`)).join(', ') }}
        </p>
      </div>
      <div v-if="selectedChange !== null" class="text-green-600 flex items-center gap-1.5">
        <s-icon name="check-decagram" size="20" class="opacity-70" />
        <span class="uppercase text-sm font-bold tracking-slight">{{ t('conflictResolved') }}</span>
      </div>
    </div>
    <div
      class="flex flex-col divide-y bg-no-repeat"
      :class="selectedChange !== null ? '' : 'divide-yellow-100'"
    >
      <div
        v-for="(change, index) in props.changes"
        :key="index"
        class="group transition px-3 py-2.5 flex-1 w-full flex items-stretch gap-x-4"
        :class="{
          'cursor-pointer': canResolveConflict,
          'even:bg-white/10 odd:bg-white/50': selectedChange !== index,
          'bg-gradient-to-r from-green-100 via-green-50 to-green-50/50 text-green-700':
            selectedChange === index,
          'hover:bg-blue-50/40 hover:text-blue-600': selectedChange !== index && canResolveConflict,
          'text-gray-600': selectedChange !== null && selectedChange !== index,
        }"
        @click.prevent="canResolveConflict ? selectRow(index) : ''"
      >
        <div
          v-if="canResolveConflict"
          class="grid grid-cols-1 grid-rows-1 items-center justify-center"
        >
          <s-icon
            name="checkbox-blank-circle-outline"
            size="22"
            class="transition row-start-1 col-start-1"
            :class="{
              'text-yellow-600': !selectedChange,
              'text-gray-600': selectedChange !== null,
              'opacity-0': selectedChange === index,
              'opacity-70 group-hover:opacity-0':
                selectedChange === null && selectedChange !== index,
              'opacity-40 group-hover:opacity-0':
                selectedChange !== null && selectedChange !== index,
            }"
          />
          <s-icon
            name="check-decagram"
            size="22"
            class="text-green-500 transition row-start-1 col-start-1"
            :class="{
              'opacity-100': selectedChange === index,
              'opacity-0 group-hover:opacity-40': selectedChange !== index,
            }"
          />
        </div>
        <div class="flex-1 grid grid-flow-col auto-cols-auto gap-y-2 gap-x-4">
          <div
            v-for="(value, key) in getRowData(change)"
            :key="key"
            class="flex flex-col items-start"
          >
            <span class="capitalize text-sm leading-tighter opacity-60 font-medium">
              {{ key }}
            </span>
            <span class="font-medium">
              {{ value }}
            </span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script setup lang="ts">
import {ref, defineProps, computed} from 'vue';
import RosterChangeDto = App.DTOs.Roster.RosterChangeDto;
import {useI18n} from 'vue-i18n';
import SIcon from '../../../design-system/SIcon.vue';

const props = defineProps<{
  email: string;
  changes: RosterChangeDto[];
}>();

const canResolveConflict = false;

const {t} = useI18n({
  useScope: 'local',
  inheritLocale: true,
});

const selectedChange = ref<number | null>(null);

function selectRow(index: number) {
  selectedChange.value = selectedChange.value === index ? null : index;
}

const messages = computed<string[]>(() => {
  return [...new Set(props.changes.flatMap((change) => change.errors || []))];
});

const getRowData = (row: RosterChangeDto) => {
  let data: any = {
    [t('row')]: row.row,
    [t('email')]: row.email,
    [t('section')]: row.sectionName,
  };

  if (row.firstName) {
    data[t('firstName')] = row.firstName;
  }

  if (row.lastName) {
    data[t('lastName')] = row.lastName;
  }

  if (row.studentId) {
    data[t('studentId')] = row.studentId;
  }

  return data;
};
</script>
<i18n>
{
  "en": {
    "conflict": {
      "already-staff": "Already a staff member",
      "student-multiple-sections": "Students cannot be in multiple sections"
    },
    "conflictResolved": "Conflict Resolved"
  },
  "fr": {
    "conflict": {
      "already-staff": "Déjà membre du personnel",
      "student-multiple-sections": "Les étudiants ne peuvent pas être dans plusieurs sections"
    },
    "conflictResolved": "Conflit résolu"
  }
}
</i18n>
