import {Node, Schema} from 'prosemirror-model';
import {EditorState, Plugin} from 'prosemirror-state';
import {parseContent} from '../schema/parser';

export function createState(
  schema: Schema,
  value: string | object | Node | null | undefined,
  plugins: Plugin[] = []
) {
  return EditorState.create({
    doc: parseContent(schema, value),
    schema,
    plugins,
  });
}
