<template>
  <s-latex-number :number="computedValue" />
</template>
<script lang="ts" setup>
import SLatexNumber from './SLatexNumber.vue';
import {computed} from 'vue';
import {decimalNumber} from '../format/task-state';

const props = defineProps<{
  value: any;
  unit?: string;
}>();

const computedValue = computed(() => {
  let result = '';
  if (props.value.isScientificNotation) {
    const number = props.value.scientificNotationNumber;
    const exponent = props.value.scientificNotationExponent;
    result = number;
    if (exponent !== 0) {
      result += ` \\times 10^{${exponent}}`;
    }
  } else {
    result = decimalNumber(props.value);
  }
  if (props.unit) {
    result += ` \\ ${props.unit}`;
  }
  return result;
});
</script>
