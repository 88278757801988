/**
 * TODO: Fix the type of `numberValue`
 * @param numberValue
 */
export function decimalNumber(numberValue: any) {
  const nDigits = numberValue.number.toString().length;

  if (numberValue.number == 0) {
    return '0';
  }

  const strInt = numberValue.number.toString();
  const decimalPointLoc = nDigits + numberValue.shift;

  if (decimalPointLoc >= nDigits) {
    // Needs right-side zero padding
    return strInt + '0'.repeat(numberValue.shift);
  } else if (decimalPointLoc > 0) {
    // Needs no zero padding
    return strInt.substr(0, decimalPointLoc) + '.' + strInt.substr(decimalPointLoc);
  } else {
    const decimal = '0.' + '0'.repeat(Math.abs(decimalPointLoc));
    return decimal + strInt;
  }
}
