import {v4 as uuidv4} from 'uuid';
import {defineComponentType} from './defineComponentType';
import FileUploadUiConfig = App.Entities.Tasks.Ui.FileUploadUiConfig;
import FileUploadComponentGrader = App.Entities.Tasks.ComponentGraders.FileUploadComponentGrader;

export const fileUpload = defineComponentType<FileUploadUiConfig, FileUploadComponentGrader>({
  translationKey: 'File Upload',
  icon: 'upload',
  uiConfigFactory: () => ({
    uuid: uuidv4(),
    type: 'FileUpload',
    name: 'File Upload',
    label: '',
    acceptedFileExtensions: null,
    part: null,
    maxFileSize: null,
    maxNumberOfFiles: null,
  }),
  componentGraderFactory: (uuid) => ({
    uuid: uuidv4(),
    type: 'FileUpload',
    name: 'File Upload Grader',
    uiComponentUuid: uuid,
    noFilesProvidedFeedback: '',
    filesProvidedFeedback: '',
    points: null,
  }),
});
