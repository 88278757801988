<template>
  <Head :title="`Admin - ${institution.name} - Administrators`"></Head>
  <s-page-block size="md" class="flex-1">
    <s-page-header class="mb-2">
      <template #heading>
        <span>{{ institution.name }} - Administrators</span>
      </template>
    </s-page-header>
    <div>
      <s-form-inline
        v-model="newForm.email"
        placeholder="Email"
        @submit="
          newForm.post(
            route('admin.institutions.administrators.store', {institution: institution.id}),
            {
              onSuccess() {
                newForm.reset();
              },
            }
          )
        "
        submitLabel="Add"
        :processing="newForm.processing"
        icon="plus"
        class="mb-3"
      />

      <table class="table">
        <thead>
          <tr>
            <th class="w-full">Email</th>
            <th>Role</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="administrator in administrators">
            <td>{{ administrator.email }}</td>
            <td>{{ administrator.roleName }}</td>
            <td>
              <s-btn
                size="sm"
                color="red-light"
                icon="trash-can"
                method="delete"
                :href="
                  route('admin.institutions.administrators.destroy', {
                    institution: institution.id,
                    user: administrator.userId,
                  })
                "
                class="w-fit"
              >
                Revoke
              </s-btn>
            </td>
          </tr>
          <tr v-if="!administrators.length">
            <td colspan="4" class="text-center">No Administrators have been added yet</td>
          </tr>
        </tbody>
      </table>
    </div>
  </s-page-block>
</template>
<script setup lang="ts">
import {useI18n} from 'vue-i18n';
import SFormInline from '../../../design-system/SFormInline.vue';
import {Head, useForm} from '@inertiajs/vue3';
import {route} from 'ziggy-js';
import {Institution} from '../../../types/entities/institution';
import SPageBlock from '../../../design-system/SPageBlock.vue';
import SBtn from '../../../design-system/SBtn.vue';
import SPageHeader from '../../../design-system/SPageHeader.vue';
import {monthDayYear} from '../../../format/dates';

const props = defineProps<{
  institution: Institution;
  administrators: {email: string; userId: number; roleName: string}[];
}>();

const {t} = useI18n();

const newForm = useForm({
  email: '',
});
</script>
