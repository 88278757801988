<template>
  <Listbox v-if="taskResponses.length" v-model="modelValue">
    <div class="relative">
      <ListboxButton
        class="pl-4 pr-10 py-2.5 flex items-center leading-tight rounded-md border border-gray-200 shadow bg-white hover:bg-gray-50 appearance-none text-blue-600 hover:text-blue-400 font-bold w-full cursor-pointer text-left transition-all ease-out duration-150"
      >
        <div
          v-if="modelValue && modelValue.id"
          class="rounded-l-md absolute left-0 top-0 w-1.5 h-full border-r"
          :class="determineTaskResponseBarClasses(modelValue)"
        ></div>
        <s-icon
          v-if="modelValue?.hasAttachments"
          name="paperclip"
          class="opacity-70 -ml-1 mr-1"
          width="16"
          height="16"
        />
        <span v-if="modelValue && !modelValue?.isSelfSubmitted">
          {{
            monthDaySometimesYear(modelValue?.lastSavedAt ?? modelValue?.createdAt, {time: true})
          }}
        </span>
        <span v-else class="block truncate">
          {{
            monthDaySometimesYear(
              modelValue?.submittedAt || modelValue?.updatedAt || modelValue?.createdAt,
              {
                time: true,
              }
            )
          }}
        </span>
        <span class="pointer-events-none absolute top-1/2 -translate-y-1/2 right-0 pr-2">
          <s-icon name="chevron-down" class="h-5 w-5 text-gray-400" />
        </span>
      </ListboxButton>

      <transition
        leave-active-class="transition duration-100 ease-in"
        leave-from-class="opacity-100"
        leave-to-class="opacity-0"
      >
        <ListboxOptions
          class="z-50 absolute max-h-60 w-full overflow-auto rounded bg-white text-base shadow-lg ring-1 ring-black/5 focus:outline-none sm:text-sm divide-y divide-gray-150"
          :class="{
            '-top-2 -translate-y-full': position === 'top',
            '-bottom-2 translate-y-full': position === 'bottom',
          }"
        >
          <ListboxOption
            v-slot="{active, selected}"
            v-for="responseOption in taskResponses"
            :key="responseOption.id"
            :value="responseOption"
            as="template"
          >
            <li
              class="relative leading-none cursor-pointer select-none py-2.5 pl-5 pr-4 transition"
              :class="{
                'bg-blue-50/50 text-blue-800': active,
                'text-gray-900': !active,
                'bg-gradient-to-r from-blue-50/50 to-blue-50 text-blue-800': selected,
              }"
            >
              <div
                class="absolute left-0 top-0 w-1.5 h-full border-r"
                :class="determineTaskResponseBarClasses(responseOption)"
              ></div>
              <div class="text-sm flex gap-1.5 items-center">
                <span
                  v-if="responseOption.isDraft"
                  class="leading-none uppercase text-xs font-bold text-gray-600 flex items-center gap-1 mb-1"
                >
                  <s-icon name="pencil" class="opacity-70" size="14" />
                  {{ $t('draft') }}
                </span>
                <span
                  v-else-if="
                    isTaskResponseEffective(responseOption.id, studentAssignmentTaskGrades)
                  "
                  class="leading-none uppercase text-xs font-bold text-blue-600 flex items-center gap-1 mb-1"
                >
                  <s-icon name="check-bold" class="opacity-70" size="14" />
                  {{ $t('activeGrade') }}
                </span>
                <span
                  v-if="
                    !responseOption.isDraft &&
                    isResponseLate(dueDate, responseOption, assignmentExtension)
                  "
                  class="leading-none uppercase text-xs font-bold text-red-600 flex items-center gap-1 mb-1"
                >
                  <s-icon name="clock-alert" class="opacity-70" size="14" />
                  {{ $t('late') }}
                </span>
              </div>
              <span
                :class="[selected ? 'font-medium' : 'font-normal', 'flex items-center truncate']"
              >
                <s-icon
                  v-if="responseOption.hasAttachments"
                  name="paperclip"
                  class="opacity-70 -ml-1 mr-1"
                  width="16"
                  height="16"
                />
                <span v-if="!responseOption.isSelfSubmitted">
                  {{
                    monthDaySometimesYear(
                      responseOption?.lastSavedAt ?? responseOption?.createdAt,
                      {
                        time: true,
                      }
                    )
                  }}
                </span>
                <span v-else class="flex-1 truncate">
                  {{
                    monthDaySometimesYear(
                      responseOption?.submittedAt ||
                        responseOption?.updatedAt ||
                        responseOption?.createdAt,
                      {
                        time: true,
                      }
                    )
                  }}
                </span>
              </span>
            </li>
          </ListboxOption>
        </ListboxOptions>
      </transition>
    </div>
  </Listbox>
</template>

<script setup lang="ts">
import {Grade} from '../../../types/entities/base-grade';
import {TaskResponse} from '../../../types/entities/tasks';
import {monthDaySometimesYear} from '../../../format/dates';
import {useTaskResponseStateColors} from './task-response-state-colors';
import {Listbox, ListboxButton, ListboxOption, ListboxOptions} from '@headlessui/vue';
import {useVModel} from '@vueuse/core';
import {isResponseLate} from '../../../util/response-timestamps';
import {AssignmentExtension} from '../../../types/entities/assignment-extension';
import SIcon from '../../../design-system/SIcon.vue';
import {isTaskResponseEffective} from './task-response-state-utils';

type PartialTaskResponse = {id: number} & Pick<
  TaskResponse,
  | 'createdAt'
  | 'updatedAt'
  | 'submittedAt'
  | 'isDraft'
  | 'hasAttachments'
  | 'isSelfSubmitted'
  | 'lastSavedAt'
>;

const props = withDefaults(
  defineProps<{
    modelValue: PartialTaskResponse | null;

    dueDate: string | null | undefined;
    assignmentExtension?: AssignmentExtension | null;

    attemptedTaskIds: number[];
    draftTaskIds: number[];

    // Task
    taskResponses: PartialTaskResponse[];
    studentAssignmentTaskGrades: Grade[];

    position?: 'top' | 'bottom';
  }>(),
  {
    position: 'bottom',
  }
);

const modelValue = useVModel(props, 'modelValue');

const {determineTaskResponseBarClasses} = useTaskResponseStateColors(props);
</script>
