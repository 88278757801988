<template>
  <Head :title="t('title')"></Head>
  <section class="page-block stack vertical gap-6 lg:gap-8 max-w-screen-md">
    <h1 class="text-3xl leading-tight text-pretty">{{ t('title') }}</h1>
  </section>
</template>
<script lang="ts" setup>
import {Head} from '@inertiajs/vue3';
import {useI18n} from 'vue-i18n';

const {t} = useI18n({
  useScope: 'local',
  inheritLocale: true,
});
</script>
<i18n>
{
  "en": {
    "title": "Failure to Unsubscribe"
  },
  "fr": {
    "title": "Échec de désabonnement"
  }
}
</i18n>
