<template>
  <input
    v-model="model"
    class="flex-1 min-w-0 leading-tight rounded-md border border-gray-200 shadow-inner bg-white focus:outline-none focus:ring-2 focus:ring-blue-50 disabled:bg-gray-100/50 disabled:cursor-not-allowed disabled:text-gray-500 placeholder-gray-400"
    :class="{
      'px-3.5 py-2.5': size === 'lg' || size === 'md',
      'px-3 py-2': size === 'sm',
      'px-2.5 py-1.5': size === 'xs',
      '': size === 'custom',
    }"
  />
</template>
<script setup lang="ts">
const model = defineModel();

export type Size = 'custom' | 'xs' | 'sm' | 'md' | 'lg';

const {size = 'md'} = defineProps<{
  size?: Size;
}>();
</script>
