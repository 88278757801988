import axios from 'axios';
import {route} from 'ziggy-js';

export function useLogout() {
  return {
    async logout() {
      try {
        await axios.post(route('api.v0.auth.logout'));

        window.location.pathname = '/login';
      } catch (e) {
        console.error(e);
      }
    },
  };
}
