<template>
  <Head :title="t('page.title', {course: course.code, assignment: assignment.name})" />
  <s-page-block>
    <s-page-header :heading="assignment.name" :description="assignment.overview">
      <template #actions>
        <div class="flex items-end flex-wrap gap-x-2 gap-y-3">
          <s-badge-group class="flex mt-3">
            <s-badge
              v-if="isPastDueDate"
              color="red"
              size="custom"
              class="px-3.5 first:pl-4 last:pr-4 py-2 text-sm leading-tight font-bold"
            >
              {{ t('pastDueDate') }}
            </s-badge>
            <!-- Task Progress -->
            <s-badge
              :color="numAttemptedTasks < numAssignmentTasks ? 'gray' : 'green'"
              class="w-fit px-3.5 first:pl-4 last:pr-4 py-2 text-sm leading-tight font-medium"
              size="custom"
            >
              <s-icon
                v-if="numAttemptedTasks >= numAssignmentTasks"
                name="check-bold"
                size="16"
                class="opacity-70"
                aria-hidden="true"
              />
              <i18n-t keypath="xOfYTasksSubmitted">
                <template v-slot:x>
                  <strong class="font-bold">
                    {{ numAttemptedTasks }}
                  </strong>
                </template>
                <template v-slot:y>
                  <strong class="font-bold">
                    {{ numAssignmentTasks }}
                  </strong>
                </template>
              </i18n-t>
            </s-badge>
            <!-- Assignment Grade -->
            <s-badge
              v-if="assignmentGrade && can.viewGrades"
              :color="determineGradePillColor(assignmentGrade)"
              class="min-w-[6.5rem] justify-end px-3.5 first:pl-4 last:pr-4 py-2 text-sm leading-tight font-bold"
              size="custom"
            >
              <span class="opacity-70">{{ t('grade') }}</span>
              <span class="font-bold whitespace-nowrap">
                <s-grade :grade-display="course.gradeDisplay" :grade="assignmentGrade" tooltip />
              </span>
            </s-badge>
            <s-badge
              v-if="!assignmentGrade && can.viewGrades"
              color="gray"
              class="min-w-[6.5rem] justify-end px-3.5 first:pl-4 last:pr-4 py-2 text-sm leading-tight font-bold"
              size="custom"
            >
              <span class="opacity-70">{{ t('grade') }}</span>
              <span class="font-bold whitespace-nowrap">
                <s-grade :grade-display="course.gradeDisplay" :grade="null" tooltip />
              </span>
            </s-badge>
            <s-badge
              v-if="!can.viewGrades"
              color="gray"
              class="justify-end px-3.5 first:pl-4 last:pr-4 py-2 text-sm leading-tight font-bold"
              size="custom"
            >
              <s-icon name="lock" class="text-gray-600" size="18" />
              <span class="sr-only">{{ t('gradesHidden') }}</span>
            </s-badge>
          </s-badge-group>
          <s-btn
            :href="
              route('web.courses.assignments.tasks.first', {
                course: course.id,
                assignment: assignment.id,
              })
            "
            icon="arrow-right"
            icon-end
            :color="actionColor"
            class="min-w-[8rem]"
          >
            {{ t('links.' + assignmentProgressStatus) }}
          </s-btn>
        </div>
      </template>
    </s-page-header>
  </s-page-block>
  <s-page-block class="flex flex-col gap-4 !pt-2">
    <assignment-overview-editor v-if="overview" :model-value="overview.state" read-only />
    <div class="card-soft border border-gray-200 px-4 md:px-5 lg:px-6 py-2.5 md:py-4">
      <assignment-info-student
        :assignment="assignment"
        :student="student"
        :course="assignment.course"
        :startDate="assignedCourseLike?.startDate"
        :dueDate="assignedCourseLike?.dueDate"
        :extendedStartDate="extension?.newStartDate"
        :extendedDueDate="extension?.newDueDate"
        :totalMarks="assignmentMaxGrade"
        :grade="assignmentGrade"
        :can-view-grades="can.viewGrades"
      />
    </div>
  </s-page-block>
</template>
<script setup lang="ts">
import {computed} from 'vue';
import {Head} from '@inertiajs/vue3';
import {useI18n} from 'vue-i18n';
import SPageHeader from '../../design-system/SPageHeader.vue';
import {Course} from '../../types/entities/course';
import {Assignment} from '../../types/entities/assignment';
import SPageBlock from '../../design-system/SPageBlock.vue';
import {useBreadcrumbs} from '../../composables/useBreadcrumbs';
import {route} from 'ziggy-js';
import SBtn from '../../design-system/SBtn.vue';
import {isDatePast} from '../../format/dates';
import {CourseLikeAssignment} from '../../types/entities/course-like-assignment';
import AssignmentInfoStudent from '../AssignmentTask/Show/AssignmentInfoStudent.vue';
import {User} from '../../types/entities/user';
import {AssignmentExtension} from '../../types/entities/assignment-extension';
import AssignmentGradeDto = App.DTOs.AssignmentGradeDto;
import UserAssignmentTimerDto = App.DTOs.UserAssignmentTimerDto;
import SBadge from '../../design-system/SBadge.vue';
import SBadgeGroup from '../../design-system/SBadgeGroup.vue';
import SIcon from '../../design-system/SIcon.vue';
import AssignmentOverviewEditor from '../../editors/assignment-overview-editor/AssignmentOverviewEditor.vue';
import SGrade from '../../components/SGrade.vue';
import {RichText} from '../../types/entities/rich-text';

const props = defineProps<{
  student: User;
  course: Course;
  assignment: Assignment;
  overview: RichText | null;
  assignmentMaxGrade: number;
  assignmentGrade: AssignmentGradeDto;
  assignedCourseLike: CourseLikeAssignment;
  extension: AssignmentExtension | null;
  numAssignmentTasks: number;
  numAttemptedTasks: number;
  numDraftTasks: number;
  timer: UserAssignmentTimerDto | null;
  can: {
    viewGrades: boolean;
  };
}>();

const {t} = useI18n({
  useScope: 'local',
  inheritLocale: true,
});

useBreadcrumbs([
  {
    label: props.course.code,
    href: route('courses.show', {course: props.course.id}),
  },
  {
    label: 'Assignments',
    href: route('courses.assignment.index', {course: props.course.id}),
  },
]);

const isPastDueDate = computed(() => {
  const activeDueDate = props.extension?.newDueDate || props.assignedCourseLike.dueDate;
  if (!activeDueDate) return false;
  return isDatePast(activeDueDate);
});

const unclaimedExtensions = computed(() => {
  return props.timer?.extensions?.filter((extension) => !extension.claimed);
});

const assignmentProgressStatus = computed(() => {
  if (!props.numAttemptedTasks && !props.timer?.hasStarted) {
    return 'not-started';
  } else if (
    props.numAttemptedTasks >= props.numAssignmentTasks ||
    (props.timer?.hasFinished && unclaimedExtensions.value?.length === 0)
  ) {
    return 'completed';
  } else {
    return 'in-progress';
  }
});

const actionColor = computed(() => {
  if (assignmentProgressStatus.value === 'not-started') {
    return 'primary';
  } else if (assignmentProgressStatus.value === 'completed') {
    return 'white';
  } else {
    return 'blue-light';
  }
});

type PillColor = 'white' | 'gray' | 'blue' | 'green' | 'yellow' | 'red';

const gradePillColors: Record<string, PillColor> = {
  ungraded: 'gray',
  incorrect: 'red',
  partial: 'blue',
  correct: 'green',
};

type HasOriginalNumeratorAndDenominator = {
  originalNumerator: number;
  originalDenominator: number;
};

function determineGradePillColor(grade: HasOriginalNumeratorAndDenominator): PillColor {
  const gradeState = determineGradeState(grade) || 'ungraded';
  return gradePillColors[gradeState] || 'gray';
}

type TaskResponseState = 'incomplete' | 'ungraded' | 'incorrect' | 'partial' | 'correct';

const getGradePercentage = (grade: HasOriginalNumeratorAndDenominator) => {
  return Math.round((100 * Number(grade.originalNumerator)) / Number(grade.originalDenominator));
};

function determineGradeState(grade: HasOriginalNumeratorAndDenominator): TaskResponseState {
  const gradePercentage = getGradePercentage(grade);

  if (gradePercentage === 0) {
    return 'incorrect';
  }

  if (gradePercentage === 100) {
    return 'correct';
  }

  return 'partial';
}
</script>
<i18n>
{
  "en": {
    "page": {
      "title": "{course} - {assignment}"
    },
    "links": {
      "show": "View Details",
      "not-started": "Start",
      "in-progress": "Continue",
      "completed": "Review",
      "tasks": "Go to Assignment"
    }
  },
  "fr": {
    "page": {
      "title": "{course} - {assignment}"
    },
    "links": {
      "show": "Voir les détails",
      "not-started": "Commencer",
      "in-progress": "Continuer",
      "completed": "Réviser",
      "tasks": "Aller au devoir"
    }
  }
}
</i18n>
