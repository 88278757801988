<template>
  <div class="text-blue-700 leading-tight flex gap-3 justify-between items-center flex-wrap">
    <s-icon v-if="taskNeedsReview(task.id)" name="thumb-up" class="opacity-70" size="18" />
    <div class="flex-1 flex flex-col gap-0.5">
      <span v-if="wasManuallyGraded" class="text-sm">
        <i18n-t keypath="manuallyGradedDescription">
          <template #name>
            <strong>{{ responseGrade?.submittedByName }}</strong>
          </template>
          <template #date>
            <strong>{{ monthDaySometimesYear(responseGrade?.reviewedAt) }}</strong>
          </template>
        </i18n-t>
      </span>
      <span v-else class="text-sm">
        <i18n-t keypath="reviewedByDescription">
          <template #name>
            <strong>{{ responseGrade?.reviewedByName }}</strong>
          </template>
          <template #date>
            <strong>{{ monthDaySometimesYear(responseGrade?.reviewedAt) }}</strong>
          </template>
        </i18n-t>
      </span>
    </div>

    <s-btn
      v-if="canRevokeReview"
      icon="close"
      size="sm"
      color="danger-white"
      @click.prevent="
        router.delete(
          route('courses.assignment.marking.tasks.grades.review.destroy', {
            course: assignment.course.id,
            assignment: assignment.id,
            user: student.id,
            task: task.id,
            grade: responseGrade?.id,
          })
        )
      "
    >
      {{ t('revoke') }}
    </s-btn>
  </div>
</template>
<script setup lang="ts">
import {route} from 'ziggy-js';
import {router} from '@inertiajs/vue3';
import SBtn from '../../../design-system/SBtn.vue';
import {monthDaySometimesYear} from '../../../format/dates';
import {computed} from 'vue';
import SIcon from '../../../design-system/SIcon.vue';
import {useI18n} from 'vue-i18n';

const {t} = useI18n();

const props = defineProps<{
  responseGrade: any;
  student: any;
  assignment: any;
  task: any;
  taskNeedsReview: (taskId: number) => boolean | undefined;
}>();

const wasManuallyGraded = computed(() => {
  return !!props.responseGrade?.submittedByName;
});

const canRevokeReview = computed(() => {
  return props.taskNeedsReview(props.task.id) && !props.responseGrade?.submittedByName;
});
</script>
<i18n>
{
  "en": {
    "manuallyGradedDescription": "Manually graded by {name} on {date}",
    "reviewedByDescription": "Reviewed by {name} on {date}",
    "revoke": "Revoke"
  },
  "fr": {
    "manuallyGradedDescription": "Corrigé manuellement par {name} le {date}",
    "reviewedByDescription": "Révisé par {name} le {date}",
    "revoke": "Révoquer"
  }
}
</i18n>
