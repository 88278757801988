import {createApp, DefineComponent, h} from 'vue';
import {createInertiaApp, router} from '@inertiajs/vue3';
import mdiVue from 'mdi-vue/v3';
import * as mdijs from '@mdi/js';

import './bootstrap';
import '../css/app.css';
import AppLayout from './layouts/AppLayout.vue';
import AdminLayout from './layouts/AdminLayout.vue';
import {initializeSentryIfEnabled} from './config/sentry-config';
import {ZiggyVue} from 'ziggy-js';
import {createStembleI18n} from './config/create-stemble-i18n';

import 'highlight.js/styles/github-dark.css';
import 'highlight.js/lib/common';
import hljsVuePlugin from '@highlightjs/vue-plugin';

createInertiaApp({
  setup({el, App, props, plugin}) {
    const browserLanguage = navigator.language;

    const i18n = createStembleI18n(browserLanguage);

    const app = createApp({render: () => h(App, props)})
      .use(plugin)
      .use(i18n)
      .use(ZiggyVue)
      .use(hljsVuePlugin)
      .use(mdiVue, {
        icons: mdijs,
      });

    // Sentry must be initialized before the app is mounted
    initializeSentryIfEnabled(app, props.initialPage);

    app.mount(el);
  },
  resolve: (name) => {
    const pages = import.meta.glob('./pages/**/*.vue', {eager: true});

    const page = pages[`./pages/${name}.vue`] as DefineComponent;

    // If name starts with '/admin', use AdminLayout
    let defaultLayout = AppLayout;
    if (name.startsWith('Admin')) {
      defaultLayout = AdminLayout;
    }

    page.default.layout = page.default.layout || defaultLayout;

    return page;
  },
  title: (title) => (title ? `${title} | Stemble` : 'Stemble'),
})
  .then(() => {
    //
  })
  .catch((e) => {
    console.error(e);
  });

/**
 * # Capture XHR Errors
 *
 * This event handler _should_ prevent XHR errors from being surfaced to Sentry.
 * This is desireable for several reasons:
 * 1. The errors do not seem to be a problem for users
 * 2. The errors reports do not have enough info to help us debug.
 * 3. They make up a huge portion of our errors.
 *
 * ## Excerpt from Inertia docs
 *
 * > The exception event fires on unexpected XHR errors such as network interruptions.
 * > In addition, this event fires for errors generated when resolving page components.
 * >
 * > You may cancel the exception event to prevent the error from being thrown.
 * >
 * > This event will not fire for XHR requests that receive 400 and 500 level responses
 * > or for non-Inertia responses, as these situations are handled in other ways by Inertia.
 *
 * @see https://inertiajs.com/events#exception
 */
router.on('exception', (event) => {
  event.preventDefault();
});
