<template>
  <div
    :class="`w-fit border flex items-center gap-1 rounded-full font-medium tracking-wide ${colorClasses[color || defaultColor]} ${sizeClasses[size || defaultSize]}`"
  >
    <s-icon
      v-if="icon"
      :name="icon"
      :class="`ml-[-0.125em] ${iconColorClass[color || defaultColor]}`"
      size="1.25em"
    />
    <slot />
  </div>
</template>

<script setup lang="ts">
import SIcon from './SIcon.vue';

type Color = 'ghost' | 'white' | 'gray' | 'blue' | 'blue-light' | 'green' | 'yellow' | 'red';
type Size = 'sm' | 'md' | 'lg' | 'custom';

defineProps<{
  color?: Color;
  size?: Size;
  icon?: string;
}>();

const defaultColor: Color = 'gray';

const colorClasses: Record<Color, string> = {
  ghost: 'bg-transparent border-gray-150 text-gray-700 shadow-inset',
  white: 'bg-white border-gray-150 text-gray-700 shadow-inset',
  gray: 'bg-gray-100 border-gray-200 text-gray-600 shadow-inset',
  blue: 'bg-blue-100/70 border-blue-200/40 text-blue-700 shadow-inset-blue',
  'blue-light': 'text-blue-600 bg-blue-50 border-blue-100/50 shadow-inset-blue',
  green: 'bg-green-100 border-green-200 text-green-700 shadow-inset-green',
  yellow: 'bg-yellow-100 border-yellow-200 text-yellow-700 shadow-inset',
  red: 'bg-red-100 border-red-200 text-red-700 shadow-inset-red',
};

const iconColorClass: Record<Color, string> = {
  ghost: 'text-gray-500',
  white: 'text-gray-500',
  gray: 'text-gray-500',
  blue: 'text-blue-500',
  'blue-light': 'text-blue-500',
  green: 'text-green-500',
  yellow: 'text-yellow-500',
  red: 'text-red-500',
};

const defaultSize: Size = 'md';

const sizeClasses: Record<Size, string> = {
  sm: 'px-2 py-0 text-xs',
  md: 'px-2.5 py-0.5 text-sm',
  lg: 'px-3 py-1 text-sm',
  custom: '',
};
</script>
