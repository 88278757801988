<template>
  <s-textarea-field
    id="textBlockText"
    label="Text"
    :model-value="text ?? ''"
    :error="errors?.text"
    @update:modelValue="emit('update:text', $event)"
  />
</template>

<script setup lang="ts">
import STextareaField from '../../../../design-system/STextareaField.vue';

const props = defineProps<{
  text: string | null;
  errors?: Record<string, any>;
}>();

const emit = defineEmits<{
  (evt: 'update:text', text: string): void;
}>();
</script>
