import {computed, ref, Ref, watch} from 'vue';
import {TaskResponse} from '../../../types/entities/tasks';

/**
 * This composable is used to track the Task response data as it is being edited by the user.
 *
 * It also tracks whether the Task response form is dirty i.e. has different data than what
 * was last saved in the database.
 *
 * @param storedResponse The response we are viewing, as it was stored in the database.
 */
export function useResponseData(storedResponse: Ref<TaskResponse | null>) {
  let initialResponseContentJson: string = '';

  function cloneResponseContent() {
    initialResponseContentJson = JSON.stringify(storedResponse.value?.content || {});

    return JSON.parse(initialResponseContentJson);
  }

  /**
   * The response data we are currently working with in the frontend. This is a clone of the stored
   * response data and is updated as the user makes changes to the response. It might
   * not match the data stored in the database.
   */
  const workingResponseData = ref<Record<string, any>>(cloneResponseContent());

  const isDirty = computed(() => {
    return !(JSON.stringify(workingResponseData.value) === initialResponseContentJson);
  });

  watch(
    storedResponse,
    () => {
      workingResponseData.value = cloneResponseContent();
    },
    {immediate: true}
  );

  return {responseData: workingResponseData, isDirty};
}
