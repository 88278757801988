import {v4 as uuidv4} from 'uuid';
import {defineComponentType} from './defineComponentType';
import RankOrderUiConfig = App.Entities.Tasks.Ui.RankOrderUiConfig;
import RankOrderComponentGrader = App.Entities.Tasks.ComponentGraders.RankOrderComponentGrader;

export const rankOrder = defineComponentType<RankOrderUiConfig, RankOrderComponentGrader>({
  translationKey: 'Rank Order',
  icon: 'compare-vertical',
  uiConfigFactory: () => ({
    uuid: uuidv4(),
    type: 'RankOrder',
    name: 'Rank Order',
    prependText: null,
    appendText: null,
    options: [],
    part: null,
  }),
  componentGraderFactory: (uuid) => ({
    uuid: uuidv4(),
    type: 'RankOrder',
    name: 'Rank Order Grader',
    uiComponentUuid: uuid,
    correctAnswers: [],
    points: null,
  }),
});
