<template>
  <task-form :errors="errors" :form="form" class="fieldset" allow-media>
    <task-editor v-if="page.props.features.visualEditorForTasks" v-model="form" />

    <s-form-list
      formTitle="Create DataDrivenTask"
      title="Task Parts"
      addLabel="Add Task Part"
      saveLabel="Save Task Part"
      :menuOptions="componentTypeMenu"
      v-model:items="componentPairs"
      :displayValue="(item?: any) => item?.uiConfig.name"
      :item-key="(item) => item.uiConfig.uuid"
      :removeItem="(item?: any) => removeUi(item.uiConfig.uuid)"
      removeLabel="Remove Task Part"
      :confirmRemove="true"
      placeholder="No Task Parts Added"
      :sortable="true"
    >
      <template #item="{item, index: i}">
        <div class="flex-1 flex w-full">
          <button
            class="group flex-1 flex items-center justify-between gap-1 w-full text-left px-2.5 first:pl-3 py-2.5 group-first:pt-3 group-last:pb-3 hover:text-blue-600 hover:bg-gray-200/20"
            @click.prevent="selectComponentPair(item)"
          >
            <s-icon
              v-if="item.hasErrors"
              name="warning"
              size="16"
              class="text-red-600 opacity-80"
            />
            <span class="flex-1 truncate">
              {{ item.uiConfig.name }}
            </span>
            <s-icon
              name="pencil"
              size="20"
              class="text-blue-500 opacity-0 transition group-hover:opacity-50"
            />
          </button>
          <button
            type="button"
            title="duplicate"
            class="border-l border-gray-200 text-blue-300 hover:text-blue-500 flex-none flex items-center justify-center py-2.5 px-3 opacity-70 hover:opacity-100 transition-all ease-out duration-150 bg-transparent hover:bg-gray-200/20"
            @click.prevent="duplicate(item)"
          >
            <s-icon name="content-duplicate" size="18" />
          </button>
        </div>
      </template>
    </s-form-list>
    <task-variable-form v-model:variables="form.variables" :errors="errors?.variables" />
  </task-form>

  <component-pair-form-modal
    v-if="selectedComponentPair"
    :task-id="form.id as number"
    :files="form.files || []"
    :variables="form.variables"
    :errors="selectedComponentPair.errors"
    :ui-config="selectedComponentPair.uiConfig"
    @update:ui-config="form.uiConfigs[selectedComponentPair.uiConfigIndex] = $event"
    :component-grader="selectedComponentPair.componentGrader"
    @update:component-grader="
      form.gradingAlgorithm.componentGraders[selectedComponentPair.componentGraderIndex] = $event
    "
    :open="componentPairModalOpen"
    @close="selectedComponentPair = null"
  />
</template>

<script lang="ts" setup>
import SFormList from '../../../design-system/SFormList.vue';
import {v4 as uuidv4} from 'uuid';
import TaskVariableForm from './Variables/TaskVariableForm.vue';
import {computed, ref} from 'vue';
import {useValidationErrors} from '../../../util/validation-errors';
import ComponentPairFormModal from './ComponentPairFormModal.vue';
import {ComponentType} from './component-registry/defineComponentType';
import {useTaskComponentTypeRegistry} from './component-registry';
import {useI18n} from 'vue-i18n';
import SIcon from '../../../design-system/SIcon.vue';
import TaskForm from '../../../forms/TaskForm.vue';
import {useForm, usePage} from '@inertiajs/vue3';
import DataDrivenTaskFormDto = App.DTOs.Tasks.DataDrivenTaskFormDto;
import TaskComponentGrader = App.Entities.Tasks.ComponentGraders.TaskComponentGrader;
import UiConfig = App.Entities.Tasks.Ui.UiConfig;
import TaskEditor from '../../../editors/task-editor/TaskEditor.vue';

const page: any = usePage();

const props = defineProps<{
  modelValue?: DataDrivenTaskFormDto;
  isCopy?: boolean;
  errors?: Record<string, any>;
}>();

const {errors} = useValidationErrors(props, 'errors');

const {t} = useI18n({
  useScope: 'local',
  inheritLocale: true,
});

const form = useForm<DataDrivenTaskFormDto>(
  () =>
    props.modelValue || {
      type: 'DataDrivenTask',
      title: '',
      textbookLink: null,
      textbookLinkText: null,
      textbookLinkIcon: null,
      youtubeId: null,
      status: 'draft',
      draftable: true,
      uiConfigs: [],
      gradingAlgorithm: {
        uuid: uuidv4(),
        componentGraders: [],
      },
      variables: [],
      files: [],
    }
);

type ComponentPair = {
  componentGraderIndex: number | null;
  componentGrader: TaskComponentGrader | null;
  uiConfigIndex: number;
  uiConfig: UiConfig;
  hasErrors: boolean;
  errors: {
    uiConfig: Record<string, any> | null;
    componentGrader: Record<string, any> | null;
  };
};
const componentPairs = computed<ComponentPair[]>({
  get: () => {
    return form.uiConfigs.map((uiConfig, uiConfigIndex) => {
      let componentGraderIndex: number | null = form.gradingAlgorithm.componentGraders.findIndex(
        (grader) => grader.uiComponentUuid === uiConfig.uuid
      );

      let componentGrader: TaskComponentGrader | null = null;
      let componentGraderErrors: Record<string, any> | null = null;
      if (componentGraderIndex !== -1) {
        componentGrader = form.gradingAlgorithm.componentGraders[componentGraderIndex];
        componentGraderErrors =
          errors.value?.gradingAlgorithm?.componentGraders?.[componentGraderIndex];
      } else {
        componentGraderIndex = null;
      }

      const uiConfigErrors = errors.value?.uiConfigs?.[uiConfigIndex];

      const hasErrors = uiConfigErrors || componentGraderErrors;

      return {
        componentGraderIndex,
        componentGrader,
        uiConfigIndex,
        uiConfig,
        hasErrors,
        errors: {
          uiConfig: uiConfigErrors,
          componentGrader: componentGraderErrors,
        },
      };
    });
  },
  set: (newComponentPairs) => {
    form.uiConfigs = newComponentPairs.map(({uiConfig}) => uiConfig);

    form.gradingAlgorithm.componentGraders = newComponentPairs
      .map(({componentGrader}) => componentGrader)
      .filter((item): item is TaskComponentGrader => !!item);
  },
});

/*
 * Component type factories, labels, icons, etc.
 */
const {componentTypes} = useTaskComponentTypeRegistry();

const componentTypeMenu = computed(() =>
  componentTypes.map((componentType) => ({
    label: t(componentType.translationKey),
    icon: componentType.icon,
    onClick: () => addComponentType(componentType),
  }))
);

const getNewComponentName = ({name, type}: UiConfig): string => {
  const existingNames = new Set(form.uiConfigs.map(({name}) => name));

  let initialGuessIndex = form.uiConfigs.filter((item) => item.type == type).length + 1;
  let newName = '';

  do {
    newName = name + ' ' + initialGuessIndex;
    if (!existingNames.has(newName)) {
      return newName;
    }
    initialGuessIndex++;
  } while (existingNames.has(newName));

  return name;
};

const addComponentType = ({
  uiConfigFactory,
  componentGraderFactory,
}: ComponentType<UiConfig, TaskComponentGrader>) => {
  const uiConfig = uiConfigFactory();
  const componentGrader = componentGraderFactory(uiConfig.uuid);

  // Push new components
  uiConfig.name = getNewComponentName(uiConfig);
  form.uiConfigs.push(uiConfig);

  if (componentGrader) {
    componentGrader.name = `${uiConfig.name} Grader`;
    form.gradingAlgorithm.componentGraders.push(componentGrader);
  }
};

const removeUi = (uuid: string) => {
  form.uiConfigs = form.uiConfigs.filter((config) => uuid !== config.uuid);
  form.gradingAlgorithm.componentGraders = form.gradingAlgorithm.componentGraders.filter(
    (config) => uuid !== config.uiComponentUuid
  );
};

const selectedComponentPair = ref<any>(null);

const selectComponentPair = (item: any) => {
  selectedComponentPair.value = item;
};

const componentPairModalOpen = computed(() => !!selectedComponentPair.value);

function duplicate(item: ComponentPair) {
  const newUiConfig: UiConfig = {
    ...item.uiConfig,
    uuid: uuidv4(),
    name: item.uiConfig.name + ' (Copy)',
  };

  form.uiConfigs.push(newUiConfig);

  if (item.componentGrader) {
    const newComponentGrader: TaskComponentGrader = {
      ...item.componentGrader,
      uuid: uuidv4(),
      uiComponentUuid: newUiConfig.uuid,
    };

    form.gradingAlgorithm.componentGraders.push(newComponentGrader);
  }
}
</script>
