export const inlineViewableImageMimeTypes = [
  'image/jpeg',
  'image/png',
  'image/gif',
  'image/bmp',
  'image/webp',
];
export const inlineViewableImageTypeExtensions = ['jpg', 'jpeg', 'png', 'gif', 'bmp', 'webp'];

export function isImageMimeType(mimeType: string) {
  return mimeType.startsWith('image/');
}
