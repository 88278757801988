<template>
  <Head title="Create Model Metadata" />
  <div class="page-block">
    <section>
      <h2 class="heading lg mb-2">Create Model Metadata</h2>
      <form @submit.prevent="form.post(route('admin.ai-analytics.models.store'), {})">
        <div class="grid grid-cols-1 md:grid-cols-2 gap-4 mb-8">
          <s-input-field
            id="model"
            placeholder="gpt-4o"
            label="Model Name"
            v-model="form.model"
            :error="form.errors.model"
          />

          <s-select-field id="llmModel" label="Llm Provider" v-model="form.llmProvider">
            <option v-for="(label, key) in LlmProviderOptions" :value="key">
              {{ label }}
            </option>
          </s-select-field>
          <s-input-field
            id="inputTokenPricingPerMillionTokens"
            type="number"
            step="0.01"
            min="0"
            label="Input Token Pricing (Per Million)"
            v-model="form.inputTokenPricingPerMillionTokens"
            :error="form.errors.inputTokenPricingPerMillionTokens"
          />
          <s-input-field
            id="outputTokenPricingPerMillionTokens"
            type="number"
            step="0.01"
            min="0"
            label="Output Token Pricing (Per Million)"
            v-model="form.outputTokenPricingPerMillionTokens"
            :error="form.errors.outputTokenPricingPerMillionTokens"
          />
          <s-input-field
            type="number"
            step="1"
            min="0"
            id="maxInputContextWindow"
            label="Max Input Context Window"
            v-model="form.maxInputContextWindow"
            :error="form.errors.maxInputContextWindow"
          />
          <s-input-field
            id="maxOutputTokens"
            type="number"
            step="1"
            min="0"
            label="Max Output Tokens"
            v-model="form.maxOutputTokens"
            :error="form.errors.maxOutputTokens"
          />
          <s-field
            id="supportsAiImageGrading"
            label="Supports AI Image Grading"
            :error="form.errors.supportsAiImageGrading"
          >
            <s-button-toggle
              id="supportsAiImageGrading"
              :options="[
                {value: true, label: 'Yes'},
                {value: false, label: 'No'},
              ]"
              v-model="form.supportsAiImageGrading"
            />
          </s-field>
          <s-field
            id="supportsStructuredOutputs"
            label="Supports Structured Outputs"
            :error="form.errors.supportsStructuredOutputs"
          >
            <s-button-toggle
              id="supportsStructuredOutputs"
              :options="[
                {value: true, label: 'Yes'},
                {value: false, label: 'No'},
              ]"
              v-model="form.supportsStructuredOutputs"
            />
          </s-field>

          <s-field
            id="supportsTemperature"
            label="Supports Temperature"
            :error="form.errors.supportsTemperature"
          >
            <s-button-toggle
              id="supportsTemperature"
              :options="[
                {value: true, label: 'Yes'},
                {value: false, label: 'No'},
              ]"
              v-model="form.supportsTemperature"
            />
          </s-field>
          <s-field id="supportsTopP" label="Supports Top P" :error="form.errors.supportsTopP">
            <s-button-toggle
              id="supportsTopP"
              :options="[
                {value: true, label: 'Yes'},
                {value: false, label: 'No'},
              ]"
              v-model="form.supportsTopP"
            />
          </s-field>
        </div>
        <div class="stack horizontal gap-2">
          <s-btn type="submit" class="w-fit" :processing="form.processing">Create</s-btn>
          <s-btn color="secondary" :href="route('admin.ai-analytics.models.index')">Cancel</s-btn>
        </div>
      </form>
    </section>
  </div>
</template>

<script setup lang="ts">
import {Head, useForm} from '@inertiajs/vue3';
import {route} from 'ziggy-js';
import SBtn from '../../../../design-system/SBtn.vue';
import SInputField from '../../../../design-system/SInputField.vue';
import SButtonToggle from '../../../../design-system/SButtonToggle.vue';
import SField from '../../../../design-system/SField.vue';
import LlmProviders = App.Enums.LlmProviders;
import SSelectField from '../../../../design-system/SSelectField.vue';

const LlmProviderOptions: Record<LlmProviders, string> = {
  OpenAi: 'OpenAI',
  GoogleGemini: 'Google Gemini',
  Anthropic: 'Anthropic',
};

const form = useForm({
  model: '',
  llmProvider: 'OpenAi',
  inputTokenPricingPerMillionTokens: 2.5,
  outputTokenPricingPerMillionTokens: 10.0,
  maxInputContextWindow: 128000,
  maxOutputTokens: 16384,
  supportsAiImageGrading: true,
  supportsStructuredOutputs: true,
  supportsTemperature: true,
  supportsTopP: true,
});
</script>
