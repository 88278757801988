<template>
  <Head title="Admin - Institutions - Add"></Head>
  <div class="page-block gap-5">
    <s-page-header heading="Add Institution"></s-page-header>
    <form @submit.prevent="form.post(route('admin.institutions.store'))">
      <s-input-field
        id="abbreviation"
        label="Abbreviation"
        v-model="form.abbreviation"
        :errors="form.errors.abbreviation"
        required
        autofocus
      />

      <s-input-field
        id="name"
        label="Name"
        v-model="form.name"
        :errors="form.errors.name"
        required
      />

      <s-btn type="submit" :processing="form.processing">Save</s-btn>
    </form>
  </div>
</template>
<script setup lang="ts">
import SPageHeader from '../../../design-system/SPageHeader.vue';
import {Head, useForm} from '@inertiajs/vue3';
import SInputField from '../../../design-system/SInputField.vue';
import SBtn from '../../../design-system/SBtn.vue';
import {route} from 'ziggy-js';

defineProps<{}>();

const form = useForm({
  abbreviation: '',
  name: '',
});
</script>
