<template>
  <div class="dashboard">
    <section class="mt-4">
      <v-chart class="chart" :option="bubbleScatterOption" autoresize />
    </section>

    <section class="mt-4">
      <label class="mr-4 ml-12" for="maxAttemptsSelect">Select Max Attempts:</label>
      <select id="maxAttemptsSelect" v-model="selectedMaxAttempts" class="form-select">
        <option v-for="(attemptData, key) in gradeStats" :key="key" :value="key">
          {{ key }} Attempts
        </option>
      </select>
    </section>

    <section class="mt-4">
      <v-chart class="chart" :option="scatterPlotOption" autoresize />
    </section>
  </div>
</template>

<script setup lang="ts">
import {computed, ref, watch} from 'vue';
import {use} from 'echarts/core';
import {ScatterChart, ScatterSeriesOption} from 'echarts/charts';
import {
  GridComponent,
  GridComponentOption,
  LegendComponent,
  LegendComponentOption,
  TimelineComponent,
  TimelineComponentOption,
  TitleComponent,
  TitleComponentOption,
  TooltipComponent,
  TooltipComponentOption,
} from 'echarts/components';
import {CanvasRenderer} from 'echarts/renderers';
import VChart from 'vue-echarts';
import * as echarts from 'echarts';
import {ComposeOption} from 'echarts';
import * as ecStat from 'echarts-stat';
import {Task} from '../../../../types/entities/tasks';
import {Assignment} from '../../../../types/entities/assignment';

echarts.registerTransform((ecStat as any).transform.regression);

use([
  TimelineComponent,
  TitleComponent,
  LegendComponent,
  ScatterChart,
  CanvasRenderer,
  TooltipComponent,
  GridComponent,
]);

type ECOption = ComposeOption<
  | ScatterSeriesOption
  | TimelineComponentOption
  | TitleComponentOption
  | TooltipComponentOption
  | LegendComponentOption
  | GridComponentOption
>;

const props = defineProps<{
  task: Task | null;
  assignment: Assignment | null;
  gradesByAttempts: {
    [maxAttempts: string]: {
      example_user_id: number;
      number_of_students: number;
      final_avg_grade: number;
      attempt_data: {
        attempt_number: number;
        avg_grade: number;
      }[];
    };
  };
}>();

const gradeStats = computed(() => props.gradesByAttempts || {});

const bubbleScatterOption = computed<any>(() => {
  const dataset: any[] = [];

  Object.keys(gradeStats.value).forEach((maxAttempts) => {
    dataset.push([
      parseInt(maxAttempts),
      gradeStats.value[maxAttempts].final_avg_grade, // y-axis: final_avg_grade
      Math.sqrt(gradeStats.value[maxAttempts].number_of_students), // Bubble size as the square root of number_of_students
      gradeStats.value[maxAttempts].number_of_students, // Keep the actual number of students in dataset
    ]);
  });

  const logRegressionData = ecStat.regression(
    'logarithmic',
    dataset.map((item) => [item[0], item[1]]),
    2
  );
  const logRegressionEquation = logRegressionData.expression;

  return {
    title: {
      text: 'Learning Rate By Attempts',
      left: 'center',
    },
    tooltip: {
      trigger: 'axis',
      axisPointer: {
        type: 'cross',
      },
      formatter: (params: any) => {
        const [maxAttempts, finalAvgGrade, bubbleSize, numberOfStudents] = params[0].value;
        return `
          Students Who <strong> Attempted ${maxAttempts} Times</strong><br />
          <strong>Average Final Grade:</strong> ${finalAvgGrade.toFixed(2)}<br />
          <strong>Number of Students:</strong> ${numberOfStudents}
        `;
      },
    },
    xAxis: {
      type: 'value',
      name: 'Max Attempts',
      splitLine: {lineStyle: {type: 'dashed'}},
    },
    yAxis: {
      type: 'value',
      name: 'Final Average Grade %',
      min: 0,
      max: 100,
      splitLine: {lineStyle: {type: 'dashed'}},
    },
    visualMap: {
      show: false,
      dimension: 2,
      min: Math.min(...dataset.map((d) => d[2])),
      max: Math.max(...dataset.map((d) => d[2])),
      seriesIndex: [0, 1],
      inRange: {
        symbolSize: [10, 100], // Bubble size scaling
      },
    },
    series: [
      {
        name: 'Attempts vs Grades',
        type: 'scatter',
        datasetIndex: 0,
        encode: {x: 0, y: 1, tooltip: [0, 1, 2, 3]},
      },
      {
        name: 'Log Regression',
        type: 'line',
        smooth: true,
        datasetIndex: 1,
        encode: {label: 2, tooltip: 1},
        symbol: 'none',
        label: {
          show: true,
          fontSize: 16,
          position: 'end',
          formatter: logRegressionEquation,
        },
        labelLayout: {dx: -20},
      },
    ],
    dataset: [
      {
        source: dataset,
      },
      {
        transform: {
          type: 'ecStat:regression',
          config: {
            method: 'logarithmic',
          },
        },
      },
    ],
  };
});

const selectedMaxAttempts = ref(Object.keys(gradeStats.value).at(-1));

const scatterPlotOption = computed<any>(() => {
  const selectedData = gradeStats.value[selectedMaxAttempts.value ?? 0];
  const dataset: any[] = selectedData.attempt_data.map((attempt: any) => [
    attempt.attempt_number,
    attempt.avg_grade,
  ]);

  const linearRegressionData = ecStat.regression('linear', dataset, 2);
  const linearRegressionEquation = linearRegressionData.expression;

  let subtext = '';
  let sublink = '';
  if (props.task?.id && props.assignment?.id) {
    subtext = `Example Student Link`;
    sublink = `https://app.stemble.com/courses/${props.assignment.course.id}/assignments/${props.assignment.id}/marking/${selectedData.example_user_id}/tasks/${props.task.id}`;
  }

  return {
    title: {
      text: `Average Grade Over Attempts (Max Attempts: ${selectedMaxAttempts.value})`,
      subtext: subtext,
      sublink: sublink,
      left: 'center',
    },
    tooltip: {
      trigger: 'axis',
      axisPointer: {
        type: 'cross',
      },
      formatter: (params: any) => {
        const [attemptNumber, avgGrade] = params[0].value;
        return `
          <strong>Attempt Number:</strong> ${attemptNumber}<br />
          <strong>Average Grade:</strong> ${avgGrade.toFixed(2)}
        `;
      },
    },
    xAxis: {
      type: 'value',
      name: 'Attempt Number',
      splitLine: {lineStyle: {type: 'dashed'}},
    },
    yAxis: {
      type: 'value',
      name: 'Average Grade %',
      min: 0,
      max: 100,
      splitLine: {lineStyle: {type: 'dashed'}},
    },
    series: [
      {
        name: 'Attempts vs Average Grade',
        type: 'scatter',
        datasetIndex: 0,
        encode: {x: 0, y: 1, tooltip: [0, 1]},
      },
      {
        name: 'Linear Regression',
        type: 'line',
        smooth: true,
        datasetIndex: 1,
        encode: {label: 2, tooltip: 1},
        symbol: 'none',
        label: {
          show: true,
          fontSize: 16,
          position: 'end',
          formatter: linearRegressionEquation,
        },
        labelLayout: {dx: -20},
      },
    ],
    dataset: [
      {
        source: dataset,
      },
      {
        transform: {
          type: 'ecStat:regression',
          config: {
            method: 'linear',
          },
        },
      },
    ],
  };
});

watch(selectedMaxAttempts, () => {});
</script>

<style scoped>
.chart {
  height: 600px;
  width: 100%;
}
</style>
