<template>
  <form @submit.prevent="submit">
    <s-tabs
      :tabs="[
        {
          label: t('tabs.general'),
          icon: 'pencil',
        },
        {
          label: t('tabs.media'),
          icon: 'image-multiple',
          // Disable media until the task is initially created (needed for now as we need a task id to associate the file against)
          disabled: !allowMedia || !form.id,
        },
      ]"
      v-model="selectedTab"
    >
      <template #tab-0-content>
        <fieldset class="flex flex-col gap-4 mb-4">
          <s-input-field
            id="text"
            :label="t('labels.title')"
            :tooltip="t('tooltips.title')"
            v-model="form.title"
            :error="form.errors?.title"
          />
          <s-input-field
            id="textbookLink"
            :label="t('labels.textbookLink')"
            :tooltip="t('tooltips.textbookLink')"
            v-model="form.textbookLink"
            :error="form.errors?.textbookLink"
          />
          <s-input-field
            id="textbookLinkText"
            :label="t('labels.textbookLinkText')"
            v-model="form.textbookLinkText"
            :error="form.errors?.textbookLinkText"
          />
          <s-input-field
            id="textbookLinkIcon"
            :label="t('labels.textbookLinkIcon')"
            :tooltip="t('tooltips.textbookLinkIcon')"
            v-model="form.textbookLinkIcon"
            :error="form.errors?.textbookLinkIcon"
          />
          <s-input-field
            v-if="!hideYoutubeId"
            id="youtubeId"
            :label="t('labels.youTubeId')"
            :tooltip="t('tooltips.youtubeId')"
            v-model="form.youtubeId"
            :error="form.errors?.youtubeId"
          />
        </fieldset>

        <fieldset class="flex flex-col gap-4 mb-4">
          <slot />
        </fieldset>

        <fieldset class="flex flex-col gap-5">
          <s-toggle-field id="draftable" :label="t('labels.draftable')" v-model="form.draftable" />
          <task-status-field v-model="form.status" />
        </fieldset>
      </template>
      <template #tab-1-content>
        <task-media-manager v-model="form.files" :task-id="form.id" />
      </template>
      <template #tab-footer>
        <span class=""></span>
        <s-btn
          :processing="false"
          class="flex-1 max-w-[14rem]"
          :icon="form.id ? 'content-save' : 'plus'"
          @click="submit"
        >
          <span v-if="!form.id">
            {{ t('actions.create') }}
          </span>
          <span v-else>
            {{ t('actions.update') }}
          </span>
        </s-btn>
      </template>
    </s-tabs>
  </form>
</template>
<script lang="ts" setup>
import {defineProps, ref} from 'vue';
import SInputField from '../design-system/SInputField.vue';
import TaskStatusField from '../components/TaskStatusField.vue';
import SToggleField from '../design-system/SToggleField.vue';
import STabs from '../design-system/STabs.vue';
import SBtn from '../design-system/SBtn.vue';
import TaskMediaManager from './TaskForm/TaskMediaManager.vue';
import {useI18n} from 'vue-i18n';
import {InertiaForm} from '@inertiajs/vue3';
import TaskFormDto = App.DTOs.Tasks.TaskFormDto;

type CommonFields = Pick<
  TaskFormDto,
  | 'id'
  | 'title'
  | 'youtubeId'
  | 'draftable'
  | 'status'
  | 'textbookLink'
  | 'textbookLinkText'
  | 'textbookLinkIcon'
  | 'files'
>;

const {form} = defineProps<{
  form: InertiaForm<CommonFields>;
  hideYoutubeId?: boolean;
  allowMedia?: boolean;
}>();

const selectedTab = ref(0);

const submit = () => {
  if (form.id) {
    form.put(route('tasks.update', {task: form.id}), {
      onSuccess: () => {
        form.reset();
      },
    });
  } else {
    form.post(route('tasks.store'));
  }
};

const {t} = useI18n();
</script>
<i18n>
{
  "en": {
    "actions.create": "Create Task",
    "actions.update": "Update Task",
    "tabs.general": "General",
    "tabs.media": "Media",
    "tooltips.title": "Displayed when browsing the Task Gallery.",
    "tooltips.textbookLink": "Offers the student a link to a relevant section in their textbook",
    "tooltips.textbookLinkIcon": "https://pictogrammers.com/library/mdi/ for available icons",
    "tooltips.youtubeId": "Offers the student a link to a relevant YouTube video",
    "labels.draftable": "Draftable",
    "labels.title": "Title",
    "labels.textbookLink": "Textbook Link",
    "labels.textbookLinkText": "Textbook Link Text",
    "labels.textbookLinkIcon": "Textbook Link Icon",
    "labels.youTubeId": "YouTube ID"
  },
  "fr": {
    "actions.create": "Créer une tâche",
    "actions.update": "Mettre à jour la tâche",
    "tabs.general": "Général",
    "tabs.media": "Médias",
    "tooltips.title": "Affiché lors de la navigation dans la galerie de tâches.",
    "tooltips.textbookLink": "Donne à l'élève un lien vers une section du manuel scolaire pertinente",
    "tooltips.textbookLinkIcon": "https://pictogrammers.com/library/mdi/ pour les icônes disponibles",
    "tooltips.youtubeId": "Offre à l'élève un lien vers une vidéo YouTube pertinente",
    "labels.draftable": "Les brouillons peuvent être enregistrés",
    "labels.title": "Titre",
    "labels.textbookLink": "Lien du manuel scolaire",
    "labels.textbookLinkText": "Texte du lien du manuel scolaire",
    "labels.textbookLinkIcon": "Icône du lien du manuel scolaire",
    "labels.youTubeId": "ID YouTube"
  }
}
</i18n>
